import React, { Component } from 'react';
import './ValidateOTP.css';
import "../../../../styles/style.css";
import Messages from "../../../Config/Messages";
import { validateOtpNo, StoreDataInStorage, logoutSession } from "../../../Config/Utils";
import { PostData } from "../../../../services/PostData";
import { VALIDATE_OTP_API } from '../../../Config/Config.json'
import Constants from '../../../Config/Constants';
import { PutMandatoryfieldSymbol } from "../../../UtillCustomComponent/UtillCustomComponent";
import OnHoverIconInfo from '../../../UtillCustomComponent/UtillCustomComponent';
import { ValidateOTPConstForm } from '../../../Config/ConstForm';

export default class ValidateOTP extends Component {
    constructor(props) {
        super(props);
        const data = this.props.location;
        this.state = {
            userFields: data.data,
            errors: {},
            otp: {},
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidationOnBlur = this.handleValidationOnBlur.bind(this);
    }

    componentDidMount() {
        logoutSession()
    }


    handleChange(event) {
        let otp = this.state.otp;
        let value = event.target.value
        otp["otp"] = value
        this.setState({ otp })
        // if (key === 'Name' && value[0] === ' ') value = value.trim()
    }

    handleValidationOnBlur(e) {
        let fields = this.state.otp;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name === "otp") {
            if (!fields["otp"]) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterOTP
            }
            else if (typeof fields["otp"] !== "undefined") {
                if (!validateOtpNo(fields["otp"])) {
                    formIsValid = false;
                    errors["otp"] = Messages.v_EnterValidOTP;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }


    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.otp;


        if (!fields["otp"]) {
            formIsValid = false;
            errors["otp"] = Messages.v_EnterOTP
        }
        else if (typeof fields["otp"] !== "undefined") {
            if (!validateOtpNo(fields["otp"])) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterValidOTP;
            }
        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        // var url = "";
        var userfields = this.state.userFields;
        e.preventDefault();

        if (this.validateForm()) {
            var postData = {};
            postData["Email"] = userfields.Email;
            postData["PhoneNumber"] = userfields.PhoneNumber;
            postData["DeviceId"] = userfields.DeviceId;
            postData["DeviceType"] = userfields.DeviceType;
            postData["Code"] = this.state.otp.otp;
            //alert('data: ' + JSON.stringify(postData));            
            this.callValidateOtpAPI(postData);
        }
    }

    callValidateOtpAPI(postData) {
        var URL = VALIDATE_OTP_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var authToken = responseJson["Data"].Token;
                    StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                    this.props.history.push({
                        pathname: '/login',
                    })
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
            });
    }

    render() {
        return (
            <section className="section">
                {/* <div id="loader" className={this.state.loaderClass}></div> */}
                <div className="registed_tab_part">
                    <div className="container">
                        <p id="instruction"><h3>Validate OTP</h3></p>
                        <div className="same_cantant">
                            <div className="registed_form_tab">
                                <div id="part0">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">
                                                <div className="reg_form_main">
                                                    <p className="col-sm-12" for="otp">
                                                        {ValidateOTPConstForm.OtpLabel} <OnHoverIconInfo message={ValidateOTPConstForm.Otphovermessage} /> <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <input
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        inputmode="numeric"
                                                        placeholder={ValidateOTPConstForm.Otpplaceholder}
                                                        name="otp"
                                                        maxLength="6"
                                                        value={this.state.otp.otp}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur} />
                                                    <div className="errorMsg">
                                                        {this.state.errors.otp}
                                                    </div>
                                                </div>


                                                <div className="bottam_sing">
                                                    <label
                                                        for="step2"
                                                        id="continue-step2"
                                                        onClick={this.handleSubmit}
                                                        className="btn btn-done"
                                                    > SUBMIT</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

