
import React from 'react';
import { StoreListConst } from './ConstForm';
import Moment from 'moment'
import { DatetimeFormate } from './constvariable';
import Constants, { publicPage } from './Constants';
import RouteMerchant from './RouteMerchant';

//import { AsyncStorage } from "react-native";
//import AsyncStorage from '@react-native-community/async-storage'

// export default class CustomVariables {
//     static TOKEN_VALUE = '';
//     static FROM_FORGET_PASSWORD = false;   
//     static vehicleId = ''; 
//  }

export function IsLessThanFutureDate(futuredate) {
    try {
        var c = Moment().format(DatetimeFormate.YYYYMMDDdash);
        var f = Moment(futuredate).format(DatetimeFormate.YYYYMMDDdash);
        if (c <= f) {
            return true;
        } else {
            return false;
        }

    } catch (err) {
        return false;
    }
}


export function validateEmail(email) {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase())
}

export function validatePassword(password) {
    const expression = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/;
    return password.match(expression);
}

export function validateUserName(name) {
    const expression = /^[a-zA-Z ]*$/;
    return name.match(expression);
}

export function validateFloat(value) {
    var value = value.toString()
    const expression = /^\d+(\.\d+)$/;
    return Boolean(value.match(expression)) || Boolean(validateInt(value));
}

export function validateInt(value) {
    var value = value.toString()
    const expression = /^[0-9]+$/;
    return value.match(expression);
}

export function validatePhoneNo(phone) {
    const expression = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    //const regex = /^[0-9\b]+$/;
    return phone.match(expression);
}

export function validateOtpNo(otp) {
    const expression = /^[0-9\b]+$/;
    return otp.match(expression);
}

export const StoreDataInStorage = (KEY, VALUE) => {
    try {
        localStorage.setItem(KEY, VALUE);
        console.log("Utils.StoreDataInStorage -> save ",);
    } catch (err) {
        return err;
    }
}

export const RetriveDataFromStorage = (KEY) => {
    try {
        const value = localStorage.getItem(KEY);
        //console.log("Utils.RetriveDataFromStorage -> value ", value );    
        return value;
    } catch (err) {
        return err;
    }
};


export const StoreObjectInStorage = (KEY, VALUE) => {
    try {
        localStorage.setItem(KEY, JSON.stringify(VALUE));
    } catch (err) {
        return err;
    }
}

export const RetriveObjectFromStorage = (KEY) => {
    try {
        var value = JSON.parse(localStorage.getItem(KEY));
        return value;
    } catch (err) {
        return err;
    }
};

export const RemoveFromStorage = (KEY) => {
    localStorage.removeItem(KEY)
};

export const removeAllSpace = (value) => {
    try {
        return String(value).replace(/\s/g, '');
    }
    catch (err) {
        console.log(err);
        return value;
    }
}

export const checkPostalCode = (addressArray) => {
    try {
        for (var strref in addressArray) {
            if (addressArray[strref] == "postal_code") {
                return true
            }
        }
        return false
    }
    catch (err) {
        console.log(err)
        return false
    }
}

export const checkLocality = (addressArray) => {
    try {
        for (var strref in addressArray) {
            if (addressArray[strref] == "locality") {
                return true
            }
        }
        return false
    }
    catch (err) {
        console.log(err)
        return false
    }
}

export function formatPhoneNumber(phoneNumberString) {
    try {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '' + match[1] + '-' + match[2] + '-' + match[3];
        }
        return phoneNumberString;
    }
    catch (err) {
        console.log(err)
        return phoneNumberString
    }
}

export function formatToDigit(phoneNumberString) {
    try {
        if (phoneNumberString) {
            return String(phoneNumberString).replace(/[^a-zA-Z0-9]/g, '').replaceAll(/\s/g, '')
        }
        return ""
    }
    catch (err) {
        console.log(err)
        return ""
    }
}

export function validatePhoneNumber(phoneNumber) {
    try {
        var regExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/;
        var phone = String(phoneNumber).match(regExp);
        return phone
    }
    catch (err) {
        console.log(err)
        return false
    }

}

export function parseTime(s) {
    try {
        var part = s.match(/(\d+):(\d+)(?: )?(am|pm)?/i);
        var hh = parseInt(part[1], 10);
        var mm = parseInt(part[2], 10);
        var ap = part[3] ? part[3].toUpperCase() : null;
        if (ap === "AM") {
            if (hh == 12) {
                hh = 0;
            }
        }
        if (ap === "PM") {
            if (hh != 12) {
                hh += 12;
            }
        }
        return hh;
    }
    catch (err) {
        console.log(err);
        return 39;
    }
}

export function PlusOneHour(s) {
    try {
        var part = s.match(/(\d+):(\d+)(?: )?(am|pm)?/i);
        var hh = parseInt(part[1], 10);
        var mm = parseInt(part[2], 10);
        if (hh == 12) {
            hh = 1;
        }
        else {
            hh = hh + 1
        }
        return hh < 10 ? String("0" + hh + ":00") : String(hh + ":00")
    }
    catch (err) {
        console.log(err);
        return 0;
    }
}

export function removeSpcesFromString(s) {
    try {
        if (s) {
            return String(s).replace(/\s/g, '')
        }
        return s
    }
    catch (e) {
        console.log(e)
        return s
    }
}

export function parseFromTime(value) {
    try {
        var valueSplited = value.split(' ')
        return valueSplited[0] + " " + valueSplited[1]
    }
    catch (err) {
        console.log(err)
        return ""
    }
}

export function SetStoreLevelStatus(value) {
    if (value === StoreListConst.PUBLISHED) {
        return StoreListConst.UPUBLISHED;
    }
    else if (value === StoreListConst.INREVIEW) {
        return StoreListConst.UINREVIEW;
    }
    else if (value === StoreListConst.APPROVED) {
        return StoreListConst.UAPPROVED;
    } else {
        return StoreListConst.UACTIVE;
    }
}

export function TimeToHours(timeString) {
    try {
        const [time, modifier] = timeString.trim().split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (String(modifier).toLowerCase() === String('PM').toLowerCase()) {
            hours = String(parseInt(hours, 10) + 12);
        }
        return { hours: hours, minutes: minutes };
    }
    catch (err) {
        console.log(err);
        return ""
    }
}

export function getTimeRanges(st, et) {
    try {
        const timeInterval = 30, startTime = TimeToHours(st.trim()), endTime = TimeToHours(et.trim())
        let { hours: shour, minutes: sminute } = startTime ? startTime : {}
        let { hours: ehour, minutes: eminute } = endTime ? endTime : {}
        let clock = []

        shour = Number(shour)
        ehour = Number(ehour)
        sminute = sminute ? Number(sminute) : 0
        eminute = eminute ? Number(eminute) : 0

        if (!isNaN(shour) && !isNaN(ehour)) {
            const start = new Date();
            start.setHours(shour, sminute, 0);
            const end = new Date();
            end.setHours(ehour, eminute, 0);

            while (start <= end) {
                let t = start.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' });
                clock.push({
                    value: t, label: t
                })
                start.setMinutes(start.getMinutes() + timeInterval);
            }
            return clock
        }
        return []
    }
    catch (err) {
        console.log("err === ", err)
        return []
    }
}


export function closeNav() {
    if (document.getElementById("mySidenav")) {
        document.getElementById("mySidenav").style.width = "0";
    }
    if (document.getElementById("myCanvasNav")) {
        document.getElementById("myCanvasNav").style.width = "0%";
    }
    if (document.getElementById("myCanvasNav")) {
        document.getElementById("myCanvasNav").style.opacity = "0";
    }
}


export function logoutSession() {
    closeNav()
    for (var ClearObj in Constants) {
        RemoveFromStorage(Constants[ClearObj])
    }
}

export function userSessionManger() {
    try {
        let ischecktoken = true, currentpath = window.location.pathname;

        if (ischecktoken) {
            ischecktoken = !publicPage.includes(currentpath)
        }

        return ischecktoken
    }
    catch (err) {
        console.log(err);
        return false
    }
}

export function ValidTaxFormat(value) {
    try {
        if (value.match(/^(\d+)?([.]?\d{0,1})?$/)) {
            return true;
        } else {
            return false;
        }
    }
    catch (err) {
        console.log(err);
        return false
    }
}