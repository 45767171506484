import { BackEndRequestURL } from '../components/Config/Config.json'

export function PUTData(url, postdata, auth) {
  let BaseURL = BackEndRequestURL + url
  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + auth
      },
      body: JSON.stringify(postdata)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
        //alert(error)
      })
  })
}
