import RouteMerchant from "./RouteMerchant";

const Constants = {
  DEVICE_ID: "DEVICE_ID",
  DEVICE_TYPE: "WEB",
  COMPANY: "MY_COMPANY",
  PUSH_TOKEN: "WEB_PUSHTOKEN",
  LATITUDE: "WEB_LATITUDE",
  LONGITUDE: "WEB_LONGITUDE",
  AUTH_TOKEN: "Token", // token for login
  STOREID: "STOREID", // for store id
  ORDERID: "ORDERID",
  ORDERFLAG: "ORDERFLAG",
  STORE_OBJ: "STORE_OBJ", // for store object
  STORE_PRODUCT_OBJ: "STORE_PRODUCT_OBJ", // for store product
  STROE_ITEM_OBJ: "STROE_ITEM_OBJ", // for store item
  TOPPING_CATEGORY_OBJ: "TOPPING_CATEGORY_OBJ", // for store topping category
  TOPPING_OBJ: "TOPPING_OBJ", // for store topping
  PICKUPTIME_OBJ: "PICKUPTIME_OBJ",
  DELIVERYTIME_OBJ: "DELIVERYTIME_OBJ",
  INVENTORY_OBJ: "INVENTORY_OBJ",
  STORESUPPLIER_OBJ: "STORESUPPLIER_OBJ",
  USER_DETAIL: "USER_DETAIL",
  COUNTRYINFO: "COUNTRYINFO",
  GOOGLEEMAIL: "Email",
  PLATFROM_FLAG: "PLATFROM_FLAG",
  PLAZAID: "PLAZAID",
  SUBSCRIPTIONID: "subscriptionid",
  // TEMPSTORE__OBJ: "TEMPSTORE"
  ISTEMP: "ISTEMP",
  ISPARENT: "ISPARENT",
  PAYMENTTYPE: "stripe_express" // For Payment type switch paypal, stripe_standard, stripe_express
};

export const DEFAULTID = "000000000000000000000000";

export const COMPANYCONST = {
  PLAZACART: "brewers near me",
  BREWERSNEARME: "Brewers near me",
  BREWERSNEARMECAP: "BREWERSNEARME",
  PLAZACARTCOM: "brewersnearme.com",
  BREWERSNEARMECOMCAP: "BREWERSNEARME.COM",
  WWWPLAZACARTCOM: "www.brewersnearme.com",
  PIDEXINC: "Pidex Inc",
  PLAZACARTMAIL: "admin@brewersnearme.com",
  Telephone: "982-8934879",
  PrivacyPolicyURL: "https://www.brewersnearme.com/legal/en/datasharing",
  PrivacyPolicyURL1: "https://brewersnearme.com/preferences/notifications",
  PrivacyPolicyURL2: "https://plazacart.com/delete-my-data",
  PrivacyPolicyURL3: "https://plazacart.com/know-my-data",
  PrivacyPolicyURL4: "https://plazacart.com/dont-sell-data",
  BREWERSNEARMEMAILCAP: "ADMIN@BREWERSNEARME.COM"
}

export const durationMinKey = {
  MIN15: "15",
  MIN30: "30",
  MIN45: "45",
  MIN60: "60",
  CUSTOM: "CUSTOM"
}

export const duratoinMinList = [
  { value: durationMinKey.MIN15, label: "15 min" },
  { value: durationMinKey.MIN30, label: "30 min" },
  { value: durationMinKey.MIN45, label: "45 min" },
  { value: durationMinKey.MIN60, label: "60 min" },
  { value: durationMinKey.CUSTOM, label: "Custom" }
]

export const DateRangeT = {
  FUTUREDAYS: "FUTUREDAYS",
  DATERANGE: "DATERANGE",
  INDEFINITELY: "INDEFINITELY"
}

export const APPOINTMENTH = {
  UPCOMING: "CONFIRMED",
  PENDING: "PENDING",
  PAST: "PAST"
}

export const PAYMENT_TYPES = {
  PAYPAL: "paypal",
  STRIPE_STANDARD: "stripe_standard",
  STRIPE_EXPRESS: "stripe_express"
}

export const CONTACT_US_VISIBLE_MODE = {
  MODE: "mode",
  CONTACT_US_MODE: 1,
  REQUEST_A_DEMO_MODE: 2
}

export const imageStandard = {
  storeBannerW: 1024,
  storeBannerH: 512,
  storeLogoW: 512,
  storeLogoH: 512,
  bulkImageUploadW: 1024,
  bulkImageUploadH: 512,
  couponImageW: 1024,
  couponImageH: 512,
  productImageW: 1024,
  productImageH: 512,
  ImageSize: 3.5
}

export const orderRefreshTime = 1000 * 60

export const TaxFlag = {
  GLOBAL: "global",
  STORE: "store"
}

export const publicPage = [
  RouteMerchant.Default,
  RouteMerchant.Login,
  RouteMerchant.Register,
  RouteMerchant.RegisterNew,
  RouteMerchant.ForgotPassword,
  RouteMerchant.Validateotp,
  RouteMerchant.TermsOfService,
  RouteMerchant.PrivacyPolicy,
  RouteMerchant.AboutUs,
  RouteMerchant.HelpAndSupport,
  RouteMerchant.ContactUs,
  RouteMerchant.Pricing
]

export default Constants;