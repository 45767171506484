import React, { Component } from 'react';
import './ProductTypeList.css';

class ProductTypeList extends Component {
  constructor(props) {
    super(props);
    this.storenavigate =this.storenavigate.bind(this);
  }

  storenavigate(e) {
      
  }

  render() {
    let ProductTypeList = this.props.data
      .map(function (data, index) {
        return (
         <div key={index}>
          <table style = {{width:'100%'}} >
          <tr>
            <th id='th-header'>
            <input type='checkbox' onClick={this.storenavigate}/></th>
            <th>{data.Prouducttype}</th>
            <th></th>
          </tr>
          <tr>
            <td></td>
            <td>{data.Description}</td>
            <td></td>
          </tr>
          </table>
         </div>
        )
      }, this);

     return (
      <div>
      {ProductTypeList}
      </div>
    );
  }
}

export default ProductTypeList;

 /*<input key={data.Prouducttypeid} type='checkbox' onClick={this.storenavigate} checked={data.IsSelected}/></th>
*/