import React from 'react'
import Moment from "moment"
import { DatetimeFormate } from "../Config/constvariable"

function OnHoverIconInfo(props) {
    return <div className="tooltip" style={{ zIndex: "unset" }}>
        {props.title ? props.title : <i className="far fa-question-circle"></i>}
        <span className={"tooltiptext settooltip settooltipbg " + (props.classString ? props.classString : "")}>{props.message}</span>
    </div>
}

export default OnHoverIconInfo


export function BreadcrumbNav({ objArray, index }) {
    try {
        objArray = objArray ? objArray : []
        index = index ? index : 0
        objArray = objArray.slice(0, index + 1);
        return <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                {objArray.map(e => {
                    return <li class="breadcrumb-item"><a>{e}</a></li>
                })}
                {/* <li class="breadcrumb-item active" aria-current="page">Library</li> */}
            </ol>
        </nav>
    }
    catch (err) {
        console.log(err);
        return ""
    }
}

export function KmAndMilesCollection() {
    var dataSet = []
    for (var i = 1; i <= 20; i++) {
        dataSet.push(
            { value: String(i), label: String(i) }
        )
    }
    return dataSet
}

export function KmAndMilesFessCollection() {
    var dataSet = [], fess = 0
    for (var i = 0; i < 80; i++) {
        fess = fess + 0.25
        dataSet.push(
            { value: String(fess), label: Number(fess).toFixed(2) }
        )
    }
    return dataSet
}

export function ExtraMilesCollection() {
    var dataSet = [], extraMiles = 0
    for (var i = 0; i < 12; i++) {
        extraMiles = extraMiles + 0.75
        dataSet.push(
            { value: String(extraMiles), label: Number(extraMiles).toFixed(2) }
        )
    }
    return dataSet
}

export function PutMandatoryfieldSymbol() {
    return <span style={{ color: 'red' }}> *</span>
}


export function DatasetOption() {
    const UiData = [
        {
            "day": "Monday",
            "isopen": false,
            "storehours": []
        },
        {
            "day": "Tuesday",
            "isopen": false,
            "storehours": []
        },
        {
            "day": "Wednesday",
            "isopen": false,
            "storehours": []
        },
        {
            "day": "Thursday",
            "isopen": false,
            "storehours": []
        },
        {
            "day": "Friday",
            "isopen": false,
            "storehours": []
        },
        {
            "day": "Saturday",
            "isopen": false,
            "storehours": []
        },
        {
            "day": "Sunday",
            "isopen": false,
            "storehours": []
        },
    ]

    return UiData
}

export function deliveryDataSet() {
    const UiData = [
        {
            "day": "Monday",
            "isdeliveryenable": false,
            "deliverytimes": []
        },
        {
            "day": "Tuesday",
            "isdeliveryenable": false,
            "deliverytimes": []
        },
        {
            "day": "Wednesday",
            "isdeliveryenable": false,
            "deliverytimes": []
        },
        {
            "day": "Thursday",
            "isdeliveryenable": false,
            "deliverytimes": []
        },
        {
            "day": "Friday",
            "isdeliveryenable": false,
            "deliverytimes": []
        },
        {
            "day": "Saturday",
            "isdeliveryenable": false,
            "deliverytimes": []
        },
        {
            "day": "Sunday",
            "isdeliveryenable": false,
            "deliverytimes": []
        },
    ]

    return UiData
}

export function pickupDataSet() {
    const UiData = [
        {
            "day": "Monday",
            "ispickupenable": false,
            "pickuptimes": []
        },
        {
            "day": "Tuesday",
            "ispickupenable": false,
            "pickuptimes": []
        },
        {
            "day": "Wednesday",
            "ispickupenable": false,
            "pickuptimes": []
        },
        {
            "day": "Thursday",
            "ispickupenable": false,
            "pickuptimes": []
        },
        {
            "day": "Friday",
            "ispickupenable": false,
            "pickuptimes": []
        },
        {
            "day": "Saturday",
            "ispickupenable": false,
            "pickuptimes": []
        },
        {
            "day": "Sunday",
            "ispickupenable": false,
            "pickuptimes": []
        },
    ]

    return UiData
}

export function DatasetDuration() {
    const UiData = [
        {
            "weekday": "Monday",
            "isopen": false,
            "storehours": "09 AM - 05 PM",
            "starttime": "09AM",
            "endtime": "05PM"
        },
        {
            "weekday": "Tuesday",
            "isopen": false,
            "storehours": "09 AM - 05 PM",
            "starttime": "09AM",
            "endtime": "05PM"
        },
        {
            "weekday": "Wednesday",
            "isopen": false,
            "storehours": "09 AM - 05 PM",
            "starttime": "09AM",
            "endtime": "05PM"
        },
        {
            "weekday": "Thursday",
            "isopen": false,
            "storehours": "09 AM - 05 PM",
            "starttime": "09AM",
            "endtime": "05PM"
        },
        {
            "weekday": "Friday",
            "isopen": false,
            "storehours": "09 AM - 05 PM",
            "starttime": "09AM",
            "endtime": "05PM"
        },
        {
            "weekday": "Saturday",
            "isopen": false,
            "storehours": "09 AM - 05 PM",
            "starttime": "09AM",
            "endtime": "05PM"
        },
        {
            "weekday": "Sunday",
            "isopen": false,
            "storehours": "09 AM - 05 PM",
            "starttime": "09AM",
            "endtime": "05PM"
        },
    ]

    return UiData
}

export function isWebSiteValidUrl(urlString) {
    try {
        var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    } catch (err) {
        return false;
    }
}

export function dateFormating(value, returnValue) {
    try {
        if (value) {
            return Moment(value).format(DatetimeFormate.ddN_DD_MN_Y)
        }
        return returnValue
    }
    catch (err) {
        console.log(err);
        return returnValue
    }
}


