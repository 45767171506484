import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import {
    RetriveObjectFromStorage, RetriveDataFromStorage,
    StoreDataInStorage, validateEmail, validateInt,
    removeAllSpace, checkPostalCode, formatPhoneNumber,
    validatePhoneNumber
} from "../../Config/Utils";
import { GET_PRODUCT_CATEGORY, PLAZAID, MERCHANTADDPLAZA_API, GOOGLE_PLACE_KEY, KEY_STRING, PLACE_ID } from '../../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
import SideMenu from "../../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import { GetDataGooglePlace } from '../../../services/GetDataGooglePlace';
import Constvariable from '../../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { GetData } from "../../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { POST_STORE_CATEGORY } from '../../Config/Config.json';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { AddPlazaForm } from '../../Config/HoverMessage'
import { AddPlazaConstForm } from "../../Config/ConstForm"
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class AddPlaza extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            plazaFields: {},
            errors: {},

            parantCategory: [],
            selectedParantCategory: null,

            isCategoryCustomizable: false,
            isActive: false,
            storeId: "",
            plazaId: "",
            categoryId: '',
            isPerent: true,

            selectedAddress: null,

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChangePlaza = this.handleChangePlaza.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            let plazaFields = this.state.plazaFields;
            plazaFields["CountryCode"] = countryInfo.CountryCode
            this.setState({ plazaFields })
        }
    }



    // /////////////// end detail methods ////////////////////////////

    handleChangePlaza(event) {
        let plazaFields = this.state.plazaFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "phonenumber") {
            plazaFields[key] = formatPhoneNumber(value)
        }
        else {
            plazaFields[key] = value
        }
        this.setState({ plazaFields })
    }

    onSelectFile = e => {
        let plazaFields = this.state.plazaFields;
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ src: reader.result })
                plazaFields['imagebase64'] = reader.result
                this.setState({ plazaFields })
                console.log(reader.result)
            }, { orientation: true })
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    handelAddress(addressObj) {
        this.setState({ selectedAddress: addressObj })
        console.log(addressObj)
        let plazaFields = this.state.plazaFields;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace("", addressObj.value.place_id, plazaFields)
        }
        else {
            plazaFields["PostalCode"] = "";
        }
        this.setState({ plazaFields })
    }

    getaddressGooglePlace(authToken, value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        console.log(placeId)
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                console.log("====", result)
                if (responseJson) {
                    var responseData = responseJson;
                    if (responseData && responseData.address_components) {
                        refObj["PostalCode"] = ""
                        for (var obj of responseData.address_components) {
                            if (obj.types && checkPostalCode(obj.types)) {
                                refObj["PostalCode"] = removeAllSpace(obj.short_name)
                                break;
                            }
                        }
                    }
                    else {
                        refObj["PostalCode"] = ""
                    }
                }
                else {
                    refObj["PostalCode"] = ""

                }
                this.setState({ refObj, isActive: false })
            }).catch(err => {
                refObj["PostalCode"] = ""
                this.setState({ refObj, isActive: false })
            });
    }

    validatePlaza() {
        let plazaFields = this.state.plazaFields;
        let errors = {}
        let formIsValid = true;

        if (!plazaFields['PlazaName']) {
            formIsValid = false;
            errors['PlazaName'] = Messages.v_EnterPlazaName;
        }

        if (!plazaFields['Email']) {
            formIsValid = false;
            errors['Email'] = Messages.v_emailId;
        }
        else if (plazaFields["Email"]) {
            if (!validateEmail(plazaFields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }
        if (!plazaFields['phonenumber']) {
            formIsValid = false;
            errors['phonenumber'] = Messages.v_phoneNumber;
        }
        else if (plazaFields["phonenumber"]) {
            if (!validatePhoneNumber(plazaFields["phonenumber"])) {
                formIsValid = false;
                errors["phonenumber"] = Messages.v_phoneNumberFormate;
            }
        }
        if (!this.state.selectedAddress) {
            formIsValid = false;
            errors['Address'] = Messages.v_PalazAddress;
        }
        if (!plazaFields['PostalCode']) {
            formIsValid = false;
            errors['PostalCode'] = Messages.v_EnterPostalCode;
        }

        if (!this.state.src) {
            formIsValid = false;
            errors['src'] = Messages.S_BANNERPlaza;
        }
        this.setState({ errors })
        return formIsValid
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validatePlaza()) {
            postData["Company"] = this.state.plazaFields.PlazaName
            postData["Email"] = this.state.plazaFields.Email
            postData["PostalCode"] = String(this.state.plazaFields.PostalCode).toUpperCase()
            postData["address"] = this.state.selectedAddress ? this.state.selectedAddress.label : ""
            postData["Address"] = this.state.selectedAddress ? this.state.selectedAddress.label : ""
            postData["imagebase64"] = this.state.plazaFields.imagebase64
            postData["CountryCode"] = this.state.plazaFields.CountryCode
            postData["phonenumber"] = this.state.plazaFields.phonenumber

            console.log("==> postdata==", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

            this.postPlaza(authToken, postData)

        }
    }


    postPlaza(authToken, postData) {
        this.setState({ isActive: true })
        var URL = MERCHANTADDPLAZA_API
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log(responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.handalModal()
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }

    handalModal() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModalAddPlaza')

        if (backgroung && modalId) {
            backgroung.style.display = "block"
            modalId.style.display = "block"
        }
    }

    closeModal() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModalAddPlaza')

        if (backgroung && modalId) {
            backgroung.style.display = "none"
            modalId.style.display = "none"
        }
        this.props.history.goBack();
    }

    render() {
        var CounryName = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            CounryName = "(" + countryInfo.CountryName + ")"
        }

        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <div className="modal-background" id="modalbackgroundId"> </div>
                    {/* <div id="loader" className={this.state.loaderClass}></div> */}
                    <Header setHeaderTitle={Constvariable.AddPlaza}></Header>

                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        {/* <div style={{ paddingTop: '10px' }}></div> */}
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">
                                {AddPlazaConstForm.PlazaName} <OnHoverIconInfo
                                    message={AddPlazaForm.PlazaName} />
                            </label>
                            <input
                                type="text"
                                name="PlazaName"
                                className="add-item-box"
                                onChange={this.handleChangePlaza}
                                value={this.state.plazaFields.PlazaName}
                                placeholder={Constvariable.AP_PlazaName}

                            />
                            <div className="errorMsg">
                                {this.state.errors.PlazaName}
                            </div>
                        </div>


                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">
                                {AddPlazaConstForm.PlazaEmail} <OnHoverIconInfo
                                    message={AddPlazaForm.PlazaEmail} />
                            </label>
                            <input
                                type="text"
                                name="Email"
                                className="add-item-box"
                                onChange={this.handleChangePlaza}
                                value={this.state.plazaFields.Email}
                                placeholder={Constvariable.AP_PlazaEmail}

                            />
                            <div className="errorMsg">
                                {this.state.errors.Email}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">
                                {AddPlazaConstForm.PhoneNumber} <OnHoverIconInfo
                                    message={AddPlazaForm.PhoneNumber} />
                            </label>
                            <input
                                type="text"
                                name="phonenumber"
                                maxLength="14"
                                className="add-item-box"
                                onChange={this.handleChangePlaza}
                                value={this.state.plazaFields.phonenumber}
                                placeholder={Constvariable.AP_PhoneNumber}

                            />
                            <div className="errorMsg">
                                {this.state.errors.phonenumber}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">
                                {AddPlazaConstForm.Address} <OnHoverIconInfo
                                    message={AddPlazaForm.Address} />
                            </label>
                            <GooglePlacesAutocomplete
                                apiKey={GOOGLE_PLACE_KEY}
                                selectProps={{
                                    value: this.state.selectedAddress,
                                    onChange: (setAddress) => this.handelAddress(setAddress),
                                    menuPortalTarget: document.body,
                                    isClearable: true,
                                    placeholder: Constvariable.AP_Address,
                                    styles: colourStyles
                                }}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: this.state.plazaFields.CountryCode ? [this.state.plazaFields.CountryCode] : [],
                                    }
                                }}
                            />
                            <div className="errorMsg">
                                {this.state.errors.Address}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">

                            <label className="label-color">
                                {AddPlazaConstForm.PostalCode} {CounryName} <OnHoverIconInfo
                                    message={AddPlazaForm.PostalCode} />
                            </label>
                            <input
                                type="text"
                                name="PostalCode"
                                maxLength="6"
                                className="add-item-box"
                                onChange={this.handleChangePlaza}
                                value={this.state.plazaFields.PostalCode}
                                placeholder={Constvariable.AP_PostalCode}

                            />
                            <div className="errorMsg">
                                {this.state.errors.PostalCode}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">
                                {AddPlazaConstForm.UploadImage} <OnHoverIconInfo
                                    message={AddPlazaForm.UploadImage} />
                            </label>
                            <center>
                                <img
                                    style={{ margin: "10px 0px" }}
                                    className="profilePic"
                                    src={this.state.src} />
                            </center>
                            <div className="col-sm-12" style={{ textAlign: "center" }}>
                                <div className="btn-upload" >
                                    <label className="btn" for="updateCover"
                                        style={{ padding: "4px 0px 0px 0px ", background: Color.THEME_COLOR }}>
                                        {AddPlazaConstForm.Upload}
                                    </label>
                                    <input
                                        type="file"
                                        id="updateCover"
                                        style={{ opacity: "0" }}
                                        data-id="cover"
                                        className="hideAction"
                                        onChange={this.onSelectFile} />
                                </div>
                            </div>
                            <div className="errorMsg">
                                {this.state.errors.src}
                            </div>
                        </div>

                        <div class="modal" id="myModalAddPlaza">
                            <div class="modal-dialog setmodaldilog">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <center>
                                            <h5>{Messages.popupPlazaTitle}</h5>
                                        </center>

                                        <div className="reg_form_main col-sm-10 offset-sm-1" >
                                            <label className="label-color">{Messages.popupPlazaMsg1}</label>
                                            <label className="label-color">{Messages.popupPlazaMsg2}</label>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <label
                                            style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                            data-dismiss="modal"
                                            onClick={() => this.closeModal()}
                                        > Ok</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="bottam_sing">
                        <label
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done"
                        > {AddPlazaConstForm.AddPlaza}</label>
                    </div>
                </LoadingOverlay>
            </section>

        );
    }
}
export default AddPlaza;