import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreDataInStorage, validateInt } from "../../Config/Utils";
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants, { DEFAULTID } from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
import SideMenu from "../../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import Constvariable from '../../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { GetData } from "../../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { GET_INVENTORY, PLAZAID } from '../../Config/Config.json';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { UpdateInventoryForm } from '../../Config/HoverMessage'
import { UpdateInventoryConstForm } from "../../Config/ConstForm";
import { PutMandatoryfieldSymbol } from "../../UtillCustomComponent/UtillCustomComponent";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
};

class UpdateInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            errors: {},
            isActive: false,
            storeId: "",
            plazaId: "",
            inventoryId: "",
            itemId: "",
            isQuntityMinus: true

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleParantCategory = this.handleParantCategory.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            var inventoryObj = RetriveObjectFromStorage(Constants.INVENTORY_OBJ);
            if (inventoryObj) {
                this.autoPopulated(inventoryObj);
            }
        } else {
            this.props.history.goBack()
        }
    }

    autoPopulated(inventoryObj) {
        let userFields = this.state.userFields;
        this.setState({ inventoryId: inventoryObj._id, itemId: inventoryObj.ItemId })
        userFields['itemName'] = inventoryObj.ItemName;
        userFields['AvailableQuantity'] = inventoryObj.AvailableQuantity;
        this.setState({ userFields })
    }


    // /////////////// end detail methods ////////////////////////////

    handleParantCategory = selectedParantCategory => {
        this.setState({ selectedParantCategory })
    }

    handleCategoryCustomizable() {
        this.state.isCategoryCustomizable ? this.setState({ isCategoryCustomizable: false }) : this.setState({ isCategoryCustomizable: true })
    }

    handleQuantityMinus() {
        this.state.isQuntityMinus ? this.setState({ isQuntityMinus: false }) : this.setState({ isQuntityMinus: true });
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["updateQuantity"]) {
            formIsValid = false;
            errors["updateQuantity"] = Messages.v_NewQuantity;
        }
        else if (fields["updateQuantity"]) {
            if (!validateInt(fields["updateQuantity"])) {
                formIsValid = false;
                errors["updateQuantity"] = Messages.v_NewQuantityNumber;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['InventoryId'] = this.state.inventoryId;
            postData['ItemId'] = this.state.itemId;

            postData['UpdateQuantity'] = this.state.userFields.updateQuantity;

            postData['Description'] = this.state.userFields.description ? this.state.userFields.description : "";
            postData['IsIncrease'] = this.state.isQuntityMinus;

            console.log("==> postdata==", postData)
            var plazaId = this.state.plazaId
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            if (this.state.storeId && this.state.inventoryId && this.state.itemId) {
                var storeId = this.state.storeId;
                this.putStoreCategory(authToken, postData, storeId, plazaId)
            }

        }
    }

    putStoreCategory(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_INVENTORY + "/" + storeId + "?" + PLAZAID + plazaId;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    render() {

        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.AdjustQuantity}></Header>

                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label style={{ textAlign: "center" }} className="label-color">{Constvariable.ProductName}: &nbsp;
                                <span>
                                    {this.state.userFields.itemName}
                                </span>
                            </label>
                        </div>
                        {/* <div style={{ paddingTop: '10px' }}></div> */}
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.itemName}
                        </p> */}
                            <label className="label-color">{Constvariable.OriginalQuantity}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UpdateInventoryForm.OriginalQuantity} /></label>
                            <input
                                style={{ color: Color.THEME_COLOR }}
                                type="text"
                                name="AvailableQuantity"
                                className='add-item-box'
                                value={this.state.userFields.AvailableQuantity}
                            // onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.AvailableQuantity}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2" >
                            {/* <p className="col-sm-12 textcolor" for="radiusUnit">
                            {Constvariable.ContinueSelling}
                        </p> */}
                            <div className="checkbox-input-div">
                                <Checkbox
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={this.state.isQuntityMinus}
                                    delay={0}
                                    backAnimationDuration={0}
                                    tickAnimationDuration={0}
                                    onChange={() => this.handleQuantityMinus()} />
                                <span className="Checkbox-label" style={{ color: Color.THEME_COLOR }}>
                                    {Constvariable.QuantityAdd}
                                    &nbsp;
                                    <OnHoverIconInfo
                                        message={UpdateInventoryForm.QuantityAdd} />
                                </span>
                                &nbsp;
                                &nbsp;
                                <Checkbox
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={!this.state.isQuntityMinus}
                                    delay={0}
                                    backAnimationDuration={0}
                                    tickAnimationDuration={0}
                                    onChange={() => this.handleQuantityMinus()} />
                                <span className="Checkbox-label" style={{ color: Color.THEME_COLOR }}>
                                    {Constvariable.QuantityMinus}
                                    &nbsp;
                                    <OnHoverIconInfo
                                        message={UpdateInventoryForm.QuantityMinus} />
                                </span>
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.itemName}
                        </p> */}
                            <label className="label-color">{UpdateInventoryConstForm.NewQuantity}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UpdateInventoryForm.NewQuantity} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="updateQuantity"
                                className='add-item-box'
                                placeholder={Constvariable.NewQuantity}
                                value={this.state.userFields.updateQuantity}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.updateQuantity}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="radiusUnit">
                            {Constvariable.ItemDescription}
                        </p> */}
                            <label className="label-color">{UpdateInventoryConstForm.ProductDescription}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UpdateInventoryForm.ProductDescription} />
                            </label>
                            <textarea
                                type="text"
                                name="description"
                                className='add-item-textarea'
                                placeholder={Constvariable.ProductDescription}
                                value={this.state.userFields.description}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.description}
                            </div>
                        </div>
                    </div>
                    <div className="reg_form_main offset-sm-3 col-sm-6">
                        <center>
                            <div className="errorMsg">
                                {this.state.errors.ErrorC}
                            </div>
                        </center>
                    </div>
                    <div className="bottam_sing">
                        <label
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done"
                        > Update</label>
                    </div>
                </LoadingOverlay>
            </section>

        );
    }
}
export default UpdateInventory;