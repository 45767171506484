import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreDataInStorage } from "../../Config/Utils";
import { GET_PRODUCT_CATEGORY, PLAZAID } from '../../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants, { DEFAULTID } from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
import SideMenu from "../../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import Constvariable from '../../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { GetData } from "../../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { POST_STORE_CATEGORY, MY_STORE_API, Tax_API } from '../../Config/Config.json';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { CategoryForm } from '../../Config/HoverMessage'
import { CategoryConstForm } from "../../Config/ConstForm";
import { PutMandatoryfieldSymbol } from "../../UtillCustomComponent/UtillCustomComponent";
import Moment, { min } from 'moment';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

const taxSelectStyle = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" }),
    input: styles => ({ ...styles, padding: "2px 5px" }),
    singleValue: styles => ({ ...styles, padding: "2px 5px", }),
    placeholder: styles => ({ ...styles, padding: "2px 5px" }),
    multiValueRemove: (styles, state) => {
        if (state.data && state.data.isdefault) {
            return { ...styles, display: "none" }
        }
        return styles
    }
}

const T = [
    {
        "_id": "648851683044e3fa2f93826b",
        "TaxType": "HST ",
        "Taxname": "Sales Tax ",
        "TaxRate": 13.0,
        "Status": "ACTIVE",
        "isdefault": true,
        "ValidFromDate": "2023-05-31T18:30:00Z",
        "ValidToDate": "2024-09-30T18:30:00Z",
    },
    {
        "_id": "648b1dd2d13640f4eac9095d",
        "TaxType": "GST",
        "Taxname": "Sales Tax update",
        "TaxRate": 10.0,
        "Status": "ACTIVE",
        "isdefault": false,
        "ValidFromDate": "2023-05-31T18:30:00Z",
        "ValidToDate": "2024-09-30T18:30:00Z"
    }
]

class AddProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            errors: {},

            parantCategory: [],
            selectedParantCategory: null,

            isCategoryCustomizable: false,
            isActive: false,
            storeId: "",
            plazaId: "",
            categoryId: '',
            isPerent: true,

            StateTaxList: [],
            selectedTax: []

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleParantCategory = this.handleParantCategory.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            this.getProductCatagory(authToken, storeId, plazaId)
        }
        else {
            this.props.history.goBack()
        }
    }

    getProductCatagory(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} })
        var URL = GET_PRODUCT_CATEGORY.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.length > 0) {
                        var responseData = responseData.filter(e => e.ParentCategoryID === "000000000000000000000000")
                        this.setState({ parantCategory: responseData });
                        // this.setState({ loaderClass: false });
                    }

                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
                this.getTax(authToken, storeId)
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
                this.getTax(authToken, storeId)
            });
    }

    getTax(authToken, storeId) {
        this.setState({ isActive: true, errors: {} });
        var URL = MY_STORE_API + "/" + storeId + "/" + Tax_API;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    responseData = responseData ? responseData : []
                    let defaultTax = responseData.filter(e => e.isdefault);
                    this.setState({
                        StateTaxList: responseData,
                        selectedTax: defaultTax
                    }, () => {
                        var storeProductobj = RetriveObjectFromStorage(Constants.STORE_PRODUCT_OBJ);
                        if (storeProductobj) {
                            this.autoPopulated(storeProductobj)
                        }
                    })
                }
                else {
                    let errors = {};
                    errors["masterMsg"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })



                // //  demo code //
                // let t = []
                // for (var i = 0; i < 1; i++) {
                //     t.push(...T)
                // }
                // let rs = t
                // let defaultTax = rs.filter(e => e.isdefault);
                // this.setState({
                //     StateTaxList: rs,
                //     selectedTax:defaultTax
                // },()=>{
                //     var storeProductobj = RetriveObjectFromStorage(Constants.STORE_PRODUCT_OBJ);
                //     if (storeProductobj) {
                //         this.autoPopulated(storeProductobj)
                //     }
                // })
                // // this.getProductTaxes(authToken, storeId)
                // ///

            }).catch(error => {
                let errors = {};
                errors["masterMsg"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    autoPopulated(storeProductobj) {
        let userFields = this.state.userFields;
        this.setState({ categoryId: storeProductobj._id })
        if (storeProductobj.ParentCategoryID === "000000000000000000000000") {
            this.setState({ isPerent: false });
        }

        userFields['productCategory'] = storeProductobj.Category_name;
        this.setState({ selectedParantCategory: this.state.parantCategory.filter(e => e._id === storeProductobj.ParentCategoryID)[0] })

        storeProductobj.IsCategoryCustomizable === 'True' ? this.setState({ isCategoryCustomizable: true }) : this.setState({ isCategoryCustomizable: false })
        userFields['customizationMessage'] = storeProductobj.CustomizationMessage;
        this.setState({ userFields })

        if (storeProductobj.taxes) {
            let { StateTaxList, selectedTax } = this.state;
            let selectedTaxLs = [...selectedTax, ...StateTaxList.filter(e => storeProductobj.taxes.some(e1 => e._id == e1.taxid))]
            console.log(selectedTaxLs)
            this.setState({ selectedTax: selectedTaxLs })
        }
    }


    // /////////////// end detail methods ////////////////////////////

    handleParantCategory = selectedParantCategory => {
        this.setState({ selectedParantCategory })

        if (selectedParantCategory) {
            this.selectTaxes(selectedParantCategory)
        }
        else {
            this.setState({ selectedTax: [] })
        }
    }

    selectTaxes(obj) {
        let { StateTaxList } = this.state;
        let list = obj.taxes ? obj.taxes : []
        let list1 = StateTaxList.filter(e => list.some(e1 => e1.taxid == e._id) || e.isdefault)
        this.setState({ selectedTax: list1 })
    }

    handleCategoryCustomizable() {
        this.state.isCategoryCustomizable ? this.setState({ isCategoryCustomizable: false }) : this.setState({ isCategoryCustomizable: true })
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    handleMasterCat(value, obj) {
        if (obj.action == "remove-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                this.setState({ selectedTax: value })
            }
        }
        else if (obj.action == "pop-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                this.setState({ selectedTax: value })
            }
        }
        else {
            value = value ? value : [];
            this.setState({ selectedTax: value })
        }
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["productCategory"]) {
            formIsValid = false;
            errors["productCategory"] = Messages.v_productCategory;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['ProductCategory'] = this.state.userFields.productCategory;
            postData['ApplicationId'] = this.state.storeId;
            postData['ParantCategoryId'] = this.state.selectedParantCategory ? this.state.selectedParantCategory._id : "000000000000000000000000";
            postData['DeviceId'] = "";
            postData['DeviceType'] = "";
            postData['IsCategoryCustomizable'] = this.state.isCategoryCustomizable;
            postData['CustomizationMessage'] = this.state.userFields.customizationMessage ? this.state.userFields.customizationMessage : "";

            let selectedTaxList = this.state.selectedTax.filter(e => !e.isdefault)
            postData["taxes"] = selectedTaxList.map(e => e._id)

            console.log("==> postdata==", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            var plazaId = this.state.plazaId
            if (this.state.categoryId != '' && this.state.categoryId) {
                postData['CategoryId'] = this.state.categoryId;
                this.putStoreCategory(authToken, postData, plazaId)
            }
            else {
                this.postStoreCategory(authToken, postData, plazaId)
            }

        }
    }


    postStoreCategory(authToken, postData, plazaId) {
        this.setState({ isActive: true, errors: {} })
        var URL = POST_STORE_CATEGORY + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false });
    }


    putStoreCategory(authToken, postData, plazaId) {
        this.setState({ isActive: true, errors: {} })
        var URL = POST_STORE_CATEGORY + "?" + PLAZAID + plazaId;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false });
    }

    render() {
        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner">
                    {/* <div id="loader" className={this.state.loaderClass}></div> */}
                    <Header setHeaderTitle={this.state.categoryId ? CategoryConstForm.UpdateProductsCategory : CategoryConstForm.AddProductsCategory}></Header>
                    {/* <div className="registed_tab_part">
                    <div className="container">

                        <div className="same_cantant">
                            <div className="registed_form_tab">
                                <div id="part1">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">

                                                <div className="reg_form_main" style={{ marginTop: "30px" }}>
                                                    <input
                                                        //  value={selectedOption}
                                                        onChange={this.handleChange}
                                                        //  options={options}
                                                        placeholder="Enter Product Name"
                                                        style={{ width: "450px" }}
                                                    />

                                                </div>

                                                <div className="reg_form_main" style={{ marginTop: "30px", width: "100px" }}>
                                                    <input
                                                        //  value={selectedOption}
                                                        onChange={this.handleChange}
                                                        //  options={options}
                                                        placeholder="Enter Product Price"
                                                        style={{ width: "450px" }}
                                                    />

                                                </div>



                                                <div className="reg_form_main" style={{ marginTop: "30px" }}>
                                                    <Select  menuPortalTarget={document.body} 
                                                        //  value={selectedOption}
                                                        onChange={this.handleChange}
                                                        //  options={options}
                                                        placeholder="Product Category"
                                                    />

                                                </div>
                                                <br></br>

                                                <div className="reg_form_main" style={{ marginTop: "30px" }}>
                                                    <textarea
                                                        //  value={selectedOption}
                                                        onChange={this.handleChange}
                                                        //  options={options}
                                                        placeholder="Additional Info"
                                                    />

                                                </div>

                                                <div>

                                                    <label style={{ marginTop: "30px" }}>Add Product Image</label>
                                                    <img
                                                        className="profilePic"
                                                        src={this.state.src} />
                                                    <input
                                                        type="file"
                                                        id="updateProfile"
                                                        data-id="profile"
                                                        className="hideAction"
                                                        //onClick={this.handelModal}
                                                        onChange={this.onSelectFile}
                                                    />
                                                </div>


                                                <div className="bottam_sing">
                                                    <label
                                                        style={{ background: Color.THEME_COLOR }}
                                                        for="step2"
                                                        id="continue-step2"
                                                        onClick={this.handleAdd}
                                                        className="btn btn-done"
                                                    > Add Product</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        {/* <div style={{ paddingTop: '10px' }}></div> */}
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.itemName}
                        </p> */}
                            <label className="label-color">{CategoryConstForm.ProductCategory}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={CategoryForm.ProductCategory} /><PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="productCategory"
                                className='add-item-box'
                                placeholder={Constvariable.ProductCategory}
                                value={this.state.userFields.productCategory}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.productCategory}
                            </div>
                        </div>

                        {this.state.isPerent &&
                            <div className="reg_form_main col-sm-8 offset-sm-2">
                                {/* <p className="col-sm-12 textcolor" for="radiusUnit">
                            {Constvariable.ProductType}
                        </p> */}
                                <label className="label-color">{CategoryConstForm.ParentCategory}
                                    &nbsp;
                                    <OnHoverIconInfo
                                        message={CategoryForm.ParentCategory} />
                                </label>
                                <Select menuPortalTarget={document.body}
                                    value={this.state.selectedParantCategory}
                                    onChange={this.handleParantCategory}
                                    options={this.state.parantCategory}
                                    styles={colourStyles}
                                    placeholder={Constvariable.ParantCategory}
                                    className="select-input"
                                    getOptionLabel={(option) => option.Category_name}
                                    getOptionValue={(option) => option._id}
                                />
                                <div className="errorMsg">
                                    {this.state.errors.parantCategory}
                                </div>
                            </div>}

                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <div className="checkbox-input-div">
                                <Checkbox
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={this.state.isCategoryCustomizable}
                                    delay={0}
                                    backAnimationDuration={0}
                                    tickAnimationDuration={0}
                                    onChange={() => this.handleCategoryCustomizable()} />
                                <span className="Checkbox-label" style={{ color: Color.THEME_COLOR }}>
                                    {Constvariable.IsCategoryCustomizable}
                                    &nbsp;
                                    <OnHoverIconInfo
                                        message={CategoryForm.CategoryCustomizable} />
                                </span>
                            </div>
                            <div className="errorMsg">
                                {this.state.errors.email}
                            </div>
                        </div> */}

                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{CategoryConstForm.CustomizationMessage}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={CategoryForm.CustomizationMessage} /></label>
                            <input
                                type="text"
                                name="customizationMessage"
                                className='add-item-box'
                                placeholder={Constvariable.CustomizationMessage}
                                value={this.state.userFields.customizationMessage}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.customizationMessage}
                            </div>
                        </div> */}

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.itemName}
                        </p> */}
                            <label className="label-color">{CategoryConstForm.ApplicableTax}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={CategoryForm.ApplicableTax} /></label>
                            <Select
                                value={this.state.selectedTax}
                                // menuPlacement="top"
                                menuPortalTarget={document.body}
                                onChange={(value, obj) => this.handleMasterCat(value, obj)}
                                isClearable={false}
                                options={this.state.StateTaxList}
                                styles={taxSelectStyle}
                                placeholder={Constvariable.SelectTaxes}
                                className="select-input"
                                // isOptionDisabled={(option) => option.isdefault}
                                isMulti={true}
                                getOptionLabel={(option) => option.Taxname + " - " + option.TaxRate + " %"}
                                getOptionValue={(option) => option._id} />
                            <div className="errorMsg">
                                {this.state.errors.selectedTax}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <center>
                                <div className="errorMsg">
                                    {this.state.errors.ErrorC}
                                </div>
                            </center>
                        </div>
                    </div>
                    <div className="bottam_sing">
                        <label
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done"
                        > {this.state.categoryId ? CategoryConstForm.btn_UpdateProductsCategory : CategoryConstForm.btn_AddProductsCategory}</label>
                    </div>
                </LoadingOverlay>
            </section>

        );
    }
}
export default AddProducts;