import React, { Component } from 'react';
import './ProductList.css';
import Color from '../Config/Color';
import Checkbox from 'react-simple-checkbox';

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  storenavigate(e) {
    window.location.href = "/OrderDetails";
  }

  render() {
    let ProductList = this.props.data
      .map(function (data, index) {
        return (
          <div key={index}>
            <div className='row'>
              <div className="col-sm">
                {(index + 1)}
              </div>
              <div class="col-sm">
                {data.ItemName}
              </div>
              <div class="col-sm">
                {data.ItemQuantity}
              </div>
              <div class="col-sm">
                $ {Number.parseFloat(data.TotalItemPrice).toFixed(2)}
              </div>
              <div class="col-sm">
                <Checkbox
                  color={Color.THEME_COLOR}
                  size={2}
                  tickSize={2}
                  checked={this.state.checked}
                  delay={0}
                  backAnimationDuration={0}
                  tickAnimationDuration={0}
                  onChange={this.handleCheck}></Checkbox>
              </div>
            </div>
          </div>
        )
      }, this);

    return (
      <div>
        {ProductList}
      </div>
    );
  }
}

export default ProductList;