import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreDataInStorage, validateInt, validateFloat } from "../../Config/Utils";
import { GET_PRODUCT_CATEGORY } from '../../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants,{DEFAULTID} from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
import SideMenu from "../../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import Constvariable, { DatetimeFormate } from '../../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { GetData } from "../../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { POST_STORE_CATEGORY, GET_STORE_SUPPLIER, SUPPLIER, GET_STORE_ITEM, GET, UPDATE, PLAZAID } from '../../Config/Config.json';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import Moment from "moment";
import './PurchaseOrder.css'
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { AddPurchaseOrderForm } from '../../Config/HoverMessage'
import { AddPurchaseOrderConstForm } from "../../Config/ConstForm"

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class AddPurchaseOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            productFields: {},
            errors: {},
            itemErrors: {},

            isCategoryCustomizable: false,
            isActive: false,
            storeId: "",
            categoryId: '',
            plazaId: "",

            storeSupplierList: [],
            seletectSupplier: null,
            storeItemList: [],
            selecteItem: null,
            productList: [],
            purchaseOrderId: ""

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleSupplier = this.handleSupplier.bind(this);
        this.handleItem = this.handleItem.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeProduct = this.handleChangeProduct.bind(this)
        this.handleAddPoduct = this.handleAddPoduct.bind(this)
        this.handelOnBlur = this.handelOnBlur.bind(this)

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            this.getStoreSupplier(authToken, storeId, plazaId)
        }
    }

    getStoreSupplier(authToken, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + storeId + SUPPLIER + "?" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.length > 0) {
                        this.setState({ storeSupplierList: responseData });
                    }
                    else {
                        // alert(Messages.NO_DATA);
                    }
                    this.getMyStoreItem(authToken, storeId, plazaId)
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                // this.setState({ isActive: false })
            });
    }

    getMyStoreItem(authToken, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_ITEM.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ storeItemList: responseData });
                        // this.setState({ loaderClass: false });
                    }
                    else {
                        // alert(Messages.NO_DATA);
                    }
                    let search = window.location.search;
                    let params = new URLSearchParams(search);

                    if (params.get('id') !== null && (params.get('id').length > 0)) {
                        var id = params.get('id');
                        this.setState({ purchaseOrderId: id })
                        this.getPurchaseOrderDetail(authToken, storeId, id, plazaId)
                    }
                    else {
                        this.setState({ isActive: false })
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                // this.setState({ isActive: false })
            });
    }

    getPurchaseOrderDetail(authToken, storeId, id, plazaId) {
        // this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + storeId + "/" + GET + "/" + id + "?" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        let detailObj = responseData;
                        let userFields = this.state.userFields;

                        // postData['SupplierId'] = this.state.seletectSupplier._id;
                        this.setState({ seletectSupplier: this.state.storeSupplierList.filter(e => detailObj.SupplierId === e._id)[0] })
                        userFields['Paymentterms'] = detailObj.Paymentterms;
                        userFields['Suppliercurrency'] = detailObj.Suppliercurrency;
                        userFields['NotetoSupplier'] = detailObj.NotetoSupplier;
                        if (detailObj.Shipmentdetails) {
                            userFields['EstimateArrival'] = Moment.utc(detailObj.Shipmentdetails.EstimateArrival).years() > 1500 ?
                                Moment.utc(detailObj.Shipmentdetails.EstimateArrival).format(DatetimeFormate.YYYYMMDDdash) : ""
                            userFields['TrackingNumber'] = detailObj.Shipmentdetails.TrackingNumber
                            userFields['ShipingCenter'] = detailObj.Shipmentdetails.ShipingCenter
                        }
                        this.setState({ productList: detailObj.Products ? detailObj.Products : [] })

                        if (detailObj.CostSummary) {
                            userFields['TotalTax'] = detailObj.CostSummary.TotalTax;
                            userFields['ShippingCharge'] = Number(detailObj.CostSummary.ShippingCharge).toFixed(2);
                            userFields['Adjustment'] = detailObj.CostSummary.CostAdjustments[0] ? detailObj.CostSummary.CostAdjustments[0].Adjustment : "";
                            userFields['Amount'] = detailObj.CostSummary.CostAdjustments[0] ? detailObj.CostSummary.CostAdjustments[0].Amount : 0.0;
                            this.setState({ TotalValue: Number(detailObj.CostSummary.Total).toFixed(2) })
                        }


                        this.setState({ userFields })
                    }
                }
                this.setState({ isActive: false })
            });
    }


    // /////////////// end detail methods ////////////////////////////

    handleSupplier = seletectSupplier => {
        this.setState({ seletectSupplier })
    }

    handleItem = selecteItem => {
        var productList = this.state.productList.filter(e1 => e1.Productid === selecteItem._id)[0]
        let productFields = this.state.productFields;
        if (productList) {

            productFields['SupplierSKU'] = productList.SupplierSKU
            productFields['Quantity'] = productList.Quantity
            productFields['Cost'] = productList.Cost
            productFields['ProductTax'] = productList.Tax

        }
        else {
            productFields['SupplierSKU'] = ""
            productFields['Quantity'] = ""
            productFields['Cost'] = ""
            productFields['ProductTax'] = ""
        }
        this.setState({ productFields })
        this.setState({ selecteItem })
    }

    handleChangeProduct(event) {
        let productFields = this.state.productFields;
        let key = event.target.name
        let value = event.target.value
        productFields[key] = value
        this.setState({ productFields })
    }

    handelOnBlur(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (Number(value)) {
            userFields[key] = Number(value).toFixed(2)
        }
        this.setState({ userFields })
    }

    validateProduct() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.productFields;

        if (!this.state.selecteItem) {
            formIsValid = false;
            errors["selecteItem"] = Messages.v_selecteItem;
        }

        if (!fields['SupplierSKU']) {
            formIsValid = false;
            errors["SupplierSKU"] = Messages.v_SupplierSKU;
        }

        if (!fields['Quantity']) {
            formIsValid = false;
            errors["Quantity"] = Messages.v_Quantity;
        }
        else if (fields['Quantity']) {
            if (!validateInt(fields['Quantity'])) {
                formIsValid = false;
                errors["Quantity"] = Messages.v_NewQuantityNumber;
            }
        }

        if (!fields['Cost']) {
            formIsValid = false;
            errors["Cost"] = Messages.v_Cost;
        }
        else if (fields['Cost']) {
            if (!validateFloat(fields['Cost'])) {
                formIsValid = false;
                errors["Cost"] = Messages.v_Costnum;
            }
        }

        if (!fields['ProductTax']) {
            formIsValid = false;
            errors["ProductTax"] = Messages.v_ProductTax;
        }
        else if (fields['ProductTax']) {
            if (!validateFloat(fields['ProductTax'])) {
                formIsValid = false;
                errors["ProductTax"] = Messages.v_ProductTaxnum;
            }
        }

        this.setState({ itemErrors: errors });
        return formIsValid;
    }

    handleAddPoduct(e) {
        if (this.validateProduct()) {
            var productList = this.state.productList
            let productFields = this.state.productFields;
            var subtotel = parseFloat(productFields.Quantity) * parseFloat(productFields.Cost)
            var tex = subtotel * parseFloat(productFields.ProductTax);

            var Products = {
                "Productid": this.state.selecteItem._id,
                "Product": this.state.selecteItem.ItemName,
                "SupplierSKU": productFields.SupplierSKU,
                "Quantity": parseFloat(productFields.Quantity),
                "Cost": parseFloat(productFields.Cost),
                "Tax": parseFloat(productFields.ProductTax),
                "Total": subtotel + tex
            }
            var checkIndex = this.state.productList.findIndex(e => e.Productid === this.state.selecteItem._id)
            if (checkIndex === -1) {
                productList.push(Products)
            }
            else {
                productList[checkIndex] = Products
            }

            console.log('======================>product', Products)
            this.setState(({ productList: productList }))
        }

    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })

        var subTotle = 0.0;
        this.state.productList.forEach(e => {
            subTotle = subTotle + e.Total;
        })
        var finelTotelTex = subTotle * parseFloat(this.state.userFields.TotalTax);
        var FinelTotel = subTotle + finelTotelTex + parseFloat(this.state.userFields.ShippingCharge) - parseFloat(this.state.userFields.Amount ? this.state.userFields.Amount : 0);
        userFields.Total = FinelTotel;
        if (!isNaN(FinelTotel)) {
            this.setState({ TotalValue: Number(FinelTotel).toFixed(2) })
        }

    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!this.state.seletectSupplier) {
            formIsValid = false;
            errors["seletectSupplier"] = Messages.v_seletectSupplier;
        }

        if (!fields['Paymentterms']) {
            formIsValid = false;
            errors["Paymentterms"] = Messages.v_Paymentterms;
        }


        if (!fields['Suppliercurrency']) {
            formIsValid = false;
            errors["Suppliercurrency"] = Messages.v_Suppliercurrency;
        }

        // if (!fields['EstimateArrival']) {
        //     formIsValid = false;
        //     errors["EstimateArrival"] = Messages.v_productCategory;
        // }
        // if (!fields['TrackingNumber']) {
        //     formIsValid = false;
        //     errors["TrackingNumber"] = Messages.v_productCategory;
        // }
        if (!fields['ShipingCenter']) {
            formIsValid = false;
            errors["ShipingCenter"] = Messages.v_ShipingCenter;
        }

        if (this.state.productList.length == 0) {
            formIsValid = false;
            errors["oneProduct"] = Messages.v_oneProduct;
        }
        // if (!fields['TotalTax']) {
        //     formIsValid = false;
        //     errors["TotalTax"] = Messages.v_TotalTax;
        // }
        if (fields['TotalTax']) {
            if (!validateFloat(fields['TotalTax'])) {
                formIsValid = false;
                errors["TotalTax"] = Messages.v_TotalTaxnum;
            }
        }

        if (!fields['ShippingCharge']) {
            formIsValid = false;
            errors["ShippingCharge"] = Messages.v_ShippingCharge;
        }
        else if (fields['ShippingCharge']) {
            if (!validateFloat(fields['ShippingCharge'])) {
                formIsValid = false;
                errors["ShippingCharge"] = Messages.v_ShippingChargenum;
            }
        }

        // if (!fields['Adjustment']) {
        //     formIsValid = false;
        //     errors["Adjustment"] = Messages.v_ShippingChargenum;
        // }
        // if (!fields['Amount']) {
        //     formIsValid = false;
        //     errors["Amount"] = Messages.v_productCategory;
        // }
        if (fields['Amount']) {
            if (!validateFloat(fields['Amount'])) {
                formIsValid = false;
                errors["Amount"] = Messages.v_Amount;
            }
        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['SupplierId'] = this.state.seletectSupplier._id;
            postData['Paymentterms'] = this.state.userFields.Paymentterms;
            postData['Suppliercurrency'] = this.state.userFields.Suppliercurrency;
            postData['NotetoSupplier'] = this.state.userFields.NotetoSupplier ? this.state.userFields.NotetoSupplier : "";
            postData['Shipmentdetails'] = {
                "EstimateArrival": this.state.userFields.EstimateArrival ? this.state.userFields.EstimateArrival : "01-01-0001",
                "TrackingNumber": this.state.userFields.TrackingNumber ? this.state.userFields.TrackingNumber : "",
                "ShipingCenter": this.state.userFields.ShipingCenter
            }
            postData['Products'] = this.state.productList

            // var totelTax = 0.0;
            var subTotle = 0.0;

            this.state.productList.forEach(e => {
                // totelTax = totelTax + e.ProductTax;
                subTotle = subTotle + e.Total;
            })

            postData['CostSummary'] = {
                "TotalTax": parseFloat(this.state.userFields.TotalTax),
                "SubTotal": subTotle,
                "ShippingCharge": parseFloat(this.state.userFields.ShippingCharge),
                "CostAdjustments": [
                    {
                        "Adjustment": this.state.userFields.Adjustment ? this.state.userFields.Adjustment : "",
                        "Amount": parseFloat(this.state.userFields.Amount ? this.state.userFields.Amount : 0)
                    }
                ],
                // "Total": 12
            }
            var FinelTotel = 0.0;
            var finelTotelTex = postData['CostSummary'].SubTotal * postData['CostSummary'].TotalTax;


            FinelTotel = postData['CostSummary'].SubTotal + finelTotelTex + parseFloat(postData['CostSummary'].ShippingCharge) - parseFloat(this.state.userFields.Amount ? this.state.userFields.Amount : 0);
            postData['CostSummary'].Total = FinelTotel;

            console.log("==> postdata==", postData)

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            var storeId = this.state.storeId;
            var plazaId = this.state.plazaId
            if (this.state.purchaseOrderId && this.state.purchaseOrderId != '') {
                this.putPurchaseOrder(authToken, postData, storeId, plazaId)
            }
            else {
                this.postPurchaseOrder(authToken, postData, storeId, plazaId)
            }

        }
    }


    postPurchaseOrder(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + storeId + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }


    putPurchaseOrder(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + storeId + "/" + UPDATE + "/" + this.state.purchaseOrderId + "?" + PLAZAID + plazaId;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log(responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }

    render() {
        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = ""
            /* "(" + countryInfo.Currency + ")" + countryInfo.Symbol */
        }

        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    {/* <div id="loader" className={this.state.loaderClass}></div> */}
                    <Header setHeaderTitle={Constvariable.AddPurchaseOrder}></Header>

                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        {/* <div style={{ paddingTop: '10px' }}></div> */}
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="radiusUnit">
                            {Constvariable.ParantCategory}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.Supplier}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.Supplier} />
                            </label>
                            <Select menuPortalTarget={document.body}
                                value={this.state.seletectSupplier}
                                onChange={this.handleSupplier}
                                options={this.state.storeSupplierList}
                                styles={colourStyles}
                                placeholder={Constvariable.Supplier_select}
                                className="select-input"
                                getOptionLabel={(option) => option.Supplier}
                                getOptionValue={(option) => option._id}
                            />
                            <div className="errorMsg">
                                {this.state.errors.seletectSupplier}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.Paymentterms}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.PaymentTerms}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.PaymentTerms} />
                            </label>
                            <input
                                type="text"
                                name="Paymentterms"
                                className='add-item-box'
                                placeholder={Constvariable.Paymentterms}
                                value={this.state.userFields.Paymentterms}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.Paymentterms}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.Suppliercurrency}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.SupplierCurrency}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.SupplierCurrency} />
                            </label>
                            <input
                                type="text"
                                name="Suppliercurrency"
                                className='add-item-box'
                                placeholder={Constvariable.Suppliercurrency}
                                value={this.state.userFields.Suppliercurrency}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.Suppliercurrency}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.NotetoSupplier}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.NotetoSupplier}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.NotetoSupplier} />
                            </label>
                            <input
                                type="text"
                                name="NotetoSupplier"
                                className='add-item-box'
                                placeholder={Constvariable.NotetoSupplier}
                                value={this.state.userFields.NotetoSupplier}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.NotetoSupplier}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {Constvariable.Shipmentdetails}
                            </p>
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.EstimateArrival}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.EstimateArrival}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.EstimateArrival} />
                            </label>
                            <input
                                type="date"
                                name="EstimateArrival"
                                className='add-item-box'
                                placeholder={Constvariable.EstimateArrival}
                                value={this.state.userFields.EstimateArrival}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.EstimateArrival}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.TrackingNumber}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.TrackingNumber}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.TrackingNumber} />
                            </label>
                            <input
                                type="text"
                                name="TrackingNumber"
                                className='add-item-box'
                                placeholder={Constvariable.TrackingNumber}
                                value={this.state.userFields.TrackingNumber}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.TrackingNumber}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.ShipingCenter}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.ShippingCenter}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.ShippingCenter} />
                            </label>
                            <input
                                type="text"
                                name="ShipingCenter"
                                className='add-item-box'
                                placeholder={Constvariable.ShipingCenter}
                                value={this.state.userFields.ShipingCenter}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.ShipingCenter}
                            </div>
                        </div>



                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {Constvariable.Products}
                                <label
                                    style={{ background: Color.THEME_COLOR, margin: "0px", marginBottom: "4px", padding: "4px", float: "right" }}
                                    for="step5"
                                    // id="continue-step5"
                                    onClick={this.handleAddPoduct}
                                    className="btn btn-done"
                                > {Constvariable.addItem}</label>
                            </p>
                            <div className="errorMsg">
                                {this.state.errors.oneProduct}
                            </div>
                        </div>

                        {this.state.productList.length > 0 &&
                            <div className="reg_form_main col-sm-8 offset-sm-2" style={{ maxHeight: "315px", overflow: "auto" }}>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">{AddPurchaseOrderConstForm.ProductName}</th>
                                            <th scope="col">{AddPurchaseOrderConstForm.SupplierSKU}</th>
                                            <th scope="col">{AddPurchaseOrderConstForm.Quantity}</th>
                                            <th scope="col">{AddPurchaseOrderConstForm.Cost}</th>
                                            <th scope="col">{AddPurchaseOrderConstForm.Tax}</th>
                                            <th scope="col">{AddPurchaseOrderConstForm.Total}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.productList.map(e => {
                                            return (<tr>
                                                <th scope="row">{e.Product}</th>
                                                <td>{e.SupplierSKU}</td>
                                                <td>{e.Quantity}</td>
                                                <td>{currencySymbol}{Number(e.Cost).toFixed(2)}</td>
                                                <td>{e.Tax}</td>
                                                <td>{currencySymbol}{Number(e.Total).toFixed(2)}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>}

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.ShipingCenter}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.Product}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.Product} />
                            </label>
                            <Select menuPortalTarget={document.body}
                                value={this.state.selecteItem}
                                onChange={this.handleItem}
                                options={this.state.storeItemList}
                                styles={colourStyles}
                                placeholder={Constvariable.Product_Select}
                                className="select-input"
                                getOptionLabel={(option) => option.ItemName}
                                getOptionValue={(option) => option._id}
                            />
                            <div className="errorMsg">
                                {this.state.itemErrors.selecteItem}
                            </div>
                        </div>
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.SupplierSKU}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.SupplierSKU}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.SupplierSKU} />
                            </label>
                            <input
                                type="text"
                                name="SupplierSKU"
                                className='add-item-box'
                                placeholder={Constvariable.SupplierSKU}
                                value={this.state.productFields.SupplierSKU}
                                onChange={this.handleChangeProduct}
                            />
                            <div className="errorMsg">
                                {this.state.itemErrors.SupplierSKU}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.Quantity}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.Quantity}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.Quantity} />
                            </label>
                            <input
                                type="text"
                                name="Quantity"
                                className='add-item-box'
                                placeholder={Constvariable.Quantity}
                                value={this.state.productFields.Quantity}
                                onChange={this.handleChangeProduct}
                            />
                            <div className="errorMsg">
                                {this.state.itemErrors.Quantity}
                            </div>
                        </div>
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.Cost}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.Cost} {currencySymbol}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.Cost} />
                            </label>
                            <input
                                type="text"
                                name="Cost"
                                className='add-item-box'
                                placeholder={Constvariable.Cost}
                                value={this.state.productFields.Cost}
                                onChange={this.handleChangeProduct}
                                onBlur={this.handelOnBlur}
                            />
                            <div className="errorMsg">
                                {this.state.itemErrors.Cost}
                            </div>
                        </div>
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.ProductTax}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.Tax}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.Tax} />
                            </label>
                            <input
                                type="text"
                                name="ProductTax"
                                className='add-item-box'
                                placeholder={Constvariable.ProductTax}
                                value={this.state.productFields.ProductTax}
                                onChange={this.handleChangeProduct}
                            />
                            <div className="errorMsg">
                                {this.state.itemErrors.ProductTax}
                            </div>
                        </div>
                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label
                                style={{ background: Color.THEME_COLOR, margin: "0px", marginBottom: "4px", padding: "4px" }}
                                for="step5"
                                id="continue-step5"
                                onClick={this.handleAdd}
                                className="btn btn-done"
                            > {Constvariable.AddProduct}</label>
                        </div> */}




                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {Constvariable.CostSummary}
                            </p>
                            <label className="label-color">{AddPurchaseOrderConstForm.ShippingCharge} {currencySymbol}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.ShippingCharge} />
                            </label>
                            <input
                                type="text"
                                name="ShippingCharge"
                                className='add-item-box'
                                placeholder={Constvariable.ShippingCharge}
                                value={this.state.userFields.ShippingCharge}
                                onChange={this.handleChange}
                                onBlur={this.handelOnBlur}
                            />
                            <div className="errorMsg">
                                {this.state.errors.ShippingCharge}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.ShippingCharge}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.Adjustment}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.Adjustment} />
                            </label>
                            <input
                                type="text"
                                name="Adjustment"
                                className='add-item-box'
                                placeholder={Constvariable.Adjustment}
                                value={this.state.userFields.Adjustment}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.Adjustment}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.ShippingCharge}
                        </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.AdjustmentAmount} {currencySymbol}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.AdjustmentAmount} />
                            </label>
                            <input
                                type="text"
                                name="Amount"
                                className='add-item-box'
                                placeholder={Constvariable.Amount}
                                value={this.state.userFields.Amount}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.Amount}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2" >
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {Constvariable.Total}
                            </p>
                            <label className="label-color">{AddPurchaseOrderConstForm.TotalTax}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.TotalTax} />
                            </label>
                            <input
                                type="text"
                                name="TotalTax"
                                className='add-item-box'
                                placeholder={Constvariable.TotalTax}
                                value={this.state.userFields.TotalTax}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.TotalTax}
                            </div>
                        </div>
                        <div className="reg_form_main col-sm-8 offset-sm-2" >
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {Constvariable.Total}
                            </p> */}
                            <label className="label-color">{AddPurchaseOrderConstForm.Total} {currencySymbol}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddPurchaseOrderForm.Total} />
                            </label>
                            <input
                                type="text"
                                name="Total"
                                className='add-item-box'
                                style={{ pointerEvents: "none" }}
                                placeholder={Constvariable.Total}
                                value={this.state.TotalValue}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.Total}
                            </div>
                        </div>
                    </div>
                    <div className="bottam_sing">
                        <button
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done"
                        > {Constvariable.AddPurchaseOrder}</button>
                    </div>
                </LoadingOverlay>
            </section >

        );
    }
}
export default AddPurchaseOrder;