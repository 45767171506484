
const Color = {
    
    GREEN:'#02821D',
    BLUE:'#1F58CB',
    RED:'#FF0000',
    BLACK: '#333',  
    GREY: '#d1d1d1',
    HEADER_COLOR:'#e2008b',
    THEME_COLOR:'#e2008b',
    WHITE: '#fff',
    GOOGLE_BTN_COLOR: '#4285F4',
    FACEBOOK_BTN_COLOR: '#3b5998'
    
  };
  export default Color;