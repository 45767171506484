import React, { Component, Fragment } from "react";
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import Checkbox from 'react-simple-checkbox';
import { GetData } from "../../../services/GetData";
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import "../../../styles/style.css";
import Color from "../../Config/Color";
import { GET_INVENTORY, GET_PRODUCT_CATEGORY, GET_STORE_ITEM, PLAZAID, MY_STORE_API, Tax_API } from '../../Config/Config.json';
import Constants, { DEFAULTID, imageStandard } from '../../Config/Constants';
import ProductConstForm from "../../Config/ConstForm";
import Constvariable, { IMAGEFILEEXTENSIONS } from '../../Config/constvariable';
import { ProductForm } from '../../Config/HoverMessage';
import Messages from "../../Config/Messages";
import { RetriveDataFromStorage, RetriveObjectFromStorage, validateFloat, validateInt } from "../../Config/Utils";
import Header from '../../Header/Header.js';
import OnHoverIconInfo, { PutMandatoryfieldSymbol } from "../../UtillCustomComponent/UtillCustomComponent";
import './AddItems.css';

const T = [
    {
        "_id": "648851683044e3fa2f93826b",
        "TaxType": "HST ",
        "Taxname": "Sales Tax ",
        "TaxRate": 13.0,
        "Status": "ACTIVE",
        "isdefault": true,
        "ValidFromDate": "2023-05-31T18:30:00Z",
        "ValidToDate": "2024-09-30T18:30:00Z",
    },
    {
        "_id": "648b1dd2d13640f4eac9095d",
        "TaxType": "GST",
        "Taxname": "Sales Tax update",
        "TaxRate": 10.0,
        "Status": "ACTIVE",
        "isdefault": false,
        "ValidFromDate": "2023-05-31T18:30:00Z",
        "ValidToDate": "2024-09-30T18:30:00Z"
    }
]

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

const taxSelectStyle = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" }),
    input: styles => ({ ...styles, padding: "2px 5px" }),
    singleValue: styles => ({ ...styles, padding: "2px 5px", }),
    placeholder: styles => ({ ...styles, padding: "2px 5px" }),
    multiValueRemove: (styles, state) => {
        if (state.data && state.data.isdefault) {
            return { ...styles, display: "none" }
        }
        return styles
    }
}

const ProductTypeList = [
    { value: 'Q', label: 'Quantity' },
    { value: 'W', label: 'Weight' }
]

const WightUnit = [
    { value: 'KG', label: 'Kilograms' },
    { value: 'LB', label: 'Pounds' },
]

class AddItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            errors: {},
            productTypeList: ProductTypeList,
            selectedProductType: null,

            storeProductobj: null,

            productCategory: [],
            selectedProductCategory: null,

            selectedFile: null,
            filename: null,
            fileSize: null,

            WightUnit: WightUnit,
            selectWightUnit: null,

            isSpacial: false,
            isTrackQuantity: false,
            continueSelling: false,
            isTopping: false,
            plazaId: "",
            storeId: "",
            itemId: "",
            isActive: false,
            isupload: false,
            Others: "",

            productSizeList: [],
            selectedProductSize: [],

            productColorList: [],
            selectedProductColor: [],
            isProductTaxFree: false,
            Imageitemid: "",
            colorWithSize: [],
            colorQuantity: [],

            StateTaxList: [],
            selectedTax: []
        };

        this.handleAdd = this.handleAdd.bind(this);
        this.handleProductType = this.handleProductType.bind(this);
        this.handleWightUnit = this.handleWightUnit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleProductCategory = this.handleProductCategory.bind(this);
        this.handleAddSize = this.handleAddSize.bind(this);
        this.handleAddColor = this.handleAddColor.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handelOnBlur = this.handelOnBlur.bind(this);
    }

    componentDidMount() {
        var storeProductobj = RetriveObjectFromStorage(Constants.STORE_PRODUCT_OBJ);
        if (storeProductobj) {
            this.setState({ storeProductobj: storeProductobj });
        }

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID;
            this.setState({ storeId: storeId, plazaId: plazaId });
            this.getProductCatagory(authToken, storeId, plazaId);
            var storeItem = RetriveObjectFromStorage(Constants.STROE_ITEM_OBJ);
            // if (storeItem) {
            //     this.getStoreInventory(authToken, storeId)
            // }
        }
        else {
            this.props.history.goBack()
        }
    }

    getProductCatagory(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} })
        var URL = GET_PRODUCT_CATEGORY.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.length > 0) {
                        responseData = responseData.filter(e => e.ParentCategoryID !== "000000000000000000000000")
                        this.setState({ productCategory: responseData });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }

                this.getTax(authToken, storeId, plazaId)
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getTax(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = MY_STORE_API + "/" + storeId + "/" + Tax_API;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    responseData = responseData ? responseData : []
                    let defaultTax = responseData.filter(e => e.isdefault);
                    this.setState({
                        StateTaxList: responseData,
                        selectedTax: defaultTax
                    })
                }
                else {
                    let errors = {};
                    errors["masterMsg"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })



                // //  demo code //
                // let t = []
                // for (var i = 0; i < 1; i++) {
                //     t.push(...T)
                // }
                // let rs = t
                // let defaultTax = rs.filter(e => e.isdefault);
                // this.setState({
                //     StateTaxList: rs,
                //     selectedTax: defaultTax
                // })
                // // this.getProductTaxes(authToken, storeId)
                // ///

                this.getStoreInventory(authToken, storeId, plazaId);
            }).catch(error => {
                let errors = {};
                errors["masterMsg"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getStoreInventory(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} })
        var URL = GET_INVENTORY + "/" + storeId + "?" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        var storeItem = RetriveObjectFromStorage(Constants.STROE_ITEM_OBJ);
                        if (storeItem) {
                            var itemId = storeItem._id;
                            this.setState({ itemId: itemId });
                            this.autopopulated(storeItem, responseData, plazaId);
                        }
                        var storeItemCategory = RetriveObjectFromStorage(Constants.STORE_PRODUCT_OBJ);
                        if (storeItemCategory) {
                            var storeCatregoryObj = this.state.productCategory.filter(e => e._id === storeItemCategory._id)[0];
                            this.setState({ selectedProductCategory: storeCatregoryObj });
                        }
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    autopopulated(storeItem, inventoryList) {
        console.log("Store item object", JSON.stringify(storeItem));
        let userFields = this.state.userFields;
        userFields['product'] = storeItem.ItemName
        var storeCatregoryObj = this.state.productCategory.filter(e => e._id === storeItem.SubCategoryID)[0]
        this.setState({ selectedProductCategory: storeCatregoryObj })
        userFields['price'] = storeItem.ItemPrice
        userFields['productDescription'] = storeItem.ItemDescription
        userFields['productSize'] = storeItem.ItemSize
        userFields['productColor'] = storeItem.ItemColor
        userFields['others'] = storeItem.Others
        userFields['InternalProudctID'] = storeItem.InternalProudctID

        this.setState({ selectedProductType: this.state.productTypeList.filter(e => e.value === storeItem.ProductType)[0] })
        this.setState({ selectWightUnit: this.state.WightUnit.filter(e1 => e1.value === storeItem.WeightType)[0] })
        userFields['weight'] = storeItem.Weight

        this.setState({ selectedFile: storeItem.ItemLogo, Imageitemid: storeItem.ItemLogoID });

        userFields['costPerItem'] = storeItem.CostPerItem
        userFields['rewardPoint'] = storeItem.RewardPoints
        userFields['tax'] = storeItem.tax
        userFields['toppingmaximumquantity'] = storeItem.toppingmaximumquantity;
        userFields['toppingminimumquantity'] = storeItem.toppingminimumquantity;
        userFields['specialdeliverycharges'] = storeItem.specialdeliverycharges

        this.setState({
            isSpacial: storeItem.IsSpecialDeliveryItem,
            productSizeList: storeItem.Sizes ? storeItem.Sizes : [],
            productColorList: storeItem.colors ? storeItem.colors : []
        })

        var inventoryObj = inventoryList.filter(e => e.ItemId === storeItem._id)[0]
        if (inventoryObj) {
            userFields['SKU'] = inventoryObj.SKU
            userFields['barCode'] = inventoryObj.BarCode
            userFields['availableQuantity'] = inventoryObj.AvailableQuantity
            this.setState({ isTrackQuantity: inventoryObj.TrackQuantity })
            this.setState({ continueSelling: inventoryObj.ContinueSelling })
        }

        this.setState({
            userFields, isProductTaxFree: storeItem.IsProductTax,
            colorWithSize: storeItem.ItemSizeColor ? storeItem.ItemSizeColor : []
        });

        if (storeItem.taxes) {
            let { StateTaxList, selectedTax } = this.state
            let selectedTaxLs = [...selectedTax, ...StateTaxList.filter(e => storeItem.taxes.some(e1 => e._id == e1.taxid))]
            this.setState({ selectedTax: selectedTaxLs })
        }
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields });
    }

    handelOnBlur(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (Number(value)) {
            userFields[key] = Number(value).toFixed(2)
        }
        this.setState({ userFields });
    }

    handleProductCategory(selectedProductCategory) {
        this.setState({ selectedProductCategory });

        if (selectedProductCategory) {
            this.selectTaxes(selectedProductCategory)
        }
        else {
            this.setState({ selectedTax: [] })
        }
    }

    selectTaxes(obj) {
        let { StateTaxList } = this.state;
        let list = obj.taxes ? obj.taxes : []
        let list1 = StateTaxList.filter(e => list.some(e1 => e1.taxid == e._id) || e.isdefault)
        this.setState({ selectedTax: list1 })
    }

    handleProductType(selectedProductType) {
        this.setState({ selectedProductType });
    }

    handleWightUnit(selectWightUnit) {
        this.setState({ selectWightUnit });
    }

    handleCheck() {
        this.state.isSpacial ? this.setState({ isSpacial: false }) : this.setState({ isSpacial: true });
        if (!this.state.isSpacial) {
            var backgroung = document.getElementById('modalbackgroundId');
            var modalId = document.getElementById('myModal');

            if (backgroung && modalId) {
                backgroung.style.display = "block";
                modalId.style.display = "block";
            }
        }
    }

    hidemodal() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal');
        if (backgroung && modalId) {
            backgroung.style.display = "none";
            modalId.style.display = "none";
        }
        let userFields = this.state.userFields;

        if (!userFields["specialdeliverycharges"]) {
            this.setState({ isSpacial: false });
        }
        var errors = this.state.errors;
        errors["specialdeliverycharges"] = "";
        this.setState({ errors: errors });
    }

    hidemodalAtSave() {
        let userFields = this.state.userFields;
        var errors = {}
        if (userFields["specialdeliverycharges"]) {
            var backgroung = document.getElementById('modalbackgroundId');
            var modalId = document.getElementById('myModal');
            if (backgroung && modalId) {
                backgroung.style.display = "none";
                modalId.style.display = "none";
            }
        } else {
            errors["specialdeliverycharges"] = Messages.v_Specialdeliverycharges;

        }
        this.setState({ errors: errors });
    }

    handleEdit() {
        // this.state.isSpacial ? this.setState({ isSpacial: false }) : this.setState({ isSpacial: true });
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal');
        if (backgroung && modalId) {
            backgroung.style.display = "block";
            modalId.style.display = "block";
        }
    }

    handleisTrackQuantity() {
        if (!this.state.continueSelling) {
            this.setState({ isTrackQuantity: !this.state.isTrackQuantity });
        }
    }

    handleContinueSelling() {
        if (!this.state.isTrackQuantity) {
            this.setState({ continueSelling: !this.state.continueSelling });
        }
    }

    handleIsTopping() {
        this.setState({ isTopping: !this.state.isTopping });
    }

    handleTaxFreeProduct() {
        this.setState({ isProductTaxFree: !this.state.isProductTaxFree });
    }

    handleAddSize() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal1');

        if (backgroung && modalId) {
            backgroung.style.display = "block";
            modalId.style.display = "block";
        }

        var userFields = this.state.userFields;
        userFields["productSize"] = "";
        this.setState({ userFields });
    }

    hidemodal1() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal1');
        if (backgroung && modalId) {
            backgroung.style.display = "none";
            modalId.style.display = "none";
        }
        // var productSizeList = this.state.productSizeList
        // var userFields = this.state.userFields
        // if (userFields["productSize"]) {
        //     var validateList = productSizeList.some(e => e.value == userFields["productSize"])
        //     if (!validateList) {
        //         productSizeList.push({ value: userFields["productSize"], label: userFields["productSize"] })

        //     }
        // }
        // this.setState({ userFields, productSizeList: productSizeList })
    }

    handleAddSizeInList() {
        var productSizeList = this.state.productSizeList;
        var userFields = this.state.userFields;
        if (userFields["productSize"]) {
            var validateList = productSizeList.some(e => e == userFields["productSize"]);
            if (!validateList) {
                productSizeList.push(userFields["productSize"]);
                userFields["productSize"] = "";
            }
        }
        this.setState({ userFields, productSizeList: productSizeList });
    }

    handleDeleteSizeInList(obj, index) {
        var productSizeList = this.state.productSizeList;
        productSizeList.splice(index, 1);
        this.setState({ productSizeList: productSizeList });
    }

    handleAddColor() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal2');

        if (backgroung && modalId) {
            backgroung.style.display = "block";
            modalId.style.display = "block";
        }
        this.setState({ errors: {} });
    }

    handleAddColorWithSize() {
        const { userFields } = this.state;
        userFields["psize"] = "";
        userFields["pdescription"] = "";
        userFields["pcolor"] = "";
        userFields["pquantity"] = "";
        this.setState({
            userFields: userFields,
            colorQuantity: [],
            errors: {}
        });
        this.handleAddColor();
    }

    hidemodal2() {
        // var productColorList = this.state.productColorList
        // var userFields = this.state.userFields
        // if (userFields["productColor"]) {
        //     var validateList = productColorList.some(e => e.value == userFields["productColor"]);
        //     if (!validateList) {
        //         productColorList.push({ value: userFields["productColor"], label: userFields["productColor"] });
        //     }
        // }
        // this.setState({ userFields, productColorList: productColorList });

        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal2');
        if (backgroung && modalId) {
            backgroung.style.display = "none";
            modalId.style.display = "none";
        }
    }

    handleAddColorInList() {
        var productColorList = this.state.productColorList;
        var userFields = this.state.userFields;
        if (userFields["productColor"]) {
            var validateList = productColorList.some(e => e == userFields["productColor"]);
            if (!validateList) {
                productColorList.push(userFields["productColor"]);
                userFields["productColor"] = "";
            }
        }
        this.setState({ userFields, productColorList: productColorList });
    }

    handleDeleteColorInList(obj, index) {
        var productColorList = this.state.productColorList;
        productColorList.splice(index, 1);
        this.setState({ productColorList: productColorList });
    }

    handleEditSizewithColor(obj) {
        const { userFields } = this.state;
        userFields["psize"] = obj.size ? obj.size : "";
        userFields["pdescription"] = obj.description ? obj.description : "";
        this.setState({
            userFields,
            colorQuantity: obj.colors ? obj.colors : [],
            errors: {}
        })
        this.handleAddColor();
    }

    handleAddColorSizeInList() {
        let errors = {};
        const { userFields, colorQuantity } = this.state
        if (!userFields["pcolor"] || !userFields["pquantity"]) {
            errors["colorQuantity"] = Messages.v_Add_product_size_color;
        }
        else if (userFields["pquantity"] && !validateInt(userFields["pquantity"])) {
            errors["colorQuantity"] = Messages.v_Add_product_size_color;
        }
        else {
            var obj = {
                "color": userFields["pcolor"],
                "quantity": userFields["pquantity"]
            }
            colorQuantity.push(obj);
            userFields["pcolor"] = "";
            userFields["pquantity"] = "";
        }
        this.setState({
            errors: errors,
            colorQuantity: colorQuantity,
            userFields
        });
    }

    validatecolorSize() {
        let errors = {};
        let formIsValid = true;
        const { userFields, colorQuantity } = this.state
        if (!userFields["psize"]) {
            formIsValid = false;
            errors["psize"] = Messages.v_Add_product_size;
        }
        if (!userFields["pdescription"]) {
            formIsValid = false;
            errors["pdescription"] = Messages.v_product_Description;
        }
        if (colorQuantity.length == 0) {
            formIsValid = false;
            errors["colorQuantity"] = Messages.v_Add_product_size_color;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    submitColorQuantity() {
        if (this.validatecolorSize()) {
            const { colorQuantity, colorWithSize, userFields } = this.state
            var c = 0, index = -1
            for (var i of colorQuantity) {
                c = c + Number(i.quantity)
            }
            for (var i in colorWithSize) {
                if (colorWithSize[i].size == userFields["psize"]) {
                    index = i;
                    break;
                }
            }
            var cObj = {
                "size": userFields["psize"],
                "description": userFields["pdescription"],
                "totalquantity": c,
                "colors": colorQuantity
            }
            if (index == -1) {
                colorWithSize.push(cObj);
            }
            else {
                colorWithSize[index] = cObj;
            }
            this.setState({ colorWithSize: colorWithSize });
            this.hidemodal2();
            let quantity = 0
            for (var j of colorWithSize) {
                quantity = quantity + Number(j.totalquantity);
            }
            userFields["availableQuantity"] = quantity;
            this.setState({ userFields });
        }
    }

    handleDeleteColorWith(s, ind) {
        const { colorQuantity } = this.state
        colorQuantity.splice(ind, 1);
        this.setState({ colorQuantity: colorQuantity });
    }

    handleMasterCat(value, obj) {
        if (obj.action == "remove-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                this.setState({ selectedTax: value })
            }
        }
        else if (obj.action == "pop-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                this.setState({ selectedTax: value })
            }
        }
        else {
            value = value ? value : [];
            this.setState({ selectedTax: value })
        }
    }

    handleDeleteSizeWith(obj, indd) {
        const { colorWithSize } = this.state
        colorWithSize.splice(indd, 1);
        this.setState({ colorWithSize: colorWithSize }, () => {
            const { colorWithSize, userFields } = this.state;
            let quantity = 0;
            for (var j of colorWithSize) {
                quantity = quantity + Number(j.totalquantity);
            }
            userFields["availableQuantity"] = String(quantity);
            this.setState({ userFields: userFields });
        });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["product"]) {
            formIsValid = false;
            errors["product"] = Messages.v_product;
        }

        if (!this.state.selectedProductCategory) {
            formIsValid = false;
            errors["productCategory"] = Messages.v_s_productCategory;
        }

        if (!fields["InternalProudctID"]) {
            formIsValid = false;
            errors["InternalProudctID"] = Messages.v_Product_internal_id;
        }

        if (!fields["price"]) {
            formIsValid = false;
            errors["price"] = Messages.v_Price;
        }
        else if (fields["price"]) {
            if (!validateFloat(fields["price"])) {
                formIsValid = false;
                errors["price"] = Messages.v_PriceNumber;
            }
        }

        /* if (!this.state.selectedProductType) {
            formIsValid = false;
            errors["productType"] = Messages.v_productType;
        } */

        if (this.state.selectedProductType && this.state.selectedProductType.value === "W") {
            if (!this.state.selectWightUnit) {
                formIsValid = false;
                errors["wightUnit"] = Messages.v_wightUnit;
            }
        }

        if (fields["weight"]) {
            if (!validateFloat(fields["weight"])) {
                formIsValid = false;
                errors["weight"] = Messages.v_weight;
            }
        }

        if (this.state.selectedProductType && this.state.selectedProductType.value === "Q") {
            if (this.state.colorWithSize.length === 0) {
                formIsValid = false;
                errors["ProductSizeColorDescription"] = Messages.v_productsizecolordescription;
            }
        }

        /* if (!fields["costPerItem"]) {
            formIsValid = false;
            errors["costPerItem"] = Messages.v_costPerItem;
        }
        else if (fields["costPerItem"]) {
            if (!validateFloat(fields["costPerItem"])) {
                formIsValid = false;
                errors["costPerItem"] = Messages.v_costPerItemNumber;
            }
        } */

        if (fields["tax"]) {
            if (!validateFloat(fields["tax"])) {
                formIsValid = false;
                errors["tax"] = Messages.v_tax;
            }
        }

        /* if (!fields["SKU"]) {
            formIsValid = false;
            errors["SKU"] = Messages.v_SKU;
        } */

        /*  if (!fields["barCode"]) {
             formIsValid = false;
             errors["barCode"] = Messages.v_BarCode;
         }*/

        if (this.state.isTrackQuantity) {
            if (!fields["availableQuantity"]) {
                formIsValid = false;
                errors["availableQuantity"] = Messages.v_AvailableQuantity;
            }
            else if (fields["availableQuantity"]) {
                if (!validateInt(fields["availableQuantity"])) {
                    formIsValid = false;
                    errors["availableQuantity"] = Messages.v_AvailableQuantityNum;
                }
                if (fields["availableQuantity"] == 0) {
                    formIsValid = false;
                    errors["availableQuantity"] = Messages.v_AvailableValidQuantityNum;
                }
            }
        }

        if (!this.state.selectedFile) {
            formIsValid = false;
            errors["selectedFile"] = Messages.v_ProductImage;
        }

        /* if (fields["rewardPoint"]) {
            if (!validateFloat(fields["rewardPoint"])) {
                formIsValid = false;
                errors["rewardPoint"] = Messages.v_RewardPoint;
            }
        } */

        /* if (this.state.isTopping) {
            if (!fields["toppingmaximumquantity"]) {
                formIsValid = false;
                errors["toppingmaximumquantity"] = Messages.v_ToppingQuantity;

            }
            else if (fields["toppingmaximumquantity"]) {
                if (!validateInt(fields["toppingmaximumquantity"])) {
                    formIsValid = false;
                    errors["toppingmaximumquantity"] = Messages.v_ProperQuantity;
                }
            }
            if (!fields["toppingminimumquantity"]) {
                formIsValid = false;
                errors["toppingminimumquantity"] = Messages.v_ToppingQuantity

            }
            else if (fields["toppingminimumquantity"]) {
                if (!validateInt(fields["toppingminimumquantity"])) {
                    formIsValid = false;
                    errors["toppingminimumquantity"] = Messages.v_ProperQuantity;
                }
            }
        } */

        if (!formIsValid) {
            errors["ErrorC"] = Messages.v_requiredFieldEmpty
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    btnDisAndEn(flag) {
        let svaebtn = document.getElementById('continue-step5')
        if (svaebtn) {
            svaebtn.disabled = flag
        }
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            this.btnDisAndEn(true);
            postData['Product'] = this.state.userFields.product;
            postData['ProductDescription'] = this.state.userFields.productDescription ? this.state.userFields.productDescription : "";
            postData['ProductCategoryId'] = this.state.selectedProductCategory._id;
            postData['DeviceId'] = "";
            postData['DeviceType'] = "";
            postData['Price'] = this.state.userFields.price;
            postData['RewardPoint'] = this.state.userFields.rewardPoint ? this.state.userFields.rewardPoint : 0.0;
            postData['CostPerItem'] = this.state.userFields.costPerItem ? this.state.userFields.costPerItem : 0.0;

            postData['ProductType'] = this.state.selectedProductType ? this.state.selectedProductType.value : "";
            postData['WeightType'] = this.state.selectWightUnit ? this.state.selectWightUnit.value : "";

            postData['Weight'] = this.state.userFields.weight;
            postData['tax'] = this.state.userFields.tax ? this.state.userFields.tax : 0.0;
            postData['IsProductTax'] = this.state.userFields.tax ? true : false;
            postData['IsSpecialDeliveryItem'] = this.state.isSpacial;
            postData["ItemSizeColor"] = this.state.colorWithSize;
            postData["internalproductId"] = this.state.userFields.InternalProudctID;

            let selectedTaxList = this.state.selectedTax.filter(e => !e.isdefault)
            postData["taxes"] = !this.state.isProductTaxFree ? selectedTaxList.map(e => e._id) : []

            /* if (this.state.selectedFile && this.state.isupload) {
                postData['ImageBase64'] = this.state.selectedFile
            } */

            if (this.state.selectedFile && this.state.isupload) {
                postData["ImageBase64List"] = [{
                    ImageBase64: this.state.selectedFile,
                }];
            } else {
                postData["ImageBase64List"] = [];
            }

            postData['toppingmaximumquantity'] = this.state.userFields.toppingmaximumquantity ? this.state.userFields.toppingmaximumquantity : 0;
            postData['toppingminimumquantity'] = this.state.userFields.toppingminimumquantity ? this.state.userFields.toppingminimumquantity : 0;

            postData['Inventory'] = {
                "SKU": this.state.userFields.SKU ? this.state.userFields.SKU : "",
                "BarCode": this.state.userFields.barCode ? this.state.userFields.barCode : "",
                "AvailableQuantity": this.state.userFields.availableQuantity ? this.state.userFields.availableQuantity : 0,
                "TrackQuantity": this.state.isTrackQuantity,
                "ContinueSelling": this.state.continueSelling
            }

            postData['Sizes'] = []; /* this.state.productSizeList */
            postData['colors'] = []; /* this.state.productColorList */
            if (this.state.isSpacial) {
                postData['specialdeliverycharges'] = this.state.userFields.specialdeliverycharges;
            }
            else {
                postData['specialdeliverycharges'] = "0.0";
            }

            postData['Others'] = this.state.userFields.others ? this.state.userFields.others : "";

            postData["IsProductTax"] = this.state.isProductTaxFree;

            console.log("==> postdata==", postData);

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            var storeId = this.state.storeId;
            var plazaId = this.state.plazaId
            if (this.state.itemId != "" && this.state.itemId) {
                postData['ProductId'] = this.state.itemId;
                this.putStoreProduct(authToken, postData, storeId, plazaId);
            }
            else {
                this.postStoreProduct(authToken, postData, storeId, plazaId);
            }

            // this.updateUserDetail(postData, authToken)
            // this.props.history.push({
            //     pathname: RouteMerchant.ManageItems,
            // })
        }
    }

    postStoreProduct(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} })
        var URL = GET_STORE_ITEM.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                console.log("==>response", result)
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
                this.btnDisAndEn(false)
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
                this.btnDisAndEn(false)
            });
    }

    putStoreProduct(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} })
        var URL = GET_STORE_ITEM.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
                this.btnDisAndEn(false)
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
                this.btnDisAndEn(false)
            });
    }

    handleinputfile = (e) => {
        e.target.value = null
    }

    onSelectFile = event => {
        try {
            var fileobj = event.target.files[0];
            if (fileobj.size > 0) {
                var fileName = event.target.files[0].name;
                var fileSize = event.target.files[0].size;
                var extension = IMAGEFILEEXTENSIONS;
                var fileSplit = fileName.split('.');
                var filelength = fileSplit.length
                var fileExtension = fileSplit[filelength - 1]
                var extensionFlag = extension.includes(String(fileExtension).toLowerCase())
                if (extensionFlag) {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(fileobj);
                    fileReader.onload = (fileLoadedEvent) => {
                        this.standardImageSizeConvert(fileLoadedEvent.target.result, fileSize)
                    };
                    this.setState({ filename: fileName });
                    this.setState({ fileSize: fileSize });
                }
                else {
                    let errors = {};
                    errors["selectedFile"] = Messages.v_ImageExtension_ALL;
                    this.setState({ errors: errors });
                }
            }
        }
        catch (err) {
            console.log("File Upload Error", err);
        }
    };

    standardImageSizeConvert(e, size) {
        let error = {}
        var img = new Image();

        img.onload = () => {
            /* var iw = img.width;
            var ih = img.height; */

            /* if (iw == imageStandard.productImageW && ih == imageStandard.productImageH) { */
            var base64 = e;
            this.setState({ selectedFile: base64, isupload: true });
            /* } */
            /* else if (imageStandard.ImageSize < size) {
                let s = Messages.v_productImageResolution
                error["selectedFile"] = s
            }
            else {
                let s = Messages.v_productImageResolution
                error["selectedFile"] = s
            } */
            this.setState({ errors: error });
        }
        img.src = e;
    }

    render() {
        var currencySymbol = "";
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO);
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol;
        }

        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner">

                    <Header setHeaderTitle={this.state.itemId ? ProductConstForm.UpdateProduct : ProductConstForm.addItem}></Header>

                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.itemName}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.ProductName} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="product"
                                className='add-item-box'
                                placeholder={Constvariable.itemName}
                                value={this.state.userFields.product}
                                onChange={this.handleChange} />
                            <div className="errorMsg">
                                {this.state.errors.product}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.s_ProductCategory}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.ProductCategory} /> <PutMandatoryfieldSymbol />
                            </label>
                            <Select menuPortalTarget={document.body}
                                value={this.state.selectedProductCategory}
                                onChange={this.handleProductCategory}
                                options={this.state.productCategory}
                                styles={colourStyles}
                                placeholder={Constvariable.s_ProductCategory}
                                className="select-input"
                                getOptionLabel={(option) => option.Category_name}
                                getOptionValue={(option) => option._id} />
                            <div className="errorMsg">
                                {this.state.errors.productCategory}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.InternalProductId}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.InternalProductId} /> <PutMandatoryfieldSymbol />
                            </label>
                            <div>
                                <label className="note-label-color">{ProductForm.ProductInternalIdNote}</label>
                            </div>
                            <input
                                type="text"
                                name="InternalProudctID"
                                className='add-item-box'
                                placeholder={Constvariable.InternalProductId}
                                value={this.state.userFields.InternalProudctID}
                                onChange={this.handleChange} />
                            <div className="errorMsg">
                                {this.state.errors.InternalProudctID}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.ItemDescription}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.ProductDescription} />
                            </label>
                            <textarea
                                type="text"
                                name="productDescription"
                                className='add-item-textarea'
                                placeholder={Constvariable.ItemDescription}
                                value={this.state.userFields.productDescription}
                                onChange={this.handleChange} />
                            <div className="errorMsg">
                                {this.state.errors.productDescription}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.ItemPrice} {currencySymbol}
                                &nbsp;
                                <OnHoverIconInfo message={ProductForm.ProductPrice} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="price"
                                className='add-item-box'
                                placeholder={Constvariable.ItemPrice}
                                value={this.state.userFields.price}
                                onChange={this.handleChange}
                                onBlur={this.handelOnBlur} />
                            <div className="errorMsg">
                                {this.state.errors.price}
                            </div>
                        </div>

                        {/* Hide as per sir call discussion on Date 28 July 2023  */}
                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.ProductType}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.ProductTypeWithToolTip1 + " " + ProductForm.ProductTypeWithToolTip2 + " " + ProductForm.ProductTypeWithToolTip3} />
                            </label>
                            <div>
                                <label className="note-label-color">{ProductForm.ProductTypeWithToolTip1}</label>
                                <br /><label className="note-label-color">{ProductForm.ProductTypeWithToolTip2}</label>
                                <br /><label className="note-label-color">{ProductForm.ProductTypeWithToolTip3}</label>
                            </div>
                            <Select
                                menuPortalTarget={document.body}
                                value={this.state.selectedProductType}
                                onChange={this.handleProductType}
                                options={this.state.productTypeList}
                                isClearable={true}
                                styles={colourStyles}
                                placeholder={Constvariable.ProductType}
                                className="select-input" />
                            <div className="errorMsg">
                                {this.state.errors.productType}
                            </div>
                        </div> */}

                        {(this.state.selectedProductType && this.state.selectedProductType.value == "W") ?
                            <div>
                                <div className="reg_form_main col-sm-8 offset-sm-2">
                                    <label className="label-color">{ProductConstForm.ProductWeightUnit}
                                        &nbsp;
                                        <OnHoverIconInfo message={ProductForm.ProductWeightUnit} /> <PutMandatoryfieldSymbol />
                                    </label>
                                    <Select menuPortalTarget={document.body}
                                        value={this.state.selectWightUnit}
                                        onChange={this.handleWightUnit}
                                        options={this.state.WightUnit}
                                        styles={colourStyles}
                                        placeholder={Constvariable.ProductWeightUnit} />
                                    <div className="errorMsg">
                                        {this.state.errors.wightUnit}
                                    </div>
                                </div>

                                <div className="reg_form_main col-sm-8 offset-sm-2">
                                    <label className="label-color">{ProductConstForm.Weight}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={ProductForm.ProductWeight} />
                                    </label>
                                    <input
                                        type="text"
                                        name="weight"
                                        className='add-item-box'
                                        placeholder={Constvariable.Weight}
                                        value={this.state.userFields.weight}
                                        onChange={this.handleChange} />
                                    <div className="errorMsg">
                                        {this.state.errors.weight}
                                    </div>
                                </div></div> : ""}

                        {(this.state.selectedProductType && this.state.selectedProductType.value == "Q") ? <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.ProductSizeColorDescription}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.ProductSizeColorDescription} /> <PutMandatoryfieldSymbol />
                            </label>
                            {this.state.colorWithSize.map((itm, indx) => {
                                return <Fragment>
                                    <div class="card mt-2 mb-2">
                                        <div class="row m-0 w-100 pt-2 pb-2">
                                            <div class="col-12 text-right"><i class="fas fa-edit" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleEditSizewithColor(itm)}></i> &nbsp;
                                                <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, padding: "10px", fontSize: "20px" }} onClick={() => this.handleDeleteSizeWith(itm, indx)}></i></div>
                                            <div class="col-12">Size: {itm.size}</div>
                                            <div class="col-12">Description: {itm.description}</div>
                                            <div class="col-12">Total Quantity: {itm.totalquantity}</div>
                                            <div class="col-12">Color - Quantity:
                                                <p>{itm.colors && itm.colors.map((color, ind) => {
                                                    return color.color + " - " + color.quantity
                                                }).join(" ,")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            })}

                            <div>
                                <div className="errorMsg">
                                    {this.state.errors.ProductSizeColorDescription}
                                </div>
                                <button
                                    style={{ background: Color.THEME_COLOR }}
                                    for="step5"
                                    id="continue-step5"
                                    onClick={() => this.handleAddColorWithSize()}
                                    className="btn btn-done" > {ProductConstForm.AddSizeColorDescription} </button>
                            </div>
                        </div> : <div></div>
                        }

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.CostPerItem} {currencySymbol}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.CostPerItem} /></label>
                            <input
                                type="text"
                                name="costPerItem"
                                className='add-item-box'
                                placeholder={Constvariable.CostPerItem}
                                value={this.state.userFields.costPerItem}
                                onChange={this.handleChange}
                                onBlur={this.handelOnBlur} />
                            <div className="errorMsg">
                                {this.state.errors.costPerItem}
                            </div>
                        </div>

                        {/* As per discussion, topping minimum quantity and topping maximum quantity hide  */}
                        {/* <div className="reg_form_main col-sm-8 offset-sm-2"><hr className="hrbordercolor" /></div> */}

                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.ToppingMinimumQuantity}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.ToppingMinimumQuantity} /></label>
                            <div>
                                <label className="note-label-color">{ProductConstForm.ToppingMinimumQuantityNote}</label>
                            </div>
                            <input
                                type="text"
                                name="toppingminimumquantity"
                                className='add-item-box'
                                placeholder={Constvariable.ToppingMinimumQuantity}
                                value={this.state.userFields.toppingminimumquantity}
                                onChange={this.handleChange} />
                            <div className="errorMsg">
                                {this.state.errors.toppingminimumquantity}
                            </div>
                        </div> */}

                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.ToppingMaximumQuantity}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.ToppingMaximumQuantity} />
                            </label>
                            <div>
                                <label className="note-label-color">{ProductConstForm.ToppingMaximumQuantityNote}</label>
                            </div>
                            <input
                                type="text"
                                name="toppingmaximumquantity"
                                className='add-item-box'
                                placeholder={Constvariable.ToppingMaximumQuantity}
                                value={this.state.userFields.toppingmaximumquantity}
                                onChange={this.handleChange} />
                            <div className="errorMsg">
                                {this.state.errors.toppingmaximumquantity}
                            </div>
                        </div> */}

                        {/* <div className="reg_form_main col-sm-8 offset-sm-2"><hr className="hrbordercolor" /></div> */}

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.IsProductTaxFree}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.IsProductTaxFree} />
                            </label>
                            <div className="checkbox-input-div">
                                <Checkbox
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={this.state.isProductTaxFree}
                                    delay={0}
                                    backAnimationDuration={0}
                                    tickAnimationDuration={0}
                                    onChange={() => this.handleTaxFreeProduct()} />
                                <span className="Checkbox-label" style={{ color: Color.THEME_COLOR }}>
                                    {ProductConstForm.Yes}
                                </span> &nbsp; &nbsp;
                                <Checkbox
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={!this.state.isProductTaxFree}
                                    delay={0}
                                    backAnimationDuration={0}
                                    tickAnimationDuration={0}
                                    onChange={() => this.handleTaxFreeProduct()} />
                                <span className="Checkbox-label" style={{ color: Color.THEME_COLOR }}>
                                    {ProductConstForm.No}
                                </span>
                            </div>
                            <div className="errorMsg">
                                {this.state.errors.tax}
                            </div>
                        </div>

                        {!this.state.isProductTaxFree && <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.ApplicableTax}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.ApplicableTax} />
                            </label>
                            <Select
                                value={this.state.selectedTax}
                                // menuPlacement="top"
                                menuPortalTarget={document.body}
                                onChange={(value, obj) => this.handleMasterCat(value, obj)}
                                isClearable={false}
                                options={this.state.StateTaxList}
                                styles={taxSelectStyle}
                                placeholder={Constvariable.SelectTaxes}
                                className="select-input"
                                // isOptionDisabled={(option) => option.isdefault}
                                isMulti={true}
                                getOptionLabel={(option) => option.Taxname + " - " + option.TaxRate + " %"}
                                getOptionValue={(option) => option._id} />
                            <div className="errorMsg">
                                {this.state.errors.selectedTax}
                            </div>
                        </div>}

                        <div className="reg_form_main col-sm-8 offset-sm-2" style={this.state.itemId ? { pointerEvents: "" } : {}}>
                            <p className="col-sm-12 textcolor" for="radiusUnit" style={{ paddingLeft: "0px" }}>
                                {Constvariable.Inventory}
                            </p>
                            <label className="label-color">{ProductConstForm.SKU}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.SKU} />
                            </label>
                            <input
                                type="text"
                                name="SKU"
                                className='add-item-box'
                                placeholder={Constvariable.SKU}
                                value={this.state.userFields.SKU}
                                onChange={this.handleChange} />
                            <div className="errorMsg">
                                {this.state.errors.SKU}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2" style={this.state.itemId ? { pointerEvents: "" } : {}}>
                            <label className="label-color">{ProductConstForm.BarCode}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.BarCode} />
                            </label>
                            <input
                                type="text"
                                name="barCode"
                                className='add-item-box'
                                placeholder={Constvariable.BarCode}
                                value={this.state.userFields.barCode}
                                onChange={this.handleChange} />
                            <div className="errorMsg">
                                {this.state.errors.barCode}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2" style={this.state.itemId ? { pointerEvents: "" } : {}}>
                            <label className="label-color">{ProductConstForm.AvailableQuantity}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.AvailableQuantity} /> {this.state.isTrackQuantity && <PutMandatoryfieldSymbol />}
                            </label>
                            <input
                                type="text"
                                name="availableQuantity"
                                className='add-item-box'
                                placeholder={this.state.isTrackQuantity ? Constvariable.AvailableQuantityM : Constvariable.AvailableQuantity}
                                value={this.state.userFields.availableQuantity}
                                disabled={(this.state.selectedProductType && this.state.selectedProductType.value == "Q") ? true : false}
                                onChange={this.handleChange} />
                            <div className="errorMsg">
                                {this.state.errors.availableQuantity}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2" style={this.state.itemId ? { pointerEvents: "" } : {}}>
                            <div className="checkbox-input-div">
                                <Checkbox
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={this.state.isTrackQuantity}
                                    delay={0}
                                    backAnimationDuration={0}
                                    tickAnimationDuration={0}
                                    onChange={() => this.handleisTrackQuantity()} />
                                <span className="Checkbox-label" style={{ color: Color.THEME_COLOR }}>
                                    {Constvariable.isTrackQuantity}
                                    &nbsp;
                                    <OnHoverIconInfo
                                        message={ProductForm.TrackQuantity} />
                                </span>
                            </div>
                            <div className="errorMsg">
                                {this.state.errors.email}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2" style={this.state.itemId ? { pointerEvents: "" } : {}}>
                            <div className="checkbox-input-div">
                                <Checkbox
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={this.state.continueSelling}
                                    delay={0}
                                    backAnimationDuration={0}
                                    tickAnimationDuration={0}
                                    onChange={() => this.handleContinueSelling()} />
                                <span className="Checkbox-label" style={{ color: Color.THEME_COLOR }}>
                                    {Constvariable.ContinueSelling}
                                    &nbsp;
                                    <OnHoverIconInfo
                                        message={ProductForm.ContinueSelling} />
                                </span>
                            </div>
                            <div className="errorMsg">
                                {this.state.errors.email}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <div className="checkbox-input-div">
                                <Checkbox
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={this.state.isSpacial}
                                    delay={0}
                                    backAnimationDuration={0}
                                    tickAnimationDuration={0}
                                    onChange={() => this.handleCheck()} />
                                <span className="Checkbox-label" style={{ color: Color.THEME_COLOR }}>
                                    {Constvariable.IsSpecialDeliveryItem}
                                    &nbsp;
                                    <OnHoverIconInfo
                                        message={ProductForm.SpecialDeliveryProduct} />
                                </span> &nbsp;
                                {this.state.isSpacial ? <label style={{ color: Color.THEME_COLOR }}>: {currencySymbol} {this.state.userFields.specialdeliverycharges}</label> : ""}
                                {this.state.isSpacial ? <i class="fas fa-edit" style={{ color: Color.THEME_COLOR, float: "right", fontSize: "20px" }} onClick={() => this.handleEdit()}></i> : ""}
                            </div>
                            <div className="errorMsg">
                                {this.state.errors.email}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.Others}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.Others} />
                            </label>
                            <textarea
                                type="text"
                                name="others"
                                className='add-item-textarea'
                                placeholder={Constvariable.Others}
                                value={this.state.userFields.others}
                                onChange={this.handleChange} />
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <div className="setuploadbtn">
                                {ProductConstForm.addItemImage} &nbsp;
                                <OnHoverIconInfo message={ProductForm.UploadImage} /> <PutMandatoryfieldSymbol />
                            </div>

                            <div className="d-block pl-0">
                                <div className="btn-upload" style={{ maxWidth: "unset" }}>
                                    <label className="btn fontc-gray bg-color-ccc" for="updateProfile" style={{ padding: "4px 0px 0px 0px ", background: Color.THEME_COLOR }}>
                                        {this.state.selectedFile ? ProductConstForm.EditLabel : ProductConstForm.Upload}
                                    </label>
                                    <input
                                        type="file"
                                        id="updateProfile"
                                        style={{ opacity: "0" }}
                                        data-id="profile"
                                        className="hideAction"
                                        onClick={this.handleinputfile}
                                        onChange={this.onSelectFile} />
                                </div>
                            </div>

                            <div className="errorMsg white-space-pw">
                                {this.state.errors.selectedFile}
                            </div>

                            <center>
                                <div className="aepa-left banner-updated">
                                    <img src={this.state.selectedFile} style={{ borderRadius: '10px' }} />
                                </div>
                            </center>

                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <center>
                                <div className="errorMsg">
                                    {this.state.errors.ErrorC}
                                </div>
                            </center>
                        </div>
                    </div>

                    <div className="bottam_sing">
                        <button
                            type="button"
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done">  {this.state.itemId ? ProductConstForm.btn_UpdateProduct : ProductConstForm.btn_AddProduct}</button>
                    </div>

                    <div className="modal-background" id="modalbackgroundId"> </div>

                    <div class="modal" id="myModal" style={{ verticalAlign: "middle" }}>
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <center>
                                        <label><b>{this.state.title}</b></label><br />
                                        <label>Add delivery expanse you want to charge on this specific product.</label>
                                    </center>

                                    <div className="reg_form_main col-sm-8 offset-sm-2" style={this.state.itemId ? { pointerEvents: "" } : {}}>

                                        <label className="label-color">Add Delivery Charges {currencySymbol}</label> <PutMandatoryfieldSymbol />
                                        <input
                                            type="text"
                                            name="specialdeliverycharges"
                                            className='add-item-box'
                                            placeholder="Add Delivery Charges"
                                            value={this.state.userFields.specialdeliverycharges}
                                            onChange={this.handleChange}
                                            onBlur={this.handelOnBlur} />
                                        <div className="errorMsg">
                                            {this.state.errors.specialdeliverycharges}
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <label
                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                        data-dismiss="modal"
                                        onClick={() => this.hidemodal()}
                                    // onClick={() => this.unsetDeleteId()}
                                    > No</label>

                                    <label
                                        // onClick={() => this.handleDelete()}
                                        // onClick={this.handleDelete}
                                        // onClick={this.handleAdd}
                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                        data-dismiss="modal"
                                        onClick={() => this.hidemodalAtSave()}
                                    > Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal" id="myModal1" style={{ verticalAlign: "middle" }}>
                        <div class="modal-dialog setModal-dialogue">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <center>
                                        <label><b>{this.state.title}</b></label><br />
                                        <label>{ProductConstForm.ProductSize}</label>
                                    </center>

                                    <div className="reg_form_main col-sm-10 offset-sm-1" style={this.state.itemId ? { pointerEvents: "" } : {}}>

                                        <label className="label-color">{ProductConstForm.EnterAvailableSizes}</label>
                                        {this.state.productSizeList.map((e, i) => {
                                            return <div className="inputboxadd">
                                                <input
                                                    type="text"
                                                    name={e}
                                                    className='add-item-box'
                                                    // placeholder="Add Delivery Charges"
                                                    value={e}
                                                // onChange={this.handleChange}
                                                />
                                                <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, padding: "10px", fontSize: "20px" }} onClick={() => this.handleDeleteSizeInList(e, i)}></i>
                                            </div>
                                        })}
                                        <div className="inputboxadd">
                                            <input
                                                type="text"
                                                name="productSize"
                                                className='add-item-box'
                                                placeholder={Constvariable.E_ProductSize}
                                                value={this.state.userFields.productSize}
                                                onChange={this.handleChange}
                                            />
                                            <i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, padding: "10px", fontSize: "20px" }} onClick={() => this.handleAddSizeInList()}></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer" onClick={() => this.hidemodal1()}>
                                    <label
                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                        data-dismiss="modal"
                                        onClick={() => this.hidemodal1()}
                                    // onClick={() => this.unsetDeleteId()}
                                    > Close</label>

                                    {/* <label
                                        // onClick={() => this.handleDelete()}
                                        //onClick={this.handleDelete}
                                        // onClick={this.handleAddToCart}
                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                        data-dismiss="modal"
                                        onClick={() => this.hidemodal1()}
                                    > Yes</label> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal" id="myModal2" style={{ verticalAlign: "middle" }}>
                        <div class="modal-dialog setModal-dialogue">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <center>
                                        <label><b>{this.state.title}</b></label><br />
                                        <label>{ProductConstForm.v_Add_product_size_color}</label>
                                    </center>
                                    <div className="reg_form_main col-12">
                                        <label className="label-color">{ProductConstForm.ProductSize}
                                            &nbsp;
                                            <OnHoverIconInfo
                                                message={ProductForm.ProductSize} /> <PutMandatoryfieldSymbol />
                                        </label>
                                        <div className="inputboxadd">
                                            <input
                                                type="text"
                                                name="psize"
                                                className='add-item-box'
                                                placeholder={Constvariable.E_ProductSize}
                                                value={this.state.userFields.psize}
                                                onChange={this.handleChange} />
                                        </div>
                                        <div className="errorMsg">
                                            {this.state.errors.psize}
                                        </div>
                                    </div>

                                    <div className="reg_form_main col-12">
                                        <label className="label-color">{ProductConstForm.Description}
                                            &nbsp;
                                            <OnHoverIconInfo style={{ width: '250px', minWidth: 'unset', maxWidth: '250px' }}
                                                message={<React.Fragment>{ProductForm.ProductDescription1} <br /> <br /> {ProductForm.ProductDescription2}</React.Fragment>} /><PutMandatoryfieldSymbol />
                                        </label>
                                        <div className="inputboxadd">
                                            <textarea
                                                type="text"
                                                name="pdescription"
                                                className='add-item-textarea'
                                                placeholder={Constvariable.Description}
                                                value={this.state.userFields.pdescription}
                                                onChange={this.handleChange} />
                                        </div>
                                        <div className="errorMsg">
                                            {this.state.errors.pdescription}
                                        </div>
                                    </div>

                                    <div className="reg_form_main col-12">
                                        <label className="label-color">{ProductConstForm.EnterAvailableColorsSize}
                                            &nbsp;
                                            <OnHoverIconInfo style={{ width: '250px', minWidth: 'unset', maxWidth: '250px' }}
                                                message={<React.Fragment>{ProductForm.ProductWithColorQuantity}</React.Fragment>} /><PutMandatoryfieldSymbol />
                                        </label>
                                        <div class="row m-0">
                                            <div class="col-5 pl-0 label-color">{ProductConstForm.Color}&nbsp;<OnHoverIconInfo style={{ width: '250px', minWidth: 'unset', maxWidth: '250px' }}
                                                message={<React.Fragment>{ProductForm.ProductColor}</React.Fragment>} /> <PutMandatoryfieldSymbol /></div>
                                            <div class="col-5 pl-0 label-color">{ProductConstForm.Quantity}&nbsp;<OnHoverIconInfo style={{ width: '250px', minWidth: 'unset', maxWidth: '250px' }}
                                                message={<React.Fragment>{ProductForm.ProductQuntity}</React.Fragment>} /><PutMandatoryfieldSymbol /></div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-5 pl-0">
                                                <input
                                                    type="text"
                                                    name="pcolor"
                                                    className='add-item-box'
                                                    placeholder={Constvariable.EnterColor}
                                                    value={this.state.userFields.pcolor}
                                                    onChange={this.handleChange} />
                                            </div>
                                            <div class="col-5 pl-0">
                                                <input
                                                    type="text"
                                                    name="pquantity"
                                                    className='add-item-box'
                                                    placeholder={Constvariable.EnterQuantity}
                                                    value={this.state.userFields.pquantity}
                                                    onChange={this.handleChange} />
                                            </div>
                                            <div class="col-2">  <i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, padding: "10px", fontSize: "20px" }} onClick={() => this.handleAddColorSizeInList()}></i></div>
                                            <div className="errorMsg">
                                                {this.state.errors.colorQuantity}
                                            </div>
                                        </div>
                                        {this.state.colorQuantity.map((e, i) => {
                                            return <div class="row m-0">
                                                <div class="col-5">{e.color}</div>
                                                <div class="col-5">{e.quantity}</div>
                                                <div class="col-2"> <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, padding: "10px", fontSize: "20px" }} onClick={() => this.handleDeleteColorWith(e, i)}></i> </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <label
                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                        data-dismiss="modal"
                                        onClick={() => this.hidemodal2()}> Close</label>
                                    <label
                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                        data-dismiss="modal"
                                        onClick={() => this.submitColorQuantity()}> Submit</label>
                                    {/* <label
                                        // onClick={() => this.handleDelete()}
                                        // onClick={this.handleDelete}
                                        // onClick={this.handleAddToCart}
                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                        data-dismiss="modal"
                                        onClick={() => this.hidemodal2()}
                                    > Yes</label> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </LoadingOverlay>
            </section >

        );
    }
}
export default AddItems;

/* old commented code */
/* ------------------------------------------------------------------------------------------------------ */
// this.handleProductSize = this.handleProductSize.bind(this)
// this.handleProductColor = this.handleProductColor.bind(this)
/* ------------------------------------------------------------------------------------------------------ */
// handleProductSize(selectedProductSize) {
//     this.setState({ selectedProductSize: selectedProductSize ? selectedProductSize : [] })
// }
// handleProductColor(selectedProductColor) {
//     this.setState({ selectedProductColor: selectedProductColor ? selectedProductColor : [] })
// }
/* ------------------------------------------------------------------------------------------------------ */
{/* <div className="reg_form_main col-sm-8 offset-sm-2">
                        <Select  menuPortalTarget={document.body} 
                            value={this.state.selectedRediusunit}
                            onChange={this.handleStateChange}
                            options={this.state.rediusunit}
                            styles={colourStyles}
                            placeholder={Constvariable.catagory}
                            className="select-input"
                         // getOptionLabel={(option) => option.statename}
                         // getOptionValue={(option) => option._id}
                        />
                        <div className="errorMsg">
                            {this.state.errors.radiusUnit}
                        </div>
                    </div> */}

{/* <div className="reg_form_main col-sm-8 offset-sm-2">
                        <Select  menuPortalTarget={document.body} 
                            value={this.state.selectedRediusunit}
                            onChange={this.handleStateChange}
                            options={this.state.rediusunit}
                            styles={colourStyles}
                            placeholder={Constvariable.addItem} />
                        <div className="errorMsg">
                            {this.state.errors.radiusUnit}
                        </div>
                    </div> */}

{/* <div className="reg_form_main col-sm-8 offset-sm-2">	
                            <label className="label-color">{ProductConstForm.ProductSize}	
                                &nbsp;	
                                <OnHoverIconInfo	
                                    message={ProductForm.ProductSize} /> <PutMandatoryfieldSymbol />	
                            </label>	
                            <input	
                                type="text"	
                                name="productSize"	
                                className='add-item-box'	
                                placeholder={Constvariable.E_ProductSizes}	
                                value={this.state.productSizeList.join(', ')} />	
                            <div className="errorMsg">	
                                {this.state.errors.productSize}	
                            </div>	
                            <div>	
                                <button	
                                    style={{ background: Color.THEME_COLOR }}	
                                    for="step5"	
                                    id="continue-step5"	
                                    onClick={this.handleAddSize}	
                                    className="btn btn-done">{ProductConstForm.AddSize}  </button>	
                            </div>	
                        </div> */}

{/* <div className="reg_form_main col-sm-8 offset-sm-2">
                        <input
                            type="text"
                            name="ItemName"
                            className='add-item-box'
                            placeholder={Constvariable.IsCategoryCustomizable}
                            value={this.state.userFields.email} />
                        <div className="errorMsg">
                            {this.state.errors.email}
                        </div>
                    </div> */}

{/* <div className="reg_form_main col-sm-8 offset-sm-2">
                        <input
                            type="text"
                            name="ItemName"
                            className='add-item-box'
                            placeholder={Constvariable.SubCategoryName}
                            value={this.state.userFields.email} />
                        <div className="errorMsg">
                            {this.state.errors.email}
                        </div>
                    </div> */}

{/* <div className="reg_form_main col-sm-8 offset-sm-2">
                        <input
                            type="text"
                            name="ItemName"
                            className='add-item-box'
                            placeholder={Constvariable.IsSubCategoryCustomizable}
                            value={this.state.userFields.email} />
                        <div className="errorMsg">
                            {this.state.errors.email}
                        </div>
                    </div> */}

{/* <div className="reg_form_main col-sm-8 offset-sm-2">
                        <input
                            type="text"
                            name="ItemName"
                            className='add-item-box'
                            placeholder={Constvariable.CustomizationMessage}
                            value={this.state.userFields.email} />
                        <div className="errorMsg">
                            {this.state.errors.email}
                        </div>
                    </div> */}

{/* <div className="reg_form_main col-sm-8 offset-sm-2">
                        <div className="checkbox-input-div">
                            <Checkbox
                                color={Color.THEME_COLOR}
                                size={2}
                                tickSize={2}
                                checked={this.state.checked}
                                delay={0}
                                backAnimationDuration={0}
                                tickAnimationDuration={0}
                                onChange={this.handleCheck} />
                            <span className="Checkbox-label">
                                {Constvariable.IsTopping}
                            </span>
                        </div>
                        <div className="errorMsg">
                            {this.state.errors.email}
                        </div>
                    </div> */}

{/* <div className="reg_form_main col-sm-8 offset-sm-2">
                        <input
                            type="text"
                            name="ItemName"
                            className='add-item-box'
                            placeholder={Constvariable.ParentItem}
                            value={this.state.userFields.email} />
                        <div className="errorMsg">
                            {this.state.errors.email}
                        </div>
                    </div> */}

{/* <input	
                                type="text"	
                                name="productSize"	
                                className='add-item-box'	
                                placeholder={Constvariable.E_ProductSizes}	
                                value={this.state.productColorList.join(', ')}	
                                //onChange={this.handleChange}	
                                /> */}
{/* <div class="row m-0 w-0"> */ }
{/* </div> */ }


{/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ProductConstForm.RewardPoint}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ProductForm.RewardPoint} />
                            </label>
                            <input
                                type="text"
                                name="rewardPoint"
                                className='add-item-box'
                                placeholder={Constvariable.RewardPoint}
                                value={this.state.userFields.rewardPoint}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.rewardPoint}
                            </div>
                        </div> */}

{/* <div className="reg_form_main col-sm-8 offset-sm-2" style={this.state.itemId ? { pointerEvents: "" } : {}}>
                            <div className="checkbox-input-div">
                                <Checkbox
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={this.state.isTopping}
                                    delay={0}
                                    backAnimationDuration={0}
                                    tickAnimationDuration={0}
                                    onChange={() => this.handleIsTopping()} />
                                <span className="Checkbox-label" style={{ color: Color.THEME_COLOR }}>
                                    {Constvariable.IsTopping}
                                </span>
                            </div>
                            <div className="errorMsg">
                                {this.state.errors.email}
                            </div>
                        </div> */}

{/* <div className="btn-upload" style={{ lineHeight: "0px" }}>
                                <label className="btn" for="updateProfile" style={{ padding: "4px 0px 0px 0px ", background: Color.THEME_COLOR }}>
                                    {ProductConstForm.Upload}
                                </label>
                                <input
                                    type="file"
                                    id="updateProfile"
                                    style={{ opacity: "0" }}
                                    data-id="profile"
                                    className="hideAction"
                                    onChange={this.onSelectFile} />
                            </div> */}