import React from 'react';
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';
import Welcome from './components/Welcome/Welcome';
import Store from './components/PlazaCart/Store/Store';
import Home from './components/PlazaCart/Home/Home';
import StoreMain from './components/PlazaCart/StoreMain/StoreMain'
import OrderDetails from './components/PlazaCart/OrderDetails/OrderDetails';
// import Register from './components/PlazaCart/Registration/Registration';
import SelectCountry from './components/PlazaCart/Registration/SelectCountry/SelectCountry';
import SelectPlaza from './components/PlazaCart/Registration/SelectPlaza/SelectPlaza';
import ValidateOTP from './components/PlazaCart/Registration/ValidateOtp/ValidateOTP';
import Login from './components/PlazaCart/Login/Login';
import ForgotPassword from './components/PlazaCart/ForgotPassword/ForgotPassword';
import RegisterNew from './components/PlazaCart/RegistrationNew/RegistrationNew';
import StoreDetails from './components/PlazaCart/StoreDetails/StoreDetails';
import AddProducts from './components/PlazaCart/Items/AddProducts';
import ManageProducts from './components/PlazaCart/ManageProducts/ManageProducts';
import ManageItems from './components/PlazaCart/ManageProducts/ManageItems';
import AddItems from './components/PlazaCart/Items/AddItems';
import RouteMerchant from './components/Config/RouteMerchant.js';
import ToppingsCategoryList from './components/PlazaCart/ToppingsCategory/ToppingsCategoryList';
import AddToppingsCategory from './components/PlazaCart/ToppingsCategory/AddToppingsCategory';
import AddToppings from './components/PlazaCart/Toppings/AddToppings';
import ManageToppings from './components/PlazaCart/Toppings/ManageToppings';
import StoreSupplier from './components/PlazaCart/StoreSupplier/StoreSupplier';
import AddStoreSupplier from './components/PlazaCart/StoreSupplier/AddStoreSupplier';
import Pickuptimes from './components/PlazaCart/Storetimes/Pickuptimes';
import AddPickuptimes from './components/PlazaCart/Storetimes/AddPickuptimes';
import Deliverytimes from './components/PlazaCart/Storetimes/Deliverytimes';
import AddDeliverytimes from './components/PlazaCart/Storetimes/AddDeliverytimes'
import ManageInventory from './components/PlazaCart/Inventory/ManageInventory';
import UpdateInventory from './components/PlazaCart/Inventory/UpdateInventory';
import InventoryHistory from './components/PlazaCart/Inventory/InventoryHistory';
import Notification from './components/Notification/Notification';
import NotificationDetail from './components/Notification/NotificationDetail';
import UserProfile from './components/UserProfile/UserProfile';
import PurchaseOrder from './components/PlazaCart/PurchaseOrder/PurchaseOrder';
import PurchaseOrderDetail from './components/PlazaCart/PurchaseOrder/PurchaseOrderDetail';
import AddPurchaseOrder from './components/PlazaCart/PurchaseOrder/AddPurchaseOrder';
import ReceiveInventory from './components/PlazaCart/Inventory/ReceiveInventory';
import Earnings from './components/PlazaCart/Earnings/Earnings';
import ItemsList from './components/PlazaCart/ManageProducts/Itemslist';
// import SelectStore from './components/Store/SelectStore'

import HomeShopify from './components/ShopifyComponent/Home/HomeShopify.js'
import ShopifyOrderDetails from './components/ShopifyComponent/OrderDetails/ShopifyOrderDetails.js'
import PayPalPayment from './components/Payment/PayPal/PayPalPayment'
import PayPalPaymentCheckout from './components/Payment/PayPal/PayPalPaymentCheckout'
import Subscription from './components/Subscription/Subscription'
import MySubscription from './components/Subscription/MySubscription'
import SubscriptionUpdate from './components/Subscription/SubscriptionUpdate'
import PlazaMain from './components/PlazaCart/StoreMain/PlazaMain'

import MySubscriptionOnly from './components/SubscriptionOnly/MySubscriptionOnly'
import SubscriptionUpdateOnly from './components/SubscriptionOnly/SubscriptionUpdateOnly'
import SubscriptionOnly from './components/SubscriptionOnly/SubscriptionOnly'
import AddPlaza from './components/PlazaCart/StoreMain/AddPlaza'
import ManageCoupon from './components/PlazaCart/Coupon/ManageCoupon'
import AddCoupon from './components/PlazaCart/Coupon/AddCoupon'
import Redemption from './components/PlazaCart/Coupon/Redemption'

import StoreWithapi from './components/PlazaCart/Store/StoreWithapi';
import RegistrationNewWithapi from './components/PlazaCart/RegistrationNew/RegistrationNewWithapi'

import BulkUploadCatalog from './components/PlazaCart/BulkUploadCatalog/BulkUploadCatalog';
import ImageBulkUpload from './components/PlazaCart/BulkUploadCatalog/ImageBulkUpload';
import StoreMainWithAdd from './components/PlazaCart/StoreMain/StoreMainWithAdd'

import DoordashSetup from './components/doordashSetup/DoordashSetup'
import UserManagement from './components/userManagement/UserManagement'
import AddUser from './components/userManagement/AddUser'
import StoreAssign from './components/userManagement/StoreAssign';
import StoreHour from './components/PlazaCart/Storetimes/StoreHour';
import TermsofService from './components/Pages/TermsofService';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import AboutUs from './components/Pages/AboutUs';
import Pricing from './components/Pages/Pricing';
import HelpAndSupport from './components/Pages/HelpAndSupport';
import SetAppointment from './components/SetAppointment/SetAppointment';
import StripeStandardPayment from './components/Payment/StripeMerchant/StripeStandardPayment';
import StripeStandardCheckout from './components/Payment/StripeMerchant/StripeStandardCheckout';
import StripeExpressPayment from './components/Payment/StripePlatform/StripeExpressPayment';
import StripeExpressCheckout from './components/Payment/StripePlatform/StripeExpressCheckout';
import ContactUs from './components/Pages/ContactUs';

import TaxList from './components/Tax/TaxList';
import MasterContextProvider from './components/MasterContextProvider/MasterContextProvider';

const Routes = () => (
    <BrowserRouter>
        <div>
            <Switch>
                <MasterContextProvider>
                    <Route exact path={RouteMerchant.Default} component={Login} />
                    <Route exact path={RouteMerchant.Login} component={Login} />

                    {/* <Route exact path={RouteMerchant.Register} component={RegistrationNewWithapi} /> */}
                    <Route exact path={RouteMerchant.Register} component={RegistrationNewWithapi} />
                    <Route exact path={RouteMerchant.RegisterNew} component={RegistrationNewWithapi} />
                    <Route exact path={RouteMerchant.Validateotp} component={ValidateOTP} />
                    <Route exact path={RouteMerchant.ForgotPassword} component={ForgotPassword} />

                    <Route exact path={RouteMerchant.StoreMain} component={StoreMainWithAdd} />
                    {/* <Route exact path={RouteMerchant.StoreMain} component={StoreMain} /> */}
                    <Route exact path={RouteMerchant.PlazaMain} component={PlazaMain} />
                    <Route exact path={RouteMerchant.AddPlaza} component={AddPlaza} />
                    <Route exact path={RouteMerchant.Store} component={StoreWithapi} />
                    {/* <Route exact path={RouteMerchant.Store} component={Store} /> */}

                    {/* <Route exact path={RouteMerchant.SelectStore} component={SelectStore} /> */}
                    <Route exact path={RouteMerchant.Home} component={Home} />
                    <Route exact path={RouteMerchant.OrderDetails} component={OrderDetails} />
                    <Route exact path={RouteMerchant.Selectcountry} component={SelectCountry} />
                    <Route exact path={RouteMerchant.Selectplaza} component={SelectPlaza} />
                    <Route exact path={RouteMerchant.Storedetails} component={StoreDetails} />

                    <Route exact path={RouteMerchant.AddProducts} component={AddProducts} />
                    <Route exact path={RouteMerchant.AddItems} component={AddItems} />
                    <Route exact path={RouteMerchant.ManageProducts} component={ManageProducts} />
                    <Route exact path={RouteMerchant.ManageItems} component={ManageItems} />
                    <Route exact path={RouteMerchant.ManageToppingCategory} component={ToppingsCategoryList} />
                    <Route exact path={RouteMerchant.AddToppingCategory} component={AddToppingsCategory} />
                    <Route exact path={RouteMerchant.AddToppings} component={AddToppings} />
                    <Route exact path={RouteMerchant.ManageToppings} component={ManageToppings} />

                    <Route exact path={RouteMerchant.StoreSupplier} component={StoreSupplier} />
                    <Route exact path={RouteMerchant.AddStoreSupplier} component={AddStoreSupplier} />
                    {/* <Route exact path={RouteMerchant.Pickuptimes} component={Pickuptimes} /> */}
                    <Route exact path={RouteMerchant.Pickuptimes} component={StoreHour} />
                    <Route exact path={RouteMerchant.AddPickuptimes} component={AddPickuptimes} />
                    <Route exact path={RouteMerchant.Deliverytimes} component={Deliverytimes} />
                    <Route exact path={RouteMerchant.AddDeliverytimes} component={AddDeliverytimes} />

                    <Route exact path={RouteMerchant.Inventory} component={ManageInventory} />
                    <Route exact path={RouteMerchant.UpdateInventory} component={UpdateInventory} />
                    <Route exact path={RouteMerchant.InventoryHistory} component={InventoryHistory} />
                    <Route exact path={RouteMerchant.Notification} component={Notification} />
                    <Route exact path={RouteMerchant.NotificationDetail} component={NotificationDetail} />
                    <Route exact path={RouteMerchant.UserProfile} component={UserProfile} />
                    <Route exact path={RouteMerchant.PurchaseOrder} component={PurchaseOrder} />
                    <Route exact path={RouteMerchant.PurchaseOrderDetail} component={PurchaseOrderDetail} />
                    <Route exact path={RouteMerchant.AddPurchaseOrder} component={AddPurchaseOrder} />
                    <Route exact path={RouteMerchant.ReceiveInventory} component={ReceiveInventory} />
                    <Route exact path={RouteMerchant.Earnings} component={Earnings} />

                    <Route exact path={RouteMerchant.ItemList} component={ItemsList} />

                    <Route exact path={RouteMerchant.HomeShopify} component={HomeShopify} />
                    <Route exact path={RouteMerchant.ShopifyOrderDetails} component={ShopifyOrderDetails} />

                    {/* Paypal Payment Flow */}
                    <Route exact path={RouteMerchant.PayPalPayment} component={PayPalPayment} />
                    <Route exact path={RouteMerchant.PayPalPaymentCheckout} component={PayPalPaymentCheckout} />

                    {/* Stripe Standard Payment Flow */}
                    <Route exact path={RouteMerchant.StripeStandardPayment} component={StripeStandardPayment} />
                    <Route exact path={RouteMerchant.StripeStandardCheckout} component={StripeStandardCheckout} />

                    {/* Stripe Express Payment Flow */}
                    <Route exact path={RouteMerchant.StripeExpressPayment} component={StripeExpressPayment} />
                    <Route exact path={RouteMerchant.StripeExpressCheckout} component={StripeExpressCheckout} />

                    <Route exact path={RouteMerchant.Subscription} component={Subscription} />
                    <Route exact path={RouteMerchant.MySubscription} component={MySubscription} />
                    <Route exact path={RouteMerchant.SubscriptionUpdate} component={SubscriptionUpdate} />

                    <Route exact path={RouteMerchant.ManageCoupon} component={ManageCoupon} />
                    <Route exact path={RouteMerchant.AddCoupon} component={AddCoupon} />

                    <Route exact path={RouteMerchant.Redemption} component={Redemption} />

                    <Route exact path={RouteMerchant.BulkUpload} component={BulkUploadCatalog} />
                    <Route exact path={RouteMerchant.ImageBulkUpload} component={ImageBulkUpload} />

                    <Route exact path={RouteMerchant.doordashConfig} component={DoordashSetup} />

                    <Route exact path={RouteMerchant.merchantUser} component={UserManagement} />
                    <Route exact path={RouteMerchant.AddMerchantUser} component={AddUser} />
                    <Route exact path={RouteMerchant.StoreAssign} component={StoreAssign} />

                    {/* <Route exact path={RouteMerchant.SubscriptionOnly} component={SubscriptionOnly} />
        <Route exact path={RouteMerchant.MySubscriptionOnly} component={MySubscriptionOnly} />
        <Route exact path={RouteMerchant.SubscriptionUpdateOnly} component={SubscriptionUpdateOnly} /> */}

                    <Route exact path={RouteMerchant.TermsOfService} component={TermsofService} />
                    <Route exact path={RouteMerchant.PrivacyPolicy} component={PrivacyPolicy} />
                    <Route exact path={RouteMerchant.AboutUs} component={AboutUs} />
                    <Route exact path={RouteMerchant.HelpAndSupport} component={HelpAndSupport} />
                    <Route exact path={RouteMerchant.Pricing} component={Pricing} />
                    <Route exact path={RouteMerchant.SetAppointment} component={SetAppointment} />
                    <Route exact path={RouteMerchant.ContactUs} component={ContactUs} />

                    <Route exact path={RouteMerchant.ManageTaxes} component={TaxList} />
                </MasterContextProvider>
            </Switch>
        </div>
    </BrowserRouter>
);

export default Routes;