import Moment from 'moment';
import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import { GetData } from "../../../services/GetData";
import { PostData } from '../../../services/PostData';
import Color from '../../Config/Color';
import { CATALOG_ERRORS_API, CATALOG_HISTORY_API, CATALOG_SAMPLEFILE_API, CATALOG_SETUP_API, CATALOG_UPLOAD_API, CATEGORY_KEY, MY_STORE_API, PLAZAID, STOREID } from '../../Config/Config.json';
import Constants, { DEFAULTID } from '../../Config/Constants';
import { BulkCatalogUploadList } from '../../Config/ConstForm';
import { DatetimeFormate } from '../../Config/constvariable';
import Messages from "../../Config/Messages";
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import Header from '../../Header/Header.js';
import './BulkUploadCatalog.css';

class BulkUploadCatalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogHistory: [],
            catalogError: [],

            storeId: '',
            plazaId: "",

            selectedFileLocal: null,
            selectedFileName: "",
            serverFielName: "",

            samplefileUrl: "",

            storeCategory: "",

            isActive: false,
            errors: {}
        }

        this.fileDrop = this.fileDrop.bind(this);
        this.handleFileInput = this.handleFileInput.bind(this);
        this.handleUploadSubmit = this.handleUploadSubmit.bind(this);
        this.handleFileProcess = this.handleFileProcess.bind(this);
        // this.handleAddItems = this.handleAddItems.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID;
            this.setState({ storeId: storeId, plazaId: plazaId });
            this.getStoredetails(authToken, storeId, plazaId);
            this.getCatalogHistoryAPI(authToken, storeId, plazaId);
            this.getCatalogErrorAPI(authToken, storeId, plazaId);
        } else {
            this.props.history.goBack()
        }
    }

    getCatalogHistoryAPI(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} })
        var URL = CATALOG_HISTORY_API + "?" + STOREID + storeId + "&" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    this.setState({ catalogHistory: responseData ? responseData : [] })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getCatalogErrorAPI(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} })
        var URL = CATALOG_ERRORS_API + "?" + STOREID + storeId + "&" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    this.setState({ catalogError: responseData ? responseData : [] });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getStoredetails(authToken, storeid, plazaid) {
        this.setState({ isActive: true, errors: {} })
        var URL = MY_STORE_API + "/" + storeid + "?" + PLAZAID + plazaid;
        console.log("authToken", URL);
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.storedetail) {
                        this.setState({ storeCategory: responseData.storedetail.Category ? responseData.storedetail.Category : "" });
                        this.getCatalogSample(authToken, responseData.storedetail.Category);
                    }
                    else {
                        this.setState({ storeCategory: "" });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getCatalogSample(authToken, storeCategory) {
        this.setState({ isActive: true, errors: {} })
        var URL = CATALOG_SAMPLEFILE_API + "?" + CATEGORY_KEY + storeCategory;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    this.setState({ samplefileUrl: responseData ? responseData : "" });
                    // this.setState({ samplefileUrl: "https://plazacart.blob.core.windows.net/plazacartdev/b741165e-ef0c-4b12-b42a-fc6bb0c61f88/Catalogfiles/sample_20220602051020_20220610034728.csv?sv=2020-08-04&ss=b&srt=sco&sp=rtf&se=2022-12-31T21:33:04Z&st=2022-05-23T12:33:04Z&spr=https&sig=CzEXExr21ZbWbN4EbICaMidwNcksXu%2BXWmQy%2BadOWS8%3D" });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    // ////////////////////////////////// End Detail api //////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    openfileUploadPanel() {
        if (document.getElementById("ctfileId")) {
            document.getElementById("ctfileId").value = null;
            document.getElementById("ctfileId").click();
        }
    }

    fileDrop(e) {
        this.setState({ errors: {} });
        e.preventDefault();
        const files = e.dataTransfer.files;
        console.log(files);
        try {
            for (var i in files) {
                var fileobj = files[i];
                if (fileobj.size > 0) {

                    var fileName = files[i].name;
                    var fileSize = files[i].size;
                    var extension = ["csv"]
                    var fileSplit = fileName.split('.');
                    var filelength = fileSplit.length
                    var fileExtension = fileSplit[filelength - 1]
                    var extensionFlag = extension.includes(fileExtension)
                    if (extensionFlag) {
                        this.masterConvertion(fileobj, fileName, fileExtension)
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.v_FileExtension
                        this.setState({ errors: errors });
                    }
                }
            }
        }
        catch (err) {
            let errors = {};
            errors["ErrorC"] = "File Upload Error"
            this.setState({ errors: errors });
            console.log("File Upload Error", err)
        }
        return false
    }

    handleFileInput(event) {
        try {
            this.setState({ errors: {} });
            event.preventDefault();
            for (var i in event.target.files) {
                var fileobj = event.target.files[i];
                if (fileobj.size > 0) {
                    var fileName = event.target.files[i].name;
                    var fileSize = event.target.files[i].size;
                    var extension = ["csv"]
                    var fileSplit = fileName.split('.');
                    var filelength = fileSplit.length
                    var fileExtension = fileSplit[filelength - 1]
                    var extensionFlag = extension.includes(fileExtension)
                    if (extensionFlag) {
                        this.masterConvertion(fileobj, fileName, fileExtension)
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.v_FileExtension
                        this.setState({ errors: errors });
                    }
                }
            }
        }
        catch (err) {
            let errors = {};
            errors["ErrorC"] = "File Upload Error"
            this.setState({ errors: errors });
            console.log("File Upload Error", err);
        }
    };


    masterConvertion = (fileObj, fileName, fileExtension) => new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            console.log(fileObj);
            reader.readAsDataURL(fileObj);
            reader.onload = (fileLoadedEvent) => {
                var fileObj = {}
                fileObj['base64data'] = fileLoadedEvent.target.result
                fileObj['filename'] = fileName
                fileObj['type'] = fileExtension
                this.option2fileVisible()
                this.setState({
                    selectedFileLocal: fileLoadedEvent.target.result,
                    selectedFileName: fileName
                })
            };
            reader.onerror = error => reject(error);
        } catch (e) {
            console.log("in Promise", errors)
        }
    })

    handleUploadSubmit(e) {
        var postData = {};
        e.preventDefault();
        let storeId = this.state.storeId;
        let plazaId = this.state.plazaId;
        let selectedFileName = this.state.selectedFileName;
        let selectedFileLocal = this.state.selectedFileLocal;
        if (storeId && selectedFileName && selectedFileLocal) {
            postData['storeid'] = storeId;
            postData['plazaid'] = plazaId;
            postData['filename'] = selectedFileName;
            postData['base64'] = selectedFileLocal;
            console.log("=====>", JSON.stringify(postData))

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

            this.uploadFileAPI(authToken, postData)
        }
    }

    uploadFileAPI(authToken, postData) {
        this.setState({ isActive: true, errors: {} })
        var URL = CATALOG_UPLOAD_API
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log(URL, result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    var responseData = responseJson["Data"];
                    this.setState({ serverFielName: responseData ? responseData : "" })
                }
                else {
                    this.getCatalogErrorAPI(authToken, postData.storeid, postData.plazaid)
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false });
    }

    handleFileProcess(e) {
        var postData = {};
        e.preventDefault();
        let storeId = this.state.storeId;
        let plazaId = this.state.plazaId;
        let serverFielName = this.state.serverFielName;
        if (storeId && serverFielName) {
            postData['storeid'] = storeId;
            postData['plazaid'] = plazaId;
            postData["azurestoragefilename"] = this.state.serverFielName
            console.log("=====>", JSON.stringify(postData))

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

            this.fileSetupAPI(authToken, postData)
        }
    }

    fileSetupAPI(authToken, postData) {
        this.setState({ isActive: true, errors: {} })
        var URL = CATALOG_SETUP_API
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log(URL, result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.handleReset()
                    this.getCatalogHistoryAPI(authToken, postData.storeid, postData.plazaid)
                }
                else {
                    this.getCatalogErrorAPI(authToken, postData.storeid, postData.plazaid)
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false });
    }

    handleReset() {
        this.setState({
            selectedFileLocal: null,
            selectedFileName: "",
            serverFielName: "",
        })
        this.option1fileVisible()
    }

    option1fileVisible() {
        var option1file = document.getElementById("option1file")
        var option2file = document.getElementById("option2file")
        if (option1file && option2file) {
            option1file.style.display = "block"
            option2file.style.display = "none"
        }
    }

    option2fileVisible() {
        var option1file = document.getElementById("option1file")
        var option2file = document.getElementById("option2file")
        if (option1file && option2file) {
            option1file.style.display = "none"
            option2file.style.display = "block"
        }
    }

    render() {
        const { hideEmptyPlazaView } = this.state;
        const columns = [
            {
                name: BulkCatalogUploadList.T1_FileName,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.filename}</div>,
            },
            {
                name: BulkCatalogUploadList.T1_Description,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.description}</div>,
            },
            {
                name: BulkCatalogUploadList.T1_CreatedDate,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{Moment.utc(row.createddate).format(DatetimeFormate.DDMMYYYY)}</div>,
            }
        ];

        const errColumns = [
            {
                name: BulkCatalogUploadList.T2_Action,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.action}</div>,
            },
            {
                name: BulkCatalogUploadList.T2_Description,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.description}</div>,
            },
            {
                name: BulkCatalogUploadList.T2_CreatedDate,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{Moment.utc(row.createddate).format(DatetimeFormate.DDMMYYYY)}</div>,
            }
        ];

        return (
            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    // active={true}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={BulkCatalogUploadList.BulkCatalogUpload} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="registered-with col-lg-12" >
                                <span>
                                    {BulkCatalogUploadList.Download_S_T1} <a href={this.state.samplefileUrl} className="setValue sethyperlink" >{BulkCatalogUploadList.Download_S_T2} </a> {BulkCatalogUploadList.Download_S_T3}
                                </span>
                            </div>

                            <div className="reg_form_main col-lg-12">
                                <form action="#" class="form-horizontal" id="option1file">
                                    <textarea
                                        id="files"
                                        name="files"
                                        placeholder={BulkCatalogUploadList.AddFile}
                                        class="form-control input-sm allinputstyle dragfiles"
                                        onDrop={this.fileDrop}
                                        onClick={() => this.openfileUploadPanel()}
                                        value={BulkCatalogUploadList.AddFile}
                                        rows="4" />
                                    <input className="catalogfile-input"
                                        type="file" id="ctfileId" accept=".csv" onChange={this.handleFileInput} />
                                </form>

                                <div id="option2file" style={{ display: "none" }}>
                                    <div className="file-uploadedbox" >
                                        <div className="">
                                            {this.state.serverFielName ? this.state.serverFielName : this.state.selectedFileName}
                                        </div>
                                        {!this.state.serverFielName && <div className="">
                                            <label
                                                style={{ background: Color.THEME_COLOR }}
                                                onClick={() => this.openfileUploadPanel()}
                                                className="btn btn-done"
                                            > {BulkCatalogUploadList.btn_Replace_File}</label>
                                        </div>}
                                    </div>
                                </div>

                                <div className="footer-button">
                                    <label
                                        style={{ background: Color.GREY, color: Color.THEME_COLOR }}
                                        onClick={() => this.handleReset()}
                                        className="btn btn-done"
                                    > {BulkCatalogUploadList.btn_Cancel}</label>
                                    {this.state.serverFielName ? <label
                                        style={{ background: Color.THEME_COLOR }}
                                        className="btn btn-done"
                                        onClick={this.handleFileProcess}
                                    > {BulkCatalogUploadList.btn_Import_Products}</label> : <label
                                        style={{ background: Color.THEME_COLOR }}
                                        className="btn btn-done"
                                        onClick={this.handleUploadSubmit}
                                    > {BulkCatalogUploadList.btn_Upload_and_Continue}</label>}
                                </div>

                                <div className="history-container">
                                    <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">{BulkCatalogUploadList.CatalogHistory}</a>
                                            <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">{BulkCatalogUploadList.CatalogErrors}</a>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane fade show active history-container" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <DataTableExtensions
                                                data={this.state.catalogHistory}
                                                columns={columns}
                                                export={false}
                                                print={false}
                                                filter={false}
                                                // filterHidden={false}
                                                // filterPlaceholder={Messages.SearchforCategory}
                                                filterStyle={{ width: "100px" }}
                                            >
                                                <DataTable
                                                    noHeader={true}
                                                    // noTableHead={true}
                                                    pagination
                                                    paginationRowsPerPageOptions={[10, 20, 30, 40]}

                                                />
                                            </DataTableExtensions>
                                        </div>
                                        <div class="tab-pane fade history-container" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <DataTableExtensions
                                                data={this.state.catalogError}
                                                columns={errColumns}
                                                export={false}
                                                print={false}
                                                filter={false}
                                                // filterHidden={false}
                                                // filterPlaceholder={Messages.SearchforCategory}
                                                filterStyle={{ width: "100px" }}
                                            >
                                                <DataTable
                                                    noHeader={true}
                                                    // noTableHead={true}
                                                    pagination
                                                    paginationRowsPerPageOptions={[10, 20, 30, 40]}

                                                />
                                            </DataTableExtensions>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteCategoryTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteCategory}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div >

        );
    }
}

export default BulkUploadCatalog;