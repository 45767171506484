import { data1 } from './data.json'

export function setTaxLabelValue(productSet, StateTaxList) {
  try {

    let StateTaxListdefult = StateTaxList.filter(e => e.isdefault);
    // let { StateTaxList, productSet } = state;
    productSet = productSet.map(masterCatObj => {



      masterCatObj.taxes = masterCatObj.taxes ? masterCatObj.taxes : []
      masterCatObj.subcategories = masterCatObj.subcategories ? masterCatObj.subcategories : []

      // category level
      let category = masterCatObj.subcategories.map(categoryObj => {

        categoryObj.taxes = categoryObj.taxes ? categoryObj.taxes : []
        categoryObj.products = categoryObj.products ? categoryObj.products : []

        // item list
        let itemList = categoryObj.products.map(itemObj => {
          itemObj.taxes = itemObj.taxes ? itemObj.taxes : []

          let itemTaxes = itemObj.taxes.filter(e => !StateTaxListdefult.some(e1 => e1._id == e.taxid))
          return {
            ...itemObj,
            "taxes": [...StateTaxListdefult, ...StateTaxList.filter(e => itemTaxes.some(e1 => e1.taxid == e._id))]
          }
        })
        // end item list

        let categoryTaxes = categoryObj.taxes.filter(e => !StateTaxListdefult.some(e1 => e1._id == e.taxid))
        return {
          ...categoryObj,
          "products": itemList,
          "taxes": [...StateTaxListdefult, ...StateTaxList.filter(e => categoryTaxes.some(e1 => e1.taxid == e._id))]
        }
      })
      // end category level

      let masterCatTaxes = masterCatObj.taxes.filter(e => !StateTaxListdefult.some(e1 => e1._id == e.taxid))
      return {
        ...masterCatObj,
        "subcategories": category,
        "taxes": [...StateTaxListdefult, ...StateTaxList.filter(e => masterCatTaxes.some(e1 => e1.taxid == e._id))]
      }
    })

    return productSet
  }
  catch (err) {
    console.log(err);
    return []
  }
}

// export function masterCategorychangeValue(state, value, ind) {
//   try {
//     let { StateTaxList, productSet } = state;
//     // productSet = productSet.map((masterCatObj, index) => {

//     //   if (index == ind) {
//     //     // subcategories level
//     //     let subcategories = masterCatObj.subcategories.map(subcategoriesObj => {

//     //       // products list
//     //       let productsList = subcategoriesObj.products.map(productsObj => {
//     //         return {
//     //           ...productsObj,
//     //           "taxes": value
//     //         }
//     //       })
//     //       // end products list

//     //       return {
//     //         ...subcategoriesObj,
//     //         "products": productsList,
//     //         "taxes": value
//     //       }
//     //     })
//     //     // end subcategories level


//     //     return {
//     //       ...masterCatObj,
//     //       "subcategories": subcategories,
//     //       "taxes": value
//     //     }
//     //   }
//     //   return masterCatObj
//     // })

//     productSet[ind].taxes = value
//     productSet[ind].subcategories = productSet[ind].subcategories.map(subcategoriesObj => {

//       let productsList = subcategoriesObj.products.map(productsObj => {
//         return {
//           ...productsObj,
//           "taxes": value
//         }
//       })

//       return {
//         ...subcategoriesObj,
//         "taxes": value,
//         "products": productsList
//       }
//     })

//     return productSet
//   }
//   catch (err) {
//     console.log(err);
//     return []
//   }
// }

export function masterCategorychangeValueSelect(state, value, obj, ind) {
  try {
    let { StateTaxList, productSet } = state;

    productSet[ind].taxes = value
    productSet[ind].subcategories = productSet[ind].subcategories.map(subcategoriesObj => {

      let productsList = subcategoriesObj.products.map(productsObj => {
        let producttaxes = productsObj.taxes
        let isproductList = producttaxes.some(e1 => e1._id == obj.option._id)
        if (!isproductList) {
          producttaxes = [...producttaxes, obj.option]
        }
        return {
          ...productsObj,
          "taxes": producttaxes
        }
      })

      let catTaxes = subcategoriesObj.taxes;
      let isCatinTax = catTaxes.some(e1 => e1._id == obj.option._id)
      if (!isCatinTax) {
        catTaxes = [...catTaxes, obj.option]
      }
      return {
        ...subcategoriesObj,
        "taxes": catTaxes,
        "products": productsList
      }
    })

    return productSet
  }
  catch (err) {
    console.log(err);
    return []
  }
}

export function masterCategorychangeValueRemove(state, value, obj, ind) {
  try {
    let { StateTaxList, productSet } = state;

    productSet[ind].taxes = value
    productSet[ind].subcategories = productSet[ind].subcategories.map(subcategoriesObj => {

      let productsList = subcategoriesObj.products.map(productsObj => {
        return {
          ...productsObj,
          "taxes": productsObj.taxes.filter(e => e._id !== obj.removedValue._id)
        }
      })

      return {
        ...subcategoriesObj,
        "taxes": subcategoriesObj.taxes.filter(e => e._id !== obj.removedValue._id),
        "products": productsList
      }
    })

    return productSet
  }
  catch (err) {
    console.log(err);
    return []
  }
}

// export function categorychangeValue(state, value,obj, mind, ind) {
//   try {
//     let { StateTaxList, productSet } = state;
//     // productSet = productSet.map((masterCatObj, index) => {

//     //   if (index == mind) {
//     //     // subcategories level
//     //     let subcategories = masterCatObj.subcategories.map((subcategoriesObj, index1) => {

//     //       if (index1 == ind) {
//     //         // products list
//     //         let productsList = subcategoriesObj.products.map(productsObj => {
//     //           return {
//     //             ...productsObj,
//     //             "taxes": value
//     //           }
//     //         })
//     //         // end products list

//     //         return {
//     //           ...subcategoriesObj,
//     //           "products": productsList,
//     //           "taxes": value
//     //         }
//     //       }
//     //       return subcategoriesObj
//     //     })
//     //     // end subcategories level


//     //     return {
//     //       ...masterCatObj,
//     //       "subcategories": subcategories
//     //     }
//     //   }
//     //   return masterCatObj
//     // })

//     productSet[mind].subcategories[ind].taxes = value;
//     productSet[mind].subcategories[ind].products = productSet[mind].subcategories[ind].products.map(productsObj => {
//       return {
//         ...productsObj,
//         "taxes": value
//       }
//     })

//     return productSet
//   }
//   catch (err) {
//     console.log(err);
//     return []
//   }
// }

export function categorychangeValueSelect(state, value, obj, mind, ind) {
  try {
    let { StateTaxList, productSet } = state;

    productSet[mind].subcategories[ind].taxes = value;
    productSet[mind].subcategories[ind].products = productSet[mind].subcategories[ind].products.map(productsObj => {
      let taxesarr = productsObj.taxes
      let isproductList = taxesarr.some(e1 => e1._id == obj.option._id)
      if (!isproductList) {
        taxesarr = [...taxesarr, obj.option]
      }
      return {
        ...productsObj,
        "taxes": taxesarr
      }
    })

    return productSet
  }
  catch (err) {
    console.log(err);
    return []
  }
}

export function categorychangeValueRemove(state, value, obj, mind, ind) {
  try {
    let { StateTaxList, productSet } = state;

    productSet[mind].subcategories[ind].taxes = value;
    productSet[mind].subcategories[ind].products = productSet[mind].subcategories[ind].products.map(productsObj => {
      return {
        ...productsObj,
        "taxes": productsObj.taxes.filter(e => e._id !== obj.removedValue._id)
      }
    })

    return productSet
  }
  catch (err) {
    console.log(err);
    return []
  }
}

export function productchangeValue(state, value, mind, cind, ind) {
  try {
    let { StateTaxList, productSet } = state;
    // productSet = productSet.map((masterCatObj, index) => {

    //   if (index == mind) {
    //     // subcategories level
    //     let subcategories = masterCatObj.subcategories.map((subcategoriesObj, index1) => {

    //       if (index1 == cind) {
    //         // products list
    //         let productsList = subcategoriesObj.products.map((productsObj, index3) => {

    //           if (index3 == ind) {
    //             return {
    //               ...productsObj,
    //               "taxes": value
    //             }
    //           }
    //           return productsObj
    //         })

    //         // end products list

    //         return {
    //           ...subcategoriesObj,
    //           "products": productsList
    //         }

    //       }

    //       return subcategoriesObj
    //     })
    //     // end subcategories level


    //     return {
    //       ...masterCatObj,
    //       "subcategories": subcategories
    //     }
    //   }
    //   return masterCatObj
    // })

    productSet[mind].subcategories[cind].products[ind].taxes = value
    // let allTaxListEmpty = true
    // for(var ob of productSet[mind].subcategories[cind].products){
    //   if(ob.taxes.length>0){
    //     allTaxListEmpty = false;
    //     break;
    //   }
    // }
    // if(allTaxListEmpty){
    //   productSet[mind].subcategories[cind].taxes = []
    // }

    return productSet
  }
  catch (err) {
    console.log(err);
    return []
  }
}

export function postCategorymodal(state) {
  try {
    let { productSet } = state
    productSet = productSet.map(categoryObj => {
      let subcategories = categoryObj.subcategories.map(subCategoyObj => {
        let products = subCategoyObj.products.map(productOb => {
          let withoutDefultitem = productOb.taxes.filter(e => !e.isdefault)
          return {
            "productid": productOb.productid,
            "taxes": productOb.IsProductTax ? [] : withoutDefultitem.map(e => ({ "taxid": e._id }))
          }
        })
        let withoutDefultsuncat = subCategoyObj.taxes.filter(e => !e.isdefault)
        return {
          "subcategoryid": subCategoyObj.subcategoryid,
          "taxes": withoutDefultsuncat.map(e => ({ "taxid": e._id })),
          "products": products
        }

      })
      let withoutDefultcat = categoryObj.taxes.filter(e => !e.isdefault)
      return {
        "categoryid": categoryObj.categoryid,
        "taxes": withoutDefultcat.map(e => ({ "taxid": e._id })),
        "subcategories": subcategories
      }
    })

    return productSet
  }
  catch (err) {
    console.log("error");
    return ""
  }
}