const ProductConstForm = {
    itemName: "Product Name",
    catagory: "Category",
    addItem: "Add Product",
    ItemPrice: "Product Price",
    InternalProductId: "Product Internal Id",
    ItemDescription: "Product Generic Description",
    Description: "Description",
    SubCategoryName: "SubCategory Name",
    IsSubCategoryCustomizable: "IsSubCategory Customizable",
    IsTopping: "IsTopping",
    ParentItem: "Parent Product",
    ProductType: "Product Measurement Options",
    ProductSize: "Product Size",
    EnterAvailableSizes: "Enter Available Sizes",
    ProductColor: "Product Color (optional)",
    ProductSizeColorDescription: "Product Size, Color, and Description",
    EnterAvailableColors: "Enter Available Colors",
    Color: "Color",
    Quantity: "Quantity",
    EnterAvailableColorsSize: "Enter Available Color & Quantity",
    s_ProductCategory: "Product Category",
    WeightType: "Weight Type",
    Weight: "Product Weight",
    CostPerItem: "Product Cost",
    ToppingMinimumQuantity: "Topping Minimum Quantity",
    ToppingMinimumQuantityNote: "The following field (Toppings) applies to restaurant or food delivery items.",
    ToppingMaximumQuantity: "Topping Maximum Quantity",
    ToppingMaximumQuantityNote: "The following field (Toppings) applies to restaurant or food delivery items.",
    IsSpecialDeliveryItem: "Special Delivery Product",
    tax: "Tax",
    IsProductTaxFree: "Select yes, if the product is a tax exempted.",
    Yes: "Yes",
    No: "No",
    addItemImage: "Add Product Image",
    RewardPoint: "Reward Point",
    SubmitItem: "Add Products",
    SKU: "SKU (Stock Keeping Unit)",
    BarCode: "UPC (Universal Product Code)",
    AvailableQuantity: "Available Quantity",
    isTrackQuantity: "Track Quantity",
    IsTopping: "Topping",
    ContinueSelling: "Continue Selling",
    ProductWeightUnit: "Product Weight Unit",
    Others: "Others",
    Upload: "Upload",
    EditLabel: "Edit",
    AddSize: "Add Size",
    AddColor: "Add Color",
    AddSizeColorDescription: "Add Size, Color, And Description",
    addItemImage: "Product Image",
    btn_AddProduct: "Add Product",
    UpdateProduct: "Update Product",
    btn_UpdateProduct: "Update Product",
    ApplicableTax: "Applicable Tax (%)"
}


export const ToppingsCategoryConstForm = {
    ToppingCategory: "Topping Category",
    AddToppingsCategory: "Add Toppings Category",
    btn_AddToppingsCategory: "Add Toppings Category",
    UpdateToppingCategory: "Update Topping Category",
    btn_UpdateToppingCategory: "Update Topping Category"
}

export const ToppingsConstForm = {
    ToppingName: "Topping Name",
    ToppingPrice: "Topping Price",
    AddTopping: "Add Topping",
    UpdateTopping: "Update Topping",
    btn_AddTopping: "Add Topping",
    btn_UpdateTopping: "Update Topping"
}

export const StoreSupplierConstForm = {
    SupplierName: "Supplier Name",
    Address: "Address",
    ApartmentName: "Apartment Name",
    CityName: "City Name",
    StateName: "State Name",
    CountryName: "Country Name",
    PostalCode: "Postal Code",
    ContactName: "Contact Name",
    Email: "Email",
    PhoneNumbe: "Phone Number",
    AddStoreSupplier: "Add Brewery Supplier",
    UpdateStoreSupplier: "Update Brewery Supplier",
    btn_AddStoreSupplier: "Add Brewery Supplier",
    btn_UpdateStoreSupplier: "Update Brewery Supplier"
}

export const UpdateInventoryConstForm = {
    NewQuantity: "New Quantity",
    ProductDescription: "Product Description",
}

export const ReceiveInventoryConstForm = {
    Product: "Product",
    SupplierSKU: "Supplier SKU (Stock Keeping Unit)",
    AcceptQuantity: "Accept Quantity",
    RejectQuantity: "Reject Quantity"
}

export const AddPickuptimesConstForm = {
    PickupTimeFrom: "Pickup Time (From)",
    PickupTimeTo: "Pickup Time (To)",
    AddPickuptimes: "Add Pickup Time",
    UpdatePickuptimes: "Update Pickup Time",
    btn_AddPickuptimes: "Add Pickup Time",
    btn_UpdatePickuptimes: "Update Pickup Time"
}

export const ValidateOTPConstForm = {
    OtpLabel: "Enter one time code:",
    Otpplaceholder: "Enter one time code",
    Otphovermessage: 'The 6-digit one-time password that was emailed to your email address.'
}

export const AddDeliverytimesConstForm = {
    DeliveryTimeFrom: "Delivery Time (From)",
    DeliveryTimeTo: "Delivery Time (To)",
    // DeliveryCost: "Delivery Cost",
    AddDeliverytimes: "Add Delivery Option",
    btn_AddDeliverytimes: "Add Delivery Option",
    UpdateDeliverytimes: "Update Delivery Option",
    btn_UpdateDeliverytimes: "Update Delivery Option"
}

export const DeliverytimesConst = {
    SideM_DeliveryOption: "Pickup and Delivery Options",
    Deliverytimes: "Pickup and Delivery Options",
    Add_Delivery_Option: "Add Delivery Option",
    DeliveryOptionSetup: "Delivery Option Setup",
    btn_Submit: "Submit",
    DeliveryOption: "Delivery Option",
    IsPickupEnable: "Pick-up",
    IsDeliveryEnable: "Delivery",
    IsFreeDelivery: "Free Delivery Services",
    DefaultMiles: "Unit Miles/KM",
    DefaultMilesFees: "Unit Miles/KM Fees",
    ExtraPerMileFees: "Extra Unit Per Mile/KM Fees",
    DeliveryTime: "Delivery Time",
    StoreTitle: 'My Brewery Supports:',
    DeliveryTimeNoMessage: 'There are no delivery services available at the brewery.',
    PickupTimeNoMessage: 'There are no pick-up services available at the brewery.',
    DoordashBusiness: "Doordash Business"
}

export const EarningsConstForm = {
    From: "From",
    To: "To",
    Earnings: "Earnings",
    OrderId: "Order Id:",
    PickupDate: "Pickup Date",
    Time: "Time",
    DeliveryDate: "Delivery Date",
    Product_s: "Product(s)",
    OrderTotalPrice: "Order Total Price"
}

export const SetupAppointmentConst = {
    SetupAppointment: "Manage Appointment"
}

export const AddPurchaseOrderConstForm = {
    Supplier: "Supplier",
    PaymentTerms: "Payment Terms",
    SupplierCurrency: "Supplier Currency",
    NotetoSupplier: "Note to Supplier",
    EstimateArrival: "Estimate Arrival",
    TrackingNumber: "Tracking Number",
    ShippingCenter: "Shipping Center",
    Product: "Product",
    SupplierSKU: "Supplier SKU",
    Quantity: "Quantity",
    Cost: "Cost",
    Tax: "Tax (%)",
    ShippingCharge: "Shipping Charge",
    Adjustment: "Adjustment",
    AdjustmentAmount: "Adjustment Amount",
    TotalTax: "Total Tax (%)",
    Total: "Total",
    ProductName: "Product Name"
}
export const CategoryConstForm = {
    ProductCategory: "Product Category",
    ParentCategory: "Parent Category",
    CustomizationMessage: 'Customization Message',
    AddProductsCategory: "Add Products Category",
    btn_AddProductsCategory: "Add Products Category",
    UpdateProductsCategory: "Update Products Category",
    btn_UpdateProductsCategory: "Update Products Category",
    ApplicableTax: "Applicable Tax (%)"
}

export const StoreConstFrom = {
    RegisterStoreWith: "Register brewery with",
    Shopify: "Shopify",
    Wix: "Wix",
    Plazacart: "BrewersNearMe",
    StoreURL: "Shopify Brewery Name",
    AccessToken: "Access Token",
    APIVersion: "API Version",
    Next: "Continue",
    PREV: "Back",
    StoreName: "Brewery Name",
    Storecategory: "Brewery Category",
    StoreOpen: "Is your brewery open to the general public?",
    PhoneNumber: "Phone Number",
    Website: "Website URL",
    FacebookUser: "Facebook User",
    City: "City Name",
    StateName: "State Name",
    CountryName: "Country Name",
    Address: "Address (optional)",
    StoreAddress: "Brewery Address",
    PhysicalReceipts: "Can you give out a physical copy of the receipts to our Shoppers for each order?",
    StoreLogo: "Upload Brewery Logo",
    StoreBanner: "Upload Brewery Banner",
    boxesInfo: "Check the boxes that describe the type of products you sell",
    Yes: "Yes",
    No: "No",
    Upload: "Upload",
    EditLabel: "Edit",
    Storeapplication: "Brewery application",
    EvaluateStore: "We will use the information provided to evaluate your brewery, so make sure it is true. You will receive notification on the registered email and mobile number once the brewery status is approved.",
    Cancel: "Cancel",
    OK: "OK",
    SUBMIT: "Submit",
    SelectPlaza: "Plaza",
    PostalCode: "Postal Code",
    PickupServiceAvailable: "Pick-up Service Available",
    DeliveryServicesAvailable: "Delivery Services Available",
    Note: "Note:-",
    Screen4Msg: "If the drop-down menu does not contain the plaza you are looking for.",
    Screen4Msg1: "To add a new plaza, kindly request the system admin to add a new plaza.",
    Screen4Msg2: "To request a new plaza,",
    Scrren4Msg3: "click here.",
    Scrren4Msg4: "1. Plaza Name",
    Screen4Msg5: "2. Plaza Email",
    Screen4Msg6: "3. Plaza Phone Number",
    Screen4Msg7: "4. Plaza Address"
}

export const StoreListConst = {
    APPROVED: "APPROVED",
    PUBLISHED: "PUBLISHED",
    INREVIEW: "INREVIEW",
    ACTIVE: "ACTIVE",
    UAPPROVED: "Approved",
    UPUBLISHED: "Published",
    UINREVIEW: "In Review",
    UACTIVE: "Active"
}

export const StoreHourConst = {
    Starthour: "Start Hour",
    Endhour: "End Hour",
    NoteLabel: "Note:",
    StoreHourUpdateMessage: "If you change the brewery time here, it will be reflected in the brewery pickup and delivery times."
}

export const RegisterNewConstForm = {
    OnlyEmail: 'Email',
    Email: "Your Email",
    Name: "First Name",
    LastName: "Last Name",
    PhoneNumber: "Phone Number",
    PlazaPhoneNumber: "Plaza Phone Number",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    PostalCode: "Postal Code",
    PlazaAddress: "Plaza Address",
    PlazaPostalCode: "Plaza Postal Code",
    SelectPlaza: "Plaza",
    Enterotp: "Enter one time code",
    PlazaName: "Plaza Name",
    PlazaEmail: "Plaza Email",
    Address: "Type Your Address",
    UploadImage: "Upload Image (optional)",
    REGISTER: "Register",
    Continue: "Continue",
    Sign_up_with_Email: "Sign up with Email",
    Skip: "Skip",
    AlreadyRegistered: "Already Registered?",
    AddPlaza: "Add Plaza",
    SUBMIT: "Submit",
    PREV: "Back",
    Upload: "Upload",
    Register: "Register",
    SelectCountry: "Select Country",
    T_SelectPlaza: "Select Plaza",
    T_AddPlaza: "Add Plaza",
    ValidateOTP: "Validate One Time Code",
    Note: "Note:-",
    Screen3Msg: "If no plazas appear in the drop-down list, click Register.",
    Screen4Msg: "If the drop-down menu does not contain the plaza you are looking for.",
    Screen4Msg1: "To add a new plaza, kindly request the system admin to add a new plaza.",
    Screen4Msg2: "To request a new plaza,",
    Scrren4Msg3: "click here.",
    Scrren4Msg4: "1. Plaza Name",
    Screen4Msg5: "2. Plaza Email",
    Screen4Msg6: "3. Plaza Phone Number",
    Screen4Msg7: "4. Plaza Address",
    Captcha: "Enter Captcha Code",
    CaptchaCode: "Captcha Code"
}

export const AddPlazaConstForm = {
    PlazaName: "Plaza Name",
    PlazaEmail: "Plaza Email",
    PhoneNumber: "Phone Number",
    Address: "Address",
    PostalCode: "Postal Code",
    UploadImage: "Upload Image",
    Upload: "Upload",
    AddPlaza: "Add Plaza",
    CloseLabel: "Close",
    AddRequestLabel: "Submit Request",
    EnterPlazaname: "Please enter plaza name",
    EnterPlazaAddress: "Please enter plaza address",
    ALERT: "Alert",
    btn_CANCEL: "Cancel",
    btn_CONFIRM: "Continue",
    modal_msg: "The store's postal code is not the same as the plaza's postal code. Do you want to continue?"
}

export const ForgotPasswordConstForm = {
    OnlyEmail: "Email",
    YourEmail: "Your Email",
    Note: "We will send you an email with a link to reset your password.",
    Note1: "You will receive an email from us containing a link to reset your password.",
    ForgotPasswordValidOTP: "Your One Time Code has been sent to the e-mail associated with your account.",
    Enterotpnumber: "Enter one time code",
    Submit: "Submit",
    Emailhovermessage: "The email address associated with your account.",
    CreateNewPassword: "Create New Password",
    CreateNewConfirmPassword: "Create New Confirm Password",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
}

export const UserProfileConstForm = {
    FirstName: "First Name",
    LastName: "Last Name",
    Phone: "Phone No.",
    Address: "Address",
    Company: "Company",
    PostalCode: "Postal Code",
    CountryCode: "Country Code",
    eMail: "Email",
    PlazaEmail: "Plaza Email",
    PlazaName: "Plaza Name",
    UpdateProfile: "Update",
}

export const MySubscriptionConst = {
    ACTIVE: "ACTIVE"
}

export const ManageCouponConst = {
    ManageCoupon: "Manage Coupon",
    btn_AddCoupon: "Add Coupon",
    Title: "Title",
    Description: "Description",
    StartDate: "Start Date",
    EndDate: "End Date",
    DicountPrice: "Dicount Price",
    MinimumRequiredAmount: "Minimum Required Amount",
    CouponsRedemptions: "Coupons Redemptions",
    btn_NO: "NO",
    btn_YES: "YES"
}

export const ContactUSConstForm = {
    ContactUS: "Contact us",
    RequestADemo: "Request a Demo",
    Name: "Name",
    Email: "Email",
    Title: "Title",
    PhoneNumber: "Phone Number",
    Comments: "Comments",
    Company: "Company",
    CAPTCHAcode: "Enter the code shown below",
    btn_Submit: 'Submit'
}

export const ContactUSConstFormPH = {
    Name: "Name",
    Email: "Email",
    Title: "Title",
    PhoneNumber: "Phone Number",
    Comments: "Comments",
    Company: "Company",
    CAPTCHAcode: "Enter the code shown below"
}

export const AddCouponConst = {
    AddCoupon: "Add Coupon",
    Title: "Title",
    Description: "Description",
    StartDate: "Start Date",
    EndDate: "End Date",
    DicountPrice: "Discount Price",
    MinimumRequiredAmount: "Minimum Order Amount Required To Avail This Coupon",
    btn_Upload: "Upload",
    UploadcouponLogo: "Upload Coupon Logo",
    btn_AddCoupon: "Add Coupon",
    UpdateCoupon: "Update Coupon",
    btn_UpdateCoupon: "Update Coupon",
    EditLabel: "Edit"
}

export const RedemptionConst = {
    Redemption: "Redemption",
    StartDate: "Start Date",
    EndDate: "End Date",
    btn_Redemption: "Redemption",
    Titel: "Titel",
    Description: "Description",
    OrderId: "OrderId",
    CustomerName: "Customer Name",
    CustomerEmail: "Customer Email",
    DiscountAmount: "Discount Amount",
    DateofRedeemed: "Date of Redeemed",
    Status: "Status"
}

export const BulkCatalogUploadList = {
    BulkCatalogUpload: "Bulk Catalog Upload",
    Download_S_T1: "Download a",
    Download_S_T2: "sample CSV template",
    Download_S_T3: "to see an example of the format required.",
    AddFile: "Add file",
    btn_Replace_File: "Replace file",
    btn_Cancel: "Cancel",
    btn_Import_Products: "Import products",
    btn_Upload_and_Continue: "Upload and continue",
    CatalogHistory: "Catalog History",
    CatalogErrors: "Catalog Errors",
    T1_FileName: "File Name",
    T1_Description: "Description",
    T1_CreatedDate: "Created Date",
    T2_Action: "Action",
    T2_Description: "Description",
    T2_CreatedDate: "Created Date"
}

export const ImageBulkUploadList = {
    ImageBulkUpload: "Image Bulk Upload",
    AddImage: "Add Image",
    UploadedImage: "Uploaded Image",
    CopyText: "Copy Text",
    CopiedText: "Copied Text"
}

export const DoordashSetupConst = {
    DoordashSetup: "Delivery Setup",
    DoordashSetupMethods: "Delivery Setup",
    btn_ClicktoSetup: "Click to Setup or Update",
    DeveloperId: "Developer Id",
    KeyId: "Key Id",
    SigningSecret: "Signing Secret",
    btn_Submit: "Submit",
    selectMsg: "Select any option from the dropdown.",
    GenerateWebhookToken: "Generate Webhook Token"
}

export const HomeConst = {
    Pending: "Pending",
    InProcess: "In Process",
    Completed: "Completed",
    All: "All",
    DataNotFound: "There are no records to display",
    OrderId: "Order Id:",
    ViewMore: "View more..",
    Track: "Track",
    Status: "Status",
    SubmitStatus: "Submit Status",
    SelfDelivery: "Self Delivery",
    Accountinfo: "Account info:",
    No: "No",
    Product: "Product",
    Quantity: "Quantity",
    Price: "Price",
    btn_Acceptorder: "Accept order",
    btn_Cancelorder: "Cancel order",
    Subtotal: "Subtotal =",
    ApplicationFee: "Service Charge =",
    ItemsSubTotal: "Item(s) Subtotal =",
    TotalProductTax: "Total Product Tax =",
    DeliveryCharges: "Delivery Charges =",
    SpecialDeliveryCharges: "Special Delivery Charges =",
    ServiceFee: "Service Fee =",
    DeliveryTip: "Delivery Tip =",
    Total: "Total =",
    Items: "Items",
    WithDiscountTotal: "With Discount Total =",
    CouponDetail: "Coupon Detail:",
    modal1_Title: "Confirmation",
    modal1_Msg: "Are you sure you want to cancel this order?",
    modal1_btnConfirm: "Confirm",
    modal1_btnBack: "Back",

    modal1_pding: "Confirmation",
    modal1_pdingMsg: "Are you sure you want to accept this order?",
    modal2_Title: "Self delivery is presently enabled.",
    modal3_Title: "Door Dash delivery is presently enabled.",
    // modal2_Msg: "Are you sure you want to accept this order?",
    btn_OrderPickedup: "Order Picked up",
    btn_ChangeStatus: "Change Status",
    modal3_Msg: "Customer picked up that order.",
    modal4_Msg: "Is order status change?",
    Future_Date_Message: "Are you sure you want to change order status for this",
    PickupTime: "Curbside Pickup - Pickup Time:",
    DeliveryAddress: "Delivery Address:",
    DeliveryTime: "Delivery Time:",
    DeliveryService: "Delivery Service:",
    PickupDate: "Pickup Date",
    PickupTimeT: "Pickup Time",
    DeliveryDate: "Delivery Date",
    DeliveryTimeT: "Delivery Time",
    Time: "Time",
    OrderDate: "Order Date - Time",
    Product_s: "Product(s)",
    PickupOrderDateTime: "Pickup Order Date - Time",
    OrderTotalPrice: "Order Total Price",
    ConfirmationCode: "Confirmation Code",
}

export const UserManagementConst = {
    ManageUsers: "Manage Users",
    btn_AddUser: "Add User",
    btn_NO: "NO",
    btn_YES: "YES",
    Email: "Email",
    MerchantName: "Merchant Name",
    PhoneNumber: "Phone Number",
    UserActivation: "User Activation",
    StoreAssign: "Assign Brewery",
    Status: "Status",
    Enterotp: "Enter one time code",
    btn_Back: "Back",
    btn_Submit: "Submit"
}

export const AddUserConst = {
    AddUser: "Add User",
    UpdateUser: "Update User",
    Name: "First Name",
    LastName: "Last Name",
    Email: "Email",
    Password: "Password",
    PhoneNumber: "Phone Number",
    PostalCode: "Postal Code",
    SelectCountry: "Select Country",
    Enterotp: "Enter one time code",
    btn_AddUser: "Add User",
    btn_UpdateUser: "Update Users",
    btn_Submit: "Submit"
}

export const StoreAssignConst = {
    StoreAssign: "Assign Brewery",
    btn_Submit: "Submit",
    StoreName: "Brewery Name",
    Address: "Address",
    MerchantName: "Merchant Name:",
    Email: "Email:",
    PhoneNumber: "Phone Number:"
}

export const ManageTaxesConstForm = {
    ManageTaxes: "Manage Taxes",
    Taxes: "Manage Taxes",
    AddStateProvince: "Add State / Province",
    Country: "Country",
    State_OR_Province: "State / Province",
    StateCode_OR_ProvinceCode: "State Code/ Province Code",
    Status: "Status",
    btn_NO: "NO",
    btn_YES: "YES",
    th_TaxName: "Tax Name",
    th_TaxType: "Tax Type",
    th_ApplicableTax: "Applicable Tax (%)",
    th_ApplicableFromDate: "Effective from date",
    th_ApplicableToDate: "Effective to date",
    th_Status: "Status",
    btn_Cancel: "Cancel",
    btn_Change: "Save",
    btn_Submit: "Submit",
    StoreLevelTax: "Store Level Tax",
    ProductLevelTax: "Category / Product Level Tax",
    ApplyDefaultToAll: "Apply to all categories & products",
    modal1_btnConfirm: "Confirm",
    modal1_Title: "Confirmation",
    modal1_pdingMsg: "Are you sure you want to delete this tax?",
    modal3_Title: "Note:",
    modal3_Msg: "This tax applies to all categories and products.",
    modal3_Msg1: "This tax is exempt for all categories and products.",
    modal1_btnOk: "Ok",
    modal4Msg: "Your applied tax rate is above 100%",
    TaxExempt: "Product is a tax exempted"
}



export default ProductConstForm