import React, { Component } from 'react';
import './StoreList.css';
import { withRouter } from 'react-router-dom';
import Constants from '../Config/Constants'
import { StoreDataInStorage, StoreObjectInStorage } from "../Config/Utils";
import Color from '../Config/Color';
import RouteMerchant from '../Config/RouteMerchant.js';

class StoreList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: "",
    }
  }

  onClickViewDetails(data) {
    console.log("value", data);
    this.props.history.push({
      pathname: RouteMerchant.Storedetails,
    });
    // StoreObjectInStorage(Constants.STORE_OBJ, data);
    StoreDataInStorage(Constants.STOREID, data.ObjectId);
  }

  storenavigate(data) {
    console.log("value", data.ObjectId);
    this.props.history.push({
      pathname: RouteMerchant.Home,
      data: data.ObjectId
    });
    StoreObjectInStorage(Constants.STORE_OBJ, data);
    StoreDataInStorage(Constants.STOREID, data.ObjectId);
  }


  render() {
    const { selectedItem } = this.state;
    let StoreList = this.props.data
      .map(function (data, index) {
        var value = data.ObjectId;
        return (
          <div key={index}>
            <div className="row store-list" >
              <img src={data.AppLogo} className="iconDetails" />
              <div>
                <h4 style={{ marginLeft: '10px' }}>{data.ApplicationName}</h4>
                {data.Status === 'ACTIVE' ? (
                  <div>
                    <span
                      style={{ background: Color.THEME_COLOR }}
                      value={data.ObjectId}
                      onClick={() => this.storenavigate(data)}
                      className="btn btn-row">
                      Go to dashboard
                    </span>
                  </div>) : (
                  <div>
                    <span
                      style={{ background: Color.THEME_COLOR }}
                      value={data.ObjectId}
                      onClick={() => this.onClickViewDetails(data)}
                      className="btn btn-row">
                      View details
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      }, this);

    return (
      <div>
        {StoreList}
      </div>
    );
  }
}

export default withRouter(StoreList);