import React, { Component } from 'react';
import './SideMenu.css';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';

import MenuData from './MenuData';
import Color from '../Config/Color';
// import AddressList from './AddressList';
import NotificationList from './NotificationList';
import CurbData from './CurbData'

import { NavLink, withRouter } from 'react-router-dom';
// import RouteConstant from './Config/RouteConstant';
//import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
// C:\Users\admin\Desktop\OFFICE\Plazacart\src\components\SideMenu\SideMenu.js
// import pencil from "../../images/icons/pencil.png";
// import curbside from "../../images/sidemenuicons/CurbSide1.png";
import myorder from "../../images/sidemenuicons/MyOrder.png";
// import location from "../../images/sidemenuicons/Location.png";
// import notificaion from "../../images/sidemenuicons/Notificaion.png";
import logout from "../../images/sidemenuicons/logout.png"
import defaultUser from "../../images/default-user.png"
import cartitems from "../../images/sidemenuicons/cart-items.png"
import CurbSide1 from "../../images/sidemenuicons/CurbSide1.png"
import earnings from "../../images/sidemenuicons/Earnings.jpg"
import more from "../../images/sidemenuicons/more.png"
import RouteMerchant from '../Config/RouteMerchant.js'
import { RetriveObjectFromStorage, StoreObjectInStorage, RetriveDataFromStorage, RemoveFromStorage, StoreDataInStorage } from "../Config/Utils";
import Constants, { PAYMENT_TYPES, TaxFlag } from '../Config/Constants'
import { GetData } from "../../services/GetData";
import Messages from "../Config/Messages";
import { MY_STORE_API_List, GET_STORE_ITEM, GET_PRODUCT_CATEGORY, GET_PROFILE, GET_COUNTRY_API, PLAZAID, GET_NOTIFICATION } from '../Config/Config.json';
import StoreListSide from './StoreListSide';
import StoreItemSide from './StoreItemSide';
import ProdcutsCategorySide from './ProdcutsCategorySide';
import Constvariable, { PlatFrom, UserAccess } from '../Config/constvariable';
import notificaion from "../../images/sidemenuicons/Notificaion.png";
import COUNTRYCURRENCY from '../Config/CountryCurrency'
import { StoreListConst, ManageTaxesConstForm } from "../Config/ConstForm"
import { DoordashSetupConst, DeliverytimesConst, UserManagementConst, SetupAppointmentConst } from "../Config/ConstForm";
import NoPriview from '../../../src/images/nopreviewp.png';
import StoreDashboardIcon from "../../images/sidemenuicons/storelist.png";

const listOrder = [
    {
        OrderId: 1,
        Name: "Item 1",
        OrderDate: "26 oct 2020, 16:44:29",
        OrderStatus: "PENDING",
        Price: "$115.05",
        ItemImage: "https://webaven.s3.amazonaws.com//images/Tenants/5f7c457721a59146e0a024a6/2020-06-10--03-22-47.png",

    },
    {
        OrderId: 2,
        Name: "Item 2",
        OrderDate: "26 oct 2020, 16:44:29",
        OrderStatus: "PENDING",
        Price: "$11.05",
        ItemImage: "https://webaven.s3.amazonaws.com/048b02aa-f645-45c4-a4b9-5febb3bc343b/images/Store/v1.jpg",
    }
];

const curbsideOrder = [
    {
        OrderId: 1,
        Name: "Item 1",
        OrderDate: "26 oct 2020, 16:44:29",
        OrderStatus: "PENDING",
        Price: "$115.05",
        ItemImage: "https://webaven.s3.amazonaws.com//images/Tenants/5f7c457721a59146e0a024a6/2020-06-10--03-22-47.png",

    },
    {
        OrderId: 2,
        Name: "Item 2",
        OrderDate: "26 oct 2020, 16:44:29",
        OrderStatus: "PENDING",
        Price: "$11.05",
        ItemImage: "https://webaven.s3.amazonaws.com/048b02aa-f645-45c4-a4b9-5febb3bc343b/images/Store/v1.jpg",
    }
];


const listNotification = [
    {
        Id: 1,
        Name: "Your order has been placed successfully and will be confirmed within 15 minutes",
        date: "26 oct 2020, 16:44:29"
    },
    {
        Id: 2,
        Name: "Your order has been placed successfully and will be confirmed within 15 minutes",
        date: "26 oct 2020, 12:36:26"
    },
    {
        Id: 3,
        Name: "Your order has been placed successfully and will be confirmed within 15 minutes",
        date: "28 sep 2020, 18:34:39"
    }
]


class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false, // (or true)
            isshopselected: false,
            storeList: [],
            storeItemListSide: [],
            storeCategoryList: [],
            storeObject: {},
            userDetail: {},
            countryData: [],
            IsParent: true,
            notificationList: [],
        }
        this.closeNav = this.closeNav.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);

        this.handleAddAddress = this.handleAddAddress.bind(this);
        this.handleSelectCountry = this.handleSelectCountry.bind(this);

        this.handleEditProfile = this.handleEditProfile.bind(this);
        // this.handleOrder = this.handleOrder.bind(this);
        this.handleItem = this.handleItem.bind(this);
        this.handleStore = this.handleStore.bind(this);
        this.handleProducts = this.handleProducts.bind(this);
    }


    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var isshopselected = RetriveObjectFromStorage(Constants.STORE_OBJ)
        var isParent = RetriveDataFromStorage(Constants.ISPARENT)
        this.setState({ IsParent: isParent == UserAccess.ISPARENTFALSE ? false : true })
        var path = window.location.pathname;
        if (isshopselected && (path != RouteMerchant.StoreMain)) {
            this.setState({ isshopselected: true, storeObject: isshopselected })
            var storeId = isshopselected.ObjectId
            var plazaId = isshopselected.plazaid
        }
        else {
            RemoveFromStorage(Constants.PLATFROM_FLAG)
            RemoveFromStorage(Constants.STORE_OBJ)
            RemoveFromStorage(Constants.PLAZAID)
            RemoveFromStorage(Constants.STOREID)
            this.setState({ isshopselected: false })
        }
        this.getMyStoreAPI(authToken);
        this.getNotification(authToken);
        var userDetail = RetriveObjectFromStorage(Constants.USER_DETAIL)
        if (userDetail) {
            this.setState({ userDetail: userDetail })
            var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
            if (!countryInfo) {
                this.getCountryAPI(userDetail.CountryCode)
            }
        }
        else {
            this.getProfileDetail(authToken);
        }
    }

    /* Get Notification List*/
    getNotification(authToken) {
        var URL = GET_NOTIFICATION
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.length > 0) {
                        this.setState({ notificationList: responseData.filter(e => e.IsRead === false) });
                    }
                }
            });
    }

    getProfileDetail(authToken) {
        this.setState({ isActive: true })
        var URL = GET_PROFILE
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ userDetail: responseData });
                        StoreObjectInStorage(Constants.USER_DETAIL, responseData);
                        this.getCountryAPI(responseData.CountryCode);
                        // var currencyInfo = COUNTRYCURRENCY.filter(e => responseData.CountryCode === e.CountryCode)[0]
                        // if (currencyInfo) {
                        // 	StoreObjectInStorage(Constants.COUNTRYINFO, currencyInfo)
                        // }
                    }
                }
            });
    }

    getCountryAPI(countryCode) {
        console.log("country list1 ",);
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        var currencyInfo = responseData.filter(e => countryCode === e.CountryCode)[0]
                        if (currencyInfo) {
                            delete currencyInfo['CountryIcon']
                            StoreObjectInStorage(Constants.COUNTRYINFO, currencyInfo)
                        }
                        this.setState({ countryData: responseData });
                    }
                    else {
                        this.setState({ countryData: [] })
                    }
                    console.log("countryData ", responseData);
                }
            });
    }

    getMyStoreAPI(authToken) {
        GetData(MY_STORE_API_List, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.length > 0) {
                        responseData = responseData.filter(e => (e.Status === StoreListConst.INREVIEW || e.Status === StoreListConst.APPROVED || e.Status === StoreListConst.PUBLISHED))
                        this.setState({ storeList: responseData });
                    }
                }
                else {

                }
            });
    }

    getMyStoreItem(authToken, storeId, plazaId) {
        var URL = GET_STORE_ITEM.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ storeItemListSide: responseData });
                    }
                }
                else {

                }
            });
    }


    getProductCatagory(authToken, storeId, plazaId) {
        var URL = GET_PRODUCT_CATEGORY.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.length > 0) {
                        this.setState({ storeCategoryList: responseData });
                    }
                }
                else {

                }
            });
    }

    // start hook methods here//////////////////////////////

    changeIsSelectShop = (flag, storeObj) => {
        this.props.history.push({
            pathname: storeObj.Status == StoreListConst.PUBLISHED ? RouteMerchant.Home : RouteMerchant.MySubscription
        })
        // var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        // this.setState({ isshopselected: flag, storeObject: storeObj })
        // var storeId = storeObj.ObjectId
        // var plazaId = storeObj.plazaid
        // this.getMyStoreItem(authToken, storeId, plazaId)
        // this.getProductCatagory(authToken, storeId, plazaId)
    }


    //  end hook methods ///////////////////////////////////

    closeNav() {
        console.log("close")
        this.setState({ opened: false })
        window.closeNav();
    }

    handleToggle() {
        alert("toggle")
    }

    handleMenuClick = value => {
        console.log("menu click", value)
        if (value === '6') {
            // this.props.history.push({
            // 	pathname: RouteConstant.Default,
            // })
        }
    }

    handleSelectCountry() {
        this.closeNav()
        // this.props.history.push({
        // 	pathname: RouteConstant.Country
        // })
    }

    handleAddAddress() {
        this.closeNav()
        // this.props.history.push({
        // 	pathname: RouteConstant.AddAddress,
        // })
    }

    handleEditProfile() {
        // this.props.history.push({
        // 	pathname: RouteConstant.EditProfile,
        // })
    }

    handelemptyStore() {
        if (this.state.storeList.length == 0) {
            this.closeNav()
            this.props.history.push({
                pathname: RouteMerchant.StoreMain,
            })
        }
    }

    closeNav() {
        window.closeNav();
    }

    handleOrder(path) {
        this.closeNav()
        this.props.history.push({
            pathname: path,
        })
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            StoreDataInStorage(Constants.STOREID, storeObj.ObjectId);
        }
    }

    handleStore() {
        this.props.history.push({
            pathname: RouteMerchant.StoreMain,
        });
    }

    handleItem() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.ManageItems,
        });
    }

    handleProducts() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.ManageProducts,
        })

    }

    navigateHome() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.StoreMain,
        })
    }

    navigateStoreSupplier() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.StoreSupplier,
        })
    }

    navigateInventory() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.Inventory,
        })
    }

    navigatePickupTimes() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.Pickuptimes,
        })
    }

    navigateDeliverytimes() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.Deliverytimes,
        })
    }

    navigateNotification() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.Notification,
        })
    }

    navigateUserProfile() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.UserProfile,
        })
    }

    navigatePurchaseOrder() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.PurchaseOrder,
        })
    }

    navigateEarnings() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.Earnings,
        })
    }

    navigatePayment() {
        this.closeNav();
        var paymentpath = "";
        if (Constants.PAYMENTTYPE === PAYMENT_TYPES.PAYPAL) {
            paymentpath = RouteMerchant.PayPalPayment;
        } else if (Constants.PAYMENTTYPE === PAYMENT_TYPES.STRIPE_STANDARD) {
            paymentpath = RouteMerchant.StripeStandardPayment;
        } else {
            paymentpath = RouteMerchant.StripeExpressPayment;
        }
        this.props.history.push({
            pathname: paymentpath
        })
    }

    navigatePlan() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.MySubscription
        })
    }

    navMasters(path) {
        this.closeNav()
        this.props.history.push({
            pathname: path
        })
    }

    logoutMethod() {
        for (var ClearObj in Constants) {
            RemoveFromStorage(Constants[ClearObj])
        }
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.Default,
        })
    }

    updateStore() {
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var plazaId = storeObj.plazaid
            this.props.history.push({
                pathname: RouteMerchant.Store,
                search: "?id=" + storeObj.ObjectId + "&plazaId=" + plazaId
            })
        }
        this.closeNav();
    }

    renderSidemenu() {
        const { IsParent, isshopselected, storeObject, userDetail } = this.state
        var platForm = RetriveDataFromStorage(Constants.PLATFROM_FLAG)
        if (platForm == PlatFrom.Shopify) {
            return <div>
                <div className="" style={{ marginBottom: "5px", cursor: "pointer", marginLeft: "20px" }} >
                    <img src={this.state.storeObject.AppLogo ? this.state.storeObject.AppLogo : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} className="sidemenu-shops" onClick={() => this.navigateHome()} />
                    <span>
                        <h5 style={{ marginLeft: '10px', display: "inline-block", color: Color.THEME_COLOR }} onClick={() => this.navigateHome()}>
                            {this.state.storeObject.ApplicationName ?
                                this.state.storeObject.ApplicationName.length > 10 ?
                                    this.state.storeObject.ApplicationName.slice(0, 10) + "..." : this.state.storeObject.ApplicationName
                                : ""}
                        </h5>
                    </span>
                </div>

                <SubMenu
                    onClick={() => this.navigateHome()}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={StoreDashboardIcon} style={{ height: "25px", width: "25px" }}></img>}
                    title="Brewery Dashboard" >
                </SubMenu>

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={() => this.handleOrder(RouteMerchant.HomeShopify)}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={myorder} style={{ height: "25px", width: "25px" }}></img>}
                    title="My Shopify Orders" >
                </SubMenu>}

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={() => { this.navigatePickupTimes() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title="Brewery Hours" >
                </SubMenu>}

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={() => { this.navigateDeliverytimes() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title={DeliverytimesConst.SideM_DeliveryOption} >
                </SubMenu>}

                {(IsParent && (storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */)) && <SubMenu
                    onClick={() => { this.navigateEarnings() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title="Earnings" >
                </SubMenu>}

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={() => { this.navigatePayment() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title="Payment Settings" >
                </SubMenu>}

                <SubMenu
                    onClick={() => { this.navigatePlan() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title="Subscription Plan" >
                </SubMenu>

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */ && userDetail.taxlevel == TaxFlag.STORE) && <SubMenu
                    onClick={() => this.navMasters(RouteMerchant.ManageTaxes)}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<i class="fa fa-percent" style={{ color: Color.THEME_COLOR, fontSize: "1.2rem" }}></i>}
                    title={ManageTaxesConstForm.ManageTaxes} >
                </SubMenu>}
            </div>
        }
        else if (platForm == PlatFrom.Wix) {

        }
        else {
            return <div>
                <div className="" style={{ marginBottom: "5px", cursor: "pointer", marginLeft: "20px" }} >
                    <img src={this.state.storeObject.AppLogo ? this.state.storeObject.AppLogo : NoPriview} style={{ borderRadius: '10px' }} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} className="sidemenu-shops" onClick={() => this.navigateHome()} />
                    <span>
                        <h5 style={{ marginLeft: '10px', display: "inline-block", color: Color.THEME_COLOR }} onClick={() => this.navigateHome()}>
                            {this.state.storeObject.ApplicationName ?
                                this.state.storeObject.ApplicationName.length > 15 ?
                                    this.state.storeObject.ApplicationName.slice(0, 15) + "..." : this.state.storeObject.ApplicationName
                                : ""}
                        </h5>
                    </span>
                </div>

                <SubMenu
                    onClick={() => this.navigateHome()}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={StoreDashboardIcon} style={{ height: "25px", width: "25px" }}></img>}
                    title="Brewery Dashboard">
                </SubMenu>

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={() => this.handleOrder(RouteMerchant.Home)}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={myorder} style={{ height: "25px", width: "25px" }}></img>}
                    title="My Orders">
                </SubMenu>}

                {(IsParent && (storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */)) && <>
                    <SubMenu
                        onClick={() => { this.navMasters(RouteMerchant.BulkUpload) }}
                        style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                        icon={<i class="fas fa-upload" style={{ color: Color.THEME_COLOR, fontSize: "1.2rem" }}></i>}
                        title="Catalog Upload" >
                    </SubMenu>

                    <SubMenu
                        onClick={() => { this.navMasters(RouteMerchant.ImageBulkUpload) }}
                        style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                        icon={<i class="fas fa-images" style={{ color: Color.THEME_COLOR, fontSize: "1.2rem" }}></i>}
                        title="Image Bulk Upload" >
                    </SubMenu>

                    <SubMenu
                        onClick={() => this.navMasters(RouteMerchant.ManageCoupon)}
                        style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                        icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                        title={"Manage Coupon"} >
                    </SubMenu>

                </>}

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={this.state.storeCategoryList.length === 0 ? this.handleProducts : () => { }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={cartitems} style={{ height: "25px", width: "25px" }}></img>}
                    suffix={this.state.storeCategoryList.length > 0 && <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", minWidth: "30px", width: "max-content", borderRadius: "20px" }} >
                        <span style={{ fontSize: "13px" }}>{this.state.storeCategoryList.length}</span>
                    </div>}
                    title={Constvariable.ManageCategory}>
                    {this.state.storeCategoryList.length > 0 && <ProdcutsCategorySide data={this.state.storeCategoryList} />}
                </SubMenu>}

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={this.state.storeItemListSide.length === 0 ? this.handleItem : () => { }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={CurbSide1} style={{ height: "25px", width: "25px" }}></img>}
                    suffix={this.state.storeItemListSide.length > 0 && <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", minWidth: "30px", width: "max-content", borderRadius: "20px" }} >
                        <span style={{ fontSize: "13px" }}>{this.state.storeItemListSide.length}</span>
                    </div>}
                    title={Constvariable.ManageItems} >
                    {this.state.storeItemListSide.length > 0 && <StoreItemSide storeItems={this.state.storeItemListSide} countryData={this.state.countryData} />}
                </SubMenu>}

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={() => this.navMasters(RouteMerchant.SetAppointment)}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<i class="far fa-clock" style={{ color: Color.THEME_COLOR, fontSize: "1.2rem" }}></i>}
                    suffix={""}
                    title={SetupAppointmentConst.SetupAppointment} >
                </SubMenu>}

                {(IsParent && (storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */)) && <>

                    <SubMenu
                        onClick={() => { this.navigateStoreSupplier() }}
                        style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                        icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                        title="Brewery Supplier" >
                    </SubMenu>

                    <SubMenu
                        onClick={() => { this.navigateInventory() }}
                        style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                        icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                        title="Inventory" >
                    </SubMenu>
                </>}

                {/* <SubMenu
					onClick={() => { this.navigatePurchaseOrder() }}
					style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
					icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
					title="Purchase Order" >
				</SubMenu> */}

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={() => { this.navigatePickupTimes() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title="Brewery Hours" >
                </SubMenu>}

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={() => { this.navigateDeliverytimes() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title={DeliverytimesConst.SideM_DeliveryOption} >
                </SubMenu>}

                {(IsParent && (storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */)) && <SubMenu
                    onClick={() => { this.navigateEarnings() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title="Earnings" >
                </SubMenu>}

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */) && <SubMenu
                    onClick={() => { this.navigatePayment() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title="Payment Settings" >
                </SubMenu>}

                <SubMenu
                    onClick={() => { this.navigatePlan() }}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<img src={earnings} style={{ height: "25px", width: "25px" }}></img>}
                    title="Subscription Plan" >
                </SubMenu>

                {(storeObject /* && storeObject.Status && storeObject.Status != StoreListConst.INREVIEW */ && userDetail.taxlevel == TaxFlag.STORE) && <SubMenu
                    onClick={() => this.navMasters(RouteMerchant.ManageTaxes)}
                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                    icon={<i class="fa fa-percent" style={{ color: Color.THEME_COLOR, fontSize: "1.2rem" }}></i>}
                    title={ManageTaxesConstForm.ManageTaxes} >
                </SubMenu>}

            </div>
        }
    }

    render() {
        const { IsParent } = this.state;
        return (
            <React.Fragment>
                <div>
                    <ProSidebar>
                        <SidebarHeader style={{ backgroundColor: Color.WHITE }}>
                            <div className="col-sm-12" style={{ marginTop: "10px" }}>
                                <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", width: "25px", position: "absolute", top: "5px", right: "15px", borderRadius: "20px" }} onClick={this.closeNav}  >
                                    <i class="fa fa-times" style={{ fontSize: "15px", marginTop: "5px" }}></i>
                                </div>
                                <center>
                                    <span style={{ cursor: "pointer" }}>
                                        <img src={defaultUser} style={{ borderRadius: "10px" }} width="100" height="100"></img>
                                        <br /><br />
                                    </span>
                                </center>

                                <div className="d-flex justify-content-between">
                                    <label style={{ color: Color.BLACK, fontWeight: 'bold' }}>{this.state.userDetail.FirstName ? (this.state.userDetail.FirstName + " " + this.state.userDetail.LastName) : ""}</label>
                                    &nbsp;
                                    {this.state.userDetail.FirstName && <i class="fas fa-edit genreSlug" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.navigateUserProfile()}></i>}
                                </div>
                                <div>
                                    <label className="sidemenuemail" style={{ color: Color.BLACK, fontWeight: '400' }}>{this.state.userDetail.eMail}</label>
                                </div>
                            </div>
                        </SidebarHeader>

                        <SidebarContent style={{}}>
                            <Menu style={{ backgroundColor: Color.WHITE }} >

                                {!this.state.isshopselected ?
                                    <div>
                                        <SubMenu
                                            onClick={() => this.handelemptyStore()}
                                            style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                                            icon={<img src={myorder} style={{ height: "25px", width: "25px" }}></img>}
                                            suffix={<div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", minWidth: "30px", width: "max-content", borderRadius: "20px" }}>
                                                <span style={{ fontSize: "13px" }}>{this.state.storeList.length > 0 ? this.state.storeList.length : ""}</span>
                                            </div>}
                                            title="My Breweries">
                                            <StoreListSide data={this.state.storeList} handelIsSelctedShop={this.changeIsSelectShop} />
                                        </SubMenu>
                                        {IsParent && <>
                                            <SubMenu
                                                onClick={() => this.navMasters(RouteMerchant.doordashConfig)}
                                                style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                                                icon={<img src={myorder} style={{ height: "25px", width: "25px" }}></img>}
                                                title={DoordashSetupConst.DoordashSetup} >
                                            </SubMenu>
                                            <SubMenu
                                                onClick={() => this.navMasters(RouteMerchant.merchantUser)}
                                                style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                                                icon={<i class="fas fa-user" style={{ color: Color.THEME_COLOR, fontSize: "1.2rem" }}></i>}
                                                title={UserManagementConst.ManageUsers} >
                                            </SubMenu>
                                            <SubMenu
                                                onClick={() => { this.navigateNotification() }}
                                                style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                                                icon={<img src={notificaion} style={{ height: "25px", width: "25px" }}></img>}
                                                suffix={<div
                                                    style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", minWidth: "30px", width: "max-content", borderRadius: "20px" }}>
                                                    <span style={{ fontSize: "13px" }}>{this.state.notificationList.length > 0 ? this.state.notificationList.length : ""}</span>
                                                </div>}
                                                title="Notification" >
                                            </SubMenu>
                                        </>}
                                    </div>
                                    :
                                    this.renderSidemenu()
                                }

                                <MenuItem
                                    onClick={() => this.logoutMethod()}
                                    icon={<img src={logout} style={{ height: "25px", width: "25px" }}></img>}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </SidebarContent>

                        <SidebarFooter style={{ backgroundColor: Color.WHITE }}>
                        </SidebarFooter>

                    </ProSidebar>
                </div>


            </React.Fragment >
        );
    }
}

export default withRouter(SideMenu);