import React, { Component } from 'react';
import StoreList from "../StoreList/StoreList";
import Messages from "../Config/Messages";
import Constants from '../Config/Constants'
import { RetriveDataFromStorage } from "../Config/Utils";
import { GetData } from "../../services/GetData";
import SideMenu from '../SideMenu/SideMenu';
import Header from '../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../Config/Color';
import Constvariable, { PlatFrom } from '../Config/constvariable';
import { RetriveObjectFromStorage, StoreObjectInStorage, RemoveFromStorage } from "../Config/Utils";
import {
    GETPARTERNERREFERRALSTATUS, STOREID, PARTERNERREFERRALCALLBACK, PLAZAID,
    SUBSCRIPTION, SUBSCRIPTIONCANCEL, SUBSCRIPTIONREVISE, SUBSCRIPTIONUPDATE
} from '../Config/Config.json';
import { PostData } from '../../services/PostData';
import RouteMerchant from '../Config/RouteMerchant.js';
// import './Payments.css'
import paypal from "../../images/paypal.svg"
import { MySubscriptionConst } from '../Config/ConstForm'

class MySubscriptionOnly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeId: '',
            plazaId: "",
            paymentObj: null,
            platForm: ""

        }
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.TEMPSTORE__OBJ);

        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = storeObj.plazaid
            this.setState({ storeId: storeId, plazaId: plazaId })

            var platForm = ""
            if (storeObj.isshopifyintegration) {
                platForm = PlatFrom.Shopify
            }
            else if (storeObj.iswixintegration) {
                platForm = PlatFrom.Wix
            }
            else {
                platForm = PlatFrom.Plazacart
            }
            this.setState({ platForm: platForm })

            this.getCheckPlan(authToken, storeId, plazaId)

        }

    }

    getCheckPlan(authToken, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = SUBSCRIPTION + "?" + STOREID + storeId + "&" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ paymentObj: responseData });
                        this.paramsRef(responseData, storeId, plazaId)
                    }
                    else {
                        this.setState({ paymentObj: null })
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
            });
    }

    paramsRef(responseData, storeId, plazaId) {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        var subscriptionid = RetriveDataFromStorage(Constants.SUBSCRIPTIONID)
        var postData = {}
        if (subscriptionid && storeId && plazaId && params.get("subscription_id")) {
            postData["planid"] = subscriptionid
            postData["storeid"] = storeId
            postData["plazaid"] = plazaId
            postData["subscriptionid"] = params.get("subscription_id")

            console.log("== postData ===>", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

            this.UpdateSubscription(authToken, postData)
        }
    }

    UpdateSubscription(authToken, postData) {
        this.setState({ isActive: true })
        var URL = SUBSCRIPTIONUPDATE
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("response", responseData)
                    this.props.history.push({
                        pathname: RouteMerchant.MySubscriptionOnly
                    });
                    RemoveFromStorage(Constants.SUBSCRIPTIONID)
                    this.getCheckPlan(authToken, postData.storeid, postData.plazaid)
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }

    //////////////////// detail api //////////////////////////////////////////
    //////////////////////// detail api ////////////////////////////////////


    navPaypalCheckout(path) {
        this.props.history.push({
            pathname: path
        });
    }

    handleDeactivePlan() {
        var postData = {}
        if (this.state.storeId && this.state.plazaId && this.state.paymentObj) {
            postData["planid"] = this.state.paymentObj.plan_id
            postData["storeid"] = this.state.storeId
            postData["plazaid"] = this.state.plazaId
            postData["subscriptionid"] = this.state.paymentObj.id

            console.log("== postData ===>", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

            this.deactivateSubscription(authToken, postData)
        }
    }

    deactivateSubscription(authToken, postData) {
        this.setState({ isActive: true })
        var URL = SUBSCRIPTIONCANCEL
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("response", responseData)
                    // if (responseData) {
                    //     var a = document.createElement('a')
                    //     a.target = "_blank"
                    //     a.href = responseData
                    //     a.click()
                    // }
                    this.getCheckPlan(authToken, postData.storeid, postData.plazaid)
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }

    render() {
        const { paymentObj } = this.state;

        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        var deactiveButton
        if (paymentObj) {
            if (paymentObj.status == MySubscriptionConst.ACTIVE) {
                deactiveButton = <div className="">
                    <a className='btn btn-light setdeactivebtn'
                        data-toggle="modal" data-target="#exampleModalLong1"
                    >
                        Deactivate Plan Subscription</a>
                </div>
            }
        }

        var planNavBtn
        if (paymentObj) {
            if (paymentObj.status == MySubscriptionConst.ACTIVE) {
                planNavBtn = <a tabIndex='' className="setValue sethyperlink"
                    onClick={(e) => this.navPaypalCheckout(RouteMerchant.SubscriptionUpdateOnly)}>Update Plan</a>
            }
            else {
                planNavBtn = <a tabIndex='' className="setValue sethyperlink"
                    onClick={(e) => this.navPaypalCheckout(RouteMerchant.SubscriptionOnly)}>View Plan</a>
            }
        }
        else {
            planNavBtn = <a tabIndex='' className="setValue sethyperlink"
                onClick={(e) => this.navPaypalCheckout(RouteMerchant.SubscriptionOnly)}>Activate Plan</a>
        }


        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.PlanDetails} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            {/* <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAddProducts}
                                    className="btn btn-reg-email">
                                    Add Topping
                                </span>
                            </div> */}
                            <div className="reg_form_main">
                                <div className="card cartBorder setmargin">
                                    <div className="card-body">
                                        <h5 className="card-title">Supported payment methods</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">Payment methods that are available with one of {this.state.platForm}'s approved payment providers.</h6>
                                        <div className="card setinnercartbg" >
                                            <div className="card-body">
                                                <div className="paymentbox">
                                                    <div className="payment-image-box">
                                                        <img src={paypal} />
                                                        <span className="card-subtitle mb-2 text-muted">Plan Subscription</span>
                                                    </div>
                                                    <div className="payment-image-box">
                                                        {planNavBtn}
                                                    </div>
                                                </div>
                                                <hr className="hrsolid-border" />
                                                <div className="paymentbox">
                                                    <div className="payment-image-box">
                                                        <p>Provider</p>
                                                        <span className="setValue">PayPal</span>
                                                    </div>
                                                    <div className="payment-image-box">
                                                        <p>Plan Name</p>
                                                        <span className="setValue">{paymentObj ? (paymentObj.plandetails ? paymentObj.plandetails.name : "-") : "-"}</span>
                                                    </div>


                                                    <div className="payment-image-box">
                                                        <p>Last Payment </p>
                                                        <span className="setValue">{paymentObj ? paymentObj.lastpaymentcurrency_code + " " + paymentObj.lastpaymentamount : "-"}</span>
                                                    </div>
                                                    {/* <div className="payment-image-box">
                                                        <p>Plan Name</p>
                                                        <span className="setValue">{paymentObj ? (paymentObj.plandetails ? paymentObj.plandetails.name : "-") : "-"}</span>
                                                    </div> */}
                                                </div>

                                                <div className="paymentbox">
                                                    <div className="payment-image-box">
                                                        <p>Status</p>
                                                        <span className="setValue">{paymentObj ? paymentObj.status : "-"}</span>
                                                    </div>
                                                    <div className="payment-image-box">
                                                        <p>Last Payment Date</p>
                                                        <span className="setValue">{paymentObj ? paymentObj.lastpaymentdate : "-"}</span>
                                                    </div>

                                                    <div className="payment-image-box">
                                                        <p>Current Amount</p>
                                                        <span className="setValue">{paymentObj ?
                                                            (paymentObj.plandetails &&
                                                                paymentObj.plandetails.billing_cycles &&
                                                                paymentObj.plandetails.billing_cycles[0] &&
                                                                paymentObj.plandetails.billing_cycles[0].billing_price_currency_code + " " + paymentObj.plandetails.billing_cycles[0].billing_price) : "-"}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="setmargin hrsolid-border" />
                                <div className="paymentbo setmargin">
                                    {deactiveButton}
                                </div>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div className="modal fade" id="exampleModalLong1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">{Messages.DeactivatePlan}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {Messages.DeactivatePlanMsg}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} >NO</button>
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDeactivePlan()}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default MySubscriptionOnly;