const RouteMerchant = {
    Default: '/',
    Login: '/login',
    Register: '/Register',
    RegisterNew: '/registernew',
    ForgotPassword: '/forgotpassword',
    Validateotp: '/validateotp',
    StoreMain: '/StoreMain',
    PlazaMain: "/plazamain",
    Store: '/Store',
    SelectStore: '/select/store/platform',
    Home: '/Home',
    OrderDetails: '/OrderDetails',
    Selectcountry: '/selectcountry',
    Selectplaza: '/selectplaza',
    Storedetails: '/storedetails',
    AddProducts: '/addProducts',
    AddItems: '/addItems',
    ManageProducts: '/manageProducts',
    ManageItems: '/manageItems',
    ManageToppingCategory: "/toppingcategory",
    AddToppingCategory: "/addtoppingscategory",
    AddToppings: "/addToppings",
    ManageToppings: "/manageToppings",
    StoreSupplier: "/storesupplier",
    AddStoreSupplier: '/addstoresupplier',
    Pickuptimes: "/storehours",
    AddPickuptimes: "/addpickuptimes",
    Deliverytimes: "/deliverytimes",
    AddDeliverytimes: "/adddeliverytimes",
    Inventory: '/inventory',
    UpdateInventory: '/updateInventory',
    InventoryHistory: '/inventoryHistory',
    Notification: "/notification",
    NotificationDetail: "/notificationdetail",
    UserProfile: "/userprofile",
    PurchaseOrder: "/purchaseorder",
    AddPurchaseOrder: "/addpurchaseorder",
    ReceiveInventory: "/receiveinventory",
    Earnings: '/earnings',
    PurchaseOrderDetail: '/purchaseorderdetails',
    ItemList: '/itemsList',

    HomeShopify: '/shopify/home',
    ManageItemsShopify: '/shopify/manageItems',
    ShopifyOrderDetails: "/shopify/OrderDetails",
    PayPalPayment: "/payment",
    PayPalPaymentCheckout: "/payment/checkout",
    StripeStandardPayment: "/stripepayment",
    StripeStandardCheckout: "/stripepayment/checkout",
    StripeExpressPayment: "/stripeplatformpayment",
    StripeExpressCheckout: "/stripeplatformpayment/checkout",
    Subscription: "/subscription",
    MySubscription: "/mysubscription",
    SubscriptionUpdate: "/update/subscription",
    ManageCoupon: "/coupon",
    AddCoupon: "/add/coupon",
    Redemption: "/redemption",

    // SubscriptionOnly: "/new/subscription",
    // MySubscriptionOnly: "/new/mysubscription",
    // SubscriptionUpdateOnly: "/new/update/subscription"
    AddPlaza: "/add/plaza",
    BulkUpload: "/bulkupload/catalog",
    ImageBulkUpload: "/bulkupload/image",
    doordashConfig: "/doordash",
    merchantUser: "/merchant/user",
    AddMerchantUser: "/add/merchant/user",
    StoreAssign: "/store/assign",
    TermsOfService: '/termsofservice',
    PrivacyPolicy: '/privacypolicy',
    PlazaRequest: "/plazarequest",
    AboutUs: "/aboutus",
    HelpAndSupport: "/helpandsupport",
    Pricing: "/Pricing",
    SetAppointment: "/setup/appointment",
    ContactUs: "/contactus",
    ManageTaxes: "/manage/tax",
}
export default RouteMerchant;
