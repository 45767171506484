import React, { Component } from "react";
import "../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../Config/Messages";
import {
    RetriveObjectFromStorage, RetriveDataFromStorage,
    StoreDataInStorage, validateEmail,
    validatePassword, formatPhoneNumber, validatePhoneNumber, validateOtpNo
} from "../Config/Utils";
import { GET_PRODUCT_CATEGORY, PLAZAID, MERCHANTUSER_API, VALIDATEOTP_API } from '../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants from '../Config/Constants'
import Header from '../Header/Header.js'
import Color from "../Config/Color"
import SideMenu from "../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import Constvariable from '../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../Config/RouteMerchant.js';
import { GetData } from "../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { POST_STORE_CATEGORY } from '../Config/Config.json';
import { PostData } from '../../services/PostData';
import { PUTData } from '../../services/PUTData';
import OnHoverIconInfo from "../UtillCustomComponent/UtillCustomComponent"
import { AddUserFrom } from '../Config/HoverMessage'
import { AddUserConst } from "../Config/ConstForm";
import { PutMandatoryfieldSymbol } from "../UtillCustomComponent/UtillCustomComponent";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {
                FirstName: "",
                LastName: "",
                Email: "",
                Password: "",
                PhoneNumber: "",
                PostalCode: "",
                CountryCode: "",
                Code: ""
            },
            errors: {},

            isActive: false,
            userId: "",
            isPassword: true,
            formScreenNav: true
        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidateOtpSubmit = this.handleValidateOtpSubmit.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var userDetail = RetriveObjectFromStorage(Constants.USER_DETAIL)
        if (userDetail) {
            let userFields = this.state.userFields;
            userFields["PostalCode"] = userDetail.PostalCode
            userFields["CountryCode"] = userDetail.CountryCode
            this.setState({ userFields })
        }

        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.get('id') !== null && (params.get('id').length > 0)) {
            var id = params.get('id');
            this.setState({ userId: id })
            this.getUserDetailAPI(authToken, id)
        }
    }

    getUserDetailAPI(authToken, userId) {
        this.setState({ isActive: true, errors: {} })
        var URL = MERCHANTUSER_API + "/" + userId
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                console.log("=====>", responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"]
                    if (responseData) {
                        this.autoPopulated(responseData)
                    }
                    this.setState({ isActive: false });
                }
                else {
                    var errors = this.state.errors;
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(e => {
                var errors = this.state.errors;
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    autoPopulated(storeProductobj) {
        let userFields = this.state.userFields;
        userFields['FirstName'] = storeProductobj.FirstName;
        userFields['LastName'] = storeProductobj.LastName
        userFields['Email'] = storeProductobj.eMail
        userFields['Password'] = ""
        userFields['PhoneNumber'] = storeProductobj.Phone
        userFields['PostalCode'] = storeProductobj.PostalCode ? storeProductobj.PostalCode : userFields['PostalCode'];
        userFields['CountryCode'] = storeProductobj.CountryCode ? storeProductobj.CountryCode : userFields['CountryCode'];
        this.setState({ userFields })
    }


    // /////////////// end detail methods ////////////////////////////

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "PhoneNumber") {
            userFields[key] = formatPhoneNumber(value)
        }
        else {
            userFields[key] = value
        }
        this.setState({ userFields })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = Messages.v_UserEmail;
        }
        else if (typeof fields["Email"] !== "undefined") {
            if (!validateEmail(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }

        if (!fields["FirstName"]) {
            formIsValid = false;
            errors["FirstName"] = Messages.v_UserName;
        }
        // else if (typeof fields["FirstName"] !== "undefined") {
        //     if (!validateUserName(fields["FirstName"])) {
        //         formIsValid = false;
        //         errors["FirstName"] = Messages.v_EnterValidName;
        //     }
        // }

        if (!fields["LastName"]) {
            formIsValid = false;
            errors["LastName"] = Messages.v_UserLastName;
        }

        if (!fields["Password"]) {
            formIsValid = false;
            errors["Password"] = Messages.v_UserPassword
        }
        else if (typeof fields["Password"] !== "undefined") {
            if (!validatePassword(fields["Password"])) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterValidPassword;
            }
        }

        if (!fields["PhoneNumber"]) {
            formIsValid = false;
            errors["PhoneNumber"] = Messages.v_UserPhoneNo
        }
        else if (typeof fields["PhoneNumber"] !== "undefined") {
            if (!validatePhoneNumber(fields["PhoneNumber"])) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterValidPhoneNo;
            }
        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();

        if (this.validateForm()) {
            postData['FirstName'] = this.state.userFields.FirstName;
            postData['LastName'] = this.state.userFields.LastName
            postData['Email'] = this.state.userFields.Email
            postData['Password'] = this.state.userFields.Password
            postData['PhoneNumber'] = this.state.userFields.PhoneNumber

            postData["DeviceId"] = Constants.DEVICE_ID;
            postData["DeviceType"] = Constants.DEVICE_TYPE;
            postData["Company"] = Constants.COMPANY;
            postData["PushToken"] = Constants.PUSH_TOKEN;
            postData["Latitude"] = Constants.LATITUDE;
            postData["Longitude"] = Constants.LONGITUDE;

            postData['PostalCode'] = this.state.userFields.PostalCode
            postData['CountryCode'] = this.state.userFields.CountryCode

            console.log("==> postdata==", postData)

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

            let userId = this.state.userId
            if (userId) {
                this.UpdateUserAPI(authToken, postData, userId)
            }
            else {
                this.postUserAPI(authToken, postData)
            }

        }
    }


    postUserAPI(authToken, postData) {
        this.setState({ isActive: true, errors: {} })
        var URL = MERCHANTUSER_API
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ formScreenNav: false, isActive: false });
                }
                else if (responseJson["StatusCode"] === 400) {
                    var errors = {};
                    errors["ErrorC"] = Messages.v_Email_already_exists;
                    this.setState({ errors: errors, isActive: false });
                } else {
                    var errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(e => {
                var errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            })
    }


    UpdateUserAPI(authToken, postData, userId) {
        this.setState({ isActive: true, errors: {} })
        var URL = MERCHANTUSER_API + "/" + userId;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false });
                    this.props.history.goBack();
                }
                else if (responseJson["StatusCode"] === 400) {
                    var errors = {};
                    errors["ErrorC"] = Messages.v_Email_already_exists;
                    this.setState({ errors: errors, isActive: false });
                }
                else {
                    var errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(e => {
                var errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    validateOTPForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;


        if (!fields["Code"]) {
            formIsValid = false;
            errors["Code"] = Messages.v_UserOTP
        }
        else if (typeof fields["Code"] !== "undefined") {
            if (!validateOtpNo(fields["Code"])) {
                formIsValid = false;
                errors["Code"] = Messages.v_EnterValidOTP;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleValidateOtpSubmit(e) {
        e.preventDefault();
        if (this.validateOTPForm()) {
            var postData = {};
            postData["Email"] = this.state.userFields.Email;
            postData["PhoneNumber"] = this.state.userFields.PhoneNumber;
            postData["DeviceId"] = Constants.DEVICE_ID;
            postData["DeviceType"] = Constants.DEVICE_TYPE;
            postData["Code"] = this.state.userFields.Code;
            console.log('otppostdata: ', postData);

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.callValidateOtpAPI(authToken, postData);
        }
    }

    callValidateOtpAPI(authToken, postData) {
        this.setState({ isActive: true, errors: {} })
        var URL = MERCHANTUSER_API + "/" + VALIDATEOTP_API;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log('responseJason:', responseJson);
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false });
                    this.props.history.goBack();
                }
                else if (responseJson["StatusCode"] === 400) {
                    var errors = {};
                    errors["ErrorC"] = Messages.Invalid_One_Time_Code;
                    this.setState({ errors: errors, isActive: false });
                } else {
                    var errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(e => {
                var errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    render() {
        const { formScreenNav, isPassword } = this.state
        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"                          >
                    <Header setHeaderTitle={this.state.userId ? AddUserConst.UpdateUser : AddUserConst.AddUser}></Header>
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        {formScreenNav ?
                            <>
                                <div className="reg_form_main col-sm-8 offset-sm-2">
                                    <label className="label-color">{AddUserConst.Name}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={AddUserFrom.Name} /> <PutMandatoryfieldSymbol />
                                    </label>
                                    <input
                                        type="text"
                                        name="FirstName"
                                        className='add-item-box'
                                        placeholder={Constvariable.YourName}
                                        value={this.state.userFields.FirstName}
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">
                                        {this.state.errors.FirstName}
                                    </div>
                                </div>

                                <div className="reg_form_main col-sm-8 offset-sm-2">
                                    <label className="label-color">{AddUserConst.LastName}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={AddUserFrom.LastName} /> <PutMandatoryfieldSymbol />
                                    </label>
                                    <input
                                        type="text"
                                        name="LastName"
                                        className='add-item-box'
                                        placeholder={Constvariable.YourLastName}
                                        value={this.state.userFields.LastName}
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">
                                        {this.state.errors.LastName}
                                    </div>
                                </div>

                                <div className="reg_form_main col-sm-8 offset-sm-2">
                                    <label className="label-color">{AddUserConst.PhoneNumber}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={AddUserFrom.PhoneNumber} /> <PutMandatoryfieldSymbol />
                                    </label>
                                    <input
                                        type="text"
                                        name="PhoneNumber"
                                        maxLength="12"
                                        className='add-item-box'
                                        placeholder={Constvariable.U_PhoneNumber}
                                        value={this.state.userFields.PhoneNumber}
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">
                                        {this.state.errors.PhoneNumber}
                                    </div>
                                </div>


                                <div className="reg_form_main col-sm-8 offset-sm-2">
                                    <label className="label-color">{AddUserConst.Email}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={AddUserFrom.Email} /> <PutMandatoryfieldSymbol />
                                    </label>
                                    <input
                                        type="text"
                                        name="Email"
                                        className='add-item-box'
                                        placeholder={Constvariable.R_Email}
                                        value={this.state.userFields.Email}
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">
                                        {this.state.errors.Email}
                                    </div>
                                </div>

                                <div className="reg_form_main col-sm-8 offset-sm-2">
                                    <label className="label-color">{AddUserConst.Password}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={AddUserFrom.Password} /> <PutMandatoryfieldSymbol />
                                    </label>
                                    <div className="row" style={{ backgroundColor: "#f0f0f0", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                        <input
                                            style={{ width: "90%", margin: 0 }}
                                            type={isPassword ? "password" : "text"}
                                            name="Password"
                                            className='add-item-box'
                                            placeholder={Constvariable.Password}
                                            value={this.state.userFields.Password}
                                            onChange={this.handleChange}
                                        />
                                        <i class={!isPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "15px" }} onClick={() => this.setState({ isPassword: !isPassword })} />
                                    </div>
                                    <div className="errorMsg">
                                        {this.state.errors.Password}
                                    </div>
                                </div>

                            </>
                            :
                            <div className="reg_form_main col-sm-8 offset-sm-2">
                                <label className="label-color">{AddUserConst.Enterotp}
                                    &nbsp;
                                    <OnHoverIconInfo
                                        message={AddUserFrom.Enterotp} />
                                </label>
                                <input
                                    type="text"
                                    name="Code"
                                    maxlength="6"
                                    className='add-item-box'
                                    placeholder={Constvariable.OtpNumber}
                                    value={this.state.userFields.Code}
                                    onChange={this.handleChange}
                                />
                                <div className="errorMsg">
                                    {this.state.errors.Code}
                                </div>
                            </div>
                        }

                    </div>
                    <div className="reg_form_main offset-sm-3 col-sm-6">
                        <center>
                            <div className="errorMsg">
                                {this.state.errors.ErrorC}
                            </div>
                        </center>
                    </div>
                    <div className="bottam_sing">
                        {formScreenNav ?
                            <label
                                style={{ background: Color.THEME_COLOR }}
                                for="step5"
                                id="continue-step5"
                                onClick={this.handleAdd}
                                className="btn btn-done"
                            > {this.state.userId ? AddUserConst.btn_UpdateUser : AddUserConst.btn_AddUser}</label>
                            :
                            <label
                                style={{ background: Color.THEME_COLOR }}
                                for="step5"
                                id="continue-step5"
                                onClick={this.handleValidateOtpSubmit}
                                className="btn btn-done"
                            > {AddUserConst.btn_Submit}</label>
                        }
                    </div>
                </LoadingOverlay>
            </section>

        );
    }
}
export default AddUser;