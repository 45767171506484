import React, { Component } from 'react';
import './MerchantList.css';

class MerchantList extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  storenavigate(e) {
    window.location.href = "/OrderDetails";
  }

  render() {

    let MerchantList = this.props.data
      .map(function (data, index) {
        return (
          <div key={index}>
            <div className='row'>
              <div className="col-sm">
                {(index + 1)}
              </div>
              <div class="col-sm">
                {data.storname}
              </div>
              <div class="col-sm">
                {data.OrderStatus}
              </div>
            </div>
          </div>
        )
      }, this);

    return (
      <div>
        {MerchantList}
      </div>
    );
  }
}

export default MerchantList;