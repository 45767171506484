import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants,{DEFAULTID} from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API } from '../../Config/Config.json'
import SideMenu from '../../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import { GET_STORE_SUPPLIER, GET, CLOSE, MARKASORDERD, PLAZAID } from '../../Config/Config.json';
import Moment from "moment";
import Constvariable, { DatetimeFormate } from '../../Config/constvariable';
import { PostData } from '../../../services/PostData';

const plazaList = [
    {
        _id: "5f7c47c621a59146e0a024ae",
        Product: "chesee pizaa",
        // Imageurl: "https://www.bigbasket.com/media/uploads/p/l/40202282_4-lays-potato-chips-indias-magic-masala.jpg",
        Quantity: "1",
        Cost: "5$"


    },

];

class PurchaseOrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeId: "",
            plazaId: "",
            userField: null,
            navFlag: 0,
            purchaseOrderId: "",
            isActive: false
        }
        this.handleAddProducts = this.handleAddProducts.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            let search = window.location.search;
            let params = new URLSearchParams(search);

            if (params.get('id') !== null && (params.get('id').length > 0)) {
                var id = params.get('id');
                this.setState({ purchaseOrderId: id })
                this.getPurchaseOrderDetail(authToken, storeId, id, plazaId)
            }

        }
    }
    getPurchaseOrderDetail(authToken, storeId, id, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + storeId + "/" + GET + "/" + id + "?" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ userField: responseData })
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
            });
    }




    regularRegister(e) {
        localStorage.removeItem("FacebookData");
        window.location.href = "/Store";
    }

    handleAddProducts() {
        this.props.history.push({
            pathname: "/addItems",
        })

    }

    handleUpdate() {
        this.props.history.push({
            pathname: "/updateInventory",
        })
    }

    gotoProduct(flag) {
        this.setState({ navFlag: flag })
    }

    closeOrder() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var purchaseOrderId = this.state.purchaseOrderId;
        var userField = this.state.userField;
        var plazaId = this.state.plazaId
        if (purchaseOrderId && userField) {
            var orderId = userField._id
            var postData = {}
            this.closePurchaseOrder(authToken, postData, purchaseOrderId, orderId, plazaId)
        }
    }
    closePurchaseOrder(authToken, postData, purchaseOrderId, orderId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + purchaseOrderId + "/" + CLOSE + "/" + orderId + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    var storeId = this.state.storeId;
                    if (storeId) {
                        this.getPurchaseOrderDetail(authToken, storeId, purchaseOrderId, plazaId)
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }

    markorder() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var purchaseOrderId = this.state.purchaseOrderId;
        var userField = this.state.userField;
        var plazaId = this.state.plazaId
        if (purchaseOrderId && userField) {
            var orderId = userField._id
            var postData = {}
            this.markAsOrder(authToken, postData, purchaseOrderId, orderId, plazaId)
        }
    }

    markAsOrder(authToken, postData, purchaseOrderId, orderId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + purchaseOrderId + "/" + MARKASORDERD + "/" + orderId + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    var storeId = this.state.storeId;
                    if (storeId) {
                        this.getPurchaseOrderDetail(authToken, storeId, purchaseOrderId, plazaId)
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }


    render() {
        const { hideEmptyPlazaView } = this.state;
        var currencySymbol = ""
        var countryInfo = this.state.userField
        if (countryInfo) {
            currencySymbol = countryInfo.Suppliercurrency + " "
        }
        const columns = [
            {
                name: "Product",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Product}</div>
            },
            {
                name: "Supplier SKU",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.SupplierSKU}</div>
            },
            {
                name: "Cost",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{currencySymbol} {Number(row.Cost).toFixed(2)}</div>
            },
            {
                name: "Quantity",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Quantity}</div>
            },
            {
                name: "Tax",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{currencySymbol} {Number(row.Tax).toFixed(2)}</div>
            },
            {
                name: "Total",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{currencySymbol} {Number(row.Total).toFixed(2)}</div>
            },
        ];
        let userFields = this.state.userField
        let renderFields = ""
        if (userFields) {
            var costAmout = "";
            var Amount = ""
            if (userFields.CostSummary) {
                if (userFields.CostSummary.CostAdjustments) {
                    userFields.CostSummary.CostAdjustments.map(e => {
                        costAmout = e.Adjustment;
                        Amount = e.Amount;
                    })
                }
            }
            renderFields = (
                <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    {this.state.navFlag === 0 &&
                        <div className="container" >
                            <center>
                                <div className="up-notifications">
                                    <h4>
                                        <span>Purchase Details</span>
                                    </h4>
                                </div>
                                <div className="up-notifications">
                                    <table>
                                        <tbody>
                                            <tr height="50">
                                                <td width="150">Purchase order Status:</td>
                                                <td><b>{userFields.PurchaseOrderStatus}</b></td>
                                            </tr>
                                            <tr height="50">
                                                <td>Supplier Name:</td>
                                                <td><b>{userFields.Supplier}</b></td>
                                            </tr>

                                            <tr height="50">
                                                <td>Payment Terms:</td>
                                                <td><b>{userFields.Paymentterms}</b></td>
                                            </tr>
                                            <tr height="50">
                                                <td>Supplier Currency:</td>
                                                <td><b>{userFields.Suppliercurrency}</b></td>
                                            </tr>

                                            <tr height="50">
                                                <td>Estimate Arrival:</td>
                                                <td><b>{userFields.Shipmentdetails && Moment(userFields.Shipmentdetails.EstimateArrival).years() > 1500 && Moment(userFields.Shipmentdetails.EstimateArrival).format(DatetimeFormate.YYYYMMDDdash)}</b></td>
                                            </tr>
                                            <tr height="50">
                                                <td>Shiping Center:</td>
                                                <td><b>{userFields.Shipmentdetails && userFields.Shipmentdetails.ShipingCenter}</b></td>
                                            </tr>

                                            <tr height="50">
                                                <td>Tracking Number:</td>
                                                <td><b>{userFields.Shipmentdetails && userFields.Shipmentdetails.TrackingNumber}</b></td>
                                            </tr>
                                            <tr height="50">
                                                <td>Products:</td>
                                                <td> <label
                                                    style={{ background: Color.THEME_COLOR, margin: "0px", marginBottom: "4px", padding: "4px" }}
                                                    for="step5"
                                                    // id="continue-step5"
                                                    onClick={() => this.gotoProduct(1)}
                                                    className="btn btn-done"
                                                > view Products</label></td>
                                            </tr>

                                            <tr height="50">
                                                <td>Sub Total:</td>
                                                <td><b>{currencySymbol}{userFields.CostSummary && Number(userFields.CostSummary.SubTotal).toFixed(2)}</b></td>
                                            </tr>
                                            <tr height="50">
                                                <td>Total Tax:</td>
                                                <td><b>{userFields.CostSummary && Number(userFields.CostSummary.TotalTax).toFixed(2)}%</b></td>
                                            </tr>
                                            <tr height="50">
                                                <td>Shipping Charge:</td>
                                                <td><b>{currencySymbol}{userFields.CostSummary && Number(userFields.CostSummary.ShippingCharge).toFixed(2)}</b></td>
                                            </tr>
                                            <tr height="50">
                                                <td>Cost Adjustments:</td>
                                                <td><b>{costAmout}</b></td>
                                            </tr>
                                            <tr height="50">
                                                <td>Cost Adjustments Amount:</td>
                                                <td><b>{currencySymbol}{Number(Amount).toFixed(2)}</b></td>
                                            </tr>
                                            <tr height="50">
                                                <td>Total:</td>
                                                <td><b>{currencySymbol}{userFields.CostSummary && Number(userFields.CostSummary.Total).toFixed()}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </center>
                        </div>
                    }
                    {this.state.navFlag === 1 && <div className="container" >
                        <center>
                            <div className="up-notifications">
                                <h4>
                                    <span>Products Details</span>
                                </h4>
                            </div>
                            {/* <div className="up-notifications"> */}
                            <div className="reg_form_main">
                                <DataTableExtensions
                                    data={userFields.Products ? userFields.Products : []}
                                    columns={columns}
                                    export={false}
                                    print={false}
                                    filter={true}
                                    filterHidden={false}
                                    filterPlaceholder={Messages.manageItemSearch}
                                    filterStyle={{ width: "100px" }}

                                >
                                    <DataTable
                                        noHeader={true}
                                    // noTableHead={true}
                                    //pagination
                                    // onRowClicked={this.handleRowClick}

                                    />
                                </DataTableExtensions>
                            </div>
                            <label
                                style={{ background: Color.THEME_COLOR, margin: "0px", marginBottom: "4px", padding: "4px" }}
                                for="step5"
                                // id="continue-step5"
                                onClick={() => this.gotoProduct(0)}
                                className="btn btn-done"
                            > Purchase Details</label>
                            {/* </div> */}
                        </center>
                    </div>
                    }

                    {userFields.PurchaseOrderStatus === "DRAFT" &&
                        < div className="container row" >
                            <div className="col-sm-3 offset-sm-2 bottam_sing">
                                <button
                                    style={{ background: Color.THEME_COLOR }}
                                    // data-toggle="modal" data-target="#exampleModalLong"
                                    onClick={() => { this.closeOrder() }}
                                    className="btn btn-done"
                                > Cloase Orderd</button>
                            </div>
                            <div className="col-sm-3 offset-sm-2 bottam_sing">
                                <button
                                    style={{ background: Color.THEME_COLOR }}
                                    for="step5"
                                    id="continue-step5"
                                    onClick={() => this.markorder()}
                                    className="btn btn-done"
                                > Mark Orderd</button>
                            </div>
                        </div>
                    }

                    {/* <div className="" >
                        <center>
                            <div className="up-notifications">
                                <h4>
                                    <span>Purchase Details</span>
                                </h4>
                            </div>
                            <div className="up-notifications">
                                <table>
                                    <tbody>
                                        <tr height="50">
                                            <td width="150">Purchase order Status:</td>
                                            <td><b>{userFields.PurchaseOrderStatus}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Supplier Name:</td>
                                            <td><b>{userFields.Supplier}</b></td>
                                        </tr>

                                        <tr height="50">
                                            <td>Payment Terms:</td>
                                            <td><b>{userFields.Paymentterms}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Supplier Currency:</td>
                                            <td><b>{userFields.Suppliercurrency}</b></td>
                                        </tr>

                                        <tr height="50">
                                            <td>Product Name:</td>
                                            <td><b>{this.state.data.Email}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td> Product Quantity:</td>
                                            <td><img src={this.state.data.LogoPath} /> </td>
                                        </tr>
                                        <tr height="50">
                                            <td>Cost:</td>
                                            <td><img src={this.state.imgbanner} /> </td>
                                        </tr>
                                        <tr height="50">
                                            <td>Tax:</td>
                                            <td><b>{this.state.data.City}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Total:</td>
                                            <td><b>{this.state.data.StreetAddress}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Shipment Details:</td>
                                            <td><b>{this.state.data.Category}</b></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </center>
                    </div> */}

                    {/* <div className="up-notifications">
                        <h4>
                            <span>Purchase History</span>
                        </h4>
                    </div>

                    <div className="reg_form_main">
                        {!hideEmptyPlazaView
                            ?
                            <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                <div className="bottam_sing">
                                    <img></img>
                                    <p className="col-sm-12" for="FirstName">

                                    </p>
                                </div>


                            </div>


                            :


                            <DataTableExtensions
                                data={plazaList}
                                columns={columns}
                                export={false}
                                print={false}
                                filter={true}
                                filterHidden={false}
                                //c filterPlaceholder={"Search for Products"}
                                filterStyle={{ width: "100px" }}

                            >
                                <DataTable
                                    noHeader={true}
                                    noTableHead={true}
                                    //pagination
                                    onRowClicked={this.handleRowClick}

                                />
                            </DataTableExtensions>

                        }
                    </div> */}

                </div >

            )
        }

        return (
            <LoadingOverlay
                active={this.state.isActive}
                text={Messages.Loading}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        position: "fixed"
                    })
                }}
                className="lodingspinner"
            >
                <div style={{ backgroundColor: Color.WHITE }}>
                    <Header setHeaderTitle={Constvariable.PurchaseOrderDetail} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        {renderFields}
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Delete item</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        Are you sure you want to delete this item?
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.closeOrder() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </div >
            </LoadingOverlay>
        );
    }
}

export default PurchaseOrderDetail;