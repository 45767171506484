import React from "react";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

function getCharacter() {
  const arr = [];
  arr.push(getRandomInt(97, 122));
  arr.push(getRandomInt(65, 90));
  arr.push(getRandomInt(48, 57));
  const value = arr[getRandomInt(0, arr.length)];
  return String.fromCharCode(value);
};

export function GETWORD(length) {
  length = length ? length : 6
  let newWord = ``;
  let count = 0;
  while (count < length) {
    newWord += getCharacter();
    count++;
  }
  return newWord;
};

export function ACaptcha({ font, align, baseline, width, height, bgColor, color, captchstr }) {

  const captcha = document.createElement('canvas');
  const newWord = captchstr;
  const context = captcha.getContext('2d');
  context.canvas.width = width;
  context.canvas.height = height;
  context.font = font;
  context.textAlign = align;
  context.textBaseline = baseline;
  context.fillStyle = bgColor;
  context.fillRect(0, 0, width, height);
  context.fillStyle = color;
  context.fillText(newWord, width / 2, height / 2);

  return (
    <div>
      <img src={context.canvas.toDataURL()} data-Key={newWord} />
    </div>
  );
}

/* Captch Code Generator */
/* class CaptchaGenerator {

  constructor(font, align, baseline, width, height, bgColor, color, length) {
    this.font = font;
    this.align = align;
    this.baseline = baseline;
    this.width = width;
    this.height = height;
    this.color = color;
    this.bgColor = bgColor;
    this.length = length;

    this.createImage = () => {
      const captcha = document.createElement('canvas');
      const newImage = document.createElement('image');

      const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
      };

      const getCharacter = () => {
        const arr = [];
        arr.push(getRandomInt(97, 122));
        arr.push(getRandomInt(65, 90));
        arr.push(getRandomInt(48, 57));
        const value = arr[getRandomInt(0, arr.length)];
        return String.fromCharCode(value);
      };

      const getWord = () => {
        let newWord = ``;
        let count = 0;
        while (count < this.length) {
          newWord += getCharacter();
          count++;
        }
        return newWord;
      };

      const newWord = getWord();
      const context = captcha.getContext('2d');
      context.canvas.width = this.width;
      context.canvas.height = this.height;
      context.font = this.font;
      context.textAlign = this.align;
      context.textBaseline = this.baseline;
      context.fillStyle = this.bgColor;
      context.fillRect(0, 0, this.width, this.height);
      context.fillStyle = this.color;
      context.fillText(newWord, this.width / 2, this.height / 2);
      newImage.setAttribute('src', context.canvas.toDataURL());
      newImage.setAttribute('data-key', newWord);
      return newImage.outerHTML;
    };
  }
}

export default CaptchaGenerator; */


/* const ACaptchaGenerator = new CaptchaGenerator(
    '30px Arial',
    'center',
    'middle',
    300,
    50,
    Color.THEME_COLOR,
    Color.WHITE,
    6
).createImage();

function createMarkup(source) {
    return { __html: source };
}

function ACaptcha() {
    return <div dangerouslySetInnerHTML={createMarkup(ACaptchaGenerator)} />;
} */