import React, { Component } from 'react';
import { COMPANYCONST } from '../Config/Constants';
import MainFooter from "../MainFooter/MainFooter";
import MainHeader from "../MainHeader/MainHeader";

export default class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div class="hero_area">
                    <MainHeader />
                </div>
                <section className="section mb-5">
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                        <div className="reg_form_main">
                            <center><p id="instruction" className="textcolor" style={{ marginBottom: '10px' }}> Pricing </p> </center>
                        </div>
                        <div class="midsection pl-2 pr-2 pl-sm-0 pr-sm-0" style={{ textAlign: 'justify' }}>
                            <div class="Justify_Font_11">
                                <p>Coming soon...</p>
                            </div>
                        </div>
                    </div>
                </section>
                <MainFooter />
            </div>
        );
    }
}