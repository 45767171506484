import React, { Component } from 'react';
import defaultUser from "../../images/icons/default-user.png";
import './SideMenu.css';
import WheelPhoto from "../../images/icons/gear-wheel.png";
import { MenuItem } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import Color from '../Config/Color';
// import RouteConstant from './Config/RouteConstant';
import { withRouter } from 'react-router';

const Curblist = [
    {
        OrderId: 1,
        Name: "Item 1",
        OrderDate: "26 oct 2020, 16:44:29",
        OrderStatus: "PENDING",
        Items:"1",
        Price: "$115.05"
    },
    {
        OrderId: 2,
        Name: "Item 2",
        OrderDate: "26 oct 2020, 16:44:29",
        OrderStatus: "PENDING",
        Items:"1",
        Price: "$11.05"
    }
];

class CurbData extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleItemClick = this.handleItemClick.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    componentDidMount() {
    }

    handleItemClick(data){
        console.log("item click")
        console.log(JSON.stringify(data))
        this.closeNav();
        // this.props.history.push({
		// 	pathname: RouteConstant.CurbsideOrder,                   
		// })
    }

    closeNav() {
        window.closeNav();
    }

    render() {
        let list = this.props.data
            .map(function (item, index) {             
                return (
                    <React.Fragment key={item.OrderId}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY }} onClick={(value) => this.handleItemClick(item)} >
                            <div className="row" style={{ fontSize: "12px" }}>Order ID: {item.OrderId} </div>
                            <div className="row" style={{ fontSize: "12px" }}>Order Date: {item.OrderDate}</div>
                            <div className="row" style={{ fontSize: "12px" }}>Order Status: {item.OrderStatus}</div>
                            <div className="row" style={{ fontSize: "12px" }}>
                                <div  style={{ fontSize: "12px", marginLeft:"auto", marginRight:"18px" }}>
                                    <center>1 Shops with <br/> 1 Items</center>
                                </div>
                            </div>
                            <div className="row" >
                                <label style={{ fontSize: "12px", color: Color.THEME_COLOR }}> {item.Price} </label>
                                <span style={{ marginLeft: "auto",  }}>
                                    <img src={item.ItemImage} style={{height:"25px", marginRight:"10px", width:"25px"}}></img>
                                    <label style={{ fontSize: "13px", float:"right", marginRight:"18px" }}>+ View More</label>
                                 </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);

        return (
            <div> 
                {list}
            </div>
        );
    }
}

export default withRouter(CurbData);