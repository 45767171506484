import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreDataInStorage, validateFloat } from "../../Config/Utils";
import { GET_PRODUCT_CATEGORY, PLAZAID, GET_COUPON_API, STOREID } from '../../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants, { DEFAULTID, imageStandard } from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
import SideMenu from "../../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import Constvariable, { DatetimeFormate, IMAGEFILEEXTENSIONS } from '../../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { GetData } from "../../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { POST_STORE_CATEGORY } from '../../Config/Config.json';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { CategoryForm, AddCouponForm } from '../../Config/HoverMessage'
import { CategoryConstForm, AddCouponConst } from "../../Config/ConstForm"
import Moment from "moment";
import { PutMandatoryfieldSymbol } from "../../UtillCustomComponent/UtillCustomComponent";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class AddCoupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {
                Title: "",
                Description: "",
                MinimumRequiredAmount: "",
                DicountPrice: "",
                StartDate: "",
                EndDate: "",
                imagebase64data: ""
            },
            errors: {},

            isActive: false,
            storeId: "",
            plazaId: "",
            couponId: '',

            selectedFile: null,
            isPerent: true

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handelOnBlur = this.handelOnBlur.bind(this)

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            let search = window.location.search;
            let params = new URLSearchParams(search);
            if (params.get('id') && (params.get('id').length > 0)) {
                var couponid = params.get('id')
                this.setState({ couponId: couponid })
                this.getCouponDetail(authToken, couponid, storeId, plazaId)
            }
        } else {
            this.props.history.goBack()
        }
    }

    getCouponDetail(authToken, couponid, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_COUPON_API + "/" + couponid + "?" + STOREID + storeId + "&" + PLAZAID + plazaId
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.autoPopulated(responseData)
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    autoPopulated(couponObj) {
        let userFields = this.state.userFields;
        userFields['Title'] = couponObj.Title;
        userFields['Description'] = couponObj.Description;
        userFields['MinimumRequiredAmount'] = couponObj.MinimumrequiredAmount;
        userFields['DicountPrice'] = couponObj.DicountPrice;
        userFields['StartDate'] = Moment.utc(couponObj.StartDate).format(DatetimeFormate.YYYYMMDDdash);
        userFields['EndDate'] = Moment.utc(couponObj.EndDate).format(DatetimeFormate.YYYYMMDDdash);
        this.setState({ userFields, selectedFile: couponObj.imageurl })
    }


    // /////////////// end detail methods ////////////////////////////

    handleinputfile = (e) => {
        e.target.value = null
    }

    onSelectFile = event => {
        try {
            var fileobj = event.target.files[0];
            if (fileobj.size > 0) {
                var fileName = event.target.files[0].name;
                var fileSize = event.target.files[0].size;
                var extension = IMAGEFILEEXTENSIONS;
                var fileSplit = fileName.split('.');
                var filelength = fileSplit.length
                var fileExtension = fileSplit[filelength - 1]
                var extensionFlag = extension.includes(String(fileExtension).toLowerCase())

                if (extensionFlag) {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(fileobj);
                    fileReader.onload = (fileLoadedEvent) => {
                        this.standardImageSizeConvert(fileLoadedEvent.target.result, fileSize)
                    };
                }
                else {
                    let errors = {};
                    errors["selectedFile"] = Messages.v_ImageExtension_ALL;
                    this.setState({ errors: errors });
                }
            }
        }
        catch (err) {
            console.log("File Upload Error", err)
        }
    };

    standardImageSizeConvert(e, size) {
        let error = {}
        var img = new Image();
        let userFields = this.state.userFields
        img.onload = () => {
            /* var iw = img.width;
            var ih = img.height; */

            /* if (iw == imageStandard.couponImageW && ih == imageStandard.couponImageH) { */
            var base64 = e;
            userFields["imagebase64data"] = e
            this.setState({ selectedFile: base64, userFields });
            /* } */
            /* else if (imageStandard.ImageSize < size) {
                let s = Messages.v_couponImageResolution
                error["selectedFile"] = s
            }
            else {
                let s = Messages.v_couponImageResolution
                error["selectedFile"] = s
            } */
            this.setState({ errors: error });
        }
        img.src = e;
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    handelOnBlur(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (Number(value)) {
            userFields[key] = Number(value).toFixed(2)
        }
        this.setState({ userFields })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["Title"]) {
            formIsValid = false;
            errors["Title"] = Messages.Title;
        }

        if (!fields["Description"]) {
            formIsValid = false;
            errors["Description"] = Messages.Description;
        }
        if (!fields["MinimumRequiredAmount"]) {
            formIsValid = false;
            errors["MinimumRequiredAmount"] = Messages.MinimumRequiredAmount;
        }
        else if (fields["MinimumRequiredAmount"]) {
            if (!validateFloat(fields["MinimumRequiredAmount"])) {
                formIsValid = false;
                errors["MinimumRequiredAmount"] = Messages.Num_MinimumRequiredAmount;
            }
        }
        if (!fields["DicountPrice"]) {
            formIsValid = false;
            errors["DicountPrice"] = Messages.DicountPrice;
        }
        else if (fields["DicountPrice"]) {
            if (!validateFloat(fields["DicountPrice"])) {
                formIsValid = false;
                errors["DicountPrice"] = Messages.Num_DicountPrice;
            }
        }

        if (fields['MinimumRequiredAmount'] && fields['DicountPrice']) {
            if (Number(fields['DicountPrice']) >= Number(fields['MinimumRequiredAmount'])) {
                formIsValid = false;
                errors["DicountPrice"] = Messages.DicountValidPrice;
            }
        }

        if (!fields["StartDate"]) {
            formIsValid = false;
            errors["StartDate"] = Messages.StartDate;
        }
        if (!fields["EndDate"]) {
            formIsValid = false;
            errors["EndDate"] = Messages.EndDate;
        }
        if (!this.state.selectedFile) {
            formIsValid = false;
            errors["selectedFile"] = Messages.uploadCouponLogo;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['storeid'] = this.state.storeId
            postData["plazaid"] = this.state.plazaId
            postData['Title'] = this.state.userFields.Title;
            postData['Description'] = this.state.userFields.Description;
            postData['MinimumrequiredAmount'] = this.state.userFields.MinimumRequiredAmount;
            postData['DicountPrice'] = this.state.userFields.DicountPrice;
            postData['StartDate'] = Moment.utc(this.state.userFields.StartDate).format(DatetimeFormate.YYYYMMDDdash);
            postData['EndDate'] = Moment.utc(this.state.userFields.EndDate).format(DatetimeFormate.YYYYMMDDdash);
            postData['imagebase64data'] = this.state.userFields.imagebase64data;

            console.log("==> postdata==", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

            if (this.state.couponId != '' && this.state.couponId) {
                postData['_id'] = this.state.couponId;
                this.putCoupon(authToken, postData)
            }
            else {
                this.postCoupon(authToken, postData)
            }

        }
    }


    postCoupon(authToken, postData) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_COUPON_API
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }


    putCoupon(authToken, postData) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_COUPON_API;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    render() {

        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }
        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner">
                    <Header setHeaderTitle={this.state.couponId ? AddCouponConst.UpdateCoupon : AddCouponConst.AddCoupon}></Header>
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{AddCouponConst.Title}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddCouponForm.Title} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="Title"
                                className='add-item-box'
                                placeholder={Constvariable.Title}
                                value={this.state.userFields.Title}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.Title}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{AddCouponConst.Description}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddCouponForm.Description} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="Description"
                                className='add-item-box'
                                placeholder={Constvariable.Description}
                                value={this.state.userFields.Description}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.Description}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{AddCouponConst.MinimumRequiredAmount} {currencySymbol}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddCouponForm.MinimumRequiredAmount} classString={"tooltip-top-left"} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="MinimumRequiredAmount"
                                className='add-item-box'
                                placeholder={Constvariable.MinimumRequiredAmount}
                                value={this.state.userFields.MinimumRequiredAmount}
                                onChange={this.handleChange}
                                onBlur={this.handelOnBlur}
                            />
                            <div className="errorMsg">
                                {this.state.errors.MinimumRequiredAmount}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{AddCouponConst.DicountPrice} {currencySymbol}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddCouponForm.DicountPrice} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="DicountPrice"
                                className='add-item-box'
                                placeholder={Constvariable.DicountPrice}
                                value={this.state.userFields.DicountPrice}
                                onChange={this.handleChange}
                                onBlur={this.handelOnBlur}
                            />
                            <div className="errorMsg">
                                {this.state.errors.DicountPrice}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{AddCouponConst.StartDate}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddCouponForm.StartDate} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="date"
                                name="StartDate"
                                className='add-item-box'
                                placeholder={Constvariable.StartDate}
                                value={this.state.userFields.StartDate}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.StartDate}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{AddCouponConst.EndDate}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddCouponForm.EndDate} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="date"
                                name="EndDate"
                                className='add-item-box'
                                placeholder={Constvariable.EndDate}
                                value={this.state.userFields.EndDate}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.EndDate}
                            </div>
                        </div>
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color" >
                                {AddCouponConst.UploadcouponLogo}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={AddCouponForm.UploadImage} /> <PutMandatoryfieldSymbol />
                            </label>
                            <div className="setupload-botton">
                                <div className="btn-upload">
                                    <label className="btn" for="updateProfile" style={{ padding: "4px 0px 0px 0px ", background: Color.THEME_COLOR }}>
                                        {this.state.selectedFile ? AddCouponConst.EditLabel : AddCouponConst.btn_Upload}
                                    </label>
                                    <input
                                        type="file"
                                        id="updateProfile"
                                        style={{ opacity: "0" }}
                                        data-id="profile"
                                        className="hideAction"
                                        onClick={this.handleinputfile}
                                        onChange={this.onSelectFile} />
                                </div>
                            </div>

                            <div className="errorMsg white-space-pw">
                                {this.state.errors.selectedFile}
                            </div>

                            <center>
                                <div className="aepa-left banner-updated">
                                    <img src={this.state.selectedFile} style={{ borderRadius: '10px' }} />
                                </div>
                            </center>
                        </div>
                    </div>

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                        <center>
                            <div className="errorMsg">
                                {this.state.errors.ErrorC}
                            </div>
                        </center>
                    </div>

                    <div className="bottam_sing">
                        <label
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done"
                        > {this.state.couponId ? AddCouponConst.btn_UpdateCoupon : AddCouponConst.btn_AddCoupon}</label>
                    </div>
                </LoadingOverlay>
            </section>

        );
    }
}
export default AddCoupon;