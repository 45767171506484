import { BackEndRequestURL } from '../components/Config/Config.json'

export function PostData(url, postdata, auth) {
  let BaseURL = BackEndRequestURL + url
  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        authorization: "Bearer " + auth,
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, X-Requested-With',
      },
      body: JSON.stringify(postdata)
    })
      .then(response => response.json())
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}
