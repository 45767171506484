import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API } from '../../Config/Config.json'
import SideMenu from '../../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import Constvariable, { DatetimeFormate } from '../../Config/constvariable';
import Moment from 'moment';

class InventoryHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryHistory: [],
            itemName: ''
        }
        this.handleAddProducts = this.handleAddProducts.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

    }

    componentDidMount() {
        var inventoryObj = RetriveObjectFromStorage(Constants.INVENTORY_OBJ);
        if (inventoryObj) {
            this.setState({ itemName: inventoryObj.ItemName })
            if (inventoryObj.History) {
                this.setState({ inventoryHistory: inventoryObj.History })
            }
        } else {
            this.props.history.goBack()
        }

    }


    regularRegister(e) {
        localStorage.removeItem("FacebookData");
        window.location.href = "/Store";
    }

    handleAddProducts() {
        this.props.history.push({
            pathname: "/addItems",
        })

    }

    handleUpdate() {
        this.props.history.push({
            pathname: "/updateInventory",
        })


    }

    render() {
        const { hideEmptyPlazaView } = this.state;
        // const columns = [
        //     {
        //         cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Date}</div>
        //     },
        //     {
        //         cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Adjustment}</div>
        //     },
        //     {
        //         cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Quantity}</div>
        //     },


        // ];

        const columns = [
            {
                name: "Date",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{Moment(row.CreatedDate).format(DatetimeFormate.DDMMYYYY)}</div>
            },
            {
                name: "Available Quantity",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.AvailableQuantity}</div>
            },
            {
                name: "Update Quantity",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.UpdateQuantity}</div>
            },


        ];

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <Header setHeaderTitle={Constvariable.InventoryHistory} />
                <section className="section" style={{ backgroundColor: Color.WHITE }}>
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                        <div className="reg_form_main">
                            <label style={{ textAlign: "center" }}>{Constvariable.ProductName}: &nbsp;
                                <span style={{ color: Color.THEME_COLOR }}>
                                    {this.state.itemName}
                                </span>
                            </label>
                        </div>
                        <div className="reg_form_main">

                            <DataTableExtensions
                                data={this.state.inventoryHistory}
                                columns={columns}
                                export={false}
                                print={false}
                                filter={true}
                                filterHidden={false}
                                filterPlaceholder={Messages.SearchforInventoryHistory}
                                filterStyle={{ width: "100px" }}

                            >
                                <DataTable
                                    noHeader={true}
                                // noTableHead={true}
                                // //pagination
                                // onRowClicked={this.handleRowClick}

                                />
                            </DataTableExtensions>


                        </div>
                    </div>
                    {/* </LoadingOverlay> */}

                    {/* <!-- delete Modal start--> */}
                    <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLongTitle">Delete item</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    Are you sure you want to delete this item?
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }}>NO</button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }}>YES</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- delete Modal end--> */}

                </section>
            </div>

        );
    }
}

export default InventoryHistory;