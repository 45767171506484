import React, { Component } from "react";
import { PostData } from "../../../services/PostData";
import { GetData } from "../../../services/GetData";
import { Redirect } from "react-router-dom";
import "../../../styles/style.css";
import "./Store.css";
import Cropper from 'react-cropper';
import { compose, withProps } from "recompose";
import ProductTypeList from "../../ProductTypeList/ProductTypeList";
import {
  GET_PRODUCTTYPE, GET_MERCHANT_MASTER_CATEGORY, Mapkey,
  MY_STORE_API, SHOPIFY_API, GET_PLAZA_API, COUNTRY_CODE, PLAZAID,
  GOOGLE_PLACE_KEY, KEY_STRING, PLACE_ID
} from '../../Config/Config.json'
import { GetDataGooglePlace } from '../../../services/GetDataGooglePlace';
import Messages from "../../Config/Messages";
import Constants from '../../Config/Constants';
import { RetriveDataFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import {
  validateUserName, validatePhoneNo, StoreDataInStorage,
  validateInt, RetriveObjectFromStorage, checkPostalCode,
  removeAllSpace, checkLocality, formatPhoneNumber, validatePhoneNumber
} from "../../Config/Utils";
import Select from 'react-select';
import Geocode from "react-geocode";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import Checkbox from 'react-simple-checkbox';
import { PUTData } from '../../../services/PUTData';
import Header from '../../Header/Header.js'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Constvariable, { DatetimeFormate, PlatFrom, GOOGLEPLACEAPIMAP } from '../../Config/constvariable';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { StoreFrom } from '../../Config/HoverMessage'
import { StoreConstFrom, StoreListConst } from "../../Config/ConstForm"
import RouteMerchant from '../../Config/RouteMerchant.js';

const avatar = 'https://rhulopr.s3.amazonaws.com/RHULO/images/default/profile/default_userprofile.png';
const cover = 'https://rhulopr.s3.amazonaws.com/RHULO/images/cover/cover-photo.jpg';
var finalFile;

const AnyReactComponent = ({ text }) => <div>{text}</div>;

var mandatoryStyle = {
  color: "red",
  textStyle: "bold"
};

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
  placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
  option: styles => ({ ...styles, color: '#333' }),
  menuPortal: styles => ({ ...styles, zIndex: "99" })
};


const PlazacartMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB13R4Lf5J4-L15jErCRBLQtQzAfzBLb00&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={15} defaultCenter={{ lat: props.lat, lng: props.lng }}>
    {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
  </GoogleMap>
));

class StoreWithapi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFields: {},
      errors: {},
      data: [],
      hideActionClass: "hideAction",
      selectcategory: '',
      coverPic: '',
      profilePic: '',
      storecatlist: [],
      selectedOption: null,
      chkboxyes: true,
      chkboxno: false,
      chkboxphyyes: true,
      chkboxphyno: false,

      chkmon: false,
      chktue: false,
      chkwed: false,
      chkthu: false,
      chkfri: false,
      chksat: false,
      chksun: false,

      monopt: '',
      moncot: '',
      tueopt: '',
      tuecot: '',
      wedopt: '',
      wedcot: '',
      thuopt: '',
      thucot: '',
      friopt: '',
      fricot: '',
      satopt: '',
      satcot: '',
      sunopt: '',
      suncot: '',

      monobject: {},
      tueobject: {},
      wedobject: {},
      thuobject: {},
      friobject: {},
      satobject: {},
      sunobject: {},

      s: [],
      pbase64: '',
      bbase64: '',
      isMapshow: false,
      storeId: "",
      isLogoUpdate: false,
      isbannerUpdate: false,
      isActive: false,

      storeStatus: "",

      isShopify: false,
      isWix: false,
      isPlazacart: true,

      countryInfo: null,
      plazaList: [],
      selectedPlaza: null,
      plazaId: "",
      isShopifyStoreChange: false,

      PickupEnable: true,
      DeliveryEnable: false,

      selectedAddress: false,
      selectedStoreAddress: false
    };

    this.openSearch = this.openSearch.bind(this);
    this.onSelectProfileFile = this.onSelectProfileFile.bind(this);
    this.onSelectBannerFile = this.onSelectBannerFile.bind(this);
    this.handelModal = this.handelModal.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.handleUserchange = this.handleUserchange.bind(this);
    this.selectcategory = this.selectcategory.bind(this);
    this.handleChangeChkyes = this.handleChangeChkyes.bind(this);
    this.handleChangeChkno = this.handleChangeChkno.bind(this);

    this.handleChangeChkphyyes = this.handleChangeChkphyyes.bind(this);
    this.handleChangeChkphyno = this.handleChangeChkphyno.bind(this);
    this.submitUserRegistrationForm = this.submitUserRegistrationForm.bind(this);
    this.handleChangePickup = this.handleChangePickup.bind(this)
    this.handleChangeDelivery = this.handleChangeDelivery.bind(this)

    // this.selectmonopt = this.selectmonopt.bind(this);
    // this.selectmoncot = this.selectmoncot.bind(this);
    // this.ChangeChkmon = this.ChangeChkmon.bind(this);

    // this.selecttueopt = this.selecttueopt.bind(this);
    // this.selecttuecot = this.selecttuecot.bind(this);
    // this.ChangeChktue = this.ChangeChktue.bind(this);

    // this.selectwedopt = this.selectwedopt.bind(this);
    // this.selectwedcot = this.selectwedcot.bind(this);
    // this.ChangeChkwed = this.ChangeChkwed.bind(this);

    // this.selectthuopt = this.selectthuopt.bind(this);
    // this.selectthucot = this.selectthucot.bind(this);
    // this.ChangeChkthu = this.ChangeChkthu.bind(this);

    // this.selectfriopt = this.selectfriopt.bind(this);
    // this.selectfricot = this.selectfricot.bind(this);
    // this.ChangeChkfri = this.ChangeChkfri.bind(this);

    // this.selectsatopt = this.selectsatopt.bind(this);
    // this.selectsatcot = this.selectsatcot.bind(this);
    // this.ChangeChksat = this.ChangeChksat.bind(this);

    // this.selectsunopt = this.selectsunopt.bind(this);
    // this.selectsuncot = this.selectsuncot.bind(this);
    // this.ChangeChksun = this.ChangeChksun.bind(this);

    this.handleSubmitforpart0 = this.handleSubmitforpart0.bind(this)
    this.handleSubmitforpart1 = this.handleSubmitforpart1.bind(this);
    this.handleSubmitforpart2 = this.handleSubmitforpart2.bind(this);
    this.handleSubmitforpart3 = this.handleSubmitforpart3.bind(this);
    this.handleSubmitforpart4 = this.handleSubmitforpart4.bind(this);
  }

  componentDidMount() {
    this.setState({
      coverPic: 'https://rhulopr.s3.amazonaws.com/RHULO/images/cover/cover-photo.jpg',
      profilePic: 'https://rhulopr.s3.amazonaws.com/RHULO/images/default/profile/default_userprofile.png'
    });
    console.log('I was triggered during componentDidMount')
    var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    console.log("authToken", authToken);
    this.getProducttypeList(authToken);
    // this.getStorecategoryList(authToken);
    this.setState({ lat: 59.95, lng: 30.33 });

    var userDetail = RetriveObjectFromStorage(Constants.USER_DETAIL)
    if (userDetail) {
      var userFields = this.state.userFields
      userFields["PostalCode"] = userDetail.PostalCode
      this.setState({ userFields })
    }

    var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
    if (countryInfo) {
      var userFields = this.state.userFields
      userFields["country"] = countryInfo.CountryName;
      userFields["countrycode"] = countryInfo.CountryCode;
      this.setState({ countryInfo: countryInfo, userFields })
    }
  }

  getProducttypeList(authToken) {
    this.setState({ isActive: true })
    console.log("authToken", authToken);
    GetData(GET_PRODUCTTYPE, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          console.log("responseData ", responseData);
          if (responseData && responseData.length > 0) {
            responseData = responseData.map(e => {
              return { ...e, "IsConfirm": false }
            })
            this.setState({ data: responseData });

            var d = this.state.data;
            var n = [];
            for (var i = 0; i < d.length; i++) {
              var aa = {};
              aa = d[i];

              let key = "IsSelected";
              let v = false;
              aa[key] = v;
              n.push(aa);
            }
            this.setState({ data: n });
            console.log(JSON.stringify(this.state.data));

          }
          else {
            alert(Messages.NO_DATA);
          }
        }
        else {
          alert(Messages.ERR_SomethingWentWrong);
        }
        this.getStorecategoryList(authToken);
      });
  }

  getStorecategoryList(authToken) {
    console.log("authToken", authToken);
    GetData(GET_MERCHANT_MASTER_CATEGORY, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          console.log("responseData ", responseData);
          if (responseData && responseData.length > 0) {
            // var extraOption = [{ "MainCategoryName": "Other" }]
            // var responseCopy = [...responseData, ...extraOption]
            // // responseData.push({
            // //   "MainCategoryName": "Others"
            // // })

            this.setState({ storecatlist: responseData });
          }
          else {
            alert(Messages.NO_DATA);
          }
        }
        else {
          alert(Messages.ERR_SomethingWentWrong);
        }
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.get('id') !== null && (params.get('id').length > 0)) {
          var storeid = params.get('id')
          var plazaid = params.get("plazaId")
          this.setState({ storeId: storeid, plazaId: plazaid })
          this.getStoredetails(authToken, storeid, plazaid)
        }
        else {
          this.setState({ isActive: false })
        }
      });
  }

  getStoredetails(authToken, storeid, plazaid) {

    var URL = MY_STORE_API + "/" + storeid + "?" + PLAZAID + plazaid
    console.log("authToken", URL);
    GetData(URL, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          console.log("responseData ", responseData);
          if (responseData && responseData.storedetail) {
            this.autopopulated(responseData.storedetail, responseData)
          }

        }
        else {
          alert(Messages.ERR_SomethingWentWrong);
        }
        this.setState({ isActive: false })
      });
  }

  autopopulated(storeObj, otherObj) {
    let userFields = this.state.userFields

    var plazaObj = {
      "_id": otherObj.plazaid,
      "CompanyName": otherObj.plazaname
    }
    this.setState({ selectedPlaza: plazaObj })

    if (storeObj.isshopifyintegration) {
      this.setState({
        isShopify: true,
        isWix: false,
        isPlazacart: false
      })
      userFields['StoreURL'] = storeObj.shopifystorename
      userFields['AccToken'] = storeObj.shopifyaccesstoken
      userFields['APIVersion'] = storeObj.shopifyapiversion
      userFields['shopifystoreid'] = storeObj.shopifystoreid
    }
    else {
      this.setState({
        isShopify: false,
        isWix: false,
        isPlazacart: true
      })
    }

    userFields['StoreName'] = storeObj.BusinessName

    var selectedOption = this.state.storecatlist.filter(e => e.MainCategoryName == storeObj.Category)[0];
    if (selectedOption) {
      this.setState({ selectedOption: { value: selectedOption.MainCategoryName, label: selectedOption.MainCategoryName } })
      userFields['StoreCategory'] = selectedOption.MainCategoryName;
    }
    userFields['IsPublicOpen'] = storeObj.IsPublicOpen;
    storeObj.IsPublicOpen ? this.setState({ chkboxyes: true, chkboxno: false }) : this.setState({ chkboxno: true, chkboxyes: false })

    userFields['PhoneNumber'] = formatPhoneNumber(storeObj.PhoneNumber)
    userFields['Website'] = storeObj.Website
    userFields['FacebookUser'] = storeObj.FacebookUser
    // userFields['Storedescription'] = storeObj.Storedescription

    userFields['Storedescription'] = ""

    userFields['City'] = storeObj.City
    userFields["state"] = storeObj.state;
    userFields["country"] = storeObj.country;
    userFields["countrycode"] = storeObj.countrycode;
    userFields["postalcode"] = storeObj.postalcode;
    // userFields['Address'] = storeObj.StreetAddress
    var selectedStoreAddress = {
      label: storeObj.StreetAddress,
      value: {
        description: storeObj.StreetAddress
      }
    }
    this.setState({ selectedStoreAddress: selectedStoreAddress })
    this.getgeocoder(storeObj.City, storeObj.StreetAddress);

    // if (storeObj.StoreOpeningTimes) {
    //   storeObj.StoreOpeningTimes.map(e => {
    //     delete e.Status
    //     if (e.Day === "Monday") {
    //       this.setState({ chkmon: e.IsOpen })
    //       this.setState({
    //         monopt: { value: e.OpeningTime, label: e.OpeningTime },
    //         moncot: { value: e.CloseTime, label: e.CloseTime }
    //       })
    //       this.setState({ monobject: e })
    //     }
    //     else if (e.Day === "Tuesday") {
    //       this.setState({ chktue: e.IsOpen })
    //       this.setState({
    //         tueopt: { value: e.OpeningTime, label: e.OpeningTime },
    //         tuecot: { value: e.CloseTime, label: e.CloseTime }
    //       })
    //       this.setState({ tueobject: e })
    //     }
    //     else if (e.Day === "Wednesday") {
    //       this.setState({ chkwed: e.IsOpen })
    //       this.setState({
    //         wedopt: { value: e.OpeningTime, label: e.OpeningTime },
    //         wedcot: { value: e.CloseTime, label: e.CloseTime }
    //       })
    //       this.setState({ wedobject: e })
    //     }
    //     else if (e.Day === "Thursday") {
    //       this.setState({ chkthu: e.IsOpen })
    //       this.setState({
    //         thuopt: { value: e.OpeningTime, label: e.OpeningTime },
    //         thucot: { value: e.CloseTime, label: e.CloseTime }
    //       })
    //       this.setState({ thuobject: e })
    //     }
    //     else if (e.Day === "Friday") {
    //       this.setState({ chkfri: e.IsOpen })
    //       this.setState({
    //         friopt: { value: e.OpeningTime, label: e.OpeningTime },
    //         fricot: { value: e.CloseTime, label: e.CloseTime }
    //       })
    //       this.setState({ friobject: e })
    //     }
    //     else if (e.Day === "Saturday") {
    //       this.setState({ chksat: e.IsOpen })
    //       this.setState({
    //         satopt: { value: e.OpeningTime, label: e.OpeningTime },
    //         satcot: { value: e.CloseTime, label: e.CloseTime }
    //       })
    //       this.setState({ satobject: e })
    //     }
    //     else if (e.Day === "Sunday") {
    //       this.setState({ chksun: e.IsOpen })
    //       this.setState({
    //         sunopt: { value: e.OpeningTime, label: e.OpeningTime },
    //         suncot: { value: e.CloseTime, label: e.CloseTime }
    //       })
    //       this.setState({ sunobject: e })
    //     }
    //   })
    // }

    userFields['PhysicalOrderReceipts'] = storeObj.PhysicalOrderReceipts;
    storeObj.PhysicalOrderReceipts ? this.setState({ chkboxphyyes: true, chkboxphyno: false }) : this.setState({ chkboxphyno: true, chkboxphyyes: false })

    this.setState({
      profilePic: storeObj.LogoPath,
      PickupEnable: storeObj.IsPickupEnable,
      DeliveryEnable: storeObj.IsDeliveryEnable
    })
    if (storeObj.Homebanner) {
      var homebanner = storeObj.Homebanner[0]
      if (homebanner) {
        this.setState({ coverPic: homebanner.HomeBannerImagePath })
      }
    }

    if (storeObj.productDeliverytypes) {
      var productdeliverytypes = this.state.data.map(e => {
        var Producttypeid = storeObj.productDeliverytypes.filter(e1 => e.Prouducttypeid === e1.Producttypeid)[0]
        if (Producttypeid) {
          return { ...e, "IsConfirm": true }
        }
        else {
          return { ...e }
        }
      })
      this.setState({ data: productdeliverytypes })
    }
    this.setState({ storeStatus: storeObj.Status })
    this.setState({ userFields })
  }


  autopopulatedShopify(storeObj) {
    let userFields = this.state.userFields
    var storeName = String(storeObj.name).replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').trim()

    userFields['StoreName'] = storeName
    userFields["shopifystoreid"] = storeObj.id


    userFields['PhoneNumber'] = formatPhoneNumber(storeObj.phone)
    userFields['Website'] = storeObj.domain
    // userFields['FacebookUser'] = storeObj.FacebookUser

    userFields['Storedescription'] = ""

    userFields['City'] = storeObj.city
    userFields["state"] = storeObj.province;
    userFields["postalcode"] = storeObj.zip;
    var address = storeObj.address2 + "," + storeObj.city + "," + storeObj.province + "," + storeObj.zip
    var selectedStoreAddress = {
      label: address,
      value: {
        description: address
      }
    }
    // userFields['Address'] = address
    this.getgeocoder(storeObj.city, address);


    this.setState({
      storeStatus: storeObj.Status,
      selectedStoreAddress: selectedStoreAddress
    })
    this.setState({ userFields, isShopifyStoreChange: false })
  }

  // /////////////////// detail api end /////////////////////////////
  ///////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  handelAddress(addressObj) {
    this.setState({
      selectedAddress: addressObj,
      selectedStoreAddress: addressObj
    })
    console.log(addressObj)
    let userFields = this.state.userFields;
    userFields["PostalCode"] = "";
    userFields["City"] = "";
    userFields["state"] = "";
    userFields["country"] = "";
    userFields["countrycode"] = "";
    userFields["postalcode"] = "";
    if (addressObj && addressObj.value && addressObj.value.place_id) {
      this.getaddressGooglePlace("", addressObj.value.place_id, userFields)
    }
    // else {
    //   userFields["PostalCode"] = "";
    //   userFields["City"] = "";
    // }
    this.setState({ userFields })
  }

  getaddressGooglePlace(authToken, value, refObj) {
    this.setState({ isActive: true })
    var placeId = value
    console.log(placeId)
    geocodeByPlaceId(placeId)
      .then(result => {
        var responseJson = result ? result[0] : null;
        console.log("====", result)
        if (responseJson) {
          var responseData = responseJson;
          if (responseData && responseData.address_components) {
            for (var obj of responseData.address_components) {
              const componentType = obj.types[0];
              switch (componentType) {
                // case GOOGLEPLACEAPIMAP.STREET_NUMBER: {
                //   refObj['apartment'] = obj.long_name;
                //   break;
                // }
                // case GOOGLEPLACEAPIMAP.ROUTE: {
                //   refObj['apartment'] += obj.short_name;
                //   break;
                // }
                case GOOGLEPLACEAPIMAP.POSTAL_CODE: {
                  refObj['postalcode'] = removeAllSpace(obj.long_name);
                  refObj["PostalCode"] = removeAllSpace(obj.short_name)
                  break;
                }
                case GOOGLEPLACEAPIMAP.POSTAL_CODE_SUFFIX: {
                  refObj['postalcode'] = removeAllSpace(refObj['postalcode'] + "-" + obj.long_name);
                  break;
                }
                case GOOGLEPLACEAPIMAP.LOCALITY: {
                  refObj['City'] = obj.long_name;
                  break;
                }
                case GOOGLEPLACEAPIMAP.ADMINISTRATIVE_AREA_LEVEL_1: {
                  refObj['state'] = obj.short_name;
                  break;
                }
                case GOOGLEPLACEAPIMAP.COUNTRY:
                  refObj['country'] = obj.long_name;
                  refObj['countrycode'] = obj.short_name;
                  break;
              }
            }
          }
        }
        this.setState({ refObj, isActive: false })
      }).catch(err => {
        this.setState({ refObj, isActive: false })
      });
  }

  validateFormpart0() {
    let errors = {};
    let formIsValid = true;
    let fields = this.state.userFields;
    if (this.state.isShopify || this.state.isWix) {
      if (!fields["StoreURL"]) {
        formIsValid = false;
        errors["StoreURL"] = Messages.StoreURL;
      }
      if (!fields["AccToken"]) {
        formIsValid = false;
        errors["AccToken"] = Messages.AccToken;
      }
      if (this.state.isShopifyStoreChange) {
        let checkToken = String(fields.AccToken).includes("*****")
        if (checkToken) {

          formIsValid = false;
          errors["AccToken"] = Messages.NewAccToken;

        }
      }
      // if (!fields["APIVersion"]) {
      //   formIsValid = false;
      //   errors["APIVersion"] = Messages.APIVersion;
      // }
    }
    if (!fields["PostalCode"]) {
      formIsValid = false;
      errors["PostalCode"] = Messages.v_EnterPostalCode;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmitforpart0(e) {
    e.preventDefault();
    if (this.validateFormpart0()) {
      let userFields = this.state.userFields
      var postData = {}
      var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
      if (this.state.isShopify) {
        var storeName = String(userFields.StoreURL).replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(" ", "-").toLocaleLowerCase().trim()
        storeName = String(storeName).split(" ").join("-")
        postData["storename"] = storeName
        postData["accesstoken"] = userFields.AccToken
        postData["apiversion"] = userFields.APIVersion
        console.log("===== addstoreOnShopify =======>", postData)

        let checkToken = String(userFields.AccToken).includes("******************************")
        if (!checkToken) {
          this.addstoreOnShopify(postData, authToken)
        }
        else {
          document.getElementById("step1").checked = true;
        }


      }
      else if (this.state.isWix) {

      }
      else {
        document.getElementById("step1").checked = true;
      }
      var countryCode = this.state.countryInfo ? this.state.countryInfo.CountryCode : ""
      if (countryCode) {
        this.getPlazaByPostalCodeAPI(this.state.userFields.PostalCode, countryCode)

      }
      else {
        this.setState({ plazaList: [], selectedPlaza: null })
      }
    }
  }


  addstoreOnShopify(postData, authToken) {
    this.setState({ isActive: true })
    PostData(SHOPIFY_API, postData, authToken)
      .then(result => {
        var responseJson = result;
        console.log('===== shopify =====>', responseJson)
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          if (responseData) {
            this.autopopulatedShopify(responseData)
            document.getElementById("step1").checked = true;
          }

        }
        else {
          alert(Messages.ERR_SomethingWentWrong)
        }
        this.setState({ isActive: false })
      })
  }

  getPlazaByPostalCodeAPI(postalCode, countryCode) {
    var API = GET_PLAZA_API + postalCode + COUNTRY_CODE + countryCode;
    GetData(API)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          console.log("responseData ", responseData);
          if (responseData) {
            this.setState({ plazaList: responseData });
          }
          else {
            this.setState({ plazaList: [], selectedPlaza: null })
          }
        }
        else {
          this.setState({ plazaList: [], selectedPlaza: null })
          // alert(Messages.NO_DATA);
        }
      });
  }

  validateFormpart1() {
    let errors = {};
    let formIsValid = true;
    let fields = this.state.userFields;
    if (!fields["StoreName"]) {
      formIsValid = false;
      errors["StoreName"] = Messages.S_NAME;
    }
    // else if (typeof fields["StoreName"] !== "undefined") {
    //   if (!validateUserName(fields["StoreName"]) && this.state.isPlazacart) {
    //     formIsValid = false;
    //     errors["StoreName"] = Messages.S_VALID_NAME;
    //   }
    // }
    if (!fields["StoreCategory"]) {
      formIsValid = false;
      errors["StoreCategory"] = Messages.S_CATE;
    }


    if (!this.state.selectedPlaza) {
      formIsValid = false;
      errors["TenantEmail"] = Messages.v_EnterPlaza
    }


    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmitforpart1(e) {
    e.preventDefault();
    if (this.validateFormpart1()) {
      let userFields = this.state.userFields;
      if (typeof userFields["IsPublicOpen"] == "undefined") {
        let key = "IsPublicOpen";
        let value = true;
        userFields[key] = value
        this.setState({ userFields });
      }
      document.getElementById("step2").checked = true;
    }
  }

  validateFormpart2() {
    let errors = {};
    let formIsValid = true;
    let fields = this.state.userFields;

    if (!fields["PhoneNumber"]) {
      formIsValid = false;
      errors["PhoneNumber"] = Messages.v_S_phoneNumber
    }
    else if (typeof fields["PhoneNumber"] !== "undefined") {
      if (!validatePhoneNumber(fields["PhoneNumber"])) {
        formIsValid = false;
        errors["PhoneNumber"] = Messages.v_EnterValidPhoneNo;
      }
    }

    if (!fields["Website"]) {
      formIsValid = false;
      errors["Website"] = Messages.S_WEBSITE_URL;
    }

    if (!fields["FacebookUser"]) {
      formIsValid = false;
      errors["FacebookUser"] = Messages.S_FACEBOOK;
    }

    // if (!fields["Storedescription"]) {
    //   formIsValid = false;
    //   errors["Storedescription"] = Messages.S_DEC;
    // }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmitforpart2(e) {
    var postData = {};
    e.preventDefault();
    if (this.validateFormpart2()) {
      document.getElementById("step3").checked = true;
    }
  }

  handelStoreAddress(addressObj) {
    this.setState({
      selectedStoreAddress: addressObj
    })

    console.log(addressObj)
    let userFields = this.state.userFields;
    userFields["City"] = "";
    userFields["state"] = "";
    userFields["country"] = "";
    userFields["countrycode"] = "";
    userFields["postalcode"] = "";
    if (addressObj && addressObj.value && addressObj.value.place_id) {
      this.getaddressGooglePlaceCity("", addressObj.value.place_id, userFields)
    }
    // else {
    //   userFields["City"] = "";
    // }
    this.setState({ userFields })
  }

  getaddressGooglePlaceCity(authToken, value, refObj) {
    this.setState({ isActive: true })
    var placeId = value
    console.log(placeId)
    geocodeByPlaceId(placeId)
      .then(result => {
        var responseJson = result ? result[0] : null;
        console.log("====", result)
        if (responseJson) {
          var responseData = responseJson;
          if (responseData && responseData.address_components) {
            for (var obj of responseData.address_components) {
              const componentType = obj.types[0];
              switch (componentType) {
                // case GOOGLEPLACEAPIMAP.STREET_NUMBER: {
                //   refObj['apartment'] = obj.long_name;
                //   break;
                // }
                // case GOOGLEPLACEAPIMAP.ROUTE: {
                //   refObj['apartment'] += obj.short_name;
                //   break;
                // }
                case GOOGLEPLACEAPIMAP.POSTAL_CODE: {
                  refObj['postalcode'] = removeAllSpace(obj.long_name);
                  break;
                }
                case GOOGLEPLACEAPIMAP.POSTAL_CODE_SUFFIX: {
                  refObj['postalcode'] = removeAllSpace(refObj['postalcode'] + "-" + obj.long_name);
                  break;
                }
                case GOOGLEPLACEAPIMAP.LOCALITY: {
                  refObj['City'] = obj.long_name;
                  break;
                }
                case GOOGLEPLACEAPIMAP.ADMINISTRATIVE_AREA_LEVEL_1: {
                  refObj['state'] = obj.short_name;
                  break;
                }
                case GOOGLEPLACEAPIMAP.COUNTRY:
                  refObj['country'] = obj.long_name;
                  refObj['countrycode'] = obj.short_name;
                  break;
              }
            }
          }
        }
        this.setState({ refObj, isActive: false })
      }).catch(err => {
        refObj["City"] = ""
        this.setState({ refObj, isActive: false })
      });
  }


  validateFormpart3() {
    let errors = {};
    let formIsValid = true;
    let fields = this.state.userFields;
    if (!fields["City"]) {
      formIsValid = false;
      errors["City"] = Messages.S_CITY;
    }
    if (!this.state.selectedStoreAddress) {
      formIsValid = false;
      errors["Address"] = Messages.S_ADDRESS;
    }
    if (!fields["state"]) {
      formIsValid = false;
      errors["state"] = Messages.v_state;
    }
    if (!fields["country"]) {
      formIsValid = false;
      errors["country"] = Messages.v_country;
    }
    if (!fields["postalcode"]) {
      formIsValid = false;
      errors["postalcode"] = Messages.v_EnterPostalCode;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmitforpart3(e) {
    e.preventDefault();
    if (this.validateFormpart3()) {
      let fields = this.state.userFields;
      var city = fields['City'];
      var address = this.state.selectedStoreAddress ? this.state.selectedStoreAddress.label : "";
      this.getgeocoder(city, address);

      let userFields = this.state.userFields;
      if (typeof userFields["PhysicalOrderReceipts"] == "undefined") {
        let key = "PhysicalOrderReceipts";
        let value = true;
        userFields[key] = value
        this.setState({ userFields });
      }
      document.getElementById("step4").checked = true;
    }
  }

  getgeocoder(city, address) {
    Geocode.setApiKey(GOOGLE_PLACE_KEY);
    Geocode.setLanguage("en");
    Geocode.enableDebug();
    Geocode.fromAddress(address + "," + city).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log("Response" + parseFloat(lat) + " " + parseFloat(lng));
        this.setState({ lat: parseFloat(lat), lng: parseFloat(lng), isMapshow: true });
      },
      error => {
        console.error(error);
      }
    );
  }

  validateFormpart4() {
    let errors = {};
    let formIsValid = true;
    if (this.state.profilePic == avatar) {
      formIsValid = false;
      errors["logo"] = Messages.S_LOGO;
    }
    if (this.state.coverPic == cover) {
      formIsValid = false;
      errors["banner"] = Messages.S_BANNER;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmitforpart4(e) {
    var postData = {};
    e.preventDefault();
    if (this.validateFormpart4()) {
      document.getElementById("step5").checked = true;
    }
  }

  closeSearch() {
    window.closeSearch();
  }

  openSearch() {
    window.openSearch();
  }






  handleCheck(itemObj) {
    var itemList = this.state.data.map(e => {
      if (itemObj.Prouducttypeid === e.Prouducttypeid) {
        return { ...e, "IsConfirm": !e.IsConfirm }
      }
      return { ...e }
    })
    this.setState({ data: itemList })
  }

  onSelectProfileFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ profilePic: reader.result, isLogoUpdate: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onSelectBannerFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ coverPic: reader.result, isbannerUpdate: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };



  handelModal(e) {
    let hideActionClass = this.state.hideActionClass
    if (hideActionClass === "") {
      hideActionClass = "hideAction"
    } else {
      hideActionClass = ""
    }
    this.setState({ hideActionClass });
  }




  selectcategory = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption.value);
    let userFields = this.state.userFields;
    let key = "StoreCategory";
    let value = selectedOption.value;
    userFields[key] = value;
    this.setState({ userFields });
  };


  handleOnPlazaSelect = selectedPlaza => {
    if (selectedPlaza) {
      this.setState({ selectedPlaza });
      console.log(`Option selected:`, selectedPlaza);
    }
    else {
      this.setState({ selectedPlaza: null })
    }
  };

  // selectmonopt = monopt => {
  //   this.setState({ monopt });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Monday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = monopt.value;
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.monopt.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.moncot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chkmon;
  //   a[kkk] = vvv;
  //   this.setState({ monobject: a });
  // };

  // selectmoncot = moncot => {
  //   this.setState({ moncot });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Monday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.monopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.monopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = moncot.value;
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chkmon;
  //   a[kkk] = vvv;

  //   this.setState({ monobject: a });
  // };

  // ChangeChkmon(e) {
  //   this.setState({ chkmon: !this.state.chkmon });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Monday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.monopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.monopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.monopt.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.moncot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = !this.state.chkmon;
  //   a[kkk] = vvv;

  //   this.setState({ monobject: a });
  // }

  // selecttueopt = tueopt => {
  //   this.setState({ tueopt });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Tuesday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = tueopt.value;
  //   a[k] = v;

  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.tuecot.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.tuecot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chktue;
  //   a[kkk] = vvv;

  //   this.setState({ tueobject: a });
  // };

  // selecttuecot = tuecot => {
  //   this.setState({ tuecot });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Tuesday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.tueopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.tueopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = tuecot.value;
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chktue;
  //   a[kkk] = vvv;

  //   this.setState({ tueobject: a });
  // };

  // ChangeChktue(e) {
  //   this.setState({ chktue: !this.state.chktue });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Tuesday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.tueopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.tueopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.tuecot.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.tuecot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = !this.state.chktue;
  //   a[kkk] = vvv;
  //   this.setState({ tueobject: a });
  // }

  // selectwedopt = wedopt => {
  //   this.setState({ wedopt });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Wednesday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = wedopt.value;
  //   a[k] = v;

  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.wedcot.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.wedcot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chkwed;
  //   a[kkk] = vvv;

  //   this.setState({ wedobject: a });
  // };

  // selectwedcot = wedcot => {
  //   this.setState({ wedcot });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Wednesday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.wedopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.wedopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = wedcot.value;
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chkwed;
  //   a[kkk] = vvv;

  //   this.setState({ wedobject: a });
  // };

  // ChangeChkwed(e) {
  //   this.setState({ chkwed: !this.state.chkwed });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Wednesday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.wedopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.wedopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.wedcot.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.wedcot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = !this.state.chkwed;
  //   a[kkk] = vvv;

  //   this.setState({ wedobject: a });
  // }

  // selectthuopt = thuopt => {
  //   this.setState({ thuopt });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Thursday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = thuopt.value;
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.thuopt.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.thucot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chkthu;
  //   a[kkk] = vvv;

  //   this.setState({ thuobject: a });
  // };

  // selectthucot = thucot => {
  //   this.setState({ thucot });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Thursday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.thuopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.thuopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = thucot.value;
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chkthu;
  //   a[kkk] = vvv;

  //   this.setState({ thuobject: a });
  // };

  // ChangeChkthu(e) {
  //   this.setState({ chkthu: !this.state.chkthu });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Thursday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.thuopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.thuopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.thucot.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.thucot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = !this.state.chkthu;
  //   a[kkk] = vvv;

  //   this.setState({ thuobject: a });
  // }

  // selectfriopt = friopt => {
  //   this.setState({ friopt });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Friday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = friopt.value;
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.friopt.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.fricot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chkfri;
  //   a[kkk] = vvv;

  //   this.setState({ friobject: a });
  // };

  // selectfricot = fricot => {
  //   this.setState({ fricot });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Friday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.friopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.friopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = fricot.value;
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chkfri;
  //   a[kkk] = vvv;

  //   this.setState({ friobject: a });
  // };

  // ChangeChkfri(e) {
  //   this.setState({ chkfri: !this.state.chkfri });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Friday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.friopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.friopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.fricot.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.fricot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = !this.state.chkfri;
  //   a[kkk] = vvv;

  //   this.setState({ friobject: a });
  // }

  // selectsatopt = satopt => {
  //   this.setState({ satopt });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Saturday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = satopt.value;
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.satopt.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.satcot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chksat;
  //   a[kkk] = vvv;

  //   this.setState({ satobject: a });
  // };

  // selectsatcot = satcot => {
  //   this.setState({ satcot });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Saturday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.satopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.satopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = satcot.value;
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chksat;
  //   a[kkk] = vvv;

  //   this.setState({ satobject: a });
  // };

  // ChangeChksat(e) {
  //   this.setState({ chksat: !this.state.chksat });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Saturday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.satopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.satopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.satcot.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.satcot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = !this.state.chksat;
  //   a[kkk] = vvv;

  //   this.setState({ satobject: a });
  // }

  // selectsunopt = sunopt => {
  //   this.setState({ sunopt });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Sunday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = sunopt.value;
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.sunopt.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.suncot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chksun;
  //   a[kkk] = vvv;

  //   this.setState({ sunobject: a });
  // };

  // selectsuncot = suncot => {
  //   this.setState({ suncot });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Sunday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.sunopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.sunopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = suncot.value;
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = this.state.chksun;
  //   a[kkk] = vvv;

  //   this.setState({ sunobject: a });
  // };

  // ChangeChksun(e) {
  //   this.setState({ chksun: !this.state.chksun });
  //   var a = {};
  //   let key = "Day";
  //   let value = "Sunday";
  //   a[key] = value;

  //   let k = "OpeningTime";
  //   let v = "";
  //   if (this.state.sunopt.value == undefined) {
  //     v = '';
  //   } else {
  //     v = this.state.sunopt.value;
  //   }
  //   a[k] = v;


  //   let kk = "CloseTime";
  //   let vv = "";
  //   if (this.state.suncot.value == undefined) {
  //     vv = '';
  //   } else {
  //     vv = this.state.suncot.value;
  //   }
  //   a[kk] = vv;

  //   let kkk = "IsOpen";
  //   let vvv = !this.state.chksun;
  //   a[kkk] = vvv;

  //   this.setState({ sunobject: a });
  // }

  handleUserchange(event) {
    let userFields = this.state.userFields;
    let key = event.target.name
    let value = event.target.value
    if ((key == "Shopify") || (key == "Wix") || (key == "Plazacart")) {
      if (key == "Shopify") {
        this.setState({
          isShopify: true,
          isWix: false,
          isPlazacart: false
        })
      }
      else if (key == "Wix") {
        this.setState({
          isShopify: false,
          isWix: true,
          isPlazacart: false
        })
      }
      else if (key == "Plazacart") {
        this.setState({
          isShopify: false,
          isWix: false,
          isPlazacart: true
        })
      }
      this.setState({ errors: {} })
    }
    else if (key == "PhoneNumber") {
      userFields[key] = formatPhoneNumber(value)
    }
    else {
      userFields[key] = value
      if (key == "StoreURL") {
        this.setState({ isShopifyStoreChange: true })
      }
    }
    this.setState({ userFields });
    this.setState({ isMapshow: false });
  }

  handleChangeChkno(e) {
    this.setState({ chkboxno: !this.state.chkboxno, chkboxyes: false });
    let userFields = this.state.userFields;
    let key = "IsPublicOpen";
    let value = false;
    userFields[key] = value
    this.setState({ userFields });
  }

  handleChangeChkyes(e) {
    this.setState({ chkboxyes: !this.state.chkboxyes, chkboxno: false });
    let userFields = this.state.userFields;
    let key = "IsPublicOpen";
    let value = true;
    userFields[key] = value
    this.setState({ userFields });
  }

  handleChangeChkphyno(e) {
    this.setState({ chkboxphyno: !this.state.chkboxphyno, chkboxphyyes: false });
    let userFields = this.state.userFields;
    let key = "PhysicalOrderReceipts";
    let value = false;
    userFields[key] = value
    this.setState({ userFields });
  }

  handleChangeChkphyyes(e) {
    this.setState({ chkboxphyyes: !this.state.chkboxphyyes, chkboxphyno: false });
    let userFields = this.state.userFields;
    let key = "PhysicalOrderReceipts";
    let value = true;
    userFields[key] = value
    this.setState({ userFields });
  }

  handleChangePickup(e) {
    if (this.state.DeliveryEnable) {
      this.setState({ PickupEnable: !this.state.PickupEnable })
    }
    else {
      this.setState({ PickupEnable: true })
    }
  }

  handleChangeDelivery(e) {
    if (this.state.PickupEnable) {
      this.setState({ DeliveryEnable: !this.state.DeliveryEnable })
    }
    else {
      this.setState({ DeliveryEnable: true })
    }
  }

  submitUserRegistrationForm(e) {
    var n = [];
    // n.push(this.state.monobject);
    // n.push(this.state.tueobject);
    // n.push(this.state.wedobject);
    // n.push(this.state.thuobject);
    // n.push(this.state.friobject);
    // n.push(this.state.satobject);
    // n.push(this.state.sunobject)
    let userFields = this.state.userFields;
    let key = "StoreOpeningTimes";
    let value = n;
    userFields[key] = value
    this.setState({ userFields });

    var a = {};
    let key1 = "Producttypeid";
    let value1 = "5ed0e0d4fe74e68138d24519";
    a[key1] = value1;

    var ptype = [];
    ptype.push(a);

    var p = {};
    p = this.state.userFields;
    p["DeviceId"] = Constants.DEVICE_ID;
    p["DeviceType"] = Constants.DEVICE_TYPE;
    if (this.state.isLogoUpdate) {
      p["LogoBase64"] = this.state.profilePic;
    }
    if (this.state.isbannerUpdate) {
      p["BannerBase64"] = this.state.coverPic
    }

    p["Storedescription"] = ""

    p["productDeliverytypes"] = this.state.data.filter(e => e.IsConfirm).map(e1 => {
      return { "Producttypeid": e1.Prouducttypeid }
    })

    p["Address"] = this.state.selectedStoreAddress ? this.state.selectedStoreAddress.label : ""

    p["isshopifyintegration"] = this.state.isShopify
    p["iswixintegration"] = this.state.isWix
    var storeName = String(this.state.userFields.StoreURL).replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(" ", "-").toLocaleLowerCase().trim()
    storeName = String(storeName).split(" ").join("-")
    p["shopifystorename"] = storeName
    p["IsPickupEnable"] = this.state.PickupEnable
    p["IsDeliveryEnable"] = this.state.DeliveryEnable

    let checkToken = String(userFields.AccToken).includes("******************************")
    if (!checkToken) {
      p["shopifyaccesstoken"] = this.state.userFields.AccToken
    }


    p["shopifyapiversion"] = this.state.userFields.APIVersion
    p["shopifystoreid"] = this.state.userFields.shopifystoreid
    p["plazaid"] = this.state.selectedPlaza ? this.state.selectedPlaza._id : "000000000000000000000000"
    console.log('======> post data ===', JSON.stringify(p))

    var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    console.log("authToken", authToken);
    let search = window.location.search;
    let params = new URLSearchParams(search);

    if (params.get('id') !== null && (params.get('id').length > 0)) {
      var storeid = params.get('id')
      p['Storeid'] = storeid;
      this.updateStore(p, authToken);
    }
    else {
      this.addstore(p, authToken);
    }
  }

  updateStore(postData, authToken) {
    this.setState({ isActive: true })
    PUTData(MY_STORE_API, postData, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          window.closeSearch();
          // window.location.href = RouteMerchant.StoreMain;

          let search = window.location.search;
          let params = new URLSearchParams(search);
          if (params.get('id') !== null && (params.get('id').length > 0)) {
            var storeid = params.get('id')
            if (this.state.storeStatus === StoreListConst.APPROVED || this.state.storeStatus === StoreListConst.PUBLISHED) {
              var plazaId = this.state.plazaId
              this.getStoredetailsObject(authToken, storeid, plazaId)
              return ""
            }
            window.location.href = RouteMerchant.StoreMain;
          }
          window.location.href = RouteMerchant.StoreMain;
        }
        else {
          alert(Messages.ERR_SomethingWentWrong)
        }
        this.setState({ isActive: false })
      });
  }
  getStoredetailsObject(authToken, storeid, plazaId) {
    this.setState({ isActive: true })
    console.log("authToken", authToken + "000" + storeid);
    var URL = MY_STORE_API + "/" + storeid + "?" + PLAZAID + plazaId
    GetData(URL, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          console.log("responseData ", responseData);
          if (responseData) {
            if (responseData.storedetail) {
              var storeObj = {
                "ObjectId": responseData.storedetail._id,
                "ApplicationGuid": responseData.storedetail.ApplicationId,
                "ApplicationName": responseData.storedetail.BusinessName,
                "AppLogo": responseData.storedetail.LogoPath,
                "AppBannerImage": "",
                "AppAddress": responseData.storedetail.StreetAddress,
                "Status": responseData.storedetail.Status,
                "isshopifyintegration": responseData.storedetail.isshopifyintegration,
                "iswixintegration": responseData.storedetail.iswixintegration,
                "shopifystorename": responseData.storedetail.shopifystorename,
                "shopifyaccesstoken": responseData.storedetail.shopifyaccesstoken,
                "shopifyapiversion": responseData.storedetail.shopifyapiversion,
                "shopifystoreid": responseData.storedetail.shopifystoreid,
                "isshopifypostorderenable": responseData.storedetail.isshopifypostorderenable,
                "plazaid": responseData.plazaid,
                "plazaname": responseData.plazaname
              }
              if (responseData.storedetail.Homebanner) {
                storeObj.AppBannerImage = responseData.storedetail.Homebanner[0] ? responseData.storedetail.Homebanner[0].HomeBannerImagePath : ""
              }
              StoreObjectInStorage(Constants.STORE_OBJ, storeObj);
            }
          }
          window.location.href = RouteMerchant.StoreMain;
        }
        this.setState({ isActive: false })
      })
  }

  addstore(postData, authToken) {
    this.setState({ isActive: true })
    PostData(MY_STORE_API, postData, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          window.closeSearch();
          window.location.href = RouteMerchant.StoreMain;
        }
        else {
          alert(Messages.ERR_SomethingWentWrong)
        }
        this.setState({ isActive: false })
      });
  }

  renderMap() {
    if (this.state.isMapshow) {
      return (
        <PlazacartMapComponent
          isMarkerShown
          lat={this.state.lat}
          lng={this.state.lng}
          key="map" />
      );
    } else {
      return (
        <div>
        </div>
      );
    }
  }

  render() {
    var avatarPic = avatar;
    var coverImg = cover;
    if (this.state.profilePic !== "") {
      avatarPic = this.state.profilePic;
    }
    if (this.state.coverPic !== "") {
      coverImg = this.state.coverPic;
    }
    const { showPlazaForm, selectedOption, monopt, moncot, tueopt, tuecot, wedopt, wedcot, thuopt,
      thucot, friopt, fricot, satopt, satcot, sunopt, suncot, storecatlist } = this.state;
    const options = storecatlist.map(v => ({
      label: v.MainCategoryName,
      value: v.MainCategoryName
    }));
    const ot = [
      { value: '5:00 AM', label: '5:00 AM' },
      { value: '6:00 AM', label: '6:00 AM' },
      { value: '7:00 AM', label: '7:00 AM' },
      { value: '8:00 AM', label: '8:00 AM' },
      { value: '9:00 AM', label: '9:00 AM' },
      { value: '10:00 AM', label: '10:00 AM' },
      { value: '11:00 AM', label: '11:00 AM' },
    ]
    const ct = [
      { value: '5:00 PM', label: '5:00 PM' },
      { value: '6:00 PM', label: '6:00 PM' },
      { value: '7:00 PM', label: '7:00 PM' },
      { value: '8:00 PM', label: '8:00 PM' },
      { value: '9:00 PM', label: '9:00 PM' },
      { value: '10:00 PM', label: '10:00 PM' },
      { value: '11:00 PM', label: '11:00 PM' },
      { value: '12:00 AM', label: '12:00 AM' },
    ]

    const CountryCode = this.state.countryInfo ? [this.state.countryInfo.CountryCode] : []

    return (
      <section className="section">
        <LoadingOverlay
          active={this.state.isActive}
          text={Messages.Loading}
          spinner
          styles={{
            overlay: (base) => ({
              ...base,
              position: "fixed"
            })
          }}
          className="lodingspinner"
        >
          <Header setHeaderTitle={Constvariable.Store} />
          <div className="registed_tab_part" style={{ position: "relative", padding: "20px 0px 0px 0px" }}>
            <div className="container setfontcolor">
              <p style={{ display: "block" }}><h4 className="setfontcolor">General Brewery Information</h4></p>
              <div className="">
                <div className="registed_form_tab" style={{ minHeight: "700px" }}>
                  <input id="step0" className="hiddenCheckBox" type="checkbox" />
                  <input id="step1" className="hiddenCheckBox" type="checkbox" />
                  <input id="step2" className="hiddenCheckBox" type="checkbox" />
                  <input id="step3" className="hiddenCheckBox" type="checkbox" />
                  <input id="step4" className="hiddenCheckBox" type="checkbox" />
                  <input id="step5" className="hiddenCheckBox" type="checkbox" />
                  <input id='step6' className="hiddenCheckBox" type='checkbox' />

                  <div id="part0">
                    <div className="reg_form_main offset-sm-3 col-sm-6" style={{ pointerEvents: this.state.storeId ? "none" : "auto" }}>
                      <div className="col-sm-12 setHoverDiv" for="Email">
                        {StoreConstFrom.RegisterStoreWith} &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.RegisterStore} />
                      </div>
                      <div className="col-sm-12 storebtn">
                        <input
                          className="storePlatform"
                          type="radio"
                          name="Plazacart"
                          value={this.state.userFields.Plazacart}
                          checked={this.state.isPlazacart}
                          onChange={this.handleUserchange} /> {StoreConstFrom.Plazacart}
                      </div>
                      <div className="col-sm-12 storebtn">
                        <input
                          className="storePlatform"
                          type="radio"
                          name="Shopify"
                          value={this.state.userFields.Shopify}
                          checked={this.state.isShopify}
                          onChange={this.handleUserchange} /> {StoreConstFrom.Shopify}
                      </div>
                      <div className="col-sm-12 storebtn">
                        <input
                          className="storePlatform"
                          type="radio"
                          name="Wix"
                          value={this.state.userFields.Wix}
                          checked={this.state.isWix}
                          onChange={this.handleUserchange} /> {StoreConstFrom.Wix}
                      </div>

                      {/* <div className="col-sm-12 errorMsg" style={{ marginTop: '5px' }}>
                        {this.state.errors.StoreCategory}
                      </div> */}
                    </div>

                    {(this.state.isShopify || this.state.isWix) && <div>
                      <div className="reg_form_main offset-sm-3 col-sm-6">
                        <div className="col-sm-12 setHoverDiv" for="Name">
                          {StoreConstFrom.StoreURL} &nbsp;
                          <OnHoverIconInfo
                            message={StoreFrom.ShopifyStoreName} />
                        </div>
                        <input
                          type="text"
                          placeholder={Constvariable.StoreURL}
                          name="StoreURL"
                          value={this.state.userFields.StoreURL}
                          onChange={this.handleUserchange} />
                        <div className="col-sm-12 errorMsg">
                          {this.state.errors.StoreURL}
                        </div>
                      </div>

                      <div className="reg_form_main offset-sm-3 col-sm-6">
                        <div className="col-sm-12 setHoverDiv" for="Name">
                          {StoreConstFrom.AccessToken}
                          &nbsp;
                          <OnHoverIconInfo
                            message={StoreFrom.AccessToken} />
                        </div>
                        <input
                          type="text"
                          placeholder={Constvariable.AccessToken}
                          name="AccToken"
                          value={this.state.userFields.AccToken}
                          onChange={this.handleUserchange} />
                        <div className="col-sm-12 errorMsg">
                          {this.state.errors.AccToken}
                        </div>
                      </div>

                      {/* <div className="reg_form_main offset-sm-3 col-sm-6">
                        <p className="col-sm-12" for="Name">
                          {Constvariable.APIVersion}
                          &nbsp;
                          <div className="tooltip"><i className="far fa-question-circle"></i>
                            <span className="tooltiptext">YYYY&minus;MM</span>
                          </div>
                        </p>
                        <input
                          type="text"
                          placeholder={Constvariable.APIVersion}
                          name="APIVersion"
                          value={this.state.userFields.APIVersion}
                          onChange={this.handleUserchange} />
                        <div className="col-sm-12 errorMsg">
                          {this.state.errors.APIVersion}
                        </div>
                      </div> */}
                    </div>
                    }

                    {!this.state.storeId && <div className="reg_form_main col-sm-6 offset-sm-3">
                      <div className="col-sm-12 setHoverDiv" for="Name">
                        {StoreConstFrom.Address} &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.Address} />
                      </div>
                      <GooglePlacesAutocomplete
                        apiKey={GOOGLE_PLACE_KEY}
                        selectProps={{
                          value: this.state.selectedAddress,
                          onChange: (setAddress) => this.handelAddress(setAddress),
                          menuPortalTarget: document.body,
                          isClearable: true,
                          placeholder: Constvariable.AP_Address,
                          styles: colourStyles
                        }}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: CountryCode
                          }
                        }}
                      />
                      <div className="errorMsg">
                        {this.state.errors.PlazaAddress}
                      </div>
                    </div>}

                    {!this.state.storeId && <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="Email">
                        {StoreConstFrom.PostalCode}  ({this.state.countryInfo ? this.state.countryInfo.CountryName : ""}) <OnHoverIconInfo
                          message={StoreFrom.PostalCode} />

                      </div>
                      <input
                        type="text"
                        onChange={this.handleUserchange}
                        name="PostalCode"
                        value={this.state.userFields.PostalCode}
                        placeholder={Constvariable.Postalcode}

                      />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.PostalCode}
                      </div>
                    </div>}

                    <div className="bottam_sing">
                      <label
                        for="step1"
                        id="continue-step1"
                        style={{ background: Color.THEME_COLOR }}
                        onClick={this.handleSubmitforpart0}
                        className="btn btn-done">{StoreConstFrom.Next}</label>
                    </div>
                  </div>


                  <div id="part1">

                    {!this.state.storeId && <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="Email">
                        {StoreConstFrom.SelectPlaza}  <OnHoverIconInfo
                          message={StoreFrom.SelectPlaza} />
                      </div>
                      <Select
                        value={this.state.selectedPlaza}
                        isClearable={true}
                        menuPortalTarget={document.querySelector('body')}
                        onChange={this.handleOnPlazaSelect}
                        options={this.state.plazaList}
                        styles={colourStyles}
                        placeholder={Constvariable.S_Plaza}
                        getOptionLabel={(option) => option.CompanyName}
                        getOptionValue={(option) => option._id}
                      />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.TenantEmail}
                      </div>
                    </div>}

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="Name">
                        {StoreConstFrom.StoreName}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.StoreName} />
                      </div>
                      <input
                        type="text"
                        placeholder={Constvariable.StoreName}
                        name="StoreName"
                        value={this.state.userFields.StoreName}
                        disabled={(this.state.isShopify || this.state.isWix)}
                        onChange={this.handleUserchange} />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.StoreName}
                      </div>
                    </div>

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="Email">
                        {StoreConstFrom.Storecategory}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.Storecategory} />
                      </div>
                      <Select
                        value={selectedOption}
                        onChange={this.selectcategory}
                        options={options}
                        styles={colourStyles}
                        menuPortalTarget={document.body}
                        placeholder={Constvariable.StoreCategory} />
                      <div className="col-sm-12 errorMsg" style={{ marginTop: '5px' }}>
                        {this.state.errors.StoreCategory}
                      </div>
                    </div>
                    <br />
                    <div className="reg_form_main offset-sm-3 col-sm-6" style={{ marginTop: 'number, string' }}>
                      <div className="col-sm-12 setHoverDiv" for="open">
                        {StoreConstFrom.StoreOpen}
                        {/* &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.StoreOpen} /> */}
                      </div>
                      <div className="col-sm-12">
                        <label style={{ margin: '10px', color: "black" }}>{StoreConstFrom.Yes}</label>
                        <input style={{ margin: '10px' }} type='checkbox' checked={this.state.chkboxyes} onChange={this.handleChangeChkyes} />
                        <label style={{ margin: '10px', color: "black" }} >{StoreConstFrom.No}</label>
                        <input style={{ margin: '10px' }} type='checkbox' checked={this.state.chkboxno} onChange={this.handleChangeChkno} />
                      </div>
                    </div>
                    <div className="bottam_sing">
                      <label
                        for="step1"
                        id="back-step0"
                        style={{ background: Color.THEME_COLOR }}
                        className="btn btn-skip">
                        {StoreConstFrom.PREV}
                      </label>
                      <label
                        for="step1"
                        id="continue-step1"
                        style={{ background: Color.THEME_COLOR }}
                        onClick={this.handleSubmitforpart1}
                        className="btn btn-done">{StoreConstFrom.Next}</label>
                    </div>
                  </div>

                  <div id="part2">
                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="PhoneNumber">
                        {StoreConstFrom.PhoneNumber}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.PhoneNumber} />
                      </div>
                      <input
                        type="text"
                        placeholder={Constvariable.S_PhoneNumber}
                        maxLength="14"
                        name="PhoneNumber"
                        value={this.state.userFields.PhoneNumber}
                        onChange={this.handleUserchange} />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.PhoneNumber}
                      </div>
                    </div>

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="Website">
                        {StoreConstFrom.Website}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.Website} />
                      </div>
                      <input
                        type="text"
                        placeholder={Constvariable.WebsiteUrl}
                        name="Website"
                        value={this.state.userFields.Website}
                        onChange={this.handleUserchange} />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.Website}
                      </div>
                    </div>
                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="FacebookUser">
                        {StoreConstFrom.FacebookUser}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.FacebookUser} />
                      </div>
                      <input
                        type="text"
                        placeholder={Constvariable.FacebookUser}
                        name="FacebookUser"
                        value={this.state.userFields.FacebookUser}
                        onChange={this.handleUserchange} />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.FacebookUser}
                      </div>
                    </div>
                    {/* <div className="reg_form_main offset-sm-3 col-sm-6">
                      <p className="col-sm-12" for="Storedescription">
                        Tell us about your store
                      </p>
                      <input
                        type="text"
                        placeholder="Ex. Super store is organic store"
                        name="Storedescription"
                        value={this.state.userFields.Storedescription}
                        onChange={this.handleUserchange} />
                      <div className="errorMsg">
                        {this.state.errors.Storedescription}
                      </div>
                    </div> */}
                    <div className="bottam_sing">
                      <label
                        for="step2"
                        id="back-step1"
                        style={{ background: Color.THEME_COLOR }}
                        className="btn btn-skip">
                        {StoreConstFrom.PREV}
                      </label>
                      <label
                        for="step3"
                        id="continue-step3"
                        style={{ background: Color.THEME_COLOR }}
                        onClick={this.handleSubmitforpart2}
                        className="btn btn-done">
                        {StoreConstFrom.Next}
                      </label>
                    </div>
                  </div>

                  <div id="part3">
                    <div className="reg_form_main col-sm-6 offset-sm-3">
                      <div className="col-sm-12 setHoverDiv" for="Name">
                        {StoreConstFrom.StoreAddress} &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.StoreAddress} />
                      </div>
                      <GooglePlacesAutocomplete
                        apiKey={GOOGLE_PLACE_KEY}
                        selectProps={{
                          value: this.state.selectedStoreAddress,
                          onChange: (setAddress) => this.handelStoreAddress(setAddress),
                          menuPortalTarget: document.body,
                          isClearable: true,
                          placeholder: Constvariable.S_StoreAddress,
                          styles: colourStyles
                        }}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: CountryCode
                          }
                        }}
                      />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.Address}
                      </div>
                    </div>

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="City">
                        {StoreConstFrom.City}  {/* (Required) */}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.City} />
                      </div>
                      <input
                        type="text"
                        placeholder={Constvariable.S_City}
                        name="City"
                        value={this.state.userFields.City}
                        onChange={this.handleUserchange} />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.City}
                      </div>
                    </div>

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="City">
                        {StoreConstFrom.StateName}  {/* (Required) */}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.StateName} />
                      </div>
                      <input
                        type="text"
                        placeholder={Constvariable.S_State}
                        name="state"
                        value={this.state.userFields.state}
                        onChange={this.handleUserchange} />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.state}
                      </div>
                    </div>

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="City">
                        {StoreConstFrom.CountryName}  {/* (Required) */}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.CountryName} />
                      </div>
                      <input
                        type="text"
                        placeholder={Constvariable.S_Country}
                        name="country"
                        value={this.state.userFields.country}
                        // onChange={this.handleUserchange}
                        disabled />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.country}
                      </div>
                    </div>

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="City">
                        {StoreConstFrom.PostalCode}  {/* (Required) */}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.PostalCode} />
                      </div>
                      <input
                        type="text"
                        placeholder={Constvariable.Postalcode}
                        name="postalcode"
                        value={this.state.userFields.postalcode}
                        onChange={this.handleUserchange} />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.postalcode}
                      </div>
                    </div>

                    {/* <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12 setHoverDiv" for="Address">
                        {StoreConstFrom.Address}
                        &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.Address} />
                      </div>
                      <input
                        type="text"
                        placeholder={Constvariable.S_Address}
                        name="Address"
                        value={this.state.userFields.Address}
                        onChange={this.handleUserchange} />
                      <div className="col-sm-12 errorMsg">
                        {this.state.errors.Address}
                      </div>
                    </div> */}

                    {/* <div className="reg_form_main offset-sm-3 col-sm-6">
                      <label style={{ margin: '10px', color: "black" }}>Opening Times (Required)</label>
                      <table style={{ width: '100%', margin: 'number, string' }}>
                        <tr>
                          <th style={{ color: "black" }}>Day</th>
                          <th style={{ color: "black" }}>Opening Time</th>
                          <th style={{ color: "black" }}>Closing Time</th>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ marginTop: '10px' }}>
                              <input style={{ margin: '2px' }} type='checkbox' checked={this.state.chkmon} onChange={this.ChangeChkmon} />
                              <label style={{ margin: '2px', color: "black" }}>Mon</label>
                            </div>
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={monopt}
                              onChange={this.selectmonopt}
                              styles={colourStyles}
                              options={ot}
                              placeholder="Open time" />
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={moncot}
                              onChange={this.selectmoncot}
                              styles={colourStyles}
                              options={ct}
                              placeholder="Close time" />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div style={{ marginTop: '10px' }}>
                              <input style={{ margin: '2px' }} type='checkbox' checked={this.state.chktue}
                                onChange={this.ChangeChktue} />
                              <label style={{ margin: '2px', color: "black" }}>Tue</label>
                            </div>
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={tueopt}
                              onChange={this.selecttueopt}
                              styles={colourStyles}
                              options={ot}
                              placeholder="Open time" />
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={tuecot}
                              onChange={this.selecttuecot}
                              styles={colourStyles}
                              options={ct}
                              placeholder="Close time" />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div style={{ marginTop: '10px' }}>
                              <input style={{ margin: '2px' }} type='checkbox'
                                checked={this.state.chkwed} onChange={this.ChangeChkwed} />
                              <label style={{ margin: '2px', color: "black" }}>Wed</label>
                            </div>
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={wedopt}
                              onChange={this.selectwedopt}
                              styles={colourStyles}
                              options={ot}
                              placeholder="Open time" />
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={wedcot}
                              onChange={this.selectwedcot}
                              styles={colourStyles}
                              options={ct}
                              placeholder="Close time" />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div style={{ marginTop: '10px' }}>
                              <input style={{ margin: '2px' }} type='checkbox' checked={this.state.chkthu}
                                onChange={this.ChangeChkthu} />
                              <label style={{ margin: '2px', color: "black" }}>Thu</label>
                            </div>
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={thuopt}
                              onChange={this.selectthuopt}
                              styles={colourStyles}
                              options={ot}
                              placeholder="Open time" />
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={thucot}
                              onChange={this.selectthucot}
                              styles={colourStyles}
                              options={ct}
                              placeholder="Close time" />
                          </td>
                        </tr>


                        <tr>
                          <td>
                            <div style={{ marginTop: '10px' }}>
                              <input style={{ margin: '2px' }} type='checkbox'
                                checked={this.state.chkfri}
                                onChange={this.ChangeChkfri} />
                              <label style={{ margin: '2px', color: "black" }}>Fri</label>
                            </div>
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={friopt}
                              onChange={this.selectfriopt}
                              styles={colourStyles}
                              options={ot}
                              placeholder="Open time" />
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={fricot}
                              onChange={this.selectfricot}
                              styles={colourStyles}
                              options={ct}
                              placeholder="Close time" />
                          </td>
                        </tr>


                        <tr>
                          <td>
                            <div style={{ marginTop: '10px' }}>
                              <input style={{ margin: '2px' }} type='checkbox'
                                checked={this.state.chksat}
                                onChange={this.ChangeChksat} />
                              <label style={{ margin: '2px', color: "black" }}>Sat</label>
                            </div>
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={satopt}
                              onChange={this.selectsatopt}
                              styles={colourStyles}
                              options={ot}
                              placeholder="Open time" />
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={satcot}
                              onChange={this.selectsatcot}
                              styles={colourStyles}
                              options={ct}
                              placeholder="Close time" />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div style={{ marginTop: '10px' }}>
                              <input style={{ margin: '2px' }} type='checkbox'
                                checked={this.state.chksun}
                                onChange={this.ChangeChksun} />
                              <label style={{ margin: '2px', color: "black" }}>Sun</label>
                            </div>
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={sunopt}
                              onChange={this.selectsunopt}
                              styles={colourStyles}
                              options={ot}
                              placeholder="Open time" />
                          </td>
                          <td>
                            <Select  menuPortalTarget={document.body} 
                              value={suncot}
                              onChange={this.selectsuncot}
                              styles={colourStyles}
                              options={ct}
                              placeholder="Close time" />
                          </td>
                        </tr>
                      </table>
                    </div> */}

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12">
                        <input style={{ marginRight: '10px' }} type='checkbox' checked={this.state.PickupEnable} onChange={this.handleChangePickup} />
                        <label className="setHoverDiv">
                          {StoreConstFrom.PickupServiceAvailable}
                        </label>
                      </div>
                    </div>

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <div className="col-sm-12">
                        <input style={{ marginRight: '10px' }} type='checkbox' checked={this.state.DeliveryEnable} onChange={this.handleChangeDelivery} />
                        <label className="setHoverDiv">
                          {StoreConstFrom.DeliveryServicesAvailable}
                        </label>
                      </div>
                    </div>

                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <label className="col-sm-12" style={{ color: "black" }}>
                        {StoreConstFrom.PhysicalReceipts}
                        {/* &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.PhysicalReceipts} /> */}
                      </label>
                      <div className="col-sm-12">
                        <input style={{ marginRight: '10px' }} type='checkbox' checked={this.state.chkboxphyyes} onChange={this.handleChangeChkphyyes} />
                        <label style={{ marginRight: '10px', color: "black" }}>{StoreConstFrom.Yes}</label>
                        <input style={{ marginRight: '10px' }} type='checkbox' checked={this.state.chkboxphyno} onChange={this.handleChangeChkphyno} />
                        <label style={{ marginRight: '10px', color: "black" }}>{StoreConstFrom.No}</label>
                      </div>
                    </div>



                    <div className="bottam_sing">
                      <label for="step3" id="back-step2" className="btn btn-skip" style={{ background: Color.THEME_COLOR }}>
                        {StoreConstFrom.PREV}
                      </label>
                      <label
                        for="step4"
                        id="continue-step4"
                        style={{ background: Color.THEME_COLOR }}
                        onClick={this.handleSubmitforpart3}
                        className="btn btn-done">
                        {StoreConstFrom.Next}
                      </label>
                    </div>
                  </div>

                  <div id="part4">
                    <div style={{ marginBottom: '10px' }}>
                      <div className="aep-avatar reg_form_main offset-sm-3 col-sm-6">
                        <h6>{StoreConstFrom.StoreLogo}
                          &nbsp;
                          <OnHoverIconInfo
                            message={StoreFrom.StoreLogo} /></h6>
                        <center>
                          <div className="aepa-left banner-updated">
                            <img src={this.state.profilePic} />
                            <div className="errorMsg">
                              {this.state.errors.logo}
                            </div>
                          </div>
                        </center>
                        <div className="aepa-right">
                          <center>
                            <div className="btn-upload" >
                              <label className="btn" for="updateProfile" style={{ padding: "4px 0px 0px 0px ", background: Color.THEME_COLOR }}>
                                {StoreConstFrom.Upload}
                              </label>
                              <input
                                type="file"
                                id="updateProfile"
                                style={{ opacity: "0" }}
                                data-id="profile"
                                className="hideAction"
                                onClick={this.handelModal}
                                onChange={this.onSelectProfileFile} />
                            </div>
                          </center>
                        </div>
                      </div>
                      <div className="aep-avatar reg_form_main offset-sm-3 col-sm-6" style={{ marginTop: '10px' }}>
                        <h6>{StoreConstFrom.StoreBanner}
                          &nbsp;
                          <OnHoverIconInfo
                            message={StoreFrom.StoreBanner} />
                        </h6>
                        <center>  <div className="aepa-left banner-updated">
                          <img src={this.state.coverPic} style={{ minHeight: "150px" }} />
                          <div className="errorMsg">
                            {this.state.errors.banner}
                          </div>
                        </div>
                        </center>
                        <div className="aepa-right">
                          <center>
                            <div className="btn-upload">
                              <label className="btn" for="updateCover" style={{ padding: "4px 0px 0px 0px ", background: Color.THEME_COLOR }}>
                                {StoreConstFrom.Upload}
                              </label>
                              <input
                                type="file"
                                id="updateCover"
                                style={{ opacity: "0" }}
                                data-id="cover"
                                className="hideAction"
                                onClick={this.handelModal}
                                onChange={this.onSelectBannerFile} />
                            </div>
                          </center>
                        </div>
                      </div>
                    </div>
                    <div className="bottam_sing">
                      <label for="step4" id="back-step3" className="btn btn-skip" style={{ background: Color.THEME_COLOR }}>
                        {StoreConstFrom.PREV}
                      </label>
                      <label
                        for="step5"
                        id="continue-step5"
                        style={{ background: Color.THEME_COLOR }}
                        onClick={this.handleSubmitforpart4}
                        className="btn btn-done">
                        {StoreConstFrom.Next}
                      </label>
                    </div>
                  </div>

                  <div id="part5">
                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      {this.renderMap()}
                      <div className="bottam_sing">
                        <label for='step5' id="back-step4" className="btn btn-skip"
                          style={{ background: Color.THEME_COLOR }}>  {StoreConstFrom.PREV}</label>
                        <label for='step6' id="continue-step6" className="btn btn-done"
                          style={{ background: Color.THEME_COLOR }}>{StoreConstFrom.Next}</label>
                      </div>
                    </div>
                  </div>

                  <div id="part6">
                    <div className="reg_form_main offset-sm-3 col-sm-6">
                      <p >
                        {StoreConstFrom.boxesInfo}
                        {/* &nbsp;
                        <OnHoverIconInfo
                          message={StoreFrom.boxesInfo} /> */}
                      </p>
                      {/* <ProductTypeList data={this.state.data} /> */}
                      {/* new changes */}
                      {this.state.data
                        .map(function (data, index) {
                          return (
                            <div key={index}>
                              <table style={{ width: '100%' }} >
                                <tr>
                                  <th id='th-header'>
                                    {/* <input type='checkbox' onClick={this.storenavigate} /> */}
                                    <Checkbox
                                      // color={Color.THEME_COLOR}
                                      size={2}
                                      tickSize={2}
                                      checked={data.IsConfirm}
                                      delay={0}
                                      backAnimationDuration={0}
                                      tickAnimationDuration={0}
                                      onChange={() => this.handleCheck(data)}></Checkbox>
                                  </th>
                                  <th >{data.Prouducttype}</th>
                                  <th></th>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td >{data.Description}</td>
                                  <td></td>
                                </tr>
                              </table>
                            </div>
                          )
                        }, this)}
                    </div>
                    <div className="bottam_sing">
                      <label for='step6' id="back-step6" className="btn btn-skip" style={{ background: Color.THEME_COLOR }}>{StoreConstFrom.PREV}</label>
                      <button className="btn btn-done" onClick={this.openSearch} style={{ background: Color.THEME_COLOR }}> {StoreConstFrom.SUBMIT}</button>
                    </div>
                    <div id="search-box" className="search-box">
                      <span
                        className="closebtn"
                        onClick={this.closeSearch}
                        title="Close Overlay">
                        {/* × */}
                      </span>
                      <div className="search-box-content">
                        <h4>{StoreConstFrom.Storeapplication}</h4>
                        <p style={{ color: "black" }}>{StoreConstFrom.EvaluateStore}</p>
                        <div>
                          <div>
                            <center>
                              <tr>
                                <td>
                                  <div className="registered-with" style={{ marginRight: '10px' }}>
                                    <span
                                      onClick={this.closeSearch}
                                      style={{ background: Color.THEME_COLOR }}
                                      className="btn btn-reg-email">
                                      {StoreConstFrom.Cancel}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="registered-with">
                                    <span
                                      onClick={this.submitUserRegistrationForm}
                                      style={{ background: Color.THEME_COLOR }}
                                      className="btn btn-reg-email">
                                      {StoreConstFrom.OK}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </section>
    );
  }
}

export default StoreWithapi;