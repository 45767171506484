import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import { RetriveDataFromStorage, validateFloat, RetriveObjectFromStorage } from "../../Config/Utils";
import { POST_TOPPING, PLAZAID } from '../../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants,{DEFAULTID} from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
import SideMenu from "../../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import RouteMerchant from '../../Config/RouteMerchant.js';
import Constvariable from '../../Config/constvariable';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { ToppingsForm } from '../../Config/HoverMessage'
import { ToppingsConstForm } from "../../Config/ConstForm"

class AddToppings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            errors: {},
            storeId: '',
            ItemId: '',
            plazaId: "",
            toppingCategoryId: '',
            toppingId: ''

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handelOnBlur = this.handelOnBlur.bind(this)
    }

    componentDidMount() {
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        var storeItemObj = RetriveObjectFromStorage(Constants.STROE_ITEM_OBJ);
        var toppingCategoryObj = RetriveObjectFromStorage(Constants.TOPPING_CATEGORY_OBJ);
        if (storeObj && storeItemObj && toppingCategoryObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            var itemId = storeItemObj._id;
            this.setState({ ItemId: itemId });
            var toppingCategoryId = toppingCategoryObj._id;
            this.setState({ toppingCategoryId: toppingCategoryId })
            var toppingobj = RetriveObjectFromStorage(Constants.TOPPING_OBJ);
            if (toppingobj) {
                this.setState({ toppingId: toppingobj._id })
                this.autoPopulated(toppingobj)
            }
        } else {
            this.props.history.goBack()
        }
    }

    autoPopulated(toppingobj) {
        let userFields = this.state.userFields;
        userFields['toppingItem'] = toppingobj.ToppingItem;
        userFields['toppingPrice'] = toppingobj.Price;
        this.setState({ userFields })
    }


    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    handelOnBlur(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (Number(value)) {
            userFields[key] = Number(value).toFixed(2)
        }
        this.setState({ userFields })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["toppingItem"]) {
            formIsValid = false;
            errors["toppingItem"] = Messages.v_toppingItem;
        }

        if (!fields["toppingPrice"]) {
            formIsValid = false;
            errors["toppingPrice"] = Messages.v_toppingPrice;
        }
        else if (fields["toppingPrice"]) {
            if (!validateFloat(fields["toppingPrice"])) {
                formIsValid = false;
                errors["toppingPrice"] = Messages.v_toppingPriceNumber;
            }

        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['ToppingItem'] = this.state.userFields.toppingItem;
            postData['ItemId'] = this.state.ItemId;
            postData['ToppingCategoryId'] = this.state.toppingCategoryId;
            postData['Price'] = this.state.userFields.toppingPrice;

            console.log("==> postdata==", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            var storeId = this.state.storeId
            var plazaId = this.state.plazaId
            if (this.state.toppingId != '' && this.state.toppingId) {
                postData['ToppingItemId'] = this.state.toppingId;
                this.putTopping(authToken, postData, storeId, plazaId)
            }
            else {
                this.postTopping(authToken, postData, storeId, plazaId)
            }

        }
    }

    postTopping(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = POST_TOPPING.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }

    putTopping(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = POST_TOPPING.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }


    render() {
        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        return (
            <section className="section">
                {/* <div id="loader" className={this.state.loaderClass}></div> */}
                <Header setHeaderTitle={this.state.toppingId ? ToppingsConstForm.UpdateTopping : ToppingsConstForm.AddTopping}></Header>
                {/* <div className="registed_tab_part">
                    <div className="container">

                        <div className="same_cantant">
                            <div className="registed_form_tab">
                                <div id="part1">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">


                                                <div className="reg_form_main" style={{ marginTop: "30px" }}>
                                                    <input
                                                        //  value={selectedOption}
                                                        onChange={this.handleChange}
                                                        //  options={options}
                                                        placeholder="Enter Item Name"
                                                        style={{ width: "450px" }}
                                                    />

                                                </div>
                                                <br></br>

                                                <div className="reg_form_main" style={{ marginTop: "30px" }}>
                                                    <input
                                                        //  value={selectedOption}
                                                        onChange={this.handleChange}
                                                        //  options={options}
                                                        placeholder="Enter Item Toppings"
                                                        style={{ width: "450px" }}
                                                    />

                                                </div>


                                                <div className="reg_form_main" style={{ marginTop: "30px" }}>
                                                    <input
                                                        //  value={selectedOption}
                                                        onChange={this.handleChange}
                                                        //  options={options}
                                                        placeholder="Enter Toppings Price
                                                    "
                                                        style={{ width: "450px" }}
                                                    />

                                                </div>


                                                <div className="bottam_sing">
                                                    <label
                                                        for="step2"
                                                        id="continue-step2"
                                                        onClick={this.handleAdd}
                                                        className="btn btn-done"
                                                    > Add Toppings</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             */}
                <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{ToppingsConstForm.ToppingName}
                            &nbsp;
                            <OnHoverIconInfo
                                message={ToppingsForm.ToppingName} />
                        </label>
                        <input
                            type="text"
                            name="toppingItem"
                            className='add-item-box'
                            placeholder={Constvariable.ToppingItem}
                            value={this.state.userFields.toppingItem}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.toppingItem}
                        </div>
                    </div>
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{ToppingsConstForm.ToppingPrice} {currencySymbol}
                            &nbsp;
                            <OnHoverIconInfo
                                message={ToppingsForm.ToppingPrice} /></label>
                        <input
                            type="text"
                            name="toppingPrice"
                            className='add-item-box'
                            placeholder={Constvariable.ToppingPrice}
                            value={this.state.userFields.toppingPrice}
                            onChange={this.handleChange}
                            onBlur={this.handelOnBlur}
                        />
                        <div className="errorMsg">
                            {this.state.errors.toppingPrice}
                        </div>
                    </div>

                </div>
                <div className="bottam_sing">
                    <label
                        style={{ background: Color.THEME_COLOR }}
                        for="step5"
                        id="continue-step5"
                        onClick={this.handleAdd}
                        className="btn btn-done"
                    > {this.state.toppingId ? ToppingsConstForm.btn_UpdateTopping : ToppingsConstForm.btn_AddTopping}</label>
                </div>
            </section>

        );
    }
}
export default AddToppings;