import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants from '../../Config/Constants'
import { RetriveDataFromStorage, StoreDataInStorage, StoreObjectInStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API, MERCHANTPLAZALIST_API } from '../../Config/Config.json'
// import SideMenu from '../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import Constvariable, { PlatFrom } from '../../Config/constvariable';
import Color from '../../Config/Color';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import RouteMerchant from '../../Config/RouteMerchant.js';


class PlazaMain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderClass: true,
			data: [],
			isActive: false
		}
		this.regularRegister = this.regularRegister.bind(this)
	}

	componentDidMount() {
		var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
		this.getMyStoreAPI(authToken);
		console.log(authToken);
	}

	getMyStoreAPI(authToken) {
		this.setState({ isActive: true })
		var URL = MERCHANTPLAZALIST_API
		GetData(URL, authToken)
			.then(result => {
				var responseJson = result;
				if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
					var responseData = responseJson["Data"];
					console.log("responseData ", responseData);
					if (responseData) {
						this.setState({ data: responseData });
					}
					else {
						alert(Messages.NO_DATA);
					}
				}
				else {
					alert(Messages.ERR_SomethingWentWrong);
				}
				this.setState({ isActive: false })
			});
	}

	regularRegister(e) {
		localStorage.removeItem("FacebookData");
		// window.location.href = "/Store";
		this.props.history.push({
			pathname: RouteMerchant.AddPlaza
		})
	}



	render() {

		const columns = [
			{
				// maxWidth: "80px",
				cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px" }} src={row.Imageurl} className="iconDetails" />
			},
			{
				// name: "Item Name",
				name: "Company Name",
				cell: row => <h6>{row.CompanyName}</h6>
			},
			{
				// name: "Item Name",
				name: "Tenant Email",
				cell: row => <span style={{ marginLeft: '10px', paddingTop: "5px", paddingBottom: "5px" }}>{row.TenantEmail}</span>
			}
		]
		return (
			<div>
				<LoadingOverlay
					active={this.state.isActive}
					text={Messages.Loading}
					spinner
					styles={{
						overlay: (base) => ({
							...base,
							position: "fixed"
						})
					}}
					className="lodingspinner"
				>
					<Header setHeaderTitle={Constvariable.ManagePlaza} />
					<section className="section" style={{ backgroundColor: Color.WHITE }}>
						<div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
							<div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
								<span
									style={{ background: Color.THEME_COLOR }}
									onClick={this.regularRegister}
									className="btn btn-reg-email">
									Submit a Plaza
								</span>
							</div>
							<div className="reg_form_main">

								<DataTableExtensions
									data={this.state.data}
									columns={columns}
									export={false}
									print={false}
									filter={this.state.data.length > 0 ? true : false}
									filterHidden={true}
									filterPlaceholder={Messages.SearchforPlaza}
									filterStyle={{ width: "100px" }}
								>
									<DataTable
										noHeader={true}
									// noTableHead={true}
									//pagination

									/>
								</DataTableExtensions>

							</div>
						</div>
					</section>

					{/* <nav className="nav-bar2 navbar-expand-lg">
						<div className="" id="navbarDefault">
							<div className="container" >

								<StoreList data={this.state.data} />
								<center>
									<div className="registered-with">
										<span
											style={{ background: Color.THEME_COLOR }}
											onClick={this.regularRegister}
											className="btn btn-reg-email">
											Submit a Store
										</span>
									</div>
								</center>
							</div>
						</div>
					</nav> */}
				</LoadingOverlay>
			</div>
		);
	}
}

export default PlazaMain;