import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreDataInStorage, validateFloat, parseTime, PlusOneHour } from "../../Config/Utils";
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
import SideMenu from "../../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import Constvariable from '../../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { GetData } from "../../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { MY_STORE_API, GET_DELIVERY_TIME, PLAZAID } from '../../Config/Config.json';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { AddDeliverytimesForm } from '../../Config/HoverMessage'
import { AddDeliverytimesConstForm } from "../../Config/ConstForm"

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

const meridian = [{ value: 'AM', label: 'AM' }, { value: 'PM', label: 'PM' }]
let clock = []

for (var i = 1; i <= 12; i++) {
    if (i <= 9) {
        clock.push({ value: "0" + String(i) + ":00", label: "0" + String(i) + ":00" })
    }
    else[
        clock.push({ value: String(i) + ":00", label: String(i) + ":00" })
    ]
}

class AddDeliverytimes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            errors: {},

            isActive: false,
            storeId: "",
            plazaId: "",
            deliveryTimeID: '',

            clockList: clock,
            selectedFrom: null,
            selectedFromM: null,
            selectedTo: null,
            selectedToM: null

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handelOnBlur = this.handelOnBlur.bind(this)

    }

    componentDidMount() {
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = storeObj.plazaid
            this.setState({ storeId: storeId, plazaId: plazaId })
            var deliveryTimeObj = RetriveObjectFromStorage(Constants.DELIVERYTIME_OBJ);
            if (deliveryTimeObj) {
                var deliveryTimeID = deliveryTimeObj._id;
                this.setState({ deliveryTimeID: deliveryTimeID })
                this.autoPopulated(deliveryTimeObj)
            }
        } else {
            this.props.history.goBack()
        }
    }

    autoPopulated(deliveryTimeObj) {
        let userFields = this.state.userFields;
        var time = deliveryTimeObj.DeliveryTime.split(' ')
        this.setState({
            selectedFrom: this.state.clockList.filter(e => e.value == time[0])[0],
            selectedFromM: meridian.filter(e1 => e1.value === time[1])[0],
            selectedTo: this.state.clockList.filter(e => e.value == time[3])[0],
            selectedToM: meridian.filter(e1 => e1.value === time[4])[0]
        })
        userFields['deliveryCost'] = deliveryTimeObj.DeliveryCost;
        this.setState({ userFields })
    }


    // /////////////// end detail methods ////////////////////////////


    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }
    handelOnBlur(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (Number(value)) {
            userFields[key] = Number(value).toFixed(2)
        }
        this.setState({ userFields })
    }

    handleFrom = selectedFrom => {
        var time = PlusOneHour(selectedFrom.value)
        let selectedTo = this.state.clockList.filter(e => e.value == time)[0]
        this.setState({ selectedFrom, selectedTo: selectedTo ? selectedTo : null })

        let selectedFromM = this.state.selectedFromM
        if (selectedFromM) {
            if (selectedFrom.value == "11:00") {
                if (selectedFromM.value == "AM") {
                    let meridianTo = meridian.filter(e => e.value == "PM")[0]
                    this.setState({ selectedToM: meridianTo ? meridianTo : null })
                }
                else {
                    let meridianTo = meridian.filter(e => e.value == "AM")[0]
                    this.setState({ selectedToM: meridianTo ? meridianTo : null })
                }
            }
            else {
                if (selectedFromM.value == "AM") {
                    let meridianTo = meridian.filter(e => e.value == "AM")[0]
                    this.setState({ selectedToM: meridianTo ? meridianTo : null })
                }
                else {
                    let meridianTo = meridian.filter(e => e.value == "PM")[0]
                    this.setState({ selectedToM: meridianTo ? meridianTo : null })
                }
            }
        }
    }
    handleFromM = selectedFromM => {
        this.setState({ selectedFromM, selectedToM: selectedFromM })

        let selectedFrom = this.state.selectedFrom
        if (selectedFrom && selectedFrom.value == "11:00") {
            if (selectedFromM.value == "AM") {
                let meridianTo = meridian.filter(e => e.value == "PM")[0]
                this.setState({ selectedToM: meridianTo ? meridianTo : null })
            }
            else {
                let meridianTo = meridian.filter(e => e.value == "AM")[0]
                this.setState({ selectedToM: meridianTo ? meridianTo : null })
            }
        }
    }

    handleTo = selectedTo => {
        this.setState({ selectedTo })
    }
    handleToM = selectedToM => {
        this.setState({ selectedToM })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if ((!this.state.selectedFrom) || (!this.state.selectedFromM) || (!this.state.selectedTo) || (!this.state.selectedToM)) {
            formIsValid = false;
            errors["deliveryTime"] = Messages.v_deliveryTime;
        }

        // if (!fields["deliveryCost"]) {
        //     formIsValid = false;
        //     errors["deliveryCost"] = Messages.v_deliveryCost;
        // }
        // else if (fields["deliveryCost"]) {
        //     if (!validateFloat(fields["deliveryCost"])) {
        //         formIsValid = false;
        //         errors["deliveryCost"] = Messages.v_deliveryCostNumber;
        //     }
        // }

        // let selectedFrom = this.state.selectedFrom, selectedFromM = this.state.selectedFromM
        // let selectedTo = this.state.selectedTo, selectedToM = this.state.selectedToM

        // if (selectedFrom && selectedFromM && selectedTo && selectedToM) {
        //     let fromTime = selectedFrom.value + " " + selectedFromM.value
        //     let toTime = selectedTo.value + " " + selectedToM.value
        //     let fromTimeDigit = parseTime(fromTime), toTimeDigit = parseTime(toTime)
        //     if (selectedTo.value == "12:00" && selectedToM.value == "AM") {
        //         toTimeDigit = 24
        //     }
        //     if ((toTimeDigit - fromTimeDigit) != 1) {
        //         formIsValid = false;
        //         // errors["deliveryTime"] = ""
        //     }
        // }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['DeliveryTime'] = this.state.selectedFrom.value + " " + this.state.selectedFromM.value + " - " + this.state.selectedTo.value + " " + this.state.selectedToM.value
            postData['DeliveryCost'] = this.state.userFields.deliveryCost ? this.state.userFields.deliveryCost : 1;

            console.log("==> postdata==", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            var storeId = this.state.storeId
            var plazaId = this.state.plazaId
            if (this.state.deliveryTimeID != '' && this.state.deliveryTimeID) {
                postData['_id'] = this.state.deliveryTimeID;
                this.putToppingCategory(authToken, postData, storeId, plazaId)
            }
            else {
                this.postToppingCategory(authToken, postData, storeId, plazaId)
            }

        }
    }


    postToppingCategory(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = MY_STORE_API + "/" + storeId + GET_DELIVERY_TIME + "?" + PLAZAID + plazaId;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }


    putToppingCategory(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = MY_STORE_API + "/" + storeId + GET_DELIVERY_TIME + "?" + PLAZAID + plazaId;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }

    render() {
        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner" >
                    <Header setHeaderTitle={this.state.deliveryTimeID ? AddDeliverytimesConstForm.UpdateDeliverytimes : AddDeliverytimesConstForm.AddDeliverytimes}></Header>
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">Delivery Time:</label>
                            <input
                                type="text"
                                name="deliveryTime"
                                className='add-item-box'
                                placeholder={Constvariable.l_Deliverytimes}
                                value={this.state.userFields.deliveryTime}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.deliveryTime}
                            </div>
                        </div> */}

                        <div className="reg_form_main col-sm-12 replace-row filedSet" >
                            <div className="col-lg-6 col-md-6 col-sm-12 replace-row filedSet" >
                                <div className="col-sm-12">
                                    <label className="label-color">{AddDeliverytimesConstForm.DeliveryTimeFrom}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={AddDeliverytimesForm.DeliveryTimeFrom} />
                                    </label>
                                </div>
                                <div className="col-sm-6">
                                    <Select menuPortalTarget={document.body}
                                        value={this.state.selectedFrom}
                                        onChange={this.handleFrom}
                                        options={this.state.clockList}
                                        styles={colourStyles}
                                        className="select-input"
                                    />
                                </div>
                                <div className="col-sm-6">
                                    {/* <label className="label-color"></label> */}
                                    <Select menuPortalTarget={document.body}
                                        value={this.state.selectedFromM}
                                        onChange={this.handleFromM}
                                        options={meridian}
                                        styles={colourStyles}
                                        //  placeholder={Constvariable.ProductType}
                                        className="select-input"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 replace-row filedSet" >
                                <div className="col-sm-12">
                                    <label className="label-color">{AddDeliverytimesConstForm.DeliveryTimeTo}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={AddDeliverytimesForm.DeliveryTimeTo} />
                                    </label>
                                </div>
                                <div className="col-sm-6">
                                    <Select menuPortalTarget={document.body}
                                        value={this.state.selectedTo}
                                        onChange={this.handleTo}
                                        options={this.state.clockList}
                                        styles={colourStyles}
                                        isDisabled={true}
                                        //  placeholder={Constvariable.ProductType}
                                        className="select-input"
                                    />
                                </div>
                                <div className="col-sm-6">
                                    {/* <label className="label-color"></label> */}
                                    <Select menuPortalTarget={document.body}
                                        value={this.state.selectedToM}
                                        onChange={this.handleToM}
                                        options={meridian}
                                        styles={colourStyles}
                                        isDisabled={true}
                                        //  placeholder={Constvariable.ProductType}
                                        className="select-input"
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 replace-row filedSet">
                                <div className="col-sm-12">
                                    <div className="errorMsg">
                                        {this.state.errors.deliveryTime}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="reg_form_main col-sm-10 offset-sm-1 replace-row filedSet">
                            <div className="col-sm-12 replace-row filedSet" >
                                <div className="col-sm-12">
                                    <label className="label-color">{AddDeliverytimesConstForm.DeliveryCost} {currencySymbol}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={AddDeliverytimesForm.DeliveryCost} />
                                    </label>
                                    <input
                                        type="text"
                                        name="deliveryCost"
                                        className='add-item-box'
                                        placeholder={Constvariable.l_DeliveryCost}
                                        value={this.state.userFields.deliveryCost}
                                        onChange={this.handleChange}
                                        onBlur={this.handelOnBlur}
                                    />
                                    <div className="errorMsg">
                                        {this.state.errors.deliveryCost}
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                    <div className="bottam_sing">
                        <label
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done"
                        > {this.state.deliveryTimeID ? AddDeliverytimesConstForm.btn_UpdateDeliverytimes : AddDeliverytimesConstForm.btn_AddDeliverytimes}</label>
                    </div>
                </LoadingOverlay>
            </section>

        );
    }
}
export default AddDeliverytimes;