import Moment, { min } from 'moment';
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import Color from '../Config/Color';
import { ManageTaxesConstForm } from '../Config/ConstForm';
import Constants from '../Config/Constants';
import Messages from "../Config/Messages";
import { RetriveObjectFromStorage, validateFloat, RetriveDataFromStorage, ValidTaxFormat } from "../Config/Utils";
import Constvariable, { DatetimeFormate, TaxStatusObj, TaxStatusDataSet } from '../Config/constvariable';
import Header from '../Header/Header.js';
import { MY_STORE_API, Tax_API, CATEGORY_ITEMTAXES_API } from '../Config/Config.json'
import { PostData } from '../../services/PostData';
import { GetData } from "../../services/GetData";
import {
    setTaxLabelValue, masterCategorychangeValueSelect, masterCategorychangeValueRemove,
    categorychangeValueSelect, categorychangeValueRemove,
    productchangeValue, postCategorymodal
} from './taxUntils'
import { customConfirmAlert } from "../Alerts/Alertinline"
import { data1 } from './data.json'
import './Taxes.css'

// const T = [
//     {
//         "_id": "648851683044e3fa2f93826b",
//         "TaxType": "HST ",
//         "Taxname": "Sales Tax ",
//         "TaxRate": 13.0,
//         "Status": "ACTIVE",
//         "isdefault": true,
//         "ValidFromDate": "2023-05-31T18:30:00Z",
//         "ValidToDate": "2024-09-30T18:30:00Z",
//     },
//     {
//         "_id": "648b1dd2d13640f4eac9095d",
//         "TaxType": "GST",
//         "Taxname": "Sales Tax update",
//         "TaxRate": 10.0,
//         "Status": "ACTIVE",
//         "isdefault": false,
//         "ValidFromDate": "2023-05-31T18:30:00Z",
//         "ValidToDate": "2024-09-30T18:30:00Z"
//     }
// ]

const taxSelectStyle = {
    // control: styles => ({ ...styles, minWidth: "180px", maxWidth:"180px" }),
    // menuPortal: styles => ({ ...styles, zIndex: "1051" })
    input: styles => ({ ...styles, padding: "2px 5px" }),
    singleValue: styles => ({ ...styles, padding: "2px 5px", }),
    placeholder: styles => ({ ...styles, padding: "2px 5px" }),
    multiValueRemove: (styles, state) => {
        if (state.data && state.data.isdefault) {
            return { ...styles, display: "none" }
        }
        return styles
    }
}

class TaxList extends Component {
    constructor(props) {
        super(props);
        this.state = {

            isActive: false,
            plazaId: "",
            storeId: "",
            StateTaxList: [],
            mStateTaxList: [],
            deletedTaxList: [],
            errors: {},
            userFields: {
                Taxname: "",
                TaxType: "",
                TaxRate: "",
                ValidFromDate: "",
                ValidToDate: "",
                isdefault: false
            },

            taxStatus: TaxStatusDataSet,
            selectedStatus: null,

            isChange: false,
            isChangeproduct: false,

            selectedHeader: 0,

            productSet: [],
            mproductSet: [],

            EditIndex: -1,
            userEdit: {
                Taxname: "",
                TaxRate: "",
                isdefault: false,
                _id: null
            }
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        console.log("=====>")

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = storeObj.plazaid;
            this.setState({ storeId: storeId, plazaId: plazaId });
            this.getTax(authToken, storeId)
        }
        else {
            this.props.history.goBack()
        }
    }

    ////////////////////////////// store level tax ///////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    getTax(authToken, storeId) {
        this.setState({ isActive: true, errors: {} });
        var URL = MY_STORE_API + "/" + storeId + "/" + Tax_API;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    responseData = responseData ? responseData : []
                    responseData = responseData.map(e => {
                        let statusObj = TaxStatusDataSet.filter(e1 => e1.value == e.Status)[0]
                        return {
                            ...e,
                            "StatusObj": statusObj ? statusObj : null,
                            "ValidFromDate": Moment.utc(e.ValidFromDate).format(DatetimeFormate.YYYYMMDDdash),
                            "ValidToDate": Moment.utc(e.ValidToDate).format(DatetimeFormate.YYYYMMDDdash)
                        }
                    })
                    this.setState({
                        StateTaxList: responseData,
                        mStateTaxList: JSON.parse(JSON.stringify(responseData))
                    })
                }
                else {
                    let errors = {};
                    errors["masterMsg"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
                this.getProductTaxes(authToken, storeId)


                // //  demo code //
                // let t = []
                // for (var i = 0; i < 1; i++) {
                //     t.push(...T)
                // }
                // let rs = t
                // /*  .map(e => {
                //      let statusObj = TaxStatusDataSet.filter(e1 => e1.value == e.Status)[0]
                //      return {
                //          ...e,
                //          "StatusObj": statusObj ? statusObj : null,
                //          "ValidFromDate": Moment.utc(e.ValidFromDate).format(DatetimeFormate.YYYYMMDDdash),
                //          "ValidToDate": Moment.utc(e.ValidToDate).format(DatetimeFormate.YYYYMMDDdash)
                //      }
                //  }) */
                // this.setState({
                //     StateTaxList: rs,
                //     mStateTaxList: JSON.parse(JSON.stringify(rs))
                // })
                // // this.getProductTaxes(authToken, storeId)
                // ///

            }).catch(error => {
                let errors = {};
                errors["masterMsg"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getProductTaxes(authToken, storeId) {
        this.setState({ isActive: true, errors: {} });
        var URL = MY_STORE_API + "/" + storeId + "/" + CATEGORY_ITEMTAXES_API;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    responseData = responseData ? responseData : []

                    const { StateTaxList } = this.state;
                    let productSet = setTaxLabelValue(responseData, StateTaxList)
                    this.setState({
                        productSet: productSet,
                        mproductSet: JSON.parse(JSON.stringify(productSet))
                    })

                }
                else {
                    let errors = {};
                    errors["masterMsg"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })

                // //  demo code //
                // const { StateTaxList } = this.state;
                // let productSet = setTaxLabelValue(data1, StateTaxList)
                // this.setState({
                //     productSet: productSet,
                //     mproductSet: JSON.parse(JSON.stringify(productSet))
                // })
                // ///

            }).catch(error => {
                let errors = {};
                errors["masterMsg"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });

            });
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        let checked = event.target.checked
        if (key == "isdefault") {
            userFields[key] = checked

            customConfirmAlert({
                title: ManageTaxesConstForm.modal3_Title,
                message: event.target.checked ? ManageTaxesConstForm.modal3_Msg : ManageTaxesConstForm.modal3_Msg1,
                buttons: [
                    {
                        label: ManageTaxesConstForm.modal1_btnOk,
                        onClick: () => {
                            return false
                        }
                    }
                ]
            });

        } else {
            userFields[key] = value
        }
        this.setState({ userFields, isChange: true });
    }

    handelStatus = s => {
        this.setState({ selectedStatus: s, isChange: true })
    }

    handelStatusList(s, index) {
        let { StateTaxList } = this.state;
        StateTaxList[index].StatusObj = s;
        StateTaxList[index].Status = s.value
        this.setState({ StateTaxList: StateTaxList, isChange: true })
    }

    handleDefultCheckbox(e, index) {
        let { StateTaxList } = this.state;
        StateTaxList[index].isdefault = e.target.checked
        customConfirmAlert({
            title: ManageTaxesConstForm.modal3_Title,
            message: e.target.checked ? ManageTaxesConstForm.modal3_Msg : ManageTaxesConstForm.modal3_Msg1,
            buttons: [
                {
                    label: ManageTaxesConstForm.modal1_btnOk,
                    onClick: () => {
                        return false
                    }
                }
            ]
        });

        this.setState({ StateTaxList: StateTaxList, isChange: true })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;
        if (!fields["Taxname"]) {
            formIsValid = false;
            errors["Taxname"] = Messages.v_TaxName;
        }

        // if (!fields["TaxType"]) {
        //     formIsValid = false;
        //     errors["TaxType"] = Messages.v_TaxType
        // }
        if (!fields["TaxRate"]) {
            formIsValid = false;
            errors["TaxRate"] = Messages.v_ApplicableTax
        }
        else if (fields["TaxRate"]) {
            if (!validateFloat(fields["TaxRate"])) {
                formIsValid = false;
                errors["TaxRate"] = Messages.v_ApplicableTaxNum
            }
        }
        // if (!fields["ValidFromDate"]) {
        //     formIsValid = false;
        //     errors["ValidFromDate"] = Messages.v_ApplicableFromDate
        // }
        // if (!fields["ValidToDate"]) {
        //     formIsValid = false;
        //     errors["ValidToDate"] = Messages.v_ApplicableToDate
        // }
        // if (!this.state.selectedStatus) {
        //     formIsValid = false;
        //     errors["selectedStatus"] = Messages.v_Status
        // }

        this.setState({ errors: errors });
        return formIsValid;
    }

    addTaxArr() {
        if (this.validateForm()) {
            var TaxObj = {}
            var userFields = this.state.userFields
            TaxObj["Taxname"] = userFields.Taxname
            TaxObj["TaxType"] = userFields.TaxType
            TaxObj["TaxRate"] = userFields.TaxRate
            TaxObj["ValidFromDate"] = userFields.ValidFromDate
            TaxObj["ValidToDate"] = userFields.ValidToDate
            // TaxObj["StatusObj"] = this.state.selectedStatus
            // TaxObj["Status"] = this.state.selectedStatus.value
            TaxObj["isdefault"] = userFields.isdefault

            var StateTaxList = this.state.StateTaxList
            StateTaxList.push(TaxObj)
            this.setState({
                StateTaxList: StateTaxList,
                userFields: {
                    "Taxname": "",
                    "TaxType": "",
                    "TaxRate": "",
                    "ValidFromDate": "",
                    "ValidToDate": "",
                    "isdefault": false
                },
                selectedStatus: null
            })
        }
    }

    removeTaxFromList(e, index) {
        var StateTaxList = this.state.StateTaxList

        if (e._id) {
            customConfirmAlert({
                title: ManageTaxesConstForm.modal1_Title,
                message: ManageTaxesConstForm.modal1_pdingMsg,
                buttons: [
                    {
                        label: ManageTaxesConstForm.modal1_btnConfirm,
                        onClick: () => {
                            let { deletedTaxList, StateTaxList } = this.state
                            deletedTaxList.push(e._id)
                            StateTaxList.splice(index, 1)
                            this.setState({
                                deletedTaxList: deletedTaxList, isChange: true,
                                StateTaxList: StateTaxList,
                                EditIndex: -1
                            })
                        }
                    },
                    {
                        label: ManageTaxesConstForm.btn_Cancel,
                        onClick: () => {
                            return false;
                        }
                    }
                ]
            });
        }
        else {
            StateTaxList.splice(index, 1)
            this.setState({ StateTaxList: StateTaxList, isChange: true })
        }

    }

    handleAdd = (e) => {
        var postData = {};
        e.preventDefault();
        const { storeId, StateTaxList, deletedTaxList } = this.state;

        if (storeId) {
            postData["_id"] = storeId;
            postData["taxes"] = StateTaxList.map(e => ({
                "_id": e._id ? e._id : null,
                "Status": TaxStatusObj.ACTIVE,
                "TaxRate": e.TaxRate,
                "TaxType": "",
                "Taxname": e.Taxname,
                "ValidFromDate": null,
                "ValidToDate": null,
                "isdefault": e.isdefault
            }))

            postData["deletedtaxes"] = deletedTaxList

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.postStoreTaxSetup(authToken, postData)
        }
    }

    postStoreTaxSetup(authToken, postData) {
        this.setState({ isActive: true })
        var URL = MY_STORE_API + "/" + Tax_API;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getTax(authToken, postData._id)
                    this.setState({ isChange: false })
                    this.resetField()
                }
                else {
                    let error = {}
                    error["masterMsg"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: error })
                }
                this.setState({ isActive: false })
            }).catch(e => {
                let error = {}
                error["masterMsg"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: error, isActive: false })
            })
    }

    resetField() {
        this.setState({
            userFields: {
                Taxname: "",
                TaxType: "",
                TaxRate: "",
                ValidFromDate: "",
                ValidToDate: "",
                isdefault: false
            },
            selectedStatus: null,
            errors: {},
            deletedTaxList: [],
            EditIndex: -1,
            userEdit: {
                Taxname: "",
                TaxRate: "",
                isdefault: false,
                _id: null
            }
        })
    }

    ////////////////// edit flow ///////////////

    handleEditIndex(obj, index) {
        let { userEdit } = this.state;
        let copyObj = JSON.parse(JSON.stringify(obj))
        userEdit["_id"] = copyObj._id;
        userEdit["Taxname"] = copyObj.Taxname;
        userEdit["TaxRate"] = String(copyObj.TaxRate);
        userEdit["isdefault"] = copyObj.isdefault;
        this.setState({ userEdit: userEdit, EditIndex: index })
    }

    handleChangeEdit = e => {
        let userEdit = this.state.userEdit;
        let key = e.target.name
        let value = e.target.value
        let checked = e.target.checked
        if (key == "isdefault") {
            userEdit[key] = checked

            customConfirmAlert({
                title: ManageTaxesConstForm.modal3_Title,
                message: e.target.checked ? ManageTaxesConstForm.modal3_Msg : ManageTaxesConstForm.modal3_Msg1,
                buttons: [
                    {
                        label: ManageTaxesConstForm.modal1_btnOk,
                        onClick: () => {
                            return false
                        }
                    }
                ]
            });

        } else {
            userEdit[key] = value
        }
        this.setState({ userEdit, isChange: true });
    }

    handleBlurEdit = e => {
        let key = e.target.name
        let value = e.target.value

        if (key == "TaxRate" && Number(value) > 100) {
            customConfirmAlert({
                title: ManageTaxesConstForm.modal3_Title,
                message: ManageTaxesConstForm.modal4Msg,
                buttons: [
                    {
                        label: ManageTaxesConstForm.modal1_btnOk,
                        onClick: () => {
                            return false
                        }
                    }
                ]
            });
        }
    }

    validateForm1() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userEdit;
        if (!fields["Taxname"]) {
            formIsValid = false;
            errors["Taxnameedit"] = Messages.v_TaxName;
        }

        if (!fields["TaxRate"]) {
            formIsValid = false;
            errors["TaxRateedit"] = Messages.v_ApplicableTax
        }
        else if (fields["TaxRate"]) {
            if (!validateFloat(fields["TaxRate"])) {
                formIsValid = false;
                errors["TaxRateedit"] = Messages.v_ApplicableTaxNum
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    addTaxArrEdit() {
        let { EditIndex, userEdit, StateTaxList } = this.state;
        var obj = {}
        if (this.validateForm1()) {
            obj["_id"] = userEdit._id ? userEdit._id : null,
                obj["Taxname"] = userEdit.Taxname;
            obj["TaxRate"] = userEdit.TaxRate;
            obj["isdefault"] = userEdit.isdefault;
            StateTaxList.splice(EditIndex, 1, obj)

            this.setState({ StateTaxList: StateTaxList }, () => {
                this.setState({
                    EditIndex: -1,
                    userEdit: {
                        Taxname: "",
                        TaxRate: "",
                        isdefault: false
                    },
                    errors: {}
                })
            })
        }
    }

    handleEditClose() {
        this.setState({
            EditIndex: -1,
            userEdit: {
                Taxname: "",
                TaxRate: "",
                isdefault: false
            },
            errors: {}
        })
    }

    /////////////////////////////

    ////////////////////////////// store level tax ///////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////


    headerChange(value) {
        this.setState({
            selectedHeader: value,
            errors: {},
            isChange: false,
            isChangeproduct: false
        })
        if (value == 1) {
            let { mproductSet } = this.state;
            let productSet = JSON.parse(JSON.stringify(mproductSet))
            this.setState({ productSet: productSet })
        }
        else {
            let { mStateTaxList } = this.state;
            let stateTaxList = JSON.parse(JSON.stringify(mStateTaxList))
            this.setState({
                StateTaxList: stateTaxList,
                deletedTaxList: []
            })
            this.resetField()
        }
    }

    handleparentMasterCategoryli(e, masterid, iconid) {
        this.masterCategoryToggle(masterid, iconid)
    }

    handleparentCategoryli(e, categoryid, iconid) {
        this.categoryToggle(categoryid, iconid)
    }

    masterCategoryToggle(masterid, iconid) {
        let masteriddom = document.getElementById(masterid);
        if (masteriddom) {
            masteriddom.classList.toggle("hide")
        }
        let icon = document.getElementById(iconid)
        if (icon) {
            icon.classList.toggle("fa-plus-square")
            icon.classList.toggle("fa-minus-square")
        }
    }

    categoryToggle(categoryid, iconid) {
        let categoryiddom = document.getElementById(categoryid);
        if (categoryiddom) {
            categoryiddom.classList.toggle("hide")
        }
        let icon = document.getElementById(iconid)
        if (icon) {
            icon.classList.toggle("fa-plus-square")
            icon.classList.toggle("fa-minus-square")
        }
    }

    handleMasterCat(value, obj, index) {
        console.log(obj)
        if (obj.action == "remove-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                let productSet = masterCategorychangeValueRemove(this.state, value, obj, index);
                this.setState({ productSet: productSet, isChangeproduct: true })
            }
        }
        else if (obj.action == "pop-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                let productSet = masterCategorychangeValueRemove(this.state, value, obj, index);
                this.setState({ productSet: productSet, isChangeproduct: true })
            }
        }
        else {
            value = value ? value : [];
            let productSet = masterCategorychangeValueSelect(this.state, value, obj, index);
            this.setState({ productSet: productSet, isChangeproduct: true })
        }
    }

    handleCategory(value, obj, mind, ind) {
        if (obj.action == "remove-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                let productSet = categorychangeValueRemove(this.state, value, obj, mind, ind)
                this.setState({ productSet: productSet, isChangeproduct: true })
            }
        }
        else if (obj.action == "pop-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                let productSet = categorychangeValueRemove(this.state, value, obj, mind, ind)
                this.setState({ productSet: productSet, isChangeproduct: true })
            }
        }
        else {
            value = value ? value : [];
            let productSet = categorychangeValueSelect(this.state, value, obj, mind, ind)
            this.setState({ productSet: productSet, isChangeproduct: true })
        }
    }

    handelproduct(value, obj, mind, cind, ind) {
        if (obj.action == "remove-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                let productSet = productchangeValue(this.state, value, mind, cind, ind)
                this.setState({ productSet: productSet, isChangeproduct: true })
            }
        }
        else if (obj.action == "pop-value") {
            if (!obj.removedValue.isdefault) {
                value = value ? value : [];
                let productSet = productchangeValue(this.state, value, mind, cind, ind)
                this.setState({ productSet: productSet, isChangeproduct: true })
            }
        }
        else {
            value = value ? value : [];
            let productSet = productchangeValue(this.state, value, mind, cind, ind)
            this.setState({ productSet: productSet, isChangeproduct: true })
        }
    }

    handleAdd1 = (e) => {
        var postData = {};
        e.preventDefault();
        const { storeId } = this.state;
        if (storeId) {
            postData["storeid"] = storeId;
            postData["categories"] = postCategorymodal(this.state)

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.postProductTaxSetup(authToken, postData)
        }
    }

    postProductTaxSetup(authToken, postData) {
        this.setState({ isActive: true })
        var URL = MY_STORE_API + "/" + CATEGORY_ITEMTAXES_API;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isChangeproduct: false })
                    this.getProductTaxes(authToken, postData["storeid"])
                }
                else {
                    let error = {}
                    error["masterMsg"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: error })
                }
                this.setState({ isActive: false })
            }).catch(e => {
                let error = {}
                error["masterMsg"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: error, isActive: false })
            })
    }

    render() {
        const { selectedHeader, productSet, StateTaxList, isChangeproduct, EditIndex } = this.state;

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner" >
                    <Header setHeaderTitle="Manage Taxes" />
                    <section className="section tax-section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            {/*  <div className="registered-with" style={{ float: "right" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                     onClick={this.handleAddProducts}
                                    className="btn btn-reg-email">
                                    Add Product
                                </span>
                            </div> */}

                            <div className="reg_form_main tax-box mb-5 tax-container">

                                <div className="row storeTime-header m-0 mb-2">
                                    <div className={"text-center col-6 time-Header get-more-genre " + (selectedHeader == 0 ? "selected-header" : "")}
                                        onClick={() => this.headerChange(0)}>{ManageTaxesConstForm.StoreLevelTax}</div>
                                    <div className={"text-center col-6 time-Header get-more-genre " + (selectedHeader == 1 ? "selected-header" : "")}
                                        onClick={() => this.headerChange(1)}>{ManageTaxesConstForm.ProductLevelTax}</div>
                                </div>

                                {selectedHeader == 0 ?
                                    <React.Fragment>
                                        <div style={{ overflowX: "auto" }}>
                                            <table>
                                                <tr>
                                                    <th>{ManageTaxesConstForm.th_TaxName}</th>
                                                    {/* <th>{ManageTaxesConstForm.th_TaxType}</th> */}
                                                    <th>{ManageTaxesConstForm.th_ApplicableTax}</th>
                                                    {/* <th>{ManageTaxesConstForm.th_ApplicableFromDate}</th> */}
                                                    {/* <th>{ManageTaxesConstForm.th_ApplicableToDate}</th> */}
                                                    <th>{ManageTaxesConstForm.ApplyDefaultToAll}</th>
                                                    {/* <th>{ManageTaxesConstForm.th_Status}</th> */}
                                                    <th></th>
                                                </tr>
                                                {this.state.StateTaxList.map((e, i) =>
                                                    <React.Fragment>
                                                        <tr index={i} >
                                                            <td>{e.Taxname}</td>
                                                            {/* <td>{e.TaxType}</td> */}
                                                            <td>{ValidTaxFormat(String(e.TaxRate)) ? Number(e.TaxRate).toFixed(2) : e.TaxRate}</td>
                                                            {/* <td>{Moment(e.ValidFromDate).format(DatetimeFormate.YYYYMMDDdash)}</td> */}
                                                            {/* <td>{Moment(e.ValidToDate).format(DatetimeFormate.YYYYMMDDdash)}</td> */}
                                                            <td><input class="makeatag" type='checkbox' checked={e.isdefault} onChange={(e) => this.handleDefultCheckbox(e, i)} /></td>
                                                            {/* <td>
                                                            <Select
                                                                // menuPortalTarget={document.body}
                                                                value={e.StatusObj}
                                                                menuPlacement={"top"}
                                                                onChange={(obj) => this.handelStatusList(obj, i)}
                                                                options={this.state.taxStatus}
                                                                styles={taxSelectStyle}
                                                                placeholder={Constvariable.Status}
                                                                className="select-input"
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.value}
                                                            />
                                                        </td> */}
                                                            <td>  <i class="fas fa-edit makeatag" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleEditIndex(e, i)}></i></td>
                                                            <td>
                                                                <i class="fa fa-trash makeatag" aria-hidden="false"
                                                                    style={{ color: Color.THEME_COLOR, fontSize: "20px" }}
                                                                    onClick={() => this.removeTaxFromList(e, i)}></i></td>
                                                        </tr>
                                                        {EditIndex == i && <tr className="high-light-tr">
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    placeholder={Constvariable.TaxName}
                                                                    name="Taxname"
                                                                    className="setinputwidth"
                                                                    value={this.state.userEdit.Taxname}
                                                                    onChange={this.handleChangeEdit}
                                                                />
                                                                <div className="errorMsg">
                                                                    {this.state.errors.Taxnameedit}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="setinputwidth"
                                                                    placeholder={Constvariable.ApplicableTax}
                                                                    name="TaxRate"
                                                                    maxLength="6"
                                                                    value={this.state.userEdit.TaxRate}
                                                                    onChange={this.handleChangeEdit}
                                                                    onBlur={this.handleBlurEdit}
                                                                />
                                                                <div className="errorMsg">
                                                                    {this.state.errors.TaxRateedit}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    // className="setinputwidth"
                                                                    class="makeatag"
                                                                    name="isdefault"
                                                                    checked={this.state.userEdit.isdefault}
                                                                    onChange={this.handleChangeEdit}
                                                                />
                                                                <div className="errorMsg">
                                                                    {this.state.errors.isdefaultedit}
                                                                </div>
                                                            </td>
                                                            <td> <i class="fas fa-times makeatag" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleEditClose()}></i></td>
                                                            <td>
                                                                <i class="fa fa-plus makeatag" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.addTaxArrEdit()}></i>
                                                            </td>
                                                        </tr>}
                                                    </React.Fragment>
                                                )}
                                                <tr>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            placeholder={Constvariable.TaxName}
                                                            name="Taxname"
                                                            className="setinputwidth"
                                                            value={this.state.userFields.Taxname}
                                                            onChange={this.handleChange}
                                                        />
                                                        <div className="errorMsg">
                                                            {this.state.errors.Taxname}
                                                        </div>
                                                    </td>
                                                    {/* <td>
                                                        <input
                                                            type="text"
                                                            placeholder={Constvariable.TaxType}
                                                            className="setinputwidth"
                                                            name="TaxType"
                                                            value={this.state.userFields.TaxType}
                                                            onChange={this.handleChange}
                                                        />
                                                        <div className="errorMsg">
                                                            {this.state.errors.TaxType}
                                                        </div>
                                                    </td> */}
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="setinputwidth"
                                                            placeholder={Constvariable.ApplicableTax}
                                                            name="TaxRate"
                                                            maxLength="6"
                                                            value={this.state.userFields.TaxRate}
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleBlurEdit}
                                                        />
                                                        <div className="errorMsg">
                                                            {this.state.errors.TaxRate}
                                                        </div>
                                                    </td>
                                                    {/* <td>
                                                        <input
                                                            type="date"
                                                            className="setinputwidth"

                                                            name="ValidFromDate"
                                                            value={this.state.userFields.ValidFromDate}
                                                            onChange={this.handleChange}
                                                        />
                                                        <div className="errorMsg">
                                                            {this.state.errors.ValidFromDate}
                                                        </div>
                                                    </td> */}
                                                    {/* <td>
                                                        <input
                                                            type="date"
                                                            className="setinputwidth"

                                                            name="ValidToDate"
                                                            value={this.state.userFields.ValidToDate}
                                                            onChange={this.handleChange}
                                                        />
                                                        <div className="errorMsg">
                                                            {this.state.errors.ValidToDate}
                                                        </div>
                                                    </td> */}
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            // className="setinputwidth"
                                                            class="makeatag"
                                                            name="isdefault"
                                                            checked={this.state.userFields.isdefault}
                                                            onChange={this.handleChange}
                                                        />
                                                        <div className="errorMsg">
                                                            {this.state.errors.isdefault}
                                                        </div>
                                                    </td>
                                                    {/* <td>
                                                        <Select
                                                            value={this.state.selectedStatus}
                                                            menuPlacement="top"
                                                            onChange={this.handelStatus}
                                                            isClearable={true}
                                                            options={this.state.taxStatus}
                                                            styles={taxSelectStyle}
                                                            placeholder={Constvariable.Status}
                                                            className="select-input"
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.value} />
                                                        <div className="errorMsg">
                                                            {this.state.errors.selectedStatus}
                                                        </div>
                                                    </td> */}
                                                    <td></td>
                                                    <td>
                                                        <i class="fa fa-plus makeatag" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.addTaxArr()}></i>
                                                    </td>
                                                </tr>
                                            </table>
                                            {/* <div className="errorMsg text-center">
                                        {this.state.errors.masterMsg}
                                    </div> */}
                                        </div>
                                        <div className="errorMsg text-center mt-4">
                                            {this.state.errors.masterMsg}
                                        </div>
                                        {this.state.isChange && <div className="bottam_sing">
                                            <label
                                                style={{ background: Color.THEME_COLOR }}
                                                for="step5"
                                                id="continue-step5"
                                                onClick={this.handleAdd}
                                                className="btn btn-done"
                                            > {ManageTaxesConstForm.btn_Submit}</label>
                                        </div>}
                                    </React.Fragment>

                                    :

                                    <React.Fragment>
                                        <div className="reg_form_main tax-box mb-5 tax-container" style={{ overflow: "auto" }}>
                                            <ul class="list-group" style={{ minWidth: "720px" }}>
                                                {productSet.length > 0 && <li class="list-group-item">
                                                    <div class="row ml-0 mr-0">
                                                        <div class="col-6"></div>
                                                        <div class="col-6 text-center"> Applicable Tax</div>
                                                    </div>
                                                </li>}
                                                {productSet.map((base, indx) => {

                                                    const categoryid = "category" + base.categoryname + indx, masterIcon = "masterIcon" + base.categoryname + indx

                                                    return <li class="list-group-item mt-2">
                                                        <div class="row ml-0 mr-0">
                                                            <div class="col-6">  <i class="fas fa-minus-square makeatag"
                                                                id={masterIcon}
                                                                onClick={(e) => this.handleparentMasterCategoryli(e, categoryid, masterIcon)}>
                                                            </i>&nbsp; {base.categoryname}</div>
                                                            <div class="col-6">
                                                                <div class="offset-lg-2 col-lg-8 offset-1 col-9">
                                                                    <Select
                                                                        value={base.taxes}
                                                                        // menuPlacement="top"
                                                                        menuPortalTarget={document.body}
                                                                        onChange={(value, obj) => this.handleMasterCat(value, obj, indx)}
                                                                        isClearable={false}
                                                                        options={StateTaxList}
                                                                        styles={taxSelectStyle}
                                                                        placeholder={Constvariable.SelectTaxes}
                                                                        className="select-input"
                                                                        // isOptionDisabled={(option) => option.isdefault}
                                                                        isMulti={true}
                                                                        getOptionLabel={(option) => option.Taxname + " - " + option.TaxRate + " %"}
                                                                        getOptionValue={(option) => option._id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* category List */}
                                                        {base.subcategories && <ul class="list-group show mt-2" key={indx} id={categoryid}>
                                                            {base.subcategories.map((catgry, indx1) => {

                                                                // let categoryFlag = this.isCategoryExpand(indx,indx1);
                                                                const productid = "product" + catgry.subcategoryname + indx1 + indx, catgryiconId = "catgryiconId" + catgry.subcategoryname + indx1 + indx

                                                                return <li class="list-group-item mt-2 padr-md-0 padl-md-0">

                                                                    <div class="row ml-0 mr-0">
                                                                        <div class="col-6">
                                                                            <i class="fas fa-minus-square makeatag"
                                                                                id={catgryiconId}
                                                                                onClick={(e) => this.handleparentCategoryli(e, productid, catgryiconId)}>
                                                                            </i>&nbsp; {catgry.subcategoryname}
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="offset-lg-3 col-lg-8 offset-2 col-9">
                                                                                <Select
                                                                                    value={catgry.taxes}
                                                                                    menuPlacement="top"
                                                                                    onChange={(value, obj) => this.handleCategory(value, obj, indx, indx1)}
                                                                                    isClearable={false}
                                                                                    menuPortalTarget={document.body}
                                                                                    options={StateTaxList}
                                                                                    styles={taxSelectStyle}
                                                                                    placeholder={Constvariable.SelectTaxes}
                                                                                    className="select-input"
                                                                                    isMulti={true}
                                                                                    getOptionLabel={(option) => option.Taxname + " - " + option.TaxRate + " %"}
                                                                                    getOptionValue={(option) => option._id} />
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    {/* product List */}
                                                                    {catgry.products && <ul class="list-group show mt-2" key={indx1} id={productid}>
                                                                        {catgry.products.map((prodctObj, indx3) => {
                                                                            return <li class="list-group-item padr-md-0 padl-md-0">
                                                                                <div class="row ml-0 mr-0">
                                                                                    <div class="col-6">{prodctObj.productname}
                                                                                    </div>
                                                                                    <div class="col-6">
                                                                                        <div class="offset-lg-4 col-lg-8 offset-3 col-9">
                                                                                            {prodctObj.IsProductTax ? ManageTaxesConstForm.TaxExempt : <Select
                                                                                                value={prodctObj.taxes}
                                                                                                menuPlacement="top"
                                                                                                onChange={(value, obj) => this.handelproduct(value, obj, indx, indx1, indx3)}
                                                                                                isClearable={false}
                                                                                                menuPortalTarget={document.body}
                                                                                                options={StateTaxList}
                                                                                                styles={taxSelectStyle}
                                                                                                placeholder={Constvariable.SelectTaxes}
                                                                                                className="select-input"
                                                                                                isMulti={true}
                                                                                                getOptionLabel={(option) => option.Taxname + " - " + option.TaxRate + " %"}
                                                                                                getOptionValue={(option) => option._id} />}
                                                                                        </div>
                                                                                    </div>
                                                                                </div></li>
                                                                        })}


                                                                    </ul>}
                                                                    {/* end product List */}

                                                                </li>
                                                            })}
                                                        </ul>}
                                                        {/* end category List */}

                                                    </li>
                                                })}
                                            </ul>


                                            {productSet.length == 0 && <div className="col-sm-12 mt-2 pl-1 pr-1">
                                                <div class="card text-center">
                                                    <div class="card-body">
                                                        <p class="card-text">{Messages.NO_RECORD}</p>
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>
                                        <div className="errorMsg text-center mt-4">
                                            {this.state.errors.masterMsg}
                                        </div>
                                        {this.state.isChangeproduct && <div className="bottam_sing">
                                            <label
                                                style={{ background: Color.THEME_COLOR }}
                                                for="step5"
                                                id="continue-step5"
                                                onClick={this.handleAdd1}
                                                className="btn btn-done"
                                            > {ManageTaxesConstForm.btn_Submit}</label>
                                        </div>}
                                    </React.Fragment>

                                }

                            </div>
                        </div>
                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default TaxList;