import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
// import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants from '../../Config/Constants'
import { RetriveDataFromStorage, StoreDataInStorage, StoreObjectInStorage, RemoveFromStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API_List } from '../../Config/Config.json'
// import SideMenu from '../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import Constvariable, { PlatFrom } from '../../Config/constvariable';
import Color from '../../Config/Color';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { StoreListConst } from "../../Config/ConstForm"
import Changeimg from '../../../images/Status/changerequest.png'
import makePayment from '../../../images/Status/makepayment.png'
import processing from '../../../images/Status/processing.png'
import publishapp from '../../../images/Status/publishapp.png'
import requestsent from '../../../images/Status/requestsent.png'
import { StoreList } from '../../Config/HoverMessage'

class StoreMain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderClass: true,
			data: [],
			isActive: false
		}
		this.regularRegister = this.regularRegister.bind(this)
	}

	componentDidMount() {
		var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
		this.getMyStoreAPI(authToken);
		console.log(authToken);
		// RemoveFromStorage(Constants.TEMPSTORE__OBJ)


	}

	getMyStoreAPI(authToken) {
		this.setState({ isActive: true })
		GetData(MY_STORE_API_List, authToken)
			.then(result => {
				var responseJson = result;
				if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
					var responseData = responseJson["Data"];
					console.log("responseData ", responseData);
					if (responseData && responseData.length > 0) {
						this.setState({ data: responseData });
					}
					else {
						alert(Messages.NO_DATA);
					}
				}
				else {
					alert(Messages.ERR_SomethingWentWrong);
				}
				this.setState({ isActive: false })
			});
	}

	regularRegister(e) {
		localStorage.removeItem("FacebookData");
		// window.location.href = "/Store";
		this.props.history.push({
			pathname: RouteMerchant.Store
		})
	}

	onClickViewDetails(data) {
		console.log("value", data);
		this.props.history.push({
			pathname: RouteMerchant.Storedetails,
			search: "?id=" + data.ObjectId + "&plazaId=" + data.plazaid
		});
		// StoreDataInStorage(Constants.PLAZAID, data.plazaid);
		// StoreDataInStorage(Constants.STOREID, data.ObjectId);
	}

	storenavigate(data) {
		console.log("value", data.ObjectId);
		// this.props.history.push({
		// 	pathname: RouteMerchant.Home,
		// 	data: data.ObjectId
		// });
		if (data.isshopifyintegration) {
			StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Shopify);
			this.props.history.push({
				pathname: RouteMerchant.MySubscription,
				data: data.ObjectId
			});
		}
		else if (data.iswixintegration) {
			StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Wix);
		}
		else {
			StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Plazacart)
			this.props.history.push({
				pathname: RouteMerchant.MySubscription,
				data: data.ObjectId
			});
		}
		RemoveFromStorage(Constants.ISTEMP)
		StoreObjectInStorage(Constants.STORE_OBJ, data);
		StoreDataInStorage(Constants.PLAZAID, data.plazaid);
		StoreDataInStorage(Constants.STOREID, data.ObjectId);
	}

	setPaymentFlow(data) {
		console.log("value", data.ObjectId);
		if (data.isshopifyintegration) {
			StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Shopify);
		}
		else if (data.iswixintegration) {
			StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Wix);
		}
		else {
			StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Plazacart)
		}
		this.props.history.push({
			pathname: RouteMerchant.MySubscription
		});
		StoreDataInStorage(Constants.ISTEMP, Constants.ISTEMP);
		StoreObjectInStorage(Constants.STORE_OBJ, data);
		StoreDataInStorage(Constants.PLAZAID, data.plazaid);
		StoreDataInStorage(Constants.STOREID, data.ObjectId);
	}

	render() {

		const columns = [
			{
				// maxWidth: "80px",
				cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px" }} src={row.AppLogo} className="iconDetails" />
			},
			{
				// name: "Item Name",
				name: "Plaza Name",
				cell: row => <h6 >{row.plazaname}</h6>
			},
			{
				// name: "Item Name",
				name: "Brewery Name",
				cell: row => (row.Status === StoreListConst.APPROVED || row.Status === StoreListConst.PUBLISHED) ? <div className="tooltip" style={{ zIndex: "unset" }}>
					<a className="setasatag" tabIndex="" onClick={() => this.storenavigate(row)} >
						<span style={{ color: Color.THEME_COLOR, fontSize: "18px" }}>{row.ApplicationName}</span>
					</a>
					<span className="tooltiptext settooltip settooltipbg">{StoreList.GoToDashboard}</span>
				</div> : <h6 >{row.ApplicationName}</h6>
			},
			{
				// name: "Item Name",
				name: "Address",
				cell: row => <span style={{ paddingTop: "5px", paddingBottom: "5px" }}>{row.AppAddress}</span>
			},

			{
				// name: "Item Name",
				// name: "Address",
				width: "fit-content",
				cell: row => {
					var domValue, hoverMsg
					if (row.Status === StoreListConst.PUBLISHED) {
						domValue = <img src={publishapp} onClick={() => this.setPaymentFlow(row)} />
						hoverMsg = StoreList.Publishapp
					}
					else if (row.Status === StoreListConst.APPROVED) {
						domValue = <img src={makePayment} onClick={() => this.setPaymentFlow(row)} />
						hoverMsg = StoreList.MakePayment
					}
					else if (row.Status === StoreListConst.INREVIEW) {
						domValue = <img src={processing} onClick={() => this.onClickViewDetails(row)} />
						hoverMsg = StoreList.Inreview
					}
					else if (row.Status === StoreListConst.ACTIVE) {
						domValue = <img src={processing} onClick={() => this.onClickViewDetails(row)} />
						hoverMsg = StoreList.Inreview
					}
					return <div className="tooltip" style={{ zIndex: "unset" }}>
						{domValue}
						<span className="tooltiptext settooltip settooltipbg">{hoverMsg}</span>
					</div>
				}
			},
			{
				// name: "Item Name",
				name: "Status",
				cell: row => <span style={{ paddingTop: "5px", paddingBottom: "5px" }}>{row.Status}</span>
			},
			// {
			// 	// name: "Item Price",
			// 	// right: true,
			// 	cell: row => {
			// 		return (row.Status === StoreListConst.APPROVED || row.Status === StoreListConst.PUBLISHED) ? (
			// 			<div>
			// 				<span
			// 					style={{ background: Color.THEME_COLOR }}
			// 					value={row.ObjectId}
			// 					onClick={() => this.storenavigate(row)}
			// 					className="btn btn-row">
			// 					Go to dashboard
			// 				</span>
			// 			</div>) : (
			// 			<div>
			// 				<span
			// 					style={{ background: Color.THEME_COLOR }}
			// 					value={row.ObjectId}
			// 					onClick={() => this.onClickViewDetails(row)}
			// 					className="btn btn-row">
			// 					&nbsp;&nbsp;&nbsp;
			// 					View details
			// 					&nbsp;&nbsp;&nbsp;
			// 				</span>
			// 			</div>
			// 		)
			// 	},
			// 	// maxWidth: "120px"
			// },
		]
		return (
			<div>
				<LoadingOverlay
					active={this.state.isActive}
					text={Messages.Loading}
					spinner
					styles={{
						overlay: (base) => ({
							...base,
							position: "fixed"
						})
					}}
					className="lodingspinner"
				>
					<Header setHeaderTitle={Constvariable.ManageStore} />
					<section className="section" style={{ backgroundColor: Color.WHITE }}>
						<div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
							<div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
								<span
									style={{ background: Color.THEME_COLOR }}
									onClick={this.regularRegister}
									className="btn btn-reg-email">
									Submit a Store
								</span>
							</div>
							<div className="reg_form_main">

								<DataTableExtensions
									data={this.state.data}
									columns={columns}
									export={false}
									print={false}
									filter={this.state.data.length > 0 ? true : false}
									filterHidden={true}
									filterPlaceholder={Messages.SearchforStore}
									filterStyle={{ width: "100px" }}
								>
									<DataTable
										noHeader={true}
									// noTableHead={true}
									//pagination

									/>
								</DataTableExtensions>

							</div>
						</div>
					</section>

					{/* <nav className="nav-bar2 navbar-expand-lg">
						<div className="" id="navbarDefault">
							<div className="container" >

								<StoreList data={this.state.data} />
								<center>
									<div className="registered-with">
										<span
											style={{ background: Color.THEME_COLOR }}
											onClick={this.regularRegister}
											className="btn btn-reg-email">
											Submit a Store
										</span>
									</div>
								</center>
							</div>
						</div>
					</nav> */}
				</LoadingOverlay>
			</div>
		);
	}
}

export default StoreMain;