import React, { Component } from 'react';
import defaultUser from "../../images/icons/default-user.png";
import './SideMenu.css';
import WheelPhoto from "../../images/icons/gear-wheel.png";
import { MenuItem } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import Color from '../Config/Color';
// import RouteConstant from './Config/RouteConstant';
import { withRouter } from 'react-router';
import RouteMerchant from '../Config/RouteMerchant.js'

class ProdcutsCategorySide extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        // this.handleItemClick = this.handleItemClick.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    componentDidMount() {
    }

    // handleItemClick(data) {
    //     console.log("item click")
    //     console.log(JSON.stringify(data))
    //     this.closeNav();
    //     // this.props.history.push({
    //     // 	pathname: RouteConstant.CurbsideOrder,                   
    //     // })
    // }

    closeNav() {
        window.closeNav();
    }

    handleProducts() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.ManageProducts,
        })

    }

    render() {
        let list = this.props.data.slice(0, 10)
            .map(function (item, index) {
                return (
                    <React.Fragment key={item.OrderId}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY }} onClick={(value) => this.handleProducts()} >
                            <div className="row" style={{ fontSize: "12px" }}><span style={{ color: Color.THEME_COLOR }}>Parent Category Name: </span> &nbsp; {item.ParentCategoryName} </div>
                            <div className="row" style={{ fontSize: "12px" }}><span style={{ color: Color.THEME_COLOR }}>Category Name: </span> &nbsp; {item.Category_name}</div>
                            {/* <div className="row" style={{ fontSize: "12px" }}>Order Status: {item.OrderStatus}</div>
                            <div className="row" style={{ fontSize: "12px" }}>
                                <div style={{ fontSize: "12px", marginLeft: "auto", marginRight: "18px" }}>
                                    <center>1 Shops with <br /> 1 Items</center>
                                </div>
                            </div>
                            <div className="row" >
                                <label style={{ fontSize: "12px", color: Color.THEME_COLOR }}> {item.Price} </label>
                                <span style={{ marginLeft: "auto", }}>
                                    <img src={item.ItemImage} style={{ height: "25px", marginRight: "10px", width: "25px" }}></img>
                                    <label style={{ fontSize: "13px", float: "right", marginRight: "18px" }}>+ View More</label>
                                </span>
                            </div> */}
                        </div>
                    </React.Fragment>
                )
            }, this);

        return (
            <div style={{ cursor: "pointer" }}>
                {list}
            </div>
        );
    }
}

export default withRouter(ProdcutsCategorySide);