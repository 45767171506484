import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Login.css';
import "../../../styles/style.css";
import Messages from "../../Config/Messages";
import { validateEmail, validatePassword, StoreDataInStorage, RemoveFromStorage, logoutSession } from "../../Config/Utils";
import { PostData } from "../../../services/PostData";
import { LOGIN_API, GOOGLESITEKEY, GOOGLE_CLIENT_ID, GOOGLE_SIGNIN_API } from '../../Config/Config.json'
import Constants, { DEFAULTID } from '../../Config/Constants'
import RouteMerchant from '../../Config/RouteMerchant.js'
import Color from "../../Config/Color"
import { UserAccess } from "../../Config/constvariable";
import { PutMandatoryfieldSymbol } from '../../UtillCustomComponent/UtillCustomComponent';
import { RegisterNewConstForm } from "../../Config/ConstForm";
import LoadingOverlay from 'react-loading-overlay';
import ReCAPTCHA from "react-google-recaptcha";
import MainHeader from '../../MainHeader/MainHeader';
import MainFooter from '../../MainFooter/MainFooter';

const publicIp = require('public-ip');
import { BrowserView, MobileView, isBrowser, isMobile, deviceDetect, osName, osVersion, fullBrowserVersion, browserName, browserVersion } from "react-device-detect";
import detectBrowserLanguage from 'detect-browser-language';
import LoginWithGoogle from "../../LoginWithGoogleFiles/LoginWithGoogle";
import { ACaptcha, GETWORD } from '../../UtillCustomComponent/CaptchaGenerator';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userFields: {},
            errors: {},
            loaderClass: false,
            ipAddress: '',
            browserLanguage: '',
            isActive: false,
            isPassword: true,

            /* Google Captcha Code */
            /* googleReCaptch: null,
            iscaptchreload: true */

            /* Native Captcha Code */
            captchWord: "",
            iscaptchwordMatch: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidationOnBlur = this.handleValidationOnBlur.bind(this);
    }

    componentDidMount() {
        /* For Ip Address */
        (async () => {
            var ip = await publicIp.v4();
            this.setState({ ipAddress: ip });
            console.log('ip address', ip);
        })();

        logoutSession()

        /* For Current Location */
        if ("geolocation" in navigator) {
            console.log("Geolocation Available");
            this.getLocation();
        } else {
            console.log("Geolocation Not Available");
            console.error("Error Code = " + error.code + " - " + error.message);
        }

        /* For Detect browser details */
        const detectBrowserLanguage = require('detect-browser-language');
        this.setState({ browserLanguage: detectBrowserLanguage() });
        console.log('detect browser language', detectBrowserLanguage());

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    /* For Get Curent Location */
    getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            console.log('Location', 'lat' + position.coords.latitude + "Lon" + position.coords.longitude);
            this.setState({ latitude: position.coords.latitude });
            this.setState({ longitude: position.coords.longitude });
        });
    };

    /* Google Captcha Code */
    /* handleGoogleRecaptcha = vl => {
        this.setState({ googleReCaptch: vl })
    } */

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name;
        let value = event.target.value;
        userFields[key] = value;

        /* Native Captcha Code */
        if (key === "captchacode") {
            if (value.length === 6) {
                if (value != this.state.captchWord) {
                    let errors = this.state.errors;
                    errors['captchacode'] = Messages.v_EnterValidCaptcha;
                    this.setState({ errors: errors, iscaptchwordMatch: false });
                } else {
                    this.setState({ iscaptchwordMatch: true, errors: {} });
                }
            } else {
                if (value.length === 0 || value.length === 1) {
                    this.setState({ errors: {} });
                }
                this.setState({ iscaptchwordMatch: false });
            }
        }

        this.setState({ userFields });
    }

    reloadCaptch() {

        /* Google Captcha Code */
        /* this.setState({ iscaptchreload: false, googleReCaptch: null })
        setTimeout(() => {
            this.setState({ iscaptchreload: true })
        }, 900) */

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    handleValidationOnBlur(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        /* if (name === "Email") {
            if (!fields["Email"]) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterEmail;
            }
            else if (typeof fields["Email"] !== "undefined") {
                if (!validateEmail(fields["Email"])) {
                    formIsValid = false;
                    errors["Email"] = Messages.v_EnterValidEmail;
                }
            }
        }
        else if (name === "Password") {
            if (!fields["Password"]) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterPassword;
            }
            else if (typeof fields["Password"] !== "undefined") {
                if (!validatePassword(fields["Password"])) {
                    formIsValid = false;
                    errors["Password"] = Messages.v_EnterValidPassword;
                }
            }
        }
        else */ if (name === "captchacode") {
            if (!fields['captchacode']) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterCaptcha;
            }
            else if (typeof fields["captchacode"] !== "undefined") {
                if (fields["captchacode"] != this.state.captchWord) {
                    formIsValid = false;
                    errors["captchacode"] = Messages.v_EnterValidCaptcha;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        /* Google Captcha Code */
        /* const { googleReCaptch } = this.state; */

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = Messages.v_EnterEmail;
        }
        else if (typeof fields["Email"] !== "undefined") {
            if (!validateEmail(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }

        if (!fields["Password"]) {
            formIsValid = false;
            errors["Password"] = Messages.v_EnterPassword
        }
        else if (typeof fields["Password"] !== "undefined") {
            if (!validatePassword(fields["Password"])) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterValidPassword;
            }
        }

        /* Native Captcha Code */
        if (!fields['captchacode']) {
            formIsValid = false;
            errors["captchacode"] = Messages.v_EnterCaptcha;
        }
        else if (typeof fields["captchacode"] !== "undefined") {
            if (fields["captchacode"] != this.state.captchWord) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterValidCaptcha;
            }
        }

        /* Google Captcha Code */
        /* if (!googleReCaptch) {
            formIsValid = false;
        } */

        this.setState({ errors: errors });
        return formIsValid;
    }


    handleSubmit(e) {

        var url = "";
        var postData = {};

        /* Google Captcha Code */
        /* const { googleReCaptch } = this.state; */

        e.preventDefault();

        if (this.validateForm()) {
            this.setState({ loaderClass: true, isActive: true, error: {} });
            postData = this.state.userFields;
            postData["DeviceId"] = ""; /* Constants.DEVICE_ID */
            postData["DeviceType"] = ""; /* Constants.DEVICE_TYPE */
            postData["PushToken"] = ""; /* Constants.PUSH_TOKEN */
            postData["ipaddress"] = this.state.ipAddress;
            postData["browser"] = browserName;
            postData["browserversion"] = fullBrowserVersion;
            postData["osdetails"] = osName + " " + osVersion;
            postData["location"] = "";
            postData["locale"] = this.state.browserLanguage;
            postData["Latitude"] = this.state.latitude;
            postData["Longitude"] = this.state.longitude;
            postData["questionid"] = DEFAULTID;
            postData["answer"] = "";
            postData["userid"] = "";
            postData["googlecaptcharesponse"] = ""; /* googleReCaptch */
            this.callLoginAPI(postData);

        }
    }

    callLoginAPI(postData) {
        localStorage.clear();
        var URL = LOGIN_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ loaderClass: false, isActive: false, errors: {} });
                    var responseData = responseJson["Data"]
                    var authToken = responseJson["Data"].Token;
                    StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                    if (responseData) {
                        let isParent = UserAccess.ISPARENTFALSE
                        if (responseData.IsParentMerchannt) {
                            isParent = UserAccess.ISPARENTTRUE
                        }
                        StoreDataInStorage(Constants.ISPARENT, isParent);
                    }
                    else {
                        RemoveFromStorage(Constants.ISPARENT)
                    }
                    this.props.history.push({
                        pathname: RouteMerchant.StoreMain,
                    })
                    window.location.reload(true);
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["ErrorC"] = responseJson.Message;
                    this.setState({ errors: errors, isActive: false });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
                this.reloadCaptch()
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
                this.reloadCaptch()
            });
    }

    ResponsewithGooglewithSigninApi = (response) => {
        this.setState({ loaderClass: true, isActive: true, error: {} });

        /* Google Captcha Code */
        const { /* googleReCaptch, */ iscaptchwordMatch } = this.state;

        var postData = {};
        console.log(response);

        try {
            if (iscaptchwordMatch /* googleReCaptch */) {

                var data = response;
                let key = Constants.GOOGLEEMAIL;
                let value = data.email;
                postData[key] = value;

                /* Google Captcha Code */
                postData["googlecaptcharesponse"] = ""; /* googleReCaptch */

                var URL = GOOGLE_SIGNIN_API;
                PostData(URL, postData)
                    .then(result => {
                        var responseJson = result;
                        console.log("responseJson", responseJson);
                        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                            this.setState({ loaderClass: false, isActive: false, errors: {} });
                            var responseData = responseJson["Data"]
                            var authToken = responseJson["Data"].Token;
                            StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                            if (responseData) {
                                let isParent = UserAccess.ISPARENTFALSE;
                                if (responseData.IsParentMerchannt) {
                                    isParent = UserAccess.ISPARENTTRUE;
                                }
                                StoreDataInStorage(Constants.ISPARENT, isParent);
                            }
                            else {
                                RemoveFromStorage(Constants.ISPARENT);
                            }
                            this.props.history.push({
                                pathname: RouteMerchant.StoreMain,
                            })
                            window.location.reload(true);
                        }
                        else if (responseJson["StatusCode"] === 400) {
                            let errors = {};
                            errors["ErrorC"] = responseJson.Message;
                            this.setState({ errors: errors, isActive: false });
                        }
                        else {
                            let errors = {};
                            errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                            this.setState({ errors: errors, isActive: false });
                        }
                        this.reloadCaptch();
                    }).catch(error => {
                        let errors = {};
                        errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                        this.setState({ errors: errors, isActive: false });
                        this.reloadCaptch();
                    });
            } else {
                let errors = {};
                let fields = this.state.userFields;
                if (!fields['captchacode']) {
                    errors["captchacode"] = Messages.v_EnterCaptcha;
                }
                else if (typeof fields["captchacode"] !== "undefined") {
                    if (fields["captchacode"] != this.state.captchWord) {
                        errors["captchacode"] = Messages.v_EnterValidCaptcha;
                    }
                }
                this.setState({ errors: errors, isActive: false });
                this.reloadCaptch()
            }
        } catch (err) {
            let errors = {};
            errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
            this.setState({ errors: errors, isActive: false });
            this.reloadCaptch();
        }
    }

    render() {

        /* Google Captcha Code */
        const { isPassword, /* googleReCaptch, iscaptchreload, */ captchWord, iscaptchwordMatch } = this.state;

        return (
            <LoadingOverlay
                active={this.state.isActive}
                text={Messages.Loading}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        position: "fixed"
                    })
                }}
                className="lodingspinner">
                <div class="hero_area">
                    <MainHeader />
                    <section className="section">
                        <div className="registed_tab_part" style={{ padding: "20px 0px 0px 0px", bottom: "auto", position: 'relative' }}>
                            <div className="container">
                                {/* <p id="" style={{ display: "block" }}><h3 style={{ display: "block", color: Color.THEME_COLOR }}>Login</h3></p> */}
                                <div className="">
                                    <div className="registed_form_tab">
                                        <div >
                                            <div className="sub_form_reg_one">
                                                <div id="home" className="tab-pane">
                                                    <div className="as_user_form">

                                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                                            <p className="col-sm-12" for="Email">
                                                                {RegisterNewConstForm.OnlyEmail} <PutMandatoryfieldSymbol />
                                                            </p>
                                                            <input
                                                                type="email"
                                                                placeholder="name@example.com"
                                                                name="Email"
                                                                value={this.state.userFields.Email}
                                                                onChange={this.handleChange} />
                                                            <div className="errorMsg">
                                                                {this.state.errors.Email}
                                                            </div>{" "}
                                                            <div className="errorMsg">
                                                                {this.state.alreadyEmail}
                                                            </div>
                                                        </div>

                                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                                            <p className="col-sm-12" for="Password">
                                                                {RegisterNewConstForm.Password} <PutMandatoryfieldSymbol />
                                                            </p>
                                                            <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                                                <input
                                                                    style={{ width: "90%", margin: 0 }}
                                                                    type={isPassword ? "password" : "text"}
                                                                    placeholder="Password"
                                                                    name="Password"
                                                                    value={this.state.userFields.Password}
                                                                    onChange={this.handleChange} />
                                                                <i class={!isPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isPassword: !isPassword })} />
                                                            </div>
                                                            <div className="errorMsg">
                                                                {this.state.errors.Password}
                                                            </div>
                                                        </div>

                                                        {/* Google Captcha Code */}
                                                        {/* <div className="reg_form_main offset-sm-3 col-sm-6 text-center">
                                                            {iscaptchreload && <ReCAPTCHA
                                                                style={{ display: "inline-block" }}
                                                                // theme="dark"
                                                                // ref={this._reCaptchaRef}
                                                                size="normal"
                                                                sitekey={GOOGLESITEKEY}
                                                                onChange={this.handleGoogleRecaptcha}
                                                            // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                                            />}
                                                        </div> */}

                                                        {/* Native Captcha Code */}
                                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                                            <p className="col-sm-12" for="Password">
                                                                {RegisterNewConstForm.CaptchaCode} <PutMandatoryfieldSymbol />
                                                            </p>
                                                            <input
                                                                type="text"
                                                                placeholder={RegisterNewConstForm.CaptchaCode}
                                                                name="captchacode"
                                                                className="float-none m-0 mb-2"
                                                                value={this.state.userFields.captchacode}
                                                                maxLength="6"
                                                                onChange={this.handleChange}
                                                                onBlur={this.handleValidationOnBlur} />
                                                            <div className="col-sm-12 errorMsg mb-2">
                                                                {this.state.errors.captchacode}
                                                            </div>
                                                            <div className="text-center">
                                                                <ACaptcha
                                                                    font={'30px Arial'}
                                                                    align={'center'}
                                                                    baseline={'middle'}
                                                                    width={200}
                                                                    height={50}
                                                                    bgColor={Color.THEME_COLOR}
                                                                    color={Color.WHITE}
                                                                    captchstr={captchWord} />
                                                            </div>
                                                        </div>

                                                        <div className="bottam_sing mt-2 mb-2">
                                                            <a href="/forgotpassword" className="theme-color font-wight-bold">Forgot your password?</a>
                                                        </div>

                                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                                            <center>
                                                                <div className="errorMsg">
                                                                    {this.state.errors.ErrorC}
                                                                </div>
                                                            </center>
                                                        </div>

                                                        <div className="bottam_sing mt-3 mb-0">
                                                            <button
                                                                for="step2"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                id="continue-step2"
                                                                onClick={this.handleSubmit}
                                                                type="button"
                                                                disabled={!iscaptchwordMatch /* !googleReCaptch */} /* Google Captcha Code */
                                                                className="btn btn-done" > Login</button>
                                                        </div>

                                                        <div className="bottam_sing">
                                                            <LoginWithGoogle
                                                                mode={1}
                                                                clientId={GOOGLE_CLIENT_ID}
                                                                disabled={!iscaptchwordMatch /* !googleReCaptch */} /* Google Captcha Code */
                                                                onSuccess={this.ResponsewithGooglewithSigninApi} />
                                                        </div>

                                                        <div className="bottam_sing mt-2 mb-4">
                                                            Don't have an account? <a href="/Register"
                                                                className="theme-color font-wight-bold"
                                                                style={{ color: Color.THEME_COLOR }}> Register</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
                <MainFooter />
            </LoadingOverlay >
        );
    }
}

