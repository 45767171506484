import React, { Component } from "react";
import "./HomeShopify.css";
// import OrderList from "../OrderList/OrderList";
import Messages from "../../Config/Messages";
import Constants,{DEFAULTID} from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreDataInStorage, parseFromTime } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { GET_STOREPRODUCT, GET_COUNTRY_API, UPDATESTATUS, GET, PLAZAID, GET_MERCHANT_ORDER_API, TRACKSTATUS_API } from '../../Config/Config.json';
import Header from '../../Header/Header.js'
import SideMenu from "../../SideMenu/SideMenu";
import Constvariable, { ORDERSTATUS, DatetimeFormate, plazaCartDeliveryObj, SelfDeliveryStatus, CurbsideStatus } from '../../Config/constvariable';
import LoadingOverlay from 'react-loading-overlay';
import Pagination from "react-js-pagination";
import { HomeConst } from '../../Config/ConstForm';
import Moment from 'moment'
import Color from '../../Config/Color';
import RouteMerchant from '../../Config/RouteMerchant.js';
import Select from 'react-select';
import { PUTData } from '../../../services/PUTData';
import { customConfirmAlert } from "../../Alerts/Alertinline"
import { PostData } from '../../../services/PostData';

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", marginBottom: "10px" }),
  placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
  menuPortal: styles => ({ ...styles, zIndex: "99" })
};

const tabIndex = {
  "first": 1,
  "second": 2,
  "third": 3,
  "forth": 4
}

class HomeShopify extends Component {
  constructor(props) {
    super(props);
    const pr = this.props.location;
    this.state = {
      classList: [
        { id: "new", class: "", div: "" },
        { id: "pending", class: "active", div: "hideFilter" },
        { id: "inprocess", class: "", div: "hideFilter" },
        { id: "completed", class: "", div: "hideFilter" },
        { id: "all", class: "", div: "hideFilter" }
      ],

      storeId: "",
      plazaId: "",

      data: [],     // all order list
      tempdata: [],   // tab wise order list
      childTempdata: [],  // page wise order list
      // loaderClass: true,
      isActive: false,
      activePage: 1,  // paggination index
      countryData: [],
      tabIndex: 1,  // tab index 

      selfDeliveryOption: SelfDeliveryStatus,
      curbsideStatus: CurbsideStatus,
      selectedSelfDS: null,   // list order status value
      selfDelfDeliveryInd: -1,  // list order status value index

      navigateList: true,    // for list navigation 
      orderDetailObj: null, // for render

      formSelectedSelfDS: null,
      errors: {}
    };
    this.setFilter = this.setFilter.bind(this);
  }

  componentDidMount() {
    console.log('I was triggered during componentDidMount')
    var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    var storeid = RetriveDataFromStorage(Constants.STOREID);
    var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
    if (storeObj) {
      var storeId = storeObj.ObjectId;
      var plazaId = DEFAULTID;
      this.setState({ storeId: storeId, plazaId: plazaId })
      this.getProductList(authToken, storeId, plazaId);
    } else {
        this.props.history.goBack()
    }
    console.log("authToken", authToken);
    // this.getProductList(authToken, storeid);
    this.getCountryAPI()

  }

  getCountryAPI() {
    console.log("country list1 ",);
    GetData(GET_COUNTRY_API)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          if (responseData) {
            this.setState({ countryData: responseData });
          }
          else {
            this.setState({ countryData: [] })
          }
          console.log("countryData ", responseData);
        }
      });
  }

  getProductList(authToken, storeid, plazaId) {
    this.setState({ isActive: true, errors: {} });
    console.log("authToken", authToken);
    var URL = GET_STOREPRODUCT + storeid + "/" + GET + "?" + PLAZAID + plazaId
    GetData(URL, authToken)
      .then(result => {
        var responseJson = result;
        // var responseJson = { StatusCode: 200, Status: "Success" }
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          // var responseData = Data
          console.log("responseData ", responseData);
          if (responseData) {
            this.setState({ data: responseData }, () => {
              let tabInd = this.state.tabIndex;
              this.setFilter(tabInd)
            });
            // var d = responseData.filter(e => e.OrderStatus == ORDERSTATUS.PENDING);
            // var childTempdata = d.slice((1 - 1) * 10, 1 * 10)
            // this.setState({ tempdata: d, childTempdata: childTempdata });
          }
        }
        else {
          let errors = {};
          errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
          this.setState({ errors: errors });
        }
        this.setState({ isActive: false })
      }).catch(error => {
        let errors = {};
        errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
        this.setState({ errors: errors, isActive: false });
      });
  }

  /* ///////////////////////////////////////////////////////////////// */
  /* ///////////////////////// detail method ///////////////////////// */
  /* ///////////////////////////////////////////////////////////////// */

  setFilter(num) {
    var parseId = num;
    parseId = parseInt(parseId);
    var classList = this.state.classList;
    if (classList) {
      for (var i = 0; i < classList.length; i++) {
        if (classList[i].class !== "") {
          classList[i].class = "";
          // classList[i].div = "hideFilter";
        }
      }
      classList[parseId].class = "active";
      // classList[parseId].div = "";
      this.setState({ classList: classList });
    }
    var d = this.state.data;
    if (d) {
      var n = [];
      for (var i = 0; i < d.length; i++) {
        if (parseId == 1) {
          if (d[i].OrderStatus == ORDERSTATUS.PENDING) {
            n.push(d[i]);
          }
        } else if (parseId == 2) {
          if ((d[i].OrderStatus == ORDERSTATUS.CONFIRMED) || (d[i].OrderStatus == ORDERSTATUS.DELIVERY_CREATED) ||
            (d[i].OrderStatus == ORDERSTATUS.DELIVERY_CONFIRMED) || (d[i].OrderStatus == ORDERSTATUS.ARRIVED_AT_PICKUP) ||
            (d[i].OrderStatus == ORDERSTATUS.DELIVERY_PICKED_UP) || (d[i].OrderStatus == ORDERSTATUS.ARRIVED_AT_DROPOFF) || (d[i].OrderStatus == ORDERSTATUS.DELIVERY_CANCEL)) {
            n.push(d[i]);
          }
        } else if (parseId == 3) {
          if ((d[i].OrderStatus == ORDERSTATUS.DELIVERED) || (d[i].OrderStatus == ORDERSTATUS.PICKEDUP)) {
            n.push(d[i]);
          }
        } else {
          n.push(d[i]);
        }
      }
      var childTempdata = n.slice((1 - 1) * 10, 1 * 10)
      this.setState({ tempdata: n, childTempdata: childTempdata });
      this.setState({ activePage: 1, tabIndex: num })
      this.setState({ navigateList: true, orderDetailObj: null })
    }
  }

  // /////////////////////// paggination method ///////////////////////////
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var childTempdata = this.state.tempdata.slice((pageNumber - 1) * 10, pageNumber * 10)
    this.setState({ childTempdata: childTempdata })
  }
  //////////////////////////////////////////////////////


  handleSelfDelivery(selectedSelfDS, i) {
    this.setState({
      selectedSelfDS: selectedSelfDS,
      selfDelfDeliveryInd: selectedSelfDS ? i : -1
    })
  }

  ////////////////////////// check order status /////////////////////////////
  dOrderStatus(obj) {
    var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    this.getOrderCrossStatus(authToken, obj.OrderId)
  }

  getOrderCrossStatus(authToken, ordereId) {
    this.setState({ isActive: true, errors: {} });
    var URL = GET_MERCHANT_ORDER_API + ordereId + "/" + TRACKSTATUS_API;
    GetData(URL, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          let storeId = this.state.storeId, plazaId = this.state.plazaId;
          this.getProductList(authToken, storeId, plazaId);
        }
        else {
          let errors = {};
          errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
          this.setState({ errors: errors });
        }
        this.setState({ isActive: false })
      }).catch(error => {
        let errors = {};
        errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
        this.setState({ errors: errors, isActive: false });
      });
  }
  //////////////////////////////////////////////////////////////////////////

  handleSelfDSubmit(obj, i) {
    var postData = {}
    let selectedObjInd = this.state.selfDelfDeliveryInd, selectedSelfDS = this.state.selectedSelfDS
    if (i == selectedObjInd && selectedSelfDS) {
      postData["Status"] = selectedSelfDS.value

      var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
      this.postOrderStatus(authToken, postData, obj.OrderId)
      // customConfirmAlert({
      //   title: HomeConst.modal1_Title,
      //   message: HomeConst.modal4_Msg,
      //   buttons: [
      //     {
      //       label: HomeConst.modal1_btnConfirm,
      //       onClick: () => {

      //       }
      //     },
      //     {
      //       label: HomeConst.modal1_btnBack,
      //       onClick: () => {
      //         return false;
      //       }
      //     }
      //   ]
      // })

    }
  }

  postOrderStatus(authToken, postData, ordereId) {
    this.setState({ isActive: true, errors: {} });
    var URL = GET_MERCHANT_ORDER_API + ordereId + "/" + UPDATESTATUS;
    PUTData(URL, postData, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          let storeId = this.state.storeId, plazaId = this.state.plazaId;
          this.getProductList(authToken, storeId, plazaId)
          this.setState({
            selectedSelfDS: null,
            selfDelfDeliveryInd: -1
          });
        }
        else {
          let errors = {};
          errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
          this.setState({ errors: errors });
        }
        this.setState({ isActive: false });
      }).catch(error => {
        let errors = {};
        errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
        this.setState({ errors: errors, isActive: false });
      });
  }

  storenavigate(data) {
    console.log("value", data.OrderId);
    this.setState({
      navigateList: false,
      orderDetailObj: data,
      formSelectedSelfDS: null
    })
    // this.props.history.push({
    //   pathname: RouteMerchant.OrderDetails,
    //   data: data.OrderId,
    // });
    // StoreDataInStorage(Constants.ORDERID, data.OrderId);
    // StoreDataInStorage(Constants.ORDERFLAG, data.OrderStatus);
  }

  ///////////////////////////////////// pandding method /////////////////////////////

  ordercancel() {
    const { orderDetailObj } = this.state;
    if (orderDetailObj) {
      var postData = {};
      postData["Status"] = ORDERSTATUS.CANCEL;
      var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

      customConfirmAlert({
        title: HomeConst.modal1_Title,
        message: <><p>Payment for cancelled orders will be credited to the customer's account.</p><p>Are you sure you want to cancel this order?</p></>,
        buttons: [
          {
            label: HomeConst.modal1_btnConfirm,
            onClick: () => {
              this.postOrderStatus(authToken, postData, orderDetailObj.OrderId);
              this.setState({ navigateList: true, orderDetailObj: null })
            }
          },
          {
            label: HomeConst.modal1_btnBack,
            onClick: () => {
              return false;
            }
          }
        ]
      })
    }
  }

  ordercall() {
    const { orderDetailObj, storeId, plazaId } = this.state;
    if (orderDetailObj && storeId) {
      var postData = {};
      postData["Isconfirm"] = orderDetailObj.IsPickupOrder ? true : false;
      postData["IsDelivered"] = orderDetailObj.IsPickupOrder ? false : true;
      postData["ApplicationId"] = storeId
      var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

      var modalT, modalM
      if (orderDetailObj.IsPickupOrder) {
        modalT = HomeConst.modal1_pding
        modalM = <>
          <p>Once an order has been accepted, you must get it ready for the customer to pick up.</p>
          <p>The customer will arrive at the brewery between {orderDetailObj.DeliveryTime}</p>
          <p>Please have the product ready for pickup by {parseFromTime(orderDetailObj.DeliveryTime)} </p>
        </>
      }
      else {
        modalT = HomeConst.modal2_Title
        modalM = <>
          <p>As soon as you accept an order, you must prepare it and give it to the buyer.</p>
          <p>The customer desired delivery between {orderDetailObj.DeliveryTime}</p>
          <p>Please have the product available by  {parseFromTime(orderDetailObj.DeliveryTime)} and do the delivery.</p>
        </>
        if (orderDetailObj.Deliveryoption != plazaCartDeliveryObj.DeliveryOptionkey) {
          modalT = HomeConst.modal3_Title
          modalM = <>
            <p>Accepting the order will pass the delivery to {orderDetailObj.Deliveryoption} via
              the system.</p>
            <p>The customer desired delivery between {orderDetailObj.DeliveryTime}</p>
            <p>Please have the product available by  {parseFromTime(orderDetailObj.DeliveryTime)} for {orderDetailObj.Deliveryoption} delivery.</p>
          </>
        }
      }

      customConfirmAlert({
        title: modalT,
        message: modalM,
        buttons: [
          {
            label: HomeConst.modal1_btnConfirm,
            onClick: () => {
              this.orderData(postData, authToken, orderDetailObj.OrderId, plazaId);
              this.setState({ navigateList: true, orderDetailObj: null })
            }
          },
          {
            label: HomeConst.modal1_btnBack,
            onClick: () => {
              return false;
            }
          }
        ]
      })
    }
  }

  orderData(postData, authtoken, orderid, plazaId) {
    this.setState({ isActive: true, errors: {} });
    var URL = GET_MERCHANT_ORDER_API + orderid + UPDATESTATUS;
    PostData(URL, postData, authtoken)
      .then(result => {
        var responseJson = result;
        console.log("responseData ", responseJson);
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          this.getProductList(authtoken, postData["ApplicationId"], plazaId);
        }
        else {
          let errors = {};
          errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
          this.setState({ errors: errors });
        }
        this.setState({ isActive: false })
      }).catch(error => {
        let errors = {};
        errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
        this.setState({ errors: errors, isActive: false });
      });
  }

  //////////////////////////////////// pendding method end ///////////////////////////

  ordercallWithPickup() {
    const { orderDetailObj } = this.state;
    if (orderDetailObj) {
      var postData = {};
      postData["Status"] = ORDERSTATUS.PICKEDUP;
      var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

      customConfirmAlert({
        title: HomeConst.modal1_Title,
        message: HomeConst.modal3_Msg,
        buttons: [
          {
            label: HomeConst.modal1_btnConfirm,
            onClick: () => {
              this.postOrderStatus(authToken, postData, orderDetailObj.OrderId);
              this.setState({ navigateList: true, orderDetailObj: null })
            }
          },
          {
            label: HomeConst.modal1_btnBack,
            onClick: () => {
              return false;
            }
          }
        ]
      })
    }
  }

  handleFormSelfDelivery(formSelectedSelfDS) {
    this.setState({ formSelectedSelfDS })
  }

  orderCallwithDelivery() {
    const { orderDetailObj, formSelectedSelfDS } = this.state;
    if (orderDetailObj && formSelectedSelfDS) {
      var postData = {};
      postData["Status"] = formSelectedSelfDS.value;
      var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

      this.postOrderStatus(authToken, postData, orderDetailObj.OrderId);
      this.setState({ navigateList: true, orderDetailObj: null });

      // customConfirmAlert({
      //   title: HomeConst.modal1_Title,
      //   message: HomeConst.modal4_Msg,
      //   buttons: [
      //     {
      //       label: HomeConst.modal1_btnConfirm,
      //       onClick: () => {
      //         this.postOrderStatus(authToken, postData, orderDetailObj.OrderId);
      //         this.setState({ navigateList: true, orderDetailObj: null })
      //       }
      //     },
      //     {
      //       label: HomeConst.modal1_btnBack,
      //       onClick: () => {
      //         return false;
      //       }
      //     }
      //   ]
      // })
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// render method /////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  statusValidateInitial(orderObj) {
    let flag = false
    if (orderObj.OrderStatus == ORDERSTATUS.PENDING) {
      flag = true
    }
    return flag
  }

  statusValidate(orderObj) {
    let flag = false;
    if ((orderObj.OrderStatus == ORDERSTATUS.CONFIRMED) || (orderObj.OrderStatus == ORDERSTATUS.DELIVERY_CREATED) ||
      (orderObj.OrderStatus == ORDERSTATUS.DELIVERY_CONFIRMED) || (orderObj.OrderStatus == ORDERSTATUS.ARRIVED_AT_PICKUP) ||
      (orderObj.OrderStatus == ORDERSTATUS.DELIVERY_PICKED_UP) || (orderObj.OrderStatus == ORDERSTATUS.ARRIVED_AT_DROPOFF) || (orderObj.OrderStatus == ORDERSTATUS.DELIVERY_CANCEL)) {
      flag = true
    }
    return flag
  }

  renderTextDetail(currencySymbol) {

    const { orderDetailObj } = this.state
    if (orderDetailObj) {
      var subTotal = 0.0
      var totalspecialdeliverycharges = 0.0
      var textType = "", TotalTaxValue = 0.0, renderTaxDiv, totalItemTax = 0, couponAmount = 0.0
      let Items = orderDetailObj.Items ? orderDetailObj.Items : []
      Items.map(itemObj => {
        totalspecialdeliverycharges = totalspecialdeliverycharges + itemObj.specialdeliverycharges
        totalItemTax = totalItemTax + itemObj.TotalItemTax
        subTotal = subTotal + itemObj.TotalItemPrice
      })
      if (orderDetailObj.TaxDetails) {
        renderTaxDiv = orderDetailObj.TaxDetails.map(e => {
          TotalTaxValue = TotalTaxValue + Number(e.TotalTax)
          return <div class="col-sm-12">
              <span style={{ float: "right" }}> {e.TaxType} ({Number(e.TaxRate) ? e.TaxRate : ""}%) = <b> {currencySymbol} {Number.parseFloat(e.TotalTax).toFixed(2)}</b> </span>
          </div>
        })
      }
      var deliverytipamount = orderDetailObj.deliverytipamount ? orderDetailObj.deliverytipamount : 0
      // var total = Number(subTotal) + Number(totalspecialdeliverycharges) + Number(orderDetailObj.TotalDeliveryCharges) + Number(orderDetailObj.ServiceFeeAmount) + TotalTaxValue + deliverytipamount + totalItemTax
      // total = total
      couponAmount = orderDetailObj.CouponDetail ? orderDetailObj.CouponDetail.discountamount : 0

      return <div className='row'>
        <div class="col-sm-12">
          <span style={{ float: "right" }}> {HomeConst.Subtotal} <b> {currencySymbol} {Number.parseFloat(subTotal).toFixed(2)}</b> </span>
        </div>

        {totalItemTax != 0 && <div class="col-sm-12">
          <span style={{ float: "right" }}> {HomeConst.TotalProductTax} <b> {currencySymbol} {Number.parseFloat(totalItemTax).toFixed(2)}</b> </span>
        </div>}
        {!orderDetailObj.IsPickupOrder && <div class="col-sm-12">
          <span style={{ float: "right" }}> {HomeConst.DeliveryCharges} <b> {currencySymbol} {Number.parseFloat(orderDetailObj.TotalDeliveryAmount - totalspecialdeliverycharges).toFixed(2)}</b> </span>
        </div>}
        {!orderDetailObj.IsPickupOrder && <div class="col-sm-12">
          <span style={{ float: "right" }}> {HomeConst.SpecialDeliveryCharges} <b> {currencySymbol} {Number.parseFloat(totalspecialdeliverycharges).toFixed(2)}</b> </span>
        </div>}
        <div class="col-sm-12">
          <span style={{ float: "right" }}> {HomeConst.ServiceFee} <b> {currencySymbol} {Number.parseFloat(orderDetailObj.ServiceFeeAmount).toFixed(2)}</b> </span>
        </div>
        {renderTaxDiv}
        {!orderDetailObj.IsPickupOrder && <div class="col-sm-12">
          <span style={{ float: "right" }}> {HomeConst.DeliveryTip} <b> {currencySymbol} {Number.parseFloat(deliverytipamount).toFixed(2)}</b> </span>
        </div>}
        {/* <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> Delivery Tip = <b> {currencySymbol} {Number.parseFloat(this.state.Total).toFixed(2)}</b> </span>
        </div> */}
        <div class="col-sm-12">
          <span style={{ float: "right" }}> {HomeConst.Total} <b> {currencySymbol} {Number.parseFloat(orderDetailObj.TotalAmount + Number(couponAmount)).toFixed(2)}</b> </span>
          <span style={{ float: "right", margin: "0px 20px 0px 0px" }}> <b>{this.state.Quntity}</b> {HomeConst.Items} </span>
        </div>
        {orderDetailObj.CouponDetail && <div class="col-sm-12">
          <span style={{ float: "right" }}> {HomeConst.WithDiscountTotal} <b> {currencySymbol} {Number.parseFloat(orderDetailObj.TotalAmount).toFixed(2)}</b> </span>
          {/* <span style={{ float: "right", margin: "0px 20px 0px 0px" }}> <b>{this.state.Quntity}</b> Items </span> */}
        </div>}
        {orderDetailObj.CouponDetail && <div className={'col-sm-12 couponBox'} >
          <b>{HomeConst.CouponDetail}</b>
          <div className='coupondesc row-over couponblock'>
            <label className="remove-mg" style={{ marginLeft: "10px", color: Color.BLACK, fontWeight: 'bold', }}>{orderDetailObj.CouponDetail.coupontitle} <span className="coupontitleamount">{currencySymbol + " " + Number(orderDetailObj.CouponDetail.discountamount).toFixed(2) + " OFF"} </span>
            </label>
            <label className="remove-mg coupondescription" style={{ marginLeft: "10px", color: Color.BLACK }}> {orderDetailObj.CouponDetail.coupondescription}</label>
          </div>
        </div>}
      </div>
    }
  }

  getispuckup() {
    const { orderDetailObj } = this.state;
    if (orderDetailObj) {
      if (orderDetailObj.IsPickupOrder) {
        return (
          <div>
            <div id='div-mr'>
              <b>{HomeConst.PickupTime} </b>
            </div>
            <div>
              <li>{orderDetailObj.DeliveryTime}  ,  {orderDetailObj.DeliveryDate}</li>
            </div>
            <div id='div-mr'>
              <b>Confirmation Code : </b>
            </div>
            <div>
              <li><b>{orderDetailObj.PickupOrderCode}</b></li>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div id='div-mr'>
              <b>Confirmation Code : </b>
            </div>
            <div>
              <li><b>{orderDetailObj.PickupOrderCode}</b></li>
            </div>
            <div id='div-mr'>
              <b>{HomeConst.DeliveryService} </b>
            </div>
            <div>
              <li>{orderDetailObj.Deliveryoption}</li>
            </div>
            <div id='div-mr'>
              <b>{HomeConst.DeliveryAddress} </b>
            </div>
            <div>
              <li>{orderDetailObj.DeliveryAddress}</li>
            </div>
            <div id='div-mr'>
              <b>{HomeConst.DeliveryTime} </b>
            </div>
            <div>
              <li>{orderDetailObj.DeliveryTime}  , {orderDetailObj.DeliveryDate}</li>
            </div>
          </div>
        );
      }
    }
  }

  render() {
    let OrderList = this.state.childTempdata
      .map((data, index) => {
        var l = [];
        l = data.Items;
        var value = data.OrderId;

        var currencySymbol = "(" + data.Currency + ")"
        var currency = this.state.countryData.filter(e => e.Currency == data.Currency)[0]
        currencySymbol = currencySymbol + (currency ? currency.Symbol : "")

        return (
          <div class="card mb-3">
            <h6 class="card-header">
              <div className="d-flex justify-content-between">
                <span>
                  {data.OrderStatus} {!this.statusValidate(data) && (data.IsPickupOrder ? "/ Curbside Pickup" : "/ Delivery")}
                </span>
                {!data.IsPickupOrder && this.statusValidate(data) &&
                  (data.Deliveryoption == plazaCartDeliveryObj.DeliveryOptionkey ?
                    <span>
                      {HomeConst.SelfDelivery}
                    </span> :
                    <a
                      // style={{ background: Color.THEME_COLOR }}
                      value={data.OrderId}
                      onClick={() => this.dOrderStatus(data)}
                      className="theme-color liArtistClass">{HomeConst.Track} {data.Deliveryoption} {HomeConst.Status}
                    </a>)}
              </div>
            </h6>
            <div class="card-body">
              <p class="card-title">{HomeConst.OrderId} {data.OrderId}</p>
              <p class="card-text">{Moment(data.DeliveryDate).format(DatetimeFormate.DDMMYYYY)} | {l.length} | {currencySymbol} {Number.parseFloat(data.TotalAmount).toFixed(2)}</p>
              <p class="card-text">Confirmation Code : <b>{data.PickupOrderCode}</b></p>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <span
                    style={{ background: Color.THEME_COLOR }}
                    value={data.OrderId}
                    onClick={() => this.storenavigate(data)}
                    className="btn btn-row ml-0 mt-0">{HomeConst.ViewMore}</span>
                </div>
                {this.statusValidate(data) &&
                  (data.IsPickupOrder
                    ? <>
                      <div className="col-lg-4 col-md-4">
                        <Select menuPortalTarget={document.body}
                          value={this.state.selfDelfDeliveryInd == index ? this.state.selectedSelfDS : null}
                          onChange={(obj) => this.handleSelfDelivery(obj, index)}
                          options={this.state.curbsideStatus}
                          isClearable
                          styles={colourStyles}
                          placeholder={Constvariable.PickedUpByCustomer}
                          className="select-input"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <span
                          style={{ background: Color.THEME_COLOR }}
                          value={data.OrderId}
                          onClick={() => this.handleSelfDSubmit(data, index)}
                          className="btn btn-row ml-0 mt-0">{HomeConst.SubmitStatus}</span>
                      </div>
                    </>
                    : (data.Deliveryoption == plazaCartDeliveryObj.DeliveryOptionkey && <>
                      <div className="col-lg-4 col-md-4">
                        <Select menuPortalTarget={document.body}
                          value={this.state.selfDelfDeliveryInd == index ? this.state.selectedSelfDS : null}
                          onChange={(obj) => this.handleSelfDelivery(obj, index)}
                          options={this.state.selfDeliveryOption}
                          isClearable
                          styles={colourStyles}
                          placeholder={Constvariable.H_ManageDeliveryStatus}
                          className="select-input"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <span
                          style={{ background: Color.THEME_COLOR }}
                          value={data.OrderId}
                          onClick={() => this.handleSelfDSubmit(data, index)}
                          className="btn btn-row ml-0 mt-0">{HomeConst.SubmitStatus}</span>
                      </div>
                    </>)
                  )}
              </div>
            </div>
          </div>
        )

        // return (
        //   <div className='div-con' key={index}>
        //     <div className="row"> {/* marginLeft: '10px',  */}
        //       <div className="col-lg-6 col-md-6">
        //         <div>
        //           {data.OrderStatus}
        //         </div>
        //         <div>{HomeConst.OrderId} {data.OrderId}</div>
        //         <div>{Moment(data.DeliveryDate).format(DatetimeFormate.DDMMYYYY)} | {l.length} | {currencySymbol} {Number.parseFloat(data.TotalAmount).toFixed(2)}</div>
        //       </div>

        //       <div className='col-lg-2'>
        //         <span
        //           style={{ background: Color.THEME_COLOR }}
        //           value={data.OrderId}
        //           onClick={() => this.storenavigate(data)}
        //           className="btn btn-row btn-fw m-0">{HomeConst.ViewMore}</span>
        //       </div>
        //       <div className="col-lg-2">
        //         <Select menuPortalTarget={document.body}
        //           value={this.state.selectedParantCategory}
        //           onChange={this.handleParantCategory}
        //           options={this.state.parantCategory}
        //           styles={colourStyles}
        //           placeholder={Constvariable.ParantCategory}
        //           className="select-input"
        //           getOptionLabel={(option) => option.Category_name}
        //           getOptionValue={(option) => option._id}
        //         />
        //       </div>
        //       <div className="col-lg-2">
        //         <a
        //           // style={{ background: Color.THEME_COLOR }}
        //           value={data.OrderId}
        //           onClick={() => this.storenavigate(data)}
        //           className="theme-color liArtistClass">{HomeConst.Track} {data.Deliveryoption} {HomeConst.Status}
        //         </a>
        //       </div>
        //     </div>
        //   </div>
        // )
      });

    const { orderDetailObj } = this.state

    var currencySymbol = ""
    if (orderDetailObj) {
      var currency = this.state.countryData.filter(e => e.Currency == orderDetailObj.Currency)[0]
      currencySymbol = "(" + orderDetailObj.Currency + ")" + (currency ? currency.Symbol : "")
    }
    return (
      <div>
        <LoadingOverlay
          active={this.state.isActive}
          // active={true}
          text={Messages.Loading}
          spinner
          styles={{
            overlay: (base) => ({
              ...base,
              position: "fixed"
            })
          }}
          className="lodingspinner"
        >
          <Header setHeaderTitle={Constvariable.StoreOrder} />

          {/* {this.state.loaderClass ? <div id="loader"></div> : null} */}
          <nav className="nav-bar2 navbar-expand-lg">
            <div className="collapse navbar-collapse" id="navbarDefault">
              <ul>
                {/* <li
                  style={{ marginRight: '10px', marginLeft: "10px" }}
                  className={this.state.classList[0].class}
                  data-id="0"
                  onClick={this.setFilter}>
                  <a href="#">New</a>
                </li> */}
                <li
                  // style={{ marginRight: '15px' }}
                  style={{ marginRight: '10px', marginLeft: "10px" }}
                  className={this.state.classList[1].class + " liArtistClass"}
                  data-id="1"
                  onClick={() => this.setFilter(tabIndex.first)}>
                  <a tabIndex="#">{HomeConst.Pending}</a>
                </li>
                <li
                  // style={{ marginRight: '15px' }}
                  style={{ marginRight: '10px', marginLeft: "10px" }}
                  className={this.state.classList[2].class + " liArtistClass"}
                  data-id="2"
                  onClick={() => this.setFilter(tabIndex.second)}>
                  <a tabIndex="#">{HomeConst.InProcess}</a>
                </li>
                <li
                  // style={{ marginRight: '15px' }}
                  style={{ marginRight: '10px', marginLeft: "10px" }}
                  className={this.state.classList[3].class + " liArtistClass"}
                  data-id="3"
                  onClick={() => this.setFilter(tabIndex.third)}>
                  <a tabIndex="#">{HomeConst.Completed}</a>
                </li>
                <li
                  style={{ marginRight: '10px', marginLeft: "10px" }}
                  className={this.state.classList[4].class + " liArtistClass"}
                  data-id="4"
                  onClick={() => this.setFilter(tabIndex.forth)}>
                  <a tabIndex="#">{HomeConst.All}</a>
                </li>
              </ul>
            </div>
          </nav>
          {this.state.navigateList ?
            <section className="section newest-post-main">
              <div className="container">
                <div className="content-wrap">
                  <div
                    id="new"
                    className={this.state.classList[0].div}>
                    {/* <OrderList data={this.state.childTempdata}
                countryData={this.state.countryData} /> */}
                    {OrderList}
                  </div>
                  {/* <div
            id="pending"
            className={this.state.classList[1].div}>
          </div>
          <div
            id="inprocess"
            className={this.state.classList[2].div}>
          </div>
          <div
            id="completed"
            className={this.state.classList[3].div}>
          </div>
          <div
            id="all"
            className={this.state.classList[4].div}>
          </div> */}
                </div>
                {this.state.tempdata.length === 0 && <div className="reg_form_main col-sm-12">
                  <center>{HomeConst.DataNotFound}</center>
                </div>}
                {this.state.tempdata.length > 0 && <div className="reg_form_main col-sm-12">
                  <hr style={{ border: "1px solid #ccc" }} />
                </div>}

                {this.state.tempdata.length > 0 && <div className="reg_form_main col-sm-12" style={{ marginTop: "0px" }}>
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.tempdata.length}
                    pageRangeDisplayed={3}
                    onChange={(e) => this.handlePageChange(e)}
                  />
                </div>}
              </div>
            </section>
            :
            (
              orderDetailObj &&
              <div className="container">
                <div className='row'>
                  <div className="col-sm-7">
                    {HomeConst.OrderId}<b> {orderDetailObj.OrderId} </b>
                  </div>
                  <div class="col-sm-4">
                    {HomeConst.Accountinfo} <b> {/* {this.state.data.CustomerName} */} <p>{orderDetailObj.CustomerEmail} , {orderDetailObj.PhoneNumber}</p></b>
                  </div>
                </div>
                <hr id='hr-top' />

                <div style={{ overflowX: "auto" }}>
                  <table>
                    <tr>
                      <th>{HomeConst.No}</th>
                      <th>{HomeConst.Product}</th>
                      <th>{HomeConst.Quantity}</th>
                      <th>{HomeConst.Price}</th>
                      {/* {this.state.orderStatus === 'PENDING' && <th></th>} */}
                    </tr>
                    {orderDetailObj && orderDetailObj.Items &&
                      orderDetailObj.Items
                        .map(function (data, index) {
                          return (
                            <tr>
                              <td> {(index + 1)}</td>
                              <td> {data.ItemName}</td>
                              <td>{data.ItemQuantity}</td>
                              <td> {currencySymbol} {Number.parseFloat(data.TotalItemPrice).toFixed(2)}</td>
                              {/* <td> {this.state.orderStatus === "PENDING" &&
                                <Checkbox
                                  color={Color.THEME_COLOR}
                                  className="checkbox-component"
                                  size={2}
                                  tickSize={2}
                                  checked={data.IsConfirm}
                                  delay={0}
                                  backAnimationDuration={0}
                                  tickAnimationDuration={0}
                                  onChange={() => this.handleCheck(data)}></Checkbox>
                              }</td> */}
                            </tr>
                          )
                        }, this)
                    }

                  </table>
                </div>

                <hr />
                {this.renderTextDetail(currencySymbol)}

                {this.getispuckup()}

                <div id="div-delivery">
                  <div className="row">

                    {this.statusValidateInitial(orderDetailObj) && <div className="col-lg-3 col-md-4 col-sm-12" style={{ margin: "4px 0px" }}>
                      <label className="btn btn-skip"
                        onClick={() => this.ordercall()}
                        style={{ background: Color.THEME_COLOR, width: "100%", padding: "10px 0px" }}>
                        {HomeConst.btn_Acceptorder}
                      </label>
                    </div>}

                    {this.statusValidate(orderDetailObj) && (orderDetailObj.IsPickupOrder ?
                      <div className="col-lg-4 col-md-4 col-sm-12" style={{ margin: "4px 0px" }}>
                        <Select menuPortalTarget={document.body}
                          value={this.state.formSelectedSelfDS}
                          onChange={(obj) => this.handleFormSelfDelivery(obj)}
                          options={this.state.curbsideStatus}
                          isClearable
                          styles={colourStyles}
                          placeholder={Constvariable.PickedUpByCustomer}
                          className="select-input"
                        />
                      </div>
                      :
                      (orderDetailObj.Deliveryoption == plazaCartDeliveryObj.DeliveryOptionkey && <div className="col-lg-4 col-md-4 col-sm-12" style={{ margin: "4px 0px" }}>
                        <Select menuPortalTarget={document.body}
                          value={this.state.formSelectedSelfDS}
                          onChange={(obj) => this.handleFormSelfDelivery(obj)}
                          options={this.state.selfDeliveryOption}
                          isClearable
                          styles={colourStyles}
                          placeholder={Constvariable.H_ManageDeliveryStatus}
                          className="select-input"
                        />
                      </div>)
                    )}


                    {/* {this.statusValidate(orderDetailObj) && (orderDetailObj.Deliveryoption != plazaCartDeliveryObj.DeliveryOptionkey) &&
                      <div className="col-lg-3 col-md-4 col-sm-12 align-self-center" style={{ margin: "4px 0px" }}>
                        <a
                          // style={{ background: Color.THEME_COLOR }}
                          // value={data.OrderId}
                          onClick={() => this.dOrderStatus(orderDetailObj)}
                          className="theme-color liArtistClass">{HomeConst.Track} {orderDetailObj.Deliveryoption} {HomeConst.Status}
                        </a>
                      </div>
                    } */}

                    {this.statusValidate(orderDetailObj) && (orderDetailObj.IsPickupOrder ?
                      <div className="col-lg-3 col-md-4 col-sm-12" style={{ margin: "4px 0px" }}>
                        <label className="btn btn-skip"
                          onClick={() => this.orderCallwithDelivery()}
                          style={{ background: Color.THEME_COLOR, width: "100%", padding: "10px 0px" }}>
                          {HomeConst.btn_ChangeStatus}
                        </label>
                      </div>
                      :
                      ((orderDetailObj.Deliveryoption == plazaCartDeliveryObj.DeliveryOptionkey) && <div className="col-lg-3 col-md-4 col-sm-12" style={{ margin: "4px 0px" }}>
                        <label className="btn btn-skip"
                          onClick={() => this.orderCallwithDelivery()}
                          style={{ background: Color.THEME_COLOR, width: "100%", padding: "10px 0px" }}>
                          {HomeConst.btn_ChangeStatus}
                        </label>
                      </div>))
                    }

                    {(this.statusValidateInitial(orderDetailObj) || this.statusValidate(orderDetailObj)) && <div className="col-lg-3 col-md-4 col-sm-12" style={{ margin: "4px 0px" }}>
                      <label
                        style={{ background: Color.THEME_COLOR, width: "100%", padding: "10px 0px" }}
                        onClick={() => this.ordercancel()}
                        className="btn btn-done">
                        {HomeConst.btn_Cancelorder}
                      </label>
                    </div>}

                  </div>
                </div>
              </div>
            )}
          <div className="reg_form_main offset-sm-3 col-sm-6">
            <center>
              <div className="errorMsg">
                {this.state.errors.ErrorC}
              </div>
            </center>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default HomeShopify;