import React from 'react';
import { GetData } from '../../services/GetData';
import { GET_PROFILE } from '../Config/Config.json';
import Constants from '../Config/Constants.js';
import { RetriveDataFromStorage, logoutSession, userSessionManger } from "../Config/Utils";
import MasterContextCreater from './MasterContextCreater';

export default class MasterContextProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getProfileDetail(authToken);
    }

    getProfileDetail(authToken) {
        var API = GET_PROFILE;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("user profile responseData ", responseData);
                }
                else if (responseJson["StatusCode"] == 401) {
                    if (userSessionManger()) {
                        logoutSession()
                        window.location.href = "/"
                    }
                }
                else {

                }
            });
    }

    render() {
        return (
            <MasterContextCreater.Provider value={{
                ...this.state,
                setStateByChild: (ref) => this.setStateByChild(ref)
            }}>
                {this.props.children}
            </MasterContextCreater.Provider>
        )
    }
}