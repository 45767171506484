import React, { Component } from 'react';
import plazacartLogo from "../../images/logo.png";
import { PrivacyAndPolicy } from "../Config/constvariable";
import MainFooter from '../MainFooter/MainFooter';
import MainHeader from '../MainHeader/MainHeader';
import Color from '../Config/Color';
import RouteMerchant from '../Config/RouteMerchant';
import {COMPANYCONST} from '../Config/Constants'

export default class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div class="hero_area">
                    <MainHeader />
                    <section className="section">
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                            <div className="reg_form_main">
                                {/* <center><img src={plazacartLogo} className="col-sm-4"></img></center> */}
                                <center><p id="instruction" className="textcolor" style={{ marginBottom: '10px' }}>{PrivacyAndPolicy.screenTitle}</p> </center>
                            </div>
                            <div class="midsection pl-2 pr-2 pl-sm-0 pr-sm-0 pages" style={{ textAlign: 'justify' }}>
                                <div class="Justify_Font_11">
                                    {/* <p class="font_bold"></p>
                                    <p><strong>plazacart</strong> is a leading provider of software and services.</p>
                                    <p>plazacart is headquartered in Missisauga Ontario, Canada. We support our customers with a full range of managed and professional computing services, and collaborate with an array of business partners.</p>
                                    <p>plazacart is committed to protecting any personal information we collect. To this end, we have prepared this Privacy Policy to communicate our ongoing commitment to ensuring personal information remains accurate, confidential and used only for identified purposes, regardless of how it is provided to us.</p>
                                    <p>plazacart believes that having a relationship with our customers that is founded on trust is not merely the law but also good business. We believe in giving full disclosure about the kinds of information we gather and what we do with it. We are committed to ensuring that privacy of our customers and visitors is protected. We are responsible for the personal information we collect, use, maintain and disclose. To ensure this accountability, we have developed this Privacy Policy, and have provided training to our employees on how to properly implement our privacy policies and practices.</p>
                                    <p class="font_bold">What is "Personal Information?"</p>
                                    <p>The term "Personal Information" refers to any information that identifies an individual or by which an identity could be derived but does not include corporate or business contact information about an identifiable individual. Personal information also excludes aggregated information that is no longer related to a specific identifiable individual. plazacart reserves the right to use aggregated or anonymous information in any way it considers appropriate. This includes anonymous or aggregated information that we obtain from you as a visitor to our web site or by using our programs and software.</p>
                                    <p class="font_bold">Collected Personal Information</p>
                                    <p>plazacart collects personal information from our customers and visitors for a variety of reasons. We may collect personal information in the furtherance of our contractual obligations.</p>
                                    <p>In serving the needs of our visitors and customers, plazacart believes that it is important to have accurate and timely information. Therefore, we may collect information through our website, personal contact and through the use of our software solutions. The kinds of personal information we will ask for may include your name, e-mail address, phone number, address, type of business, as well as such other personal information that is needed to fulfill the purposes of a particular statement of work or to further its obligations under a particular program or as identified in this Privacy Policy. Sometimes we will ask for other personal information to fulfill the needs of the particular application or service agreement requested and authorized by our visitors and customers.</p>
                                    <p>We do not solicit customers of our clients, unless authorized specifically in writing to do so by the client.</p>
                                    <p>We also collect and store statistics and other information about the online activities of visitors to the plazacart web site or through the use of plazacart's software solutions. These statistics do not contain individual identifying information and constitute aggregated or anonymous data only.</p>
                                    <p>Customers may be invited to receive an email newsletter by providing an email address. Customer email addresses and any personal customer information will not be distributed or shared with third parties. Customers can opt out of being contacted by us, or receiving such information from us, at any time by sending an email to <strong>privacyofficer@plazacart.com</strong></p>
                                    <p>Business customers may be using our products and services in connection with the use of  data and information about their customers and prospects ("Data"). Sometimes we may also obtain Data from other sources, a government agency or registry, or other individuals or organizations that our customers have  provided informed consent to contact. plazacart will not review, share, distribute, print, or reference any such Data except as provided in the agreement between us and our customer (the "Agreement"), or as may be required by law. The agreement between plazacart and our business customer may provide for the use of non-identifying information to identify and analyze data for business purposes. In no circumstance will the Personal Information of a specific end-user be transferred, sold, or shared with a third party without their express written consent. </p>
                                    <p>Individual records may at times be viewed or accessed only for the purpose of resolving a problem, support issue, or suspected violation of the Agreement, or as may be required by law. Of course, customers are responsible for maintaining the confidentiality and security of their user registrations and passwords. </p>
                                    <p class="font_bold">Consent</p>
                                    <p>Consent for the collection, use and/or disclosure of personal information may be obtained orally, electronically or in writing and may be expressly given or implied. In determining how we obtain consent, we will take into account the sensitivity or the personal information that we are collecting, using and/or disclosing. </p>
                                    <p>We may use personal information to provide products or services, including for billing and credit purposes.  Other uses of personal information may include the detection of fraud, security or technical issues, and to protect against imminent harm to the rights, property, or safety of plazacart, or the public as permitted or required by law. We will not send any further material to anyone that informs us that they no longer wish to receive information about our services or about new developments.</p>
                                    <p class="font_bold">Disclosure of Personal Information</p>
                                    <p>Under certain circumstances, we may disclose personal information when we are required by law to do so or when we have consent for the disclosure. We also reserve the right to disclose personal information to third parties to help collect payment for any product purchase or service. Personal information may also be transferred to third-party services providers for administrative purposes (e.g. computer back-up service) and the third party is bound by an Agreement and our Privacy Policy. Public information may also be freely disclosed unless you have requested that it not be disseminated.</p>
                                    <p class="font_bold">Security of Your Personal Information</p>
                                    <p>We take all reasonable precautions to ensure that personal information is kept safe from loss, unauthorized access, modification, or disclosure. We operate on a secure data network protected by an industry standard firewall and password protected systems. Our security and privacy policies are periodically reviewed and enhanced, and only authorized individuals at plazacart have access to personal information unless we have consent for access by a third party through an agreement.</p>
                                    <p>plazacart employs industry standard technological safeguards such as network security software and hardware firewalls to prevent hacking or unauthorized computer access. Personal Information is password protected on all of our internal networks and continuously monitored by full-time network security personnel. All employees and third parties, including licensed users of our software applications that have access to personal information collected by us, are required to sign a confidentiality agreement in relation to this Privacy Policy.</p>
                                    <p class="font_bold">Links to Other Websites or Services</p>
                                    <p>Our website contains links to other websites. plazacart is not responsible for the privacy practices or the content of such websites. plazacart is not responsible for third-party actions and disclosure of personal information by such third parties.</p>
                                    <p class="font_bold">Email Addresses and Cookies</p>
                                    <p>plazacart recognizes that SPAM is a growing problem on networks of all sizes and we are doing our part to prevent its spread. To prevent the growth of SPAM and in accordance with applicable laws, plazacart will not share or sell email addresses to mass marketers or to other third parties.</p>
                                    <p>Cookies are pieces of data assigned by a web server and stored on a computer's hard drive for later retrieval. We do not use cookies.</p>
                                    <p class="font_bold">Retention of Your Personal Information</p>
                                    <p>plazacart keeps personal information for as long as is reasonably necessary for us to complete the objectives set out at the time of collecting the information. When performing services we may retain personal information as long a reasonably necessary to fulfill our contractual obligations. We will retain personal information for as long as our Agreement with our customer requires, or as may be required by law, whichever is longer.</p>
                                    <p class="font_bold">Changes to this Privacy Policy</p>
                                    <p>Since plazacart regularly reviews all of its policies and procedures, plazacart may change its Privacy Policy from time to time. All changes to this policy will be posted promptly.</p>
                                    <p class="font_bold">Request for Access and Inquiries/Complaints</p>
                                    <p>If you have any questions please contact our privacy officer at: <strong>privacyofficer@plazacart.com</strong>.</p> */}

                                    <p><strong>{COMPANYCONST.BREWERSNEARMECOMCAP} PRIVACY POLICY</strong></p>
                                    <p><strong>I. Introduction / II. Overview / III. Data collections and uses / IV. Choice and transparency / V. Terms for certain jurisdictions / VI. Updates to this policy</strong></p>
                                    <p><strong>I. Introduction</strong></p>
                                    <p>When you use {COMPANYCONST.BREWERSNEARMECOMCAP}, you trust us with your personal data. We&rsquo;re committed to keeping that trust. That starts with helping you understand our privacy practices.</p>
                                    <p>We recommend that you read this policy to learn more about the personal data we collect, how it&rsquo;s used and shared, and your choices regarding this data.</p>
                                    <p>Use of {COMPANYCONST.BREWERSNEARMECOMCAP}, websites, products or other services made available is at all times subject to our Terms of Use or Shopper Terms and Conditions, as applicable, each of which incorporates this Privacy Policy. Any terms we use in this Privacy Policy without defining them have the definitions given to them in the&nbsp;<a style={{ color: Color.THEME_COLOR }} href={RouteMerchant.TermsOfService} target="_blank">Terms of Use</a>&nbsp;or&nbsp;<a style={{ color: Color.THEME_COLOR }} href={RouteMerchant.TermsOfService} target="_blank">Shopper Terms and Conditions</a>, as applicable.</p>
                                    <p>Last modified: <strong>January 10, 2023</strong></p>
                                    <p>Effective date: <strong>January 11, 2023</strong></p>
                                    <p><strong>II. Overview</strong></p>

                                    <p><strong>A. Scope</strong></p>
                                    <p>This policy describes how {COMPANYCONST.BREWERSNEARMECOMCAP} and its affiliates collect and use personal data. This policy applies to all users of our apps, websites, features, or other services anywhere in the world.</p>
                                    <p>Our practices are subject to applicable laws in the places in which we operate. This means that we engage in the practices described in this policy in a particular country or region only if permitted under the laws of those places.</p>
                                    <p>This policy also governs {COMPANYCONST.BREWERSNEARMECOMCAP} other collections of personal data in connection with its services. For example, we may collect the contact information of owners or employees of breweries or other merchants.</p>
                                    <p>All those subject to this policy are referred to as &ldquo;users&rdquo; in this policy.</p>

                                    <p><strong>B. Data controller and transfer</strong></p>
                                    <p>{COMPANYCONST.BREWERSNEARMECOMCAP} Technologies, Inc. is the data controller for the personal data collected in connection with accessing or using the services.</p>
                                    <p>Questions, comments, and complaints about {COMPANYCONST.BREWERSNEARMECOMCAP} data practices can be submitted to our Data Protection Officer at <a href={"mailto:"+COMPANYCONST.PLAZACARTMAIL} style={{ color: Color.THEME_COLOR }}>{COMPANYCONST.PLAZACARTMAIL}</a>.</p>

                                    <p><strong>III. Personal data collections and uses</strong></p>

                                    <p><strong>A. Sources of personal data</strong></p>
                                    <p>The following personal data is collected by or on behalf of {COMPANYCONST.BREWERSNEARMECOMCAP}:</p>
                                    <ol>
                                        <li><strong> Data provided by users. This includes:</strong></li>
                                        <p>when users provide such information directly to us, and when personal data about users is automatically collected in connection with access to or use of the services, such as through our use of cookies when users use our Platform.</p>
                                        <li><strong> Data provided by our subsidiaries and affiliates</strong></li>
                                        <p>when they provide us with personal data about users. For example, when users purchase items through the {COMPANYCONST.BREWERSNEARME} apps, the order may be processed through the Platform and {COMPANYCONST.BREWERSNEARME} will provide certain personal data to us in order to complete the transaction. For more information about how we may share personal data with {COMPANYCONST.BREWERSNEARME}, please see our&nbsp;<a
                                            style={{ color: Color.THEME_COLOR }}
                                            href={COMPANYCONST.PrivacyPolicyURL} target="_blank" >Data Sharing Policy</a>.</p>
                                        <li><strong> Data provided by third parties</strong></li>
                                        <p>when they provide us with personal data about users. Third Parties that share user personal data with us include service providers. For example, we may use analytics service providers to analyze how users interact and engage with the services, or third parties may help us provide users with customer support.</p>
                                        <li><strong> Data provided by networks connected to our Platform</strong></li>
                                        <p>when users provide your third-party account credentials to us or otherwise sign in to access or use the services through a third-party site or service (such as Facebook/Google), some content and/or information in those accounts may be transmitted into a user&rsquo;s account with us.</p>
                                    </ol>

                                    <p><strong>B. Categories of personal data</strong></p>
                                    <p>The following categories of personal data are collected by or on behalf of {COMPANYCONST.BREWERSNEARMECOMCAP}:</p>
                                    <ul>
                                        <li>Personal identifiers, including name, email address, telephone number, IP address, delivery address/current location and Cookies. We collect personal identifiers from users, third parties and affiliates.</li>
                                        <li>Information protected against security breaches under state law, including name, email address, telephone number, IP address, delivery address/current location, Cookies and payment information (not including card number). We collect such information from users, third parties and affiliates.</li>
                                        <li>Commercial information, including purchase details and history. We collect commercial information from users.</li>
                                        <li>Internet or other similar network activity information, including Interactions with the Platform, Cookies, device data (such as device ID, manufacturer, operating system, language), IP address, and browsing, search, and purchase history.</li>
                                        <li>Geolocation data, including current location, which you can choose whether or not to share. We collect geolocation data from users.</li>
                                        <li>Sensory data, including customer service calls (voice recordings), which we collect from users.</li>
                                    </ul>

                                    <p><strong>C. How we use personal data</strong></p>
                                    <p>We use personal data to operate, improve, understand and personalize the services, including:</p>
                                    <ul>
                                        <li>To meet or fulfill the reason you provided the information to us.</li>
                                        <li>To communicate with you about the services, including service announcements, updates or offers.</li>
                                        <li>To provide support and assistance for the services.</li>
                                        <li>To create and manage your account.</li>
                                        <li>To personalize website content and communications based on your preferences, including targeted offers and ads served through the services.</li>
                                        <li>To process orders or other transactions, including payment transactions.</li>
                                        <li>To respond to user inquiries and fulfill user requests.</li>
                                        <li>To improve and develop the services and Platform, including testing, research, analysis and product development.</li>
                                    </ul>
                                    <ul>
                                        <li>To protect against or deter fraudulent, illegal or harmful actions and maintain the safety, security and integrity of the services.</li>
                                        <li>To comply with our legal or contractual obligations, resolve disputes, and enforce our Terms of Use.</li>
                                        <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                                        <li>For any other business purpose stated when collecting your personal data or as otherwise set forth in applicable data privacy laws.</li>
                                    </ul>

                                    <p><strong>D. Cookies and third-party technologies</strong></p>
                                    <p>{COMPANYCONST.BREWERSNEARMECOMCAP} uses cookies and similar technologies such as pixel tags, web beacons, clear GIFs, and JavaScript (collectively, &ldquo;Cookies&rdquo;) to enable our servers to recognize your web browser and tell us how and when you visit and use the services, to analyze trends, learn about our user base and operate and improve the services. Cookies are small pieces of data&ndash; usually text files &ndash; placed on your computer, tablet, phone, or similar device when you use that device to visit the Platform. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Platform does not support &ldquo;Do Not Track&rdquo; requests sent from a browser at this time.</p>
                                    <p>We use the following types of Cookies:</p>
                                    <ul>
                                        <li>Essential Cookies, which are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of the Platform. Disabling these Cookies may make certain features and services unavailable.</li>
                                        <li>Functional Cookies, which are used to record your choices and settings regarding the services, maintain your preferences over time and recognize you when you return to the Platform. These Cookies help us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).</li>
                                        <li>Performance/Analytical Cookies, which allow us to understand how visitors use the services such as by collecting information about the number of visitors to the Platform, what pages visitors view on the Platform and how long visitors are viewing pages on the Platform. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the services&rsquo; content for those who engage with our advertising.</li>
                                    </ul>
                                    <p>Google, Inc. (&ldquo;Google&rdquo;) uses cookies in connection with its Google Analytics services. Google&rsquo;s ability to use and share information collected by Google Analytics about your visits to the Platform is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google&rsquo;s use of cookies by visiting the Google advertising opt-out page&nbsp;<a href="https://policies.google.com/technologies/ads" style={{ color: Color.THEME_COLOR }} target="_blank">https://policies.google.com/technologies/ads</a>&nbsp;or the Google Analytics Opt- out Browser Add-on at&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://tools.google.com/dlpage/gaoptout/" target="_blank">https://tools.google.com/dlpage/gaoptout/</a></p>

                                    <p>You can decide whether or not to accept Cookies through your internet browser&rsquo;s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your computer. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.</p>
                                    <p>To explore what Cookie settings are available to you, look in the &ldquo;preferences&rdquo; or &ldquo;options&rdquo; section of your browser&rsquo;s menu. To find out more information about Cookies, including information about how to manage and delete Cookies, please visit&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://www.allaboutcookies.org/" target="_blank">https://www.allaboutcookies.org/</a></p>
                                    <p>For mobile users, you have controls in your device's operating system that enable you to choose whether to allow cookies, or share your advertising ID with companies like {COMPANYCONST.BREWERSNEARMECOMCAP}&nbsp; or our advertising service providers. For information on controlling your mobile choices, you can visit&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://thenai.org/opt-out/mobile-opt-out/" target="_blank">https://thenai.org/opt-out/mobile-opt-out/</a></p>

                                    <p><strong>E. Data sharing and disclosure</strong></p>
                                    <p><strong>1. Purposes of data disclosure</strong></p>

                                    <p>{COMPANYCONST.BREWERSNEARMECOMCAP} may share personal data for the purposes of:</p>
                                    <ul>
                                        <li>Auditing related to a current interaction and concurrent transactions.</li>
                                        <li>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.</li>
                                        <li>Debugging to identify and repair errors that impair existing intended functionality.</li>
                                        <li>Short-term, transient use of personal data that is not used by another party to build a consumer profile or otherwise alter your consumer experience outside the current interaction.</li>
                                        <li>Performing services on our behalf, a retailer brewery&rsquo;s behalf or an advertiser&rsquo;s behalf, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider.</li>
                                        <li>Undertaking internal research for technological development and demonstration.</li>
                                        <li>Undertaking activities to verify or maintain the quality or safety of a service or device that we own or control.</li>
                                        <li>Complying with any legal obligation or regulatory requirement.</li>
                                        <li>Enforcing our contractual terms with users and any other agreement.</li>
                                        <li>Protecting our rights or the rights of others, including to prevent fraud or other crimes.</li>
                                    </ul>
                                    <p><strong>2. Recipients of personal data</strong></p>
                                    <p>{COMPANYCONST.BREWERSNEARMECOMCAP} may share personal data with:</p>
                                    <ul>
                                        <li>Service providers, including:
                                            <ul>
                                                <li>Payment processors</li>
                                                <li>Security and fraud prevention consultants</li>
                                                <li>Legal and other professional advisors</li>
                                                <li>Hosting and other technology and communications providers</li>
                                                <li>Analytics providers</li>
                                                <li>Contract personnel</li>
                                            </ul>
                                        </li>
                                        <li>Parties who acquire your personal data through an acquisition or other change of control. Personal data may be transferred to a third party if we undergo a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of {COMPANYCONST.BREWERSNEARMECOMCAP} assets, whether as a going concern (such as through an acquisition), or as part of a bankruptcy, liquidation, or similar proceeding or transaction in which that third party assumes control of our business (in whole or in part).</li>
                                        <li>Other parties at your direction or with your consent, including:
                                            <ul>
                                                <li>Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the services), which may include Shoppers and/or drivers.</li>
                                                <li>Social media and other third-party services (if you intentionally interact with them through your use of the services, such as by linking your {COMPANYCONST.BREWERSNEARMECOMCAP} accounts).</li>
                                                <li>Third-party business partners who you access through the services, such as retail breweries and advertisers.</li>
                                                <li>Other parties authorized by you.</li>
                                            </ul>
                                        </li>
                                        <li>Affiliates, including {COMPANYCONST.BREWERSNEARMECOMCAP} parent company and subsidiaries, as well as other entities under common ownership or control with {COMPANYCONST.BREWERSNEARMECOMCAP} . This may include entities owned or controlled by {COMPANYCONST.BREWERSNEARME}, which may use the data for their own purposes, for example to display your order history and status in the {COMPANYCONST.BREWERSNEARME} app, when you purchase items through the {COMPANYCONST.BREWERSNEARME} app.</li>
                                        <li>Parties that require personal data to be disclosed for legal reasons (such as pursuant to subpoenas or other legal process).</li>
                                    </ul>

                                    <p><strong>F. Data retention</strong></p>
                                    <p>We retain personal data about you for as long as you have an open account with us or as otherwise necessary to provide you services or fulfil the purposes for which we have collected the personal data, and afterwards for six (6) months to respond to any follow-up questions or concerns relating to your account. Thereafter, we may retain personal data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. Afterwards, we may retain some information in a depersonalized or aggregated form but not in a way that would identify you personally.</p>

                                    <p><strong>G. Account safety</strong></p>
                                    <p>We recommend appropriately selecting and protecting your password and/or other sign-on mechanism, limiting access to your computer or device and browser, and signing off after you have finished accessing your account to help keep your account safe. Although we work to protect the privacy of your account and other personal data that we hold in our records, no method of transmitting data over the Internet or storing data is completely secure. We cannot guarantee the complete security of any personal data you share with us, and we are not responsible for the theft, destruction, loss or inadvertent disclosure of your information or content except as otherwise required under applicable law.</p>

                                    <p><strong>IV. Choice and transparency</strong></p>
                                    <p><strong>A. Device permissions</strong></p>
                                    <p>Most mobile device platforms (IOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner&rsquo;s permission, and these platforms have different methods for how that permission can be obtained. Please check the available settings on your device or check with your provider.</p>
                                    <p><strong>B. Marketing and notification choices</strong></p>
                                    <p>We may communicate with you if you&rsquo;ve provided us the means and any legally required consent to do so. For example, if you&rsquo;ve given us your email address, we may send you promotional email offers or email you about your use of the services. If you want to revoke your consent, or do not want to receive communications from us, please indicate your preference by changing your account settings at&nbsp;<a href={COMPANYCONST.PrivacyPolicyURL1} style={{ color: Color.THEME_COLOR }} target="_blank">{COMPANYCONST.PrivacyPolicyURL1}</a>. You may also opt out of receiving emails and other messages from {COMPANYCONST.BREWERSNEARMECOMCAP} by following the unsubscribe instructions in those messages. Please note that if you decline to receive essential account communications (such as receipts), you may no longer be able to use some or all features of the services.</p>
                                    <p><strong>C. User personal data requests</strong></p>
                                    <p>Users may submit a personal data request using the following methods:</p>
                                    <ul>
                                        <li>Submit a&nbsp;<a style={{ color: Color.THEME_COLOR }} href={COMPANYCONST.PrivacyPolicyURL2} target="_blank">request to delete your data</a></li>
                                        <li>Submit a&nbsp;<a style={{ color: Color.THEME_COLOR }} href={COMPANYCONST.PrivacyPolicyURL3} target="_blank">request to know your data</a></li>
                                    </ul>
                                    <p>You may also authorize an agent to exercise your rights on your behalf. To do this, you must provide your agent with written permission to exercise your rights on your behalf, and we may request a copy of this written permission from you or your authorized agent when they make a request on your behalf.</p>

                                    <p><strong>V. Terms for certain jurisdictions</strong></p>
                                    <p><strong>B. For California users</strong></p>
                                    <p>California residents may request that {COMPANYCONST.BREWERSNEARMECOMCAP}:</p>
                                    <ul>
                                        <li>Disclose the sources, categories, and specific pieces of personal information we have collected about them, how that information is used including the purpose, and with whom {COMPANYCONST.BREWERSNEARMECOMCAP} shares it</li>
                                        <li>Delete their personal information</li>
                                        <li>Disclose, for any &ldquo;sales&rdquo; of personal information under the CCPA, the categories of personal information collected and sold and to what categories of third parties it was sold</li>
                                        <li>Opt them out of sales of their personal information (if any)</li>
                                        <li>Provide a copy of their personal information in a readily usable format that allows the information to be transmitted to others</li>
                                        <li>California residents may not be discriminated against for exercising any of the rights described above.</li>
                                    </ul>
                                    <p>California residents may exercise their rights through the links below:</p>
                                    <ul>
                                        <li>Request&nbsp;<a style={{ color: Color.THEME_COLOR }} href={COMPANYCONST.PrivacyPolicyURL2} target="_blank">deletion of your account</a></li>
                                        <li>Request an&nbsp;<a style={{ color: Color.THEME_COLOR }} href={COMPANYCONST.PrivacyPolicyURL3} target="_blank">export of your data</a></li>
                                        <li><a style={{ color: Color.THEME_COLOR }} href={COMPANYCONST.PrivacyPolicyURL4} target="_blank">Opt out of sale of your personal information</a>. {COMPANYCONST.BREWERSNEARMECOMCAP} does not sell your data &mdash; we are not data brokers, and we don&rsquo;t put your data on the open market. However, under the CCPA, some sharing of personal information necessary to provide you with personalized ads may be considered a &ldquo;sale,&rdquo; even if no money is exchanged. You may opt-out of such sharing using the link above. If you opt out, {COMPANYCONST.BREWERSNEARMECOMCAP} will stop sharing your information with some of its advertising partners, and you will no longer receive personalized offers from {COMPANYCONST.BREWERSNEARMECOMCAP} on some sites or services.</li>
                                    </ul>
                                    <p><strong>VI. Updates to this policy</strong></p>
                                    <p>We may occasionally update this policy. If we make significant changes, we will notify users in advance of the changes through the {COMPANYCONST.BREWERSNEARMECOMCAP} app or through other means, such as email. We encourage users to periodically review this policy for the latest information on our privacy practices.</p>
                                    <p>Use of our services after an update constitutes consent to the updated policy to the extent permitted by law.</p>
                                    <p>&nbsp;</p>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <MainFooter />
            </div>
        );
    }
}