import React, { Component } from 'react';
import StoreList from "../StoreList/StoreList";
import Messages from "../Config/Messages";
import Constants, { duratoinMinList, durationMinKey, DateRangeT, APPOINTMENTH, DEFAULTID } from '../Config/Constants'
import Constvariable, { DatetimeFormate, appointmentStatus } from '../Config/constvariable';
import Select from 'react-select';
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage, removeSpcesFromString } from "../Config/Utils";
import { GetData } from "../../services/GetData";
import { GET_TOPPING_CATEGORY, MY_STORE_API, GET_PICKUP_TIME, PLAZAID, STOREHOURS_API, SETUPSTOREHOURS_API, APPOIMENT_APi, OPEN_API, LIST_API, UPDATESTATUS } from '../Config/Config.json'
import Header from '../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import { PostData } from '../../services/PostData';
import { PUTData } from '../../services/PUTData'
import Color from '../Config/Color';
import { DeleteData } from '../../services/DeleteData';
import RouteMerchant from '../Config/RouteMerchant.js';
import OnHoverIconInfo from "../UtillCustomComponent/UtillCustomComponent"
import { StoreHourConst, SetupAppointmentConst } from '../Config/ConstForm';
import { StoreHourList } from '../Config/HoverMessage';
import { DatasetDuration } from "../UtillCustomComponent/UtillCustomComponent";
import { PutMandatoryfieldSymbol, dateFormating } from '../UtillCustomComponent/UtillCustomComponent';
import './SetAppointment.css'
import { validateAppointment } from './SetAppointmentUtility'
import Moment from "moment"

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "1080" })
};

const meridian = [{ value: 'AM', label: 'AM' }, { value: 'PM', label: 'PM' }]
let clock = []

for (var i = 1; i <= 12; i++) {
    if (i <= 9) {
        clock.push({ value: "0" + String(i) + " " + meridian[0].value, label: "0" + String(i) + " " + meridian[0].value })
    }
    else if (i > 9 && i < 12) {
        clock.push({ value: String(i) + " " + meridian[0].value, label: String(i) + " " + meridian[0].value })
    }
    else[
        clock.push({ value: String(i) + " " + meridian[1].value, label: String(i) + " " + meridian[1].value })
    ]
}

for (var i = 1; i <= 12; i++) {
    if (i <= 9) {
        clock.push({ value: "0" + String(i) + " " + meridian[1].value, label: "0" + String(i) + " " + meridian[1].value })
    }
    else if (i > 9 && i < 12) {
        clock.push({ value: String(i) + " " + meridian[1].value, label: String(i) + " " + meridian[1].value })
    }
    else[
        clock.push({ value: String(i) + " " + meridian[0].value, label: String(i) + " " + meridian[0].value })
    ]
}

const dats = [
    {
        "_id": "637f43f76c01c33791c8d543",
        "customerid": "63636b1d23877376d5d5ed41",
        "customerfirstname": null,
        "customerlastname": null,
        "customeremail": null,
        "storeid": "000000000000000000000000",
        "storename": null,
        "storeaddress": null,
        "date": "2022-11-23T18:30:00Z",
        "starttime": "9am",
        "endtime": "5pm",
        "customernotes": "customernotes",
        "merchantnotes": null,
        "appoimentstatus": "PENDING",
        "Status": null,
        "CreatedDate": "2022-11-24T10:14:15.577Z",
        "ModifiedDate": "2022-11-24T10:14:15.577Z"
    },
    {
        "_id": "637f44586c01c33791c8d545",
        "customerid": "62e3e993482e856900691934",
        "customerfirstname": "T",
        "customerlastname": "J",
        "customeremail": "tj24618@gmail.com",
        "storeid": "000000000000000000000000",
        "storename": null,
        "storeaddress": null,
        "date": "2022-11-23T18:30:00Z",
        "starttime": "9am",
        "endtime": "5pm",
        "customernotes": "customernotes",
        "merchantnotes": null,
        "appoimentstatus": "PENDING",
        "Status": null,
        "CreatedDate": "2022-11-24T10:15:52.301Z",
        "ModifiedDate": "2022-11-24T10:15:52.301Z"
    },
    {
        "_id": "637f4ade2509f67c30715aba",
        "customerid": "62e3e993482e856900691934",
        "customerfirstname": "T",
        "customerlastname": "J",
        "customeremail": "tj24618@gmail.com",
        "storeid": "000000000000000000000000",
        "storename": null,
        "storeaddress": null,
        "date": "2022-11-23T18:30:00Z",
        "starttime": "9am",
        "endtime": "5pm",
        "customernotes": "customernotes",
        "merchantnotes": null,
        "appoimentstatus": "PENDING",
        "Status": null,
        "CreatedDate": "2022-11-24T10:43:42.041Z",
        "ModifiedDate": "2022-11-24T10:43:42.041Z"
    },
    {
        "_id": "637f4b052509f67c30715abc",
        "customerid": "62e3e993482e856900691934",
        "customerfirstname": "T confirm",
        "customerlastname": "J",
        "customeremail": "tj24618@gmail.com",
        "storeid": "000000000000000000000000",
        "storename": null,
        "storeaddress": null,
        "date": "2022-11-30T18:30:00Z",
        "starttime": "9am",
        "endtime": "5pm",
        "customernotes": "customernotes",
        "merchantnotes": null,
        "appoimentstatus": "CONFIRMED",
        "Status": null,
        "CreatedDate": "2022-11-24T10:44:21.967Z",
        "ModifiedDate": "2022-11-24T10:44:21.967Z"
    },
    {
        "_id": "637f4b4f2509f67c30715abe",
        "customerid": "62e3e993482e856900691934",
        "customerfirstname": "Tpendding",
        "customerlastname": "J",
        "customeremail": "tj24618@gmail.com",
        "storeid": "000000000000000000000000",
        "storename": null,
        "storeaddress": null,
        "date": "2022-11-30T18:30:00Z",
        "starttime": "9am",
        "endtime": "5pm",
        "customernotes": "customernotes",
        "merchantnotes": null,
        "appoimentstatus": "PENDING",
        "Status": null,
        "CreatedDate": "2022-11-24T10:45:35.207Z",
        "ModifiedDate": "2022-11-24T10:45:35.207Z"
    }
]

const dObj = {
    "_id": "637ca4f3772e72a41ffbbcb7",
    "storeid": "62e3e0ebf4502b075c12e10d",
    "isopen": true,
    "daterangetype": "FUTUREDAYS",
    "nextcalendardays": 15,
    "isdefinitely": false,
    "fromdate": null,
    "tomdate": null,
    "duration": "98",
    "workinghourtype": "EXITINGSCHEDULE",
    "weekdays": [
        {
            "weekday": "Monday",
            "starttime": "9AM",
            "endtime": "5PM",
            "isopen": true
        }
    ],
    "customdays": null,
    "CreatedDate": "2022-11-22T10:31:15.564Z",
    "ModifiedDate": "2022-11-24T11:00:19.165Z"
}

class SetAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {

            isActive: false,
            plazaId: "",
            storeId: "",

            timeOptions: DatasetDuration(),

            editDayIndex: -1,
            EditTimeList: "",

            clockList: clock,
            selectedFrom: null,
            selectedTo: null,

            errors: {},
            masterTimeOption: [],
            isSubmit: false,

            ////////////////////////////////////////////

            duraionList: duratoinMinList,
            selectedDuration: duratoinMinList[0],

            selectedDateRangT: DateRangeT.FUTUREDAYS,

            isSAppointment: false,

            selectedHeader: 0,
            userFields: {
                nextcalendardays: "",
                fromdate: "",
                tomdate: "",
                duration: ""
            },

            masterAppointment: [],
            appointmentList: [],
            appointmentLstInd: -1,

            setHeder: APPOINTMENTH.UPCOMING,
            appointmentId: ""

        }

        this.handleChange = this.handleChange.bind(this)

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        StoreObjectInStorage(Constants.PICKUPTIME_OBJ, '')
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            this.getSetSchedule(authToken, storeId);
        } else {
            this.props.history.goBack()
        }
        console.log(authToken);
    }

    getSetSchedule(authToken, storeId) {
        this.setState({ isActive: true, errors: {} });
        var URL = APPOIMENT_APi + "/" + storeId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.detailAutopopulate(responseData)
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
                this.getCustomerSchedule(authToken, storeId)
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
                this.getCustomerSchedule(authToken, storeId)
            });
    }

    getCustomerSchedule(authToken, storeId) {
        this.setState({ isActive: true, errors: {} });
        var URL = APPOIMENT_APi + "/" + storeId + "/" + LIST_API;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ masterAppointment: responseData }, () => {
                            this.filterType(this.state.setHeder)
                        })
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    filterType(ftype) {
        const { masterAppointment } = this.state
        let list = []
        if (ftype == APPOINTMENTH.UPCOMING) {
            list = masterAppointment.filter(e9 => e9.appoimentstatus == ftype && this.dateCompaire(e9.date))
        }
        else if (ftype == APPOINTMENTH.PENDING) {
            list = masterAppointment.filter(e9 => e9.appoimentstatus == ftype && this.dateCompairePending(e9.date))
        }
        else if (ftype == APPOINTMENTH.PAST) {
            list = masterAppointment.filter(e9 => this.dateCompairePast(e9.date))
        }
        this.setState({ appointmentList: list })
    }

    dateCompaire(date) {
        let currentDate = Moment.utc().format(DatetimeFormate.YYYYMMDDdash)
        return Moment.utc(date).format(DatetimeFormate.YYYYMMDDdash) >= currentDate
    }

    dateCompairePending(date) {
        let currentDate = Moment.utc().format(DatetimeFormate.YYYYMMDDdash)
        return Moment.utc(date).format(DatetimeFormate.YYYYMMDDdash) >= currentDate
    }

    dateCompairePast(date) {
        let currentDate = Moment.utc().format(DatetimeFormate.YYYYMMDDdash)
        return Moment.utc(date).format(DatetimeFormate.YYYYMMDDdash) < currentDate
    }

    checkbtnFilter(status, date) {
        return status == APPOINTMENTH.PENDING && this.dateCompairePending(date)
    }

    detailAutopopulate(responseData) {
        const { userFields, duraionList, timeOptions } = this.state
        if (responseData.daterangetype == DateRangeT.FUTUREDAYS) {
            userFields["nextcalendardays"] = responseData.nextcalendardays ? responseData.nextcalendardays : ""
        }
        else if (responseData.daterangetype == DateRangeT.DATERANGE) {
            userFields["fromdate"] = responseData.fromdate ? Moment.utc(responseData.fromdate).format(DatetimeFormate.YYYYMMDDdash) : ""
            userFields["tomdate"] = responseData.tomdate ? Moment.utc(responseData.tomdate).format(DatetimeFormate.YYYYMMDDdash) : ""
        }
        let duration = duraionList.filter(e => e.value == responseData.duration)[0]
        if (!duration) {
            duration = duraionList[4]
            userFields["duration"] = responseData.duration ? responseData.duration : ""
        }

        let clockList = timeOptions.map(e => {
            let responseList = responseData.weekdays ? responseData.weekdays : []
            let responseObj = responseList.filter(e1 => e1.weekday == e.weekday)[0]
            if (responseObj) {
                return responseObj
            }
            return e
        })
        this.setState({
            appointmentId: responseData._id,
            isSAppointment: responseData.isopen,
            selectedDateRangT: responseData.daterangetype,
            userFields,
            selectedDuration: duration,
            timeOptions: clockList
        })
    }

    headerChange(value) {
        let storeId = this.state.storeId
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        if (storeId) {
            if (value == 0 && value != this.state.selectedHeader) {
                this.setState({ isSubmit: false })
                this.getSetSchedule(authToken, storeId);
            }
            else if (value == 1 && value != this.state.selectedHeader) {
                this.setState({ appointmentLstInd: -1 })
                this.getCustomerSchedule(authToken, storeId)
            }
        }
        this.setState({ selectedHeader: value, errors: {} })
    }

    //////////////////////////////////// header I method ////////////////////////////////////////////////
    //////////////////////////////////// header I method ////////////////////////////////////////////////
    //////////////////////////////////// header I method ////////////////////////////////////////////////

    handleAppointmentOpen() {
        // this.setState({ isSAppointment: !this.state.isSAppointment })
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        let storeId = this.state.storeId
        if (storeId) {
            this.setState({ isActive: true })
            this.postSetScheduleStatus(authToken, storeId, !this.state.isSAppointment)
        }
    }

    postSetScheduleStatus(authToken, id, flag) {
        this.setState({ errors: {} });
        var URL = APPOIMENT_APi + "/" + id + "/" + OPEN_API + "/" + String(flag);
        let postData = {}
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isSAppointment: !this.state.isSAppointment })
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    handleDateRangT(t) {
        const { userFields } = this.state
        if (t == DateRangeT.FUTUREDAYS) {
            userFields['fromdate'] = ""
            userFields['tomdate'] = ""
        }
        else if (t == DateRangeT.DATERANGE) {
            userFields["nextcalendardays"] = ""
        }
        else if (t == DateRangeT.INDEFINITELY) {
            userFields['fromdate'] = ""
            userFields['tomdate'] = ""
            userFields["nextcalendardays"] = ""
        }
        this.setState({ selectedDateRangT: t, userFields, errors: {}, isSubmit: true })
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields, isSubmit: true })
    }

    handleDuration = selectedDuration => {
        const { userFields } = this.state
        userFields["duration"] = ""
        this.setState({ selectedDuration, userFields, isSubmit: true })
    }


    handleTimeOption(timeObj) {
        let timeOptions = this.state.timeOptions;
        for (var index in timeOptions) {
            if (timeOptions[index].weekday == timeObj.weekday) {
                timeOptions[index].isopen = !timeOptions[index].isopen
            }
            if (index == this.state.editDayIndex && !timeOptions[index].isopen) {
                this.setState({ editDayIndex: -1 })
            }
        }
        this.setState({
            timeOptions: timeOptions,
            isSubmit: true
        })
    }

    handleEditTime(DObj, Ind) {
        let selectedF = null, selectedT = null
        let val = removeSpcesFromString(DObj.starttime) + " - " + removeSpcesFromString(DObj.endtime)
        if (DObj.storehours) {
            selectedF = this.state.clockList.filter(e => e.value == String(DObj.starttime).slice(0, 2) + " " + String(DObj.starttime).slice(2))[0]
            selectedT = this.state.clockList.filter(e => e.value == String(DObj.endtime).slice(0, 2) + " " + String(DObj.endtime).slice(2))[0]
        }
        this.setState({
            editDayIndex: Ind,
            EditTimeList: val,
            selectedFrom: selectedF ? selectedF : null,
            selectedTo: selectedT ? selectedT : null,
            errors: {},
        })
    }

    handleFrom = (selectedFrom) => {
        this.setState({ selectedFrom })
    }

    handleTo = (selectedTo) => {
        this.setState({ selectedTo })
    }

    validateTimeListForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if ((!this.state.selectedFrom) || (!this.state.selectedTo)) {
            formIsValid = false;
            errors["deliveryTime"] = Messages.v_StoreAppointmentHours;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSaveInArray(obj, index) {
        let EditTimeList = this.state.EditTimeList;
        let selectedFrom = this.state.selectedFrom;
        let selectedTo = this.state.selectedTo
        let timeOptions = this.state.timeOptions;
        if (this.validateTimeListForm()) {
            let stringTime = removeSpcesFromString(selectedFrom.value) + " - " + removeSpcesFromString(selectedTo.value)
            timeOptions[index].starttime = removeSpcesFromString(selectedFrom.value)
            timeOptions[index].endtime = removeSpcesFromString(selectedTo.value)
            this.setState({
                timeOptions: timeOptions,
                EditTimeList: stringTime,
                selectedFrom: null,
                selectedTo: null,
                isSubmit: true
            })
        }
    }

    cancelArrayAssign() {
        this.setState({
            editDayIndex: -1,
            EditTimeList: "",
            selectedFrom: null,
            selectedTo: null
        })
    }

    handleSubmit_Tab1() {
        var postData = {}
        var obj = validateAppointment(this.state)
        this.setState({ errors: obj.errors })
        if (obj.formIsValid) {
            const { userFields, selectedDuration } = this.state;

            postData["isopen"] = this.state.isSAppointment;
            postData["daterangetype"] = this.state.selectedDateRangT;
            postData["isdefinitely"] = this.state.selectedDateRangT == DateRangeT.INDEFINITELY;
            postData["nextcalendardays"] = userFields['nextcalendardays'] ? String(userFields['nextcalendardays']).trim() : 0;
            postData["fromdate"] = userFields["fromdate"] ? userFields["fromdate"] : null;
            postData["tomdate"] = userFields["tomdate"] ? userFields["tomdate"] : null;
            postData["workinghourtype"] = "EXITINGSCHEDULE";
            if (selectedDuration.value == durationMinKey.CUSTOM) {
                postData["duration"] = String(userFields["duration"]).trim();
            }
            else {
                postData["duration"] = selectedDuration.value;
            }
            postData["weekdays"] = this.state.timeOptions;

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            let storeId = this.state.storeId, appointmentId = this.state.appointmentId
            /*  postData["_id"] = appointmentId */
            console.log("====", JSON.stringify(postData))
            if (storeId) {
                if (appointmentId) {
                    this.setState({ isActive: true })
                    this.putSetSchedule(authToken, postData, storeId)
                }
                else {
                    this.setState({ isActive: true })
                    this.postSetSchedule(authToken, postData, storeId)
                }
            }
            this.cancelArrayAssign()
        }
    }

    postSetSchedule(authToken, postData, id) {
        this.setState({ errors: {} });
        var URL = APPOIMENT_APi + "/" + id;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getSetSchedule(authToken, id)
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    putSetSchedule(authToken, postData, id) {
        this.setState({ errors: {} });
        var URL = APPOIMENT_APi + "/" + id;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getSetSchedule(authToken, id)
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }


    //////////////////////////////////// header I method ////////////////////////////////////////////////
    //////////////////////////////////// header I method ////////////////////////////////////////////////
    //////////////////////////////////// header I method ////////////////////////////////////////////////

    // deleteFromArray(TObj, parentIndex) {
    //     let EditTimeList = this.state.EditTimeList
    //     EditTimeList = EditTimeList.filter(e => e != TObj)
    //     let timeOptions = this.state.timeOptions;
    //     timeOptions[parentIndex].storehours = EditTimeList
    //     this.setState({
    //         timeOptions: timeOptions,
    //         EditTimeList: EditTimeList,
    //         isSubmit: true
    //     })
    // }

    handleFilterHeader(filtertp) {
        this.setState({ setHeder: filtertp, appointmentLstInd: -1 })
        this.filterType(filtertp)
    }



    handleviewInDetail(indexx) {
        this.setState({ appointmentLstInd: this.state.appointmentLstInd == indexx ? -1 : indexx })
    }

    cancelAppointment(Obj) {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var postData = {}
        postData["appoimentid"] = Obj._id
        postData["appoimentstatus"] = appointmentStatus.CANCEL
        this.cancelApptmntAPI(authToken, postData)
    }

    cancelApptmntAPI(authToken, postData) {
        this.setState({ isActive: true })
        this.setState({ errors: {} });
        var URL = APPOIMENT_APi + UPDATESTATUS;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ appointmentLstInd: -1 })
                    this.getCustomerSchedule(authToken, this.state.storeId)
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    confirmAppointment(Obj) {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var postData = {}
        postData["appoimentid"] = Obj._id
        postData["appoimentstatus"] = appointmentStatus.CONFIRMED
        this.confirmAppointmentAPI(authToken, postData)
    }

    confirmAppointmentAPI(authToken, postData) {
        this.setState({ isActive: true })
        this.setState({ errors: {} });
        var URL = APPOIMENT_APi + UPDATESTATUS;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ appointmentLstInd: -1 })
                    this.getCustomerSchedule(authToken, this.state.storeId)
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    render() {
        const { selectedHeader } = this.state
        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner" >
                    <Header setHeaderTitle={SetupAppointmentConst.SetupAppointment} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container appointment" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            {/* <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handlePickupTime}
                                    className="btn btn-reg-email">
                                    Add Pickup Time
                                </span>
                            </div> */}

                            <div className="reg_form_main">
                                {/*<div className="row storeTime-header">
                                    <div className="text-center col-12 time-Header selected-header hour-header">Store Hours</div>
                                </div>*/}
                                <div className="row storeTime-header m-0 mb-2">
                                    <div className={"text-center col-6 time-Header get-more-genre " + (selectedHeader == 0 ? "selected-header" : "")}
                                        onClick={() => this.headerChange(0)}>Manage Schedule</div>
                                    <div className={"text-center col-6 time-Header get-more-genre " + (selectedHeader == 1 ? "selected-header" : "")}
                                        onClick={() => this.headerChange(1)}>Manage Appointment</div>
                                </div>

                                {selectedHeader == 0 ?
                                    <React.Fragment>
                                        <div class="row m-0 blcok-hr">
                                            <div class="col-sm-8 col-7 pb-2">
                                                <p>Can people book an appointment?</p>
                                            </div>
                                            <div class="col-sm-4 col-5 pb-2 text-right">
                                                <label class="switch m-0">
                                                    <input type="checkbox" onClick={() => this.handleAppointmentOpen()} checked={this.state.isSAppointment} />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                            <hr />
                                        </div>
                                        <div style={{ pointerEvents: this.state.isSAppointment ? "auto" : "none" }}>
                                            <div class="row m-0 mt-2 div-2-elmnt">
                                                <div class="col-sm-6">
                                                    <b>Date range </b>
                                                </div>
                                                <div class="col-sm-6 pr-0 hind-msg">
                                                    Set a range of dates when you can accept meetings.
                                                </div>
                                                <div class="col-sm-12 mt-2">
                                                    Invitees can schedule...
                                                </div>
                                                <div class="col-sm-12 mt-4 d-flex align-items-center div-2-elmnt-box1">
                                                    <input type="radio"
                                                        checked={this.state.selectedDateRangT == DateRangeT.FUTUREDAYS}
                                                        onChange={() => this.handleDateRangT(DateRangeT.FUTUREDAYS)}
                                                    /> <input type="text"
                                                        name="nextcalendardays"
                                                        value={this.state.userFields.nextcalendardays}
                                                        onChange={this.handleChange}
                                                    /> calander days into the future
                                                </div>
                                                <div class="col-sm-12 ml-4">
                                                    <div className="errorMsg">
                                                        {this.state.errors.nextcalendardays}
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 mt-4">
                                                    <input type="radio"
                                                        checked={this.state.selectedDateRangT == DateRangeT.DATERANGE}
                                                        onChange={() => this.handleDateRangT(DateRangeT.DATERANGE)}
                                                    /> Within a date range
                                                    <div class="row m-0 ml-2 mt-2">
                                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                                            <input type="date"
                                                                name="fromdate"
                                                                value={this.state.userFields.fromdate}
                                                                onChange={this.handleChange} />
                                                            <div className="errorMsg">
                                                                {this.state.errors.fromdate}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-4 col-sm-6 mt-sm-0 mt-2">
                                                            <input type="date"
                                                                name="tomdate"
                                                                value={this.state.userFields.tomdate}
                                                                onChange={this.handleChange} />
                                                            <div className="errorMsg">
                                                                {this.state.errors.tomdate}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 mt-4">
                                                    <input type="radio"
                                                        checked={this.state.selectedDateRangT == DateRangeT.INDEFINITELY}
                                                        onChange={() => this.handleDateRangT(DateRangeT.INDEFINITELY)}
                                                    /> Indefinitely into the future
                                                </div>
                                            </div>

                                            <div class="row m-0 col-sm-12 p-0 mt-2 blcok-hr">
                                                <hr />
                                            </div>
                                            <div class="row m-0 mt-2">
                                                <div class="col-sm-6">
                                                    <label>
                                                        Duration
                                                    </label>
                                                </div>
                                                <div class="col-sm-6 hind-msg">
                                                    Define how long your event will be. It can be as long as 12 hours.
                                                </div>
                                                <div class="col-sm-6">
                                                    <Select menuPortalTarget={document.body}
                                                        value={this.state.selectedDuration}
                                                        onChange={this.handleDuration}
                                                        options={this.state.duraionList}
                                                        styles={colourStyles}
                                                        // placeholder={Constvariable.ParantCategory}
                                                        className="select-input"
                                                    // getOptionLabel={(option) => option.Category_name}
                                                    // getOptionValue={(option) => option._id}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.parantCategory}
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.selectedDuration && this.state.selectedDuration.value == durationMinKey.CUSTOM &&
                                                <div class="col-sm-6">
                                                    <input type="text"
                                                        name="duration"
                                                        maxLength={3}
                                                        value={this.state.userFields.duration}
                                                        onChange={this.handleChange} />
                                                    <div className="errorMsg">
                                                        {this.state.errors.duration}
                                                    </div>
                                                </div>
                                            }

                                            <div class="row m-0 col-sm-12 p-0 mt-2 blcok-hr">
                                                <hr />
                                            </div>

                                            <div class="row m-0 mt-2 div-2-elmnt">
                                                <div class="col-sm-7">
                                                    How do you want to offer your availability for this event type?
                                                </div>
                                                {/* <div class="col-sm-6 pr-0">
                                      Set a range of dates when you can accept meetings.
                                  </div> */}
                                                {/* <div class="row col-sm-12 mt-4 m-0"> */}
                                                <div class="row m-0 col-sm-12 mt-2">
                                                    <div class="col-sm-4 schedual-type-box schedual-type-select">
                                                        Use an existing schedule
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                                <div class="col-sm-12 mt-2">
                                                    Schedule Working Hours
                                                </div>
                                            </div>

                                            <div class="row m-0 storehour-container ml-3 mr-3 mt-2">
                                                <div class="col-sm-12 mt-2 mb-2 ml-3">
                                                    WEEKLY HOURS
                                                </div>
                                                <div class="col-sm-12 p-0">
                                                    {this.state.timeOptions.map((obj, i) => {
                                                        return <>
                                                            <div className="row storetime-li w-100" key={i}>
                                                                <div className="row col-sm-8 m-0">
                                                                    <div className="col-md-2 col-sm-3 col-3">
                                                                        <label class="switch m-0">
                                                                            <input type="checkbox" onClick={() => this.handleTimeOption(obj)} checked={obj.isopen} />
                                                                            <span class="slider round"></span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-sm-2 col-3">
                                                                        {obj.isopen && <i class="fas fa-edit get-more-genre mt-1" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleEditTime(obj, i)}></i>
                                                                        }
                                                                    </div>
                                                                    <div className="col-sm-6 col-6">
                                                                        <label className="pt-1 mb-0">
                                                                            {obj.weekday}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {this.state.editDayIndex == i ?
                                                                    <div className="col-sm-4 text-right">
                                                                        {this.state.EditTimeList}
                                                                    </div>
                                                                    :
                                                                    <div className="col-sm-4 text-right">
                                                                        {
                                                                            obj.isopen ?
                                                                                obj.starttime + " - " + obj.endtime
                                                                                :
                                                                                <div className="col-sm-12 mt-1 disable-text">
                                                                                    Closed
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            {this.state.editDayIndex == i &&
                                                                <div className="row col-sm-12 m-0 storeAdd-from">
                                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-10">
                                                                        <label className="col-sm-12 label-color p-0">{StoreHourConst.Starthour}
                                                                            &nbsp;
                                                                            <OnHoverIconInfo
                                                                                message={StoreHourList.Starthour} /> <PutMandatoryfieldSymbol />
                                                                        </label>
                                                                        <Select menuPortalTarget={document.body}
                                                                            value={this.state.selectedFrom}
                                                                            onChange={this.handleFrom}
                                                                            options={this.state.clockList}
                                                                            styles={colourStyles}
                                                                            className="select-input"
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-10">
                                                                        <label className="col-sm-12 label-color p-0">{StoreHourConst.Endhour}
                                                                            &nbsp;
                                                                            <OnHoverIconInfo
                                                                                message={StoreHourList.Endhour} /> <PutMandatoryfieldSymbol />
                                                                        </label>
                                                                        <Select menuPortalTarget={document.body}
                                                                            value={this.state.selectedTo}
                                                                            onChange={this.handleTo}
                                                                            options={this.state.clockList}
                                                                            styles={colourStyles}
                                                                            className="select-input"
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-1 col-2 d-flex align-items-center">
                                                                        <i class="fa fa-plus get-more-genre" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleSaveInArray(obj, i)}></i>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="errorMsg">
                                                                            {this.state.errors.deliveryTime}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row m-0 col-sm-12 p-0">
                                                                        <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                                                                            <button type="button"
                                                                                class="btn btn-secondary w-100 btn_cancel"
                                                                                onClick={() => this.cancelArrayAssign()}>Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    })}
                                                </div>
                                            </div>
                                            <div className="row m-0 mt-5 col-sm-12 p-0 justify-content-around mb-5">
                                                {this.state.isSubmit &&
                                                    <div className="col-sm-3">
                                                        <button type="button" class="btn btn-primary w-100 btn-submit" onClick={() => this.handleSubmit_Tab1()}>Submit</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <div class="row m-0 blcok-hr">
                                            {/* <div class="col-sm-12 pb-2"> */}
                                            <ul class="filter-header-ul w-100">
                                                <li class="filter-list-li">
                                                    <a class={this.state.setHeder == APPOINTMENTH.UPCOMING ? "active" : ""}
                                                        tabIndex="-1"
                                                        onClick={() => this.handleFilterHeader(APPOINTMENTH.UPCOMING)}
                                                    >Upcoming</a>
                                                </li>
                                                <li class="filter-list-li">
                                                    <a class={this.state.setHeder == APPOINTMENTH.PENDING ? "active" : ""} tabIndex="-1"
                                                        onClick={() => this.handleFilterHeader(APPOINTMENTH.PENDING)}
                                                    >Pending</a>
                                                </li>
                                                <li class="filter-list-li">
                                                    <a class={this.state.setHeder == APPOINTMENTH.PAST ? "active" : ""} tabIndex="-1"
                                                        onClick={() => this.handleFilterHeader(APPOINTMENTH.PAST)}
                                                    >Past</a>
                                                </li>
                                            </ul>
                                            {/* </div> */}
                                        </div>

                                        {this.state.appointmentList.map((item, indexx) => {
                                            return <div class="card mt-2">
                                                <h6 class="card-header">{dateFormating(item.date)}</h6>
                                                <div class="card-body row m-0">
                                                    <div class="col-9 pl-0">
                                                        <div class="row m-0 w-100">
                                                            <div class="col-sm-4 pl-0">
                                                                {item.starttime} - {item.endtime}
                                                            </div>
                                                            <div class="col-sm-4 pl-0 pr-0">
                                                                <div> {item.customerlastname} {item.customerfirstname}</div>
                                                                <div> {item.customeremail}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 pl-0 pr-0 text-right">
                                                        <a tabIndex="-1" class="setasatag" onClick={() => this.handleviewInDetail(indexx)}> Details </a>
                                                    </div>
                                                </div>
                                                {this.state.appointmentLstInd == indexx && <div class="card-body row m-0 blcok-hr pt-0">
                                                    <hr class="mt-0" />
                                                    <div class="col-md-4 pl-0">
                                                        Status:
                                                    </div>
                                                    <div class="col-md-8 pl-0">
                                                        {item.appoimentstatus}
                                                    </div>
                                                    <div class="col-md-4 pl-0">
                                                        Customer Notes:
                                                    </div>
                                                    <div class="col-md-8 pl-0">
                                                        {item.customernotes}
                                                    </div>
                                                    {this.checkbtnFilter(item.appoimentstatus, item.date) &&
                                                        <div class="row m-0 mt-5 w-100">
                                                            <div className="col-lg-2 col-md-3 col-sm-4">
                                                                <button type="button"
                                                                    class="btn btn-secondary w-100 btn_cancel"
                                                                    onClick={() => this.cancelAppointment(item)}>Cancel</button>
                                                            </div>
                                                            <div className="col-lg-2 col-md-3 col-sm-4 mt-2 mt-sm-0">
                                                                <button type="button" class="btn btn-primary w-100 btn-submit" onClick={() => this.confirmAppointment(item)}>Confirm</button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                }
                                            </div>
                                        })}

                                    </React.Fragment>
                                }


                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <center>
                                        <div className="errorMsg">
                                            {this.state.errors.ErrorC}
                                        </div>
                                    </center>
                                </div>
                                {/* <div className="row m-0 mt-5 col-sm-12 p-0 justify-content-around mb-5">
                                    <div className="col-sm-12">
                                        <div className="col-sm-12">
                                            <p>
                                                {StoreHourConst.NoteLabel}
                                            </p>
                                            <span className="text-justify">
                                                {StoreHourConst.StoreHourUpdateMessage}
                                            </span>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeletePickupTimesTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeletePickupTimes}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}
                    </section>
                </LoadingOverlay>
            </div >

        );
    }
}

export default SetAppointment;