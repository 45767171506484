import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants,{DEFAULTID} from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { GET_INVENTORY, PLAZAID } from '../../Config/Config.json'
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import RouteMerchant from '../../Config/RouteMerchant.js';
import Constvariable from '../../Config/constvariable';

class ManageInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeId: "",
            plazaId: "",
            isActive: false,
            inventoryList: [],
            errors: {}
        }
        this.handleAddProducts = this.handleAddProducts.bind(this);
        // this.handleUpdate = this.handleUpdate.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        StoreObjectInStorage(Constants.INVENTORY_OBJ, "")
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId });
            this.getStoreInventory(authToken, storeId, plazaId);
        } else {
            this.props.history.goBack()
        }
        console.log(authToken);
    }

    getStoreInventory(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_INVENTORY + "/" + storeId + "?" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ inventoryList: responseData });
                        this.setState({ loaderClass: false });
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    // regularRegister(e) {
    //     localStorage.removeItem("FacebookData");
    //     window.location.href = "/Store";
    // }

    handleAddProducts() {
        this.props.history.push({
            pathname: "/addItems",
        })

    }

    handleUpdate(inventoryObj) {
        StoreObjectInStorage(Constants.INVENTORY_OBJ, inventoryObj)
        this.props.history.push({
            pathname: RouteMerchant.UpdateInventory,
        })
    }

    handleReceiveInventory(inventoryObj) {
        StoreObjectInStorage(Constants.INVENTORY_OBJ, inventoryObj)
        this.props.history.push({
            pathname: RouteMerchant.ReceiveInventory,
        })
    }

    viewHistory(inventoryObj) {
        StoreObjectInStorage(Constants.INVENTORY_OBJ, inventoryObj)
        this.props.history.push({
            pathname: RouteMerchant.InventoryHistory,
        })
    }

    render() {
        const { hideEmptyPlazaView } = this.state;
        // const columns = [
        //     {
        //         maxWidth: "80px",
        //         cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px" }} src={row.Imageurl} className="iconDetails" />
        //     },
        //     {
        //         cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.CompanyName}</div>
        //     },
        // {
        //     maxWidth: "80px",
        //     cell: row => <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
        //         <span
        //             style={{ background: Color.THEME_COLOR }}
        //             onClick={this.handleUpdate}
        //             className="btn btn-reg-email">
        //             Update
        //         </span>
        //     </div>


        // },

        // ];

        const columns = [
            {
                name: "Item Name",
                cell: row => <div
                    style={{ marginLeft: "0px", fontSize: "15px", color: Color.THEME_COLOR, cursor: "pointer" }}
                    onClick={() => this.viewHistory(row)}
                >{row.ItemName}</div>
            },
            {
                name: "Available Quantity",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.AvailableQuantity}</div>
            },
            /* {
                name: "Incoming Quantity",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.IncomingQuantity}</div>
            }, */
            {
                cell: row => <div className="registered-with">
                    <span
                        style={{ background: Color.THEME_COLOR, padding: "8px" }}
                        onClick={() => this.handleUpdate(row)}
                        className="btn btn-reg-email">
                        Update
                    </span>
                </div>
            }/* ,
            {
                cell: row => <div className="registered-with">
                    <span
                        style={{ background: Color.THEME_COLOR, padding: "8px" }}
                        onClick={() => this.handleReceiveInventory(row)}
                        className="btn btn-reg-email">
                        Receive
                    </span>
                </div>
            }, */
        ];


        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.Inventory} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="reg_form_main">
                                {!hideEmptyPlazaView
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img></img>
                                            <p className="col-sm-12" for="FirstName">

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <DataTableExtensions
                                        data={this.state.inventoryList}
                                        columns={columns}
                                        export={false}
                                        print={false}
                                        filter={this.state.inventoryList.length > 0 ? true : false}
                                        filterHidden={false}
                                        filterPlaceholder={Messages.SearchforInventory}
                                        filterStyle={{ width: "100px" }} >
                                        <DataTable
                                            noHeader={true}
                                            pagination
                                            paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                        // noTableHead={true}
                                        // //pagination
                                        // onRowClicked={this.handleRowClick}

                                        />
                                    </DataTableExtensions>

                                }
                            </div>
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Delete item</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        Are you sure you want to delete this item?
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default ManageInventory;