import React, { Component } from 'react';
import Messages from "../Config/Messages";
import Constants from '../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage, validateOtpNo } from "../Config/Utils";
import { GetData } from "../../services/GetData";
import { GET_PRODUCT_CATEGORY, POST_STORE_CATEGORY, PLAZAID, MERCHANTUSER_API, ACTIVATION_API, VALIDATEOTP_API } from '../Config/Config.json'
import SideMenu from '../SideMenu/SideMenu';
import Header from '../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../Config/Color';
import pencil from '../../images/icons/pencil.png';
import RouteMerchant from '../Config/RouteMerchant.js';
import { DeleteData } from '../../services/DeleteData';
import Constvariable, { UserStatus } from '../Config/constvariable';
import { UserManagementConst } from '../Config/ConstForm'
import OnHoverIconInfo from "../UtillCustomComponent/UtillCustomComponent"
import { UserManagementList } from '../Config/HoverMessage'
import { PostData } from '../../services/PostData';

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            userList: [],
            userId: "",

            userFields: {},
            errors: {}
        }
        this.handleAddUser = this.handleAddUser.bind(this);
        this.handleChange = this.handleChange.bind(this)
        // this.handleAddItems = this.handleAddItems.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getUserList(authToken)
    }

    getUserList(authToken) {
        this.setState({ isActive: true, errors: {} })
        var URL = MERCHANTUSER_API;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    this.setState({ userList: responseData ? responseData : [] })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleAddUser() {
        this.props.history.push({
            pathname: RouteMerchant.AddMerchantUser,
        })

    }

    handleEditUser(row) {
        this.props.history.push({
            pathname: RouteMerchant.AddMerchantUser,
            search: "?id=" + row._id
        })
    }

    handleStoreAng(row) {
        this.props.history.push({
            pathname: RouteMerchant.StoreAssign,
            search: "?id=" + row._id
        })
    }


    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    handleUserActivation(data) {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        let userId = data._id
        let userFields = this.state.userFields
        userFields["DeviceId"] = Constants.DEVICE_ID;
        userFields["DeviceType"] = Constants.DEVICE_TYPE;
        userFields["Code"] = ""
        if (userId) {
            this.userActivation(authToken, userId);
            this.handleOtpPopup()
            userFields['Email'] = data.eMail
            userFields['PhoneNumber'] = data.Phone
        }
        else {
            userFields['Email'] = ""
            userFields['PhoneNumber'] = ""
        }
        this.setState({ userFields, errors: {} })
    }

    validateOTPForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;


        if (!fields["Code"]) {
            formIsValid = false;
            errors["Code"] = Messages.v_UserOTP
        }
        else if (typeof fields["Code"] !== "undefined") {
            if (!validateOtpNo(fields["Code"])) {
                formIsValid = false;
                errors["Code"] = Messages.v_EnterValidOTP;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleValidateOtpSubmit() {
        if (this.validateOTPForm()) {
            var postData = {};
            postData["Email"] = this.state.userFields.Email;
            postData["PhoneNumber"] = this.state.userFields.PhoneNumber;
            postData["DeviceId"] = Constants.DEVICE_ID;
            postData["DeviceType"] = Constants.DEVICE_TYPE;
            postData["Code"] = this.state.userFields.Code;
            console.log('otppostdata: ', postData);

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.callValidateOtpAPI(authToken, postData);
        }
    }

    callValidateOtpAPI(authToken, postData) {
        this.setState({ isActive: true, errors: {} })
        var URL = MERCHANTUSER_API + "/" + VALIDATEOTP_API;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log('responseJason:', responseJson);
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.hidemodal1()
                    this.getUserList(authToken)
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["Code"] = Messages.Invalid_One_Time_Code;
                    this.setState({ errors: errors });
                }
                else {
                    let errors = {};
                    errors["Code"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["Code"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    userActivation(authToken, uid) {
        this.setState({ isActive: true, errors: {} })
        var URL = MERCHANTUSER_API + "/" + uid + "/" + ACTIVATION_API
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleOtpPopup() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal1')

        if (backgroung && modalId) {
            backgroung.style.display = "block"
            modalId.style.display = "block"
        }
    }

    hidemodal1() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal1')
        if (backgroung && modalId) {
            backgroung.style.display = "none"
            modalId.style.display = "none"
        }
    }

    handleDelete(itemObj, flag) {
        if (flag) {
            this.setState({ userId: itemObj._id })
        }
        else {
            this.setState({ userId: "" })
        }
    }

    deleteItem() {
        let userId = this.state.userId;
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.deleteUserAPI(authToken, userId)
    }

    deleteUserAPI(authToken, userId) {
        this.setState({ isActive: true })
        var URL = MERCHANTUSER_API + "/" + userId;
        var postData = {}
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ userId: "" })
                    this.getUserList(authToken)
                }
                else {
                    this.setState({ isActive: false })
                }
            })
    }

    render() {
        const columns = [
            {
                name: UserManagementConst.Email,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.eMail}</div>,
            },
            {
                name: UserManagementConst.MerchantName,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.LastName + " " + row.FirstName}</div>,
            },
            {
                name: UserManagementConst.PhoneNumber,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Phone}</div>,
            },
            {
                name: UserManagementConst.Status,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Status}</div>,
            },
            {
                // maxWidth: "100px",
                cell: row => <a className="theme-color liArtistClass" style={{ fontSize: "15px" }}
                    onClick={() => this.handleStoreAng(row)}>{UserManagementConst.StoreAssign}</a>
            },
            {
                // maxWidth: "80px",
                cell: row => row.Status == UserStatus.INACTIVE ? <a className="theme-color liArtistClass" style={{ fontSize: "15px" }}
                    onClick={() => this.handleUserActivation(row)}>{UserManagementConst.UserActivation}</a> : ""
            },
            {
                maxWidth: "80px",
                cell: row => <i class="fas fa-edit liArtistClass" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleEditUser(row)}></i>
            },
            {
                maxWidth: "80px",
                cell: row => <a data-toggle="modal" data-target="#exampleModalLong">
                    <i class="fa fa-trash liArtistClass" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleDelete(row, true)}></i>
                </a>
            }
        ];

        return (
            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner">
                    <Header setHeaderTitle={UserManagementConst.ManageUsers} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAddUser}
                                    className="btn btn-reg-email">
                                    {UserManagementConst.btn_AddUser}
                                </span>
                            </div>

                            <div className="reg_form_main">
                                <DataTableExtensions
                                    data={this.state.userList}
                                    columns={columns}
                                    export={false}
                                    print={false}
                                    filter={true}
                                    filterHidden={false}
                                    filterPlaceholder={Messages.SearchforUser}
                                    filterStyle={{ width: "100px" }}>
                                    <DataTable
                                        noHeader={true}
                                        // noTableHead={true}
                                        pagination
                                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                    />
                                </DataTableExtensions>
                            </div>

                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteUserTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteUser}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>{UserManagementConst.btn_NO}</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>{UserManagementConst.btn_YES}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                    <div className="modal-background" id="modalbackgroundId"> </div>
                    <div class="modal" id="myModal1" style={{ verticalAlign: "middle" }}>
                        <div class="modal-dialog setModal-dialogue">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLongTitle"></h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" onClick={() => { this.hidemodal1() }}>&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="reg_form_main col-sm-12">
                                        <label className="label-color">{UserManagementConst.Enterotp}  &nbsp;
                                            <OnHoverIconInfo
                                                message={UserManagementList.Enterotp} /></label>
                                        <div className="inputboxadd">
                                            <input
                                                type="text"
                                                name="Code"
                                                maxLength="6"
                                                className='add-item-box'
                                                placeholder={Constvariable.OtpNumber}
                                                value={this.state.userFields.Code}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="errorMsg">
                                            {this.state.errors.Code}
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.hidemodal1({}, false)}>{UserManagementConst.btn_Back}</button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.handleValidateOtpSubmit() }}>{UserManagementConst.btn_Submit}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>

        );
    }
}

export default UserManagement;