import React, { Component } from 'react';
import './Welcome.css';
import Constants from '../Config/Constants'
import { RetriveDataFromStorage} from "../Config/Utils";

class Welcome extends Component {
	constructor(props) {
		super(props);
			this.state={

			}
	}

	componentDidMount() { 
		var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
		console.log("authToken",authToken);
	}

	render() {

		return (
			<section>
				<div className="container" >
					<center><h1>Welcome</h1></center>
 				 </div>
			</section>

		);
	}
}

export default Welcome;