import React, { Component } from "react";
import PropTypes from "prop-types";
import { render, unmountComponentAtNode } from "react-dom";
// import "./react-confirm-alert.css";
// import './style.css'
import "./Alertinline.css";

class Alertinline extends Component {
    static propTypes = {
        title: PropTypes.any,
        message: PropTypes.any,
        buttons: PropTypes.array,
        childrenElement: PropTypes.func,
        customUI: PropTypes.func,
        closeOnClickOutside: PropTypes.bool,
        closeOnEscape: PropTypes.bool,
        willUnmount: PropTypes.func,
        onClickOutside: PropTypes.func,
        onKeypressEscape: PropTypes.func
    };

    static defaultProps = {
        buttons: [
            {
                label: "Cancel",
                onClick: () => null
            },
            {
                label: "Confirm",
                onClick: () => null
            }
        ],
        childrenElement: () => null,
        closeOnClickOutside: true,
        closeOnEscape: true,
        willUnmount: () => null,
        onClickOutside: () => null,
        onKeypressEscape: () => null
    };

    handleClickButton = button => {
        if (button.onClick) button.onClick();
        this.close();
    };

    handleClickOverlay = e => {
        const { closeOnClickOutside, onClickOutside } = this.props;
        const isClickOutside = e.target === this.overlay;

        if (closeOnClickOutside && isClickOutside) {
            onClickOutside();
            this.close();
        }
    };

    close = () => {
        removeBodyClass();
        removeElementReconfirm();
        removeSVGBlurReconfirm();
    };

    keyboardClose = event => {
        const { closeOnEscape, onKeypressEscape } = this.props;
        const isKeyCodeEscape = event.keyCode === 27;

        if (closeOnEscape && isKeyCodeEscape) {
            onKeypressEscape(event);
            this.close();
        }
    };

    componentDidMount = () => {
        document.addEventListener("keydown", this.keyboardClose, false);
    };

    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.keyboardClose, false);
        this.props.willUnmount();
    };

    renderCustomUI = () => {
        const { title, message, customUI } = this.props;
        const dataCustomUI = {
            title,
            message,
            onClose: this.close
        };

        return customUI(dataCustomUI);
    };

    render() {
        const { title, message, buttons, childrenElement, customUI } = this.props;

        return (
            <div
                className="modal-root-div"
                ref={dom => (this.overlay = dom)}
                onClick={this.handleClickOverlay}
            >
                <div className="modal-background d-block"> </div>
                <div class="modal d-block">
                    <div class="modal-dialog" role="document" style={{ top: "25%" }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">{title}</h5>

                            </div>
                            <div class="modal-body">
                                <p>{message}</p>
                            </div>
                            <div class="modal-footer">
                                {buttons.map((button, i) => (
                                    <button
                                        type="button" class="btn btn-primary theme-bg"
                                        key={i}
                                        onClick={() => this.handleClickButton(button)}
                                    >
                                        {button.label}
                                    </button>
                                ))}
                                {/* <button type="button" class="btn btn-primary">Save changes</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="react-confirm-alert">
          {customUI ? (
            this.renderCustomUI()
          ) : (
              <div className="react-confirm-alert-body">
                {title && <h1>{title}</h1>}
                {message}
                {childrenElement()}
                <div className="react-confirm-alert-button-group">
                  {buttons.map((button, i) => (
                    <button
                      key={i}
                      onClick={() => this.handleClickButton(button)}
                    >
                      {button.label}
                    </button>
                  ))}
                </div>
              </div>
            )}
        </div> */}
            </div>
        );
    }
}

function createSVGBlurReconfirm() {
    // If has svg ignore to create the svg
    // const svg = document.getElementById("react-confirm-alert-firm-svg");
    // if (svg) return;
    // const svgNS = "http://www.w3.org/2000/svg";
    // const feGaussianBlur = document.createElementNS(svgNS, "feGaussianBlur");
    // feGaussianBlur.setAttribute("stdDeviation", "0.3");

    // const filter = document.createElementNS(svgNS, "filter");
    // filter.setAttribute("id", "gaussian-blur");
    // filter.appendChild(feGaussianBlur);

    // const svgElem = document.createElementNS(svgNS, "svg");
    // svgElem.setAttribute("id", "react-confirm-alert-firm-svg");
    // svgElem.setAttribute("class", "modal-background");
    // svgElem.appendChild(filter);

    // document.body.appendChild(svgElem);
}

function removeSVGBlurReconfirm() {
    // const svg = document.getElementById("react-confirm-alert-firm-svg");
    // svg.parentNode.removeChild(svg);
    // document.body.children[0].classList.remove("react-confirm-alert-blur");
}

function createElementReconfirm(properties) {
    let divTarget = document.getElementById("setrender");
    if (divTarget) {
        // Rerender - the mounted ReactConfirmAlert
        render(<Alertinline {...properties} />, divTarget);
    } else {
        // Mount the ReactConfirmAlert component
        // document.body.children[0].classList.add("react-confirm-alert-blur");
        divTarget = document.createElement("div");
        divTarget.id = "setrender";
        document.body.appendChild(divTarget);
        render(<Alertinline {...properties} />, divTarget);
    }
}

function removeElementReconfirm() {
    const target = document.getElementById("setrender");
    unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
}

function addBodyClass() {
    // document.body.classList.add("react-confirm-alert-body-element");
}

function removeBodyClass() {
    // document.body.classList.remove("react-confirm-alert-body-element");
}

export function customConfirmAlert(properties) {
    // addBodyClass();
    // createSVGBlurReconfirm();
    createElementReconfirm(properties);
}
export default Alertinline;
