import React, { Component } from 'react';
import RouteMerchant from '../Config/RouteMerchant';
import { COMPANYCONST, CONTACT_US_VISIBLE_MODE } from '../Config/Constants';

class MainHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    openNav() {
        document.getElementById("myNav").style.width = "100%";
    }

    closeNav() {
        document.getElementById("myNav").style.width = "0%";
    }

    render() {
        return <header class="header_section">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg custom_nav-container">
                    <a class="navbar-brand" href={RouteMerchant.Default}>
                        {/* <img src={process.env.PUBLIC_URL + "/asset/images/logo.svg"} alt="" /> */}<span>
                            {COMPANYCONST.PLAZACART}
                        </span>
                    </a>

                    <div class="navbar-collapse" id="">
                        <div class="container">
                            <div class=" mr-auto flex-column flex-lg-row align-items-center">
                                <ul class="navbar-nav justify-content-between ">
                                    <div class="d-none d-lg-flex">
                                        <li class="nav-item">
                                            {/* <a class="nav-link" href="fruit.html">Customer Number : 01234567890</a> */}
                                        </li>
                                    </div>
                                    <div class="d-none d-lg-flex">
                                        <li class="nav-item">
                                            <a class="nav-link theme-color" href={RouteMerchant.Default}>
                                                Home
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link theme-color" href={RouteMerchant.AboutUs} target="_blank">
                                                About Us
                                            </a>
                                        </li>
                                        {/* <li class="nav-item">
                                            <a class="nav-link" href="">
                                                Testimonial
                                            </a>
                                        </li> */}
                                        <li class="nav-item">
                                            <a class="nav-link theme-color" href={RouteMerchant.TermsOfService} target="_blank">
                                                Terms & Conditions
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link theme-color" href={RouteMerchant.PrivacyPolicy} target="_blank">
                                                Privacy Policy
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link theme-color" href={RouteMerchant.ContactUs + "?" + CONTACT_US_VISIBLE_MODE.MODE + "=" + CONTACT_US_VISIBLE_MODE.REQUEST_A_DEMO_MODE} target="_blank">
                                                Request a Demo
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link theme-color" href={RouteMerchant.Register}>
                                                Sign Up
                                            </a>
                                        </li>

                                        {/* <form class="form-inline my-2 ml-5 mb-3 mb-lg-0">
                                            <button class="btn  my-2 my-sm-0 nav_search-btn" type="submit"></button>
                                        </form> */}
                                    </div>
                                </ul>
                            </div>
                        </div>

                        <div class="custom_menu-btn">
                            <button onClick={() => this.openNav()}></button>
                        </div>
                        <div id="myNav" class="overlayside">
                            <a href="#" class="closebtn" onClick={() => this.closeNav()}>&times;</a>
                            <div class="overlay-content">
                                <a href={RouteMerchant.Default}>Home</a>
                                <a href={RouteMerchant.Login}>Sign Up</a>
                                <a href={RouteMerchant.TermsOfService} target="_blank"> Terms & Conditions</a>
                                <a href={RouteMerchant.PrivacyPolicy} target="_blank"> Privacy Policy</a>
                                <a href={RouteMerchant.AboutUs} target="_blank">About Us</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    }
}

export default MainHeader;