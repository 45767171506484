import React, { Component } from 'react';
import Messages from "../Config/Messages";
import Constants from '../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../Config/Utils";
import { GetData } from "../../services/GetData";
import { GET_PRODUCT_CATEGORY, POST_STORE_CATEGORY, PLAZAID, MERCHANTUSER_API, MY_STORE_API_List, STORES_API } from '../Config/Config.json'
import SideMenu from '../SideMenu/SideMenu';
import Header from '../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../Config/Color';
import pencil from '../../images/icons/pencil.png';
import RouteMerchant from '../Config/RouteMerchant.js';
import { DeleteData } from '../../services/DeleteData';
import Constvariable from '../Config/constvariable';
import { StoreAssignConst } from '../Config/ConstForm'
import { PostData } from "../../services/PostData";

class StoreAssign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            storeList: [],

            userId: "",
            userDetail: null,
            assignStoreList: []
        }

        // this.handleAddItems = this.handleAddItems.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getMyStoreAPI(authToken)
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.get('id') !== null && (params.get('id').length > 0)) {
            var id = params.get('id');
            this.setState({ userId: id })
            this.getUserDetailAPI(authToken, id)
        }
    }

    getUserDetailAPI(authToken, userId) {
        // this.setState({ isActive: true })
        var URL = MERCHANTUSER_API + "/" + userId
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                console.log("=====>", responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"]
                    this.setState({ userDetail: responseData ? responseData : null })
                    if (responseData) {
                        this.setState({ assignStoreList: responseData.stores ? responseData.stores : [] })
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                // this.setState({ isActive: false })
            });
    }

    getMyStoreAPI(authToken) {
        this.setState({ isActive: true })
        GetData(MY_STORE_API_List, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ storeList: responseData });
                    }
                    else {
                        alert(Messages.NO_DATA);
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
            });
    }

    handleAssignStore(obj, flag, ind) {
        let assignStoreList = this.state.assignStoreList;
        if (flag) {
            assignStoreList.splice(ind, 1)
        }
        else {
            assignStoreList.push({ storeid: obj.ObjectId })
        }
        this.setState({ assignStoreList: assignStoreList })
    }

    handleAssignSubmit = (e) => {
        var postData = {}
        let userId = this.state.userId

        if (userId) {
            postData["merchantid"] = userId
            postData["stores"] = this.state.assignStoreList.map(e => e.storeid)

            console.log("=====> postaData", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.postAssignStoreAPI(authToken, postData)
        }

    }

    postAssignStoreAPI(authToken, postData) {
        this.setState({ isActive: true })
        var URL = MERCHANTUSER_API + "/" + STORES_API
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log("=====> result", responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.props.history.goBack();
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
                this.setState({ isActive: false })
            })
    }

    render() {
        const { userDetail } = this.state
        const columns = [
            {
                cell: row => {
                    let indStoreAssign = this.state.assignStoreList.findIndex(obj => obj.storeid == row.ObjectId)
                    let expressionSA = indStoreAssign > -1
                    return <input type="checkbox"
                        checked={indStoreAssign > -1}
                        onChange={() => this.handleAssignStore(row, expressionSA, indStoreAssign)} />
                }
            },
            {
                // maxWidth: "80px",
                cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px" }} src={row.AppLogo} className="iconDetails" />
            },
            {
                name: StoreAssignConst.StoreName,
                cell: row => <span >{row.ApplicationName}</span>
            },
            {
                // name: "Item Name",
                name: StoreAssignConst.Address,
                cell: row => <span style={{ paddingTop: "5px", paddingBottom: "5px" }}>{row.AppAddress}</span>
            },
        ];

        return (
            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    // active={true}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={StoreAssignConst.StoreAssign} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div class="card set-fontSize">
                                <div class="card-header d-flex">
                                    <span class=""><b>{StoreAssignConst.MerchantName} </b></span> &nbsp;<span>{userDetail ? (userDetail.FirstName + " " + userDetail.LastName) : ""}</span>
                                </div>
                                <div class="card-body d-flex pb-0">
                                    <span class=""><b>{StoreAssignConst.Email}</b> </span> &nbsp;<span>{userDetail ? userDetail.eMail : ""}</span>
                                </div>
                                <div class="card-body d-flex">
                                    <span class=""><b>{StoreAssignConst.PhoneNumber}</b> </span>&nbsp;<span>{userDetail ? userDetail.Phone : ""}</span>
                                </div>
                            </div>
                            <div className="reg_form_main">
                                <DataTableExtensions
                                    data={this.state.storeList}
                                    columns={columns}
                                    export={false}
                                    print={false}
                                    filter={true}
                                    filterHidden={false}
                                    filterPlaceholder={Messages.SearchforStoreAssign}
                                    filterStyle={{ width: "100px" }}
                                >
                                    <DataTable
                                        noHeader={true}
                                        // noTableHead={true}
                                        pagination
                                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                    />
                                </DataTableExtensions>
                            </div>

                            <div className="registered-with text-center mb-5">
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAssignSubmit}
                                    className="btn btn-done">
                                    {StoreAssignConst.btn_Submit}
                                </span>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default StoreAssign;