import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { GetData } from "../../../services/GetData";
import { PostData } from '../../../services/PostData';
import Color from '../../Config/Color';
import { GETPARTERNERREFERRALSTATUS, PARTERNERREFERRALCALLBACK, PARTERNERREFERRALURL, PLAZAID, STOREID } from '../../Config/Config.json';
import Constants, { DEFAULTID } from '../../Config/Constants';
import Messages from "../../Config/Messages";
import RouteMerchant from '../../Config/RouteMerchant.js';
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { PlatFrom } from '../../Config/constvariable';
import Header from '../../Header/Header.js';
import '../Payments.css';

class PayPalPaymentCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeId: '',
            paypalUrl: "",
            plazaId: "",
            partnerStatusObj: null,
            platForm: "",
            errors: {}
        }
        this.handleAddProducts = this.handleAddProducts.bind(this);
        // this.handleAddItems = this.handleAddItems.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);

        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })

            var platForm = ""
            if (storeObj.isshopifyintegration) {
                platForm = PlatFrom.Shopify
            }
            else if (storeObj.iswixintegration) {
                platForm = PlatFrom.Wix
            }
            else {
                platForm = PlatFrom.Plazacart
            }
            this.setState({ platForm: platForm })

            this.getParternerReferralStatus(authToken, storeId, plazaId)

        }
        else {
            this.props.history.goBack()
        }

    }

    getParternerReferralStatus(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GETPARTERNERREFERRALSTATUS + "?" + STOREID + storeId + "&" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ partnerStatusObj: responseData });
                    }
                    else {
                        this.setState({ partnerStatusObj: null });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.getPaypalURL(authToken, storeId, plazaId);
                this.setState({ isActive: false });
            });
    }

    getPaypalURL(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = PARTERNERREFERRALURL + "?" + STOREID + storeId + "&" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ paypalUrl: responseData });
                    }
                    else {
                        this.setState({ paypalUrl: null })
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    handalPaypalStatus() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var partnerStatusObj = this.state.partnerStatusObj
        if (partnerStatusObj) {
            var postData = {}
            postData["merchantId"] = partnerStatusObj.storeid
            postData["merchantIdInPayPal"] = partnerStatusObj.merchantIdInPayPal
            postData["permissionsGranted"] = partnerStatusObj.permissionsGranted
            postData["consentStatus"] = false
            postData["productIntentId"] = partnerStatusObj.productIntentId
            postData["isEmailConfirmed"] = partnerStatusObj.isEmailConfirmed
            postData["accountStatus"] = partnerStatusObj.accountStatus
            postData["returnMessage"] = ""

            console.log("postData", postData)
            // this.postpaypalSetup(authToken, postData)
        }
    }

    postpaypalSetup(authToken, postData) {
        this.setState({ isActive: true, errors: {} });
        var URL = PARTERNERREFERRALCALLBACK
        PostData(URL, postData, authToken)
            .then(result => {
                console.log(result)
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                    // this.getParternerReferralStatus(authToken, storeId)
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false });
    }


    // regularRegister(e) {
    //     localStorage.removeItem("FacebookData");
    //     window.location.href = "/Store";
    // }

    handleAddProducts() {
        this.props.history.push({
            pathname: RouteMerchant.AddToppings,
        })

    }

    handleEdittopping(row) {
        StoreObjectInStorage(Constants.TOPPING_OBJ, row)
        this.props.history.push({
            pathname: RouteMerchant.AddToppings,
        })

    }

    handleDelete(toppingObj, flag) {
        if (flag) {
            this.setState({ toppingId: toppingObj._id })
        }
        else {
            this.setState({ toppingId: "" })
        }
    }

    // deleteItem() {
    //     let itemId = this.state.ItemId;
    //     let storeId = this.state.storeId;
    //     let toppingcateid = this.state.toppingCategoryId;
    //     let toppingId = this.state.toppingId;
    //     var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

    //     var postData = {}
    //     postData['ItemId'] = itemId;
    //     postData['ToppingCategoryId'] = toppingcateid;
    //     postData['ToppingItemId'] = toppingId;

    //     this.deleteItemAPI(authToken, storeId, postData)
    // }

    // deleteItemAPI(authToken, storeId, postData) {
    //     this.setState({ isActive: true })
    //     var URL = DELETE_TOPPING.replace('#id#', storeId);
    //     PostData(URL, postData, authToken)
    //         .then(result => {
    //             var responseJson = result;
    //             if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    //                 this.setState({ toppingId: '' })
    //                 let itemId = this.state.ItemId;
    //                 let toppingCategoryId = this.state.toppingCategoryId;
    //                 this.getToppingList(authToken, storeId, itemId, toppingCategoryId);
    //             }
    //             else {
    //                 this.setState({ isActive: false })
    //               
    //             }
    //         })

    // }

    render() {
        const { hideEmptyPlazaView } = this.state;

        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        let activatBtn

        if (this.state.partnerStatusObj) {
            activatBtn = this.state.partnerStatusObj.Status == "ACTIVE" ?
                <label className='btn btn-light setdeactivebtn'
                    target="_self"
                    onClick={() => this.handalPaypalStatus()}>
                    Deactivate Paypal Express Checkout</label>
                :
                <a href={this.state.paypalUrl}
                    target="_blank"
                    className='btn btn-light setactivebtn'>
                    Activate Paypal Express Checkout
                </a>
        }

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={"Payment"} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            {/* <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAddProducts}
                                    className="btn btn-reg-email">
                                    Add Topping
                                </span>
                            </div> */}
                            <div className="reg_form_main">
                                <div class="card cartBorder setmargin" >
                                    <div class="card-body">

                                        <div className="paymentbox">
                                            <div className="payment-image-box">
                                                <p>Transaction Fees</p>
                                                <span className="setValue">0%</span>
                                            </div>
                                            <div className="payment-image-box">
                                                <p>Status</p>
                                                <span className="setValue">{this.state.partnerStatusObj ? (this.state.partnerStatusObj.Status == "ACTIVE" ? "ACTIVE" : "INACTIVE") : "INACTIVE"}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="card cartBorder setmargin">
                                    <div class="card-body">
                                        <h5 class="card-title">About Paypal Express Checkout</h5>
                                        <label class="card-subtitle mb-2">Receive payments in foreign currencies with PayPal. Manage payment approval in payment preferences in PayPal.
                                            {this.state.platForm == PlatFrom.Shopify && <a className="sethyperlink"
                                                href="https://help.shopify.com/en/manual/payments/paypal"
                                                target="_blank"> Learn more.</a>}
                                        </label>
                                        <div className="paymentbox setaligncenter">
                                            <div className="payment-image-box">
                                                <label> Learn more about
                                                    {this.state.platForm == PlatFrom.Shopify && <a className="sethyperlink"
                                                        href="https://help.shopify.com/en/manual/payments/paypal"
                                                        target="_blank"> Paypal Express Checkout.</a>}
                                                </label>
                                            </div>
                                            <div className="payment-image-box">
                                                <button className='btn btn-light setdocbtn' style={{ backgroundColor: "#fff" }}>
                                                    <a className="sethyperlink" href="https://www.paypal.com/us/cgi-bin/webscr?cmd=_additional-payment-overview-outside" target="_blank">Get support from Paypal </a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="setmargin hrsolid-border" />
                                <div className="paymentbo setmargin">

                                    <div className="">
                                        {this.state.partnerStatusObj ?
                                            activatBtn
                                            : <a href={this.state.paypalUrl} target="_blank" className='btn btn-light setactivebtn'>Activate Paypal Express Checkout</a>}
                                    </div>
                                </div>
                            </div>

                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteToppingsTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteToppings}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default PayPalPaymentCheckout;