import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants from '../../Config/Constants'
import Constvariable, { HOURSSTATIC } from '../../Config/constvariable';
import Select from 'react-select';
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage, getTimeRanges, TimeToHours } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { GET_TOPPING_CATEGORY, MY_STORE_API, GET_PICKUP_TIME, PLAZAID, STOREHOURS_API, SETUPSTOREHOURS_API } from '../../Config/Config.json'
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import { PostData } from '../../../services/PostData';
import Color from '../../Config/Color';
import { DeleteData } from '../../../services/DeleteData';
import RouteMerchant from '../../Config/RouteMerchant.js';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { StoreHourConst } from '../../Config/ConstForm';
import { StoreHourList } from '../../Config/HoverMessage';
import { DatasetOption } from "../../UtillCustomComponent/UtillCustomComponent";
import { PutMandatoryfieldSymbol } from '../../UtillCustomComponent/UtillCustomComponent';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "1080" })
};

class StoreHour extends Component {
    constructor(props) {
        super(props);
        this.state = {

            isActive: false,
            plazaId: "",
            storeId: "",

            timeOptions: DatasetOption(),

            editDayIndex: -1,
            EditTimeList: [],

            clockList: getTimeRanges(HOURSSTATIC.starthour, HOURSSTATIC.endhour),
            selectedFrom: null,
            selectedTo: null,

            errors: {},
            masterTimeOption: [],
            isSubmit: false

        }

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);

        StoreObjectInStorage(Constants.PICKUPTIME_OBJ, '')
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = storeObj.plazaid
            this.setState({ storeId: storeId, plazaId: plazaId })
            this.getStoreHours(authToken, storeId);
        } else {
            this.props.history.goBack()
        }
        console.log(authToken);
    }

    getStoreHours(authToken, storeId) {
        this.setState({ isActive: true, errors: {} });
        var URL = MY_STORE_API + "/" + storeId + "/" + STOREHOURS_API;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ masterTimeOption: responseData });
                        let timeOptions = this.state.timeOptions.map(e => {
                            let index = responseData.findIndex(e1 => e1.day == e.day)
                            if (index >= 0) {
                                return {
                                    ...e,
                                    "isopen": responseData[index].isopen,
                                    "storehours": responseData[index].storehours ? responseData[index].storehours : []
                                }
                            }
                            return e
                        })
                        this.setState({ timeOptions: timeOptions, errors: {} })
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleTimeOption(timeObj) {
        let timeOptions = this.state.timeOptions;
        for (var index in timeOptions) {
            if (timeOptions[index].day == timeObj.day) {
                timeOptions[index].isopen = !timeOptions[index].isopen
            }
            if (index == this.state.editDayIndex && !timeOptions[index].isopen) {
                this.setState({ editDayIndex: -1 })
            }
        }
        this.setState({
            timeOptions: timeOptions,
            isSubmit: true
        })
    }

    handleEditTime(DObj, Ind) {
        this.setState({
            editDayIndex: Ind,
            EditTimeList: DObj.storehours ? DObj.storehours : [],
            selectedFrom: null,
            selectedTo: null,
            errors: {},
        })
    }

    handleFrom = (selectedFrom) => {
        this.setState({ selectedFrom })
    }

    handleTo = (selectedTo) => {
        this.setState({ selectedTo })
    }

    deleteFromArray(TObj, parentIndex) {
        let EditTimeList = this.state.EditTimeList
        EditTimeList = EditTimeList.filter(e => e != TObj)
        let timeOptions = this.state.timeOptions;
        timeOptions[parentIndex].storehours = EditTimeList
        this.setState({
            timeOptions: timeOptions,
            EditTimeList: EditTimeList,
            isSubmit: true
        })
    }

    validateTimeListForm(Obj) {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;
        const { selectedFrom, selectedTo } = this.state

        if ((!this.state.selectedFrom) || (!this.state.selectedTo)) {
            formIsValid = false;
            errors["deliveryTime"] = Messages.v_StoreHours;
        }

        if (selectedFrom && selectedTo) {
            let startTObj = TimeToHours(selectedFrom.value), endTObj = TimeToHours(selectedTo.value)
            if (`${startTObj.hours}:${startTObj.minutes}` >= `${endTObj.hours}:${endTObj.minutes}`) {
                formIsValid = false;
                errors["deliveryTime"] = Messages.v_StoreHours;
            }
            else {
                let startTObj = TimeToHours(selectedFrom.value), endTObj = TimeToHours(selectedTo.value)
                let startTHour = `${startTObj.hours}:${startTObj.minutes}`, endTHour = `${endTObj.hours}:${endTObj.minutes}`, iserror = true
                let tempShour = Obj.storehours ? Obj.storehours : [], flag = false
                for (var t of tempShour) {
                    let [sh, eh] = t ? t.split("-") : []
                    let shTemp = TimeToHours(sh)
                    let ehTemp = TimeToHours(eh)
                    if ((`${shTemp.hours}:${shTemp.minutes}` < endTHour && endTHour <= `${ehTemp.hours}:${ehTemp.minutes}`) ||
                        (`${shTemp.hours}:${shTemp.minutes}` <= startTHour && startTHour < `${ehTemp.hours}:${ehTemp.minutes}`) ||
                        (`${shTemp.hours}:${shTemp.minutes}` <= startTHour && endTHour <= `${ehTemp.hours}:${ehTemp.minutes}`) ||
                        (startTHour < `${shTemp.hours}:${shTemp.minutes}` && `${ehTemp.hours}:${ehTemp.minutes}` < endTHour)
                    ) {
                        flag = true;
                        break
                    }
                }
                if (flag) {
                    formIsValid = false
                    errors["deliveryTime"] = Messages.v_StoreHours1
                }
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSaveInArray(obj, index) {
        let EditTimeList = this.state.EditTimeList;
        let selectedFrom = this.state.selectedFrom;
        let selectedTo = this.state.selectedTo
        let timeOptions = this.state.timeOptions;
        if (this.validateTimeListForm(obj)) {
            let stringTime = selectedFrom.value + " - " + selectedTo.value
            let ind = EditTimeList.findIndex(e => e == stringTime);
            if (ind < 0) {
                EditTimeList.push(stringTime)
            }
            timeOptions[index].storehours = EditTimeList
            this.setState({
                timeOptions: timeOptions,
                EditTimeList: EditTimeList,
                selectedFrom: null,
                selectedTo: null,
                isSubmit: true
            })
        }

    }

    // handleSaveInMain(obj, ind) {
    //     let timeOptions = this.state.timeOptions;
    //     timeOptions[ind].storehours = this.state.EditTimeList
    //     this.setState({
    //         timeOptions: timeOptions,
    //         editDayIndex: -1,
    //         EditTimeList: [],
    //         selectedFrom: null,
    //         selectedTo: null
    //     })
    // }

    cancelArrayAssign() {
        this.setState({
            editDayIndex: -1,
            EditTimeList: [],
            selectedFrom: null,
            selectedTo: null
        })
    }

    handleSubmit() {
        var postData = {}
        this.setState({ isActive: true })
        postData["isadd"] = this.state.masterTimeOption.length == 0
        postData["storehours"] = this.state.timeOptions
        postData["storeid"] = this.state.storeId

        console.log("====", postData)

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.postStoreHours(authToken, postData)
        this.cancelArrayAssign()
    }

    postStoreHours(authToken, postData) {
        this.setState({ errors: {} });
        var URL = MY_STORE_API + "/" + SETUPSTOREHOURS_API;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getStoreHours(authToken, postData["storeid"])
                    this.setState({ isSubmit: false })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    render() {

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner" >
                    <Header setHeaderTitle={Constvariable.ManageStoreHours} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            {/* <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handlePickupTime}
                                    className="btn btn-reg-email">
                                    Add Pickup Time
                                </span>
                            </div> */}

                            <div className="reg_form_main">
                                {/*<div className="row storeTime-header">
                                    <div className="text-center col-12 time-Header selected-header hour-header">Store Hours</div>
                                </div>*/}
                                {this.state.timeOptions.map((obj, i) => {
                                    return <>
                                        <div className="row storetime-li" key={i}>
                                            <div className="row col-sm-8 m-0">
                                                <div className="col-md-2 col-sm-3 col-3">
                                                    <label class="switch m-0">
                                                        <input type="checkbox" onClick={() => this.handleTimeOption(obj)} checked={obj.isopen} />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-2 col-3">
                                                    {obj.isopen && <i class="fas fa-edit get-more-genre mt-1" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleEditTime(obj, i)}></i>
                                                    }
                                                </div>
                                                <div className="col-sm-6 col-6">
                                                    <label className="pt-1 mb-0">
                                                        {obj.day}
                                                    </label>
                                                </div>
                                            </div>
                                            {this.state.editDayIndex == i ?
                                                <div className="col-sm-4 text-right">
                                                    {this.state.EditTimeList.map((lObj, SubIndex) => {
                                                        return <div className="col-sm-12 col-12 mt-1">
                                                            {lObj} &nbsp; <i class="fa fa-trash get-more-genre" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.deleteFromArray(lObj, i)}></i>
                                                        </div>
                                                    })}
                                                </div>
                                                :
                                                <div className="col-sm-4 text-right">
                                                    {
                                                        obj.isopen ?
                                                            obj.storehours.map((t, tind) => {
                                                                return <div className="col-sm-12 mt-1" key={tind}>
                                                                    {t}
                                                                </div>
                                                            })
                                                            :
                                                            <div className="col-sm-12 mt-1 disable-text">
                                                                Closed
                                                            </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {this.state.editDayIndex == i &&
                                            <div className="row col-sm-12 m-0 storeAdd-from">
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-10">
                                                    <label className="col-sm-12 label-color p-0">{StoreHourConst.Starthour}
                                                        &nbsp;
                                                        <OnHoverIconInfo
                                                            message={StoreHourList.Starthour} /> <PutMandatoryfieldSymbol />
                                                    </label>
                                                    <Select menuPortalTarget={document.body}
                                                        value={this.state.selectedFrom}
                                                        onChange={this.handleFrom}
                                                        options={this.state.clockList}
                                                        styles={colourStyles}
                                                        className="select-input"
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-10">
                                                    <label className="col-sm-12 label-color p-0">{StoreHourConst.Endhour}
                                                        &nbsp;
                                                        <OnHoverIconInfo
                                                            message={StoreHourList.Endhour} /> <PutMandatoryfieldSymbol />
                                                    </label>
                                                    <Select menuPortalTarget={document.body}
                                                        value={this.state.selectedTo}
                                                        onChange={this.handleTo}
                                                        options={this.state.clockList}
                                                        styles={colourStyles}
                                                        className="select-input"
                                                    />
                                                </div>
                                                <div className="col-sm-1 col-2 d-flex align-items-center">
                                                    <i class="fa fa-plus get-more-genre" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleSaveInArray(obj, i)}></i>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="errorMsg">
                                                        {this.state.errors.deliveryTime}
                                                    </div>
                                                </div>
                                                <div className="row m-0 col-sm-12 p-0">
                                                    {/* <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                                                        <button type="button" class="btn btn-primary w-100 btn-submit" onClick={() => this.handleSaveInMain(obj, i)}>Save</button>
                                                    </div> */}
                                                    <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                                                        <button type="button"
                                                            class="btn btn-secondary w-100 btn_cancel"
                                                            onClick={() => this.cancelArrayAssign()}>Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                })}
                                <div className="row m-0 mt-5 col-sm-12 p-0 justify-content-around mb-5">
                                    {this.state.isSubmit &&
                                        <div className="col-sm-3">
                                            <button type="button" class="btn btn-primary w-100 btn-submit" onClick={() => this.handleSubmit()}>Submit</button>
                                        </div>
                                    }
                                </div>
                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <center>
                                        <div className="errorMsg">
                                            {this.state.errors.ErrorC}
                                        </div>
                                    </center>
                                </div>
                                <div className="row m-0 mt-5 col-sm-12 p-0 justify-content-around mb-5">
                                    <div className="col-sm-12">
                                        <div className="col-sm-12">
                                            <p>
                                                {StoreHourConst.NoteLabel}
                                            </p>
                                            <span className="text-justify">
                                                {StoreHourConst.StoreHourUpdateMessage}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeletePickupTimesTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeletePickupTimes}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}
                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default StoreHour;