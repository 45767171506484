import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { GetData } from "../../../services/GetData";
import Color from '../../Config/Color';
import { PLAZAID, STOREID, STRIPE_ACCOUNT_DEACTIVE_API, STRIPE_ACCOUNT_LINK_API, STRIPE_ACCOUNT_STATUS_API } from '../../Config/Config.json';
import Constants,{DEFAULTID} from '../../Config/Constants';
import { PlatFrom } from '../../Config/constvariable';
import Messages from "../../Config/Messages";
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import Header from '../../Header/Header.js';
import '../Payments.css';

class StripeStandardCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeId: '',
            stripeurl: "",
            plazaId: "",
            stripeAccountStatusObj: null,
            platForm: "",
            errors: {}
        }
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);

        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID;
            this.setState({ storeId: storeId, plazaId: plazaId });

            var platForm = "";
            if (storeObj.isshopifyintegration) {
                platForm = PlatFrom.Shopify;
            }
            else if (storeObj.iswixintegration) {
                platForm = PlatFrom.Wix;
            }
            else {
                platForm = PlatFrom.Plazacart;
            }
            this.setState({ platForm: platForm });
            this.GetStripeAccountStatus(authToken, storeId, plazaId);
        } else {
            this.props.history.goBack()
        }
    }

    GetStripeAccountStatus(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = STRIPE_ACCOUNT_STATUS_API + "?" + STOREID + storeId + "&" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ stripeAccountStatusObj: responseData });
                    }
                    else {
                        this.setState({ stripeAccountStatusObj: null });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.GetStripeAccountLink(authToken, storeId, plazaId);
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors });
                this.setState({ isActive: false });
            });
    }

    GetStripeAccountLink(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = STRIPE_ACCOUNT_LINK_API + "?" + STOREID + storeId + "&" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ stripeurl: responseData });
                    }
                    else {
                        this.setState({ stripeurl: null })
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    handleStripDeactiveStatus() {
        this.setState({ isActive: true, errors: {} });
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        if (this.state.storeId) {
            var URL = STRIPE_ACCOUNT_DEACTIVE_API + "?" + STOREID + this.state.storeId;
            GetData(URL, authToken)
                .then(result => {
                    var responseJson = result;
                    if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                        var responseData = responseJson["Data"];
                        if (responseData) {
                            this.setState({ stripeAccountStatusObj: responseData });
                        }
                        else {
                            this.setState({ stripeAccountStatusObj: null });
                        }
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                        this.setState({ errors: errors });
                    }
                    this.setState({ isActive: false });
                }).catch(error => {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                });
        }
    }

    handleDelete(toppingObj, flag) {
        if (flag) {
            this.setState({ toppingId: toppingObj._id })
        }
        else {
            this.setState({ toppingId: "" })
        }
    }

    render() {
        const { hideEmptyPlazaView } = this.state;

        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        let activatBtn

        if (this.state.stripeAccountStatusObj) {
            activatBtn = this.state.stripeAccountStatusObj.Status == "ACTIVE" ?
                <label className='btn btn-light setdeactivebtn'
                    target="_self"
                    onClick={() => this.handleStripDeactiveStatus()}>
                    Deactivate Stripe Checkout</label>
                :
                <a href={this.state.stripeurl}
                    target="_blank"
                    className='btn btn-light setactivebtn'>
                    Activate Stripe Checkout
                </a>
        }

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner">
                    <Header setHeaderTitle={"Payment"} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main">
                                <div class="card cartBorder setmargin" >
                                    <div class="card-body">

                                        <div className="paymentbox">
                                            <div className="payment-image-box">
                                                <p>Transaction Fees</p>
                                                <span className="setValue">0%</span>
                                            </div>
                                            <div className="payment-image-box">
                                                <p>Status</p>
                                                <span className="setValue">{this.state.stripeAccountStatusObj ? (this.state.stripeAccountStatusObj.Status == "ACTIVE" ? "ACTIVE" : "INACTIVE") : "INACTIVE"}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="card cartBorder setmargin">
                                    <div class="card-body">
                                        <h5 class="card-title">About Stripe Checkout</h5>
                                        <label class="card-subtitle mb-2">Checkout supports one-time payments and subscriptions for your global customer base with coverage across over twenty local payment methods.
                                            {this.state.platForm == PlatFrom.Shopify && <a className="sethyperlink"
                                                href="https://support.stripe.com/questions/contact-options-for-shopify-and-stripe"
                                                target="_blank"> Learn more.</a>}
                                        </label>
                                        <div className="paymentbox setaligncenter">
                                            <div className="payment-image-box">
                                                <label> Learn more about
                                                    {this.state.platForm == PlatFrom.Shopify && <a className="sethyperlink"
                                                        href="https://support.stripe.com/questions/contact-options-for-shopify-and-stripe"
                                                        target="_blank"> Stripe Checkout.</a>}
                                                </label>
                                            </div>
                                            <div className="payment-image-box">
                                                <button className='btn btn-light setdocbtn' style={{ backgroundColor: "#fff" }}>
                                                    <a className="sethyperlink" href="https://support.stripe.com/contact/email" target="_blank">Get support from Stripe </a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="setmargin hrsolid-border" />
                                <div className="paymentbo setmargin">

                                    <div className="">
                                        {this.state.stripeAccountStatusObj ?
                                            activatBtn
                                            : <a href={this.state.stripeurl} target="_blank" className='btn btn-light setactivebtn'>Activate Stripe Checkout</a>}
                                    </div>
                                </div>
                            </div>

                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteToppingsTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteToppings}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default StripeStandardCheckout;