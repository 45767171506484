import React, { Component } from "react";
import "../../../styles/style.css";
// import './RegistrationNew.css';
import { PostData } from "../../../services/PostData";
import { GetData } from "../../../services/GetData";
import ReactFlagsSelect from 'react-flags-select'; // https://www.npmjs.com/package/react-flags-select
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import {
    validateEmail, validatePassword, validateUserName, validatePhoneNo, validateOtpNo,
    StoreDataInStorage, validateInt
} from "../../Config/Utils";
import { GET_COUNTRY_API, GET_PLAZA_API, COUNTRY_CODE, USER_REGISTRATION_API, VALIDATE_OTP_API } from '../../Config/Config.json'

import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants,{COMPANYCONST} from '../../Config/Constants'
import Color from '../../Config/Color';
import RouteMerchant from "../../Config/RouteMerchant";
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import RegistrationForm from '../../Config/HoverMessage'
import { RegisterNewConstForm } from "../../Config/ConstForm"
import Constvariable from '../../Config/constvariable';

var mandatoryStyle = {
    color: "red",
    textStyle: "bold"
};

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
};

class RegisterNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",

            userFields: {},   //registrstion fields
            errors: {},

            countryFields: {},  //select country fields
            countryData: [],     //array of country list bind from API -> country fields

            plazaFields: {},  //select plaza fields
            plazaList: [],   //array of plaza list bind from API -> plaza fields         
            selectedOption: null,  //select plaza fields
            src: null,
            showPlazaForm: false,

            otp: {},  //otp fields
            IsWithPlaza: false

        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangePlaza = this.handleChangePlaza.bind(this)
        this.handleValidationOnBlur = this.handleValidationOnBlur.bind(this);

        this.handleCountrySubmit = this.handleCountrySubmit.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleValidationCountryonBlur = this.handleValidationCountryonBlur.bind(this);
        this.onSelectFlag = this.onSelectFlag.bind(this);

        this.handlePlazaSubmit = this.handlePlazaSubmit.bind(this);
        this.handleOnPlazaSelect = this.handleOnPlazaSelect.bind(this);

        this.handleValidateOtpSubmit = this.handleValidateOtpSubmit.bind(this);
        this.handleValidateOtpChange = this.handleValidateOtpChange.bind(this);
        this.handleValidationOTPonBlur = this.handleValidationOTPonBlur.bind(this);

    }

    componentDidMount() {

    }



    /***************************************************************************************** */
    // START --> FUNCTIONS FOR REGISTRATION MODULE
    /***************************************************************************************** */

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
        // if (key === 'Name' && value[0] === ' ') value = value.trim()
    }

    handleValidationOnBlur(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name == "Email") {
            if (!fields["Email"]) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterEmail;
            }
            else if (typeof fields["Email"] !== "undefined") {
                if (!validateEmail(fields["Email"])) {
                    formIsValid = false;
                    errors["Email"] = Messages.v_EnterValidEmail;
                }
            }
        }
        else if (name == "FirstName") {
            if (!fields["FirstName"]) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterName;
            }
            else if (typeof fields["FirstName"] !== "undefined") {
                if (!validateUserName(fields["FirstName"])) {
                    formIsValid = false;
                    errors["FirstName"] = Messages.v_EnterValidName;
                }
            }
        }
        else if (name == "LastName") {
            if (!fields["LastName"]) {
                formIsValid = false;
                errors["LastName"] = Messages.v_EnterLastName;
            }
        }
        else if (name == "Password") {
            if (!fields["Password"]) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterPassword
            }
            else if (typeof fields["Password"] !== "undefined") {
                if (!validatePassword(fields["Password"])) {
                    formIsValid = false;
                    errors["Password"] = Messages.v_EnterValidPassword;
                }
            }
        }
        else if (name == "PhoneNumber") {
            if (!fields["PhoneNumber"]) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterPhoneNo
            }
            else if (typeof fields["PhoneNumber"] !== "undefined") {
                if (!validatePhoneNo(fields["PhoneNumber"])) {
                    formIsValid = false;
                    errors["PhoneNumber"] = Messages.v_EnterValidPhoneNo;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }


    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = Messages.v_EnterEmail;
        }
        else if (typeof fields["Email"] !== "undefined") {
            if (!validateEmail(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }

        if (!fields["FirstName"]) {
            formIsValid = false;
            errors["FirstName"] = Messages.v_EnterName;
        }
        else if (typeof fields["FirstName"] !== "undefined") {
            if (!validateUserName(fields["FirstName"])) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterValidName;
            }
        }

        if (!fields["LastName"]) {
            formIsValid = false;
            errors["LastName"] = Messages.v_EnterLastName;
        }

        if (!fields["Password"]) {
            formIsValid = false;
            errors["Password"] = Messages.v_EnterPassword
        }
        else if (typeof fields["Password"] !== "undefined") {
            if (!validatePassword(fields["Password"])) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterValidPassword;
            }
        }

        if (!fields["PhoneNumber"]) {
            formIsValid = false;
            errors["PhoneNumber"] = Messages.v_EnterPhoneNo
        }
        else if (typeof fields["PhoneNumber"] !== "undefined") {
            if (!validatePhoneNo(fields["PhoneNumber"])) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterValidPhoneNo;
            }
        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {

        var postData = {};
        e.preventDefault();

        if (this.validateForm()) {
            document.getElementById("step1").checked = true;
            this.setState({ screenTitle: "Select Country" });
            this.getCountryAPI();

        }
    }
    /***************************************************************************************** */
    // END --> FUNCTIONS FOR REGISTRATION MODULE
    /***************************************************************************************** */


    /***************************************************************************************** */
    // START --> FUNCTIONS FOR SELECT COUNTRY MODULE
    /***************************************************************************************** */

    getCountryAPI() {
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        var countryCode = [];
                        for (var i = 0; i < responseData.length; i++) {
                            var obj = responseData[i].CountryCode;
                            countryCode.push(obj);
                        }
                        this.setState({ countryData: countryCode });
                    }
                    console.log("countryData ", this.state.countryData);
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
            });
    }

    handleCountryChange(event) {
        let countryFields = this.state.countryFields;
        let key = event.target.name
        let value = event.target.value
        countryFields[key] = value
        this.setState({ countryFields })
    }

    onSelectFlag(countryCode) {
        let countryFields = this.state.countryFields;
        countryFields["CountryCode"] = countryCode
        this.setState({ countryFields })
        var errors = this.state.errors.CountryCode;
        this.setState({ errors: {} });

    }


    handleValidationCountryonBlur(e) {
        let fields = this.state.countryFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;
        if (name === "PostalCode") {
            if (!fields["PostalCode"]) {
                formIsValid = false;
                errors["PostalCode"] = Messages.v_EnterPostalCode
            }
        }

    }


    validateCountryForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.countryFields;

        if (!fields["CountryCode"]) {
            formIsValid = false;
            errors["CountryCode"] = Messages.v_EnterCountryName;
        }

        if (!fields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCountrySubmit(e) {
        var postData = {};
        e.preventDefault();

        if (this.validateCountryForm()) {
            //postData = this.state.userFields;
            // postData["CountryCode"] = this.state.countryFields.CountryCode;
            // postData["PostalCode"] = this.state.countryFields.PostalCode; 
            // document.getElementById("step3").checked = true;
            // this.setState({screenTitle:"Select Plaza"});     
            this.setState({ showPlazaForm: false })
            this.getPlazaByPostalCodeAPI(this.state.countryFields.PostalCode, this.state.countryFields.CountryCode);

        }
    }


    /***************************************************************************************** */
    // END --> FUNCTIONS FOR SELECT COUNTRY MODULE
    /***************************************************************************************** */


    /***************************************************************************************** */
    // START --> FUNCTIONS FOR SELECT PLAZA MODULE
    /***************************************************************************************** */

    getPlazaByPostalCodeAPI(postalCode, countryCode) {
        var API = GET_PLAZA_API + postalCode + COUNTRY_CODE + countryCode;
        GetData(API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        document.getElementById("step2").checked = true;
                        this.setState({ screenTitle: "Select Plaza" });
                        this.setState({ plazaList: responseData });
                    }
                }
                else {
                    alert(Messages.NO_DATA);
                }
            });
    }

    handleOnPlazaSelect = selectedOption => {
        if (selectedOption) {
            this.setState({ selectedOption });
            console.log(`Option selected:`, selectedOption.value);
            // let plazaFields = this.state.plazaFields;
            // plazaFields["TenantEmail"] = selectedOption.value
            // this.setState({ plazaFields })
            // var errors = this.state.errors.TenantEmail;
            // this.setState({ errors: {} });
        }
        else {
            this.setState({ selectedOption: null })
        }
    };

    validatePlazaForm() {
        let errors = {};
        let formIsValid = true;

        let fields = this.state.plazaFields;
        if (!fields["TenantEmail"]) {
            // formIsValid = false;
            // errors["TenantEmail"] = Messages.v_EnterPlaza;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    // Code to open image file uploader and select file and set image to src
    onSelectFile = e => {
        let plazaFields = this.state.plazaFields;
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ src: reader.result })
                plazaFields['imagebase64'] = reader.result
                this.setState({ plazaFields })
                console.log(reader.result)
            }, { orientation: true })
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    handleChangePlaza(event) {
        let plazaFields = this.state.plazaFields;
        let key = event.target.name
        let value = event.target.value
        plazaFields[key] = value
        this.setState({ plazaFields })
        // if (key === 'Name' && value[0] === ' ') value = value.trim()
    }

    validatePlaza() {
        let plazaFields = this.state.plazaFields;
        let errors = {}
        let formIsValid = true;

        if (!plazaFields['PlazaName1']) {
            formIsValid = false;
            errors['PlazaName1'] = Messages.v_EnterPlazaName;
        }

        if (!plazaFields['Email']) {
            formIsValid = false;
            errors['Email'] = Messages.v_emailId;
        }
        // else if (plazaFields["Email"]) {
        //     if (!validateEmail(plazaFields["Email"])) {
        //         formIsValid = false;
        //         errors["Email"] = Messages.v_EnterValidEmail;
        //     }
        // }
        if (!plazaFields['Address']) {
            formIsValid = false;
            errors['Address'] = Messages.v_PalazAddress;
        }

        if (!this.state.src) {
            formIsValid = false;
            errors['src'] = Messages.S_BANNERPlaza;
        }
        // this.setState({ errors })
        return formIsValid
    }

    validateProperValue() {
        let plazaFields = this.state.plazaFields;
        let errors = {}
        let formIsValid = true;
        if (plazaFields["Email"]) {
            if (!validateEmail(plazaFields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }
        if (plazaFields["PlazaPhoneNumber"]) {
            if (!validateInt(plazaFields["PlazaPhoneNumber"])) {
                formIsValid = false;
                errors["PlazaPhoneNumber"] = Messages.v_EnterValidEmail;
            }
        }
        this.setState({ errors })
        return formIsValid
    }

    handlePlazaSubmit(e) {
        var postData = {};
        e.preventDefault();
        if (this.validatePlazaForm()) {
            postData = this.state.userFields;
            postData["CountryCode"] = this.state.countryFields.CountryCode;
            postData["PostalCode"] = String(this.state.countryFields.PostalCode).toUpperCase();

            postData["DeviceId"] = Constants.DEVICE_ID;
            postData["DeviceType"] = Constants.DEVICE_TYPE;
            postData["Company"] = Constants.COMPANY;
            postData["PushToken"] = Constants.PUSH_TOKEN;
            postData["Latitude"] = Constants.LATITUDE;
            postData["Longitude"] = Constants.LONGITUDE;
            if (this.state.selectedOption) {
                postData["TenantEmail"] = this.state.selectedOption ? this.state.selectedOption.value : "";

            }
            else {
                if (this.validatePlaza()) {
                    if (!this.validateProperValue()) {
                        return ""
                    }
                    var plazaObj = this.state.plazaFields;
                    plazaObj['CountryCode'] = this.state.countryFields.CountryCode;
                    plazaObj['Company'] = this.state.plazaFields.PlazaName1
                    plazaObj['PostalCode'] = String(this.state.countryFields.PostalCode).toUpperCase();
                    plazaObj['phonenumber'] = this.state.plazaFields.PlazaPhoneNumber;
                    plazaObj['address'] = this.state.plazaFields.Address;
                    postData["Plaza"] = plazaObj
                    postData["TenantEmail"] = this.state.plazaFields.Email;
                    delete plazaObj['PlazaName1']
                    this.setState({ IsWithPlaza: true })
                }
                else {
                    postData["TenantEmail"] = "";
                    this.setState({ IsWithPlaza: false })
                }

            }

            console.log('====>', postData)
            console.log('data: ', JSON.stringify(postData));

            this.callResgisterAPI(postData);
        }
    }

    callResgisterAPI(postData) {
        var URL = USER_REGISTRATION_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    document.getElementById("step3").checked = true;
                    this.setState({ screenTitle: "Validate OTP" });
                }
                else {
                    alert(responseJson["Message"])
                }
            });
    }


    /***************************************************************************************** */
    // END --> FUNCTIONS FOR SELECT PLAZA MODULE
    /***************************************************************************************** */


    /***************************************************************************************** */
    // START --> FUNCTIONS FOR VALIDATE OTP MODULE
    /***************************************************************************************** */

    handleValidateOtpChange(event) {
        let otp = this.state.otp;
        let value = event.target.value
        otp["otp"] = value
        this.setState({ otp })
    }

    handleValidationOTPonBlur(e) {
        let fields = this.state.otp;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name === "otp") {
            if (!fields["otp"]) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterOTP
            }
            else if (typeof fields["otp"] !== "undefined") {
                if (!validateOtpNo(fields["otp"])) {
                    formIsValid = false;
                    errors["otp"] = Messages.v_EnterValidOTP;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }


    validateOTPForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.otp;


        if (!fields["otp"]) {
            formIsValid = false;
            errors["otp"] = Messages.v_EnterOTP
        }
        else if (typeof fields["otp"] !== "undefined") {
            if (!validateOtpNo(fields["otp"])) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterValidOTP;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleValidateOtpSubmit(e) {
        e.preventDefault();
        if (this.validateOTPForm()) {
            var postData = {};
            postData["Email"] = this.state.userFields.Email;
            postData["PhoneNumber"] = this.state.userFields.PhoneNumber;
            postData["DeviceId"] = Constants.DEVICE_ID;
            postData["DeviceType"] = Constants.DEVICE_TYPE;
            postData["Code"] = this.state.otp.otp;
            console.log('otppostdata: ', postData);
            this.callValidateOtpAPI(postData);
        }
    }

    callValidateOtpAPI(postData) {
        var URL = VALIDATE_OTP_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log('responseJason:', responseJson);

                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var authToken = responseJson["Data"].Token;
                    StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                    if (this.state.IsWithPlaza) {
                        this.handalModal()
                    }
                    else {
                        this.props.history.push({
                            pathname: RouteMerchant.Login,
                        })
                    }

                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
            });
    }

    handalModal() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModalWithPlaza')

        if (backgroung && modalId) {
            backgroung.style.display = "block"
            modalId.style.display = "block"
        }
    }

    closeModal() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModalWithPlaza')

        if (backgroung && modalId) {
            backgroung.style.display = "none"
            modalId.style.display = "none"
        }
        this.props.history.push({
            pathname: RouteMerchant.Login,
        })
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR VALIDATE OTP MODULE
    /***************************************************************************************** */


    render() {
        const { showPlazaForm, selectedOption, plazaList } = this.state;
        const options = plazaList.map(v => ({
            label: v.CompanyName,
            value: v.TenantEmail
        }));

        return (
            <section className="section">
                <div className="modal-background" id="modalbackgroundId"> </div>
                <div className="registed_tab_part" style={{ position: "relative", padding: "20px 0px 0px 0px" }}>
                    <div className="container">
                        <p id="" style={{ display: "block" }}><h4 style={{ color: Color.THEME_COLOR }}>{this.state.screenTitle}</h4></p>
                        {/* <p><h4>General Store Information</h4></p> */}
                        <div className="" >
                            <div className="registed_form_tab setmindiv" style={{ color: "#333" }}>
                                <input id="step0" className="hiddenCheckBox" type="checkbox" />
                                <input id="step1" className="hiddenCheckBox" type="checkbox" />
                                <input id="step2" className="hiddenCheckBox" type="checkbox" />
                                <input id="step3" className="hiddenCheckBox" type="checkbox" />
                                <input id="step4" className="hiddenCheckBox" type="checkbox" />
                                <input id='step5' className="hiddenCheckBox" type='checkbox' />

                                <div id="part0">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">
                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv" for="Email">
                                                        {RegisterNewConstForm.Email}   <OnHoverIconInfo
                                                            message={RegistrationForm.Email} />
                                                    </div>
                                                    <input

                                                        type="email"
                                                        placeholder={Constvariable.R_Email}
                                                        name="Email"
                                                        value={this.state.userFields.Email}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur}

                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.Email}
                                                    </div>{" "}
                                                    <div className="errorMsg">
                                                        {this.state.alreadyEmail}
                                                    </div>
                                                </div>


                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv" for="FirstName">
                                                        {RegisterNewConstForm.Name}    <OnHoverIconInfo
                                                            message={RegistrationForm.Name} />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        placeholder={Constvariable.YourName}
                                                        name="FirstName"
                                                        value={this.state.userFields.FirstName}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.FirstName}
                                                    </div>
                                                </div>

                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv" for="LastName">
                                                        {RegisterNewConstForm.LastName} <OnHoverIconInfo
                                                            message={RegistrationForm.LastName} />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        placeholder={Constvariable.YourLastName}
                                                        name="LastName"
                                                        value={this.state.userFields.LastName}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.LastName}
                                                    </div>
                                                </div>

                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv" for="PhoneNumber">
                                                        {RegisterNewConstForm.PhoneNumber} <OnHoverIconInfo
                                                            message={RegistrationForm.PhoneNumber} />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        placeholder={Constvariable.YourPhoneNumber}
                                                        name="PhoneNumber"
                                                        maxLength="10"
                                                        value={this.state.userFields.PhoneNumber}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.PhoneNumber}
                                                    </div>
                                                </div>

                                                <div className="reg_form_main offset-sm-3 col-sm-6">

                                                    <div className="col-sm-12 setHoverDiv" for="Password">
                                                        {RegisterNewConstForm.Password}  <OnHoverIconInfo
                                                            message={RegistrationForm.Password} />
                                                    </div>
                                                    <input
                                                        type="password"
                                                        placeholder={Constvariable.Password}
                                                        name="Password"
                                                        value={this.state.userFields.Password}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur}
                                                        onFocus={this.validatePass}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.Password}
                                                    </div>
                                                </div>


                                                <div className="bottam_sing">
                                                    <label
                                                        for="step1"
                                                        id="continue-step1"
                                                        style={{ background: Color.THEME_COLOR }}
                                                        onClick={this.handleSubmit}
                                                        className="btn btn-done"
                                                    > {RegisterNewConstForm.REGISTER}</label>
                                                </div>

                                                <div className="reg_form_main">
                                                    <center><a href={RouteMerchant.Login}
                                                        className="col-sm-12 textcolor">{RegisterNewConstForm.AlreadyRegistered}</a></center>
                                                </div>

                                                <div className="reg_form_main offset-sm-3 col-sm-6 settextalignterms textcolor">
                                                    By creating an account or logging in, you agree to {COMPANYCONST.BREWERSNEARME}'s <a href=""
                                                        className="setasatag">Conditions of Use</a> and  <a tabIndex=""
                                                            className="setasatag">Privacy Policy.</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="part1">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">
                                                <div className="reg_form_main offset-sm-3 col-sm-6" style={{ color: "black" }}>
                                                    <ReactFlagsSelect
                                                        className="menu-flags"
                                                        name="CountryCode"
                                                        //defaultCountry="US"
                                                        // countries={["US", "CA", "IN"]}
                                                        style={{ color: "black" }}
                                                        countries={this.state.countryData}
                                                        placeholder={Constvariable.R_Country}
                                                        selectedSize={18}
                                                        optionsSize={16}
                                                        onSelect={this.onSelectFlag}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.CountryCode}
                                                    </div>
                                                </div>

                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv" for="Email">
                                                        {RegisterNewConstForm.PostalCode}  <OnHoverIconInfo
                                                            message={RegistrationForm.PostalCode} />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={this.handleCountryChange}
                                                        name="PostalCode"
                                                        value={this.state.countryFields.PostalCode}
                                                        placeholder={Constvariable.Postalcode}
                                                        onBlur={this.handleValidationCountryonBlur}

                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.PostalCode}
                                                    </div>
                                                </div>
                                                <div className="bottam_sing">
                                                    <label
                                                        for="step1"
                                                        id="back-step0"
                                                        style={{ background: Color.THEME_COLOR }}
                                                        className="btn btn-skip">
                                                        {RegisterNewConstForm.PREV}
                                                    </label>
                                                    <label
                                                        for="step2"
                                                        id="continue-step2"
                                                        style={{ background: Color.THEME_COLOR }}
                                                        onClick={this.handleCountrySubmit}
                                                        className="btn btn-done"> {RegisterNewConstForm.Continue} </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="part2">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">
                                                {!showPlazaForm && <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv" for="Email">
                                                        {RegisterNewConstForm.SelectPlaza}  <OnHoverIconInfo
                                                            message={RegistrationForm.SelectPlaza} />
                                                    </div>
                                                    <Select
                                                        value={selectedOption}
                                                        isClearable={true}
                                                        menuPortalTarget={document.querySelector('body')}
                                                        onChange={this.handleOnPlazaSelect}
                                                        options={options}
                                                        styles={colourStyles}
                                                        placeholder={Constvariable.Plaza}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.TenantEmail}
                                                    </div>
                                                </div>
                                                }


                                                {showPlazaForm
                                                    ? <div id="PlazaForm">
                                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                                            <div className="col-sm-12 setHoverDiv">
                                                                {RegisterNewConstForm.PlazaName} <OnHoverIconInfo
                                                                    message={RegistrationForm.PlazaName} />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="PlazaName1"
                                                                onChange={this.handleChangePlaza}
                                                                value={this.state.plazaFields.PlazaName1}
                                                                placeholder={Constvariable.R_PlazaName}

                                                            />
                                                            <div className="errorMsg">
                                                                {this.state.errors.PlazaName1}
                                                            </div>
                                                        </div>
                                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                                            <div className="col-sm-12 setHoverDiv">
                                                                {RegisterNewConstForm.PlazaEmail} <OnHoverIconInfo
                                                                    message={RegistrationForm.PlazaEmail} />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="Email"
                                                                onChange={this.handleChangePlaza}
                                                                value={this.state.plazaFields.Email}
                                                                placeholder={Constvariable.R_PlazaEmail}

                                                            />
                                                            <div className="errorMsg">
                                                                {this.state.errors.Email}
                                                            </div>
                                                        </div>
                                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                                            <div className="col-sm-12 setHoverDiv">
                                                                {RegisterNewConstForm.PlazaPhoneNumber} <OnHoverIconInfo
                                                                    message={RegistrationForm.PlazaPhoneNumber} />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="PlazaPhoneNumber"
                                                                maxLength="14"
                                                                onChange={this.handleChangePlaza}
                                                                value={this.state.plazaFields.PlazaPhoneNumber}
                                                                placeholder={Constvariable.R_PlazaPhoneNumber}

                                                            />
                                                            <div className="errorMsg">
                                                                {this.state.errors.PlazaPhoneNumber}
                                                            </div>
                                                        </div>
                                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                                            <div className="col-sm-12 setHoverDiv">
                                                                {RegisterNewConstForm.Address} <OnHoverIconInfo
                                                                    message={RegistrationForm.Address} />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="Address"
                                                                onChange={this.handleChangePlaza}
                                                                value={this.state.plazaFields.Address}
                                                                placeholder={Constvariable.R_Address}

                                                            />
                                                            <div className="errorMsg">
                                                                {this.state.errors.Address}
                                                            </div>
                                                        </div>
                                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                                            <div className="col-sm-12 setHoverDiv">
                                                                {RegisterNewConstForm.UploadImage} <OnHoverIconInfo
                                                                    message={RegistrationForm.UploadImage} />
                                                            </div>
                                                            <center>
                                                                <img
                                                                    style={{ margin: "10px 0px" }}
                                                                    className="profilePic"
                                                                    src={this.state.src} />
                                                            </center>
                                                            <div className="col-sm-12">
                                                                <div className="btn-upload">
                                                                    <label className="btn" for="updateCover" style={{ padding: "4px 0px 0px 0px ", background: Color.THEME_COLOR }}>
                                                                        {RegisterNewConstForm.Upload}
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        id="updateCover"
                                                                        style={{ opacity: "0" }}
                                                                        data-id="cover"
                                                                        className="hideAction"
                                                                        onChange={this.onSelectFile} />
                                                                </div>
                                                            </div>
                                                            <div className="errorMsg">
                                                                {this.state.errors.src}
                                                            </div>
                                                        </div>
                                                        <div className="bottam_sing">
                                                            <label
                                                                for="step2"
                                                                id="back-step1"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                className="btn btn-skip">
                                                                {RegisterNewConstForm.PREV}
                                                            </label>
                                                            <label
                                                                for="step3"
                                                                id="continue-step3"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                onClick={this.handlePlazaSubmit}
                                                                className="btn btn-done"
                                                            >  {RegisterNewConstForm.Continue}</label>
                                                        </div>
                                                    </div>

                                                    : null
                                                }

                                                {selectedOption ? <div className="bottam_sing">
                                                    <label
                                                        for="step2"
                                                        id="back-step1"
                                                        style={{ background: Color.THEME_COLOR }}
                                                        className="btn btn-skip">
                                                        {RegisterNewConstForm.PREV}
                                                    </label>
                                                    <label
                                                        for="step3"
                                                        id="continue-step3"
                                                        style={{ background: Color.THEME_COLOR }}
                                                        onClick={this.handlePlazaSubmit}
                                                        className="btn btn-done"
                                                    >  {RegisterNewConstForm.Continue}</label>
                                                </div> :
                                                    showPlazaForm ? null : <div className="bottam_sing">
                                                        <label
                                                            for="step2"
                                                            id="back-step1"
                                                            style={{ background: Color.THEME_COLOR }}
                                                            className="btn btn-skip">
                                                            {RegisterNewConstForm.PREV}
                                                        </label>
                                                        <button
                                                            type="button"
                                                            id="btnAddPlaza"
                                                            // hidden="false"
                                                            style={{ background: Color.THEME_COLOR }}
                                                            className="btn btn-done"
                                                            onClick={() => this.setState({ showPlazaForm: !showPlazaForm, screenTitle: "Add Plaza" })}
                                                        > {RegisterNewConstForm.AddPlaza}</button>
                                                    </div>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="part3">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">
                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv" for="otp">
                                                        {RegisterNewConstForm.Enterotp} <OnHoverIconInfo
                                                            message={RegistrationForm.Enterotp} />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        inputmode="numeric"
                                                        placeholder={Constvariable.OtpNumber}
                                                        name="otp"
                                                        maxLength="6"
                                                        value={this.state.otp.otp}
                                                        onChange={this.handleValidateOtpChange}
                                                        onBlur={this.handleValidationOTPonBlur}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.otp}
                                                    </div>
                                                </div>


                                                <div className="bottam_sing">
                                                    <label
                                                        for="step1"
                                                        id="continue-step1"
                                                        style={{ background: Color.THEME_COLOR }}
                                                        onClick={this.handleValidateOtpSubmit}
                                                        className="btn btn-done"
                                                    >  {RegisterNewConstForm.SUBMIT}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal" id="myModalWithPlaza">
                                    <div class="modal-dialog setmodaldilog">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <center>
                                                    <h5>{Messages.popupPlazaTitle}</h5>
                                                </center>

                                                <div className="reg_form_main col-sm-10 offset-sm-1" >
                                                    <label className="label-color">{Messages.popupPlazaMsg1}</label>
                                                    <label className="label-color">{Messages.popupPlazaMsg2}</label>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <label
                                                    style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                                    data-dismiss="modal"
                                                    onClick={() => this.closeModal()}
                                                > Ok</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default RegisterNew;