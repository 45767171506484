import React, { Component } from 'react';
import StoreList from "../StoreList/StoreList";
import Messages from "../Config/Messages";
import Constants from '../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../Config/Utils";
import { GetData } from "../../services/GetData";
import { GET_NOTIFICATION } from '../Config/Config.json'
import Header from '../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../Config/Color';
import RouteMerchant from '../Config/RouteMerchant.js';
import Constvariable from '../Config/constvariable';
import emailread from "../../images/icons/read.png";
import emailUnread from "../../images/icons/Unread.png";
import { DeleteData } from '../../services/DeleteData';
import delete1 from "../../images/icons/delete.png";

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeId: "",
            isActive: false,
            notificationId: '',
            notificationList: [],
            errors: {}
        }
        this.handleAddProducts = this.handleAddProducts.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

        this.getNotification(authToken);
        console.log(authToken);
    }

    getNotification(authToken) {
        this.setState({ isActive: true, errors: {} })
        var URL = GET_NOTIFICATION
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ notificationList: responseData });
                        this.setState({ loaderClass: false });
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    handleAddProducts() {
        this.props.history.push({
            pathname: "/addItems",
        })
    }

    handleDetail(notificationObj) {
        this.props.history.push({
            pathname: RouteMerchant.NotificationDetail,
            search: "?id=" + notificationObj._id
        })
    }

    handleDelete(notificationObj, flag) {
        if (flag) {
            this.setState({ notificationId: notificationObj._id })
        }
        else {
            this.setState({ notificationId: "" })
        }
    }


    deleteItem() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var notificationId = this.state.notificationId;
        this.deleteItemAPI(authToken, notificationId)
    }

    deleteItemAPI(authToken, notificationId) {
        this.setState({ isActive: true, errors: {} })
        var URL = GET_NOTIFICATION + "/" + notificationId;
        var postData = {}
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ notificationId: '' })
                    this.getNotification(authToken);
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = responseJson["Message"];
                    this.setState({ errors: errors });
                    this.setState({ isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    render() {
        const { hideEmptyPlazaView } = this.state;
        const columns = [
            {
                name: "From UserName",
                cell: row => <div
                    style={{ marginLeft: "0px", fontSize: "15px" }}
                    onClick={() => this.handleDetail(row)}>{row.FromUserName}</div>
            },
            {
                name: "Notification Subject",
                cell: row => {
                    if (row.NotificationSubject) {
                        return row.NotificationSubject.length > 25 ?
                            <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.NotificationSubject.slice(0, 25) + "..."}</div> :
                            <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.NotificationSubject}</div>
                    }
                }
            },
            {
                name: "Notification Message",
                cell: row => {
                    if (row.NotificationMessage) {
                        return row.NotificationMessage.length > 50 ?
                            <div style={{ marginLeft: "0px", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: row.NotificationMessage.slice(0, 50) + "..." }} /> :
                            <div style={{ marginLeft: "0px", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: row.NotificationMessage }} />

                    }

                }
            },
            {
                cell: row => <div className="registered-with" onClick={() => this.handleDetail(row)}>
                    {row.IsRead ?
                        <img src={emailread} style={{ height: "20px", width: "20px" }}></img> :
                        <img src={emailUnread} style={{ height: "20px", width: "20px" }}></img>
                    }
                    {/* <span
                        style={{ background: Color.THEME_COLOR }}
                        onClick={() => this.handleDetail(row)}
                        className="btn btn-reg-email">
                        view Detail
                    </span> */}
                </div>,
                maxWidth: "30px"
            },
            {
                // cell: row => <img src={delete1} style={{ width: "20px", height: "20px" }} onClick={(value) => this.handleDelete(row)}></img>,
                cell: row => <a data-toggle="modal" data-target="#exampleModalLong">
                    <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleDelete(row, true)}></i>
                </a>,
                maxWidth: "30px"
            },

        ];


        return (
            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.Notification} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="reg_form_main">
                                {!hideEmptyPlazaView
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img></img>
                                            <p className="col-sm-12" for="FirstName">

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <DataTableExtensions
                                        data={this.state.notificationList}
                                        columns={columns}
                                        export={false}
                                        print={false}
                                        filter={this.state.notificationList.length > 0 ? true : false}
                                        filterHidden={false}
                                        filterPlaceholder={Messages.SearchforNotification}
                                        filterStyle={{ width: "100px" }} >
                                        <DataTable
                                            noHeader={true}
                                        // noTableHead={true}
                                        // //pagination
                                        // onRowClicked={this.handleRowClick}
                                        />
                                    </DataTableExtensions>
                                }
                            </div>
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteNotificationTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteNotification}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default Notification;