import React, { Component } from 'react';
import './SideMenu.css';
import Color from '../Config/Color';
// import RouteConstant from './Config/RouteConstant';

import delete1 from "../../images/icons/delete.png";
import read from "../../images/icons/read.png";

import {  withRouter } from 'react-router-dom';

class NotificationList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleItemClick = this.handleItemClick.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    componentDidMount() {
    }

    handleItemClick(data){
        console.log("item click")
        console.log(JSON.stringify(data))
        this.closeNav()
        
        // this.props.history.push({
        //     pathname: RouteConstant.NotificationDetail,
        // })
    }

    closeNav() {
        window.closeNav();
    }

    render() {
        let list = this.props.data
            .map(function (item, index) {             
                return (
                    <React.Fragment key={item.OrderId}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, paddingBottom:"5px", borderBottom: "1px solid" + Color.GREY }} onClick={(value) => this.handleItemClick(item)} >
                            <div className="row" style={{ fontSize: "12px", marginLeft:"1px", marginRight:"3px" }}>                               
                                {item.Name} 
                            </div>
                            <div className="row" style={{ fontSize: "12px", marginLeft:"2px", marginRight:"5px" }}>                               
                                {item.date} 
                            </div>
                           
                            <div className="row" >    
                                <span style={{ marginLeft: "auto" }}>
                                    <img src={read} style={{marginRight:"10px", width:"20px", height:"20px"}} alt={read}></img>
                                    <img src={delete1} style={{float:"right", marginRight:"16px", width:"20px", height:"20px"}} alt={delete1}></img>
                                    {/* <i class="fa fa-envelope-open" style={{fontSize: "20px", color:Color.THEME_COLOR,  marginRight:"10px", width:"20px"}}></i> */}
                                    {/* <i class="fa fa-trash"  style={{ fontSize: "20px",color:Color.GREY,float:"right", marginRight:"16px"  }}></i> */}
                                 </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);

        return (
            <div> 
                {list}
            </div>
        );
    }
}

export default withRouter(NotificationList);