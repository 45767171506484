import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreDataInStorage } from "../../Config/Utils";
import { GET_PRODUCT_CATEGORY, PLAZAID } from '../../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants, {DEFAULTID} from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
import SideMenu from "../../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import Constvariable from '../../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { GetData } from "../../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { POST_STORE_CATEGORY, GET_TOPPING_CATEGORY } from '../../Config/Config.json';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { ToppingsCategoryForm } from '../../Config/HoverMessage'
import { ToppingsCategoryConstForm } from "../../Config/ConstForm"

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
};

class AddToppingsCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            errors: {},

            isActive: false,
            plazaId: "",
            storeId: "",
            ItemId: '',
            toppingCategoryId: ''

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            var storeItemObj = RetriveObjectFromStorage(Constants.STROE_ITEM_OBJ);
            if (storeItemObj) {
                var itemId = storeItemObj._id;
                this.setState({ ItemId: itemId });
                var toppingCategoryObj = RetriveObjectFromStorage(Constants.TOPPING_CATEGORY_OBJ);
                if (toppingCategoryObj) {
                    this.setState({ toppingCategoryId: toppingCategoryObj._id })
                    this.autoPopulated(toppingCategoryObj)
                }
            } else {
                this.props.history.goBack()
            }
        } else {
            this.props.history.goBack()
        }
    }

    autoPopulated(toppingCategoryObj) {
        let userFields = this.state.userFields;
        userFields['toppingCategory'] = toppingCategoryObj.ToppingCategory;
        this.setState({ userFields })
    }


    // /////////////// end detail methods ////////////////////////////


    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["toppingCategory"]) {
            formIsValid = false;
            errors["toppingCategory"] = Messages.v_toppingCategory;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['ToppingCategory'] = this.state.userFields.toppingCategory;
            postData['ItemId'] = this.state.ItemId;

            console.log("==> postdata==", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            var storeId = this.state.storeId
            var plazaId = this.state.plazaId
            if (this.state.toppingCategoryId != '' && this.state.toppingCategoryId) {
                postData['ToppingCategoryId'] = this.state.toppingCategoryId;
                this.putToppingCategory(authToken, postData, storeId, plazaId)
            }
            else {
                this.postToppingCategory(authToken, postData, storeId, plazaId)
            }

        }
    }


    postToppingCategory(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL1 = GET_TOPPING_CATEGORY.replace('#id#', storeId);
        var URL = URL1.replace('#pid#/', "");
        URL = URL + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }


    putToppingCategory(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL1 = GET_TOPPING_CATEGORY.replace('#id#', storeId);
        var URL = URL1.replace('#pid#/', "");
        URL = URL + "?" + PLAZAID + plazaId
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }

    render() {

        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner" >
                    <Header setHeaderTitle={this.state.toppingCategoryId ? ToppingsCategoryConstForm.UpdateToppingCategory : ToppingsCategoryConstForm.AddToppingsCategory}></Header>
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label className="label-color">{ToppingsCategoryConstForm.ToppingCategory}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ToppingsCategoryForm.ToppingCategory} />
                            </label>
                            <input
                                type="text"
                                name="toppingCategory"
                                className='add-item-box'
                                placeholder={Constvariable.ToppingCategory}
                                value={this.state.userFields.toppingCategory}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.toppingCategory}
                            </div>
                        </div>

                    </div>
                    <div className="bottam_sing">
                        <label
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done"
                        > {this.state.toppingCategoryId ? ToppingsCategoryConstForm.btn_UpdateToppingCategory : ToppingsCategoryConstForm.btn_AddToppingsCategory}</label>
                    </div>
                </LoadingOverlay>
            </section>

        );
    }
}
export default AddToppingsCategory;