import React, { Component } from "react";
import "../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../Config/Messages";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreDataInStorage, validateInt } from "../Config/Utils";
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants from '../Config/Constants'
import Header from '../Header/Header.js'
import Color from "../Config/Color"
import Constvariable, { DatetimeFormate } from '../Config/constvariable';
import { GetData } from "../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { GET_NOTIFICATION } from '../Config/Config.json';
import './Notification.css'
import Moment from 'moment';

class NotificationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userFields: {},
            isActive: false,
            errors: []
        };
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        let search = window.location.search;
        let params = new URLSearchParams(search);

        if (params.get('id') !== null && (params.get('id').length > 0)) {
            var id = params.get('id');
            this.getNotificationDetail(authToken, id);
        }
    }

    getNotificationDetail(authToken, id) {
        this.setState({ isActive: true, errors: {} })
        var URL = GET_NOTIFICATION + "/" + id;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ userFields: responseData });
                        this.setState({ loaderClass: false });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }



    // /////////////// end detail methods ////////////////////////////


    render() {

        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.NotificationDetail}></Header>

                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <label style={{ textAlign: "center", color: Color.THEME_COLOR }}>
                                {Constvariable.FromUserName}: &nbsp;
                            </label>
                            <span>
                                {this.state.userFields.FromUserName}
                            </span>
                        </div> */}

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.itemName}
                        </p> */}
                            <label style={{ color: Color.THEME_COLOR }}>{Constvariable.NotificationSubject}</label>
                            <span
                                // style={{ color: Color.THEME_COLOR }}
                                type="text"
                                name="AvailableQuantity"
                                className='notification-tag'
                                value={this.state.userFields.NotificationSubject}
                            >{this.state.userFields.NotificationSubject}</span>

                        </div>


                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.itemName}
                        </p> */}
                            <label style={{ color: Color.THEME_COLOR }}>{Constvariable.NotificationMessage}</label>
                            <div
                                type="text"
                                name="updateQuantity"
                                style={{ textAlign: "justify", minHeight: "400px" }}
                                className='add-item-box notification-tag'
                                value={this.state.userFields.NotificationMessage}
                                dangerouslySetInnerHTML={{ __html: this.state.userFields.NotificationMessage }} />
                        </div>
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <span style={{ justifyContent: "flex-end", display: 'flex' }}>
                                {Moment(this.state.userFields.CreatedDate).format(DatetimeFormate.DDMMMYYYYhmma)}
                            </span>
                        </div>
                        <div className="reg_form_main offset-sm-3 col-sm-6">
                            <center>
                                <div className="errorMsg">
                                    {this.state.errors.ErrorC}
                                </div>
                            </center>
                        </div>
                    </div>
                </LoadingOverlay>
            </section>
        );
    }
}
export default NotificationDetail;