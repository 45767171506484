import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants,{DEFAULTID} from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { GET_COUPON_API, REDEMPTIONS } from '../../Config/Config.json'
import SideMenu from '../../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import Constvariable, { DatetimeFormate } from '../../Config/constvariable';
import { PostData } from '../../../services/PostData';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { RedemptionForm } from '../../Config/HoverMessage'
import { RedemptionConst } from "../../Config/ConstForm"
import Moment from 'moment'


class Redemption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userFields: {
                StartDate: "",
                EndDate: ""
            },
            errors: {},
            storeId: "",
            plazaId: "",

            couponId: "",
            redemptionList: [],
            isActive: false
        }
        this.handleEarnings = this.handleEarnings.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
        }
        else {
            this.props.history.goBack()
        }

        let search = window.location.search;
        let params = new URLSearchParams(search);

        if (params.get('id') && (params.get('id').length > 0)) {
            var id = params.get('id');
            this.setState({ couponId: id })
        }
    }



    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["StartDate"]) {
            formIsValid = false;
            errors["StartDate"] = Messages.v_StartDate;
        }

        if (!fields["EndDate"]) {
            formIsValid = false;
            errors["EndDate"] = Messages.v_EndDate;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    handleEarnings(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['StartDate'] = this.state.userFields.StartDate;
            postData['EndDate'] = this.state.userFields.EndDate;
            postData["storeid"] = this.state.storeId
            postData["plazaid"] = this.state.plazaId

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            if (this.state.couponId) {
                var couponId = this.state.couponId
                this.getRedemption(authToken, postData, couponId)
            }
        }

    }

    getRedemption(authToken, postData, couponId) {
        this.setState({ isActive: true })
        var URL = GET_COUPON_API + "/" + couponId + "/" + REDEMPTIONS
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log("==", result);
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ redemptionList: responseData })
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }


    render() {
        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        const columns = [
            {
                name: RedemptionConst.Titel,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.coupontitle}</div>
            },
            {
                name: RedemptionConst.Description,
                cell: row => <div style={{ marginLeft: "0px" }}>{row.coupondescription}</div>
            },
            {
                name: RedemptionConst.OrderId,
                cell: row => <div style={{ marginLeft: "0px" }}>{row.OrderId}</div>
            },
            {
                name: RedemptionConst.CustomerName,
                cell: row => <div style={{ marginLeft: "0px" }}>{row.customername}</div>
            },
            {
                name: RedemptionConst.CustomerEmail,
                cell: row => <div style={{ marginLeft: "0px" }}>{row.customeremail}</div>
            },
            {
                name: RedemptionConst.DiscountAmount,
                cell: row => <div style={{ marginLeft: "0px" }}>{currencySymbol + " " + Number(row.discountamount).toFixed(2)}</div>
            },
            {
                name: RedemptionConst.DateofRedeemed,
                cell: row => <div style={{ marginLeft: "0px" }}>{Moment.utc(row.DateofRedeemed).format(DatetimeFormate.DDMMYYYY)}</div>
            },
            {
                name: RedemptionConst.Status,
                cell: row => <div style={{ marginLeft: "0px" }}>{row.Status}</div>
            }
        ];

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={RedemptionConst.Redemption} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="reg_form_main" style={{ display: "flex", flexWrap: "wrap" }}>
                                <div className="col-sm-4">
                                    <label className="label-color">{RedemptionConst.StartDate}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={RedemptionForm.StartDate} />
                                    </label>
                                    <input
                                        type="date"
                                        name="StartDate"
                                        className='add-item-box'
                                        value={this.state.userFields.StartDate}
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">
                                        {this.state.errors.StartDate}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label className="label-color">{RedemptionConst.EndDate}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={RedemptionForm.EndDate} />
                                    </label>
                                    <input
                                        type="date"
                                        name="EndDate"
                                        className='add-item-box'
                                        value={this.state.userFields.EndDate}
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">
                                        {this.state.errors.EndDate}
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                        <span
                                            style={{ background: Color.THEME_COLOR }}
                                            onClick={this.handleEarnings}
                                            className="btn btn-reg-email">
                                            {RedemptionConst.btn_Redemption}
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div className="reg_form_main col-sm-12">

                                <DataTableExtensions
                                    data={this.state.redemptionList}
                                    columns={columns}
                                    export={false}
                                    print={false}
                                    filter={this.state.redemptionList.length > 0}
                                    filterHidden={false}
                                    filterPlaceholder={Messages.SearchforRedemption}
                                    filterStyle={{ width: "100px" }}

                                >
                                    <DataTable
                                        noHeader={true}
                                        noTableHead={false}
                                        pagination
                                    />
                                </DataTableExtensions>



                            </div>

                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Delete item</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        Are you sure you want to delete this item?
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default Redemption;