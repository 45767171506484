import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API, GET_EARNING, PLAZAID } from '../../Config/Config.json'
import SideMenu from '../../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import Constvariable,{DatetimeFormate} from '../../Config/constvariable';
import { PostData } from '../../../services/PostData';
import './Earnings.css'
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { EarningsForm } from '../../Config/HoverMessage'
import { EarningsConstForm } from "../../Config/ConstForm";
import { PutMandatoryfieldSymbol } from '../../UtillCustomComponent/UtillCustomComponent';
import Moment from 'moment'

import Pagination from "react-js-pagination";

const customStyles = {
    rows: {
        style: {
            border: 'none', // override the row height
        }
    },
}

class Earnings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            userFields: {},
            errors: {},
            storeId: "",
            plazaId: "",
            earningList: [],
            masterEarning: [],
            isActive: false,
            activePage: 1
        }
        this.handleEarnings = this.handleEarnings.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = storeObj.plazaid
            this.setState({ storeId: storeId, plazaId: plazaId })
        } else {
            this.props.history.goBack()
        }
    }



    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["FromDate"]) {
            formIsValid = false;
            errors["FromDate"] = Messages.v_FromDate;
        }

        if (!fields["ToDate"]) {
            formIsValid = false;
            errors["ToDate"] = Messages.v_ToDate;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    handleEarnings(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['FromDate'] = this.state.userFields.FromDate;
            postData['ToDate'] = this.state.userFields.ToDate;

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            if (this.state.storeId && this.state.storeId !== '') {
                var storeId = this.state.storeId;
                var plazaId = this.state.plazaId
                this.getEarning(authToken, postData, storeId, plazaId)
            }
        }

    }

    getEarning(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = MY_STORE_API + "/" + storeId + GET_EARNING + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        var firstpageList = responseData.slice((1 - 1) * 10, 1 * 10)
                        this.setState({ earningList: firstpageList, masterEarning: responseData })
                    }
                    this.setState({ activePage: 1 })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });

    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        var masterList = this.state.masterEarning.slice((pageNumber - 1) * 10, pageNumber * 10)
        this.setState({ earningList: masterList })
    }

    render() {
        const { hideEmptyPlazaView } = this.state;
        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        const columns = [
            {
                cell: (row, index) =>
                    <div className='div-con' key={index}>
                        <div className="row" style={{ justifyContent: "center" }}> {/* marginLeft: '10px',  */}
                            <div>
                                <div style={{ marginLeft: '10px', fontSize: "18px" }}><b>Order Id:</b> {row.OrderId}</div>
                                <div style={{ margin: '0px 0px 0px 10px', fontSize: "18px" }}>{row.DeliveryDate} | {row.Items ? row.Items.length : 0} items | $ {Number.parseFloat(row.SubTotal + row.TotalTaxes + row.ServiceFeeAmount + row.TotalDeliveryCharges).toFixed(2)}</div>
                            </div>
                            <div className='div-right'>

                            </div>
                        </div>
                    </div>
            },

        ];

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.Earnings} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="reg_form_main" style={{ display: "flex", flexWrap: "wrap" }}>
                                <div className="col-sm-4">
                                    <label className="label-color">{EarningsConstForm.From}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={EarningsForm.From} /> <PutMandatoryfieldSymbol />
                                    </label>
                                    <input
                                        type="date"
                                        name="FromDate"
                                        className='add-item-box'
                                        placeholder={Constvariable.SupplierSKU}
                                        value={this.state.userFields.FromDate}
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">
                                        {this.state.errors.FromDate}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label className="label-color">{EarningsConstForm.To}
                                        &nbsp;
                                        <OnHoverIconInfo
                                            message={EarningsForm.To} /> <PutMandatoryfieldSymbol />
                                    </label>
                                    <input
                                        type="date"
                                        name="ToDate"
                                        className='add-item-box'
                                        placeholder={Constvariable.SupplierSKU}
                                        value={this.state.userFields.ToDate}
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">
                                        {this.state.errors.ToDate}
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                        <span
                                            style={{ background: Color.THEME_COLOR }}
                                            onClick={this.handleEarnings}
                                            className="btn btn-reg-email">
                                            {EarningsConstForm.Earnings}
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div className="reg_form_main col-sm-12">
                                {!hideEmptyPlazaView
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img></img>
                                            <p className="col-sm-12" for="FirstName">

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    this.state.earningList.map((data, index) => {
                                        return  <div class="card mb-3">
                                        {/* <h6 class="card-header">
                                          <div className="d-flex justify-content-between">
                                            <span>
                                              {data.OrderStatus} 
                                            </span>
                                          </div>
                                        </h6> */}
                                        <div class="card-body">
                                          <p class="card-title">{EarningsConstForm.OrderId} <span style={{ color: Color.BLACK, fontWeight: "bold" }}>{data.OrderId}</span></p>
                                                <p class="card-text">{(data.IsPickupOrder ? (EarningsConstForm.PickupDate + " - " + EarningsConstForm.Time) : (EarningsConstForm.DeliveryDate + " - " + EarningsConstForm.Time))} : <span style={{ color: Color.BLACK, fontWeight: "bold" }}>{Moment(data.DeliveryDate).format(DatetimeFormate.DDMMYYYY) + "" + ((data && data.DeliveryTime) ? " - (" + data.DeliveryTime + ")" : "")}</span></p>
                                                <p class="card-text">{EarningsConstForm.Product_s} : <b className="textcolor"> {data.Items ? data.Items.length : 0}</b></p>
                                                <p class="card-text">{EarningsConstForm.OrderTotalPrice} : <b className="textcolor">{currencySymbol} {Number.parseFloat(data.TotalAmount).toFixed(2)}</b></p>
                                          {/* <p class="card-text">Confirmation Code : <b className="textcolor">{data.PickupOrderCode}</b></p> */}
                                        </div>
                                      </div>
                                        // return <div className='div-con' key={index}>
                                        //     <div className="" style={{ justifyContent: "center" }}> {/* marginLeft: '10px',  */}
                                        //         <div>
                                        //             <b>{row.index}</b>
                                        //             <div style={{ marginLeft: '10px', fontSize: "18px" }}><b>Order Id:</b> {row.OrderId}</div>
                                        //             <div style={{ margin: '0px 0px 0px 10px', fontSize: "18px" }}>{row.DeliveryDate} | {row.Items ? row.Items.length : 0} Products | {currencySymbol} {Number.parseFloat(row.TotalAmount).toFixed(2)}</div>
                                        //         </div>
                                        //         <div className='div-right'>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                    })

                                    // <DataTableExtensions
                                    //     data={data}
                                    //     columns={columns}
                                    //     export={false}
                                    //     print={false}
                                    //     filter={false}
                                    //     filterHidden={false}
                                    //     filterPlaceholder={Messages.SearchforEarnings}
                                    //     filterStyle={{ width: "100px" }}

                                    // >
                                    //     <DataTable
                                    //         noHeader={true}
                                    //         noTableHead={true}
                                    //         customStyles={customStyles}
                                    //         pagination
                                    //         paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                    //         //pagination
                                    //         onRowClicked={this.handleRowClick}

                                    //     />
                                    // </DataTableExtensions>

                                }

                                {this.state.masterEarning.length === 0 && <div className="reg_form_main col-sm-12">
                                    <center>There are no records to display</center>
                                </div>}
                            </div>

                            <div className="reg_form_main col-sm-12">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>

                            {this.state.masterEarning.length > 0 && <div className="reg_form_main col-sm-12">
                                <hr style={{ border: "1px solid #ccc" }} />
                            </div>}

                            {this.state.masterEarning.length > 0 && <div className="reg_form_main col-sm-12" style={{ marginTop: "0px" }}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.masterEarning.length}
                                    pageRangeDisplayed={3}
                                    onChange={(e) => this.handlePageChange(e)}
                                />
                            </div>}
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Delete item</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        Are you sure you want to delete this item?
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default Earnings;