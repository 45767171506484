import React, { Component } from "react";
import "./OrderDetails.css";
import ProductList from "../../ProductList/ProductList";
import MerchantList from "../MerchantList/MerchantList";
import Constants from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import Messages from "../../Config/Messages";
import { GetData } from "../../../services/GetData";
import {
  GET_STOREPRODUCT, GET_MERCHANT_ORDER_API, POST_ORDERCONFORMATION,
  GET_COUNTRY_API, PLAZAID, GET, UPDATESTATUS, PICKUPSTATUS
} from '../../Config/Config.json'
import { PostData } from "../../../services/PostData";
import Color from '../../Config/Color';
import Checkbox from 'react-simple-checkbox';
import Header from '../../Header/Header.js'
import Constvariable from '../../Config/constvariable';
import LoadingOverlay from 'react-loading-overlay';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import RouteMerchant from '../../Config/RouteMerchant.js';

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    const pr = this.props.location;
    this.state = {
      pdata: [],
      data: '',
      mdata: [],
      Quntity: 0,
      Total: 0,
      oid: pr.data,
      daddress: '',
      dtime: '5am - 5pm',
      ddate: '10-7-2020',
      sname: '',
      sflag: '',
      loaderClass: true,
      isShowm: true,
      isShowc: true,
      isPickupCall: true,

      orderConfomationList: [],
      orderStatus: "",
      orderid: "",
      applicationId: "",
      plazaId: "",
      isActive: false,

      countryData: []
    };
    this.openSearch = this.openSearch.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.ordercall = this.ordercall.bind(this);
    this.orderaccept = this.orderaccept.bind(this);
    this.ordercancel = this.ordercancel.bind(this);
  }

  openSearch() {
    window.openSearch();
  }

  ordercall() {
    var orderid = RetriveDataFromStorage(Constants.ORDERID);
    var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    var oflag = RetriveDataFromStorage(Constants.ORDERFLAG);
    var plazaId = this.state.plazaId
    if (oflag == "PENDING") {
      var storeid = RetriveDataFromStorage(Constants.STOREID);
      var postData = {};
      postData["ApplicationId"] = storeid;
      postData["Isconfirm"] = true;
      postData["IsDelivered"] = false;
      this.orderData(postData, authToken, orderid, plazaId);
    } else if (oflag == "CONFIRMED") {
      if (this.state.isPickupCall) {
        var storeid = RetriveDataFromStorage(Constants.STOREID);
        var postData = {};
        postData["ApplicationId"] = storeid;
        postData["Isconfirm"] = false;
        postData["IsDelivered"] = true;
        this.orderPickupData(postData, authToken, orderid, plazaId);
      } else {
        window.openSearch();
      }
    } else {

    }
  }

  ordercancel() {
    var orderid = RetriveDataFromStorage(Constants.ORDERID);
    var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    var oflag = RetriveDataFromStorage(Constants.ORDERFLAG);
    if (oflag == "PENDING") {
      var storeid = RetriveDataFromStorage(Constants.STOREID);
      var postData = {};
      postData["ApplicationId"] = storeid;
      postData["Isconfirm"] = false;
      postData["IsDelivered"] = false;
      // alert(JSON.stringify(postData))
      console.log('======>', postData)
      var plazaId = this.state.plazaId
      this.orderData(postData, authToken, orderid, plazaId);
    } else if (oflag == "CONFIRMED") {
      window.openSearch();
    } else {

    }
  }

  orderaccept() {
    var orderid = RetriveDataFromStorage(Constants.ORDERID);
    var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    var oflag = RetriveDataFromStorage(Constants.ORDERFLAG);
    var storeid = RetriveDataFromStorage(Constants.STOREID);
    var postData = {};
    postData["ApplicationId"] = storeid;
    postData["Isconfirm"] = false;
    postData["IsDelivered"] = true;
    var plazaId = this.state.plazaId
    this.orderDataaccept(postData, authToken, orderid, plazaId);
  }

  orderDataaccept(postData, authtoken, orderid, plazaId) {
    this.setState({ loaderClass: true });
    this.setState({ isActive: true })
    var URL = GET_MERCHANT_ORDER_API + orderid + UPDATESTATUS + "?" + PLAZAID + plazaId;
    PostData(URL, postData, authtoken)
      .then(result => {
        var responseJson = result;
        console.log("responseData ", responseJson);
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          this.setState({ loaderClass: false });
          window.closeSearch();
          window.location.href = RouteMerchant.Home;
        }
        else {
          this.setState({ loaderClass: false });
          alert(Messages.ERR_SomethingWentWrong)
        }
        this.setState({ isActive: false })
      });
  }

  orderData(postData, authtoken, orderid, plazaId) {
    this.setState({ loaderClass: true });
    this.setState({ isActive: true })
    var URL = GET_MERCHANT_ORDER_API + orderid + UPDATESTATUS + "?" + PLAZAID + plazaId;
    PostData(URL, postData, authtoken)
      .then(result => {
        var responseJson = result;
        console.log("responseData ", responseJson);
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          this.setState({ loaderClass: false });
          window.location.href = RouteMerchant.Home;
        }
        else {
          this.setState({ loaderClass: false });
          alert(Messages.ERR_SomethingWentWrong)
        }
        this.setState({ isActive: false })
      });
  }

  orderPickupData(postData, authtoken, orderid, plazaId) {
    this.setState({ loaderClass: true });
    this.setState({ isActive: true })
    var URL = GET_MERCHANT_ORDER_API + orderid + PICKUPSTATUS + "?" + PLAZAID + plazaId;
    PostData(URL, postData, authtoken)
      .then(result => {
        var responseJson = result;
        console.log("responseData ", responseJson);
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          this.setState({ loaderClass: false });
          window.location.href = RouteMerchant.Home;
        }
        else {
          this.setState({ loaderClass: false });
          alert(Messages.ERR_SomethingWentWrong)
        }
        this.setState({ isActive: false })
      });
  }

  closeSearch() {
    window.closeSearch();
  }

  formateDate(date, timeFlag) {
    var dateToFormate = new Date(date);
    var getDate = dateToFormate.getDate();
    var getMonth = dateToFormate.getMonth() + 1;
    var getYear = dateToFormate.getFullYear();
    var getHours = dateToFormate.getHours();
    var getMinutes = dateToFormate.getMinutes();
    var returnDate = getMonth + "-" + getDate + "-" + getYear;
    return returnDate;
  }

  getOrderDetails(authToken, storeid, orderid, plazaId) {
    this.setState({ isActive: true })

    var URL = GET_STOREPRODUCT + storeid + "/" + GET + "/" + orderid + "?" + PLAZAID + plazaId
    console.log("authToken", URL);
    GetData(URL, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          if (responseData) {
            console.log("responseData ", responseData + '' + responseData.IsPickup);
            this.setState({ data: responseData, loaderClass: false });
            this.setState({ orderStatus: responseData.OrderStatus })
            this.setState({ orderid: responseData.OrderId, applicationId: responseData.storeid })
            var d = this.state.data;
            if (d) {
              console.log('================== item==============>', responseData)
              var t = d.SubTotal + d.TotalDeliveryCharges;
              if (d.Items) {
                var pdata = d.Items.map(item => {
                  return { ...item, "IsConfirm": false }
                })
                this.setState({ pdata: pdata })
              }
              this.setState({
                Total: t,
                daddress: d.DeliveryAddress,
                dtime: d.DeliveryTime,
                ddate: this.formateDate(d.DeliveryDate, false),
                // pdata: d.Items,
                isPickupCall: d.IsPickupOrder
              });
              console.log("ipspuckup" + d.IsPickup);

              if (responseData.OrderStatus == "CONFIRMED") {
                if (this.state.isPickupCall) {
                  var name = 'Accept Pickup order';
                  this.setState({ sname: name });
                  this.setState({ isShowm: true, isShowc: false });
                } else {
                  var name = 'Accept delivery order';
                  this.setState({ sname: name });
                  this.setState({ isShowm: true, isShowc: false });
                }
              }
            }
            var p = this.state.pdata;
            if (p) {
              var q = 0;
              for (var i = 0; i < p.length; i++) {
                q = q + p[i].ItemQuantity;
              }
              this.setState({ Quntity: q });
            }
          }
        }
        else {
          alert(Messages.ERR_SomethingWentWrong);
        }
        this.setState({ isActive: false })
      });
  }

  getMerchantListAPI(authToken, orderid, plazaId) {
    console.log("authToken", authToken);
    var URL = GET_MERCHANT_ORDER_API + orderid + "?" + PLAZAID + plazaId;
    GetData(URL, authToken)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          console.log("responseData ", responseData);
          if (responseData) {
            this.setState({ mdata: [responseData] });
          }
          // else {
          //   alert(Messages.NO_DATA);
          // }
        }
        else {
          alert(Messages.ERR_SomethingWentWrong);
        }
      });
  }

  getispuckup() {
    if (this.state.isPickupCall) {
      return (
        <div>
          <div id='div-mr'>
            <b>Pickup Time: </b>
          </div>
          <div>
            <li>{this.state.dtime}  ,  {this.state.ddate}</li>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div id='div-mr'>
            <b>Delivery Address: </b>
          </div>
          <div>
            <li>{this.state.daddress}</li>
          </div>
          <div id='div-mr'>
            <b>Delivery Time: </b>
          </div>
          <div>
            <li>{this.state.dtime}  , {this.state.ddate}</li>
          </div>
        </div>
      );
    }
  }

  componentWillMount() {
    var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    var storeid = RetriveDataFromStorage(Constants.STOREID);
    var orderid = RetriveDataFromStorage(Constants.ORDERID);
    var oflag = RetriveDataFromStorage(Constants.ORDERFLAG);

    var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
    if (storeObj) {
      var plazaId = storeObj.plazaid
      this.setState({ plazaId: plazaId })
      this.setState({ oid: orderid, sflag: oflag });
      this.getOrderDetails(authToken, storeid, orderid, plazaId);
      this.getCountryAPI()
      this.getMerchantListAPI(authToken, orderid, plazaId);
    }
    if (oflag == 'PENDING') {
      var name = 'Accept order';
      this.setState({ sname: name });
      this.setState({ isShowm: true, isShowc: true });
    } else if (oflag == "CONFIRMED") {
      var name = 'Accept delivery order';
      this.setState({ sname: name });
      this.setState({ isShowm: true, isShowc: false });
    } else if (oflag == "DELIVERY_CONFIRMED") {
      var name = 'Delivered';
      this.setState({ sname: name });
      this.setState({ isShowm: false, isShowc: false });
    } else {
      var name = 'Delivered';
      this.setState({ sname: name });
      this.setState({ isShowm: false, isShowc: false });
    }
  }
  getCountryAPI() {
    console.log("country list1 ",);
    GetData(GET_COUNTRY_API)
      .then(result => {
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          var responseData = responseJson["Data"];
          if (responseData) {
            this.setState({ countryData: responseData });
          }
          else {
            this.setState({ countryData: [] })
          }
          console.log("countryData ", responseData);
        }
      });
  }

  handleCheck(itemObj) {
    var itemList = this.state.pdata.map(e => {
      if (itemObj.ItemId === e.ItemId) {
        return { ...e, "IsConfirm": !e.IsConfirm }
      }
      return { ...e }
    })
    this.setState({ pdata: itemList })
  }

  orderConfirmation() {
    var postData = {};
    postData['Orderid'] = this.state.orderid;
    postData['ApplicationId'] = this.state.applicationId;
    postData['Products'] = this.state.pdata.map(e => {
      return { "ItemId": e.ItemId, "IsConfirm": e.IsConfirm }
    })
    var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    if (this.state.orderid && this.state.applicationId) {
      var storeId = this.state.applicationId
      var plazaId = this.state.plazaId
      this.postOrderConformation(authToken, postData, storeId, plazaId)

    }

  }

  postOrderConformation(authToken, postData, storeId, plazaId) {
    this.setState({ isActive: true })
    var URL = GET_STOREPRODUCT + storeId + "/" + POST_ORDERCONFORMATION + "?" + PLAZAID + plazaId
    PostData(URL, postData, authToken)
      .then(result => {
        console.log("========", result)
        var responseJson = result;
        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
          this.setState({ isActive: false })
          this.props.history.goBack();
        }
        else {
          alert(Messages.ERR_SomethingWentWrong)
        }
        this.setState({ isActive: false })
      })
  }

  renderTextDetail(currencySymbol) {

    var orderDetailObj = this.state.data
    if (orderDetailObj) {
      var subTotal = 0.0
      var totalspecialdeliverycharges = 0.0
      var textType = "", TotalTaxValue = 0.0, renderTaxDiv, totalItemTax = 0, couponAmount = 0.0
      let Items = orderDetailObj.Items ? orderDetailObj.Items : []
      Items.map(itemObj => {
        totalspecialdeliverycharges = totalspecialdeliverycharges + itemObj.specialdeliverycharges
        totalItemTax = totalItemTax + itemObj.TotalItemTax
        subTotal = subTotal + itemObj.TotalItemPrice
      })
      if (orderDetailObj.TaxDetails) {
        renderTaxDiv = orderDetailObj.TaxDetails.map(e => {
          TotalTaxValue = TotalTaxValue + Number(e.TotalTax)
          return <div class="offset-sm-6 col-sm-6">
            <span style={{ float: "right" }}> {e.TaxType} ({e.TaxRate}%) = <b> {currencySymbol} {Number.parseFloat(e.TotalTax).toFixed(2)}</b> </span>
          </div>
        })
      }
      var deliverytipamount = orderDetailObj.deliverytipamount ? orderDetailObj.deliverytipamount : 0
      // var total = Number(subTotal) + Number(totalspecialdeliverycharges) + Number(orderDetailObj.TotalDeliveryCharges) + Number(orderDetailObj.ServiceFeeAmount) + TotalTaxValue + deliverytipamount + totalItemTax
      // total = total
      couponAmount = orderDetailObj.CouponDetail ? orderDetailObj.CouponDetail.discountamount : 0

      return <div className='row'>
        <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> Subtotal = <b> {currencySymbol} {Number.parseFloat(subTotal).toFixed(2)}</b> </span>
        </div>

        {totalItemTax != 0 && <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> Total Product Tax = <b> {currencySymbol} {Number.parseFloat(totalItemTax).toFixed(2)}</b> </span>
        </div>}
        {!orderDetailObj.IsPickupOrder && <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> Delivery Charges = <b> {currencySymbol} {Number.parseFloat(orderDetailObj.TotalDeliveryAmount - totalspecialdeliverycharges).toFixed(2)}</b> </span>
        </div>}
        {!orderDetailObj.IsPickupOrder && <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> Special Delivery Charges = <b> {currencySymbol} {Number.parseFloat(totalspecialdeliverycharges).toFixed(2)}</b> </span>
        </div>}
        <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> Service Fee = <b> {currencySymbol} {Number.parseFloat(orderDetailObj.ServiceFeeAmount).toFixed(2)}</b> </span>
        </div>
        {renderTaxDiv}
        {!orderDetailObj.IsPickupOrder && <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> Delivery Tip = <b> {currencySymbol} {Number.parseFloat(deliverytipamount).toFixed(2)}</b> </span>
        </div>}
        {/* <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> Delivery Tip = <b> {currencySymbol} {Number.parseFloat(this.state.Total).toFixed(2)}</b> </span>
        </div> */}
        <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> Total = <b> {currencySymbol} {Number.parseFloat(orderDetailObj.TotalAmount + Number(couponAmount)).toFixed(2)}</b> </span>
          <span style={{ float: "right", margin: "0px 20px 0px 0px" }}> <b>{this.state.Quntity}</b> Items </span>
        </div>
        {orderDetailObj.CouponDetail && <div class="offset-sm-6 col-sm-6">
          <span style={{ float: "right" }}> With Discount Total = <b> {currencySymbol} {Number.parseFloat(orderDetailObj.TotalAmount).toFixed(2)}</b> </span>
          {/* <span style={{ float: "right", margin: "0px 20px 0px 0px" }}> <b>{this.state.Quntity}</b> Items </span> */}
        </div>}
        {orderDetailObj.CouponDetail && <div className={'col-sm-12 couponBox'} >
          <b>Coupon Detail:</b>
          <div className='coupondesc row-over couponblock'>
            <label className="remove-mg" style={{ marginLeft: "10px", color: Color.BLACK, fontWeight: 'bold', }}>{orderDetailObj.CouponDetail.coupontitle} <span className="coupontitleamount">{currencySymbol + " " + Number(orderDetailObj.CouponDetail.discountamount).toFixed(2) + " OFF"} </span>
            </label>
            <label className="remove-mg coupondescription" style={{ marginLeft: "10px", color: Color.BLACK }}> {orderDetailObj.CouponDetail.coupondescription}</label>
          </div>
        </div>}
      </div>
    }
  }

  render() {
    const { mdata } = this.state;
    var showBtn = false;
    var result = mdata.every(element => element.OrderStatus === "CONFIRMED" ||
      element.OrderStatus === "CANCEL");
    if (result === true) {
      showBtn = false
    }
    else {
      showBtn = true
    }

    var currencySymbol = ""
    var countryInfo = this.state.data
    if (countryInfo) {
      var currency = this.state.countryData.filter(e => e.Currency == countryInfo.Currency)[0]
      currencySymbol = "(" + countryInfo.Currency + ")" + (currency ? currency.Symbol : "")
    }

    const columns = [
      {
        name: "No",
        cell: (row, index) => <div>{index + 1}</div>
      },
      {
        name: "Product",
        cell: (row, index) => <div>{row.ItemName}</div>
      },
      {
        name: "Quntity",
        cell: (row, index) => <div>{row.ItemQuantity}</div>
      },
      {
        name: "Amount",
        cell: (row, index) => <div>{currencySymbol} {Number.parseFloat(row.TotalItemPrice).toFixed(2)}</div>
      },

    ]

    var confomationobj = {
      name: "Amount",
      cell: (row, index) => <Checkbox
        color={Color.THEME_COLOR}
        size={2}
        tickSize={2}
        checked={row.IsConfirm}
        delay={0}
        backAnimationDuration={0}
        tickAnimationDuration={0}
        onChange={() => this.handleCheck(row)}></Checkbox>
    }

    if (this.state.orderStatus === "PENDING") {
      columns.push(confomationobj)
    }

    return (
      <div>
        <LoadingOverlay
          active={this.state.isActive}
          text={Messages.Loading}
          spinner
          styles={{
            overlay: (base) => ({
              ...base,
              position: "fixed"
            })
          }}
          className="lodingspinner"
        >
          <Header setHeaderTitle={Constvariable.OrderDetails} />
          <div className="container">
            {this.state.loaderClass ? <div id="loader" ></div> : null}
            <div className='row'>
              <div className="col-sm-7">
                Order id :<b> {this.state.oid} </b>
              </div>
              {/* <div className="col">
              </div> */}
              <div class="offset-sm-1 col-sm-4">
                Account info : <b> {this.state.data.CustomerName} <p>{this.state.data.CustomerEmail} , {this.state.data.PhoneNumber}</p></b>
              </div>
            </div>
            <hr id='hr-top' />

            {/* <div className='row'>
              <div className="col-sm">
                No
              </div>
              <div class="col-sm">
                Product
              </div>
              <div class="col-sm">
                Quntity
              </div>
              <div class="col-sm">
                Amount
              </div>
              {this.state.orderStatus === 'PENDING' && < div class="col-sm">
              </div>}
            </div> */}
            {/* <ProductList data={this.state.pdata ? this.state.pdata : []} /> */}
            <div style={{ overflowX: "auto" }}>
              <table>
                <tr>
                  <th>No</th>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  {this.state.orderStatus === 'PENDING' && <th></th>}
                </tr>
                {
                  this.state.pdata
                    .map(function (data, index) {
                      return (
                        <tr>
                          <td> {(index + 1)}</td>
                          <td> {data.ItemName} {data.Instruction ? "(" + data.Instruction + ")" : ""}</td>
                          <td>{data.ItemQuantity}</td>
                          <td> {currencySymbol} {Number.parseFloat(data.TotalItemPrice).toFixed(2)}</td>
                          <td> {this.state.orderStatus === "PENDING" &&
                            <Checkbox
                              color={Color.THEME_COLOR}
                              className="checkbox-component"
                              size={2}
                              tickSize={2}
                              checked={data.IsConfirm}
                              delay={0}
                              backAnimationDuration={0}
                              tickAnimationDuration={0}
                              onChange={() => this.handleCheck(data)}></Checkbox>
                          }</td>
                        </tr>
                      )
                    }, this)
                }

              </table>
            </div>

            {/* {
              this.state.pdata
                .map(function (data, index) {
                  return (
                    <div key={index}>
                      <div className='row'>
                        <div className="col-sm">
                          {(index + 1)}
                        </div>
                        <div class="col-sm">
                          {data.ItemName}
                        </div>
                        <div class="col-sm">
                          {data.ItemQuantity}
                        </div>
                        <div class="col-sm">
                          $ {Number.parseFloat(data.TotalItemPrice).toFixed(2)}
                        </div>
                        {this.state.orderStatus === "PENDING" && < div class="col-sm">
                          <Checkbox
                            color={Color.THEME_COLOR}
                            size={2}
                            tickSize={2}
                            checked={data.IsConfirm}
                            delay={0}
                            backAnimationDuration={0}
                            tickAnimationDuration={0}
                            onChange={() => this.handleCheck(data)}></Checkbox>
                        </div>}
                      </div>
                    </div>
                  )
                }, this)
            } */}

            <hr />
            {this.renderTextDetail(currencySymbol)}
            {/* <div className='row'>
              <div class="offset-sm-6 col-sm-6">
                <span style={{ float: "right" }}> Delivery Charges = <b> {currencySymbol} {Number.parseFloat(this.state.Total).toFixed(2)}</b> </span>
              </div>
              <div class="offset-sm-6 col-sm-6">
                <span style={{ float: "right" }}> Special Delivery Charges = <b> {currencySymbol} {Number.parseFloat(this.state.Total).toFixed(2)}</b> </span>
              </div>
              <div class="offset-sm-6 col-sm-6">
                <span style={{ float: "right" }}> Service Fee = <b> {currencySymbol} {Number.parseFloat(this.state.Total).toFixed(2)}</b> </span>
              </div>
              <div class="offset-sm-6 col-sm-6">
                <span style={{ float: "right" }}> HST (13%) = <b> {currencySymbol} {Number.parseFloat(this.state.Total).toFixed(2)}</b> </span>
              </div>
              <div class="offset-sm-6 col-sm-6">
                <span style={{ float: "right" }}> Delivery Tip = <b> {currencySymbol} {Number.parseFloat(this.state.Total).toFixed(2)}</b> </span>
              </div>
              <div class="offset-sm-6 col-sm-6">
                <span style={{ float: "right" }}> Total = <b> {currencySymbol} {Number.parseFloat(this.state.Total).toFixed(2)}</b> </span>
                <span style={{ float: "right", margin: "0px 20px 0px 0px" }}> <b>{this.state.Quntity}</b> Items </span>
              </div>
            </div> */}


            {this.getispuckup()}

            {/* <div id="div-delivery">
              <div className="registered-with">
                {this.state.isShowm ? <label className="btn btn-skip" onClick={this.ordercall} style={{ background: Color.THEME_COLOR }}>
                  {this.state.sname}
                </label> : null}
                {this.state.isShowc ? <label
                  style={{ background: Color.THEME_COLOR }}
                  onClick={this.ordercancel}
                  className="btn btn-done">
                  Cancel order
                </label> : null}

                {this.state.orderStatus === "PENDING" && <label
                  onClick={() => this.orderConfirmation()}
                  style={{ background: Color.THEME_COLOR }}
                  className="btn btn-done">
                  order confirmation
                </label>}
              </div>
            </div> */}

            <div id="div-delivery">
              <div className="row">
                {this.state.isShowm ?
                  <div className="col-lg-3 col-md-4 col-sm-12" style={{ margin: "4px 0px" }}>
                    <label className="btn btn-skip"
                      onClick={this.ordercall}
                      style={{ background: Color.THEME_COLOR, width: "100%", padding: "10px 0px" }}>
                      {this.state.sname}
                    </label>
                  </div>
                  : null}

                {this.state.isShowc ?
                  <div className="col-lg-3 col-md-4 col-sm-12" style={{ margin: "4px 0px" }}>
                    <label
                      style={{ background: Color.THEME_COLOR, width: "100%", padding: "10px 0px" }}
                      onClick={this.ordercancel}
                      className="btn btn-done">
                      Cancel order
                    </label>
                  </div>
                  : null}

                {this.state.orderStatus === "PENDING" &&
                  <div className="col-lg-3 col-md-4 col-sm-12" style={{ margin: "4px 0px" }}>
                    <label
                      onClick={() => this.orderConfirmation()}
                      style={{ background: Color.THEME_COLOR, width: "100%", padding: "10px 0px" }}
                      className="btn btn-done">
                      Order confirmation
                    </label>
                  </div>}
              </div>
            </div>

            <div id="search-box" className="search-box">
              <span
                className="closebtn"
                onClick={this.closeSearch}
                title="Close Overlay">
                ×
              </span>
              <div className="search-box-content">
                <h4>Merchant Status</h4>
                <div>
                  <div className='row'>
                    <div className="col-sm">
                      No
                    </div>
                    <div class="col-sm">
                      Merchant`s Name
                    </div>
                    <div class="col-sm">
                      Order Status
                    </div>
                  </div>
                  <hr />
                  <MerchantList data={this.state.mdata} />
                  <div>
                    <center>
                      <tr>
                        <td>
                          <div className="registered-with" style={{ marginRight: '10px' }}>
                            <span
                              hidden={showBtn}
                              onClick={this.orderaccept}
                              className="btn btn-reg-email">
                              Accept
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="registered-with">
                            <span
                              onClick={this.closeSearch}
                              className="btn btn-reg-email">
                              Cancel
                            </span>
                          </div>
                        </td>
                      </tr>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </LoadingOverlay>
      </div>
    );
  }
}

export default OrderDetails;