import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { GET_STORE_SUPPLIER, SUPPLIER, GET_STORE_ITEM, PLAZAID } from '../../Config/Config.json'
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import { DeleteData } from '../../../services/DeleteData';
import RouteMerchant from '../../Config/RouteMerchant.js';

class StoreSupplier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeSupplierList: [],
            isActive: false,
            plazaId: "",
            storeId: "",
            ItemId: "",
            supplierId: "",
            errors: {}
        }
        this.handleAddProducts = this.handleAddProducts.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        StoreObjectInStorage(Constants.STROE_ITEM_OBJ, '')
        StoreObjectInStorage(Constants.STORESUPPLIER_OBJ, '')
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = storeObj.plazaid
            this.setState({ storeId: storeId, plazaId: plazaId })
            this.getStoreSupplier(authToken, storeId, plazaId)
        } else {
            this.props.history.goBack()
        }
        console.log(authToken);
    }

    getStoreSupplier(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_STORE_SUPPLIER + storeId + SUPPLIER + "?" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        responseData.map(e1 => {
                            return { ...e1, "fullAddress": e1.Apartment + ', ' + e1.City + ', ' + e1.State + ', ' + e1.Country + ' - ' + e1.PinCode }
                        })
                        this.setState({ storeSupplierList: responseData });
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleAddProducts() {
        this.props.history.push({
            pathname: RouteMerchant.AddStoreSupplier
        })
    }

    // handleEditProducts(row) {
    //     StoreObjectInStorage(Constants.STROE_ITEM_OBJ, row)
    //     this.props.history.push({
    //         pathname: RouteMerchant.AddItems
    //     })
    // }

    // handleDelete(itemObj, flag) {
    //     if (flag) {
    //         this.setState({ ItemId: itemObj._id })
    //     }
    //     else {
    //         this.setState({ ItemId: "" })
    //     }
    // }

    // handleAddTopping(row) {
    //     StoreObjectInStorage(Constants.STROE_ITEM_OBJ, row)
    //     this.props.history.push({
    //         pathname: RouteMerchant.ManageToppingCategory
    //     })
    // }

    // deleteItem() {
    //     let itemId = this.state.ItemId;
    //     let storeId = this.state.storeId;
    //     var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    //     this.deleteItemAPI(authToken, storeId, itemId)
    // }

    // deleteItemAPI(authToken, storeId, itemId) {
    //     this.setState({ isActive: true })
    //     var URL = GET_STORE_ITEM.replace('#id#', storeId) + "/" + itemId;
    //     var postData = {}
    //     DeleteData(URL, postData, authToken)
    //         .then(result => {
    //             var responseJson = result;
    //             if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    //                 this.setState({ ItemId: "" })
    //                 this.getMyStoreItem(authToken, storeId)
    //             }
    //             else {
    //                 this.setState({ isActive: false })
    //             }
    //         })
    // }
    handleEdit(row) {
        StoreObjectInStorage(Constants.STORESUPPLIER_OBJ, row)
        this.props.history.push({
            pathname: RouteMerchant.AddStoreSupplier,
        })
    }

    handleDelete(supplierObj, flag) {
        if (flag) {
            this.setState({ supplierId: supplierObj._id })
        }
        else {
            this.setState({ supplierId: "" })
        }
    }

    deleteItem() {
        let storeId = this.state.storeId;
        let supplierId = this.state.supplierId;
        var plazaId = this.state.plazaId
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.deleteItemAPI(authToken, storeId, supplierId, plazaId)
    }

    deleteItemAPI(authToken, storeId, supplierId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + storeId + SUPPLIER + "?" + PLAZAID + plazaId
        var postData = { "_id": supplierId }
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ supplierId: "" })
                    this.getStoreSupplier(authToken, storeId, plazaId)
                }
                else {
                    this.setState({ isActive: false })
                }
            })
    }

    render() {
        const { hideEmptyPlazaView } = this.state;
        const columns = [

            {
                name: "Supplier",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Supplier}</div>
            },
            {
                name: "Address",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Apartment + ', ' + row.City + ', ' + row.State + ', ' + row.Country + ' - ' + row.PinCode}</div>
            },
            {
                name: "Contact Name",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.ContactName}</div>
            },
            {
                name: "Email",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Email}</div>
            },
            {
                name: "Phone Number",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.PhoneNumber}</div>
            },
            {
                maxWidth: "80px",
                cell: row => <i class="fas fa-edit" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleEdit(row)}></i>
                //  <i class="fa fa-shopping-cart" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={this.handleAddProducts}></i>
            },
            {
                maxWidth: "80px",
                cell: row => <a data-toggle="modal" data-target="#exampleModalLong">
                    <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleDelete(row, true)}></i>
                </a>
            },

        ];

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle="Manage Brewery Supplier" />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAddProducts}
                                    className="btn btn-reg-email">
                                    Add Brewery Supplier
                                </span>
                            </div>
                            <div className="reg_form_main">
                                {!hideEmptyPlazaView
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img></img>
                                            <p className="col-sm-12" for="FirstName">

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <DataTableExtensions
                                        data={this.state.storeSupplierList}
                                        columns={columns}
                                        export={false}
                                        print={false}
                                        filter={this.state.storeSupplierList.length > 0 ? true : false}
                                        filterHidden={true}
                                        filterPlaceholder={Messages.managestoresupplierSearch}
                                        filterStyle={{ width: "100px" }}
                                    >
                                        <DataTable
                                            noHeader={true}
                                        // noTableHead={true}
                                        //pagination

                                        />
                                    </DataTableExtensions>
                                }
                            </div>
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteSupplier}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteSupplierMessage}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}
                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default StoreSupplier;