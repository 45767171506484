import Messages from '../Config/Messages'
import { DateRangeT, durationMinKey } from '../Config/Constants'
import { validateInt } from '../Config/Utils'

export function validateAppointment(state) {
    let errors = {};
    let formIsValid = true;
    let fields = state.userFields;

    if (state.selectedDateRangT == DateRangeT.FUTUREDAYS) {
        if (!fields["nextcalendardays"]) {
            formIsValid = false;
            errors["nextcalendardays"] = Messages.v_calanderDays;
        }
        else if (fields["nextcalendardays"]) {
            if (!validateInt(String(fields["nextcalendardays"]).trim())) {
                formIsValid = false;
                errors["nextcalendardays"] = Messages.v_calanderDays_int;
            }
        }

    }

    if (state.selectedDateRangT == DateRangeT.DATERANGE) {
        if (!fields["fromdate"]) {
            formIsValid = false;
            errors["fromdate"] = Messages.v_s_start;
        }
        if (!fields["tomdate"]) {
            formIsValid = false;
            errors["tomdate"] = Messages.v_e_date;
        }
    }

    if (state.selectedDuration.value == durationMinKey.CUSTOM) {
        if (!fields["duration"]) {
            formIsValid = false;
            errors["duration"] = Messages.v_d_time;
        }
        else if (fields["duration"]) {
            if (!validateInt(String(fields["duration"]).trim())) {
                formIsValid = false;
                errors["duration"] = Messages.v_d_time_int;
            } else {
                if (Number(fields["duration"]) > 720) {
                    formIsValid = false;
                    errors["duration"] = Messages.v_d_time_int;
                }
            }
        }
    }

    return {
        errors: errors,
        formIsValid: formIsValid
    }
}