const Constvariable = {
    // storemain label ///
    dashboard: "Dashboard",
    // end storemain label ///

    StoreOrder: "Brewery Orders",

    // addItem label ///
    ManageStore: "Manage Brewery",
    itemName: "Enter Product Name",
    catagory: "Select Category",
    addItem: "Add Product",
    ItemPrice: "Enter Product Price",
    ItemDescription: "Enter Product Generic Description (optional)",
    SubCategoryName: "SubCategory Name",
    IsSubCategoryCustomizable: "IsSubCategory Customizable",
    IsTopping: "IsTopping",
    ParentItem: "Parent Product",
    ProductType: "Select Product Measurement Option",
    ProductSize: "Select Product Size (optional)",
    E_ProductSize: "Product Size",
    E_ProductSizes: "Product Sizes",
    ProductColor: "Select Product Color (optional)",
    E_ProductColor: "Product Color",
    E_ProductColors: "Product Colors",
    EnterColor: "Color",
    EnterQuantity: "Quantity",
    s_ProductCategory: "Select Product Category",
    WeightType: "Select Weight Type",
    Weight: "Enter Weight",
    CostPerItem: "Enter Product Cost (optional)",
    ToppingMinimumQuantity: "Enter Topping Minimum Quantity (optional)",
    ToppingMaximumQuantity: "Enter Topping Maximum Quantity (optional)",
    IsSpecialDeliveryItem: "Special Delivery Product (optional)",
    tax: "Enter tax",
    addItemImage: "Add Product Image",
    RewardPoint: "Enter Reward Point (optional)",
    SubmitItem: "Add Products",
    SKU: "Enter SKU (Stock Keeping Unit) (optional)",
    InternalProductId: "Enter Product Internal Id",
    BarCode: "Enter UPC (Universal Product Code) (optional)",
    AvailableQuantityM: "Enter Available Quantity",
    AvailableQuantity: "Enter Available Quantity (optional)",
    isTrackQuantity: "Track Quantity (optional)",
    IsTopping: "Topping",
    ContinueSelling: "Continue Selling (optional)",
    ProductWeightUnit: "Select Product Weight Unit",
    Others: "Enter Others (optional)",
    // addItem label end ///

    // addProduct label ///
    ProductCategory: "Enter Product Category",
    ParantCategory: "Select parant category (optional)",
    IsCategoryCustomizable: "Category Customizable (optional)",
    CustomizationMessage: "Customization Message (optional)",
    AddProduct: "Add Products Category",
    // addProduct label ///

    //side manu //
    ManageCategory: "Manage Category",
    ManageItems: "Manage Products",
    BulkCatalogUpload: "Bulk Catalog Upload",
    //end side manu //

    //AddToppingsCategory //
    ToppingCategory: "Enter Topping Category",
    AddToppingCategory: "Add",
    ManageToppingsCategory: "Manage Toppings Category",
    AddToppingsCategory: "Add Toppings Category",
    //AddToppingsCategory//

    // ManageToppings //
    ManageToppings: "Manage Toppings",
    // end ManageToppings //

    // AddToppings //
    ToppingItem: "Enter Topping Name",
    ToppingPrice: "Enter Topping Price",
    //  end AddToppings//

    // AddStoreSupplier//
    Supplier: "Enter Supplier Name",
    Address: "Select Address",
    Apartment: "Enter Apartment Name",
    City: "Enter City Name",
    State: "Enter State Name",
    Country: "Enter Country Name",
    PinCode: "Enter Postal Code",
    ContactName: "Enter Contact Name",
    Email: "Enter Email",
    PhoneNumber: "Enter Phone Number",
    AddSupplier: "Add Supplier",
    // add AddStoreSupplier //

    // pickuptime //
    PickupTime: "Manage Pickup Time",
    ManageStoreHours: "Brewery Hours",
    AddPickuptimes: "Add Pickup Time",
    l_PickupTime: "Enter Pickup Time",
    l_PickupTime1: "Enter Pickup End Time",
    // end pickuptime//

    // Deliverytimes //
    Deliverytimes: "Manage Delivery Option",
    AddDeliverytimes: "Add Delivery Option",
    l_Deliverytimes: "Enter Delivery Time",
    // l_DeliveryCost: "Enter Delivery Cost",
    DefaultMiles: "Select Unit Miles/KM",
    DefaultMilesFees: "Select Unit Miles/KM Fees",
    ExtraPerMileFees: "Select Extra Unit Per Mile/KM Fees",
    DoordashBusiness: "Select Doordash Business",
    // end Deliverytimes //

    // UpdateInventory //
    OriginalQuantity: "Original Quantity",
    NewQuantity: "Enter New Quantity",
    ProductDescription: "Enter Product Description (optional)",
    ProductName: "Product Name",
    Inventory: "Inventory",
    AdjustQuantity: "Adjust Quantity",
    InventoryHistory: "Inventory History",
    QuantityAdd: "Add Quantity?",
    QuantityMinus: "Minus Quantity?",
    // end UpdateInventory //

    // Notification //
    Notification: "Notification",
    // end Notification //

    // NotificationDetail //
    NotificationDetail: "Notification Detail",
    FromUserName: "From User'S Name",
    NotificationSubject: "Notification Subject",
    NotificationMessage: "Notification Message",
    // end NotificationDetail //

    // UserProfile //
    ManageProfile: "Manage Profile",
    FirstName: "First Name",
    LastName: "Last Name",
    Phone: "Phone No.",
    Company: "Company Name",
    PostalCode: "Postal Code",
    Profile_Address: "Select Address",
    CountryCode: "Country Code",
    eMail: "Email",
    PlazaEmail: "Plaza Email",
    PlazaName: "Plaza Name",
    UpdateProfile: "Update",
    // end UserProfile //

    // PurchaseOrder //
    PurchaseOrder: "Purchase Order",
    PurchaseOrderDetail: "Purchase Order Detail",
    // end PurchaseOrder //

    // AddPurchaseOrder //
    AddPurchaseOrder: "Add Purchase Order",
    Supplier_select: "Select Supplier",
    Paymentterms: "Enter Payment Terms",
    Suppliercurrency: "Enter Supplier Currency",
    NotetoSupplier: "Enter Note to Supplier (optional)",
    Shipmentdetails: "Shipment details",
    EstimateArrival: "Enter Estimate Arrival",
    TrackingNumber: "Enter Tracking Number (optional)",
    ShipingCenter: 'Enter Shipping Center',
    Products: "Products",
    Product_Select: "Select Product",
    SupplierSKU: "Enter Supplier SKU (Stock Keeping Unit)",
    Quantity: "Enter Quantity",
    Cost: "Enter Cost",
    ProductTax: "Enter Tax",
    ShippingCharge: "Enter Shipping Charge",
    CostSummary: "CostSummary",
    CostAdjustments: "CostAdjustments",
    Adjustment: "Adjustment (optional)",
    Amount: "Enter Adjustment Amount (optional)",
    TotalTax: "Enter Total Tax",
    Total: "Enter Total",
    // end AddPurchaseOrder //

    //  ReceiveInventory //
    ReceiveInventory: "Receive Inventory",
    AcceptQuantity: "Enter Accept Quantity",
    RejectQuantity: "Enter Reject Quantity",
    // end ReceiveInventory //

    // Earnings //
    Earnings: "Earnings",
    // end Earnings //

    // StoreDetails //
    StoreDetails: "Brewery Details",
    // end StoreDetails //

    // OrderDetails //
    OrderDetails: "Order Details",
    // end OrderDetails //

    // Store //
    Store: "Brewery Submission",
    // RegisterStoreWith: "Register store with",
    // Shopify: "Shopify",
    // Wix: "Wix",
    // Plazacart: "Plazacart",
    StoreURL: "Shopify Brewery Name",
    AccessToken: "Access Token",
    APIVersion: "API Version",
    Next: "Next",
    StoreName: "Enter Brewery Name",
    StoreCategory: "Enter Brewery Category",
    S_Plaza: "Select Plaza",
    S_PhoneNumber: "Enter Phone Number",
    WebsiteUrl: "Enter Website url",
    FacebookUser: "Enter Facebook User",
    S_City: "Enter City Name",
    S_State: "Enter State Name",
    S_Country: "Enter Country Name",
    S_StoreAddress: "Select Brewery Address",
    // end Store //

    // RegisterNew //
    R_Email: "example@gmail.com",
    YourName: "Your First Name",
    YourLastName: "Your Last Name",
    YourPhoneNumber: "Your Phone Number",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    R_Country: "Select Country",
    R_Address: "Type Your Address (optional)",
    Postalcode: "Postal code",
    Plaza: "Select Plaza (optional)",
    R_PlazaName: "Plaza Name (optional)",
    R_PlazaEmail: "Plaza Email (optional)",
    R_PlazaPhoneNumber: "Plaza Phone Number (optional)",
    R_PlazaAddress: "Plaza Address (optional)",
    R_PlazaPostalcode: "Plaza Postal code (optional)",
    OtpNumber: "Enter your one time code",
    // end RegisterNew //

    // ForgotPassword //
    FP_Email: "name@example.com",
    //  end ForgotPassword //

    // Subscription //
    Plan: "Plan",
    // end Subscription //

    // MySubscription //
    PlanDetails: "Subscription Details",
    // end MySubscription //

    // SubscriptionUpdate //
    PlanUpdate: "Plan Update",
    // end SubscriptionUpdate //

    // PlazaMain //
    ManagePlaza: "Manage Plaza",
    // end PlazaMain //

    // AddPlaza //
    AddPlaza: "Add Plaza",
    AP_PlazaName: "Plaza Name",
    AP_PlazaEmail: "Plaza Email",
    AP_PhoneNumber: "Phone Number",
    AP_Address: "Address",
    AP_PostalCode: "Postal Code",
    // end AddPlaza //

    // AddCoupon //
    Title: "Enter Title",
    Description: "Enter Description",
    StartDate: "Enter Start Date",
    EndDate: "Enter End Date",
    DicountPrice: "Enter Discount Price",
    MinimumRequiredAmount: "Enter Minimum Order Amount Required To Avail This Coupon",
    // end AddCoupon //

    // DoordashSetup //
    DeveloperId: "Enter Developer Id",
    KeyId: "Enter Key Id",
    SigningSecret: "Enter Signing Secret",
    D_SelectOption: "Select Option",
    // end DoordashSetup //

    // Home //
    H_ManageDeliveryStatus: "Manage Delivery Status",
    PickedUpByCustomer: "Picked up by customer",
    // end Home //

    // AddUser //
    U_PhoneNumber: "Enter Phone Number",
    // end AddUser //

    // tax //
    Status: "Select Status",
    TaxName: "Tax Name",
    TaxType: "Tax Type",
    ApplicableTax: "Applicable Tax (%)",
    // end tax //

    SelectTaxes: "Select Taxes"
}

export const DatetimeFormate = {
    DDMMYYYY: "DD/MM/YYYY",
    YYYYMMDDdash: "YYYY-MM-DD",
    DDMMMYYYYhmma: "DD MMM YYYY h:mm a",
    HMMACAP: "h:mm A",
    HMMA: "h:mm a",
    ddN_DD_MN_Y: "dddd, D MMMM YYYY",
}

export const appointmentStatus = {
    PENDING: "PENDING",
    CONFIRMED: "CONFIRMED",
    CANCEL: "CANCEL"
}

export const PlatFrom = {
    Plazacart: "Plazacart",
    Shopify: "Shopify",
    Wix: "Wix",
    BrewersNearMe: "Brewers near me"
}

export const GOOGLEPLACEAPIMAP = {
    STREET_NUMBER: "street_number",
    ROUTE: "route",
    POSTAL_CODE: "postal_code",
    POSTAL_CODE_SUFFIX: "postal_code_suffix",
    LOCALITY: "locality",
    ADMINISTRATIVE_AREA_LEVEL_1: "administrative_area_level_1",
    COUNTRY: "country"
}

export const ORDERSTATUS = {
    PENDING: "PENDING",
    DELIVERY_CREATED: "DELIVERY_CREATED",
    DELIVERY_CONFIRMED: "DELIVERY_CONFIRMED",
    ARRIVED_AT_PICKUP: "ARRIVED_AT_PICKUP",
    DELIVERY_PICKED_UP: "DELIVERY_PICKED_UP",
    ARRIVED_AT_DROPOFF: "ARRIVED_AT_DROPOFF",
    ENROUTE_TO_DROPOFF: "ENROUTE_TO_DROPOFF",
    ENROUTE_TO_PICKUP: "ENROUTE_TO_PICKUP",
    DELIVERY_CANCEL: "DELIVERY_CANCEL",
    DELIVERED: "DELIVERED",
    CONFIRMED: "CONFIRMED",
    PICKEDUP: "PICKEDUP",
    CANCEL: "CANCEL"
}

export const SelfDeliveryStatus = [
    { label: "DELIVERY_CONFIRMED", value: "DELIVERY_CONFIRMED" },
    // { label: "ARRIVED_AT_PICKUP", value: "ARRIVED_AT_PICKUP" },
    // { label: "DELIVERY_PICKED_UP", value: "DELIVERY_PICKED_UP" },
    { label: "ARRIVED_AT_DROPOFF", value: "ARRIVED_AT_DROPOFF" },
    { label: "DELIVERED", value: "DELIVERED" },
    { label: "DELIVERY_CANCEL", value: "DELIVERY_CANCEL" },
    { label: "ENROUTE_TO_DROPOFF", value: "ENROUTE_TO_DROPOFF" },
    // { label: "ENROUTE_TO_PICKUP", value: "ENROUTE_TO_PICKUP" }
]

export const CurbsideStatus = [
    { label: "PICKEDUP", value: "PICKEDUP" }
]

export const plazaCartDeliveryObj = {
    DeliveryOptionkey: "PLAZACART",
    DOORDASH: "DOORDASH"
}

export const UserStatus = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
}

export const UserAccess = {
    ISPARENTTRUE: "ISPARENTTRUE",
    ISPARENTFALSE: "ISPARENTFALSE"
}

export const TermsService = {
    screenTitle: "Terms and Conditions"
}

export const PrivacyAndPolicy = {
    screenTitle: "Privacy Policy"
}

export const IMAGEFILEEXTENSIONS = ["jpg", "jpeg", "bmp", "gif", "png", "JPG", "JPEG", "BMP", "GIF", "PNG"];

export const HOURSSTATIC = {
    starthour: "12:00 am",
    endhour: "11:30 pm"
}

export const TaxStatusObj = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
}

export const TaxStatusDataSet = [
    { value: TaxStatusObj.ACTIVE, label: TaxStatusObj.ACTIVE },
    { value: TaxStatusObj.INACTIVE, label: TaxStatusObj.INACTIVE }
]

export default Constvariable;