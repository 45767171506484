import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants,{DEFAULTID} from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { GET_STORE_SUPPLIER, DELETE, PLAZAID } from '../../Config/Config.json'
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import RouteMerchant from '../../Config/RouteMerchant.js';
import Constvariable from '../../Config/constvariable';
import emailread from "../../../images/icons/read.png";
import emailUnread from "../../../images/icons/Unread.png";
import { DeleteData } from '../../../services/DeleteData';
import delete1 from "../../../images/icons/delete.png";

class PurchaseOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeId: "",
            plazaId: "",
            isActive: false,
            peurchaseOrderId: '',
            purchaseOrderList: []
        }
        this.handlePurchaseOrder = this.handlePurchaseOrder.bind(this);
        // this.handleUpdate = this.handleUpdate.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeObj.ObjectId, plazaId: plazaId })
            this.getPurchaseOrder(authToken, storeId, plazaId);
        }

        console.log(authToken);
    }

    getPurchaseOrder(authToken, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + "/" + storeId + "?" + PLAZAID + plazaId;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ purchaseOrderList: responseData });
                        this.setState({ loaderClass: false });
                    }
                    else {
                        alert(Messages.NO_DATA);
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
            });
    }


    handlePurchaseOrder() {
        this.props.history.push({
            pathname: RouteMerchant.AddPurchaseOrder,
        })

    }


    editPurchaseOrder(purchaseOrderObj) {
        this.props.history.push({
            pathname: RouteMerchant.AddPurchaseOrder,
            search: "?id=" + purchaseOrderObj._id
        })
    }

    detailPurchaseOrder(purchaseOrderObj) {
        this.props.history.push({
            pathname: RouteMerchant.PurchaseOrderDetail,
            search: "?id=" + purchaseOrderObj._id
        })
    }

    handleDelete(peurchaseOrderObj, flag) {
        if (flag) {
            this.setState({ peurchaseOrderId: peurchaseOrderObj._id })
        }
        else {
            this.setState({ peurchaseOrderId: "" })
        }
    }


    deleteItem() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var peurchaseOrderId = this.state.peurchaseOrderId;
        var storeId = this.state.storeId;
        var plazaId = this.state.plazaId
        this.deleteItemAPI(authToken, storeId, peurchaseOrderId,plazaId)
    }

    deleteItemAPI(authToken, storeId, peurchaseOrderId,plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_SUPPLIER + "/" + storeId + "/" + DELETE + "/" + peurchaseOrderId+ "?" + PLAZAID + plazaId;
        var postData = {}
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ peurchaseOrderId: '' })
                    this.getPurchaseOrder(authToken, storeId,plazaId);
                }
                else {
                    this.setState({ isActive: false })
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })

    }

    render() {
        const { hideEmptyPlazaView } = this.state;

        const columns = [
            {
                name: "Supplier",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px", color: Color.THEME_COLOR, cursor: "pointer" }} onClick={() => this.detailPurchaseOrder(row)}>{row.Supplier}</div>
            },
            {
                name: "Payment terms",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Paymentterms}</div>
            },
            {
                name: "Supplier Currency",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Suppliercurrency} {row.CostSummary ? Number(row.CostSummary.Total).toFixed(2) : ""}</div>
            },
            {
                name: "Tracking Number",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Shipmentdetails ? row.Shipmentdetails.TrackingNumber : ""}</div>
            },
            {
                maxWidth: "80px",
                cell: row => <i class="fas fa-edit" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.editPurchaseOrder(row)}></i>
            },
            {
                maxWidth: "80px",
                cell: row => <a data-toggle="modal" data-target="#exampleModalLong">
                    <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleDelete(row, true)}></i>
                </a>
            },

        ];

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.PurchaseOrder} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handlePurchaseOrder}
                                    className="btn btn-reg-email">
                                    Add Purchase Order
                                </span>
                            </div>
                            <div className="reg_form_main">
                                {!hideEmptyPlazaView
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img></img>
                                            <p className="col-sm-12" for="FirstName">

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <DataTableExtensions
                                        data={this.state.purchaseOrderList}
                                        columns={columns}
                                        export={false}
                                        print={false}
                                        filter={this.state.purchaseOrderList.length > 0 ? true : false}
                                        filterHidden={false}
                                        filterPlaceholder={Messages.SearchforPurchaseOrder}
                                        filterStyle={{ width: "100px" }}
                                    >
                                        <DataTable
                                            noHeader={true}
                                        // noTableHead={true}
                                        // //pagination
                                        // onRowClicked={this.handleRowClick}

                                        />
                                    </DataTableExtensions>

                                }
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeletePurchaseOrderTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeletePurchaseOrder}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default PurchaseOrder;