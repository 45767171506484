import React, { Component } from 'react';
import './SelectPlaza.css';
import "../../../../styles/style.css";
import Messages from "../../../Config/Messages";
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import { PostData } from "../../../../services/PostData";
import { GET_PLAZA_API, COUNTRY_CODE, USER_REGISTRATION_API  } from '../../../Config/Config.json'
import { GetData } from "../../../../services/GetData";
import Constants from '../../../Config/Constants'

export default class SelectPlaza extends Component {
    constructor(props) {
        super(props);
        const data = this.props.location;
        this.state = {
            userFields: data.data,
            plazaFields: {},
            plazaList : [],
            errors: {},
            selectedOption: null,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    //    console.log("UserData", this.state.userFields.PostalCode);
      //  console.log("UserData", this.state.userFields.CountryCode);
     //   this.getPlazaByPostalCodeAPI(this.state.userFields.PostalCode, this.state.userFields.CountryCode);
    }

    getPlazaByPostalCodeAPI(postalCode, countryCode) {
        
        var API = GET_PLAZA_API + postalCode + COUNTRY_CODE + countryCode;
        GetData(API)
          .then(result => {
            var responseJson = result;
            if ( responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
              var responseData = responseJson["Data"];
              console.log("responseData ", responseData);
              if (responseData && responseData.length) {              
                this.setState({plazaList: responseData});
              }                                    
            }
            else {            
              alert(Messages.ERR_SomethingWentWrong);
            }
          });
      }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption.value);
        let plazaFields = this.state.plazaFields;
        plazaFields["TenantEmail"] = selectedOption.value
        this.setState({ plazaFields })        
      };

    validateForm() {
        let errors = {};
        let formIsValid = true;

        let fields = this.state.plazaFields;
        if (!fields["TenantEmail"]) {
            formIsValid = false;
            errors["TenantEmail"] = Messages.v_EnterPlaza;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {                
                this.setState({ src: reader.result })
            }, { orientation: true })
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    handleSubmit(e) {
        // var url = "";
        var postData = {};
        e.preventDefault();

        if (this.validateForm()) {
            postData = this.state.userFields;
            postData["TenantEmail"] = this.state.plazaFields.TenantEmail;
            postData["DeviceId"] = Constants.DEVICE_ID;
            postData["DeviceType"] = Constants.DEVICE_TYPE;
            postData["Company"] = Constants.COMPANY;
            postData["PushToken"] = Constants.PUSH_TOKEN;
            postData["Latitude"] = Constants.LATITUDE;
            postData["Longitude"] = Constants.LONGITUDE;  

            //alert('data: ' + JSON.stringify(postData));
            this.callResgisterAPI(postData);
        }
    }

    callResgisterAPI(postData) {        
        var URL = USER_REGISTRATION_API;       
        PostData(URL, postData)
          .then(result => {
            var responseJson = result;
            if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") { 
               this.props.history.push({
                    pathname: '/validateotp',
                    data: postData 
                })
            } 
            else
            {
                alert(responseJson["Message"])
            }
        });
    }



    render() {
        const { showPlazaForm, selectedOption, plazaList } = this.state;
        const options = plazaList.map(v => ({
            label: v.CompanyName,
            value: v.TenantEmail
          }));
       
        return (
            <section className="section">
                {/* <div id="loader" className={this.state.loaderClass}></div> */}
                <div className="registed_tab_part">
                    <div className="container">
                        <p id="instruction"><h4>Select Plaza</h4></p>
                        <div className="same_cantant">
                            <div className="registed_form_tab">
                                <div id="part1">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">
                                                <div className="reg_form_main">
                                                    <Select
                                                        value={selectedOption}
                                                        onChange={this.handleChange}
                                                        options={options}
                                                        placeholder="Select Plaza"
                                                    />                                                   
                                                    <div className="errorMsg">
                                                        {this.state.errors.TenantEmail}
                                                    </div>                                                    
                                                </div>

                                                <div className="reg_form_main">
                                                    <button
                                                        type="button"
                                                        id="btnAddPlaza"
                                                        hidden="true"
                                                        onClick={() => this.setState({ showPlazaForm: !showPlazaForm })}
                                                    >Add Plaza</button>
                                                </div>

                                                {showPlazaForm
                                                    ? <div id="PlazaForm">
                                                        <div className="reg_form_main">
                                                            <input
                                                                type="text"
                                                                name="PlazaName1"
                                                                value={this.state.plazaFields.PlazaName1}
                                                                placeholder="Plaza name"

                                                            />
                                                        </div>
                                                        <div className="reg_form_main">
                                                            <input
                                                                type="text"
                                                                name="Address"
                                                                value={this.state.plazaFields.Address}
                                                                placeholder="Ex. 1200 Danforth ave"

                                                            />
                                                        </div>
                                                        <div>
                                                            <img
                                                                className="profilePic"
                                                                src={this.state.src} />
                                                            <input
                                                                type="file"
                                                                id="updateProfile"
                                                                data-id="profile"
                                                                className="hideAction"
                                                                //onClick={this.handelModal}
                                                                onChange={this.onSelectFile}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                                <div className="bottam_sing">
                                                    <label
                                                        for="step2"
                                                        id="continue-step2"
                                                        onClick={this.handleSubmit}
                                                        className="btn btn-done"
                                                    > Continue</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

