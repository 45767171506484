import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants, { DEFAULTID } from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API, GET_STORE_ITEM, PLAZAID } from '../../Config/Config.json'
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import { DeleteData } from '../../../services/DeleteData';
import RouteMerchant from '../../Config/RouteMerchant.js';

class ItemsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeItemList: [],
            isActive: false,
            plazaId: "",
            storeId: "",
            ItemId: "",
        }
        // this.handleAddProducts = this.handleAddProducts.bind(this);
        // this.handleAddItems = this.handleAddItems.bind(this);
        this.handleAddProducts = this.handleAddProducts.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        StoreObjectInStorage(Constants.STROE_ITEM_OBJ, '')
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            this.getMyStoreItem(authToken, storeId, plazaId)
        } else {
            this.props.history.goBack()
        }
        // this.getMyStoreAPI(authToken);
        console.log(authToken);
    }

    getMyStoreItem(authToken, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_ITEM.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        var storeItemCategory = RetriveObjectFromStorage(Constants.STORE_PRODUCT_OBJ);
                        if (storeItemCategory) {
                            responseData = responseData.filter(e => e.SubCategoryID === storeItemCategory._id)
                            this.setState({ storeItemList: responseData });
                        }
                        this.setState({ loaderClass: false });
                    }
                    else {
                        alert(Messages.NO_DATA);
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
            });
    }

    handleAddProducts() {
        this.props.history.push({
            pathname: RouteMerchant.AddItems
        })
    }

    handleEditProducts(row) {
        StoreObjectInStorage(Constants.STROE_ITEM_OBJ, row)
        this.props.history.push({
            pathname: RouteMerchant.AddItems
        })
    }

    handleDelete(itemObj, flag) {
        if (flag) {
            this.setState({ ItemId: itemObj._id })
        }
        else {
            this.setState({ ItemId: "" })
        }
    }

    handleAddTopping(row) {
        StoreObjectInStorage(Constants.STROE_ITEM_OBJ, row)
        this.props.history.push({
            pathname: RouteMerchant.ManageToppingCategory
        })
    }

    deleteItem() {
        let itemId = this.state.ItemId;
        let storeId = this.state.storeId;
        var plazaId = this.state.plazaId
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.deleteItemAPI(authToken, storeId, itemId, plazaId)
    }

    deleteItemAPI(authToken, storeId, itemId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_STORE_ITEM.replace('#id#', storeId) + "/" + itemId + "?" + PLAZAID + plazaId;
        var postData = {}
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ ItemId: "" })
                    this.getMyStoreItem(authToken, storeId, plazaId)
                }
                else {
                    this.setState({ isActive: false })
                }
            })
    }

    render() {
        const { hideEmptyPlazaView } = this.state;
        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }
        const columns = [
            {
                maxWidth: "80px",
                cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px" }} src={row.ItemLogo} className="iconDetails" />
            },
            {
                name: "Product Name",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.ItemName}</div>
            },
            {
                name: "Product Price",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{currencySymbol}{Number(row.ItemPrice).toFixed(2)}</div>
            },
            {
                maxWidth: "80px",
                cell: row => <i class="fas fa-edit" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleEditProducts(row)}></i>
            },
            {
                maxWidth: "80px",
                cell: row => <a data-toggle="modal" data-target="#exampleModalLong">
                    <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleDelete(row, true)}></i>
                </a>
            },
            {
                maxWidth: "80px",
                cell: row => (row.ProductType == "") && <i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleAddTopping(row)}></i>
            },
        ];

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle="Product" />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="registered-with" style={{ float: "right" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAddProducts}
                                    className="btn btn-reg-email">
                                    Add Product
                                </span>
                            </div>

                            <div className="reg_form_main">
                                {!hideEmptyPlazaView
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img></img>
                                            <p className="col-sm-12" for="FirstName">

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <DataTableExtensions
                                        data={this.state.storeItemList}
                                        columns={columns}
                                        export={false}
                                        print={false}
                                        filter={this.state.storeItemList.length > 0 ? true : false}
                                        filterHidden={true}
                                        filterPlaceholder={Messages.manageItemSearch}
                                        filterStyle={{ width: "100px" }}
                                    >
                                        <DataTable
                                            noHeader={true}
                                            pagination
                                            paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                        // noTableHead={true}
                                        //pagination

                                        />
                                    </DataTableExtensions>

                                }
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteModalTitle}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteModalMessage}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default ItemsList;