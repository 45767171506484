import React, { Component } from 'react';
import './SelectCountry.css';
import "../../../../styles/style.css";
import Messages from "../../../Config/Messages";
import ReactFlagsSelect from 'react-flags-select'; // https://www.npmjs.com/package/react-flags-select
// import 'react-flags-select/css/react-flags-select.css';
import { GetData } from "../../../../services/GetData";
import { GET_COUNTRY_API } from '../../../Config/Config.json'


export default class SelectCountry extends Component {
    constructor(props) {
        super(props);
        const data = this.props.location;
        this.state = {
            userFields: data.data,
            countryFields: {},           
            errors: {},
            countryData:[],
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);        
        this.onSelectFlag = this.onSelectFlag.bind(this);
    }

    componentDidMount() {
        console.log("UserData", this.state.userFields);
        this.getCountryAPI();
    }

    getCountryAPI() {

        GetData(GET_COUNTRY_API)
          .then(result => {
            var responseJson = result;
            if ( responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
              var responseData = responseJson["Data"];
              console.log("responseData ", responseData);
              var countryCode = [];
              for(var i = 0 ; i < responseData.length; i++)
              {
                  var obj = responseData[i].CountryCode;
                  countryCode.push(obj);
              }  
              this.setState({countryData: countryCode});
              console.log("countryData ", this.state.countryData);              
            }
            else {            
              alert(Messages.ERR_SomethingWentWrong);
            }
          });
      }
    


    handleChange(event) {
        let countryFields = this.state.countryFields;
        let key = event.target.name
        let value = event.target.value
        countryFields[key] = value
        this.setState({ countryFields })
        // if (key === 'Name' && value[0] === ' ') value = value.trim()
    }

    onSelectFlag(countryCode){
        //console.log('countryCode ', countryCode)
        let countryFields = this.state.countryFields;
        countryFields["CountryCode"] = countryCode
        this.setState({ countryFields })
        console.log('CountryCode ', this.state.countryFields)
       
    }


    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.countryFields;

        if (!fields["CountryCode"]) {
            formIsValid = false;
            errors["CountryCode"] = Messages.v_EnterCountryName;
        }

        if (!fields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        // var url = "";
        var postData = {};
        e.preventDefault();

        if (this.validateForm()) {
            postData = this.state.userFields;
            postData["CountryCode"] = this.state.countryFields.CountryCode;
            postData["PostalCode"] = this.state.countryFields.PostalCode;           
            this.props.history.push({
                pathname: '/selectplaza',
                data: postData // your data array of objects
            })
        }
    }

    render() {
        
        return (
            <section className="section">
                {/* <div id="loader" className={this.state.loaderClass}></div> */}
                <div className="registed_tab_part">
                    <div className="container">
                        <p id="instruction"><h3>Select Country</h3></p>
                        <div className="same_cantant">
                            <div className="registed_form_tab">
                                <div id="part1">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">
                                                <div className="reg_form_main">  
                                                        <ReactFlagsSelect
                                                            className="menu-flags"
                                                            name="CountryCode"
                                                           //defaultCountry="US"
                                                           //countries={["US", "CA", "IN"]} 
                                                         
                                                           countries={this.state.countryData}
                                                           placeholder="Select Country"
                                                           selectedSize={18}
                                                           optionsSize={16}
                                                           onSelect={this.onSelectFlag}
                                                        /> 
                                                        <div className="errorMsg">
                                                                {this.state.errors.CountryCode}
                                                        </div>                                                      
                                                </div>

                                                <div className="reg_form_main">
                                                    <input
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        name="PostalCode"
                                                        value={this.state.countryFields.PostalCode}
                                                        placeholder="Postal code"                                                       
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.PostalCode}
                                                    </div>
                                                </div>
                                                <div className="bottam_sing">
                                                    <label
                                                        for="step2"
                                                        id="continue-step2"
                                                        onClick={this.handleSubmit}
                                                        className="btn btn-done"> Continue</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


// <section className="section">
//      <div className="registed_tab_part akcss">
//                     <div className="container">                   
//                     <div id="part1">
//                     <div className="sub_form_reg_one">
//                         <div id="home" className="tab-pane">
//                             <div className="as_user_form">
//                                 <div className="reg_form_main">                                  
//                                     <ul className="info-list">
//                                         <li>
//                                             <select
//                                                 id="test"
//                                                 name="CountryName"
//                                                 defaultValue=""
//                                                 onChange={this.handleSelectCountry}
//                                             >
//                                                 <option className="option2" selected  value="" disabled>
//                                                     select country
//                                                 </option>
//                                                 <option className="option2" value="US">
//                                                     USA
//                                                 </option>
//                                                 <option className="option2" value="CA">
//                                                     CANADA
//                                                 </option>
//                                             </select>                                           
//                                         </li>
//                                         <li>
//                                             <input
//                                                 type="text"
//                                                 onChange={this.handleChange}
//                                                 name="PostalCode"
//                                                 value={this.state.countryFields.PostalCode}
//                                                 placeholder="Postal code"                                            
//                                             />
                                           
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                             <div className="bottam_sing">
//                                 <label
//                                     for="step2"
//                                     id="continue-step2"
//                                     onClick={this.handleSubmit}
//                                     className="btn btn-done"> Continue</label>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
// </section>


