import React, { Component } from 'react';
import defaultUser from "../../images/icons/default-user.png";
import './SideMenu.css';
import WheelPhoto from "../../images/icons/gear-wheel.png";
import { MenuItem } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import Color from '../Config/Color';
// import RouteConstant from './Config/RouteConstant';
import { withRouter } from 'react-router';
import RouteMerchant from '../Config/RouteMerchant.js'
import { StoreDataInStorage, RetriveObjectFromStorage } from "../Config/Utils";
import Constants from '../Config/Constants'

class StoreItemSide extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleItemClick = this.handleItemClick.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    componentDidMount() {
    }

    handleItemClick(data) {
        console.log("item click")
        console.log(JSON.stringify(data))
        this.closeNav();
        // this.props.history.push({
        // 	pathname: RouteConstant.CurbsideOrder,                   
        // })
    }

    closeNav() {
        window.closeNav();
    }

    handleItem() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteMerchant.ManageItems,
        })

    }

    render() {
        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        let list = this.props.storeItems.slice(0, 10)
            .map(function (item, index) {
                return (
                    <React.Fragment key={item.OrderId}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY }} onClick={(value) => this.handleItem()} >
                            <div className="row" style={{ fontSize: "12px", flexWrap: "nowrap" }}>
                                <span style={{ width: "65px" }}> Item Name: </span>
                                {item.ItemName}
                            </div>
                            <div className="row" style={{ fontSize: "12px" }}>Category Name: {item.Category_name}</div>
                            <div className="row" style={{ fontSize: "12px" }}>Product Type: {item.ProductType}</div>
                            {item.ProductType == "W" && < div className="row" style={{ fontSize: "12px" }}>
                                <div style={{ fontSize: "12px", marginLeft: "auto", marginRight: "18px" }}>
                                    <center>{item.Weight} &nbsp; {item.WeightType}</center>
                                </div>
                            </div>
                            }
                            {/* < div className="row" style={{ fontSize: "12px" }}>
                                <div style={{ fontSize: "12px", marginLeft: "auto", marginRight: "18px" }}>
                                    <center>{0.0} &nbsp; {"kg"}</center>
                                </div>
                            </div> */}
                            <div className="row" >
                                <label style={{ fontSize: "12px", color: Color.THEME_COLOR }}>ItemPrice: {currencySymbol} {item.ItemPrice}
                                    <br />
                                    Cost Per Item: {currencySymbol} {item.CostPerItem}
                                </label>
                                <span style={{ marginLeft: "auto", }}>
                                    <img src={item.ItemLogo} style={{ height: "50px", marginRight: "10px", width: "50px" }}></img>
                                    {/* <label style={{ fontSize: "13px", float: "right", marginRight: "18px" }}>+ View More</label> */}
                                </span>
                            </div>
                        </div>
                    </React.Fragment >
                )
            }, this);

        return (
            <div style={{ cursor: "pointer" }}>
                {list}
            </div>
        );
    }
}

export default withRouter(StoreItemSide);