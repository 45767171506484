import React, { Component } from "react";
import "../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../Config/Messages";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreDataInStorage } from "../Config/Utils";
import { GET_PRODUCT_CATEGORY, PLAZAID } from '../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants from '../Config/Constants'
import Header from '../Header/Header.js'
import Color from "../Config/Color"
import SideMenu from "../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import Constvariable, { plazaCartDeliveryObj } from '../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../Config/RouteMerchant.js';
import { GetData } from "../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { DELIVERYOPTIONS_API, DELIVERYOPTIONS_MERCHANT_API, GET_PROFILE, GENERATEWEBHOOKTOKEN } from '../Config/Config.json';
import { PostData } from '../../services/PostData';
import { PUTData } from '../../services/PUTData';
import OnHoverIconInfo from "../UtillCustomComponent/UtillCustomComponent"
import { DoordashSetupForm } from '../Config/HoverMessage'
import { DoordashSetupConst } from "../Config/ConstForm"
import doordashlogo from '../../images/doordash-logo.png';
import { PutMandatoryfieldSymbol } from "../UtillCustomComponent/UtillCustomComponent";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class DoordashSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userFields: {},
            errors: {},
            isActive: false,

            allDeliverySetupList: [],
            selectedConfig: null,

            merchantDeliverySetupList: [],
            objFieldsList: [],
            isdoordashenable: false

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getProfileDetail(authToken)
    }

    getProfileDetail(authToken) {
        this.setState({ isActive: true })
        var URL = GET_PROFILE
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ isdoordashenable: responseData.isdoordashenable ? true : false })
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
                this.getAllConfig(authToken)
            });
    }

    getAllConfig(authToken) {
        this.setState({ isActive: true })
        var URL = DELIVERYOPTIONS_API;
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    responseData = responseData ? responseData : []
                    if (!this.state.isdoordashenable) {
                        responseData = responseData.filter(e => e.DeliveryOptionkey != plazaCartDeliveryObj.DOORDASH)
                    }
                    this.setState({ allDeliverySetupList: responseData, errors: {} });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.getMerchantConfig(authToken)
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getMerchantConfig(authToken) {
        this.setState({ isActive: true })
        var URL = DELIVERYOPTIONS_MERCHANT_API;
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    responseData = responseData ? responseData : []
                    this.setState({ merchantDeliverySetupList: responseData, errors: {} })
                    let { selectedConfig } = this.state;
                    if (selectedConfig) {
                        var obj = responseData.filter(e1 => e1.DeliveryOptionkey == selectedConfig.DeliveryOptionkey)[0]
                        if (obj) {
                            selectedConfig.webhookauthorizationtoken = obj.webhookauthorizationtoken
                        }
                    }
                    this.setState({ selectedConfig: selectedConfig })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    // /////////////// end detail methods ////////////////////////////

    openSetupModal() {
        this.handleModalsShow()
        this.resetValErr()
    }

    handleChange(event) {
        let key = event.target.name
        let value = event.target.value
        let objFieldsList = this.state.objFieldsList;
        objFieldsList = objFieldsList.map(e => {
            if (e.fieldkey == key) {
                return { ...e, "fieldvalue": value }
            }
            return e
        })
        this.setState({ objFieldsList: objFieldsList })
    }

    handleConfig = selectedConfig => {
        let merchantDetail = selectedConfig, objFields = selectedConfig.fields ? selectedConfig.fields : []

        if (merchantDetail && merchantDetail.fields) {
            let merchantDeliverySetupList = this.state.merchantDeliverySetupList
            var obj = merchantDeliverySetupList.filter(e1 => e1.DeliveryOptionkey == merchantDetail.DeliveryOptionkey)[0]
            if (obj) {
                merchantDetail.fields = merchantDetail.fields.map(e => {
                    var field = obj.fields.find(e1 => e1.fieldkey == e.fieldkey)
                    if (field) {
                        return { ...e, ...field }
                    }
                    return e
                })
                merchantDetail.webhookauthorizationtoken = obj.webhookauthorizationtoken
            }
        }
        this.setState({
            selectedConfig: merchantDetail,
            objFieldsList: objFields
        })
    }

    genrateWebhookToken(ob) {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getDoorDashToken(authToken, ob)
    }

    getDoorDashToken(authToken, selectedConfig) {
        this.setState({ isActive: true })
        var URL = GENERATEWEBHOOKTOKEN;
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    selectedConfig.webhookauthorizationtoken = responseData
                    this.setState({ selectedConfig: selectedConfig })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;

        let objFieldsList = this.state.objFieldsList;
        for (var obj of objFieldsList) {
            if (!obj.fieldvalue) {
                formIsValid = false;
                errors["Merr"] = Messages.v_allfield;
                break
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm() && this.state.selectedConfig) {
            postData = { ...this.state.selectedConfig }
            postData["fields"] = this.state.objFieldsList;
            delete postData["_id"]

            console.log("==> postdata==", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

            this.postMerchantSetup(authToken, postData)

        }
    }


    postMerchantSetup(authToken, postData) {
        this.setState({ isActive: true })
        var URL = DELIVERYOPTIONS_MERCHANT_API
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getMerchantConfig(authToken)
                    let selectedConfig = this.state.selectedConfig;
                    if (selectedConfig && selectedConfig.fields) {
                        selectedConfig.fields = postData.fields
                    }
                    this.setState({ selectedConfig: selectedConfig, errors: {} });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
                this.handleModalHide();
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    resetValErr() {
        let objFieldsList = this.state.objFieldsList;
        objFieldsList = objFieldsList.map(e => {
            return { ...e, "fieldvalue": "" }
        })
        this.setState({ objFieldsList: objFieldsList })
        this.setState({
            errors: {}
        })
    }

    handleModalsShow() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal2')
        if (backgroung && modalId) {
            backgroung.style.display = "block"
            modalId.style.display = "block"
        }
    }

    handleModalHide() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal2')
        if (backgroung && modalId) {
            backgroung.style.display = "none"
            modalId.style.display = "none"
        }
    }

    stringDotedConvert(value) {
        try {
            return String(value).slice(0, 10) + String(value).slice(10).replace(/(\W|\S)/g, ".")
        }
        catch (err) {
            console.log(err)
            return value
        }
    }

    render() {
        const { doorDashDetail, selectedConfig, objFieldsList } = this.state;

        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={DoordashSetupConst.DoordashSetup}></Header>

                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        <div className="reg_form_main">
                            <div className="card cartBorder setmargin">
                                <div className="card-body">
                                    <div className="d-md-flex justify-content-md-between align-items-center">
                                        <h5 className="card-title">{DoordashSetupConst.DoordashSetupMethods}</h5>
                                        <div className="reg_form_main col-lg-4 col-sm-6 p-0">
                                            {/* <label className="label-color">{DoordashSetupConst.ParentCategory}
                                            &nbsp;
                                            <OnHoverIconInfo
                                                message={DoordashSetupForm.ParentCategory} />
                                        </label> */}
                                            <Select menuPortalTarget={document.body}
                                                value={this.state.selectedConfig}
                                                onChange={this.handleConfig}
                                                options={this.state.allDeliverySetupList}
                                                styles={colourStyles}
                                                placeholder={Constvariable.D_SelectOption}
                                                className="select-input"
                                                getOptionLabel={(option) => option.DeliveryOption}
                                                getOptionValue={(option) => option.DeliveryOptionkey}
                                            />
                                        </div>
                                    </div>
                                    <div className="card setinnercartbg" >
                                        <div className="card-body">
                                            <div className="paymentbox">
                                                <div className="payment-image-box">
                                                    <h6> {selectedConfig ? selectedConfig.DeliveryOption : ""}
                                                    </h6>
                                                    {/* <img src={doordashlogo} /> */}
                                                </div>
                                                <div className="payment-image-box">
                                                    {selectedConfig && <a tabIndex='' className="setValue sethyperlink" onClick={() => this.openSetupModal()}> {DoordashSetupConst.btn_ClicktoSetup} </a>}
                                                </div>
                                            </div>

                                            <hr className="hrsolid-border" />
                                            <div className="paymentbox">
                                                {selectedConfig ?
                                                    (selectedConfig.fields &&
                                                        selectedConfig.fields.map((e, i) => {
                                                            return <div className="col-md-6" key={i}>
                                                                <p>{e.fieldname}</p>
                                                                <span className="setValue">{e.fieldvalue ? this.stringDotedConvert(e.fieldvalue) : "-"}</span>
                                                            </div>
                                                        }))
                                                    :
                                                    <div className="col-md-12">
                                                        <h6 className="text-center">{DoordashSetupConst.selectMsg}</h6>
                                                    </div>
                                                }
                                                {/* <div className="col-md-6">
                                                    <p>{DoordashSetupConst.DeveloperId}</p>
                                                    <span className="setValue">{doorDashDetail ? this.stringDotedConvert(doorDashDetail.developer_id) : "-"}</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <p>{DoordashSetupConst.KeyId}</p>
                                                    <span className="setValue">{doorDashDetail ? this.stringDotedConvert(doorDashDetail.key_id) : "-"}</span>
                                                </div>

                                                <div className="col-md-6">
                                                    <p>{DoordashSetupConst.SigningSecret}</p>
                                                    <span className="setValue">{doorDashDetail ? this.stringDotedConvert(doorDashDetail.signing_secret) : "-"}</span>
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                    {selectedConfig && selectedConfig.webhookauthorizationtoken
                                        && selectedConfig.DeliveryOptionkey == plazaCartDeliveryObj.DOORDASH && <React.Fragment>
                                            <div className="card setinnercartbg mt-2" >
                                                <div className="card-body">
                                                    <div class="row ml-0 mr-0">
                                                        <div class=" col-sm-6 col-8 pl-0">
                                                            <h6>   Generate Doordash Webhook Token</h6>
                                                        </div>
                                                        <div class="col-sm-6 col-4 pr-0 text-right">
                                                            <a tabIndex='' className="setValue sethyperlink"
                                                                onClick={() => this.genrateWebhookToken(selectedConfig)}> {DoordashSetupConst.GenerateWebhookToken} </a>
                                                        </div>
                                                    </div>
                                                    <hr className="hrsolid-border" />
                                                    <div class="row ml-0 mr-0">
                                                        <div class="col-sm-6 col-12 pl-0">
                                                            <p>Token</p>
                                                            <span className="setValue">
                                                                {selectedConfig.webhookauthorizationtoken}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <center>
                                            <div className="errorMsg">
                                                {this.state.errors.ErrorC}
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ///////////////////////// add modal start /////////////////////////// */}
                    <div className="modal-background" id="modalbackgroundId"> </div>
                    <div class="modal" id="myModal2" style={{ verticalAlign: "middle" }}>
                        <div class="modal-dialog setModal-addStore">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div className="registed_tab_part pb-0 pt-0" style={{ position: "relative" }}>
                                        <div className="container setfontcolor">
                                            <div className="text-right genreSlug">
                                                <i class="fas fa-times" onClick={() => this.handleModalHide()}></i>
                                            </div>
                                            <p style={{ display: "block" }}>
                                                <h4 className="setfontcolor">{DoordashSetupConst.DoordashSetup}</h4>
                                            </p>

                                            {objFieldsList && objFieldsList.map(e => {
                                                return <div className="reg_form_main col-sm-12">
                                                    <label className="label-color">{e.fieldname}
                                                        {/* &nbsp;
                                                        <OnHoverIconInfo
                                                            message={DoordashSetupForm.DeveloperId} /> */} <PutMandatoryfieldSymbol />
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name={e.fieldkey}
                                                        className='add-item-box'
                                                        placeholder={e.fieldname}
                                                        value={e.fieldvalue}
                                                        onChange={this.handleChange}
                                                    />
                                                    {/* <div className="errorMsg">
                                                        {this.state.errors.developer_id}
                                                    </div> */}
                                                </div>
                                            })}
                                            <div className="reg_form_main col-sm-12">
                                                <div className="errorMsg">
                                                    {this.state.errors.Merr}
                                                </div>
                                            </div>

                                            {/* <div className="reg_form_main col-sm-12">
                                                <label className="label-color">{DoordashSetupConst.KeyId}
                                                    &nbsp;
                                                    <OnHoverIconInfo
                                                        message={DoordashSetupForm.KeyId} />
                                                </label>
                                                <input
                                                    type="text"
                                                    name="key_id"
                                                    className='add-item-box'
                                                    placeholder={Constvariable.KeyId}
                                                    value={this.state.userFields.key_id}
                                                    onChange={this.handleChange}
                                                />
                                                <div className="errorMsg">
                                                    {this.state.errors.key_id}
                                                </div>
                                            </div>
                                            <div className="reg_form_main col-sm-12">
                                                <label className="label-color">{DoordashSetupConst.SigningSecret}
                                                    &nbsp;
                                                    <OnHoverIconInfo
                                                        message={DoordashSetupForm.SigningSecret} />
                                                </label>
                                                <input
                                                    type="text"
                                                    name="signing_secret"
                                                    className='add-item-box'
                                                    placeholder={Constvariable.SigningSecret}
                                                    value={this.state.userFields.signing_secret}
                                                    onChange={this.handleChange}
                                                />
                                                <div className="errorMsg">
                                                    {this.state.errors.signing_secret}
                                                </div>
                                            </div> */}

                                            <div className="bottam_sing">
                                                <label
                                                    style={{ background: Color.THEME_COLOR }}
                                                    for="step5"
                                                    id="continue-step5"
                                                    onClick={this.handleAdd}
                                                    className="btn btn-done"
                                                > {DoordashSetupConst.btn_Submit}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ////////////////////// add modal end ///////////////////////// */}

                </LoadingOverlay>
            </section>

        );
    }
}
export default DoordashSetup;