const Messages = {
    v_EnterName: "*Please enter your first name",
    v_EnterComments: "*Please enter your comments",
    v_EnterCaptchacode: "*Please enter the code shown below",
    v_EnterValidCaptchacode: "*Please enter the valid code shown below",
    v_EnterFullName: "*Please enter your name",
    v_EnterValidName: "*Please enter alphabet characters only",
    v_EnterLastName: "*Please enter your last name",
    v_EnterEmail: "*Please enter your email",
    v_EnterPassword: "*Please enter your password",
    v_EnterPhoneNo: "*Please enter your phone number",
    v_EnterConfirmPassword: "*Please enter confirm password",
    v_EnterCreateNewPassword: "*Please enter your new password",
    v_EnterValidEmail: "*Please enter valid email address",
    v_ConfirmPwdMatchWithPwd: "*Confirm Password must match to the entered password",
    v_PasswordChanged: "your password has been changed successfully",
    v_EnterCreateConfirmPassword: "*Please enter your new confirm password",
    v_EnterValidPassword: "*Password must be 8 characters long with one number, one special character and one upper case letter",
    v_EnterValidPhoneNo: "*Please enter valid phone number",
    v_EnterOnlyNumber: "*Please enter only number",
    v_EnterOTP: "*Please enter your one time code",
    v_Add_product_size_color: "*Please add product color & quantity",
    v_productsizecolordescription: "*Please add product size, color and description",
    v_Add_product_size: "*Please add product size",
    v_product_Description: "*Please enter product description",
    v_EnterValidOTP: "*One time code must be 6 digit number",
    v_EnterPostalCode: "*Please enter postal code",
    v_EnterCountryName: "*Please enter country name",
    v_EnterPlaza: "*Please select plaza name",
    v_EnterPlazaName: "*Please enter plaza name",
    NO_DATA: "Data not found. Please try again later.",
    ERR_SomethingWentWrong: "Something went wrong. Please try again later.",
    Invalid_One_Time_Code: "Invalid one time code",
    MSG_CHECK_EMAIL: "Please check your email.",
    S_NAME: "*Please enter brewery name",
    S_VALID_NAME: "Enter valid brewery name",
    S_CATE: "*Please select brewery category",
    S_WEBSITE_URL: "*Please enter brewery website url",
    S_WEBSITE_Valid_URL: "*Please enter a valid store website URL",
    S_FACEBOOK: "*Please enter facebook user",
    S_DEC: "Enter your brewery description",
    S_CITY: "*Please enter city name",
    S_ADDRESS: "*Please select brewery adddress",
    S_LOGO: "Please upload your brewery's logo.",
    S_BANNER: "Please upload your brewery's banner.",
    S_BANNERPlaza: "*Please upload your plaza's banner.",
    v_PalazAddress: "*Please select plaza address",

    // additem //
    v_product: "*Please enter product name",
    v_Price: "*Please enter product price",
    v_PriceNumber: "*Please enter numeric price",
    v_RewardPoint: "*Please enter numeric reward point",
    v_costPerItem: "*Please enter cost per product",
    v_costPerItemNumber: "*Please enter numeric cost per product",
    v_weight: "*Please enter numeric weight",
    v_tax: "*Please enter numeric tax",
    v_productType: "*Please select product type",
    v_s_productCategory: "*Please select product category",
    v_wightUnit: "*Please select wight unit",
    v_SKU: "*Please enter SKU",
    v_BarCode: "*Please Enter UPC (Universal Product Code)",
    v_AvailableQuantity: "*Please enter available quantity",
    v_AvailableQuantityNum: "*Please enter numeric quantity",
    v_AvailableValidQuantityNum: "*Please enter valid numeric quantity",
    v_ToppingQuantity: "*Please enter topping quantity",
    v_ProperQuantity: "*Please enter proper quantity",
    v_ProductImage: "*Select product images",
    v_Specialdeliverycharges: "*Please enter Special Delivery Charges",
    // end additem //

    // add addProduct //
    v_productCategory: "*Please enter product category",
    v_toppingCategory: "*Please enter topping category",
    // end  addProduct //

    // add topping //
    v_toppingItem: "*Please enter topping name",
    v_toppingPrice: "*Please enter topping price",
    v_toppingPriceNumber: "*Please enter numeric topping price",
    // end add topping //

    // AddStoreSupplier //
    v_supplier: "*Please enter supplier name",
    v_address: "*Please select address",
    v_apartment: "*Please enter apartment",
    v_city: "*Please enter city name",
    v_state: "*Please enter state name",
    v_country: "*Please enter country name",
    v_pinCode: "*Please enter postal code",
    v_contactName: "*Please enter contact name",
    v_emailId: "*Please enter email",
    v_emailIdFormate: "*Please enter proper email",
    v_phoneNumber: "*Please enter phone number",
    v_phoneNumberFormate: "*Please enter proper phone number",
    // end AddStoreSupplier //

    // AddPickuptimes //
    v_pickupTime: "*Please select proper pickup time",
    v_pickupTimestart: "*Please enter pickup start time",
    v_pickupTimeend: "*Please enter pickup end time",
    v_StoreHours: "*Please select proper brewery hours",
    v_StoreHours1: "Please check and select a different time than the one you've already selected. (This applies to brewery, delivery, and pick-up timing.)",
    // end AddPickuptimes //

    // AddDeliverytimes //
    v_deliveryTime: "*Please select proper delivery time",
    v_deliveryCost: "*Please enter delivery cost",
    v_deliveryCostNumber: "*Please enter numeric delivery cost",

    // Deliverytimes //
    v_Defaultmiles: "*Please select unit miles/KM",
    v_DefaultmilesInt: "*Please enter proper default miles",
    v_Defaultmilesfees: "*Please select unit miles/KM fees",
    v_DefaultmilesfeesInt: "*Please enter proper default miles fees",
    v_Extrapermilefees: "*Please select extra unit per mile/KM fees",
    v_ExtrapermilefeesInt: "*Please enter proper extra per mile fees",
    v_StoreHours3: "Your brewery is not open between the delivery times you selected. Please update the delivery time based on brewery hours.",
    v_StoreHours4: "Your brewery is not open between the pickup times you selected. Please update the pickup time based on brewery hours.",
    v_DoordashBusiness: "*Please select doordash business",
    // end Deliverytimes //

    // UpdateInventory //
    v_NewQuantity: "*Please enter new quantity",
    v_NewQuantityNumber: "*Please enter numeric quantity",
    // end UpdateInventory //

    // AddPurchaseOrder //
    v_selecteItem: "*Please select product",
    v_SupplierSKU: "*Please enter Supplier SKU",
    v_Quantity: "*Please enter quantity",
    v_Cost: "*Please enter cost",
    v_Costnum: "*Please enter numeric cost",
    v_ProductTax: "*Please enter product tax",
    v_ProductTaxnum: "*Please enter numeric product tax",
    v_seletectSupplier: "*Please select supplier",
    v_Paymentterms: "*Please enter payment terms",
    v_Suppliercurrency: "*Please enter supplier currency",
    v_ShipingCenter: "*Please enter shiping center",
    v_TotalTax: "*Please entre total tax",
    v_TotalTaxnum: "*Please enter numeric tax",
    v_ShippingCharge: "*Please enter shipping charge",
    v_ShippingChargenum: "*Please enter numeric shipping charge",
    v_Amount: "*Please enter numeric amount",
    v_oneProduct: "*Please enter at least one product",
    // emd AddPurchaseOrder //

    // Earnings //
    v_FromDate: "*Please enter from date",
    v_ToDate: "*Please enter to date",
    // end Earnings //

    //  ReceiveInventory //
    v_AcceptQuantity: "*Please enter accept quantity",
    v_RejectQuantity: "*Please enter reject quantity",
    //  end ReceiveInventory //

    // constent messages //
    Loading: "Loading...",
    DeleteModalMessage: "Are you sure you want to delete this Product?",
    DeleteSupplier: "Delete Supplier",
    DeleteSupplierMessage: "Are you sure you want to delete this Supplier?",
    DeleteCategoryTitile: "Delete Category",
    DeleteCategory: "Are you sure you want to delete this Category?",
    ParentDeleteCategory: "Are you sure you want to delete this parent category? It will delete all related categories and products.",
    DeleteToppingsCategoryTitile: "Delete Toppings Category",
    DeleteToppingsCategory: "Are you sure you want to delete this Toppings Category?",
    DeleteToppingsTitile: "Delete Topping",
    DeleteToppings: "Are you sure you want to delete this Topping?",
    DeletePurchaseOrderTitile: "Delete Purchase Order",
    DeletePurchaseOrder: "Are you sure you want to delete this Purchase Order?",
    DeletePickupTimesTitile: "Delete Pickup Time",
    DeletePickupTimes: "Are you sure you want to delete this Pickup Time?",
    DeleteDeliveryTimesTitile: "Delete Delivery Option",
    DeleteDeliveryTime: "Are you sure you want to delete this Delivery Option?",
    DeleteNotificationTitile: "Delete Notification",
    DeleteNotification: "Are you sure you want to delete this Notification?",
    DeleteCouponTitile: "Delete Coupon",
    DeleteCoupon: "Are you sure you want to delete this Coupon?",
    DeleteUserTitile: "Delete User",
    DeleteUser: "Are you sure you want to delete this User?",

    DeleteModalTitle: "Delete Product",
    manageItemSearch: "Search for Products",
    SearchforStore: "Search for Brewery",
    SearchforPlaza: "Search for Plaza",
    SearchforCategory: "Search for Category",
    SearchforToppingsCategory: "Search for Toppings Category",
    SearchforToppings: "Search for Toppings",
    SearchforInventory: "Search for Inventory",
    SearchforInventoryHistory: "Search for Inventory History",
    SearchforPickuptimes: "Search for Pickup Times",
    SearchforDeliverytimes: "Search for Delivery Options",
    SearchforNotification: "Search for Notification",
    SearchforPurchaseOrder: "Search for Purchase Order",
    SearchforEarnings: "Search for Earnings",
    SearchforCoupon: "Search for Coupon",
    SearchforRedemption: "Search for Redemption",
    SearchforUser: "Search for User",
    SearchforStoreAssign: "Search for Brewery",
    // end constent messages //


    // sideMenu constent  ////
    ACTIVE: "ACTIVE",
    // end sideMenu constent ////

    // Store //
    StoreURL: "Enter Brewery's URL",
    v_S_phoneNumber: "*Please enter phone number",
    AccToken: "Enter access token",
    NewAccToken: "Enter new access token",
    APIVersion: "Enter API version",
    // end Store //

    // Subscription //
    paymentTermTitle: "Payment Terms",
    paymentTermMsg1: "Message Coming Soon",
    cancelSubscriptionTitle: "How do I cancel my subscription?",
    subscriptionMsg1: "Message Coming Soon",
    havePayPalTitle: "Do I have to use PayPal?",
    haveStripeTitle: "Do I have to use Stripe",
    havePayPalMsg1: "Message Coming Soon",
    // end Subscription //

    // MySubscription //
    DeactivatePlan: "Deactivate Plan",
    DeactivatePlanMsg: "Are you sure you want to cancel your plan?",
    // end MySubscription //

    //  RegisterNew // 
    popupPlazaTitle: "Note",
    popupPlazaMsg1: "Thank You. Your request for adding a new plaza is received successfully.'We will use the information provided to evaluate your plaza, so make sure it is true.'",
    popupPlazaMsg2: "The plaza will be available on the list once the evaluation is completed by our team.",
    v_Selectaddress: "*Please select address",
    //  end RegisterNew //

    //New Plaza Request//
    newpopupPlazaTitle: "New Plaza Request",

    // AddCoupon //
    Title: "*Please Enter Coupon Title",
    Description: "*Please Enter Coupon Description",
    MinimumRequiredAmount: "*Please Enter Minimum Order Amount Required To Avail This Coupon",
    Num_MinimumRequiredAmount: "*Please Enter Proper Minimum Required Amount",
    DicountPrice: "*Please Enter Discount Price",
    Num_DicountPrice: "*Please Enter Proper Discount Price",
    DicountValidPrice: "*Please Enter A Discount Price Less Than The Minimum Required Order Amount",
    StartDate: "*Please Enter Start Date",
    EndDate: "*Please Enter End Date",
    uploadCouponLogo: "*Please Upload Coupon logo",
    // end AddCoupon //


    // Redemption //
    v_StartDate: "*Please enter start date",
    v_EndDate: "*Please enter end date",
    // end Redemption //

    // BulkUploadCatalog //
    v_FileExtension: "File type must be .csv",
    // end BulkUploadCatalog //

    // ImageBulkUpload //
    v_ImageExtension: "File type must be .jpg ,.jpeg ,.bmp ,.gif ,.png",
    v_ImageExtension_ALL: "Allowed image types: .png, .jpg, .jpeg, .gif, .bmp",
    // end ImageBulkUpload //

    // DoordashSetup //
    v_developer_id: "*Please enter developer id",
    v_key_id: "*Please enter key id",
    v_signing_secret: "*Please enter signing secret",
    v_allfield: "*All fields are mandatory",
    // end DoordashSetup //

    // AddUser //
    v_UserEmail: "*Please enter email",
    v_UserName: "*Please enter first name",
    v_UserLastName: "*Please enter last name",
    v_UserPassword: "*Please enter password",
    v_UserPhoneNo: "*Please enter phone number",
    v_UserOTP: "*Please enter one time code",
    v_otp_valide: "Invalid one time code",
    // end AddUser //
    v_ForgotPaswword_Email: "We did not recognise this email address.",
    v_Email_already_exists: "Email is already exists",
    v_sent_otp_message: "Your One Time Code has been sent to the e-mail associated with your account.",
    v_Add_plaza_success_message: "The request to add a new plaza was submitted successfully.",
    v_calanderDays: "*Please enter next calendar days",
    v_calanderDays_int: "*Please enter proper next calendar days",
    v_s_start: "*Please enter schedule start date",
    v_e_date: "*Please enter schedule end date",
    v_d_time: "*Please enter duration time",
    v_d_time_int: "*Please enter proper duration time",
    v_StoreAppointmentHours: "*Please enter proper hours",
    v_Product_internal_id: "*Please enter product internal id",
    v_ContactUS_message: "Thank you,We have received your request and will get back to you soon.",
    managestoresupplierSearch: "Search for supplier",
    v_EnterCaptcha: "*Please enter captcha code",
    v_EnterValidCaptcha: "*Please enter valid captcha code",

    v_storeLogoResolution: "Allowed image types: .png, .jpg, .jpeg, .gif\nRequired resolutions: [512 X 512]\nMax file size allowed: 3.5 MB",
    v_storeBannerResolution: "Allowed image types: .png, .jpg, .jpeg, .gif\nRequired resolutions: [1024 X 512]\nMax file size allowed: 3.5 MB",
    v_bulkImageUploadResolution: "Allowed image types: .png, .jpg, .jpeg, .gif\nRequired resolutions: [1024 X 512]\nMax file size allowed: 3.5 MB",
    v_couponImageResolution: "Allowed image types: .png, .jpg, .jpeg, .gif\nRequired resolutions: [1024 X 512]\nMax file size allowed: 3.5 MB",
    v_productImageResolution: "Allowed image types: .png, .jpg, .jpeg, .gif\nRequired resolutions: [1024 X 512]\nMax file size allowed: 3.5 MB",
    v_requiredFieldEmpty: "There was an error in saving. Please make sure to enter required fields in red.",
    v_TaxValidat: "*Please enter peroper tax details",
    v_TaxName: "*Please enter tax name",
    v_TaxType: "*Please enter tax type",
    v_ApplicableTax: "*Please enter applicable tax (%)",
    v_ApplicableTaxNum: "*Please enter proper applicable tax (%)",
    v_ApplicableFromDate: "*Please enter applicable from date",
    v_ApplicableToDate: "*Please enter applicable to date",
    v_Status: "*Please select status",
    NO_RECORD: "There are no records to display"
};

export default Messages;