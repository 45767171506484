import React, { Component } from "react";
import "../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../Config/Messages";
import { RetriveDataFromStorage, validateFloat, RetriveObjectFromStorage, validateInt, StoreObjectInStorage, checkPostalCode, removeAllSpace, formatPhoneNumber, validatePhoneNumber } from "../Config/Utils";
import { GET_PROFILE, GOOGLE_PLACE_KEY, KEY_STRING, PLACE_ID } from '../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants from '../Config/Constants'
import { GetDataGooglePlace } from '../../services/GetDataGooglePlace';
import Header from '../Header/Header.js'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Color from "../Config/Color"
import SideMenu from "../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import RouteMerchant from '../Config/RouteMerchant.js';
import Constvariable, { UserAccess } from '../Config/constvariable';
import { PostData } from '../../services/PostData';
import { PUTData } from '../../services/PUTData';
import { GetData } from "../../services/GetData";
import defaultUser from "../../images/default-user.png";
import LoadingOverlay from 'react-loading-overlay';
import OnHoverIconInfo from "../UtillCustomComponent/UtillCustomComponent"
import { UserProfileForm } from '../Config/HoverMessage'
import { UserProfileConstForm } from "../Config/ConstForm";
import { PutMandatoryfieldSymbol } from "../UtillCustomComponent/UtillCustomComponent";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            errors: {},
            storeId: '',
            ItemId: '',
            toppingCategoryId: '',
            toppingId: '',

            selectedAddress: null,
            IsParent: true

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

        var isParent = RetriveDataFromStorage(Constants.ISPARENT)
        this.setState({ IsParent: isParent == UserAccess.ISPARENTFALSE ? false : true })
        var userDetail = RetriveObjectFromStorage(Constants.USER_DETAIL)
        if (userDetail) {
            this.setState({ userFields: userDetail })
            this.autoPopulated(userDetail)
        }
        else {
            this.getProfileDetail(authToken)
        }

    }

    getProfileDetail(authToken) {
        this.setState({ isActive: true })
        var URL = GET_PROFILE
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.autoPopulated(responseData)
                    }
                    else {
                        alert(Messages.NO_DATA);
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
            });
    }

    autoPopulated(userObj) {
        let userFields = this.state.userFields;
        userFields['firstName'] = userObj.FirstName;
        userFields['lastName'] = userObj.LastName;
        userFields['phone'] = formatPhoneNumber(userObj.Phone);
        userFields['company'] = userObj.Company;
        userFields['postalCode'] = userObj.PostalCode;
        userFields['countryCode'] = userObj.CountryCode;
        userFields['eMail'] = userObj.eMail;
        userFields['plazaEmail'] = userObj.PlazaEmail;
        userFields['plazaName'] = userObj.PlazaName;
        this.setState({ userFields })
    }



    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "phone") {
            userFields[key] = formatPhoneNumber(value)
        }
        else {
            userFields[key] = value
        }
        this.setState({ userFields })
    }

    handelAddress(addressObj) {
        this.setState({
            selectedAddress: addressObj
        })
        console.log(addressObj)
        let userFields = this.state.userFields;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace("", addressObj.value.place_id, userFields)
        }
        else {
            userFields['postalCode'] = ""
        }
        this.setState({ userFields })
    }

    getaddressGooglePlace(authToken, value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        console.log(placeId)
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                console.log("====", result)
                if (responseJson) {
                    var responseData = responseJson;
                    if (responseData && responseData.address_components) {
                        refObj["postalCode"] = ""
                        for (var obj of responseData.address_components) {
                            if (obj.types && checkPostalCode(obj.types)) {
                                refObj["postalCode"] = removeAllSpace(obj.short_name)
                                break
                            }
                        }
                    }
                    else {
                        refObj["postalCode"] = ""
                    }
                }
                else {
                    refObj["postalCode"] = ""
                }
                this.setState({ refObj, isActive: false })
            }).catch(err => {
                refObj["postalCode"] = ""
                this.setState({ refObj, isActive: false })
            });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = Messages.v_EnterName;
        }

        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = Messages.v_EnterLastName;
        }

        if (!fields["phone"]) {
            formIsValid = false;
            errors["phone"] = Messages.v_EnterPhoneNo;
        }
        else if (fields["phone"]) {
            if (!validatePhoneNumber(fields["phone"])) {
                formIsValid = false;
                errors["phone"] = Messages.v_phoneNumberFormate;
            }
        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['FirstName'] = this.state.userFields.firstName;
            postData['LastName'] = this.state.userFields.lastName;
            postData['PhoneNumber'] = this.state.userFields.phone;
            postData['PostalCode'] = this.state.userFields.postalCode;

            console.log("==> postdata==", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.updateProfile(authToken, postData)

        }
    }

    updateProfile(authToken, postData) {
        this.setState({ isActive: true })
        var URL = GET_PROFILE
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    StoreObjectInStorage(Constants.USER_DETAIL, '')
                    this.props.history.goBack();
                }
                else {
                    alert(responseJson["Message"])
                }
            })
        this.setState({ isActive: false })
    }


    render() {

        const { IsParent } = this.state
        return (

            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner" >
                    <Header setHeaderTitle={Constvariable.ManageProfile}></Header>
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                        {/* <div className="col-sm-12" style={{ marginTop: "10px" }}>
                        <center>
                            <img src={this.state.userFields.ProfilePicture ? this.state.userFields.ProfilePicture : defaultUser} style={{ borderRadius: "50px" }} width="100" height="100" ></img>
                            <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, height: "30px", width: "30px", position: "absolute", top: "60%", left: "51%", borderRadius: "20px" }} onClick={() => { this.base64convert() }}>
                                <i class="fas fa-camera" style={{ fontSize: "17px", marginTop: "6px" }} onClick={this.handleEditProfile}></i>
                            </div>
                            <input type="file" id="imgupload" style={{ display: "none" }} name="myfile" onChange={this.handleFileInput} accept=".jpg,.bmp,.png,.jpeg" />
                        </center>
                        <br />
                    </div> */}

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.FirstName}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.FirstName} /> <PutMandatoryfieldSymbol />
                            </p>
                            <input
                                type="text"
                                name="firstName"
                                className='add-item-box'
                                placeholder={Constvariable.FirstName}
                                value={this.state.userFields.firstName}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.firstName}
                            </div>
                        </div>
                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.LastName}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.LastName} /> <PutMandatoryfieldSymbol />
                            </p>
                            <input
                                type="text"
                                name="lastName"
                                className='add-item-box'
                                placeholder={Constvariable.LastName}
                                value={this.state.userFields.lastName}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.lastName}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.Phone}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.Phone} /> <PutMandatoryfieldSymbol />
                            </p>
                            <input
                                type="text"
                                name="phone"
                                className='add-item-box'
                                placeholder={Constvariable.Phone}
                                value={this.state.userFields.phone}
                                maxlength="14"
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.phone}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.Company}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.Company} />
                            </p>
                            <input
                                type="text"
                                name="company"
                                className='add-item-box'
                                placeholder={Constvariable.Company}
                                value={this.state.userFields.company}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.company}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.Address} &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.Address} />
                            </p>
                            <GooglePlacesAutocomplete
                                apiKey={GOOGLE_PLACE_KEY}
                                selectProps={{
                                    value: this.state.selectedAddress,
                                    onChange: (setAddress) => this.handelAddress(setAddress),
                                    menuPortalTarget: document.body,
                                    isClearable: true,
                                    placeholder: Constvariable.Profile_Address,
                                    styles: colourStyles
                                }}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: this.state.userFields.countryCode ? [this.state.userFields.countryCode] : []
                                    }
                                }}
                            />
                            <div className="errorMsg">
                                {this.state.errors.address}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.PostalCode}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.PostalCode} />
                            </p>
                            <input
                                type="text"
                                name="postalCode"
                                className='add-item-box'
                                placeholder={Constvariable.PostalCode}
                                value={this.state.userFields.postalCode}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.postalCode}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.CountryCode}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.CountryCode} />
                            </p>
                            <input
                                type="text"
                                name="countryCode"
                                className='add-item-box'
                                placeholder={Constvariable.CountryCode}
                                value={this.state.userFields.countryCode}
                            // onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.countryCode}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.eMail}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.eMail} /> <PutMandatoryfieldSymbol />
                            </p>
                            <input
                                type="text"
                                name="eMail"
                                className='add-item-box'
                                placeholder={Constvariable.eMail}
                                value={this.state.userFields.eMail}
                            // onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.eMail}
                            </div>
                        </div>

                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.PlazaEmail}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.PlazaEmail} />
                            </p>
                            <input
                                type="text"
                                name="plazaEmail"
                                className='add-item-box'
                                placeholder={Constvariable.PlazaEmail}
                                value={this.state.userFields.plazaEmail} />
                            <div className="errorMsg">
                                {this.state.errors.plazaEmail}
                            </div>
                        </div> */}

                        {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                            <p className="col-sm-12 textcolor" for="StreetAddress" style={{ paddingLeft: "0px" }}>
                                {UserProfileConstForm.PlazaName}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={UserProfileForm.PlazaName} />
                            </p>
                            <input
                                type="text"
                                name="plazaName"
                                className='add-item-box'
                                placeholder={Constvariable.PlazaName}
                                value={this.state.userFields.plazaName} />
                            <div className="errorMsg">
                                {this.state.errors.plazaName}
                            </div>
                        </div> */}
                    </div>
                    {IsParent && <div className="bottam_sing">
                        <label
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done"
                        > {UserProfileConstForm.UpdateProfile}</label>
                    </div>}
                </LoadingOverlay>
            </section>

        );
    }
}
export default UserProfile;