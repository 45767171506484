import React, { useEffect, useRef } from "react"


function MyPayment(props) {
    try {
        var s = document.createElement('script');
        const appendHead = () => {
            var button = window.paypal.Buttons({
                createSubscription: function (data, actions) {
                    return actions.subscription.create({
                        'plan_id': props.planId // Creates the subscription
                    });
                },
                onApprove: function (data, actions) {
                    console.log('====planid', props)
                    data.pid = props.planId
                    props.successSubscribe(data)
                    //alert('You have successfully created subscription ' + data.subscriptionID); // Optional message given to subscriber
                }
            })

            if (document.getElementById("paypal-button-container")) {
                button.render("#paypal-button-container")
            }

        }

        s.setAttribute('src', "https://www.paypal.com/sdk/js?client-id=" + props.clientId + "&vault=true&intent=subscription"); s.onload = appendHead;
        document.head.insertBefore(s, document.head.firstElementChild);


        return (
            <div>
                <div id={"paypal-button-container"}></div>
            </div>
        )
    }
    catch (err) {
        console.log(err)
        return null
    }

}

export default MyPayment