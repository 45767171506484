import React, { Component } from "react";
import Header from "./components/Header/Header";
import Routes from "./routes";

class App extends Component {
  constructor() {
    super();
    this.state = {
      appName: "React App",
      home: false
    };
  }
  render() {
    return (
      <div>
        <div data-off-canvas-content>
          {/* <Header name={this.state.appName} /> */}
          <Routes name={this.state.appName} />
        </div>
      </div>
    );
  }
}
export default App;