import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { GetData } from "../../../services/GetData";
import { PostData } from '../../../services/PostData';
import Color from '../../Config/Color';
import { GETPARTERNERREFERRALSTATUS, PARTERNERREFERRALCALLBACK, PLAZAID, STOREID } from '../../Config/Config.json';
import Constants, { DEFAULTID, COMPANYCONST } from '../../Config/Constants';
import Messages from "../../Config/Messages";
import RouteMerchant from '../../Config/RouteMerchant.js';
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { PlatFrom } from '../../Config/constvariable';
import Header from '../../Header/Header.js';
import paypal from "../../../images/paypal.svg";
import '../Payments.css';

class PayPalPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeId: '',
            plazaId: "",
            partnerStatusObj: null,
            platForm: ""

        }
        this.handleAddProducts = this.handleAddProducts.bind(this);
        // this.handleAddItems = this.handleAddItems.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);

        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })

            var platForm = ""
            if (storeObj.isshopifyintegration) {
                platForm = PlatFrom.Shopify
            }
            else if (storeObj.iswixintegration) {
                platForm = PlatFrom.Wix
            }
            else {
                platForm = PlatFrom.Plazacart
            }
            this.setState({ platForm: platForm })

            let search = window.location.search;
            let params = new URLSearchParams(search);

            var postData = {}
            postData["merchantId"] = params.get("merchantId")
            postData["merchantIdInPayPal"] = params.get("merchantIdInPayPal")
            postData["permissionsGranted"] = params.get("permissionsGranted") == "true" ? true : false
            postData["consentStatus"] = params.get("consentStatus") == "true" ? true : false
            postData["productIntentId"] = params.get("productIntentId")
            postData["isEmailConfirmed"] = params.get("isEmailConfirmed") == "true" ? true : false
            postData["accountStatus"] = params.get("accountStatus")
            postData["returnMessage"] = params.get("returnMessage")

            if (params.get("merchantId") && params.get("merchantIdInPayPal")) {
                console.log("postData", postData)
                this.postpaypalSetup(authToken, postData, storeId, plazaId)
            }
            else {
                this.getParternerReferralStatus(authToken, storeId, plazaId);
            }


        }
        else {
            this.props.history.goBack()
        }
    }

    postpaypalSetup(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = PARTERNERREFERRALCALLBACK + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log(result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    // this.props.history.goBack();
                    this.props.history.push({
                        pathname: RouteMerchant.PayPalPayment,
                    })
                    this.getParternerReferralStatus(authToken, storeId, plazaId)
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })
        this.setState({ isActive: false })
    }

    getParternerReferralStatus(authToken, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = GETPARTERNERREFERRALSTATUS + "?" + STOREID + storeId + "&" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ partnerStatusObj: responseData });
                    }
                    else {
                        this.setState({ partnerStatusObj: null })
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
            });
    }


    // regularRegister(e) {
    //     localStorage.removeItem("FacebookData");
    //     window.location.href = "/Store";
    // }

    handleAddProducts() {
        this.props.history.push({
            pathname: RouteMerchant.AddToppings,
        })

    }

    handleEdittopping(row) {
        StoreObjectInStorage(Constants.TOPPING_OBJ, row)
        this.props.history.push({
            pathname: RouteMerchant.AddToppings,
        })

    }

    handleDelete(toppingObj, flag) {
        if (flag) {
            this.setState({ toppingId: toppingObj._id })
        }
        else {
            this.setState({ toppingId: "" })
        }
    }

    // deleteItem() {
    //     let itemId = this.state.ItemId;
    //     let storeId = this.state.storeId;
    //     let toppingcateid = this.state.toppingCategoryId;
    //     let toppingId = this.state.toppingId;
    //     var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

    //     var postData = {}
    //     postData['ItemId'] = itemId;
    //     postData['ToppingCategoryId'] = toppingcateid;
    //     postData['ToppingItemId'] = toppingId;

    //     this.deleteItemAPI(authToken, storeId, postData)
    // }

    // deleteItemAPI(authToken, storeId, postData) {
    //     this.setState({ isActive: true })
    //     var URL = DELETE_TOPPING.replace('#id#', storeId);
    //     PostData(URL, postData, authToken)
    //         .then(result => {
    //             var responseJson = result;
    //             if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    //                 this.setState({ toppingId: '' })
    //                 let itemId = this.state.ItemId;
    //                 let toppingCategoryId = this.state.toppingCategoryId;
    //                 // this.getToppingList(authToken, storeId, itemId, toppingCategoryId);
    //             }
    //             else {
    //                 this.setState({ isActive: false })
    //                 alert(responseJson["Message"])
    //             }
    //         })

    // }

    navPaypalCheckout() {
        this.props.history.push({
            pathname: RouteMerchant.PayPalPaymentCheckout
        });
    }

    render() {
        const { hideEmptyPlazaView } = this.state;

        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={"Payment"} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            {/* <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAddProducts}
                                    className="btn btn-reg-email">
                                    Add Topping
                                </span>
                            </div> */}
                            <div className="reg_form_main">
                                <div className="card cartBorder setmargin">
                                    <div className="card-body">
                                        <h5 className="card-title">Supported payment methods</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">Payment methods that are available with one of {this.state.platForm}'s approved payment providers.</h6>
                                        <div className="card setinnercartbg" >
                                            <div className="card-body">
                                                <div className="paymentbox">
                                                    <div className="payment-image-box">
                                                        <img src={paypal} />
                                                        <span className="card-subtitle mb-2 text-muted">EXPRESS CHECKOUT</span>
                                                    </div>
                                                    <div className="payment-image-box">
                                                        <a tabIndex='' className="setValue sethyperlink"
                                                            onClick={(e) => this.navPaypalCheckout()}>{this.state.partnerStatusObj ? (this.state.partnerStatusObj.Status == "ACTIVE" ? "Manage" : "Activate Paypal") : "Activate Paypal"}</a>
                                                    </div>
                                                </div>
                                                <hr className="hrsolid-border" />
                                                <div className="paymentbox">
                                                    <div className="payment-image-box">
                                                        <p>Provider</p>
                                                        <span className="setValue">PayPal</span>
                                                    </div>
                                                    <div className="payment-image-box">
                                                        <p>Status</p>
                                                        <span className="setValue">{this.state.partnerStatusObj ? (this.state.partnerStatusObj.Status == "ACTIVE" ? "ACTIVE" : "INACTIVE") : "INACTIVE"}</span>
                                                    </div>
                                                    <div className="payment-image-box">
                                                        <p>Transaction Fee</p>
                                                        <span className="setValue">0%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 setterms">
                                    <p>
                                        <a class="" data-toggle="collapse" href="#multiCollapseExample1"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                            <i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }}></i> {Messages.paymentTermTitle}</a>
                                    </p>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample1">
                                                <div class="card card-body">
                                                    <p style={{ color: Color.BLACK }}>{COMPANYCONST.PLAZACART} does not assume any liability for transactions authorized. When you complete the secure PayPal online payment form, funds will be deducted from your PayPal account in US currency.<br /> <br /> Selecting the monthly plan at $79/month will commit you to a monthly subscription. Each month your PayPal account will be billed $79 (non-refundable). You may cancel at any time by clicking on the &lsquo;delete&rsquo; button in your communications portal {COMPANYCONST.PLAZACART} will unpublished your brewery from platform.<br /> <br /> Selecting the annual plan will commit you to an annual subscription of $480 or a discounted price when {COMPANYCONST.PLAZACART} offers promotional campaigns. Annual subscriptions are non-refundable. Your PayPal account will be charged the full annual amount which will give you access to the {COMPANYCONST.PLAZACART} system for an entire year. In 12 months from the date of purchase your app will be auto-renewed and your PayPal account will be charged the same discounted price of $480 (or the discounted price that was applied in the initial transaction). If you do not wish to auto-renew at that time than simply go into your Communications Portal prior to the 12 month period and click on the &lsquo;delete&rsquo; button. {COMPANYCONST.PLAZACART} will unpublished your brewery from platform.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-sm-12 setterms">
                                    <p>
                                        <a class="" data-toggle="collapse" href="#multiCollapseExample2"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
                                            <i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }}></i> {Messages.cancelSubscriptionTitle}</a>
                                    </p>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample2">
                                                <div class="card card-body">
                                                    {Messages.subscriptionMsg1}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-sm-12 setterms">
                                    <p>
                                        <a class="" data-toggle="collapse" href="#multiCollapseExample3"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample3">
                                            <i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }}></i> {Messages.havePayPalTitle}</a>
                                    </p>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample3">
                                                <div class="card card-body">
                                                    <p style={{ color: Color.BLACK }}>PayPal can process your credit card without the need of a PayPal account. Simply click on the 'Pay with PayPal' button and PayPal Secure Payments will process your payment</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">{Messages.DeleteToppingsTitile}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {Messages.DeleteToppings}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default PayPalPayment;