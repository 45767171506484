import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import { RetriveDataFromStorage, validateFloat, RetriveObjectFromStorage, validateEmail, validatePhoneNo, validateInt, removeAllSpace, formatPhoneNumber, validatePhoneNumber } from "../../Config/Utils";
import { GET_STORE_SUPPLIER, SUPPLIER, POST_TOPPING, PLAZAID, GOOGLE_PLACE_KEY, KEY_STRING, PLACE_ID } from '../../Config/Config.json'
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
// import SearchField from "react-search-field";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { GetDataGooglePlace } from '../../../services/GetDataGooglePlace';
import Constvariable, { GOOGLEPLACEAPIMAP } from '../../Config/constvariable';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import LoadingOverlay from 'react-loading-overlay';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { StoreSupplierForm } from '../../Config/HoverMessage'
import { StoreSupplierConstForm } from "../../Config/ConstForm";
import { PutMandatoryfieldSymbol } from "../../UtillCustomComponent/UtillCustomComponent";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class AddStoreSupplier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            errors: {},
            storeId: '',
            plazaId: "",
            ItemId: '',
            toppingCategoryId: '',
            toppingId: '',
            supplierId: '',
            errors: {},
            selectedAddress: null

        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = storeObj.plazaid
            this.setState({ storeId: storeId, plazaId: plazaId })
            var storeSupplier = RetriveObjectFromStorage(Constants.STORESUPPLIER_OBJ);
            if (storeSupplier) {
                this.setState({ supplierId: storeSupplier._id });
                this.autoPopulated(storeSupplier);
            }
        } else {
            this.props.history.goBack()
        }
    }

    autoPopulated(storeSupplier) {
        let userFields = this.state.userFields;
        userFields['supplier'] = storeSupplier.Supplier;
        // userFields['address'] = storeSupplier.Address;
        userFields['apartment'] = storeSupplier.Apartment;
        userFields['city'] = storeSupplier.City;
        userFields['state'] = storeSupplier.State;
        userFields['country'] = storeSupplier.Country;
        userFields['pinCode'] = storeSupplier.PinCode;
        userFields['contactName'] = storeSupplier.ContactName;
        userFields['emailId'] = storeSupplier.Email;
        userFields['phoneNumber'] = formatPhoneNumber(storeSupplier.PhoneNumber);
        var selectedAddress = {
            label: storeSupplier.Address,
            value: {
                description: storeSupplier.Address
            }
        }
        this.setState({
            userFields,
            selectedAddress: selectedAddress
        })
    }


    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "phoneNumber") {
            userFields[key] = formatPhoneNumber(value)
        }
        else {
            userFields[key] = value
        }
        this.setState({ userFields })
    }

    handelAddress(addressObj) {
        this.setState({
            selectedAddress: addressObj
        })
        console.log(addressObj)
        let userFields = this.state.userFields;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace("", addressObj.value.place_id, userFields)
        }
        else {
            userFields['apartment'] = "";
            userFields['city'] = "";
            userFields['pinCode'] = "";
            userFields['state'] = "";
            userFields['country'] = "";
        }
        this.setState({ userFields })
    }

    getaddressGooglePlace(authToken, value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        console.log(placeId)
        refObj['apartment'] = "";
        refObj['city'] = "";
        refObj['pinCode'] = "";
        refObj['state'] = "";
        refObj['country'] = "";
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                console.log("====", result)
                if (responseJson) {
                    var responseData = responseJson;
                    if (responseData && responseData.address_components) {
                        for (var obj of responseData.address_components) {
                            const componentType = obj.types[0];
                            switch (componentType) {
                                case GOOGLEPLACEAPIMAP.STREET_NUMBER: {
                                    refObj['apartment'] = obj.long_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.ROUTE: {
                                    refObj['apartment'] += obj.short_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.POSTAL_CODE: {
                                    refObj['pinCode'] = removeAllSpace(obj.long_name);
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.POSTAL_CODE_SUFFIX: {
                                    refObj['pinCode'] = removeAllSpace(refObj['pinCode'] + "-" + obj.long_name);
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.LOCALITY: {
                                    refObj['city'] = obj.long_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.ADMINISTRATIVE_AREA_LEVEL_1: {
                                    refObj['state'] = obj.short_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.COUNTRY:
                                    refObj['country'] = obj.long_name;
                                    break;
                            }
                        }
                    }
                }
                this.setState({ refObj, isActive: false })
            }).catch(err => {
                this.setState({ refObj, isActive: false })
            });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["supplier"]) {
            formIsValid = false;
            errors["supplier"] = Messages.v_supplier;
        }

        if (!this.state.selectedAddress) {
            formIsValid = false;
            errors["address"] = Messages.v_address;
        }

        if (!fields["apartment"]) {
            formIsValid = false;
            errors["apartment"] = Messages.v_apartment;
        }

        if (!fields["city"]) {
            formIsValid = false;
            errors["city"] = Messages.v_city;
        }

        if (!fields["state"]) {
            formIsValid = false;
            errors["state"] = Messages.v_state;
        }

        if (!fields["country"]) {
            formIsValid = false;
            errors["country"] = Messages.v_country;
        }

        if (!fields["pinCode"]) {
            formIsValid = false;
            errors["pinCode"] = Messages.v_pinCode;
        }

        if (!fields["contactName"]) {
            formIsValid = false;
            errors["contactName"] = Messages.v_contactName;
        }

        if (!fields["emailId"]) {
            formIsValid = false;
            errors["emailId"] = Messages.v_emailId;
        }
        else if (fields["emailId"]) {
            if (!validateEmail(fields["emailId"])) {
                formIsValid = false;
                errors["emailId"] = Messages.v_emailIdFormate;
            }

        }

        if (!fields["phoneNumber"]) {
            formIsValid = false;
            errors["phoneNumber"] = Messages.v_phoneNumber;
        }
        else if (fields["phoneNumber"]) {
            if (!validatePhoneNumber(fields["phoneNumber"])) {
                formIsValid = false;
                errors["phoneNumber"] = Messages.v_phoneNumberFormate;
            }
        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['Supplier'] = this.state.userFields.supplier;
            postData['Address'] = this.state.selectedAddress ? this.state.selectedAddress.label : "";
            postData['Apartment'] = this.state.userFields.apartment;
            postData['City'] = this.state.userFields.city;
            postData['State'] = this.state.userFields.state;
            postData['Country'] = this.state.userFields.country;
            postData['PinCode'] = this.state.userFields.pinCode;
            postData['ContactName'] = this.state.userFields.contactName;
            postData['Email'] = this.state.userFields.emailId;
            postData['PhoneNumber'] = this.state.userFields.phoneNumber;

            console.log("==> postdata==", postData)
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            var storeId = this.state.storeId
            var plazaId = this.state.plazaId
            if (storeId) {
                if (this.state.supplierId && this.state.supplierId !== '') {
                    postData['_id'] = this.state.supplierId;
                    this.putStoreSupplier(authToken, postData, storeId, plazaId)
                }
                else {
                    this.postStoreSupplier(authToken, postData, storeId, plazaId)
                }
            }
        }
    }

    postStoreSupplier(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_STORE_SUPPLIER + storeId + SUPPLIER + "?" + PLAZAID + plazaId;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    putStoreSupplier(authToken, postData, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_STORE_SUPPLIER + storeId + SUPPLIER + "?" + PLAZAID + plazaId
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    render() {

        return (

            <section className="section">
                <Header setHeaderTitle={this.state.supplierId ? StoreSupplierConstForm.UpdateStoreSupplier : StoreSupplierConstForm.AddStoreSupplier}></Header>
                <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.SupplierName}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.SupplierName} /> <PutMandatoryfieldSymbol />
                        </label>
                        <input
                            type="text"
                            name="supplier"
                            className='add-item-box'
                            placeholder={Constvariable.Supplier}
                            value={this.state.userFields.supplier}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.supplier}
                        </div>
                    </div>

                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">
                            {StoreSupplierConstForm.Address} &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.Address} /> <PutMandatoryfieldSymbol />
                        </label>
                        <GooglePlacesAutocomplete
                            apiKey={GOOGLE_PLACE_KEY}
                            selectProps={{
                                value: this.state.selectedAddress,
                                onChange: (setAddress) => this.handelAddress(setAddress),
                                menuPortalTarget: document.body,
                                isClearable: true,
                                placeholder: Constvariable.Address,
                                styles: colourStyles
                            }}
                        // autocompletionRequest={{
                        //     componentRestrictions: {
                        //         country: CountryCode
                        //     }
                        // }}
                        />
                        <div className="errorMsg">
                            {this.state.errors.address}
                        </div>
                    </div>

                    {/* <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.Address}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.Address} />
                        </label>
                        <input
                            type="text"
                            name="address"
                            className='add-item-box'
                            placeholder={Constvariable.Address}
                            value={this.state.userFields.address}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.address}
                        </div>
                    </div> */}
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.ApartmentName}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.ApartmentName} /> <PutMandatoryfieldSymbol />
                        </label>
                        <input
                            type="text"
                            name="apartment"
                            className='add-item-box'
                            placeholder={Constvariable.Apartment}
                            value={this.state.userFields.apartment}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.apartment}
                        </div>
                    </div>
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.CityName}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.CityName} /> <PutMandatoryfieldSymbol />
                        </label>
                        <input
                            type="text"
                            name="city"
                            className='add-item-box'
                            placeholder={Constvariable.City}
                            value={this.state.userFields.city}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.city}
                        </div>
                    </div>
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.StateName}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.StateName} /> <PutMandatoryfieldSymbol />
                        </label>
                        <input
                            type="text"
                            name="state"
                            className='add-item-box'
                            placeholder={Constvariable.State}
                            value={this.state.userFields.state}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.state}
                        </div>
                    </div>

                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.CountryName}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.CountryName} /> <PutMandatoryfieldSymbol />
                        </label>
                        <input
                            type="text"
                            name="country"
                            className='add-item-box'
                            placeholder={Constvariable.Country}
                            value={this.state.userFields.country}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.country}
                        </div>
                    </div>
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.PostalCode}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.PostalCode} /> <PutMandatoryfieldSymbol />
                        </label>
                        <input
                            type="text"
                            name="pinCode"
                            className='add-item-box'
                            placeholder={Constvariable.PinCode}
                            value={this.state.userFields.pinCode}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.pinCode}
                        </div>
                    </div>
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.ContactName}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.ContactName} /> <PutMandatoryfieldSymbol />
                        </label>
                        <input
                            type="text"
                            name="contactName"
                            className='add-item-box'
                            placeholder={Constvariable.ContactName}
                            value={this.state.userFields.contactName}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.contactName}
                        </div>
                    </div>
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.Email}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.Email} /> <PutMandatoryfieldSymbol />
                        </label>
                        <input
                            type="text"
                            name="emailId"
                            className='add-item-box'
                            placeholder={Constvariable.Email}
                            value={this.state.userFields.emailId}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.emailId}
                        </div>
                    </div>
                    <div className="reg_form_main col-sm-8 offset-sm-2">
                        <label className="label-color">{StoreSupplierConstForm.PhoneNumbe}
                            &nbsp;
                            <OnHoverIconInfo
                                message={StoreSupplierForm.PhoneNumbe} /> <PutMandatoryfieldSymbol />
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            maxLength="14"
                            className='add-item-box'
                            placeholder={Constvariable.PhoneNumber}
                            value={this.state.userFields.phoneNumber}
                            onChange={this.handleChange}
                        />
                        <div className="errorMsg">
                            {this.state.errors.phoneNumber}
                        </div>
                    </div>
                </div>

                <div className="reg_form_main offset-sm-3 col-sm-6">
                    <center>
                        <div className="errorMsg">
                            {this.state.errors.ErrorC}
                        </div>
                    </center>
                </div>

                <div className="bottam_sing">
                    <label
                        style={{ background: Color.THEME_COLOR }}
                        for="step5"
                        id="continue-step5"
                        onClick={this.handleAdd}
                        className="btn btn-done"
                    > {this.state.supplierId ? StoreSupplierConstForm.btn_UpdateStoreSupplier : StoreSupplierConstForm.btn_AddStoreSupplier}</label>
                </div>
            </section>
        );
    }
}
export default AddStoreSupplier;