import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants, { DEFAULTID } from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API, GET_STORE_ITEM, PLAZAID, GET_COUPON_API, STOREID } from '../../Config/Config.json'
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import { DeleteData } from '../../../services/DeleteData';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { ManageCouponConst } from '../../Config/ConstForm';
import Moment from 'moment'
import { DatetimeFormate } from '../../Config/constvariable'
import NoPriview from '../../../images/nopreviewp.png';

class ManageCoupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            couponList: [],
            isActive: false,
            storeId: "",
            ItemId: "",
            plazaId: "",
            couponId: "",
            errors: {}
        }
        this.handleAddCoupon = this.handleAddCoupon.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        StoreObjectInStorage(Constants.STROE_ITEM_OBJ, '')
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            this.getCouponAPI(authToken, storeId, plazaId)
        } else {
            this.props.history.goBack()
        }
        console.log(authToken);
    }

    getCouponAPI(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_COUPON_API + "?" + STOREID + storeId + "&" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                console.log("==", result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ couponList: responseData });
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleAddCoupon() {
        this.props.history.push({
            pathname: RouteMerchant.AddCoupon
        })
    }

    handleEditCoupon(couponObj) {
        this.props.history.push({
            pathname: RouteMerchant.AddCoupon,
            search: "?id=" + couponObj._id
        })
    }

    navUseCoupon(couponObj) {
        this.props.history.push({
            pathname: RouteMerchant.Redemption,
            search: "?id=" + couponObj._id
        })
    }

    handleDelete(obj, flag) {
        if (flag) {
            this.setState({ couponId: obj._id })
        }
        else {
            this.setState({ couponId: "" })
        }
    }

    deleteItem() {
        let couponId = this.state.couponId;
        let storeId = this.state.storeId;
        var plazaId = this.state.plazaId
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        if (couponId && storeId && plazaId) {
            this.deleteCouponAPI(authToken, couponId, storeId, plazaId)
        }
    }

    deleteCouponAPI(authToken, couponId, storeId, plazaId) {
        this.setState({ isActive: true })
        var URL = GET_COUPON_API + "/" + couponId + "?" + STOREID + storeId + "&" + PLAZAID + plazaId
        var postData = {}
        console.log("==", URL)
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ ItemId: "" })
                    this.getCouponAPI(authToken, storeId, plazaId)
                }
                else {
                    this.setState({ isActive: false })
                }
            })
    }

    render() {
        const { hideEmptyPlazaView } = this.state;
        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }
        const columns = [
            {
                maxWidth: "80px",
                cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px" }} src={row.imageurl ? row.imageurl : NoPriview} className="iconDetails" onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} />
            },
            {
                name: ManageCouponConst.Title,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Title}</div>
            },
            {
                name: ManageCouponConst.Description,
                cell: row => <div style={{ marginLeft: "0px" }}>{row.Description}</div>
            },
            {
                name: ManageCouponConst.StartDate,
                cell: row => <div style={{ marginLeft: "0px" }}>{Moment.utc(row.StartDate).format(DatetimeFormate.DDMMYYYY)}</div>
            },
            {
                name: ManageCouponConst.EndDate,
                cell: row => <div style={{ marginLeft: "0px" }}>{Moment.utc(row.EndDate).format(DatetimeFormate.DDMMYYYY)}</div>
            },
            {
                name: ManageCouponConst.DicountPrice,
                cell: row => <div style={{ marginLeft: "0px" }}>{currencySymbol} {Number(row.DicountPrice).toFixed(2)}</div>
            },
            {
                name: ManageCouponConst.MinimumRequiredAmount,
                cell: row => <div style={{ marginLeft: "0px" }}>{currencySymbol} {Number(row.MinimumrequiredAmount).toFixed(2)}</div>
            },
            {
                cell: row => <a className="setasatag" style={{ color: Color.THEME_COLOR }} onClick={() => this.navUseCoupon(row)}>{ManageCouponConst.CouponsRedemptions}</a>
            },
            {
                maxWidth: "80px",
                cell: row => <i class="fas fa-edit" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleEditCoupon(row)}></i>
            },
            {
                maxWidth: "80px",
                cell: row => <a data-toggle="modal" data-target="#exampleModalLong">
                    <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleDelete(row, true)}></i>
                </a>
            },
        ];

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={ManageCouponConst.ManageCoupon} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAddCoupon}
                                    className="btn btn-reg-email">
                                    {ManageCouponConst.btn_AddCoupon}
                                </span>
                            </div>
                            <div className="reg_form_main">

                                <DataTableExtensions
                                    data={this.state.couponList}
                                    columns={columns}
                                    export={false}
                                    print={false}
                                    filter={this.state.couponList.length > 0 ? true : false}
                                    filterHidden={true}
                                    filterPlaceholder={Messages.SearchforCoupon}
                                    filterStyle={{ width: "100px" }} >
                                    <DataTable
                                        noHeader={true}
                                        pagination
                                        paginationRowsPerPageOptions={[10, 20, 30, 40]}

                                    />
                                </DataTableExtensions>

                            </div>
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteCouponTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteCoupon}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>{ManageCouponConst.btn_NO}</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>{ManageCouponConst.btn_YES}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}
                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default ManageCoupon;