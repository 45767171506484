import React, { Component } from 'react';
import { Link } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
// import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants, { DEFAULTID, imageStandard } from '../../Config/Constants'
import {
    RetriveDataFromStorage, StoreDataInStorage,
    StoreObjectInStorage, RemoveFromStorage,
    RetriveObjectFromStorage, removeAllSpace,
    formatPhoneNumber, validatePhoneNumber, SetStoreLevelStatus
} from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API_List } from '../../Config/Config.json'
// import SideMenu from '../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import Constvariable, { PlatFrom, UserAccess, IMAGEFILEEXTENSIONS } from '../../Config/constvariable';
import Color from '../../Config/Color';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { AddPlazaConstForm, StoreListConst } from "../../Config/ConstForm"
import Changeimg from '../../../images/Status/changerequest.png'
import makePayment from '../../../images/Status/makepayment.png'
import processing from '../../../images/Status/processing.png'
import publishapp from '../../../images/Status/publishapp.png'
import requestsent from '../../../images/Status/requestsent.png'
import { StoreList } from '../../Config/HoverMessage'
import { customConfirmAlert } from "../../Alerts/Alertinline"
import './StoreMain.css'

// /////////////////// Add Store import /////////////////////////////////
import { PostData } from "../../../services/PostData";
import { compose, withProps } from "recompose";
import {
    GET_PRODUCTTYPE, GET_MERCHANT_MASTER_CATEGORY, Mapkey,
    MY_STORE_API, SHOPIFY_API, GET_PLAZA_API, COUNTRY_CODE, PLAZAID,
    GOOGLE_PLACE_KEY, KEY_STRING, PLACE_ID, PLAZA_REQUEST_API
} from '../../Config/Config.json'
import Select from 'react-select';
import Geocode from "react-geocode";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import Checkbox from 'react-simple-checkbox';
import { PUTData } from '../../../services/PUTData';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { DatetimeFormate, GOOGLEPLACEAPIMAP } from '../../Config/constvariable';
import OnHoverIconInfo, { BreadcrumbNav, isWebSiteValidUrl } from "../../UtillCustomComponent/UtillCustomComponent"
import { StoreFrom } from '../../Config/HoverMessage'
import { StoreConstFrom } from "../../Config/ConstForm"
import defultUser from '../../../images/defualtprofielpick.png';
import defualtBanner from '../../../images/defualtbannerpick.png';
import NoPriview from '../../../images/nopreviewp.png';
import { PutMandatoryfieldSymbol } from '../../UtillCustomComponent/UtillCustomComponent';
///////////////////// end Add store import ///////////////////////////////

// const avatar = 'https://rhulopr.s3.amazonaws.com/RHULO/images/default/profile/default_userprofile.png';
// const cover = 'https://rhulopr.s3.amazonaws.com/RHULO/images/cover/cover-photo.jpg';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
    option: styles => ({ ...styles, color: '#333' }),
    menuPortal: styles => ({ ...styles, zIndex: "1081" })
};

const PlazacartMapComponent = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyB13R4Lf5J4-L15jErCRBLQtQzAfzBLb00&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={15} defaultCenter={{ lat: props.lat, lng: props.lng }}>
        {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
));

let BreadcrumbArray = [
    "Postal code",
    "Brewery Detail",
    "Contact Detail",
    "Address",
    "Brewery Logo and Banner",
    "Map",
    "Product type"
]

class StoreMainWithAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            storeList: [],
            isActive: false,

            // ///////////// add Store State ///////////////////
            userFields: {},
            errors: {},
            isShopify: false,
            isWix: false,
            isPlazacart: true,
            isMapshow: false,
            selectedAddress: null,
            selectedStoreAddress: null,

            selectedOption: null,
            storecatlist: [],
            chkboxyes: true,
            chkboxno: false,

            PickupEnable: true,
            DeliveryEnable: false,
            chkboxphyyes: true,
            chkboxphyno: false,
            profilePic: null,
            coverPic: '',
            isLogoUpdate: false,
            isbannerUpdate: false,
            data: [],
            storeId: "",
            plazaId: "",
            plazaList: [],
            selectedPlaza: null,
            BreadNavIndex: 0,
            BreadcrumbArray: BreadcrumbArray,
            // /////////////////////////////////////////////////

            IsParent: true,
            isSubmitStoreenable: true,
            StoreMainObject: {}
        }
        this.regularRegister = this.regularRegister.bind(this)
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getMyStoreAPI(authToken);
        // RemoveFromStorage(Constants.TEMPSTORE__OBJ)
        // this.handleModalsShow()

        var isParent = RetriveDataFromStorage(Constants.ISPARENT)
        this.setState({ IsParent: isParent == UserAccess.ISPARENTFALSE ? false : true })

        ///////////////////////////////// add store ///////////////////////////////

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getProducttypeList(authToken);
        // this.getStorecategoryList(authToken);
        this.setState({ lat: 59.95, lng: 30.33 });


        var userDetail = RetriveObjectFromStorage(Constants.USER_DETAIL)
        if (userDetail) {
            var userFields = this.state.userFields
            userFields["PostalCode"] = userDetail.PostalCode
            this.setState({ userFields })
        }

        this.readCountryInfo()
        ///////////////////////////////////////////////////////////////////////
    }

    readCountryInfo() {
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            var userFields = this.state.userFields
            userFields["country"] = countryInfo.CountryName;
            userFields["countrycode"] = countryInfo.CountryCode;
            this.setState({ countryInfo: countryInfo, userFields })
        }
    }

    getMyStoreAPI(authToken) {
        this.setState({ isActive: true, errors: {} })
        GetData(MY_STORE_API_List, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ storeList: responseData });
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getProducttypeList(authToken) {
        this.setState({ isActive: true })
        GetData(GET_PRODUCTTYPE, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        responseData = responseData.map(e => {
                            return { ...e, "IsConfirm": false }
                        })
                        this.setState({ data: responseData });

                        var d = this.state.data;
                        var n = [];
                        for (var i = 0; i < d.length; i++) {
                            var aa = {};
                            aa = d[i];

                            let key = "IsSelected";
                            let v = false;
                            aa[key] = v;
                            n.push(aa);
                        }
                        this.setState({ data: n });
                    }
                }
                else {

                }
                this.getStorecategoryList(authToken);
            });
    }

    getStorecategoryList(authToken) {
        GetData(GET_MERCHANT_MASTER_CATEGORY, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ storecatlist: responseData });
                    }
                    let errors = {};
                    this.setState({ errors: errors });
                }
                else {
                    let errors = {};
                    errors["StoreCategory"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                let search = window.location.search;
                let params = new URLSearchParams(search);
                if (params.get('id') !== null && (params.get('id').length > 0)) {
                    var storeid = params.get('id')
                    // var plazaid = params.get("plazaId")
                    this.setState({ storeId: storeid, plazaId: DEFAULTID })
                    this.getStoredetails(authToken, storeid, DEFAULTID)
                }
                else {
                    this.setState({ isActive: false })
                }
            }).catch(error => {
                let errors = {};
                errors["StoreCategory"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getStoredetails(authToken, storeid, plazaid) {
        var URL = MY_STORE_API + "/" + storeid + "?"
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData && responseData.storedetail) {
                        this.autopopulated(responseData.storedetail, responseData)
                    }
                    this.handleModalsShow();
                    let errors = {};
                    this.setState({ errors: errors });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    autopopulated(storeObj, otherObj) {
        let userFields = this.state.userFields

        var plazaObj = {
            "_id": DEFAULTID,
            "CompanyName": otherObj.plazaname
        }
        this.setState({ selectedPlaza: plazaObj, StoreMainObject: storeObj });

        let BreadcrumbArray = this.state.BreadcrumbArray;
        if (storeObj.isshopifyintegration) {
            BreadcrumbArray[1] = StoreConstFrom.Shopify
            this.setState({
                isShopify: true,
                isWix: false,
                isPlazacart: false,
                BreadcrumbArray: BreadcrumbArray
            })
            userFields['StoreURL'] = storeObj.shopifystorename
            userFields['AccToken'] = storeObj.shopifyaccesstoken
            userFields['APIVersion'] = storeObj.shopifyapiversion
            userFields['shopifystoreid'] = storeObj.shopifystoreid
        }
        else {
            /* BreadcrumbArray[1] = StoreConstFrom.Plazacart; */
            this.setState({
                isShopify: false,
                isWix: false,
                isPlazacart: true,
                BreadcrumbArray: BreadcrumbArray
            })
        }

        userFields['StoreName'] = storeObj.BusinessName

        var selectedOption = this.state.storecatlist.filter(e => e.MainCategoryName == storeObj.Category)[0];
        if (selectedOption) {
            this.setState({ selectedOption: { value: selectedOption.MainCategoryName, label: selectedOption.MainCategoryName } })
            userFields['StoreCategory'] = selectedOption.MainCategoryName;
        }
        userFields['IsPublicOpen'] = storeObj.IsPublicOpen;
        storeObj.IsPublicOpen ? this.setState({ chkboxyes: true, chkboxno: false }) : this.setState({ chkboxno: true, chkboxyes: false })

        userFields['PhoneNumber'] = formatPhoneNumber(storeObj.PhoneNumber)
        userFields['Website'] = storeObj.Website
        userFields['FacebookUser'] = storeObj.FacebookUser
        // userFields['Storedescription'] = storeObj.Storedescription

        userFields['Storedescription'] = ""

        userFields['City'] = storeObj.City
        userFields["state"] = storeObj.state;
        userFields["country"] = storeObj.country;
        userFields["countrycode"] = storeObj.countrycode;
        userFields["postalcode"] = storeObj.postalcode;
        userFields["PostalCode"] = storeObj.postalcode;
        // userFields['Address'] = storeObj.StreetAddress
        var selectedStoreAddress = {
            label: storeObj.StreetAddress,
            value: {
                description: storeObj.StreetAddress
            }
        }
        this.setState({ selectedStoreAddress: selectedStoreAddress })
        this.getgeocoder(storeObj.City, storeObj.StreetAddress);
        userFields['PhysicalOrderReceipts'] = storeObj.PhysicalOrderReceipts;
        storeObj.PhysicalOrderReceipts ? this.setState({ chkboxphyyes: true, chkboxphyno: false }) : this.setState({ chkboxphyno: true, chkboxphyyes: false })

        this.setState({
            profilePic: storeObj.LogoPath,
            PickupEnable: storeObj.IsPickupEnable,
            DeliveryEnable: storeObj.IsDeliveryEnable
        })
        if (storeObj.Homebanner) {
            var homebanner = storeObj.Homebanner[0]
            if (homebanner) {
                this.setState({ coverPic: homebanner.HomeBannerImagePath })
            }
        }

        if (storeObj.productDeliverytypes) {
            var productdeliverytypes = this.state.data.map(e => {
                var Producttypeid = storeObj.productDeliverytypes.filter(e1 => e.Prouducttypeid === e1.Producttypeid)[0]
                if (Producttypeid) {
                    return { ...e, "IsConfirm": true }
                }
                else {
                    return { ...e }
                }
            })
            this.setState({ data: productdeliverytypes })
        }
        this.setState({ storeStatus: storeObj.Status })
        this.setState({ userFields })
    }


    ///////////////////////////////// End Detail method ////////////////////////////

    regularRegister(e) {
        this.handleModalsShow()
        this.resetAllFields()
        this.readCountryInfo()
        // this.props.history.push({
        // 	pathname: RouteMerchant.Store
        // })
    }

    handleEditStore(storeObj) {
        document.getElementById("step0").checked = false;
        document.getElementById("step1").checked = true;
        document.getElementById("step2").checked = false;
        document.getElementById("step3").checked = false;
        document.getElementById("step4").checked = false;
        document.getElementById("step5").checked = false;
        document.getElementById("step6").checked = false;
        document.getElementById("step7").checked = false;
        if (storeObj.ObjectId) {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            let BreadcrumbArray = this.state.BreadcrumbArray;
            /* BreadcrumbArray[1] = StoreConstFrom.Plazacart; */
            this.setState({
                storeId: storeObj.ObjectId,
                // plazaId: storeObj.plazaid,
                isLogoUpdate: false,
                isbannerUpdate: false,
                BreadNavIndex: 0,
                errors: {},
                BreadcrumbArray: BreadcrumbArray
            })
            this.getStoredetails(authToken, storeObj.ObjectId, DEFAULTID)
        }
        this.readCountryInfo()
    }

    onClickViewDetails(data) {
        this.props.history.push({
            pathname: RouteMerchant.Storedetails,
            search: "?id=" + data.ObjectId + "&plazaId=" + DEFAULTID
        });
        // StoreDataInStorage(Constants.PLAZAID, data.plazaid);
        // StoreDataInStorage(Constants.STOREID, data.ObjectId);
    }

    storenavigate(data) {
        // this.props.history.push({
        // 	pathname: RouteMerchant.Home,
        // 	data: data.ObjectId
        // });
        if (data.isshopifyintegration) {
            StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Shopify);
            this.props.history.push({
                pathname: data.Status == StoreListConst.PUBLISHED ? RouteMerchant.Home : RouteMerchant.MySubscription,
                data: data.ObjectId
            });
        }
        else if (data.iswixintegration) {
            StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Wix);
        }
        else {
            StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Plazacart)
            this.props.history.push({
                pathname: data.Status == StoreListConst.PUBLISHED ? RouteMerchant.Home : RouteMerchant.MySubscription,
                data: data.ObjectId
            });
        }
        RemoveFromStorage(Constants.ISTEMP)
        StoreObjectInStorage(Constants.STORE_OBJ, data);
        // StoreDataInStorage(Constants.PLAZAID, data.plazaid);
        StoreDataInStorage(Constants.STOREID, data.ObjectId);
    }

    setPaymentFlow(data) {
        if (data.isshopifyintegration) {
            StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Shopify);
        }
        else if (data.iswixintegration) {
            StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Wix);
        }
        else {
            StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Plazacart)
        }
        this.props.history.push({
            pathname: RouteMerchant.MySubscription
        });
        StoreDataInStorage(Constants.ISTEMP, Constants.ISTEMP);
        StoreObjectInStorage(Constants.STORE_OBJ, data);
        // StoreDataInStorage(Constants.PLAZAID, data.plazaid);
        StoreDataInStorage(Constants.STOREID, data.ObjectId);
    }

    // ////////////////////////////////////////////////////////////////
    // /////////////////////////// Add Store Modal    ///////////////////////////////
    // ////////////////////////////////////////////////////////////////////


    // //////////////// part 0 ////////////////////////////////////////////

    validateFormpart0() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;
        if (this.state.isShopify || this.state.isWix) {
            if (!fields["StoreURL"]) {
                formIsValid = false;
                errors["StoreURL"] = Messages.StoreURL;
            }
            if (!fields["AccToken"]) {
                formIsValid = false;
                errors["AccToken"] = Messages.AccToken;
            }
            if (this.state.isShopifyStoreChange) {
                let checkToken = String(fields.AccToken).includes("*****")
                if (checkToken) {

                    formIsValid = false;
                    errors["AccToken"] = Messages.NewAccToken;

                }
            }
            // if (!fields["APIVersion"]) {
            //   formIsValid = false;
            //   errors["APIVersion"] = Messages.APIVersion;
            // }
        }
        if (!fields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitforpart0 = (e) => {
        e.preventDefault();
        if (this.validateFormpart0()) {
            let userFields = this.state.userFields
            var postData = {}
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            if (this.state.isShopify) {
                var storeName = String(userFields.StoreURL).replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(" ", "-").toLocaleLowerCase().trim()
                storeName = String(storeName).split(" ").join("-")
                postData["storename"] = storeName
                postData["accesstoken"] = userFields.AccToken
                postData["apiversion"] = userFields.APIVersion

                let checkToken = String(userFields.AccToken).includes("******************************")
                if (!checkToken) {
                    this.addstoreOnShopify(postData, authToken)
                }
                else {
                    document.getElementById("step1").checked = true;
                    this.setState({ BreadNavIndex: 1 })
                }
            }
            else if (this.state.isWix) {

            }
            else {
                document.getElementById("step1").checked = true;
                this.setState({ BreadNavIndex: 1 })
            }
            var countryCode = this.state.countryInfo ? this.state.countryInfo.CountryCode : ""
            if (countryCode) {
                this.setState({ selectedPlaza: null })
                this.getPlazaByPostalCodeAPI(this.state.userFields.PostalCode, countryCode)
            }
            else {
                this.setState({ plazaList: [], selectedPlaza: null })
            }
        }
    }

    addstoreOnShopify(postData, authToken) {
        this.setState({ isActive: true })
        PostData(SHOPIFY_API, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.autopopulatedShopify(responseData)
                        document.getElementById("step1").checked = true;
                    }
                    this.setState({ errors: {} });
                }
                else {
                    let errors = {};
                    errors["StoreURL"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["StoreURL"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    autopopulatedShopify(storeObj) {
        let userFields = this.state.userFields
        var storeName = String(storeObj.name).replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').trim()

        userFields['StoreName'] = storeName
        userFields["shopifystoreid"] = storeObj.id


        userFields['PhoneNumber'] = storeObj.phone
        userFields['Website'] = storeObj.domain
        // userFields['FacebookUser'] = storeObj.FacebookUser

        userFields['Storedescription'] = ""

        userFields['City'] = storeObj.city
        var address = storeObj.address2 + "," + storeObj.city + "," + storeObj.province + "," + storeObj.zip
        userFields['Address'] = address
        this.getgeocoder(storeObj.city, address);


        this.setState({ storeStatus: storeObj.Status })
        this.setState({ userFields, isShopifyStoreChange: false })
    }

    getPlazaByPostalCodeAPI(postalCode, countryCode) {
        var API = GET_PLAZA_API + postalCode + COUNTRY_CODE + countryCode;
        GetData(API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ plazaList: responseData, selectedPlaza: null });
                    }
                    else {
                        this.setState({ plazaList: [], selectedPlaza: null });
                    }
                    let errors = {};
                    this.setState({ errors: errors });
                }
                else {
                    let errors = {};
                    errors["TenantEmail"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ plazaList: [], selectedPlaza: null, errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["TenantEmail"] = Messages.ERR_SomethingWentWrong;
                this.setState({ plazaList: [], selectedPlaza: null, errors: errors });
            });
    }

    ///////////////////////////////////// end part 0 //////////////////
    //////////////////////////////////////////////////////////

    /////////////////////////////////// start part 1 ///////////////

    handleOnPlazaSelect = selectedPlaza => {
        if (selectedPlaza) {
            this.setState({ selectedPlaza });
        }
        else {
            this.setState({ selectedPlaza: null })
        }
    };

    handleChangeChkyes = (e) => {
        this.setState({ chkboxyes: !this.state.chkboxyes, chkboxno: false });
        let userFields = this.state.userFields;
        let key = "IsPublicOpen";
        let value = true;
        userFields[key] = value
        this.setState({ userFields });
    }

    handleChangeChkno = (e) => {
        this.setState({ chkboxno: !this.state.chkboxno, chkboxyes: false });
        let userFields = this.state.userFields;
        let key = "IsPublicOpen";
        let value = false;
        userFields[key] = value
        this.setState({ userFields });
    }

    selectcategory = selectedOption => {
        this.setState({ selectedOption });
        let userFields = this.state.userFields;
        let key = "StoreCategory";
        let value = selectedOption.value;
        userFields[key] = value;
        this.setState({ userFields });
    };

    validateFormpart1() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;
        if (!fields["StoreName"]) {
            formIsValid = false;
            errors["StoreName"] = Messages.S_NAME;
        }
        // else if (typeof fields["StoreName"] !== "undefined") {
        //   if (!validateUserName(fields["StoreName"]) && this.state.isPlazacart) {
        //     formIsValid = false;
        //     errors["StoreName"] = Messages.S_VALID_NAME;
        //   }
        // }
        if (!fields["StoreCategory"]) {
            formIsValid = false;
            errors["StoreCategory"] = Messages.S_CATE;
        }


        if (!this.state.selectedPlaza) {
            // formIsValid = false;
            // errors["TenantEmail"] = Messages.v_EnterPlaza
        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitforpart1 = (e) => {
        e.preventDefault();
        if (this.validateFormpart1()) {
            let userFields = this.state.userFields;
            if (typeof userFields["IsPublicOpen"] == "undefined") {
                let key = "IsPublicOpen";
                let value = true;
                userFields[key] = value
                this.setState({ userFields });
            }
            document.getElementById("step2").checked = true;
            this.setState({ BreadNavIndex: 2 })
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////// end part 1 /////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////// start part 2 ///////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////

    validateFormpart2() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["PhoneNumber"]) {
            formIsValid = false;
            errors["PhoneNumber"] = Messages.v_S_phoneNumber
        }
        else if (typeof fields["PhoneNumber"] !== "undefined") {
            if (!validatePhoneNumber(fields["PhoneNumber"])) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterValidPhoneNo;
            }
        }

        if (!fields["Website"]) {
            formIsValid = false;
            errors["Website"] = Messages.S_WEBSITE_URL;
        } else if (typeof fields["Website"] !== "undefined") {
            if (!isWebSiteValidUrl(fields["Website"])) {
                formIsValid = false;
                errors["Website"] = Messages.S_WEBSITE_Valid_URL;
            }
        }

        {/* Changes are made based on the call discussion. Date 10 Aug 2022 */ }
        /* if (!fields["FacebookUser"]) {
            formIsValid = false;
            errors["FacebookUser"] = Messages.S_FACEBOOK;
        } */

        // if (!fields["Storedescription"]) {
        //   formIsValid = false;
        //   errors["Storedescription"] = Messages.S_DEC;
        // }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitforpart2 = (e) => {
        var postData = {};
        e.preventDefault();
        if (this.validateFormpart2()) {
            document.getElementById("step3").checked = true;
            this.setState({ BreadNavIndex: 3 })
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////// end part 2 ///////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////// start parrt 3 /////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////

    handelStoreAddress(addressObj) {
        this.setState({
            selectedStoreAddress: addressObj
        })

        let userFields = this.state.userFields;
        userFields["City"] = "";
        userFields["state"] = "";
        userFields["country"] = "";
        userFields["countrycode"] = "";
        userFields["postalcode"] = "";
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlaceCity("", addressObj.value.place_id, userFields)
        }
        // else {
        //   userFields["City"] = "";
        // }
        this.setState({ userFields })
    }

    getaddressGooglePlaceCity(authToken, value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                if (responseJson) {
                    var responseData = responseJson;
                    if (responseData && responseData.address_components) {
                        for (var obj of responseData.address_components) {
                            const componentType = obj.types[0];
                            switch (componentType) {
                                case GOOGLEPLACEAPIMAP.POSTAL_CODE: {
                                    refObj['postalcode'] = removeAllSpace(obj.long_name);
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.POSTAL_CODE_SUFFIX: {
                                    refObj['postalcode'] = removeAllSpace(refObj['postalcode'] + "-" + obj.long_name);
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.LOCALITY: {
                                    refObj['City'] = obj.long_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.ADMINISTRATIVE_AREA_LEVEL_1: {
                                    refObj['state'] = obj.short_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.COUNTRY:
                                    refObj['country'] = obj.long_name;
                                    refObj['countrycode'] = obj.short_name;
                                    break;
                            }
                        }
                    }
                }
                this.setState({ refObj, isActive: false })
            }).catch(err => {
                refObj["City"] = ""
                this.setState({ refObj, isActive: false })
            });
    }

    handleChangePickup = (e) => {
        if (this.state.DeliveryEnable) {
            this.setState({ PickupEnable: !this.state.PickupEnable })
        }
        else {
            this.setState({ PickupEnable: true })
        }
    }

    handleChangeDelivery = (e) => {
        if (this.state.PickupEnable) {
            this.setState({ DeliveryEnable: !this.state.DeliveryEnable })
        }
        else {
            this.setState({ DeliveryEnable: true })
        }
    }

    handleChangeChkphyyes = (e) => {
        this.setState({ chkboxphyyes: !this.state.chkboxphyyes, chkboxphyno: false });
        let userFields = this.state.userFields;
        let key = "PhysicalOrderReceipts";
        let value = true;
        userFields[key] = value
        this.setState({ userFields });
    }

    handleChangeChkphyno = (e) => {
        this.setState({ chkboxphyno: !this.state.chkboxphyno, chkboxphyyes: false });
        let userFields = this.state.userFields;
        let key = "PhysicalOrderReceipts";
        let value = false;
        userFields[key] = value
        this.setState({ userFields });
    }

    validateFormpart3() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;
        if (!fields["City"]) {
            formIsValid = false;
            errors["City"] = Messages.S_CITY;
        }
        if (!this.state.selectedStoreAddress) {
            formIsValid = false;
            errors["Address"] = Messages.S_ADDRESS;
        }
        if (!fields["state"]) {
            formIsValid = false;
            errors["state"] = Messages.v_state;
        }
        if (!fields["country"]) {
            formIsValid = false;
            errors["country"] = Messages.v_country;
        }
        if (!fields["postalcode"]) {
            formIsValid = false;
            errors["postalcode"] = Messages.v_EnterPostalCode;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitforpart3 = (e) => {
        e.preventDefault();
        if (this.validateFormpart3()) {
            let fields = this.state.userFields;
            var city = fields['City'];
            var address = this.state.selectedStoreAddress ? this.state.selectedStoreAddress.label : "";
            this.getgeocoder(city, address);

            let userFields = this.state.userFields;
            if (typeof userFields["PhysicalOrderReceipts"] == "undefined") {
                let key = "PhysicalOrderReceipts";
                let value = true;
                userFields[key] = value
                this.setState({ userFields });
            }
            document.getElementById("step4").checked = true;
            this.setState({ BreadNavIndex: 4 })
        }
    }

    /* Plazacart Latest Submit Code Check in Date 7 July 2023*/
    /*handleSubmitforpart3 = (e) => {
        e.preventDefault();
        if (this.validateFormpart3()) {
            let fields = this.state.userFields;
            if (fields.PostalCode == fields.postalcode) {
                var city = fields['City'];
                var address = this.state.selectedStoreAddress ? this.state.selectedStoreAddress.label : "";
                this.getgeocoder(city, address);

                let userFields = this.state.userFields;
                if (typeof userFields["PhysicalOrderReceipts"] == "undefined") {
                    let key = "PhysicalOrderReceipts";
                    let value = true;
                    userFields[key] = value
                    this.setState({ userFields });
                }
                document.getElementById("step4").checked = true;
                this.setState({ BreadNavIndex: 4 })
            }
            else {
                this.handleModalHide()
                customConfirmAlert({
                    title: AddPlazaConstForm.ALERT,
                    message: AddPlazaConstForm.modal_msg,
                    buttons: [
                        {
                            label: AddPlazaConstForm.btn_CANCEL,
                            onClick: () => {
                                this.handleModalsShow();
                                var StoreMainObject = this.state.StoreMainObject;
                                var Storeid = this.state.storeId;
                                if (StoreMainObject && Storeid) {
                                    let userFields = this.state.userFields;
                                    userFields['City'] = StoreMainObject.City
                                    userFields["state"] = StoreMainObject.state;
                                    userFields["country"] = StoreMainObject.country;
                                    userFields["countrycode"] = StoreMainObject.countrycode;
                                    userFields["postalcode"] = StoreMainObject.postalcode;
                                    userFields["PostalCode"] = StoreMainObject.postalcode;

                                    // Select Address Auto Populate
                                    var selectedStoreAddress = {
                                        label: StoreMainObject.StreetAddress,
                                        value: {
                                            description: StoreMainObject.StreetAddress
                                        }
                                    }

                                    this.setState({ selectedStoreAddress: selectedStoreAddress })
                                    this.getgeocoder(StoreMainObject.City, StoreMainObject.StreetAddress);

                                    if (typeof userFields["PhysicalOrderReceipts"] == "undefined") {
                                        let key = "PhysicalOrderReceipts";
                                        let value = true;
                                        userFields[key] = value
                                        this.setState({ userFields });
                                    }

                                    this.setState({ BreadNavIndex: 4, userFields });
                                } else {
                                    var city = fields['City'];
                                    var address = this.state.selectedStoreAddress ? this.state.selectedStoreAddress.label : "";
                                    this.getgeocoder(city, address);

                                    let userFields = this.state.userFields;
                                    userFields.postalcode = userFields.PostalCode
                                    if (typeof userFields["PhysicalOrderReceipts"] == "undefined") {
                                        let key = "PhysicalOrderReceipts";
                                        let value = true;
                                        userFields[key] = value
                                        this.setState({ userFields });
                                    }
                                    this.setState({ BreadNavIndex: 4, userFields });
                                }
                            }
                        },
                        {
                            label: AddPlazaConstForm.btn_CONFIRM,
                            onClick: () => {
                                this.handleModalsShow();
                                var city = fields['City'];
                                var address = this.state.selectedStoreAddress ? this.state.selectedStoreAddress.label : "";
                                this.getgeocoder(city, address);

                                let userFields = this.state.userFields;
                                if (typeof userFields["PhysicalOrderReceipts"] == "undefined") {
                                    let key = "PhysicalOrderReceipts";
                                    let value = true;
                                    userFields[key] = value
                                    this.setState({ userFields });
                                }
                                document.getElementById("step4").checked = true;
                                this.setState({ BreadNavIndex: 4 })
                            }
                        }
                    ]
                });
            }
        }
    }*/

    getgeocoder(city, address) {
        Geocode.setApiKey(GOOGLE_PLACE_KEY);
        Geocode.setLanguage("en");
        Geocode.enableDebug();
        Geocode.fromAddress(address + "," + city).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({ lat: parseFloat(lat), lng: parseFloat(lng), isMapshow: true });
            },
            error => {

            }
        );
    }



    //////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////// end part 3 /////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////// start part 4 //////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////

    imageError(e, e1) {
        console.log(e1, e);
        if (e1 == "logo") {
            e.target.src = defultUser
        }
        else if (e1 == "banner") {
            e.target.src = defualtBanner
        }
    }

    resetImgsrc(e, e1) {
        let imageSelect = document.getElementById(e1)
        if (imageSelect) {
            e.target.value = null
        }
    }

    standardImageSizeConvert(e, errkey, size) {
        let error = {}
        var img = new Image();
        img.onload = () => {
            /* var iw = img.width;
            var ih = img.height;

            size = size / (1024 * 1024) */

            if (errkey == "logo") {
                /* if (iw == imageStandard.storeLogoW && ih == imageStandard.storeBannerH) { */
                this.setState({ profilePic: e, isLogoUpdate: true });
                /* } */
                /* else if (imageStandard.ImageSize < size) {
                    let s = Messages.v_storeLogoResolution
                    error[errkey] = s
                }
                else {
                    let s = Messages.v_storeLogoResolution
                    error[errkey] = s
                } */
            }
            else if (errkey == "banner") {
                /* if (iw == imageStandard.storeBannerW && ih == imageStandard.storeBannerH) { */
                this.setState({ coverPic: e, isbannerUpdate: true })
                /* } */
                /* else if (imageStandard.ImageSize < size) {
                    let s = Messages.v_storeBannerResolution
                    error[errkey] = s
                }
                else {
                    let s = Messages.v_storeBannerResolution
                    error[errkey] = s
                } */
            }
            this.setState({ errors: error });
        }
        img.src = e;
    }

    onSelectProfileFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            var fileName = e.target.files[0].name, filesize = e.target.files[0].size;
            var extension = IMAGEFILEEXTENSIONS
            var fileSplit = fileName.split('.');
            var filelength = fileSplit.length
            var fileExtension = fileSplit[filelength - 1]
            var extensionFlag = extension.includes(String(fileExtension).toLowerCase())
            if (extensionFlag) {
                reader.addEventListener("load", () => {
                    this.standardImageSizeConvert(reader.result, "logo", filesize); // new code
                    // this.setState({ profilePic: reader.result, isLogoUpdate: true, errors: {} }) // old code
                });
                reader.readAsDataURL(e.target.files[0]);
            }
            else {
                let errors = {};
                errors["logo"] = Messages.v_ImageExtension_ALL; /* Messages.v_storeLogoResolution */
                this.setState({ errors: errors });
            }
        }
    };

    onSelectBannerFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            var fileName = e.target.files[0].name, filesize = e.target.files[0].size;
            var extension = IMAGEFILEEXTENSIONS
            var fileSplit = fileName.split('.');
            var filelength = fileSplit.length
            var fileExtension = fileSplit[filelength - 1]
            var extensionFlag = extension.includes(String(fileExtension).toLowerCase())
            if (extensionFlag) {
                const reader = new FileReader();
                reader.addEventListener("load", () =>
                    // this.setState({ coverPic: reader.result, isbannerUpdate: true, errors: {} }) // old code
                    this.standardImageSizeConvert(reader.result, "banner", filesize) // new code
                );
                reader.readAsDataURL(e.target.files[0]);
            }
            else {
                let errors = {};
                errors["banner"] = Messages.v_ImageExtension_ALL; /* Messages.v_storeBannerResolution */
                this.setState({ errors: errors });
            }
        }
    };

    validateFormpart4() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.profilePic) {
            formIsValid = false;
            errors["logo"] = Messages.S_LOGO;
        }
        if (!this.state.coverPic) {
            formIsValid = false;
            errors["banner"] = Messages.S_BANNER;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitforpart4 = (e) => {
        var postData = {};
        e.preventDefault();
        if (this.validateFormpart4()) {
            document.getElementById("step5").checked = true;
            this.setState({ BreadNavIndex: 5 });
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////// end part 4 /////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////// start part 6 ////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    handleCheck(itemObj) {
        var itemList = this.state.data.map(e => {
            if (itemObj.Prouducttypeid === e.Prouducttypeid) {
                return { ...e, "IsConfirm": !e.IsConfirm }
            }
            return { ...e }
        })
        this.setState({ data: itemList })
    }

    openSearch() {
        document.getElementById("step7").checked = true;
        this.setState({ BreadNavIndex: 7 })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////// end part 6 ///////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////// start part 7 //////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////

    submitUserRegistrationForm = (e) => {
        this.handleModalHide();
        var n = [];
        // n.push(this.state.monobject);
        // n.push(this.state.tueobject);
        // n.push(this.state.wedobject);
        // n.push(this.state.thuobject);
        // n.push(this.state.friobject);
        // n.push(this.state.satobject);
        // n.push(this.state.sunobject)
        let userFields = this.state.userFields;
        let key = "StoreOpeningTimes";
        let value = n;
        userFields[key] = value
        this.setState({ userFields });

        var a = {};
        let key1 = "Producttypeid";
        let value1 = "5ed0e0d4fe74e68138d24519";
        a[key1] = value1;

        var ptype = [];
        ptype.push(a);

        var p = {};
        p = this.state.userFields;
        p["DeviceId"] = Constants.DEVICE_ID;
        p["DeviceType"] = Constants.DEVICE_TYPE;

        if (this.state.isLogoUpdate) {
            p["LogoBase64"] = this.state.profilePic;
        } else {
            p["LogoBase64"] = "";
        }

        if (this.state.isbannerUpdate) {
            p["BannerBase64"] = this.state.coverPic;
        } else {
            p["BannerBase64"] = "";
        }

        p["Storedescription"] = ""

        p["productDeliverytypes"] = this.state.data.filter(e => e.IsConfirm).map(e1 => {
            return { "Producttypeid": e1.Prouducttypeid }
        })

        p["Address"] = this.state.selectedStoreAddress ? this.state.selectedStoreAddress.label : ""

        p["isshopifyintegration"] = this.state.isShopify
        p["iswixintegration"] = this.state.isWix
        var storeName = String(this.state.userFields.StoreURL).replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(" ", "-").toLocaleLowerCase().trim()
        storeName = String(storeName).split(" ").join("-")
        p["shopifystorename"] = storeName
        p["IsPickupEnable"] = this.state.PickupEnable
        p["IsDeliveryEnable"] = this.state.DeliveryEnable

        let checkToken = String(userFields.AccToken).includes("******************************")
        if (!checkToken) {
            p["shopifyaccesstoken"] = this.state.userFields.AccToken
        }


        p["shopifyapiversion"] = this.state.userFields.APIVersion
        p["shopifystoreid"] = this.state.userFields.shopifystoreid
        p["plazaid"] = DEFAULTID

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        let search = window.location.search;
        let params = new URLSearchParams(search);

        if (this.state.storeId) {
            p['Storeid'] = this.state.storeId;
            this.updateStore(p, authToken);
        }
        else {
            this.addstore(p, authToken);
        }
    }

    updateStore(postData, authToken) {
        this.setState({ isActive: true })
        PUTData(MY_STORE_API, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    // window.closeSearch();
                    // window.location.href = RouteMerchant.StoreMain;

                    this.getMyStoreAPI(authToken);
                    // this.handleModalHide();

                    // let search = window.location.search;
                    // let params = new URLSearchParams(search);
                    // if (params.get('id') !== null && (params.get('id').length > 0)) {
                    // 	var storeid = params.get('id')
                    // 	if (this.state.storeStatus === StoreListConst.APPROVED || this.state.storeStatus === StoreListConst.PUBLISHED) {
                    // 		var plazaId = this.state.plazaId
                    // 		this.getStoredetailsObject(authToken, storeid, plazaId)
                    // 		return ""
                    // 	}
                    // 	window.location.href = RouteMerchant.StoreMain;
                    // }
                    // window.location.href = RouteMerchant.StoreMain;
                    this.setState({ errors: {} });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(errror => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    // getStoredetailsObject(authToken, storeid, plazaId) {
    // 	this.setState({ isActive: true })
    // 	var URL = MY_STORE_API + "/" + storeid + "?" + PLAZAID + plazaId
    // 	GetData(URL, authToken)
    // 		.then(result => {
    // 			var responseJson = result;
    // 			if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    // 				var responseData = responseJson["Data"];
    // 				if (responseData) {
    // 					if (responseData.storedetail) {
    // 						var storeObj = {
    // 							"ObjectId": responseData.storedetail._id,
    // 							"ApplicationGuid": responseData.storedetail.ApplicationId,
    // 							"ApplicationName": responseData.storedetail.BusinessName,
    // 							"AppLogo": responseData.storedetail.LogoPath,
    // 							"AppBannerImage": "",
    // 							"AppAddress": responseData.storedetail.StreetAddress,
    // 							"Status": responseData.storedetail.Status,
    // 							"isshopifyintegration": responseData.storedetail.isshopifyintegration,
    // 							"iswixintegration": responseData.storedetail.iswixintegration,
    // 							"shopifystorename": responseData.storedetail.shopifystorename,
    // 							"shopifyaccesstoken": responseData.storedetail.shopifyaccesstoken,
    // 							"shopifyapiversion": responseData.storedetail.shopifyapiversion,
    // 							"shopifystoreid": responseData.storedetail.shopifystoreid,
    // 							"isshopifypostorderenable": responseData.storedetail.isshopifypostorderenable,
    // 							"plazaid": responseData.plazaid,
    // 							"plazaname": responseData.plazaname
    // 						}
    // 						if (responseData.storedetail.Homebanner) {
    // 							storeObj.AppBannerImage = responseData.storedetail.Homebanner[0] ? responseData.storedetail.Homebanner[0].HomeBannerImagePath : ""
    // 						}
    // 						StoreObjectInStorage(Constants.STORE_OBJ, storeObj);
    // 					}
    // 				}
    // 				window.location.href = RouteMerchant.StoreMain;
    // 			}
    // 			this.setState({ isActive: false })
    // 		})
    // }

    addstore(postData, authToken) {
        this.setState({ isActive: true })
        PostData(MY_STORE_API, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    // window.closeSearch();
                    // window.location.href = RouteMerchant.StoreMain;
                    this.getMyStoreAPI(authToken);
                    this.handleModalHide();
                    this.setState({ errors: {} });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////// end part 7 /////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    handleUserchange = (event) => {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if ((key == "Shopify") || (key == "Wix") || (key == "Plazacart")) {
            let BreadcrumbArray = this.state.BreadcrumbArray;
            if (key == "Shopify") {
                this.setState({
                    isShopify: true,
                    isWix: false,
                    isPlazacart: false
                })
                BreadcrumbArray[1] = StoreConstFrom.Shopify
            }
            else if (key == "Wix") {
                this.setState({
                    isShopify: false,
                    isWix: true,
                    isPlazacart: false
                })
                BreadcrumbArray[1] = StoreConstFrom.Wix;
            }
            else if (key == "Plazacart") {
                this.setState({
                    isShopify: false,
                    isWix: false,
                    isPlazacart: true
                })
                /* BreadcrumbArray[1] = StoreConstFrom.Plazacart; */
            }
            this.setState({ errors: {}, BreadcrumbArray: BreadcrumbArray })
        }
        else if (key == "PhoneNumber") {
            userFields[key] = formatPhoneNumber(value)
        }
        else {
            userFields[key] = value
            if (key == "StoreURL") {
                this.setState({ isShopifyStoreChange: true })
            }
        }
        this.setState({ userFields });
        this.setState({ isMapshow: false });
    }

    handelAddress = (addressObj) => {
        this.setState({
            selectedAddress: addressObj,
            selectedStoreAddress: addressObj
        })
        let userFields = this.state.userFields;
        userFields["PostalCode"] = "";
        userFields["City"] = "";
        userFields["state"] = "";
        userFields["country"] = "";
        userFields["countrycode"] = "";
        userFields["postalcode"] = "";
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace("", addressObj.value.place_id, userFields)
        }
        this.setState({ userFields })
    }

    getaddressGooglePlace(authToken, value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                if (responseJson) {
                    var responseData = responseJson;
                    if (responseData && responseData.address_components) {
                        for (var obj of responseData.address_components) {
                            const componentType = obj.types[0];
                            switch (componentType) {
                                case GOOGLEPLACEAPIMAP.POSTAL_CODE: {
                                    refObj['postalcode'] = removeAllSpace(obj.long_name);
                                    refObj["PostalCode"] = removeAllSpace(obj.short_name)
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.POSTAL_CODE_SUFFIX: {
                                    refObj['postalcode'] = removeAllSpace(refObj['postalcode'] + "-" + obj.long_name);
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.LOCALITY: {
                                    refObj['City'] = obj.long_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.ADMINISTRATIVE_AREA_LEVEL_1: {
                                    refObj['state'] = obj.short_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.COUNTRY:
                                    refObj['country'] = obj.long_name;
                                    refObj['countrycode'] = obj.short_name;
                                    break;
                            }
                        }
                    }
                }
                this.setState({ refObj, isActive: false })
            }).catch(err => {
                this.setState({ refObj, isActive: false })
            });
    }

    breadcrumbNavFun(ind) {
        this.setState({ BreadNavIndex: ind })
    }

    handleModalsShow() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal2')
        if (backgroung && modalId) {
            backgroung.style.display = "block"
            modalId.style.display = "block"
        }
    }

    handleModalHide() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal2')
        if (backgroung && modalId) {
            backgroung.style.display = "none"
            modalId.style.display = "none"
        }
    }

    resetAllFields() {
        let userFields = this.state.userFields;
        userFields["StoreURL"] = "";
        userFields["AccToken"] = "";

        var userDetail = RetriveObjectFromStorage(Constants.USER_DETAIL)
        if (userDetail) {
            userFields["PostalCode"] = userDetail.PostalCode
        }

        userFields["StoreName"] = "";
        userFields['StoreCategory'] = "";
        userFields['IsPublicOpen'] = true;
        userFields['PhoneNumber'] = "";
        userFields['Website'] = "";
        userFields['FacebookUser'] = "";

        userFields['Storedescription'] = "";
        userFields['City'] = "";
        userFields["state"] = "";
        userFields["country"] = "";
        userFields["countrycode"] = "";
        userFields["postalcode"] = "";
        userFields['PhysicalOrderReceipts'] = true

        document.getElementById("step0").checked = true;
        document.getElementById("step1").checked = false;
        document.getElementById("step2").checked = false;
        document.getElementById("step3").checked = false;
        document.getElementById("step4").checked = false;
        document.getElementById("step5").checked = false;
        document.getElementById("step6").checked = false;
        document.getElementById("step7").checked = false;

        var productdeliverytypes = this.state.data.map(e => {
            return { ...e, "IsConfirm": false }
        })

        let BreadcrumbArray = this.state.BreadcrumbArray;
        /* BreadcrumbArray[1] = StoreConstFrom.Plazacart; */

        this.setState({
            userDetail,
            data: productdeliverytypes,
            errors: {},
            isShopify: false,
            isWix: false,
            isPlazacart: true,
            isMapshow: false,
            selectedAddress: null,
            selectedStoreAddress: null,

            selectedOption: null,
            chkboxyes: true,
            chkboxno: false,

            BreadNavIndex: 0,
            PickupEnable: true,
            DeliveryEnable: false,
            chkboxphyyes: true,
            chkboxphyno: false,
            profilePic: null,
            coverPic: '',
            isLogoUpdate: false,
            isbannerUpdate: false,
            storeId: "",
            // plazaId: "",
            plazaList: [],
            selectedPlaza: null,
            BreadcrumbArray: BreadcrumbArray,
            StoreMainObject: {},
        });
    }

    renderMap() {
        if (this.state.isMapshow) {
            return (
                <PlazacartMapComponent
                    isMarkerShown
                    lat={this.state.lat}
                    lng={this.state.lng}
                    key="map" />
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }

    ////////////////////////////////////// plaza request methids //////////////////////

    handalPlazaRequestModal() {
        let userFields = this.state.userFields;
        userFields["requestplazaname"] = "";
        userFields["requestplazaaddress"] = "";
        this.setState({ errors: {}, userFields: userFields });

        /*  var backgroung = document.getElementById('modalbackgroundId'); */
        var modalId = document.getElementById('plazarequestmodel')

        if (/* backgroung && */ modalId) {
            /* backgroung.style.display = "block" */
            modalId.style.display = "block"
        }
    }

    closePlazaRequestModal() {
        let userFields = this.state.userFields;
        userFields["requestplazaname"] = "";
        userFields["requestplazaaddress"] = "";
        this.setState({ errors: {}, userFields: userFields });

        /* var backgroung = document.getElementById('modalbackgroundId'); */
        var modalId = document.getElementById('plazarequestmodel')

        if (/* backgroung && */ modalId) {
            /* backgroung.style.display = "none" */
            modalId.style.display = "none"
        }
    }

    SavePlazaRequestModel() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        if (this.validateplazarequest()) {
            var postData = {};
            /* For new plaza request model */
            postData["requestedplazaname"] = this.state.userFields.requestplazaname ? this.state.userFields.requestplazaname : '';
            postData["requestedplazaaddress"] = this.state.userFields.requestplazaaddress ? this.state.userFields.requestplazaaddress : '';
            this.addplazarequest(postData, authToken);
        }
    }

    validateplazarequest() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;
        if (!fields["requestplazaname"]) {
            formIsValid = false;
            errors["requestplazaname"] = AddPlazaConstForm.EnterPlazaname;
        }
        if (!fields["requestplazaaddress"]) {
            formIsValid = false;
            errors["requestplazaaddress"] = AddPlazaConstForm.EnterPlazaAddress;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    addplazarequest(postData, authToken) {
        this.setState({ isActive: true })
        PostData(PLAZA_REQUEST_API, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    /* var backgroung = document.getElementById('modalbackgroundId'); */
                    var modalId = document.getElementById('plazarequestmodel');

                    if (/* backgroung && */ modalId) {
                        /*  backgroung.style.display = "none" */
                        modalId.style.display = "none"
                    }

                    let errors = {};
                    errors["TenantEmail"] = Messages.v_Add_plaza_success_message;
                    this.setState({ errors: errors });
                }
                else {
                    let errors = {};
                    errors["StoreURL"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["StoreURL"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    ////////////////////////////////////////// end plaza request methods ////////////////

    handleChange = (e) => {
        let userFields = this.state.userFields;
        let key = e.target.name
        let value = e.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    HandelSubscriber(data) {
        if (data.isshopifyintegration) {
            StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Shopify);
            this.props.history.push({
                pathname: data.Status == StoreListConst.INREVIEW ? RouteMerchant.MySubscription : RouteMerchant.StoreMain,
                data: data.ObjectId
            });
        }
        else if (data.iswixintegration) {
            StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Wix);
        }
        else {
            StoreDataInStorage(Constants.PLATFROM_FLAG, PlatFrom.Plazacart)
            this.props.history.push({
                pathname: data.Status == StoreListConst.INREVIEW ? RouteMerchant.MySubscription : RouteMerchant.StoreMain,
                data: data.ObjectId
            });
        }
        RemoveFromStorage(Constants.ISTEMP);
        StoreObjectInStorage(Constants.STORE_OBJ, data);
        // StoreDataInStorage(Constants.PLAZAID, data.plazaid);
        StoreDataInStorage(Constants.STOREID, data.ObjectId);
    }

    render() {
        const { IsParent } = this.state;

        let columns = [
            {
                cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px" }} src={row.AppLogo ? row.AppLogo : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} className="iconDetails" />
            },
            {
                name: "Brewery Name",
                cell: row => (row.Status === StoreListConst.INREVIEW || row.Status === StoreListConst.APPROVED || row.Status === StoreListConst.PUBLISHED) ? <div className="tooltip" style={{ zIndex: "unset" }}>
                    <a className="setasatag" tabIndex="" onClick={() => this.storenavigate(row)} >
                        <span style={{ color: Color.THEME_COLOR, fontSize: "18px" }}>{row.ApplicationName}</span>
                    </a>
                    <span className="tooltiptext settooltip settooltipbg">{StoreList.GoToDashboard}</span>
                </div> : <h6 >{row.ApplicationName}</h6>
            },
            {
                name: "Address",
                cell: row => <span style={{ paddingTop: "5px", paddingBottom: "5px" }}>{row.AppAddress}</span>
            },
            {
                width: "fit-content",
                cell: row => {
                    var domValue, hoverMsg
                    if (row.Status === StoreListConst.PUBLISHED) {
                        domValue = <img src={publishapp} onClick={() => this.setPaymentFlow(row)} />
                        hoverMsg = StoreList.Publishapp
                    }
                    else if (row.Status === StoreListConst.APPROVED) {
                        domValue = <img src={makePayment} onClick={() => this.setPaymentFlow(row)} />
                        hoverMsg = StoreList.MakePayment
                    }
                    else if (row.Status === StoreListConst.INREVIEW) {
                        domValue = <img src={processing} onClick={() => this.setPaymentFlow(row)} /> /* this.onClickViewDetails(row) */
                        hoverMsg = StoreList.MakePayment /* StoreList.Inreview */
                    }
                    else if (row.Status === StoreListConst.ACTIVE) {
                        domValue = <img src={processing} onClick={() => this.onClickViewDetails(row)} />
                        hoverMsg = StoreList.Inreview
                    }
                    return <div className="tooltip" style={{ zIndex: "unset" }}>
                        {domValue}
                        <span className="tooltiptext settooltip settooltipbg">{hoverMsg}</span>
                    </div>
                }
            },
            {
                name: "Status",
                cell: row => <span style={{ paddingTop: "5px", paddingBottom: "5px" }}>{SetStoreLevelStatus(row.Status)}</span>
            }
        ]

        if (IsParent) {
            columns.push({
                cell: row => <i class="fas fa-edit genreSlug" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleEditStore(row)}></i>
            });
            columns.push({
                cell: row =>
                    <div className="tooltip" style={{ zIndex: "unset" }}>
                        <span style={{ paddingTop: "5px", paddingBottom: "5px", fontSize: '18px' }}>{(row.Status === StoreListConst.INREVIEW) ? <span class="badge badge-pill" style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} onClick={() => this.HandelSubscriber(row)}>Subscribe</span> : ""}</span>
                        <span className="tooltiptext settooltip settooltipbg">Click to subscribe</span>
                    </div>
            });
        }

        ///////////////////// add store  ///////////////////////////////////////

        const { selectedOption, storecatlist } = this.state;

        const options = storecatlist.map(v => ({
            label: v.MainCategoryName,
            value: v.MainCategoryName
        }));
        const CountryCode = this.state.countryInfo ? [this.state.countryInfo.CountryCode] : []
        ///////////////////////////////////////////////////////////////////////
        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner">
                    <Header setHeaderTitle={Constvariable.ManageStore} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            {IsParent && <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.regularRegister}
                                    className="btn btn-reg-email">
                                    Submit a Brewery
                                </span>
                            </div>}
                            <div className="reg_form_main">
                                <DataTableExtensions
                                    data={this.state.storeList}
                                    columns={columns}
                                    export={false}
                                    print={false}
                                    filter={this.state.storeList.length > 0 ? true : false}
                                    filterHidden={true}
                                    filterPlaceholder={Messages.SearchforStore}
                                    filterStyle={{ width: "100px" }}>
                                    <DataTable
                                        noHeader={true}
                                        pagination
                                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                    // noTableHead={true}
                                    //pagination
                                    />
                                </DataTableExtensions>
                            </div>
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                    </section>

                    {/* ////////////////////////////// Add Store /////////////////////////////////  */}
                    <div className="modal-background" id="modalbackgroundId"> </div>
                    <div class="modal" id="myModal2" style={{ verticalAlign: "middle" }}>
                        <div class="modal-dialog setModal-addStore">
                            <div class="modal-content">
                                <div class="modal-body">
                                    {/* <center>
                                        <label><b>{this.state.title}</b></label><br />
                                    </center>*/}

                                    <div className="registed_tab_part pb-0 pt-0" style={{ position: "relative" }}>
                                        <div className="container setfontcolor">
                                            <div className="text-right genreSlug">
                                                <i class="fas fa-times" onClick={() => this.handleModalHide()}></i>
                                            </div>
                                            {/* <p style={{ display: "block" }}>
                                                <h4 className="setfontcolor">Store Submission</h4></p>*/}
                                            <BreadcrumbNav
                                                objArray={this.state.BreadcrumbArray}
                                                index={this.state.BreadNavIndex}
                                            />
                                            <div className="">
                                                <div className="registed_form_tab">
                                                    <input id="step0" className="hiddenCheckBox" type="checkbox" />
                                                    <input id="step1" className="hiddenCheckBox" type="checkbox" />
                                                    <input id="step2" className="hiddenCheckBox" type="checkbox" />
                                                    <input id="step3" className="hiddenCheckBox" type="checkbox" />
                                                    <input id="step4" className="hiddenCheckBox" type="checkbox" />
                                                    <input id="step5" className="hiddenCheckBox" type="checkbox" />
                                                    <input id='step6' className="hiddenCheckBox" type='checkbox' />
                                                    <input id='step7' className="hiddenCheckBox" type='checkbox' />

                                                    <div id="part0">
                                                        {/*<div className="reg_form_main p-0 col-sm-12" style={{ pointerEvents: this.state.storeId ? "none" : "auto" }}>
                                                            <div className="col-sm-12 setHoverDiv" for="Email">
                                                                {StoreConstFrom.RegisterStoreWith} &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.RegisterStore} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            
                                                            <div className="col-sm-12 storebtn">
                                                                <input
                                                                    className="storePlatform"
                                                                    type="radio"
                                                                    name="Wix"
                                                                    value={this.state.userFields.Wix}
                                                                    checked={this.state.isWix}
                                                                    onChange={this.handleUserchange} /> {StoreConstFrom.Wix}
                                                            </div> 
                                                        </div>*/}

                                                        {(this.state.isShopify || this.state.isWix) && <div>
                                                            <div className="reg_form_main p-0 col-sm-12">
                                                                <div className="col-sm-12 setHoverDiv" for="Name">
                                                                    {StoreConstFrom.StoreURL} &nbsp;
                                                                    <OnHoverIconInfo
                                                                        message={StoreFrom.ShopifyStoreName} /> <PutMandatoryfieldSymbol />
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    placeholder={Constvariable.StoreURL}
                                                                    name="StoreURL"
                                                                    value={this.state.userFields.StoreURL}
                                                                    onChange={this.handleUserchange} />
                                                                <div className="col-sm-12 errorMsg">
                                                                    {this.state.errors.StoreURL}
                                                                </div>
                                                            </div>

                                                            <div className="reg_form_main p-0 col-sm-12">
                                                                <div className="col-sm-12 setHoverDiv" for="Name">
                                                                    {StoreConstFrom.AccessToken}
                                                                    &nbsp;
                                                                    <OnHoverIconInfo
                                                                        message={StoreFrom.AccessToken} /> <PutMandatoryfieldSymbol />
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    placeholder={Constvariable.AccessToken}
                                                                    name="AccToken"
                                                                    value={this.state.userFields.AccToken}
                                                                    onChange={this.handleUserchange} />
                                                                <div className="col-sm-12 errorMsg">
                                                                    {this.state.errors.AccToken}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }

                                                        {!this.state.storeId && <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="Name">
                                                                {StoreConstFrom.Address} &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.Address} />
                                                            </div>
                                                            <GooglePlacesAutocomplete
                                                                apiKey={GOOGLE_PLACE_KEY}
                                                                selectProps={{
                                                                    value: this.state.selectedAddress,
                                                                    onChange: (setAddress) => this.handelAddress(setAddress),
                                                                    // menuPortalTarget: document.body,
                                                                    isClearable: true,
                                                                    placeholder: Constvariable.AP_Address,
                                                                    styles: colourStyles,
                                                                }}
                                                                autocompletionRequest={{
                                                                    componentRestrictions: {
                                                                        country: CountryCode
                                                                    }
                                                                }}
                                                            />
                                                            <div className="errorMsg">
                                                                {this.state.errors.PlazaAddress}
                                                            </div>
                                                        </div>}

                                                        {!this.state.storeId && <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="Email">
                                                                {StoreConstFrom.PostalCode}  ({this.state.countryInfo ? this.state.countryInfo.CountryName : ""}) <OnHoverIconInfo
                                                                    message={StoreFrom.PostalCode} /> <PutMandatoryfieldSymbol />

                                                            </div>
                                                            <input
                                                                type="text"
                                                                onChange={this.handleUserchange}
                                                                name="PostalCode"
                                                                value={this.state.userFields.PostalCode}
                                                                placeholder={Constvariable.Postalcode} />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.PostalCode}
                                                            </div>
                                                        </div>}

                                                        <div className="bottam_sing">
                                                            <label
                                                                for="step1"
                                                                id="continue-step1"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                onClick={this.handleSubmitforpart0}
                                                                className="btn btn-done">{StoreConstFrom.Next}</label>
                                                        </div>
                                                    </div>

                                                    <div id="part1">
                                                        {/*!this.state.storeId && <div className="col-sm-12 p-0">
                                                            <div className="col-sm-12 setHoverDiv" for="Email">
                                                                {StoreConstFrom.SelectPlaza}  <OnHoverIconInfo
                                                                    message={StoreFrom.SelectPlaza} /> <PutMandatoryfieldSymbol /> &nbsp;
                                                                <span className="fontc-gray">{StoreConstFrom.Screen4Msg2} <a onClick={() => this.handalPlazaRequestModal()} className="theme-color">{StoreConstFrom.Scrren4Msg3} </a>
                                                                </span>
                                                            </div>
                                                            <Select
                                                                value={this.state.selectedPlaza}
                                                                isClearable={true}
                                                                menuPortalTarget={document.querySelector('body')}
                                                                onChange={this.handleOnPlazaSelect}
                                                                options={this.state.plazaList}
                                                                styles={colourStyles}
                                                                placeholder={Constvariable.S_Plaza}
                                                                getOptionLabel={(option) => option.CompanyName}
                                                                getOptionValue={(option) => option._id} />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.TenantEmail}
                                                            </div>
                                                            {/* <div className="col-sm-12">
																<p>
																	{StoreConstFrom.Note}
																</p>
																<div className="text-justify">
																	{StoreConstFrom.Screen4Msg}
																</div>
																<div className="text-justify">
																	{StoreConstFrom.Screen4Msg1}
																</div>
																<div className="text-justify">
																	{StoreConstFrom.Screen4Msg2} <a href="mailto:tj24618@gmail.com" target="_blank" className="theme-color">{StoreConstFrom.Scrren4Msg3} </a>
																</div>
																<div className="col-sm-12">
																	{StoreConstFrom.Scrren4Msg4}
																</div>
																<div className="col-sm-12">
																	{StoreConstFrom.Screen4Msg5}
																</div>
																<div className="col-sm-12">
																	{StoreConstFrom.Screen4Msg6}
																</div>
																<div className="col-sm-12">
																	{StoreConstFrom.Screen4Msg7}
																</div>
															</div> }

                                                        </div>*/}

                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="Name">
                                                                {StoreConstFrom.StoreName}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.StoreName} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder={Constvariable.StoreName}
                                                                name="StoreName"
                                                                value={this.state.userFields.StoreName}
                                                                disabled={(this.state.isShopify || this.state.isWix)}
                                                                onChange={this.handleUserchange} />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.StoreName}
                                                            </div>
                                                        </div>

                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="Email">
                                                                {StoreConstFrom.Storecategory}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.Storecategory} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            <Select
                                                                value={selectedOption}
                                                                onChange={this.selectcategory}
                                                                menuPlacement="top"
                                                                options={options}
                                                                styles={colourStyles}
                                                                menuPortalTarget={document.body}
                                                                placeholder={Constvariable.StoreCategory} />
                                                            <div className="col-sm-12 errorMsg" style={{ marginTop: '5px' }}>
                                                                {this.state.errors.StoreCategory}
                                                            </div>
                                                        </div>
                                                        <br />
                                                        {/* Changes are made based on the call discussion. Date 10 Aug 2022 */}
                                                        {/* <div className="reg_form_main p-0 col-sm-12" style={{ marginTop: 'number, string' }}>
															<div className="col-sm-12 setHoverDiv" for="open">
																{StoreConstFrom.StoreOpen}
															</div>
															<div className="col-sm-12">
																<label style={{ margin: '10px', color: "black" }}>{StoreConstFrom.Yes}</label>
																<input style={{ margin: '10px' }} type='checkbox' checked={this.state.chkboxyes} onChange={this.handleChangeChkyes} />
																<label style={{ margin: '10px', color: "black" }} >{StoreConstFrom.No}</label>
																<input style={{ margin: '10px' }} type='checkbox' checked={this.state.chkboxno} onChange={this.handleChangeChkno} />
															</div>
														</div> */}
                                                        <div className="bottam_sing">
                                                            <label
                                                                for="step1"
                                                                id="back-step0"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                onClick={() => this.breadcrumbNavFun(0)}
                                                                className="btn btn-skip btn-fw">
                                                                {StoreConstFrom.PREV}
                                                            </label>
                                                            <label
                                                                for="step1"
                                                                id="continue-step1"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                onClick={this.handleSubmitforpart1}
                                                                className="btn btn-done btn-fw">{StoreConstFrom.Next}</label>
                                                        </div>
                                                    </div>

                                                    <div id="part2">
                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="PhoneNumber">
                                                                {StoreConstFrom.PhoneNumber}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.PhoneNumber} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder={Constvariable.S_PhoneNumber}
                                                                maxLength="14"
                                                                name="PhoneNumber"
                                                                value={this.state.userFields.PhoneNumber}
                                                                onChange={this.handleUserchange} />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.PhoneNumber}
                                                            </div>
                                                        </div>

                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="Website">
                                                                {StoreConstFrom.Website}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.Website} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder={Constvariable.WebsiteUrl}
                                                                name="Website"
                                                                value={this.state.userFields.Website}
                                                                onChange={this.handleUserchange} />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.Website}
                                                            </div>
                                                        </div>
                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="FacebookUser">
                                                                {StoreConstFrom.FacebookUser}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.FacebookUser} />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder={Constvariable.FacebookUser}
                                                                name="FacebookUser"
                                                                value={this.state.userFields.FacebookUser}
                                                                onChange={this.handleUserchange} />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.FacebookUser}
                                                            </div>
                                                        </div>
                                                        <div className="bottam_sing">
                                                            <label
                                                                for="step2"
                                                                id="back-step1"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                onClick={() => this.breadcrumbNavFun(1)}
                                                                className="btn btn-skip btn-fw">
                                                                {StoreConstFrom.PREV}
                                                            </label>
                                                            <label
                                                                for="step3"
                                                                id="continue-step3"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                onClick={this.handleSubmitforpart2}
                                                                className="btn btn-done btn-fw">
                                                                {StoreConstFrom.Next}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div id="part3">
                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="Name">
                                                                {StoreConstFrom.StoreAddress} &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.StoreAddress} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            <GooglePlacesAutocomplete
                                                                apiKey={GOOGLE_PLACE_KEY}
                                                                selectProps={{
                                                                    value: this.state.selectedStoreAddress,
                                                                    onChange: (setAddress) => this.handelStoreAddress(setAddress),
                                                                    menuPortalTarget: document.body,
                                                                    isClearable: true,
                                                                    placeholder: Constvariable.S_StoreAddress,
                                                                    styles: colourStyles
                                                                }}
                                                                autocompletionRequest={{
                                                                    componentRestrictions: {
                                                                        country: CountryCode
                                                                    }
                                                                }}
                                                            />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.Address}
                                                            </div>
                                                        </div>

                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="City">
                                                                {StoreConstFrom.City}  {/* (Required) */}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.City} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder={Constvariable.S_City}
                                                                name="City"
                                                                value={this.state.userFields.City}
                                                                onChange={this.handleUserchange} />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.City}
                                                            </div>
                                                        </div>

                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="City">
                                                                {StoreConstFrom.StateName}  {/* (Required) */}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.StateName} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder={Constvariable.S_State}
                                                                name="state"
                                                                value={this.state.userFields.state}
                                                                onChange={this.handleUserchange} />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.state}
                                                            </div>
                                                        </div>

                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="City">
                                                                {StoreConstFrom.CountryName}  {/* (Required) */}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.CountryName} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder={Constvariable.S_Country}
                                                                name="country"
                                                                value={this.state.userFields.country}
                                                                // onChange={this.handleUserchange}
                                                                disabled />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.country}
                                                            </div>
                                                        </div>

                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <div className="col-sm-12 setHoverDiv" for="City">
                                                                {StoreConstFrom.PostalCode}  {/* (Required) */}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={StoreFrom.PostalCode} /> <PutMandatoryfieldSymbol />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder={Constvariable.Postalcode}
                                                                name="postalcode"
                                                                value={this.state.userFields.postalcode}
                                                                onChange={this.handleUserchange} />
                                                            <div className="col-sm-12 errorMsg">
                                                                {this.state.errors.postalcode}
                                                            </div>
                                                        </div>

                                                        {/* <div className="reg_form_main p-0 col-sm-12">
															<div className="col-sm-12">
																<input style={{ marginRight: '10px' }} type='checkbox' checked={this.state.PickupEnable} onChange={this.handleChangePickup} />
																<label className="setHoverDiv">
																	{StoreConstFrom.PickupServiceAvailable}
																</label>
															</div>
														</div>

														<div className="reg_form_main p-0 col-sm-12">
															<div className="col-sm-12">
																<input style={{ marginRight: '10px' }} type='checkbox' checked={this.state.DeliveryEnable} onChange={this.handleChangeDelivery} />
																<label className="setHoverDiv">
																	{StoreConstFrom.DeliveryServicesAvailable}
																</label>
															</div>
														</div> */}

                                                        {/* Changes are made based on the call discussion. Date 10 Aug 2022 */}
                                                        {/* <div className="reg_form_main p-0 col-sm-12">
															<label className="col-sm-12" style={{ color: "black" }}>
																{StoreConstFrom.PhysicalReceipts}
															</label>
															<div className="col-sm-12">
																<input style={{ marginRight: '10px' }} type='checkbox' checked={this.state.chkboxphyyes} onChange={this.handleChangeChkphyyes} />
																<label style={{ marginRight: '10px', color: "black" }}>{StoreConstFrom.Yes}</label>
																<input style={{ marginRight: '10px' }} type='checkbox' checked={this.state.chkboxphyno} onChange={this.handleChangeChkphyno} />
																<label style={{ marginRight: '10px', color: "black" }}>{StoreConstFrom.No}</label>
															</div>
														</div> */}

                                                        <div className="bottam_sing">
                                                            <label for="step3" id="back-step2"
                                                                className="btn btn-skip btn-fw"
                                                                onClick={() => this.breadcrumbNavFun(2)}
                                                                style={{ background: Color.THEME_COLOR }}>
                                                                {StoreConstFrom.PREV}
                                                            </label>
                                                            <label
                                                                for="step4"
                                                                id="continue-step4"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                onClick={this.handleSubmitforpart3}
                                                                className="btn btn-done btn-fw">
                                                                {StoreConstFrom.Next}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div id="part4">
                                                        <div style={{ marginBottom: '10px' }}>
                                                            <div className="aep-avatar reg_form_main p-0 col-sm-12">
                                                                <h6>{StoreConstFrom.StoreLogo}
                                                                    &nbsp;
                                                                    <OnHoverIconInfo
                                                                        message={StoreFrom.StoreLogo} /> <PutMandatoryfieldSymbol /> </h6>
                                                                <center>
                                                                    <div className="aepa-left banner-updated width-image">
                                                                        <img src={this.state.profilePic ? this.state.profilePic : defultUser} onError={(e) => this.imageError(e, "logo")} />
                                                                        <div className="errorMsg white-space-pw">
                                                                            {this.state.errors.logo}
                                                                        </div>
                                                                    </div>
                                                                </center>
                                                                <div className="d-block pl-0">
                                                                    <div className="btn-upload" style={{ maxWidth: "unset" }}>
                                                                        <label className="btn fontc-gray bg-color-ccc" for="updateProfile" style={{ padding: "4px 0px 0px 0px ", background: Color.THEME_COLOR }}>
                                                                            {this.state.profilePic ? StoreConstFrom.EditLabel : StoreConstFrom.Upload}
                                                                        </label>
                                                                        <input
                                                                            type="file"
                                                                            id="updateProfile"
                                                                            style={{ opacity: "0" }}
                                                                            data-id="profile"
                                                                            className="hideAction"
                                                                            onClick={(e) => this.resetImgsrc(e, "updateProfile")}
                                                                            onChange={this.onSelectProfileFile} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aep-avatar reg_form_main p-0 col-sm-12" style={{ marginTop: '10px' }}>
                                                                <h6>{StoreConstFrom.StoreBanner}
                                                                    &nbsp;
                                                                    <OnHoverIconInfo
                                                                        message={StoreFrom.StoreBanner} /> <PutMandatoryfieldSymbol />
                                                                </h6>
                                                                <center>  <div className="aepa-left banner-updated width-image">
                                                                    <img src={this.state.coverPic ? this.state.coverPic : defualtBanner} style={{ minHeight: "150px" }} onError={(e) => this.imageError(e, "banner")} />
                                                                    <div className="errorMsg white-space-pw">
                                                                        {this.state.errors.banner}
                                                                    </div>
                                                                </div>
                                                                </center>
                                                                <div className="d-block pl-0">
                                                                    <div className="btn-upload" style={{ maxWidth: "unset" }}>
                                                                        <label className="btn fontc-gray bg-color-ccc" for="updateCover" style={{ padding: "4px 0px 0px 0px ", background: Color.THEME_COLOR }}>
                                                                            {this.state.coverPic ? StoreConstFrom.EditLabel : StoreConstFrom.Upload}
                                                                        </label>
                                                                        <input
                                                                            type="file"
                                                                            id="updateCover"
                                                                            style={{ opacity: "0" }}
                                                                            data-id="cover"
                                                                            className="hideAction"
                                                                            onClick={(e) => this.resetImgsrc(e, "updateCover")}
                                                                            onChange={this.onSelectBannerFile} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bottam_sing">
                                                            <label for="step4" id="back-step3"
                                                                className="btn btn-skip btn-fw"
                                                                onClick={() => this.breadcrumbNavFun(3)}
                                                                style={{ background: Color.THEME_COLOR }}>
                                                                {StoreConstFrom.PREV}
                                                            </label>
                                                            <label
                                                                for="step5"
                                                                id="continue-step5"
                                                                style={{ background: Color.THEME_COLOR }}
                                                                onClick={this.handleSubmitforpart4}
                                                                className="btn btn-done btn-fw">
                                                                {StoreConstFrom.Next}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div id="part5">
                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            {this.renderMap()}
                                                            <div className="bottam_sing">
                                                                <label for='step5' id="back-step4"
                                                                    className="btn btn-skip btn-fw"
                                                                    onClick={() => this.breadcrumbNavFun(4)}
                                                                    style={{ background: Color.THEME_COLOR }}>  {StoreConstFrom.PREV}</label>
                                                                <label for='step6'
                                                                    id="continue-step6"
                                                                    className="btn btn-done btn-fw"
                                                                    onClick={() => this.breadcrumbNavFun(6)}
                                                                    style={{ background: Color.THEME_COLOR }}>{StoreConstFrom.Next}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id="part6">
                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <p >
                                                                {StoreConstFrom.boxesInfo}
                                                                {/* &nbsp;
																		<OnHoverIconInfo
																		message={StoreFrom.boxesInfo} /> */}
                                                            </p>
                                                            {/* <ProductTypeList data={this.state.data} /> */}
                                                            {/* new changes */}
                                                            {this.state.data
                                                                .map(function (data, index) {
                                                                    return (
                                                                        <div key={index}>
                                                                            <table style={{ width: '100%' }} >
                                                                                <tr>
                                                                                    <th id='th-header'>
                                                                                        {/* <input type='checkbox' onClick={this.storenavigate} /> */}
                                                                                        <Checkbox
                                                                                            // color={Color.THEME_COLOR}
                                                                                            size={2}
                                                                                            tickSize={2}
                                                                                            checked={data.IsConfirm}
                                                                                            delay={0}
                                                                                            backAnimationDuration={0}
                                                                                            tickAnimationDuration={0}
                                                                                            onChange={() => this.handleCheck(data)}></Checkbox>
                                                                                    </th>
                                                                                    <th >{data.Prouducttype}</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td >{data.Description}</td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    )
                                                                }, this)}
                                                        </div>
                                                        <div className="bottam_sing">
                                                            <label for='step6' id="back-step5"
                                                                className="btn btn-skip btn-fw"
                                                                onClick={() => this.breadcrumbNavFun(5)}
                                                                style={{ background: Color.THEME_COLOR }}>{StoreConstFrom.PREV}</label>
                                                            <button className="btn btn-done btn-fw"
                                                                for='step7' id="continue-step7"
                                                                onClick={() => this.openSearch()} style={{ background: Color.THEME_COLOR }}> {StoreConstFrom.Next}</button>
                                                        </div>
                                                    </div>

                                                    <div id="part7">
                                                        <div className="reg_form_main p-0 col-sm-12">
                                                            <h5 className="text-center">{StoreConstFrom.Storeapplication}</h5>
                                                            <p className="fontc-gray text-justify">{StoreConstFrom.EvaluateStore}</p>
                                                        </div>
                                                        <div className="bottam_sing">
                                                            <label for='step7' id="back-step6" className="btn btn-skip btn-fw"
                                                                onClick={() => this.breadcrumbNavFun(6)}
                                                                style={{ background: Color.THEME_COLOR }}>  {StoreConstFrom.PREV}</label>
                                                            <label
                                                                className="btn btn-done btn-fw"
                                                                onClick={this.submitUserRegistrationForm}
                                                                style={{ background: Color.THEME_COLOR }}>{StoreConstFrom.SUBMIT}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="modal-footer" onClick={() => this.hidemodal2()}>
									<label
										style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
										data-dismiss="modal"
										onClick={() => this.hidemodal2()}
									> Close</label>
								</div> */}
                            </div>
                        </div>
                    </div>

                    {/* ////////////////////////////// End Add Store /////////////////////////////////  */}

                    {/* Plaza new Request Dailog /////////////////// start */}
                    <div class="modal" id="plazarequestmodel">
                        <div class="modal-dialog setmodaldilog dialogcustomplazarequest">
                            <div class="modal-content dialogcustomplazarequestcontent">
                                <div class="modal-body">
                                    <center>
                                        <h5>{Messages.newpopupPlazaTitle}</h5>
                                    </center>
                                    <div className="reg_form_main col-12">
                                        <p className="col-sm-12" for="requestplazaname">
                                            {AddPlazaConstForm.PlazaName} <PutMandatoryfieldSymbol />
                                        </p>
                                        <input
                                            type="text"
                                            placeholder={AddPlazaConstForm.PlazaName}
                                            name="requestplazaname"
                                            value={this.state.userFields.requestplazaname}
                                            onChange={this.handleChange} />
                                        <div className="errorMsg">
                                            {this.state.errors.requestplazaname}
                                        </div>
                                    </div>

                                    <div className="reg_form_main col-12">
                                        <p className="col-sm-12" for="requestplazaaddress">
                                            {AddPlazaConstForm.Address} <PutMandatoryfieldSymbol />
                                        </p>
                                        <textarea
                                            style={{ borderRadius: '10px' }}
                                            type="text"
                                            name="requestplazaaddress"
                                            className='add-item-textarea'
                                            placeholder={AddPlazaConstForm.Address}
                                            value={this.state.userFields.requestplazaaddress}
                                            onChange={this.handleChange} />
                                        <div className="errorMsg">
                                            {this.state.errors.requestplazaaddress}
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <label
                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                        data-dismiss="modal"
                                        onClick={() => this.SavePlazaRequestModel()}>
                                        {AddPlazaConstForm.AddRequestLabel}</label>
                                    <label
                                        style={{ color: Color.BLACK, marginLeft: "auto", marginRight: "auto" }}
                                        data-dismiss="modal"
                                        onClick={() => this.closePlazaRequestModal()}
                                    >{AddPlazaConstForm.CloseLabel}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Plaza new Request Dailog /////////////////// end */}

                </LoadingOverlay >
            </div >
        );
    }
}

export default StoreMainWithAdd;