import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./Header.css";
import Constants from '../Config/Constants'
import { RetriveDataFromStorage } from "../Config/Utils";
import Color from "../Config/Color";
import SideMenu from "../SideMenu/SideMenu";
// import RouteConstant from ".Config/RouteConstant";

import backBtn from "../../images/icons/back.png";
// import cartBtn from "../../images/icons/cart.png";
import Badge from '@uiw/react-badge'; // https://www.npmjs.com/package/@uiw/react-badge
import RouteMerchant from '../Config/RouteMerchant.js'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            headerTitle: "",
            hasHeaderImage: false,
            headerImage: "",
            hasHamburgerDisplay: false,

            visibleIconCart: "hidden",
            visibleIconBackBtn: "visible",

            noOfItemsInCart: props.countItemCart
        };

        this.closeNav = this.closeNav.bind(this);
        this.openNav = this.openNav.bind(this);

        this.goBack = this.goBack.bind(this);
        this.handleCart = this.handleCart.bind(this);
    }

    componentDidMount() {

        /*secure session app */
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        if (!authToken) {
            this.props.history.push({
                pathname: RouteMerchant.Default,
            });
        }

        var path = window.location.pathname;
        switch (path) {
            case RouteMerchant.StoreMain:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.Home:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.ManageProducts:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.ManageItems:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.StoreSupplier:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.Pickuptimes:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.Deliverytimes:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.Inventory:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.Notification:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.PurchaseOrder:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.ReceiveInventory:
                // this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.Earnings:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.HomeShopify:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.SetAppointment:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.PayPalPayment:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.StripeStandardPayment:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.StripeExpressPayment:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.PlazaMain:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.ManageCoupon:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.MySubscription:
                var istemp = RetriveDataFromStorage(Constants.ISTEMP)
                if (!istemp) {
                    this.setState({ hasHamburgerDisplay: true })
                }
                break;
            case RouteMerchant.BulkUpload:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.ImageBulkUpload:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.doordashConfig:
                this.setState({ hasHamburgerDisplay: true })
                break;
            case RouteMerchant.merchantUser:
                this.setState({ hasHamburgerDisplay: true })
                break;

            case RouteMerchant.ManageTaxes:
                this.setState({ hasHamburgerDisplay: true })
                break;
            default:
            // code block
        }
        //console.log(path)
        // if (path === RouteConstant.Login) {

        // }
        // else if (path === RouteConstant.ForgotPassword) {
        //   //this.setState({ headerTitle: Constants.Title_ForgotPwd })
        // }
        // else if (path === RouteConstant.Register) {
        //   this.setState({ headerTitle: Constants.Title_PlazaList })
        //   this.setState({ visibleIconCart: "visible" });
        // }
        // else if (path === RouteConstant.Dashboard) {
        //   this.setState({ visibleIconCart: "visible" });
        //   this.setState({ hasHamburgerDisplay: true });
        // }
        // else if (path === RouteConstant.PlazaStore) {
        //   var obj = RetriveObjectFromStorage(Constants.Obj_PLAZA)
        //   this.setState({ headerImage: obj.Imageurl })
        //   this.setState({ visibleIconCart: "visible" });
        //   this.setState({ hasHeaderImage: true });
        //   this.setState({ hasHamburgerDisplay: true });
        // }
        // else if (path === RouteConstant.PlazaStoreItems) {
        //   //var obj = RetriveObjectFromStorage(Constants.Obj_STORE)
        //   this.setState({ hasHeaderImage: false });
        //   //this.setState({ headerTitle: obj.ApplicationName })     
        //   this.setState({ visibleIconCart: "visible" });
        // }
        // else if (path === RouteConstant.AddToppings) {
        //   //var obj = RetriveObjectFromStorage(Constants.Obj_STORE_ITEMS, '');
        //   //this.setState({ headerTitle: obj.ItemName})     
        //   this.setState({ visibleIconCart: "visible" });
        // }
        // else if (path === RouteConstant.AddToCart) {
        //   //var obj = RetriveObjectFromStorage(Constants.Obj_STORE_ITEMS)
        //   //this.setState({ headerTitle:  obj.ItemName })     
        //   this.setState({ visibleIconCart: "visible" });
        // }
        // else if (path === RouteConstant.MyCart) {
        //   this.setState({ visibleIconCart: "hidden" });
        // }
        // else if (path === RouteConstant.Checkout) {
        //   this.setState({ visibleIconCart: "hidden" });
        // }
        // else if (path === RouteConstant.Payment) {
        //   this.setState({ visibleIconCart: "hidden" });
        // }
        // else if (path === RouteConstant.AddAddress) {
        //   this.setState({ visibleIconCart: "hidden" });
        // }
        // else if (path === RouteConstant.NotificationDetail) {
        //   this.setState({ visibleIconCart: "hidden" });
        // }
        // else if (path === RouteConstant.EditProfile) {
        //   this.setState({ visibleIconCart: "hidden" });
        // }
        // else if (path === RouteConstant.OrderDetail) {
        //   this.setState({ visibleIconCart: "hidden" });
        // }
        // else if (path === RouteConstant.CurbsideOrder) {
        //   this.setState({ visibleIconCart: "hidden" });
        // }


        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        //var flagRememberMe = RetriveDataFromStorage(Constants.FLAG_REMEMBER_ME);
        //console.log("authToken",authToken);
        if (authToken === '' || authToken === null) {
            this.setState({ isLoggedIn: false });
        } else {
            this.setState({ isLoggedIn: true });
        }

        var cartItems = RetriveDataFromStorage(Constants.Obj_CART_ITEMS, '');
        if (cartItems && cartItems.length > 0) {
            if (cartItems && cartItems.length > 0) {
                this.setState({ noOfItemsInCart: cartItems.length });
            } else {
                this.setState({ noOfItemsInCart: '0' });
            }
        }
    }


    setHeaderTitle = title => {
        this.setState({ headerTitle: Constants.Title_CurbsideOrders })
    }

    closeNav() {
        window.closeNav();
    }

    openNav() {
        window.openNav();
    }

    goBack() {
        var path = window.location.pathname;
        if (path == RouteMerchant.MySubscription) {
            this.props.history.push({
                pathname: RouteMerchant.StoreMain
            })
        }
        else {
            this.props.history.goBack();
        }
    }

    handleCart() {
        // this.props.history.push({
        //   pathname: RouteConstant.MyCart,
        //   countCartItem: this.state.noOfItemsInCart
        // })
    }

    render() {
        const { isLoggedIn, hasHeaderImage, headerImage, hasHamburgerDisplay, noOfItemsInCart } = this.state;
        return (
            <div className="header-container">
                <header className="header" style={{ background: Color.THEME_COLOR }}>
                    <div id="mySidenav" className="sidenav">
                        {/* <a
              href="javascript:void(0)"
              className="closebtn"
              onClick={this.closeNav}>
              &times;
            </a> */}
                        <SideMenu />
                    </div>

                    <div className="top-bar" style={{ background: Color.THEME_COLOR }}>
                        <div className="col-sm-12">
                            <div className="row">

                                {isLoggedIn && hasHamburgerDisplay ?
                                    <a className="nav-link" onClick={this.openNav} >
                                        <i class="fa fa-bars" aria-hidden="false" style={{ float: "left", fontSize: "30px" }}></i>
                                    </a>
                                    : <a className="nav-link"  >
                                        <i class="fas fa-arrow-left" style={{ float: "left", fontSize: "30px", color: Color.WHITE }} onClick={this.goBack}></i>
                                        {/* <img src={backBtn} style={{ float: "left", height: "25px", width: "25px" }} onClick={this.goBack}></img> */}
                                    </a>
                                }

                                <a className="navbar-brand header-title" style={{ marginLeft: "auto", marginRight: "auto" }}>
                                    {hasHeaderImage ?
                                        <center>
                                            <img
                                                style={{ height: "40px", width: "40px", borderRadius: "10px" }}
                                                src={headerImage}
                                                onClick={this.goBack}
                                                //src='https://webaven.s3.amazonaws.com//images/Tenants/5f7c457721a59146e0a024a6/2020-06-10--03-22-47.png'
                                                className="iconDetails" />
                                        </center>
                                        :
                                        <label
                                            id="headername"
                                            className="header-name"
                                            style={{ marginLeft: "0px", overflow: "hidden", textOverflow: "ellipsis", fontSize: "23px", paddingTop: "5px", fontWeight: "bold" }}>
                                            {/* {this.state.headerTitle} */}
                                            {this.props.setHeaderTitle}
                                        </label>

                                    }
                                </a>

                                {/* {isLoggedIn ?                    */}
                                <a className="nav-link" id="headerCart" style={{ visibility: this.state.visibleIconCart, float: "right", }}>
                                    <Badge
                                        count={this.props.countItemCart ? this.props.countItemCart : "0"}
                                        style={{ padding: "1px", backgroundColor: Color.BLACK, marginLeft: "30px", borderRadius: "50px", height: "20px", width: "20px" }}>
                                        <i class="fas fa-shopping-cart" aria-hidden="true" style={{ color: Color.THEME_COLOR, fontSize: "25px", float: "right" }}
                                            onClick={this.handleCart}></i>
                                    </Badge>
                                    {/* <Badge count={noOfItemsInCart} style={{  padding:"1px", backgroundColor: Color.BLACK, marginLeft:"30px", borderRadius:"50px", height:"20px", width:"20px" }}>
                    <i class="fas fa-shopping-cart" aria-hidden="true" style={{ color: Color.THEME_COLOR, fontSize: "25px", float: "right" }} onClick={this.handleCart}></i>
                 </Badge> */}
                                    {/* <i class="fas fa-shopping-cart" aria-hidden="true" style={{ color: Color.THEME_COLOR, fontSize: "25px", float: "right" }}></i> */}
                                </a>
                                {/* : ""
                  }  */}
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}
export default withRouter(Header);
