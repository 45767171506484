import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants, { DEFAULTID, imageStandard } from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { GET_PRODUCT_CATEGORY, POST_STORE_CATEGORY, PLAZAID, CATALOG_SAMPLEFILE_API, CATEGORY_KEY, MY_STORE_API, CATALOG_UPLOAD_API, CATALOG_SETUP_API, CATALOG_HISTORY_API, STOREID, CATALOG_ERRORS_API, CATALOG_IMAGE_UPLOAD } from '../../Config/Config.json'
import SideMenu from '../../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import pencil from '../../../images/icons/pencil.png';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { DeleteData } from '../../../services/DeleteData';
import Constvariable, { IMAGEFILEEXTENSIONS } from '../../Config/constvariable';
import { PostData } from '../../../services/PostData';
import { BulkCatalogUploadList, ImageBulkUploadList } from '../../Config/ConstForm'
import './BulkUploadCatalog.css'
import Moment from 'moment'
import { DatetimeFormate } from '../../Config/constvariable'

class ImageBulkUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogHistory: [],
            catalogError: [],

            storeId: '',
            plazaId: "",

            selectedFileLocal: null,
            selectedFileName: "",
            serverFielName: "",

            samplefileUrl: "",

            storeCategory: "",

            isActive: false,

            fileUploadUrl: [],
            errors: {}
        }

        this.fileDrop = this.fileDrop.bind(this)
        this.handleFileInput = this.handleFileInput.bind(this);
        this.handleUploadSubmit = this.handleUploadSubmit.bind(this);
        // this.handleFileProcess = this.handleFileProcess.bind(this);
        // this.handleAddItems = this.handleAddItems.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            // this.getStoredetails(authToken, storeId, plazaId)
            // this.getCatalogHistoryAPI(authToken, storeId, plazaId)
            // this.getCatalogErrorAPI(authToken, storeId, plazaId)
        } else {
            this.props.history.goBack()
        }
    }

    // getCatalogHistoryAPI(authToken, storeId, plazaId) {
    //     this.setState({ isActive: true })
    //     var URL = CATALOG_HISTORY_API + "?" + STOREID + storeId + "&" + PLAZAID + plazaId;
    //     GetData(URL, authToken)
    //         .then(result => {
    //             var responseJson = result;
    //             if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    //                 var responseData = responseJson["Data"];
    //                 console.log("responseData ", responseData);
    //                 this.setState({ catalogHistory: responseData ? responseData : [] })
    //             }
    //             else {
    //             }
    //             this.setState({ isActive: false })
    //         });
    // }

    // getCatalogErrorAPI(authToken, storeId, plazaId) {
    //     this.setState({ isActive: true })
    //     var URL = CATALOG_ERRORS_API + "?" + STOREID + storeId + "&" + PLAZAID + plazaId;
    //     GetData(URL, authToken)
    //         .then(result => {
    //             var responseJson = result;
    //             if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    //                 var responseData = responseJson["Data"];
    //                 console.log("responseData ", responseData);
    //                 this.setState({ catalogError: responseData ? responseData : [] })
    //             }
    //             else {
    //             }
    //             this.setState({ isActive: false })
    //         });
    // }

    // getStoredetails(authToken, storeid, plazaid) {
    //     this.setState({ isActive: true })
    //     var URL = MY_STORE_API + "/" + storeid + "?" + PLAZAID + plazaid
    //     console.log("authToken", URL);
    //     GetData(URL, authToken)
    //         .then(result => {
    //             var responseJson = result;
    //             if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    //                 var responseData = responseJson["Data"];
    //                 console.log("responseData ", responseData);
    //                 if (responseData && responseData.storedetail) {
    //                     this.setState({ storeCategory: responseData.storedetail.Category ? responseData.storedetail.Category : "" });
    //                     this.getCatalogSample(authToken, responseData.storedetail.Category)

    //                 }
    //                 else {
    //                     this.setState({ storeCategory: "" })
    //                 }

    //             }
    //             else {
    //             }
    //             this.setState({ isActive: false })
    //         });
    // }

    // getCatalogSample(authToken, storeCategory) {
    //     this.setState({ isActive: true })
    //     var URL = CATALOG_SAMPLEFILE_API + "?" + CATEGORY_KEY + storeCategory;
    //     GetData(URL, authToken)
    //         .then(result => {
    //             var responseJson = result;
    //             if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    //                 var responseData = responseJson["Data"];
    //                 console.log("responseData ", responseData);
    //                 this.setState({ samplefileUrl: "https://plazacart.blob.core.windows.net/plazacartdev/b741165e-ef0c-4b12-b42a-fc6bb0c61f88/Catalogfiles/sample_20220602051020_20220610034728.csv?sv=2020-08-04&ss=b&srt=sco&sp=rtf&se=2022-12-31T21:33:04Z&st=2022-05-23T12:33:04Z&spr=https&sig=CzEXExr21ZbWbN4EbICaMidwNcksXu%2BXWmQy%2BadOWS8%3D" })
    //                 // this.setState({ samplefileUrl: responseData ? responseData : "" })

    //             }
    //             else {
    //             }
    //             this.setState({ isActive: false })
    //         });
    // }

    // ////////////////////////////////// End Detail api //////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    openfileUploadPanel() {
        if (document.getElementById("ctfileId")) {
            document.getElementById("ctfileId").value = null
            document.getElementById("ctfileId").click()
        }
    }

    fileDrop(e) {
        this.setState({ errors: {} })
        e.preventDefault();
        const files = e.dataTransfer.files;
        console.log(files);
        try {
            for (var i = 0; i < 1; i++) {
                var fileobj = files[i];
                if (fileobj.size > 0) {

                    var fileName = files[i].name;
                    var fileSize = files[i].size;
                    var extension = IMAGEFILEEXTENSIONS;
                    var fileSplit = fileName.split('.');
                    var filelength = fileSplit.length
                    var fileExtension = fileSplit[filelength - 1]
                    var extensionFlag = extension.includes(String(fileExtension).toLowerCase())
                    if (extensionFlag) {
                        this.masterConvertion(fileobj, fileName, fileExtension)
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.v_ImageExtension_ALL;
                        this.setState({ errors: errors });
                    }
                }
            }
        }
        catch (err) {
            console.log("File Upload Error", err);
            let errors = {};
            errors["ErrorC"] = "File Upload Error";
            this.setState({ errors: errors });
        }
        return false
    }

    handleFileInput(event) {
        this.setState({ errors: {} })
        try {
            event.preventDefault();
            for (var i = 0; i < 1; i++) {
                var fileobj = event.target.files[i];
                if (fileobj.size > 0) {
                    var fileName = event.target.files[i].name;
                    var fileSize = event.target.files[i].size;
                    var extension = IMAGEFILEEXTENSIONS;
                    var fileSplit = fileName.split('.');
                    var filelength = fileSplit.length
                    var fileExtension = fileSplit[filelength - 1]
                    var extensionFlag = extension.includes(String(fileExtension).toLowerCase())
                    if (extensionFlag) {
                        this.masterConvertion(fileobj, fileName, fileExtension, fileSize)

                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.v_ImageExtension_ALL;
                        this.setState({ errors: errors });
                    }
                }
            }
        }
        catch (err) {
            console.log("File Upload Error", err);
            let errors = {};
            errors["ErrorC"] = "File Upload Error";
            this.setState({ errors: errors });
        }
    };

    masterConvertion = (fileObj, fileName, fileExtension, fileSize) => new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(fileObj);
            reader.onload = (fileLoadedEvent) => {
                var fileObj = {}
                fileObj['base64data'] = fileLoadedEvent.target.result
                fileObj['filename'] = fileName
                fileObj['type'] = fileExtension
                this.standardImageSizeConvert(fileObj, fileSize)
            };
            reader.onerror = error => reject(error);
        } catch (e) {
            console.log("in Promise", errors)
        }
    })

    standardImageSizeConvert(e, size) {
        let error = {}
        var img = new Image();
        img.onload = () => {
            /* var iw = img.width;
            var ih = img.height; */

            /* if (iw == imageStandard.bulkImageUploadW && ih == imageStandard.bulkImageUploadH) { */
            this.setState({
                selectedFileLocal: e.base64data,
                selectedFileName: e.filename
            })
            this.handleUploadSubmit(e.base64data, e.filename)
            /* } */
            /* else if (imageStandard.ImageSize < size) {
                let s = Messages.v_bulkImageUploadResolution
                error["ErrorC"] = s
            }
            else {
                let s = Messages.v_bulkImageUploadResolution
                error["ErrorC"] = s
            } */
            this.setState({ errors: error });
        }
        img.src = e.base64data;
    }

    handleUploadSubmit(fileData, fileName) {
        var postData = {};
        let storeId = this.state.storeId;
        let plazaId = this.state.plazaId;
        let selectedFileName = fileName;
        let selectedFileLocal = fileData;
        if (storeId && selectedFileName && selectedFileLocal) {
            postData['storeid'] = storeId;
            postData['plazaid'] = plazaId;
            postData['filename'] = selectedFileName;
            postData['base64'] = selectedFileLocal;
            console.log("=====>", JSON.stringify(postData))

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.uploadFileAPI(authToken, postData)
        }
    }

    uploadFileAPI(authToken, postData) {
        this.setState({ isActive: true, errors: {} })
        var URL = CATALOG_IMAGE_UPLOAD
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log(URL, result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    var responseData = responseJson["Data"];
                    this.setState({ fileUploadUrl: responseData ? [{ "fileUrl": responseData, "base64": postData.base64 }] : [] })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    // handleFileProcess(e) {
    //     var postData = {};
    //     e.preventDefault();
    //     let storeId = this.state.storeId;
    //     let plazaId = this.state.plazaId;
    //     let serverFielName = this.state.serverFielName;
    //     if (storeId && plazaId && serverFielName) {
    //         postData['storeid'] = storeId;
    //         postData['plazaid'] = plazaId;
    //         postData["azurestoragefilename"] = this.state.serverFielName
    //         console.log("=====>", JSON.stringify(postData))

    //         var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

    //         this.fileSetupAPI(authToken, postData)
    //     }
    // }

    // fileSetupAPI(authToken, postData) {
    //     this.setState({ isActive: true })
    //     var URL = CATALOG_SETUP_API
    //     PostData(URL, postData, authToken)
    //         .then(result => {
    //             var responseJson = result;
    //             console.log(URL, result)
    //             if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    //                 this.handleReset()
    //                 this.getCatalogHistoryAPI(authToken, postData.storeid, postData.plazaid)
    //             }
    //             else {
    //                 this.getCatalogErrorAPI(authToken, postData.storeid, postData.plazaid)
    //             }
    //         })
    //     this.setState({ isActive: false })
    // }

    handleReset() {
        this.setState({
            selectedFileLocal: null,
            selectedFileName: "",
            serverFielName: "",
        })
        this.option1fileVisible()
    }

    option1fileVisible() {
        var option1file = document.getElementById("option1file")
        var option2file = document.getElementById("option2file")
        if (option1file && option2file) {
            option1file.style.display = "block"
            option2file.style.display = "none"
        }
    }

    option2fileVisible() {
        var option1file = document.getElementById("option1file")
        var option2file = document.getElementById("option2file")
        if (option1file && option2file) {
            option1file.style.display = "none"
            option2file.style.display = "block"
        }
    }

    copyOnclipboard(e, textObj, hovermsgId) {
        const elem = document.createElement('textarea');
        elem.value = textObj ? textObj.fileUrl : "";
        document.body.appendChild(elem);
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);

        if (document.getElementById(hovermsgId)) {
            document.getElementById(hovermsgId).innerHTML = ImageBulkUploadList.CopiedText
        }
    }

    resetHoverMsg(hovermsgId) {
        if (document.getElementById(hovermsgId)) {
            document.getElementById(hovermsgId).innerHTML = ImageBulkUploadList.CopyText
        }
    }

    render() {
        const { hideEmptyPlazaView } = this.state;
        const columns = [
            {
                // name: BulkCatalogUploadList.T1_FileName,
                cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px" }} src={row.base64} className="iconDetails" />
            },
            {
                // name: BulkCatalogUploadList.T1_Description,
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.fileUrl}</div>,
            },
            {
                // name: BulkCatalogUploadList.T1_CreatedDate,
                cell: row => <div className="tooltip" style={{ zIndex: "unset" }}>
                    <i class="fas fa-clipboard"
                        style={{ color: Color.THEME_COLOR, fontSize: "1.2rem" }}
                        onClick={(e) => this.copyOnclipboard(e, row, "link1")}
                        onMouseOut={(e) => this.resetHoverMsg("link1")}> </i>
                    <span className="tooltiptext settooltip settooltipbg tooltip-left-middle" id="link1">{ImageBulkUploadList.CopyText}</span>
                </div>
                ,
                center: true
            }
        ];

        // const errColumns = [
        //     {
        //         name: BulkCatalogUploadList.T2_Action,
        //         cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.action}</div>,
        //     },
        //     {
        //         name: BulkCatalogUploadList.T2_Description,
        //         cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.description}</div>,
        //     },
        //     {
        //         name: BulkCatalogUploadList.T2_CreatedDate,
        //         cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{Moment.utc(row.createddate).format(DatetimeFormate.DDMMYYYY)}</div>,
        //     }
        // ];

        return (
            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    // active={true}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={ImageBulkUploadList.ImageBulkUpload} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="reg_form_main col-lg-12">
                                <form action="#" class="form-horizontal" id="option1file">
                                    <textarea
                                        id="files"
                                        name="files"
                                        placeholder={ImageBulkUploadList.AddImage}
                                        class="form-control input-sm allinputstyle dragfiles"
                                        onDrop={this.fileDrop}
                                        onClick={() => this.openfileUploadPanel()}
                                        value={ImageBulkUploadList.AddImage}
                                        rows="4" />
                                    <input className="catalogfile-input"
                                        type="file" id="ctfileId" accept=".jpg , .jpeg , .bmp , .gif , .png" onChange={this.handleFileInput} />
                                </form>

                                {/* <div id="option2file" style={{ display: "none" }}>
                                    <div className="file-uploadedbox" >
                                        <div className="">
                                            {this.state.serverFielName ? this.state.serverFielName : this.state.selectedFileName}
                                        </div>
                                        {!this.state.serverFielName && <div className="">
                                            <label
                                                style={{ background: Color.THEME_COLOR }}
                                                onClick={() => this.openfileUploadPanel()}
                                                className="btn btn-done"
                                            > {BulkCatalogUploadList.btn_Replace_File}</label>
                                        </div>}
                                    </div>
                                </div> */}

                                {/* <div className="footer-button">
                                    <label
                                        style={{ background: Color.GREY, color: Color.THEME_COLOR }}
                                        onClick={() => this.handleReset()}
                                        className="btn btn-done"
                                    > {BulkCatalogUploadList.btn_Cancel}</label>
                                    {this.state.serverFielName ? <label
                                        style={{ background: Color.THEME_COLOR }}
                                        className="btn btn-done"
                                        onClick={this.handleFileProcess}
                                    > {BulkCatalogUploadList.btn_Import_Products}</label> : <label
                                        style={{ background: Color.THEME_COLOR }}
                                        className="btn btn-done"
                                        onClick={this.handleUploadSubmit}
                                    > {BulkCatalogUploadList.btn_Upload_and_Continue}</label>}
                                </div> */}

                                <div className="history-container">
                                    <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">{ImageBulkUploadList.UploadedImage}</a>
                                            {/* <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">{BulkCatalogUploadList.CatalogErrors}</a> */}
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane fade show active history-container" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <DataTableExtensions
                                                data={this.state.fileUploadUrl}
                                                columns={columns}
                                                export={false}
                                                print={false}
                                                filter={false}
                                                // filterHidden={false}
                                                // filterPlaceholder={Messages.SearchforCategory}
                                                filterStyle={{ width: "100px" }}
                                            >
                                                <DataTable
                                                    noHeader={true}
                                                    noTableHead={true}
                                                    pagination
                                                    paginationRowsPerPageOptions={[10, 20, 30, 40]}

                                                />
                                            </DataTableExtensions>
                                        </div>
                                        {/* <div class="tab-pane fade history-container" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <DataTableExtensions
                                                data={this.state.catalogError}
                                                columns={errColumns}
                                                export={false}
                                                print={false}
                                                filter={false}
                                                // filterHidden={false}
                                                // filterPlaceholder={Messages.SearchforCategory}
                                                filterStyle={{ width: "100px" }}
                                            >
                                                <DataTable
                                                    noHeader={true}
                                                    // noTableHead={true}
                                                    pagination
                                                    paginationRowsPerPageOptions={[10, 20, 30, 40]}

                                                />
                                            </DataTableExtensions>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <center>
                                        <div className="errorMsg white-space-pw">
                                            {this.state.errors.ErrorC}
                                        </div>
                                    </center>
                                </div>
                            </div>

                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteCategoryTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteCategory}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div >

        );
    }
}

export default ImageBulkUpload;