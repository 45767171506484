import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants,{DEFAULTID} from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API, PLAZAID } from '../../Config/Config.json'
import Header from '../../Header/Header.js'
import Constvariable from '../../Config/constvariable';
import Color from '../../Config/Color';
import RouteMerchant from '../../Config/RouteMerchant.js';
import LoadingOverlay from 'react-loading-overlay';

class StoreDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            imgbanner: '',
            loaderClass: "",
            storeId: "",
            plazaId: "",
            isActive: false
        }
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        var storeid = params.get("id");
        var plazaid = DEFAULTID;
        if (storeid && plazaid) {
            this.setState({ storeId: storeid, plazaId: plazaid })
            this.getStoredetails(authToken, storeid, plazaid)
        }
    }

    getStoredetails(authToken, storeid, plazaid) {
        this.setState({ isActive: true })
        var URL = MY_STORE_API + "/" + storeid + "?" + PLAZAID + plazaid
        console.log("authToken", URL);
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        if (responseData.storedetail) {
                            this.setState({ data: responseData.storedetail });
                            var b = [];
                            b = responseData.storedetail.Homebanner;
                            var a = b[0].HomeBannerImagePath;
                            this.setState({ imgbanner: a, loaderClass: "hideAction" });
                            console.log("responseData ", a);
                        }
                    }

                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
            });
    }

    handleUpdateStore() {
        var storeId = this.state.storeId;
        var plazaId = this.state.plazaId
        if (storeId && plazaId) {
            this.props.history.push({
                pathname: RouteMerchant.Store,
                search: "?id=" + storeId + "&plazaId=" + plazaId
            })
        }

    }

    render() {
        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    // active={true}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.StoreDetails} />
                    <div className="container" >
                        <center>
                            <div className="up-notifications">
                                <h4>
                                    <span>General Information</span>
                                </h4>
                            </div>
                            <div className="up-notifications">
                                <table>
                                    <tbody>
                                        <tr height="50">
                                            <td width="150">Brewery name:</td>
                                            <td><b>{this.state.data.BusinessName}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Status:</td>
                                            <td><b>{this.state.data.Status}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Contact Number:</td>
                                            <td><b>{this.state.data.PhoneNumber}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Email:</td>
                                            <td><b>{this.state.data.Email}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Brewery Profile:</td>
                                            <td><img src={this.state.data.LogoPath} /> </td>
                                        </tr>
                                        <tr height="50">
                                            <td>Brewery Banner:</td>
                                            <td><img src={this.state.imgbanner} /> </td>
                                        </tr>
                                        <tr height="50">
                                            <td>City:</td>
                                            <td><b>{this.state.data.City}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Address:</td>
                                            <td><b>{this.state.data.StreetAddress}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Brewery Category:</td>
                                            <td><b>{this.state.data.Category}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Brewery Open for public:</td>
                                            <td><b>{this.state.data.IsPublicOpen ? "Yes" : "No"}</b></td>
                                        </tr>
                                        <tr height="50">
                                            <td>Physical Order Receipts:</td>
                                            <td><b>{this.state.data.PhysicalOrderReceipts ? "Yes" : "No"}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <div className="bottam_sing">
                                    <label
                                        style={{ background: Color.THEME_COLOR }}
                                        for="step5"
                                        id="continue-step5"
                                        onClick={() => this.handleUpdateStore()}
                                        className="btn btn-done"
                                    > {Constvariable.UpdateProfile}</label>
                                </div> */}
                            </div>
                        </center>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}

export default StoreDetails;