import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants, { DEFAULTID } from '../../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { GET_PRODUCT_CATEGORY, POST_STORE_CATEGORY, PLAZAID } from '../../Config/Config.json'
import SideMenu from '../../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import pencil from '../../../images/icons/pencil.png';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { DeleteData } from '../../../services/DeleteData';
import Constvariable from '../../Config/constvariable';

class ManageProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            storeCategoryList: [],
            categoryId: '',
            storeId: '',
            plazaId: "",
            errors: {},
            isParentCategoryDelete: false
        }
        this.handleAddProducts = this.handleAddProducts.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            this.getProductCatagory(authToken, storeId, plazaId)
        } else {
            this.props.history.goBack()
        }
        console.log(authToken);
        StoreObjectInStorage(Constants.STORE_PRODUCT_OBJ, '')
    }

    getProductCatagory(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_PRODUCT_CATEGORY.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ storeCategoryList: responseData });
                        // this.setState({ loaderClass: false });
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    regularRegister(e) {
        localStorage.removeItem("FacebookData");
        window.location.href = "/Store";
    }

    handleAddProducts() {
        this.props.history.push({
            pathname: RouteMerchant.AddProducts,
        })

    }

    handleEditProducts(row) {
        StoreObjectInStorage(Constants.STORE_PRODUCT_OBJ, row)
        this.props.history.push({
            pathname: RouteMerchant.AddProducts,
        })
    }

    handleAddItems(row) {
        StoreObjectInStorage(Constants.STORE_PRODUCT_OBJ, row)
        this.props.history.push({
            pathname: RouteMerchant.ItemList,
        })

    }

    handleDelete(itemObj, flag) {
        this.setState({ categoryId: "" });
    }

    DeleteShowhandle(itemObj, flag) {
        var isParentCategoryDelete = false;
        if (flag) {
            if (itemObj.ParentCategoryID === "000000000000000000000000") {
                isParentCategoryDelete = true;
            } else {
                isParentCategoryDelete = false;
            }
            this.setState({ categoryId: itemObj._id, isParentCategoryDelete: isParentCategoryDelete });
        }
        else {
            this.setState({ categoryId: "", isParentCategoryDelete: isParentCategoryDelete });
        }
    }

    deleteItem() {
        let categoryId = this.state.categoryId;
        var plazaId = this.state.plazaId
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.deleteCategoryAPI(authToken, categoryId, plazaId)
    }

    deleteCategoryAPI(authToken, categoryId, plazaId) {
        this.setState({ isActive: true })
        var URL = POST_STORE_CATEGORY + '/' + categoryId + "?" + PLAZAID + plazaId;
        var postData = {}
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ categoryId: "" })
                    var storeId = this.state.storeId
                    this.getProductCatagory(authToken, storeId, plazaId)
                }
                else {
                    this.setState({ isActive: false })
                }
            })
    }

    render() {
        const { hideEmptyPlazaView, isParentCategoryDelete } = this.state;
        const columns = [
            {
                // maxWidth: "80px",
                name: "Category Name",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.Category_name}</div>,
                // cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px" }} src={row.Imageurl} className="iconDetails" />
            },
            {
                name: "Parent Category Name",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.ParentCategoryName}</div>,
                // cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.CompanyName}</div>
            },
            {
                maxWidth: "80px",
                cell: row => <i class="fas fa-edit" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleEditProducts(row)}></i>
                //  <i class="fa fa-shopping-cart" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={this.handleAddProducts}></i>
            },
            {
                maxWidth: "80px",
                cell: row => <a data-toggle="modal" data-target="#exampleModalLong">
                    <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.DeleteShowhandle(row, true)}></i>
                </a>
            },
            {
                maxWidth: "80px",
                cell: row => { return row.ParentCategoryID !== "000000000000000000000000" && < i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleAddItems(row)}></i > }
            },

        ];

        return (
            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner" >
                    <Header setHeaderTitle={Constvariable.ManageCategory} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAddProducts}
                                    className="btn btn-reg-email">
                                    {Constvariable.AddProduct}
                                </span>
                            </div>

                            <div className="reg_form_main">
                                {!hideEmptyPlazaView
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img></img>
                                            <p className="col-sm-12" for="FirstName">

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <DataTableExtensions
                                        data={this.state.storeCategoryList}
                                        columns={columns}
                                        export={false}
                                        print={false}
                                        filter={true}
                                        filterHidden={false}
                                        filterPlaceholder={Messages.SearchforCategory}
                                        filterStyle={{ width: "100px" }}
                                    >
                                        <DataTable
                                            noHeader={true}
                                            // noTableHead={true}
                                            pagination
                                            paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                            onRowClicked={this.handleRowClick}

                                        />
                                    </DataTableExtensions>
                                }
                            </div>

                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteCategoryTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {isParentCategoryDelete ? Messages.ParentDeleteCategory : Messages.DeleteCategory}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default ManageProducts;