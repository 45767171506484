import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
// import StoreList from "../../StoreList/StoreList";
import Messages from "../Config/Messages";
import Constants,{DEFAULTID} from '../Config/Constants'
import { RetriveDataFromStorage, StoreDataInStorage, StoreObjectInStorage, RetriveObjectFromStorage } from "../Config/Utils";
import { GetData } from "../../services/GetData";
import { PostData } from '../../services/PostData';
import { PAYPALPLAN_API, TYPE, SUBSCRIPTION, GETCLIENTID_API, PLANTYPE, STOREID } from '../Config/Config.json'
// import SideMenu from '../SideMenu/SideMenu';
import Header from '../Header/Header.js'
import Constvariable, { PlatFrom } from '../Config/constvariable';
import Color from '../Config/Color';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import RouteMerchant from '../Config/RouteMerchant.js';
import { StoreListConst } from "../Config/ConstForm"
import './Subscription.css'
import MyPayment from './MyPayment'

class Subscription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderClass: true,
			planList: [],
			isActive: false,
			storeId: "",
			plazaId: "",
			clientId: "",
			errors: {}
		}

	}

	componentDidMount() {
		var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
		var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
		if (storeObj) {
			var storeId = storeObj.ObjectId;
			var plazaId = DEFAULTID
			this.setState({ storeId: storeId, plazaId: plazaId })
			if (storeObj.isshopifyintegration) {
				var platForm = String(PlatFrom.Shopify).toUpperCase();
				this.getPlanListAPI(authToken, platForm, storeId);
			}
			else if (storeObj.iswixintegration) {

			}
			else {
				var platForm = String(PlatFrom.Plazacart).toUpperCase();
				this.getPlanListAPI(authToken, platForm, storeId);
			}
		} else {
			this.props.history.goBack()
		}
		console.log(authToken);
	}

	getPlanListAPI(authToken, platForm, storeId) {
		this.setState({ isActive: true, errors: {} })
		var URL = PAYPALPLAN_API + "?" + TYPE + platForm + "&" + PLANTYPE + "ADD" + "&" + STOREID + storeId;
		console.log(URL)
		GetData(URL, authToken)
			.then(result => {
				var responseJson = result;
				if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
					var responseData = responseJson["Data"];
					console.log("responseData ", responseData);
					if (responseData) {
						responseData = responseData.map(e => {
							return { ...e, ispaypalShow: false }
						})
						this.setState({ planList: responseData });
					}
					else {
						let errors = {};
						errors["ErrorC"] = Messages.NO_DATA;
						this.setState({ errors: errors });
					}
				}
				else {
					let errors = {};
					errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
					this.setState({ errors: errors });
				}
				this.setState({ isActive: false })
				this.getClientToken(authToken)
			}).catch(error => {
				let errors = {};
				errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
				this.setState({ errors: errors, isActive: false });
			});
	}


	getClientToken(authToken) {
		this.setState({ isActive: true, errors: {} })
		var URL = GETCLIENTID_API
		console.log(URL)
		GetData(URL, authToken)
			.then(result => {
				var responseJson = result;
				if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
					var responseData = responseJson["Data"];
					console.log("responseData ", responseData);

					this.setState({ clientId: responseData });

				}
				else {
					let errors = {};
					errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
					this.setState({ errors: errors });
				}
				this.setState({ isActive: false });
			}).catch(error => {
				let errors = {};
				errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
				this.setState({ errors: errors, isActive: false });
			});
	}

	showpaypal(i) {
		var planList = this.state.planList
		planList = planList.map((planObj, index) => {
			if (index == i) {
				return { ...planObj, ispaypalShow: true }
			}
			return { ...planObj, ispaypalShow: false }
		})
		this.setState({ planList: planList })
	}

	handelSubscribe(subscribeObj) {
		var postData = {}

		postData["planid"] = subscribeObj.pid
		postData["storeid"] = this.state.storeId
		postData["plazaid"] = this.state.plazaId
		postData["subscriptionid"] = subscribeObj.subscriptionID

		console.log("== postData ===>", postData, subscribeObj)
		var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

		if (this.state.storeId) {
			this.postSubscription(authToken, postData)
		}

	}

	postSubscription(authToken, postData) {
		this.setState({ isActive: true, errors: {} })
		var URL = SUBSCRIPTION
		PostData(URL, postData, authToken)
			.then(result => {
				var responseJson = result;
				if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
					this.setState({ isActive: false })
					this.props.history.goBack();
				}
				else {
					let errors = {};
					errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
					this.setState({ errors: errors });
				}
			}).catch(error => {
				let errors = {};
				errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
				this.setState({ errors: errors, isActive: false });
			});
		this.setState({ isActive: false })
	}

	render() {


		return (
			<div>
				<LoadingOverlay
					active={this.state.isActive}
					text={Messages.Loading}
					spinner
					styles={{
						overlay: (base) => ({
							...base,
							position: "fixed"
						})
					}}
					className="lodingspinner"
				>
					<Header setHeaderTitle={Constvariable.Plan} />
					<section className="section" style={{ backgroundColor: Color.WHITE }}>
						<div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
							<div class="row columns" style={{ margin: "0px" }}>
								{this.state.planList.map((e, index) => {
									var priceObj = null
									if (e.billing_cycles) {
										// priceObj = e.billing_cycles[0] ? e.billing_cycles[0] : null
										if (e.billing_cycles) {
											priceObj = e.billing_cycles.map(billCycelObj => {
												return <li>{billCycelObj.billing_price_currency_code + " " + billCycelObj.billing_price + " / " + billCycelObj.billing_interval_unit + " (" + billCycelObj.billing_tenure_type + ")"}</li>
											})
										}
									}
									return <div className="col-md-4 setsubsriptioncard" key={index}>
										<ul class="price" style={{ boxShadow: e.ispaypalShow ? "0 8px 12px 0 rgba(0,0,0,0.2)" : "unset" }}>
											<li class="header">{e.name}</li>
											{priceObj}
											<li>
												{e.description}
											</li>
											<li class="grey">

												{e.ispaypalShow ? <MyPayment divId={index}
													clientId={this.state.clientId}
													planId={e.id}
													successSubscribe={(sIdObj) => this.handelSubscribe(sIdObj)} /> : <label class="button setbtn" onClick={() => this.showpaypal(index)}>Subscribe</label>}
											</li>
										</ul>
									</div>
								})}

								<div className="reg_form_main offset-sm-3 col-sm-6">
									<center>
										<div className="errorMsg">
											{this.state.errors.ErrorC}
										</div>
									</center>
								</div>

								<div className="col-sm-12 setterms">
									<p>
										<a class="" data-toggle="collapse" href="#multiCollapseExample1"
											role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
											<i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }}></i> {Messages.paymentTermTitle}</a>
									</p>
									<div class="row">
										<div class="col">
											<div class="collapse multi-collapse" id="multiCollapseExample1">
												<div class="card card-body">
													{Messages.paymentTermMsg1}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 setterms">
									<p>
										<a class="" data-toggle="collapse" href="#multiCollapseExample2"
											role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
											<i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }}></i> {Messages.cancelSubscriptionTitle}</a>
									</p>
									<div class="row">
										<div class="col">
											<div class="collapse multi-collapse" id="multiCollapseExample2">
												<div class="card card-body">
													{Messages.subscriptionMsg1}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 setterms">
									<p>
										<a class="" data-toggle="collapse" href="#multiCollapseExample3"
											role="button" aria-expanded="false" aria-controls="multiCollapseExample3">
											<i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }}></i> {Messages.havePayPalTitle}</a>
									</p>
									<div class="row">
										<div class="col">
											<div class="collapse multi-collapse" id="multiCollapseExample3">
												<div class="card card-body">
													{Messages.havePayPalMsg1}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</section>
				</LoadingOverlay>
			</div>
		);
	}
}

export default Subscription;