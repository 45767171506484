import { BackEndRequestURL } from '../components/Config/Config.json'

export function GetData(url, auth) {
  let BaseURL = BackEndRequestURL + url
  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + auth,
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, X-Requested-With',
      }
    })
      .then(response => response.json())
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
        //alert(error)
      })
  })
}
