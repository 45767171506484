import React, { Component } from "react";
import "../../../styles/style.css";
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreDataInStorage, validateInt } from "../../Config/Utils";
import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants,{DEFAULTID} from '../../Config/Constants'
import Header from '../../Header/Header.js'
import Color from "../../Config/Color"
import SideMenu from "../../SideMenu/SideMenu"
// import SearchField from "react-search-field";
import Constvariable from '../../Config/constvariable';
import Checkbox from 'react-simple-checkbox';
import RouteMerchant from '../../Config/RouteMerchant.js';
import { GetData } from "../../../services/GetData";
import LoadingOverlay from 'react-loading-overlay';
import { GET_INVENTORY, GET_STORE_ITEM, GET_STORE_SUPPLIER, PUT_RECEIVEINVENTORY, PLAZAID } from '../../Config/Config.json';
import { PostData } from '../../../services/PostData';
import { PUTData } from '../../../services/PUTData';
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import { ReceiveInventoryForm } from '../../Config/HoverMessage'
import { ReceiveInventoryConstForm } from "../../Config/ConstForm";
import { PutMandatoryfieldSymbol } from "../../UtillCustomComponent/UtillCustomComponent";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class ReceiveInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: "Register",
            userFields: {},
            errors: {},
            isActive: false,
            storeId: "",
            plazaId: "",
            inventoryId: "",
            storeItemList: [],
            selecteItem: null,


        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleParantCategory = this.handleParantCategory.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            var inventoryObj = RetriveObjectFromStorage(Constants.INVENTORY_OBJ);
            if (inventoryObj) {
                this.setState({ inventoryId: inventoryObj._id })
                this.getMyStoreItem(authToken, storeId, plazaId);
            }

        } else {
            this.props.history.goBack()
        }
    }

    getMyStoreItem(authToken, storeId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_STORE_ITEM.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ storeItemList: responseData });
                        // this.setState({ loaderClass: false });
                    }
                    else {

                    }
                    this.setState({ isActive: false });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                // this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleItem = selecteItem => {
        // var productList = this.state.productList.filter(e1 => e1.Productid === selecteItem._id)[0]
        // let productFields = this.state.productFields;
        // if (productList) {

        //     productFields['SupplierSKU'] = productList.SupplierSKU
        //     productFields['Quantity'] = productList.Quantity
        //     productFields['Cost'] = productList.Cost
        //     productFields['ProductTax'] = productList.Tax

        // }
        // else {
        //     productFields['SupplierSKU'] = ""
        //     productFields['Quantity'] = ""
        //     productFields['Cost'] = ""
        //     productFields['ProductTax'] = ""
        // }
        // this.setState({ productFields })
        this.setState({ selecteItem })
    }

    // /////////////// end detail methods ////////////////////////////

    handleParantCategory = selectedParantCategory => {
        this.setState({ selectedParantCategory })
    }

    handleCategoryCustomizable() {
        this.state.isCategoryCustomizable ? this.setState({ isCategoryCustomizable: false }) : this.setState({ isCategoryCustomizable: true })
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!this.state.selecteItem) {
            formIsValid = false;
            errors["selecteItem"] = Messages.v_selecteItem;
        }

        if (!fields["SupplierSKU"]) {
            formIsValid = false;
            errors["SupplierSKU"] = Messages.v_SupplierSKU;
        }

        if (!fields["AcceptQuantity"]) {
            formIsValid = false;
            errors["AcceptQuantity"] = Messages.v_AcceptQuantity;
        }
        else if (fields["AcceptQuantity"]) {
            if (!validateInt(fields["AcceptQuantity"])) {
                formIsValid = false;
                errors["AcceptQuantity"] = Messages.v_NewQuantityNumber;
            }
        }

        if (!fields["RejectQuantity"]) {
            formIsValid = false;
            errors["RejectQuantity"] = Messages.v_RejectQuantity;
        }
        else if (fields["RejectQuantity"]) {
            if (!validateInt(fields["RejectQuantity"])) {
                formIsValid = false;
                errors["RejectQuantity"] = Messages.v_NewQuantityNumber;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            var productArray = []
            var productObj = {}
            productObj['Productid'] = this.state.selecteItem._id;
            productObj['SupplierSKU'] = this.state.userFields.SupplierSKU;
            productObj['AcceptQuantity'] = this.state.userFields.AcceptQuantity;
            productObj['RejectQuantity'] = this.state.userFields.RejectQuantity;

            productArray.push(productObj)
            postData['Products'] = productArray

            console.log("==> postdata==", postData)

            var plazaId = this.state.plazaId
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            if (this.state.storeId && this.state.inventoryId) {
                var storeId = this.state.storeId;
                var inventoryId = this.state.inventoryId;
                this.putReceiveInventory(authToken, postData, storeId, inventoryId, plazaId)
            }

        }
    }

    putReceiveInventory(authToken, postData, storeId, inventoryId, plazaId) {
        this.setState({ isActive: true, errors: {} });
        var URL = GET_STORE_SUPPLIER + storeId + "/" + PUT_RECEIVEINVENTORY + "/" + inventoryId + "?" + PLAZAID + plazaId;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false })
    }

    render() {

        return (
            <section className="section">
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.ReceiveInventory}></Header>

                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.ShipingCenter}
                        </p> */}
                            <label className="label-color">{ReceiveInventoryConstForm.Product}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ReceiveInventoryForm.Product} /> <PutMandatoryfieldSymbol />
                            </label>
                            <Select
                                value={this.state.selecteItem}
                                onChange={this.handleItem}
                                options={this.state.storeItemList}
                                styles={colourStyles}
                                menuPortalTarget={document.body}
                                placeholder={Constvariable.Product_Select}
                                className="select-input"
                                getOptionLabel={(option) => option.ItemName}
                                getOptionValue={(option) => option._id}
                            />
                            <div className="errorMsg">
                                {this.state.errors.selecteItem}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.SupplierSKU}
                        </p> */}
                            <label className="label-color">{ReceiveInventoryConstForm.SupplierSKU}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ReceiveInventoryForm.SupplierSKU} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="SupplierSKU"
                                className='add-item-box'
                                placeholder={Constvariable.SupplierSKU}
                                value={this.state.userFields.SupplierSKU}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.SupplierSKU}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.AcceptQuantity}
                        </p> */}
                            <label className="label-color">{ReceiveInventoryConstForm.AcceptQuantity}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ReceiveInventoryForm.AcceptQuantity} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="AcceptQuantity"
                                className='add-item-box'
                                placeholder={Constvariable.AcceptQuantity}
                                value={this.state.userFields.AcceptQuantity}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.AcceptQuantity}
                            </div>
                        </div>

                        <div className="reg_form_main col-sm-8 offset-sm-2">
                            {/* <p className="col-sm-12 textcolor" for="StreetAddress">
                            {Constvariable.RejectQuantity}
                        </p> */}
                            <label className="label-color">{ReceiveInventoryConstForm.RejectQuantity}
                                &nbsp;
                                <OnHoverIconInfo
                                    message={ReceiveInventoryForm.RejectQuantity} /> <PutMandatoryfieldSymbol />
                            </label>
                            <input
                                type="text"
                                name="RejectQuantity"
                                className='add-item-box'
                                placeholder={Constvariable.RejectQuantity}
                                value={this.state.userFields.RejectQuantity}
                                onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                                {this.state.errors.RejectQuantity}
                            </div>
                        </div>

                    </div>
                    <div className="reg_form_main offset-sm-3 col-sm-6">
                        <center>
                            <div className="errorMsg">
                                {this.state.errors.ErrorC}
                            </div>
                        </center>
                    </div>

                    <div className="bottam_sing">
                        <label
                            style={{ background: Color.THEME_COLOR }}
                            for="step5"
                            id="continue-step5"
                            onClick={this.handleAdd}
                            className="btn btn-done"
                        > Update</label>
                    </div>
                </LoadingOverlay>
            </section>

        );
    }
}
export default ReceiveInventory;