import React, { Component } from "react";
import "../../../styles/style.css";
// import './RegistrationNew.css';
import { PostData } from "../../../services/PostData";
import { GetData } from "../../../services/GetData";
import { GetDataGooglePlace } from '../../../services/GetDataGooglePlace';
import ReactFlagsSelect from 'react-flags-select'; // https://www.npmjs.com/package/react-flags-select
// import 'react-flags-select/css/react-flags-select.css';
import Messages from "../../Config/Messages";
import {
    validateEmail, validatePassword, validateUserName, validatePhoneNo, validateOtpNo,
    StoreDataInStorage, validateInt, removeAllSpace, checkPostalCode, formatPhoneNumber,
    validatePhoneNumber, logoutSession
} from "../../Config/Utils";
import { GET_COUNTRY_API, GET_PLAZA_API, COUNTRY_CODE, USER_REGISTRATION_API, VALIDATE_OTP_API, GOOGLE_PLACE_KEY, KEY_STRING, PLACE_ID, GOOGLESITEKEY, GOOGLE_CLIENT_ID } from '../../Config/Config.json'

import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants, { DEFAULTID } from '../../Config/Constants'
import Color from '../../Config/Color';
import RouteMerchant from "../../Config/RouteMerchant";
import OnHoverIconInfo from "../../UtillCustomComponent/UtillCustomComponent"
import RegistrationForm from '../../Config/HoverMessage'
import { AddPlazaConstForm, RegisterNewConstForm } from "../../Config/ConstForm"
import Constvariable from '../../Config/constvariable';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { PutMandatoryfieldSymbol } from "../../UtillCustomComponent/UtillCustomComponent";
import LoadingOverlay from 'react-loading-overlay';

const publicIp = require('public-ip');
import { BrowserView, MobileView, isBrowser, isMobile, deviceDetect, osName, osVersion, fullBrowserVersion, browserName, browserVersion } from "react-device-detect";
import detectBrowserLanguage from 'detect-browser-language';
import MainHeader from "../../MainHeader/MainHeader";
import ReCAPTCHA from "react-google-recaptcha";
import MainFooter from "../../MainFooter/MainFooter";
import Emailicon from "../../../images/icons/email.png";
import LoginWithGoogle from "../../LoginWithGoogleFiles/LoginWithGoogle";
import { ACaptcha, GETWORD } from '../../UtillCustomComponent/CaptchaGenerator';

var mandatoryStyle = {
    color: "red",
    textStyle: "bold"
};

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
    option: styles => ({ ...styles, color: '#333' }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class RegisterNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTitle: RegisterNewConstForm.Register,

            userFields: {},   //registrstion fields
            errors: {},

            countryFields: {},  //select country fields
            countryData: [],     //array of country list bind from API -> country fields

            plazaFields: {},  //select plaza fields
            plazaList: [],   //array of plaza list bind from API -> plaza fields         
            selectedOption: null,  //select plaza fields
            src: null,
            showPlazaForm: false,

            otp: {},  //otp fields
            IsWithPlaza: false,

            selectedAddress: null,
            selectedPlazaAddress: null,
            ipAddress: '',
            browserLanguage: '',
            isActive: false,
            isPassword: true,
            isConfirmPassword: true,

            /* Google Captcha Code */
            /* googleReCaptch: null,
            iscaptchreload: true, */

            /* Native Captcha Code */
            captchWord: "",
            iscaptchwordMatch: false,

            steppage: 0,
            issignupwithgooogle: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangePlaza = this.handleChangePlaza.bind(this)
        this.handleValidationOnBlur = this.handleValidationOnBlur.bind(this);

        this.handleCountrySubmit = this.handleCountrySubmit.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleValidationCountryonBlur = this.handleValidationCountryonBlur.bind(this);
        this.onSelectFlag = this.onSelectFlag.bind(this);

        this.handlePlazaSubmit = this.handlePlazaSubmit.bind(this);
        this.handleOnPlazaSelect = this.handleOnPlazaSelect.bind(this);

        this.handleValidateOtpSubmit = this.handleValidateOtpSubmit.bind(this);
        this.handleValidateOtpChange = this.handleValidateOtpChange.bind(this);
        this.handleValidationOTPonBlur = this.handleValidationOTPonBlur.bind(this);
    }

    componentDidMount() {

        /* For Ip Address */
        (async () => {
            var ip = await publicIp.v4();
            this.setState({ ipAddress: ip });
            console.log('ip address', ip);
        })();

        logoutSession()

        /* For Current Location */
        if ("geolocation" in navigator) {
            console.log("Geolocation Available");
            this.getLocation();

        } else {
            console.log("Geolocation Not Available");
            console.error("Error Code = " + error.code + " - " + error.message);
        }

        /* For Detect browser details */
        const detectBrowserLanguage = require('detect-browser-language');
        this.setState({ browserLanguage: detectBrowserLanguage() });
        console.log('detect browser language', detectBrowserLanguage());

        this.getCountryAPI();

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    /* For Get Curent Location */
    getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            console.log('Location', 'lat' + position.coords.latitude + "Lon" + position.coords.longitude);
            this.setState({ latitude: position.coords.latitude });
            this.setState({ longitude: position.coords.longitude });
        });
    };

    /***************************************************************************************** */
    // START --> FUNCTIONS FOR REGISTRATION MODULE
    /***************************************************************************************** */

    /* Google Capcha Code */
    /* handleGoogleRecaptcha = vl => {
        this.setState({ googleReCaptch: vl })
    } */

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name;
        let value = event.target.value;

        if (key == "PhoneNumber") {
            userFields[key] = formatPhoneNumber(value);
        }
        else {
            userFields[key] = value;
        }

        /* Native Captcha Code */
        if (key === "captchacode") {
            if (value.length === 6) {
                if (value != this.state.captchWord) {
                    let errors = this.state.errors;
                    errors['captchacode'] = Messages.v_EnterValidCaptcha;
                    this.setState({ errors: errors, iscaptchwordMatch: false });
                } else {
                    this.setState({ iscaptchwordMatch: true, errors: {} });
                }
            } else {
                if (value.length === 0 || value.length === 1) {
                    this.setState({ errors: {} });
                }
                this.setState({ iscaptchwordMatch: false });
            }
        }

        this.setState({ userFields });
        // if (key === 'Name' && value[0] === ' ') value = value.trim()
    }

    reloadCaptch() {

        /* Google Captcha Code */
        /* this.setState({ iscaptchreload: false, googleReCaptch: null })
        setTimeout(() => {
            this.setState({ iscaptchreload: true })
        }, 900) */

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    handleValidationOnBlur(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name == "Email") {
            if (!fields["Email"]) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterEmail;
            }
            else if (typeof fields["Email"] !== "undefined") {
                if (!validateEmail(fields["Email"])) {
                    formIsValid = false;
                    errors["Email"] = Messages.v_EnterValidEmail;
                }
            }
        }

        if (name == "FirstName") {
            if (!fields["FirstName"]) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterName;
            }
            else if (typeof fields["FirstName"] !== "undefined") {
                if (!validateUserName(fields["FirstName"])) {
                    formIsValid = false;
                    errors["FirstName"] = Messages.v_EnterValidName;
                }
            }
        }

        if (name == "LastName") {
            if (!fields["LastName"]) {
                formIsValid = false;
                errors["LastName"] = Messages.v_EnterLastName;
            }
        }

        if (name == "Password") {
            if (!fields["Password"]) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterPassword
            }
            else if (typeof fields["Password"] !== "undefined") {
                if (!validatePassword(fields["Password"])) {
                    formIsValid = false;
                    errors["Password"] = Messages.v_EnterValidPassword;
                }
            }
        }

        if (name == "ConfirmPassword") {
            if (!fields["ConfirmPassword"]) {
                formIsValid = false;
                errors["ConfirmPassword"] = Messages.v_EnterConfirmPassword;
            }
            else if (typeof fields["ConfirmPassword"] !== "undefined") {
                if (!validatePassword(fields["ConfirmPassword"])) {
                    formIsValid = false;
                    errors["ConfirmPassword"] = Messages.v_EnterValidPassword;
                }
            }
        }

        if (name == "PhoneNumber") {
            if (!fields["PhoneNumber"]) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterPhoneNo
            }
            else if (typeof fields["PhoneNumber"] !== "undefined") {
                if (!validatePhoneNumber(fields["PhoneNumber"])) {
                    formIsValid = false;
                    errors["PhoneNumber"] = Messages.v_EnterValidPhoneNo;
                }
            }
        }

        /* Native Captcha Code */
        if (name === "captchacode") {
            if (!fields['captchacode']) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterCaptcha;
            }
            else if (typeof fields["captchacode"] !== "undefined") {
                if (fields["captchacode"] != this.state.captchWord) {
                    formIsValid = false;
                    errors["captchacode"] = Messages.v_EnterValidCaptcha;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;
        let countryfields = this.state.countryFields;

        /* Google Captcha Code */
        /* const { googleReCaptch } = this.state; */

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = Messages.v_EnterEmail;
        }
        else if (typeof fields["Email"] !== "undefined") {
            if (!validateEmail(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }

        if (!fields["FirstName"]) {
            formIsValid = false;
            errors["FirstName"] = Messages.v_EnterName;
        }
        else if (typeof fields["FirstName"] !== "undefined") {
            if (!validateUserName(fields["FirstName"])) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterValidName;
            }
        }

        if (!fields["LastName"]) {
            formIsValid = false;
            errors["LastName"] = Messages.v_EnterLastName;
        }

        if (!fields["Password"]) {
            formIsValid = false;
            errors["Password"] = Messages.v_EnterPassword
        }
        else if (typeof fields["Password"] !== "undefined") {
            if (!validatePassword(fields["Password"])) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterValidPassword;
            }
        }

        if (!fields["ConfirmPassword"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = Messages.v_EnterConfirmPassword;
        }
        else if (typeof fields["ConfirmPassword"] !== "undefined") {
            if (!validatePassword(fields["ConfirmPassword"])) {
                formIsValid = false;
                errors["ConfirmPassword"] = Messages.v_EnterValidPassword;
            }
        }

        if (fields["ConfirmPassword"] != fields["Password"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = Messages.v_ConfirmPwdMatchWithPwd;
        }

        if (!fields["PhoneNumber"]) {
            formIsValid = false;
            errors["PhoneNumber"] = Messages.v_EnterPhoneNo
        }
        else if (typeof fields["PhoneNumber"] !== "undefined") {
            if (!validatePhoneNumber(fields["PhoneNumber"])) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterValidPhoneNo;
            }
        }

        if (!countryfields["CountryCode"]) {
            formIsValid = false;
            errors["CountryCode"] = Messages.v_EnterCountryName;
        }

        if (!countryfields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }

        /* Native Captcha Code */
        if (!fields['captchacode']) {
            formIsValid = false;
            errors["captchacode"] = Messages.v_EnterCaptcha;
        }
        else if (typeof fields["captchacode"] !== "undefined") {
            if (fields["captchacode"] != this.state.captchWord) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterValidCaptcha;
            }
        }

        /* Google Captcha Code */
        /* if (!googleReCaptch) {
            formIsValid = false;
        } */


        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            /* document.getElementById("step2").checked = true; */
            /*  this.setState({ screenTitle: RegisterNewConstForm.SelectCountry }); */
            /* this.setState({ showPlazaForm: false }) */
            this.handlePlazaSubmit(e);
        }
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR REGISTRATION MODULE
    /***************************************************************************************** */


    /***************************************************************************************** */
    // START --> FUNCTIONS FOR SELECT COUNTRY MODULE
    /***************************************************************************************** */

    getCountryAPI() {
        this.setState({ isActive: false });
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        var countryCode = [];
                        for (var i = 0; i < responseData.length; i++) {
                            var obj = responseData[i].CountryCode;
                            countryCode.push(obj);
                        }
                        this.setState({ countryData: countryCode, errors: {} });
                    }
                    console.log("countryData ", this.state.countryData);
                }
                else {
                    let errors = {};
                    errors["CountryCode"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["CountryCode"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleCountryChange(event) {
        let countryFields = this.state.countryFields;
        let key = event.target.name
        let value = event.target.value
        countryFields[key] = value
        this.setState({ countryFields });
        if (countryFields.PostalCode && countryFields.CountryCode) {
            this.getPlazaByPostalCodeAPI(countryFields.PostalCode, countryFields.CountryCode);
        }
    }

    onSelectFlag(countryCode) {
        let countryFields = this.state.countryFields;
        if (countryCode != countryFields["CountryCode"]) {
            countryFields["PostalCode"] = "";
            this.setState({ selectedAddress: null })
        }
        countryFields["CountryCode"] = countryCode
        this.setState({ countryFields })
        var errors = this.state.errors;
        errors["CountryCode"] = ""
        this.setState({ errors: errors });
        if (countryFields.PostalCode && countryFields.CountryCode) {
            this.getPlazaByPostalCodeAPI(countryFields.PostalCode, countryFields.CountryCode);
        }
    }

    handelAddress(addressObj) {
        this.setState({ selectedAddress: addressObj })
        console.log(addressObj)
        let countryFields = this.state.countryFields;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace("", addressObj.value.place_id, countryFields)
        }
        else {
            countryFields["PostalCode"] = "";
        }
        this.setState({ countryFields, errors: {} })
    }

    getaddressGooglePlace(authToken, value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        console.log(placeId)
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                console.log("====", result)
                if (responseJson) {
                    var responseData = responseJson;
                    if (responseData && responseData.address_components) {
                        refObj["PostalCode"] = ""
                        for (var obj of responseData.address_components) {
                            if (obj.types && checkPostalCode(obj.types)) {
                                refObj["PostalCode"] = removeAllSpace(obj.short_name);
                                this.getPlazaByPostalCodeAPI(refObj["PostalCode"], this.state.countryFields.CountryCode);
                                break;
                            }
                        }
                    }
                    else {
                        refObj["PostalCode"] = ""
                    }
                }
                else {
                    refObj["PostalCode"] = ""

                }
                this.setState({ refObj, isActive: false })
            }).catch(err => {
                refObj["PostalCode"] = ""
                this.setState({ refObj, isActive: false })
            });
    }

    handleValidationCountryonBlur(e) {
        let fields = this.state.countryFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;
        if (name === "PostalCode") {
            if (!fields["PostalCode"]) {
                formIsValid = false;
                errors["PostalCode"] = Messages.v_EnterPostalCode
            }
        }
    }

    validateCountryForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.countryFields;

        if (!fields["CountryCode"]) {
            formIsValid = false;
            errors["CountryCode"] = Messages.v_EnterCountryName;
        }

        if (!fields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCountrySubmit(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateCountryForm()) {
            this.setState({ showPlazaForm: false })
            this.getPlazaByPostalCodeAPI(this.state.countryFields.PostalCode, this.state.countryFields.CountryCode);

        }
    }


    /***************************************************************************************** */
    // END --> FUNCTIONS FOR SELECT COUNTRY MODULE
    /***************************************************************************************** */


    /***************************************************************************************** */
    // START --> FUNCTIONS FOR SELECT PLAZA MODULE
    /***************************************************************************************** */

    getPlazaByPostalCodeAPI(postalCode, countryCode) {
        this.setState({ isActive: false });
        var API = GET_PLAZA_API + postalCode + COUNTRY_CODE + countryCode;
        GetData(API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ plazaList: responseData, errors: {} });
                    }
                }
                else {
                    let errors = {};
                    errors["TenantEmail"] = Messages.NO_DATA;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["TenantEmail"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleOnPlazaSelect = selectedOption => {
        if (selectedOption) {
            this.setState({ selectedOption });
            console.log(`Option selected:`, selectedOption.value);
        }
        else {
            this.setState({ selectedOption: null })
        }
    };

    handlePlazaselectSubmit() {
        this.setState({ screenTitle: RegisterNewConstForm.T_AddPlaza })
        document.getElementById("step3").checked = true;
    }

    validatePlazaForm() {
        let errors = {};
        let formIsValid = true;

        let fields = this.state.plazaFields;
        if (!fields["TenantEmail"]) {
            // formIsValid = false;
            // errors["TenantEmail"] = Messages.v_EnterPlaza;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    // Code to open image file uploader and select file and set image to src
    onSelectFile = e => {
        let plazaFields = this.state.plazaFields;

        if (e.target.files && e.target.files.length > 0) {
            var fileName = event.target.files[0].name;
            var extension = ["jpg", "jpeg", "bmp", "gif", "png"]
            var fileSplit = fileName.split('.');
            var filelength = fileSplit.length
            var fileExtension = fileSplit[filelength - 1]
            var extensionFlag = extension.includes(fileExtension)
            if (extensionFlag) {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    this.setState({ src: reader.result })
                    plazaFields['imagebase64'] = reader.result
                    this.setState({ plazaFields })
                    console.log(reader.result)
                }, { orientation: true })
                reader.readAsDataURL(e.target.files[0]);
            }
            else {
                alert(Messages.v_ImageExtension)
            }
        }
    };

    handleChangePlaza(event) {
        let plazaFields = this.state.plazaFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "PlazaPhoneNumber") {
            plazaFields[key] = formatPhoneNumber(value)
        }
        else {
            plazaFields[key] = value
        }
        this.setState({ plazaFields })
    }

    handelPlazaAddress(addressObj) {
        this.setState({ selectedPlazaAddress: addressObj })
        console.log(addressObj)
        let plazaFields = this.state.plazaFields;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace("", addressObj.value.place_id, plazaFields)
        }
        else {
            plazaFields["PostalCode"] = "";
        }
        this.setState({ plazaFields })
    }

    validatePlazaRender() {
        var flag = false;
        const { plazaFields, selectedPlazaAddress, src } = this.state;

        if (plazaFields["PlazaName1"] || plazaFields["Email"] || plazaFields["PostalCode"] || selectedPlazaAddress || src || plazaFields["PlazaPhoneNumber"]) {
            flag = true
        }

        return flag
    }

    validatePlaza() {
        let plazaFields = this.state.plazaFields;
        let errors = {}
        let formIsValid = true;

        if (!plazaFields['PlazaName1']) {
            formIsValid = false;
            errors['PlazaName1'] = Messages.v_EnterPlazaName;
        }

        if (!plazaFields['Email']) {
            formIsValid = false;
            errors['Email'] = Messages.v_emailId;
        }
        else if (plazaFields["Email"]) {
            if (!validateEmail(plazaFields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }
        if (!this.state.selectedPlazaAddress) {
            formIsValid = false;
            errors['Address'] = Messages.v_Selectaddress;
        }

        if (!plazaFields['PostalCode']) {
            formIsValid = false;
            errors['PlazaPostalCode'] = Messages.v_EnterPostalCode;
        }

        if (!plazaFields["PlazaPhoneNumber"]) {
            formIsValid = false;
            errors["PlazaPhoneNumber"] = Messages.v_phoneNumber;
        }
        else if (plazaFields["PlazaPhoneNumber"]) {
            if (!validatePhoneNumber(plazaFields["PlazaPhoneNumber"])) {
                formIsValid = false;
                errors["PlazaPhoneNumber"] = Messages.v_EnterValidPhoneNo;
            }
        }

        if (!this.state.src) {
            formIsValid = false;
            errors['src'] = Messages.S_BANNERPlaza;
        }
        this.setState({ errors })
        return formIsValid
    }

    handlePlazaSubmit(e) {
        var postData = {};
        e.preventDefault();

        /* Google Captcha Code */
        /* const { googleReCaptch } = this.state; */

        if (this.validatePlazaForm()) {
            this.setState({ errors: {}, isActive: true });
            postData = this.state.userFields;
            postData["CountryCode"] = this.state.countryFields.CountryCode;
            postData["PostalCode"] = String(this.state.countryFields.PostalCode).toUpperCase();
            postData["Company"] = Constants.COMPANY;
            postData["DeviceId"] = "";
            postData["DeviceType"] = "";
            postData["PushToken"] = "";
            postData["Latitude"] = this.state.latitude;
            postData["Longitude"] = this.state.longitude;
            postData["TenantEmail"] = "";
            postData["ipaddress"] = this.state.ipAddress;
            postData["browser"] = browserName;
            postData["browserversion"] = fullBrowserVersion;
            postData["osdetails"] = osName + " " + osVersion;
            if (this.state.selectedAddress && this.state.selectedAddress.label) {
                postData["location"] = this.state.selectedAddress.label;
            } else {
                postData["location"] = String(this.state.countryFields.PostalCode).toUpperCase() + "," + this.state.countryFields.CountryCode;
            }
            postData["locale"] = this.state.browserLanguage;
            postData["questionid"] = DEFAULTID;
            postData["answer"] = "";
            postData["userid"] = "";

            /* For new plaza request model */
            postData["requestedplazaname"] = this.state.userFields.requestplazaname ? this.state.userFields.requestplazaname : '';
            postData["requestedplazaaddress"] = this.state.userFields.requestplazaaddress ? this.state.userFields.requestplazaaddress : '';

            postData["googlecaptcharesponse"] = ""; /* googleReCaptch */

            if (this.state.selectedOption) {
                postData["TenantEmail"] = this.state.selectedOption ? this.state.selectedOption.value : "";
            }
            if (this.validatePlazaRender()) {
                if (!this.validatePlaza()) {
                    return ""
                }
                var plazaObj = this.state.plazaFields;
                plazaObj['CountryCode'] = this.state.countryFields.CountryCode;
                plazaObj['Company'] = this.state.plazaFields.PlazaName1
                plazaObj['PostalCode'] = String(this.state.plazaFields.PostalCode).toUpperCase();
                plazaObj['phonenumber'] = this.state.plazaFields.PlazaPhoneNumber;
                plazaObj['address'] = this.state.selectedPlazaAddress ? this.state.selectedPlazaAddress.label : "";
                postData["Plaza"] = plazaObj
                postData["TenantEmail"] = this.state.plazaFields.Email;
                // plazaObj["Email"] = this.state.plazaFields.Email;
                // delete plazaObj['PlazaName1']
                this.setState({ IsWithPlaza: true });
            }

            console.log('====>', postData);
            console.log('Post data: ', JSON.stringify(postData));
            this.callResgisterAPI(postData);
        }
    }

    callResgisterAPI(postData) {
        var URL = USER_REGISTRATION_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    /* document.getElementById("step1").checked = true; */
                    this.setState({ screenTitle: RegisterNewConstForm.ValidateOTP, errors: {}, steppage: 2 });
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["ErrorC"] = Messages.v_Email_already_exists; /* responseJson["Message"]; */
                    this.setState({ errors: errors, issignupwithgooogle: false });
                } else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
                this.reloadCaptch()
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
                this.reloadCaptch()
            });
    }

    ResponsewithGooglewithSigninApi = (response) => {
        console.log(response);
        try {
            var userFields = this.state.userFields;
            if (response && response.sub) {
                if (response && response.email) {
                    userFields['Email'] = response.email;
                    this.setState({ issignupwithgooogle: true });
                }
                if (response && response.family_name) {
                    userFields['FirstName'] = response.family_name;
                }
                if (response && response.given_name) {
                    userFields['LastName'] = response.given_name;
                }
                this.setState({ userFields: userFields, steppage: 1, errors: {} });
            } else {
                this.setState({ steppage: 0, errors: {}, issignupwithgooogle: false });
            }
        } catch (err) {
            console.log(err);
            this.setState({ steppage: 0, errors: {}, issignupwithgooogle: false });
        }
    }

    ////////////////////////////////////// plaza request methids //////////////////////
    handalPlazaRequestModal() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('plazarequestmodel')

        if (backgroung && modalId) {
            backgroung.style.display = "block"
            modalId.style.display = "block"
        }
    }

    closePlazaRequestModal() {
        let userFields = this.state.userFields;
        userFields["requestplazaname"] = "";
        userFields["requestplazaaddress"] = "";
        this.setState({ errors: {}, userFields: userFields });

        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('plazarequestmodel')

        if (backgroung && modalId) {
            backgroung.style.display = "none"
            modalId.style.display = "none"
        }
    }

    SavePlazaRequestModel() {
        if (this.validateplazarequest()) {
            var backgroung = document.getElementById('modalbackgroundId');
            var modalId = document.getElementById('plazarequestmodel')

            if (backgroung && modalId) {
                backgroung.style.display = "none"
                modalId.style.display = "none"
            }

            let errors = {};
            errors["plazaaddmsg"] = Messages.v_Add_plaza_success_message;
            this.setState({ errors: errors });
        }
    }

    validateplazarequest() {
        let errors = {};
        let formIsValid = true;

        let fields = this.state.userFields;
        if (!fields["requestplazaname"]) {
            errors["requestplazaname"] = AddPlazaConstForm.EnterPlazaname;
        }
        if (!fields["requestplazaaddress"]) {
            errors["requestplazaaddress"] = AddPlazaConstForm.EnterPlazaAddress;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    ////////////////////////////////////////// end plaza request methods ////////////////


    /***************************************************************************************** */
    // END --> FUNCTIONS FOR SELECT PLAZA MODULE
    /***************************************************************************************** */


    /***************************************************************************************** */
    // START --> FUNCTIONS FOR VALIDATE OTP MODULE
    /***************************************************************************************** */

    handleValidateOtpChange(event) {
        let otp = this.state.otp;
        let value = event.target.value
        otp["otp"] = value
        this.setState({ otp })
    }

    handleValidationOTPonBlur(e) {
        let fields = this.state.otp;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name === "otp") {
            if (!fields["otp"]) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterOTP
            }
            else if (typeof fields["otp"] !== "undefined") {
                if (!validateOtpNo(fields["otp"])) {
                    formIsValid = false;
                    errors["otp"] = Messages.v_EnterValidOTP;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }


    validateOTPForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.otp;


        if (!fields["otp"]) {
            formIsValid = false;
            errors["otp"] = Messages.v_EnterOTP
        }
        else if (typeof fields["otp"] !== "undefined") {
            if (!validateOtpNo(fields["otp"])) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterValidOTP;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleValidateOtpSubmit(e) {
        e.preventDefault();
        if (this.validateOTPForm()) {
            this.setState({ errors: {}, isActive: true });
            var postData = {};
            postData["Email"] = this.state.userFields.Email;
            postData["PhoneNumber"] = this.state.userFields.PhoneNumber;
            postData["DeviceId"] = "";/* Constants.DEVICE_ID */
            postData["DeviceType"] = "";/* Constants.DEVICE_TYPE */
            postData["Code"] = this.state.otp.otp;
            console.log('otppostdata: ', postData);
            this.callValidateOtpAPI(postData);
        }
    }

    callValidateOtpAPI(postData) {
        var URL = VALIDATE_OTP_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log('responseJason:', responseJson);
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var authToken = responseJson["Data"].Token;
                    StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                    if (this.state.IsWithPlaza) {
                        this.handalModal();
                    }
                    else {
                        this.props.history.push({
                            pathname: RouteMerchant.Login,
                        })
                    }
                    let errors = {};
                    this.setState({ errors: errors, isActive: false });
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["otp"] = Messages.Invalid_One_Time_Code; /* responseJson.Message; */
                    this.setState({ errors: errors });
                } else {
                    let errors = {};
                    errors["otp"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["otp"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handalModal() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModalWithPlaza')

        if (backgroung && modalId) {
            backgroung.style.display = "block"
            modalId.style.display = "block"
        }
    }

    closeModal() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModalWithPlaza')

        if (backgroung && modalId) {
            backgroung.style.display = "none"
            modalId.style.display = "none"
        }
        this.props.history.push({
            pathname: RouteMerchant.Login,
        })
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR VALIDATE OTP MODULE
    /***************************************************************************************** */

    handleBackBtn(flag) {
        if (flag == 1) {
            this.setState({ screenTitle: RegisterNewConstForm.Register })
        }
        else if (flag == 2) {
            this.setState({ screenTitle: RegisterNewConstForm.SelectCountry })
        }
        else if (flag == 3) {
            this.setState({ screenTitle: RegisterNewConstForm.T_SelectPlaza })
        }
        this.setState({ errors: {} })
    }

    render() {
        /* Google Captcha Code */
        const { showPlazaForm, selectedOption, plazaList, isPassword, isConfirmPassword, /*googleReCaptch, iscaptchreload,*/ steppage, issignupwithgooogle, iscaptchwordMatch, captchWord } = this.state;
        const options = plazaList.map(v => ({
            label: v.CompanyName,
            value: v.TenantEmail
        }));

        return (<LoadingOverlay
            active={this.state.isActive}
            text={Messages.Loading}
            spinner
            styles={{
                overlay: (base) => ({
                    ...base,
                    position: "fixed"
                })
            }}
            className="lodingspinner">
            <div class="hero_area">
                <MainHeader />
                <section className="section">
                    <div className="modal-background" id="modalbackgroundId"> </div>
                    <div className="registed_tab_part" style={{ position: "relative", padding: "20px 0px 0px 0px" }}>
                        <div className="container">
                            <div className="" >
                                <div className="registed_form_tab setmindiv" style={{ color: "#333" }}>
                                    <input id="step0" className="hiddenCheckBox" type="checkbox" />
                                    <input id="step1" className="hiddenCheckBox" type="checkbox" />
                                    <input id="step2" className="hiddenCheckBox" type="checkbox" />
                                    <input id="step3" className="hiddenCheckBox" type="checkbox" />
                                    <input id="step4" className="hiddenCheckBox" type="checkbox" />
                                    <input id='step5' className="hiddenCheckBox" type='checkbox' />

                                    {steppage == 0 && <div /* id="part0" */>
                                        <div className="sub_form_reg_one">
                                            <div id="home" className="tab-pane">
                                                <div className="as_user_form">

                                                    <div className="reg_form_main">
                                                        <center>
                                                            <div
                                                                className="btn btn-done d-flex m-0"
                                                                style={{
                                                                    height: "44px",
                                                                    borderRadius: "4px", backgroundColor: Color.THEME_COLOR,
                                                                    width: '300px',
                                                                    padding: 0,
                                                                    alignItems: 'center',
                                                                    gap: '60px'
                                                                }}
                                                                onClick={() => { this.setState({ steppage: 1, errors: {}, issignupwithgooogle: false }); }}>
                                                                <img
                                                                    src={Emailicon}
                                                                    style={{ height: "30px", width: "30px", margin: "0px 5px" }} />
                                                                <label className="m-0" style={{ fontSize: "14px", letterSpacing: '0.25px' }}>{RegisterNewConstForm.Sign_up_with_Email}</label>
                                                            </div>
                                                        </center>
                                                    </div>
                                                    <div className="reg_form_main">
                                                        <div className="bottam_sing">
                                                            <LoginWithGoogle
                                                                mode={2}
                                                                clientId={GOOGLE_CLIENT_ID}
                                                                disabled={false}
                                                                onSuccess={this.ResponsewithGooglewithSigninApi} />
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main mt-2">
                                                        <center><a href={RouteMerchant.Login}
                                                            className="col-sm-12 theme-color font-wight-bold">{RegisterNewConstForm.AlreadyRegistered}</a></center>
                                                    </div>

                                                    <div className="reg_form_main offset-sm-3 col-sm-6 settextalignterms textcolor">
                                                        By creating an account or logging in, you agree to Brewersnearme's
                                                        <a href={RouteMerchant.TermsOfService} target="_blank"
                                                            className="theme-color font-wight-bold d-block">Terms & Conditions</a><a tabIndex="" href={RouteMerchant.PrivacyPolicy}
                                                                className="theme-color font-wight-bold d-block" target="_blank">Privacy Policy.</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {steppage == 1 && <div /* id="part0" */>
                                        <div className="sub_form_reg_one">
                                            <div id="home" className="tab-pane">
                                                <div className="as_user_form">

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12 setHoverDiv" for="Email">
                                                            {RegisterNewConstForm.OnlyEmail}   <OnHoverIconInfo
                                                                message={RegistrationForm.Email} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <input
                                                            type="email"
                                                            placeholder={Constvariable.R_Email}
                                                            name="Email"
                                                            disabled={issignupwithgooogle}
                                                            value={this.state.userFields.Email}
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleValidationOnBlur} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.Email}
                                                        </div>
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.alreadyEmail}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12 setHoverDiv" for="FirstName">
                                                            {RegisterNewConstForm.Name}    <OnHoverIconInfo
                                                                message={RegistrationForm.Name} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder={Constvariable.YourName}
                                                            name="FirstName"
                                                            value={this.state.userFields.FirstName}
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleValidationOnBlur} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.FirstName}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12 setHoverDiv" for="LastName">
                                                            {RegisterNewConstForm.LastName} <OnHoverIconInfo
                                                                message={RegistrationForm.LastName} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder={Constvariable.YourLastName}
                                                            name="LastName"
                                                            value={this.state.userFields.LastName}
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleValidationOnBlur} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.LastName}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12 setHoverDiv" for="PhoneNumber">
                                                            {RegisterNewConstForm.PhoneNumber} <OnHoverIconInfo
                                                                message={RegistrationForm.PhoneNumber} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder={Constvariable.YourPhoneNumber}
                                                            name="PhoneNumber"
                                                            maxLength="12"
                                                            value={this.state.userFields.PhoneNumber}
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleValidationOnBlur} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.PhoneNumber}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12 setHoverDiv" for="Password">
                                                            {RegisterNewConstForm.Password}  <OnHoverIconInfo
                                                                message={RegistrationForm.Password} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                                            <input
                                                                style={{ width: "90%", margin: 0 }}
                                                                type={isPassword ? "password" : "text"}
                                                                placeholder={Constvariable.Password}
                                                                name="Password"
                                                                value={this.state.userFields.Password}
                                                                onChange={this.handleChange}
                                                                onBlur={this.handleValidationOnBlur}
                                                                onFocus={this.validatePass} />
                                                            <i class={!isPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isPassword: !isPassword })} />
                                                        </div>
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.Password}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <p className="col-sm-12 textcolor" for="Password" style={{ fontWeight: 'bold' }}>
                                                            {RegisterNewConstForm.ConfirmPassword} <OnHoverIconInfo
                                                                message={RegistrationForm.Password} /> <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                                            <input
                                                                style={{ width: "90%", margin: 0 }}
                                                                type={isConfirmPassword ? "password" : "text"}
                                                                placeholder={Constvariable.ConfirmPassword}
                                                                name="ConfirmPassword"
                                                                className="float-none"
                                                                value={this.state.userFields.ConfirmPassword}
                                                                onChange={this.handleChange}
                                                                onBlur={this.handleValidationOnBlur} />
                                                            <i class={!isConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isConfirmPassword: !isConfirmPassword })} />
                                                        </div>
                                                        <div className="col-sm-12 errorMsg">
                                                            {this.state.errors.ConfirmPassword}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6" style={{ color: "black" }}>
                                                        <div className="col-sm-12 setHoverDiv" for="Email">
                                                            {RegisterNewConstForm.SelectCountry}  <OnHoverIconInfo
                                                                message={RegistrationForm.SelectCountry} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <ReactFlagsSelect
                                                            className="menu-flags"
                                                            name="CountryCode"
                                                            selected={this.state.countryFields.CountryCode}
                                                            style={{ color: "black" }}
                                                            countries={this.state.countryData}
                                                            placeholder={Constvariable.R_Country}
                                                            selectedSize={18}
                                                            optionsSize={16}
                                                            onSelect={this.onSelectFlag} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.CountryCode}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12 setHoverDiv" for="Email">
                                                            {RegisterNewConstForm.Address}  <OnHoverIconInfo
                                                                message={RegistrationForm.Address} />
                                                        </div>
                                                        <GooglePlacesAutocomplete
                                                            apiKey={GOOGLE_PLACE_KEY}
                                                            selectProps={{
                                                                value: this.state.selectedAddress,
                                                                onChange: (setAddress) => this.handelAddress(setAddress),
                                                                isClearable: true,
                                                                placeholder: Constvariable.R_Address,
                                                                styles: colourStyles
                                                            }}
                                                            autocompletionRequest={{
                                                                componentRestrictions: {
                                                                    country: this.state.countryFields.CountryCode ? [this.state.countryFields.CountryCode] : [],
                                                                }
                                                            }} />
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12 setHoverDiv" for="Email">
                                                            {RegisterNewConstForm.PostalCode}  <OnHoverIconInfo
                                                                message={RegistrationForm.PostalCode} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            onChange={this.handleCountryChange}
                                                            name="PostalCode"
                                                            value={this.state.countryFields.PostalCode}
                                                            placeholder={Constvariable.Postalcode}
                                                            onBlur={this.handleValidationCountryonBlur} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.PostalCode}
                                                        </div>
                                                    </div>

                                                    {/*<div className="reg_form_main offset-sm-3 col-sm-6">*/}
                                                    {/*    <div className="col-sm-12 setHoverDiv" for="Email">*/}
                                                    {/*        {RegisterNewConstForm.SelectPlaza}  <OnHoverIconInfo*/}
                                                    {/*            message={RegistrationForm.SelectPlaza} /> &nbsp;*/}
                                                    {/*        <span className="fontc-gray">{RegisterNewConstForm.Screen4Msg2} <a className="theme-color" onClick={() => this.handalPlazaRequestModal()}>{RegisterNewConstForm.Scrren4Msg3} </a>*/}
                                                    {/*        </span>*/}
                                                    {/*    </div>*/}
                                                    {/*    <Select*/}
                                                    {/*        value={selectedOption}*/}
                                                    {/*        isClearable={true}*/}
                                                    {/*        menuPortalTarget={document.querySelector('body')}*/}
                                                    {/*        onChange={this.handleOnPlazaSelect}*/}
                                                    {/*        options={options}*/}
                                                    {/*        styles={colourStyles}*/}
                                                    {/*        placeholder={Constvariable.Plaza} />*/}
                                                    {/*    <div className="col-md-12 errorMsg">*/}
                                                    {/*        {this.state.errors.TenantEmail}*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="col-md-12 alert-success">*/}
                                                    {/*        {this.state.errors.plazaaddmsg}*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12">
                                                            <p>
                                                                {RegisterNewConstForm.Note}
                                                            </p>
                                                            <span className="text-justify">
                                                                {RegisterNewConstForm.Screen3Msg}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {/* Google Captcha Code */}
                                                    {/* <div className="reg_form_main offset-sm-3 col-sm-6 text-center">
                                                        {iscaptchreload && <ReCAPTCHA
                                                            style={{ display: "inline-block" }}
                                                            // theme="dark"
                                                            // ref={this._reCaptchaRef}
                                                            size="normal"
                                                            sitekey={GOOGLESITEKEY}
                                                            onChange={this.handleGoogleRecaptcha}
                                                        // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                                        />}
                                                    </div> */}

                                                    {/* Native Captcha Code */}
                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12 setHoverDiv" for="Email">
                                                            {RegisterNewConstForm.CaptchaCode}  <OnHoverIconInfo
                                                                message={RegisterNewConstForm.CaptchaCode} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder={RegisterNewConstForm.CaptchaCode}
                                                            name="captchacode"
                                                            className="float-none m-0 mb-2"
                                                            value={this.state.userFields.captchacode}
                                                            maxLength="6"
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleValidationOnBlur} />
                                                        <div className="col-sm-12 errorMsg mb-2">
                                                            {this.state.errors.captchacode}
                                                        </div>
                                                        <div className="text-center">
                                                            <ACaptcha
                                                                font={'30px Arial'}
                                                                align={'center'}
                                                                baseline={'middle'}
                                                                width={200}
                                                                height={50}
                                                                bgColor={Color.THEME_COLOR}
                                                                color={Color.WHITE}
                                                                captchstr={captchWord} />
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <center>
                                                            <div className="errorMsg">
                                                                {this.state.errors.ErrorC}
                                                            </div>
                                                        </center>
                                                    </div>

                                                    <div className="bottam_sing">
                                                        <button
                                                            type="button"
                                                            for="step1"
                                                            id="continue-step1"
                                                            style={{ background: Color.THEME_COLOR }}
                                                            onClick={this.handleSubmit}
                                                            className="btn btn-done"
                                                            disabled={!iscaptchwordMatch /* !googleReCaptch */} /* Google Code Captcha */
                                                        > {issignupwithgooogle ? RegisterNewConstForm.Continue : RegisterNewConstForm.REGISTER}</button>
                                                    </div>

                                                    <div className="reg_form_main mt-2">
                                                        <center><a href={RouteMerchant.Login}
                                                            className="col-sm-12 theme-color font-wight-bold">{RegisterNewConstForm.AlreadyRegistered}</a></center>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6 settextalignterms textcolor">
                                                        By creating an account or logging in, you agree to Brewersnearme's
                                                        <a href={RouteMerchant.TermsOfService} target="_blank"
                                                            className="theme-color font-wight-bold d-block">Terms & Conditions</a><a tabIndex="" href={RouteMerchant.PrivacyPolicy}
                                                                className="theme-color font-wight-bold d-block" target="_blank">Privacy Policy.</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {/* <div id="part1">
                                        <div className="sub_form_reg_one">
                                            <div id="home" className="tab-pane">
                                                <div className="as_user_form">

                                                    <div className="reg_form_main offset-sm-3 col-sm-6" style={{ color: "black" }}>
                                                        <div className="col-sm-12 setHoverDiv" for="Email">
                                                            {RegisterNewConstForm.SelectCountry}  <OnHoverIconInfo
                                                                message={RegistrationForm.SelectCountry} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <ReactFlagsSelect
                                                            className="menu-flags"
                                                            name="CountryCode"
                                                            selected={this.state.countryFields.CountryCode}
                                                            style={{ color: "black" }}
                                                            countries={this.state.countryData}
                                                            placeholder={Constvariable.R_Country}
                                                            selectedSize={18}
                                                            optionsSize={16}
                                                            onSelect={this.onSelectFlag} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.CountryCode}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                                        <div className="col-sm-12 setHoverDiv" for="Email">
                                                            {RegisterNewConstForm.Address}  <OnHoverIconInfo
                                                                message={RegistrationForm.Address} />
                                                        </div>
                                                        <GooglePlacesAutocomplete
                                                            apiKey={GOOGLE_PLACE_KEY}
                                                            selectProps={{
                                                                value: this.state.selectedAddress,
                                                                onChange: (setAddress) => this.handelAddress(setAddress),
                                                                isClearable: true,
                                                                placeholder: Constvariable.R_Address,
                                                                styles: colourStyles
                                                            }}
                                                            autocompletionRequest={{
                                                                componentRestrictions: {
                                                                    country: this.state.countryFields.CountryCode ? [this.state.countryFields.CountryCode] : [],
                                                                }
                                                            }} />
                                                    </div>

                                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                                        <div className="col-sm-12 setHoverDiv" for="Email">
                                                            {RegisterNewConstForm.PostalCode}  <OnHoverIconInfo
                                                                message={RegistrationForm.PostalCode} /> <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            onChange={this.handleCountryChange}
                                                            name="PostalCode"
                                                            value={this.state.countryFields.PostalCode}
                                                            placeholder={Constvariable.Postalcode}
                                                            onBlur={this.handleValidationCountryonBlur} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.PostalCode}
                                                        </div>
                                                    </div>

                                                    <div className="bottam_sing">
                                                        <label
                                                            for="step1"
                                                            id="back-step0"
                                                            style={{ background: Color.THEME_COLOR }}
                                                            className="btn btn-done btn-fw" onClick={() => this.handleBackBtn(1)}>
                                                            {RegisterNewConstForm.PREV}
                                                        </label>
                                                        <label
                                                            for="step2"
                                                            id="continue-step2"
                                                            style={{ background: Color.THEME_COLOR }}
                                                            onClick={this.handleCountrySubmit}
                                                            className="btn btn-done btn-fw"> {RegisterNewConstForm.Continue} </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div id="part2">
                                        <div className="sub_form_reg_one">
                                            <div id="home" className="tab-pane">
                                                <div className="as_user_form">
                                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                                        <div className="col-sm-12 setHoverDiv" for="Email">
                                                            {RegisterNewConstForm.SelectPlaza}  <OnHoverIconInfo
                                                                message={RegistrationForm.SelectPlaza} /> &nbsp;
                                                            <span className="fontc-gray">{RegisterNewConstForm.Screen4Msg2} <a className="theme-color" onClick={() => this.handalPlazaRequestModal()}>{RegisterNewConstForm.Scrren4Msg3} </a>
                                                            </span>
                                                        </div>
                                                        <Select
                                                            value={selectedOption}
                                                            isClearable={true}
                                                            menuPortalTarget={document.querySelector('body')}
                                                            onChange={this.handleOnPlazaSelect}
                                                            options={options}
                                                            styles={colourStyles}
                                                            placeholder={Constvariable.Plaza} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.TenantEmail}
                                                        </div>
                                                        <div className="col-md-12 alert-success">
                                                            {this.state.errors.plazaaddmsg}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                                        <div className="col-sm-12">
                                                            <p>
                                                                {RegisterNewConstForm.Note}
                                                            </p>
                                                            <span className="text-justify">
                                                                {RegisterNewConstForm.Screen3Msg}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                                        <center>
                                                            <div className="errorMsg">
                                                                {this.state.errors.ErrorC}
                                                            </div>
                                                        </center>
                                                    </div>

                                                    <div className="bottam_sing">
                                                        <label
                                                            for="step2"
                                                            id="back-step1"
                                                            style={{ background: Color.THEME_COLOR }}
                                                            className="btn btn-skip btn-fw" onClick={() => this.handleBackBtn(2)}>
                                                            {RegisterNewConstForm.PREV}
                                                        </label>
                                                        <button
                                                            type="button"
                                                            for="step3"
                                                            id="continue-step3"
                                                            style={{ background: Color.THEME_COLOR }}
                                                            className="btn btn-done btn-fw"
                                                            onClick={this.handlePlazaSubmit}
                                                        > {this.state.selectedOption ? RegisterNewConstForm.Continue : RegisterNewConstForm.Continue}</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div id="part3">
                                    <div className="sub_form_reg_one">
                                        <div id="home" className="tab-pane">
                                            <div className="as_user_form">
                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <p>
                                                        {RegisterNewConstForm.Note}
                                                    </p>
                                                    <div className="text-justify">
                                                        {RegisterNewConstForm.Screen4Msg}
                                                    </div>
                                                    <div className="text-justify">
                                                        {RegisterNewConstForm.Screen4Msg1}
                                                    </div>
                                                    <div className="text-justify">
                                                        {RegisterNewConstForm.Screen4Msg2} <a href="mailto:tj24618@gmail.com" target="_blank" className="theme-color">{RegisterNewConstForm.Scrren4Msg3} </a>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        {RegisterNewConstForm.Scrren4Msg4}
                                                    </div>
                                                    <div className="col-sm-12">
                                                        {RegisterNewConstForm.Screen4Msg5}
                                                    </div>
                                                    <div className="col-sm-12">
                                                        {RegisterNewConstForm.Screen4Msg6}
                                                    </div>
                                                    <div className="col-sm-12">
                                                        {RegisterNewConstForm.Screen4Msg7}
                                                    </div>
                                                    <div className="col-sm-12">
                                                        5. Plaza Banner Image file (.jpg, .png, .gif)
                                                    </div>
                                                </div>
                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv">
                                                        {RegisterNewConstForm.PlazaName} <OnHoverIconInfo
                                                            message={RegistrationForm.PlazaName} />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="PlazaName1"
                                                        onChange={this.handleChangePlaza}
                                                        value={this.state.plazaFields.PlazaName1}
                                                        placeholder={Constvariable.R_PlazaName}

                                                    />
                                                    <div className="col-md-12 errorMsg">
                                                        {this.state.errors.PlazaName1}
                                                    </div>
                                                </div>
                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv">
                                                        {RegisterNewConstForm.PlazaEmail} <OnHoverIconInfo
                                                            message={RegistrationForm.PlazaEmail} />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="Email"
                                                        onChange={this.handleChangePlaza}
                                                        value={this.state.plazaFields.Email}
                                                        placeholder={Constvariable.R_PlazaEmail}

                                                    />
                                                    <div className="col-md-12 errorMsg">
                                                        {this.state.errors.Email}
                                                    </div>
                                                </div>
                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv">
                                                        {RegisterNewConstForm.PlazaPhoneNumber} <OnHoverIconInfo
                                                            message={RegistrationForm.PlazaPhoneNumber} />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="PlazaPhoneNumber"
                                                        maxLength="12"
                                                        onChange={this.handleChangePlaza}
                                                        value={this.state.plazaFields.PlazaPhoneNumber}
                                                        placeholder={Constvariable.R_PlazaPhoneNumber}

                                                    />
                                                    <div className="col-md-12 errorMsg">
                                                        {this.state.errors.PlazaPhoneNumber}
                                                    </div>
                                                </div>
                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv">
                                                        {RegisterNewConstForm.PlazaAddress} <OnHoverIconInfo
                                                            message={RegistrationForm.PlazaAddress} />
                                                    </div>
                                                    <GooglePlacesAutocomplete
                                                        apiKey={GOOGLE_PLACE_KEY}
                                                        selectProps={{
                                                            value: this.state.selectedPlazaAddress,
                                                            onChange: (setAddress) => this.handelPlazaAddress(setAddress),
                                                            isClearable: true,
                                                            menuPortalTarget: document.body,
                                                            placeholder: Constvariable.R_PlazaAddress,
                                                            styles: colourStyles
                                                        }}
                                                        autocompletionRequest={{
                                                            componentRestrictions: {
                                                                country: this.state.countryFields.CountryCode ? [this.state.countryFields.CountryCode] : [],
                                                            }
                                                        }}
                                                    />
                                                    <div className="col-md-12 errorMsg">
                                                        {this.state.errors.Address}
                                                    </div>
                                                </div>
                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv" for="Email">
                                                        {RegisterNewConstForm.PlazaPostalCode}  <OnHoverIconInfo
                                                            message={RegistrationForm.PlazaPostalCode} />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        onChange={this.handleChangePlaza}
                                                        name="PostalCode"
                                                        value={this.state.plazaFields.PostalCode}
                                                        placeholder={Constvariable.R_PlazaPostalcode}

                                                    />
                                                    <div className="col-md-12 errorMsg">
                                                        {this.state.errors.PlazaPostalCode}
                                                    </div>
                                                </div>
                                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                                    <div className="col-sm-12 setHoverDiv">
                                                        {RegisterNewConstForm.UploadImage} <OnHoverIconInfo
                                                            message={RegistrationForm.UploadImage} />
                                                    </div>
                                                    <center>
                                                        <img
                                                            style={{ margin: "10px 0px" }}
                                                            className="profilePic"
                                                            src={this.state.src} />
                                                    </center>
                                                    <div className="col-sm-12">
                                                        <div className="btn-upload w-100 mw-100">
                                                            <label className="btn w-100 fontc-gray" for="updateCover" style={{ padding: "4px 0px 0px 0px ", background: Color.GREY }}>
                                                                {RegisterNewConstForm.Upload}
                                                            </label>
                                                            <input
                                                                type="file"
                                                                id="updateCover"
                                                                style={{ opacity: "0" }}
                                                                data-id="cover"
                                                                className="hideAction"
                                                                accept=".jpg , .jpeg , .bmp , .gif , .png"
                                                                onChange={this.onSelectFile} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 errorMsg">
                                                        {this.state.errors.src}
                                                    </div>
                                                </div>
                                                <div className="bottam_sing">
                                                    <label
                                                        for="step3"
                                                        id="back-step2"
                                                        style={{ background: Color.THEME_COLOR }}
                                                        className="btn btn-skip btn-fw" onClick={() => this.handleBackBtn(3)}>
                                                        {RegisterNewConstForm.PREV}
                                                    </label>
                                                    <label
                                                        for="step4"
                                                        id="continue-step4"
                                                        style={{ background: Color.THEME_COLOR }}
                                                        onClick={this.handlePlazaSubmit}
                                                        className="btn btn-done btn-fw"
                                                    >
                                                        {this.validatePlazaRender() ? RegisterNewConstForm.Continue : RegisterNewConstForm.Skip}
                                                        {RegisterNewConstForm.Continue}
                                                    </label>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                    {steppage == 2 && <div /* id="part1" */>
                                        <div className="sub_form_reg_one">
                                            <div id="home" className="tab-pane">
                                                <div className="as_user_form">
                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12 setHoverDiv" for="otp">
                                                            {RegisterNewConstForm.Enterotp} <OnHoverIconInfo
                                                                message={RegistrationForm.Enterotp} />
                                                            <PutMandatoryfieldSymbol />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputmode="numeric"
                                                            placeholder={Constvariable.OtpNumber}
                                                            name="otp"
                                                            maxLength="6"
                                                            value={this.state.otp.otp}
                                                            onChange={this.handleValidateOtpChange}
                                                            onBlur={this.handleValidationOTPonBlur} />
                                                        <div className="col-md-12 errorMsg">
                                                            {this.state.errors.otp}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main offset-md-3 col-md-6">
                                                        <div className="col-sm-12">
                                                            <p>
                                                                {RegisterNewConstForm.Note}
                                                            </p>
                                                            <span className="text-justify">
                                                                {Messages.v_sent_otp_message}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="bottam_sing">
                                                        <label
                                                            for="step1"
                                                            id="continue-step1"
                                                            style={{ background: Color.THEME_COLOR }}
                                                            onClick={this.handleValidateOtpSubmit}
                                                            className="btn btn-done"
                                                        >  {RegisterNewConstForm.SUBMIT}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    <div class="modal" id="myModalWithPlaza">
                                        <div class="modal-dialog setmodaldilog">
                                            <div class="modal-content">
                                                <div class="modal-body">
                                                    <center>
                                                        <h5>{Messages.popupPlazaTitle}</h5>
                                                    </center>

                                                    <div className="reg_form_main col-sm-10 offset-sm-1" >
                                                        <label className="label-color">{Messages.popupPlazaMsg1}</label>
                                                        <label className="label-color">{Messages.popupPlazaMsg2}</label>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <label
                                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                                        data-dismiss="modal"
                                                        onClick={() => this.closeModal()}
                                                    > Ok</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Plaza new Request Dailog */}
                                    <div class="modal" id="plazarequestmodel">
                                        <div class="modal-dialog setmodaldilog">
                                            <div class="modal-content">
                                                <div class="modal-body">
                                                    <center>
                                                        <h5>{Messages.newpopupPlazaTitle}</h5>
                                                    </center>

                                                    <div className="reg_form_main col-12">
                                                        <p className="col-sm-12" for="requestplazaname">
                                                            {AddPlazaConstForm.PlazaName} <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <input
                                                            type="text"
                                                            placeholder={AddPlazaConstForm.PlazaName}
                                                            name="requestplazaname"
                                                            value={this.state.userFields.requestplazaname}
                                                            onChange={this.handleChange} />
                                                        <div className="errorMsg">
                                                            {this.state.errors.requestplazaname}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main col-12">
                                                        <p className="col-sm-12" for="requestplazaaddress">
                                                            {AddPlazaConstForm.Address} <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <textarea
                                                            style={{ borderRadius: '10px' }}
                                                            type="text"
                                                            name="requestplazaaddress"
                                                            className='add-item-textarea'
                                                            placeholder={AddPlazaConstForm.Address}
                                                            value={this.state.userFields.requestplazaaddress}
                                                            onChange={this.handleChange} />
                                                        <div className="errorMsg">
                                                            {this.state.errors.requestplazaaddress}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <label
                                                        style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                                        data-dismiss="modal"
                                                        onClick={() => this.SavePlazaRequestModel()}>
                                                        {AddPlazaConstForm.AddRequestLabel}</label>
                                                    <label
                                                        style={{ color: Color.BLACK, marginLeft: "auto", marginRight: "auto" }}
                                                        data-dismiss="modal"
                                                        onClick={() => this.closePlazaRequestModal()}
                                                    >{AddPlazaConstForm.CloseLabel}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <MainFooter />
        </LoadingOverlay>
        );
    }
}
export default RegisterNew;