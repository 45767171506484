
const RegistrationForm = {
    "Email": "Email",
    "Name": "First Name",
    "LastName": "Last Name",
    "PhoneNumber": "Phone Number",
    "PlazaPhoneNumber": "Plaza Phone Number",
    "Password": "e.g. Test@123",
    "PostalCode": "Postal Code",
    "SelectPlaza": "Select Plaza",
    "Enterotp": "Your One Time Code has been sent to the e-mail associated with your account.",
    "PlazaName": "Plaza Name",
    "PlazaEmail": "Plaza Email",
    "Address": "Address",
    "PlazaAddress": "Plaza Address",
    "PlazaPostalCode": "Plaza Postal Code",
    "UploadImage": "Upload Image",
    "SelectCountry": "Choose the country and address for your business.",
    ConfirmPassword: "Confirm Password",
}

export const AddPlazaForm = {
    "PlazaName": "Plaza Name",
    "PostalCode": "Postal Code",
    "PlazaEmail": "Plaza Email",
    "PhoneNumber": "Phone Number",
    "Address": "Address",
    "UploadImage": "Upload Image"
}

export const StoreFrom = {
    "RegisterStore": "Choose the platform from which you would like to register your brewery.",
    "ShopifyStoreName": "Entre Shopify Brewery Name",
    "AccessToken": "Enter Access Token",
    "StoreName": "Enter Brewery Name",
    "Storecategory": "Brewery Category",
    "StoreOpen": "Enter something About",
    "PhoneNumber": "Enter Phone Number",
    "Website": "Enter Website URl",
    "FacebookUser": "Enter Facebook User",
    "City": "Enter City Name",
    "StateName": "State Name",
    "CountryName": "Country Name",
    "Address": "Select Address",
    "StoreAddress": "Select Brewery Address",
    "PhysicalReceipts": "Enter something About",
    "StoreLogo": "Upload Brewery Logo",
    "StoreBanner": "Upload Brewery Banner",
    "boxesInfo": "Enter something About",
    "SelectPlaza": "Select Plaza",
    "PostalCode": "Postal Code"
}

export const StoreList = {
    "Publishapp": "Click to Change plan",
    "MakePayment": "Click to make payment",
    "Inreview": "Click to view in detail",
    "GoToDashboard": "Go to Dashboard"
}

export const CategoryForm = {
    "ProductCategory": "Enter Product Category",
    "ParentCategory": "Select Parent Category",
    "CategoryCustomizable": "Select Category Customizable",
    "CustomizationMessage": 'Enter Customization Message',
    "ApplicableTax": "Applicable Tax (%)"
}

export const ProductForm = {
    "ProductName": "Enter Product Name",
    "ProductCategory": "Select Product Category",
    "ProductPrice": "Product Price",
    "ProductDescription": "Product Generic Description",
    "ProductDescription1": "Bottom : Product Name/Style 30 (Fit,Fabric,Waist Size : 30 in, Length Size: 37 in)",
    "ProductDescription2": "Dresses: Product Name/Style (Fit,Fabric,Bust Size : 36 in, Length Size: 38 in)",
    "InternalProductId": "Product internal id is managed by the merchant and is fully utilised for catalogue management.",
    "ProductType": "Product Measurement Options",
    "ProductTypeWithToolTip1": "Select the option that applies to your product.",
    "ProductInternalIdNote": "Product internal id is managed by the merchant and is fully utilised for catalogue management.",
    "ProductTypeWithToolTip2": "The weight type is applied to the weight-based product.",
    "ProductTypeWithToolTip3": "The quantity type is applied to the quantity-based product.",
    "ProductSize": "Add/Select Product Size",
    "ProductColor": "Select Product Color",
    "ProductSizeColorDescription": "Product Size, Color, and Description",
    "ProductWeightUnit": "Select Product Weight Unit",
    "ProductWeight": "Product Weight",
    "CostPerItem": "Product Cost",
    "ToppingMinimumQuantity": "Enter Minimum Topping Quantity",
    "ToppingMaximumQuantity": "Enter Maximum Topping  Quantity",
    "RewardPoint": "Reward Point",
    "Tax": "Tax",
    "IsProductTaxFree": "Select yes, if the product is a tax exempted.",
    "SKU": "SKU (Stock Keeping Unit)",
    "BarCode": "UPC (Universal Product Code)",
    "AvailableQuantity": "Available Quantity",
    "Others": "Add Others Information",
    "TrackQuantity": "Select Track Quantity",
    "ContinueSelling": "Continue Selling",
    "SpecialDeliveryProduct": "Special Delivery Product",
    "UploadImage": "Upload Product Image",
    "ProductWithColorQuantity": "Product Available Color And Quantity",
    "ProductColor": "Product Color",
    "ProductQuntity": "Product Quntity",
    "ApplicableTax": "Applicable Tax (%)"
}

export const ToppingsCategoryForm = {
    "ToppingCategory": "Enter Topping Category"
}

export const ToppingsForm = {
    "ToppingName": "Enter Topping Name",
    "ToppingPrice": "Enter Topping Price"
}

export const StoreSupplierForm = {
    "SupplierName": "Enter Supplier Name",
    "Address": "Address",
    "ApartmentName": "Apartment Name",
    "CityName": "City Name",
    "StateName": "State Name",
    "CountryName": "Country Name",
    "PostalCode": "Postal Code",
    "ContactName": "Contact Name",
    "Email": "Email",
    "PhoneNumbe": "Phone Number"
}

export const UpdateInventoryForm = {
    "QuantityMinus": "Remove Quantity",
    "QuantityAdd": "Add Quantity",
    "NewQuantity": "Add New Quantity",
    "OriginalQuantity": "Original Quantity",
    "ProductDescription": "Add Product Description"
}

export const ReceiveInventoryForm = {
    "Product": "Select Product",
    "SupplierSKU": "Enter Supplier SKU (Stock Keeping Unit)",
    "AcceptQuantity": "Accept Quantity",
    "RejectQuantity": "Reject Quantity"
}

export const AddPickuptimesForm = {
    "PickupTimeFrom": "Select Pickup Time (From)",
    "PickupTimeTo": "Select Pickup Time (To)"
}

export const AddDeliverytimesForm = {
    "DeliveryTimeFrom": "Select Delivery Time (From)",
    "DeliveryTimeTo": "Select Delivery Time (To)",
    // "DeliveryCost": "Enter Delivery Cost"
}

export const EarningsForm = {
    "From": "Enter From Date",
    "To": "Enter To Date"
}

export const AddPurchaseOrderForm = {
    "Supplier": "Supplier",
    "PaymentTerms": "Payment Terms",
    "SupplierCurrency": "Supplier Currency",
    "NotetoSupplier": "Note to Supplier",
    "EstimateArrival": "Estimate Arrival",
    "TrackingNumber": "Tracking Number",
    "ShippingCenter": "Shipping Center",
    "Product": "Select Product",
    "SupplierSKU": "Enter Supplier SKU (Stock Keeping Unit)",
    "Quantity": "Enter Quantity",
    "Cost": "Enter Cost",
    "Tax": "Tax",
    "ShippingCharge": "Add Shipping Charge",
    "Adjustment": "Adjustment",
    "AdjustmentAmount": "Adjustment Amount",
    "TotalTax": "Enter Total Tax",
    "Total": "Total"
}

export const UserProfileForm = {
    "FirstName": "First Name",
    "LastName": "Last Name",
    "Phone": "Phone Number",
    "Company": "Company Name",
    "PostalCode": "Postal Code",
    "Address": "Address",
    "CountryCode": "Country Code",
    "eMail": "Email",
    "PlazaEmail": "Plaza Email",
    "PlazaName": "Plaza Name"
}

export const AddCouponForm = {
    "Title": "Title",
    "Description": "Description",
    "StartDate": "Start Date",
    "EndDate": "End Date",
    "DicountPrice": "Discount Price",
    "MinimumRequiredAmount": "Minimum Order Amount Required To Avail This Coupon",
    "UploadImage": "Upload Coupon Logo"
}

export const RedemptionForm = {
    "StartDate": "Start Date",
    "EndDate": "End Date",
}

export const DoordashSetupForm = {
    "DeveloperId": "Developer Id",
    "KeyId": "Key Id",
    "SigningSecret": "Signing Secret",
}

export const DeliverytimesForm = {
    "DeliveryOption": "Delivery Option",
    "DefaultMiles": "Unit Miles/KM",
    "DefaultMilesFees": "Unit Miles/KM Fees",
    "ExtraPerMileFees": "Extra Unit Per Mile/KM Fees",
    "DoordashBusiness": "Select Doordash Business"
}

export const AddUserFrom = {
    "Name": "First Name",
    "LastName": "Last Name",
    "Email": "Email",
    "Password": "e.g. Test@123",
    "PhoneNumber": "Phone Number",
    "PostalCode": "Postal Code",
    "SelectCounty": "Select County",
    "Enterotp": "Enter one time code"
}

export const UserManagementList = {
    "Enterotp": "Enter one time code"
}

export const StoreHourList = {
    "Starthour": "Select Start Hour",
    "Endhour": "Select End Hour"
}

export default RegistrationForm