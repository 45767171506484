import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants from '../../Config/Constants'
import Select from 'react-select';
import Constvariable from '../../Config/constvariable';
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage, validateFloat, getTimeRanges, TimeToHours } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import { MY_STORE_API, GET_DELIVERY_TIME, GET_TOPPING_CATEGORY, PLAZAID, DELIVERYOPTIONS_MERCHANT_API, DELIVERYOPTIONS_API, STOREHOURS_API, SETUPSTOREDELIVERYHOURS_API, SETUPSTOREPICKUPHOURS_API, DELIVERYOPTIONS_DOORDASH_BUSSINESS_API } from '../../Config/Config.json'
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import { PostData } from '../../../services/PostData';
import Color from '../../Config/Color';
import { DeleteData } from '../../../services/DeleteData';
import RouteMerchant from '../../Config/RouteMerchant.js';
import OnHoverIconInfo, { KmAndMilesCollection, KmAndMilesFessCollection, ExtraMilesCollection } from "../../UtillCustomComponent/UtillCustomComponent"
import { DeliverytimesConst, AddDeliverytimesConstForm, AddPickuptimesConstForm } from '../../Config/ConstForm';
import { DeliverytimesForm, AddDeliverytimesForm, AddPickuptimesForm } from '../../Config/HoverMessage';
import { deliveryDataSet, pickupDataSet } from "../../UtillCustomComponent/UtillCustomComponent";
import { PutMandatoryfieldSymbol } from '../../UtillCustomComponent/UtillCustomComponent';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important" }),
    menuPortal: styles => ({ ...styles, zIndex: "1080" })
};

const plazaCartObj = {
    DeliveryOption: "Brewery Delivery",
    DeliveryOptionkey: "PLAZACART"
}

class Deliverytimes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            hideEmptyPlazaView: true,
            isActive: false,
            plazaId: "",
            storeId: "",
            deliveryTimeList: [],
            deliveryTimeId: '',

            merchantConfigs: [],
            selectedService: plazaCartObj,
            IsPickupEnable: false,
            IsDeliveryEnable: false,
            IsfreeDelivery: false,
            userFields: {},
            errors: {},
            storeConfigDetail: null,

            defaultMilesOption: KmAndMilesCollection(),
            selectedDefultMiles: null,

            defaultMilesOptionFess: KmAndMilesFessCollection(),
            selectedDefultMilesFess: null,

            extraMilesOption: ExtraMilesCollection(),
            selectedExtra: null,

            selectedHeader: 0,
            timeOptions: deliveryDataSet(),
            masterTimeOption: [],

            editDayIndex: -1,
            EditTimeList: [],

            clockList: [],
            selectedFrom: null,
            selectedTo: null,
            isSubmit: false,

            storeHourData: [],

            doordashBusinessList: [],
            selectedDoordashBusiness: null
        }
        this.handleDeliveryTimes = this.handleDeliveryTimes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        StoreObjectInStorage(Constants.DELIVERYTIME_OBJ, '')
        if (storeObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = storeObj.plazaid
            this.setState({ storeId: storeId, plazaId: plazaId });
            this.getStoreHours(authToken, storeId)
        } else {
            this.props.history.goBack()
        }
        console.log(authToken);
        this.getMerchantConfig(authToken);
    }

    getMerchantConfig(authToken) {
        this.setState({ isActive: true, errors: {} });
        var URL = DELIVERYOPTIONS_MERCHANT_API;
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        responseData.unshift(plazaCartObj);
                    }
                    this.setState({ merchantConfigs: responseData ? responseData : [] })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });

                this.getDoorDashBusinessList(authToken)
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
                this.getDoorDashBusinessList(authToken)
            });
    }

    ////////// doordash business change //

    getDoorDashBusinessList(authToken) {
        this.setState({ isActive: true, errors: {} });
        var URL = DELIVERYOPTIONS_DOORDASH_BUSSINESS_API;
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        let lst = responseData.result ? responseData.result : [];
                        var doordashBusinessList = [{
                            external_business_id: "",
                            name: "Select business -OR- Create business"
                        }];
                        doordashBusinessList = [...doordashBusinessList, ...lst]
                        this.setState({ doordashBusinessList: doordashBusinessList })
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
                let storeId = this.state.storeId;
                if (storeId) {
                    this.getStoreConfig(authToken, storeId);
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
                let storeId = this.state.storeId;
                if (storeId) {
                    this.getStoreConfig(authToken, storeId);
                }
            });
    }

    ///////////////////////////////////////////

    getStoreConfig(authToken, storeId) {
        this.setState({ isActive: true, errors: {} });
        var URL = MY_STORE_API + "/" + storeId + "/" + DELIVERYOPTIONS_API;
        console.log(URL)
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    this.setDetail(responseData);
                    this.setState({ storeConfigDetail: responseData })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }



    setDetail(responseData) {
        if (responseData) {
            let userFields = this.state.userFields;
            let defaultmilesString = "", defaultmilesFessString = "", extrapermilefeesString = ""
            if (responseData.deliveryfees) {
                defaultmilesString = responseData.deliveryfees.defaultmiles ? String(responseData.deliveryfees.defaultmiles) : "0";
                defaultmilesFessString = responseData.deliveryfees.defaultmilesfees ? String(responseData.deliveryfees.defaultmilesfees) : "0";
                extrapermilefeesString = responseData.deliveryfees.extrapermilefees ? String(responseData.deliveryfees.extrapermilefees) : "0";
            }
            var selectedService = this.state.merchantConfigs.filter(obj => obj.DeliveryOptionkey == responseData.DeliveryOption)[0];
            let selectedDefultMiles = this.state.defaultMilesOption.filter(obj => obj.value == defaultmilesString)[0]
            let selectedDefultMilesFess = this.state.defaultMilesOptionFess.filter(e => e.value == defaultmilesFessString)[0]
            let selectedExtra = this.state.extraMilesOption.filter(e => e.value == extrapermilefeesString)[0]

            let selectedDoordashBusiness = this.state.doordashBusinessList.filter(e => e.external_business_id == responseData.doordashbussinessexternalid)[0]

            this.setState({
                userFields,
                selectedService: selectedService ? selectedService : plazaCartObj,
                IsPickupEnable: responseData.IsPickupEnable,
                IsDeliveryEnable: responseData.IsDeliveryEnable,
                IsfreeDelivery: responseData.deliveryfees ? responseData.deliveryfees.IsfreeDelivery : false,

                selectedDefultMiles: selectedDefultMiles ? selectedDefultMiles : null,
                selectedDefultMilesFess: selectedDefultMilesFess ? selectedDefultMilesFess : null,
                selectedExtra: selectedExtra ? selectedExtra : null,
                selectedDoordashBusiness: selectedDoordashBusiness ? selectedDoordashBusiness : null
            })
        }
    }

    getStoreHours(authToken, storeId) {
        this.setState({ isActive: true })
        var URL = MY_STORE_API + "/" + storeId + "/" + STOREHOURS_API;
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ masterTimeOption: responseData });
                        let timeOptions = this.state.timeOptions.map(e => {
                            let index = responseData.findIndex(e1 => e1.day == e.day)
                            if (index >= 0) {
                                if (this.state.selectedHeader == 0) {
                                    return {
                                        ...e,
                                        "isdeliveryenable": responseData[index].isdeliveryenable,
                                        "deliverytimes": responseData[index].deliverytimes ? responseData[index].deliverytimes : []
                                    }
                                }
                                else if (this.state.selectedHeader == 1) {
                                    return {
                                        ...e,
                                        "ispickupenable": responseData[index].ispickupenable,
                                        "pickuptimes": responseData[index].pickuptimes ? responseData[index].pickuptimes : []
                                    }
                                }
                            }
                            return e
                        })
                        this.setState({ timeOptions: timeOptions })
                    }
                    else {
                        let errors = {};
                        errors["ErrorC"] = Messages.NO_DATA;
                        this.setState({ errors: errors });
                    }

                    this.setState({ storeHourData: responseData ? responseData : [] })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, storeHourData: [] });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handlePickupChbox() {
        this.setState({ IsPickupEnable: !this.state.IsPickupEnable })
        this.postPickupCh(!this.state.IsPickupEnable)

    }

    postPickupCh(flag) {
        var postData = {
            "storeid": this.state.storeId,
            "IsPickupEnable": flag,
            "IsDeliveryEnable": false,
            "DeliveryOption": "PLAZACART",
            "IsPlazaCartDeliveryEnable": false,
            "deliveryfees": {
                "IsfreeDelivery": false,
                "defaultmiles": 5,
                "defaultmilesfees": 9.75,
                "extrapermilefees": 0.75
            }
        }
        let responseData = this.state.storeConfigDetail;
        if (responseData) {
            if (responseData.deliveryfees) {
                postData["deliveryfees"]["defaultmiles"] = responseData.deliveryfees.defaultmiles ? String(responseData.deliveryfees.defaultmiles) : "0";
                postData["deliveryfees"]["defaultmilesfees"] = responseData.deliveryfees.defaultmilesfees ? String(responseData.deliveryfees.defaultmilesfees) : "0";
                postData["deliveryfees"]["extrapermilefees"] = responseData.deliveryfees.extrapermilefees ? String(responseData.deliveryfees.extrapermilefees) : "0";
                postData["deliveryfees"]["IsfreeDelivery"] = responseData.deliveryfees ? responseData.deliveryfees.IsfreeDelivery : false
            }
            var selectedService = this.state.merchantConfigs.filter(obj => obj.DeliveryOptionkey == responseData.DeliveryOption)[0];

            postData["IsDeliveryEnable"] = responseData.IsDeliveryEnable;
            postData["DeliveryOption"] = selectedService ? selectedService.DeliveryOptionkey : plazaCartObj.DeliveryOptionkey;
        }

        console.log("=====> postby deliverych", postData)

        if (postData["storeid"]) {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.postStoreConfig(authToken, postData)
        }
    }

    handleDeliveryChbox() {
        // if (!this.state.IsDeliveryEnable) {
        //     // this.handleModalsShow()
        //     // this.resetErrVal()
        // }
        // else {
        this.postforDelivery(!this.state.IsDeliveryEnable)
        // }
        this.setState({ IsDeliveryEnable: !this.state.IsDeliveryEnable })
    }

    handleConfig = selectedService => {
        if (selectedService.DeliveryOptionkey != plazaCartObj.DeliveryOptionkey) {
            this.setState({ IsfreeDelivery: false })
        }
        this.setState({ selectedService })
    }

    handleDefaultMiles = selectedDefultMiles => {
        this.setState({ selectedDefultMiles })
    }

    handleDefaultMilesFess = selectedDefultMilesFess => {
        this.setState({ selectedDefultMilesFess })
    }

    handleExtratMiles = selectedExtra => {
        this.setState({ selectedExtra })
    }

    handleDoordashBusiness = (selectedDoordashBusiness) => {
        this.setState({ selectedDoordashBusiness })
    }

    postforDelivery(flag) {
        var postData = {
            "storeid": this.state.storeId,
            "IsPickupEnable": true,
            "IsDeliveryEnable": flag,
            "DeliveryOption": "PLAZACART",
            "IsPlazaCartDeliveryEnable": false,
            "deliveryfees": {
                "IsfreeDelivery": false,
                "defaultmiles": 5,
                "defaultmilesfees": 9.75,
                "extrapermilefees": 0.75
            }
        }
        let responseData = this.state.storeConfigDetail;
        if (responseData) {
            if (responseData.deliveryfees) {
                postData["deliveryfees"]["defaultmiles"] = responseData.deliveryfees.defaultmiles ? String(responseData.deliveryfees.defaultmiles) : "0";
                postData["deliveryfees"]["defaultmilesfees"] = responseData.deliveryfees.defaultmilesfees ? String(responseData.deliveryfees.defaultmilesfees) : "0";
                postData["deliveryfees"]["extrapermilefees"] = responseData.deliveryfees.extrapermilefees ? String(responseData.deliveryfees.extrapermilefees) : "0";
                postData["deliveryfees"]["IsfreeDelivery"] = responseData.deliveryfees ? responseData.deliveryfees.IsfreeDelivery : false
            }
            var selectedService = this.state.merchantConfigs.filter(obj => obj.DeliveryOptionkey == responseData.DeliveryOption)[0];

            postData["IsPickupEnable"] = responseData.IsPickupEnable;
            postData["DeliveryOption"] = selectedService ? selectedService.DeliveryOptionkey : plazaCartObj.DeliveryOptionkey;
        }

        console.log("=====> postby deliverych", postData);

        if (postData["storeid"]) {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.postStoreConfig(authToken, postData)
        }
    }

    handlePlazaDeliveryChBox() {
        this.setState({ IsfreeDelivery: !this.state.IsfreeDelivery });
        this.postFreeDelivery(!this.state.IsfreeDelivery);
    }

    postFreeDelivery(flag) {
        var postData = {
            "storeid": this.state.storeId,
            "IsPickupEnable": true,
            "IsDeliveryEnable": true,
            "DeliveryOption": "PLAZACART",
            "IsPlazaCartDeliveryEnable": false,
            "deliveryfees": {
                "IsfreeDelivery": flag,
                "defaultmiles": 5,
                "defaultmilesfees": 9.75,
                "extrapermilefees": 0.75
            }
        }
        let responseData = this.state.storeConfigDetail;
        if (responseData) {
            if (responseData.deliveryfees) {
                postData["deliveryfees"]["defaultmiles"] = responseData.deliveryfees.defaultmiles ? String(responseData.deliveryfees.defaultmiles) : "0";
                postData["deliveryfees"]["defaultmilesfees"] = responseData.deliveryfees.defaultmilesfees ? String(responseData.deliveryfees.defaultmilesfees) : "0";
                postData["deliveryfees"]["extrapermilefees"] = responseData.deliveryfees.extrapermilefees ? String(responseData.deliveryfees.extrapermilefees) : "0";
                // postData["deliveryfees"]["IsfreeDelivery"] = responseData.deliveryfees ? responseData.deliveryfees.IsfreeDelivery : false
            }
            var selectedService = this.state.merchantConfigs.filter(obj => obj.DeliveryOptionkey == responseData.DeliveryOption)[0];

            postData["IsPickupEnable"] = responseData.IsPickupEnable;
            postData["IsDeliveryEnable"] = responseData.IsDeliveryEnable;
            postData["DeliveryOption"] = selectedService ? selectedService.DeliveryOptionkey : plazaCartObj.DeliveryOptionkey;
            postData["DeliveryOption"] = flag ? "PLAZACART" : postData["DeliveryOption"];
        }
        console.log("=====> postby deliverych", postData);
        if (postData["storeid"]) {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.postStoreConfig(authToken, postData)
        }
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    handleDeliveryTimes() {
        this.props.history.push({
            pathname: RouteMerchant.AddDeliverytimes
        })
    }

    handleEditDeliveryTimes(row) {
        StoreObjectInStorage(Constants.DELIVERYTIME_OBJ, row)
        this.props.history.push({
            pathname: RouteMerchant.AddDeliverytimes
        })
    }

    handleEditDeliveryConfig() {
        this.handleModalsShow()
        let storeConfigDetail = this.state.storeConfigDetail;
        this.setDetail(storeConfigDetail)
        this.setState({ errors: {} })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let field = this.state.userFields;

        if (!this.state.IsfreeDelivery && this.state.selectedService.DeliveryOptionkey == plazaCartObj.DeliveryOptionkey) {
            if (!this.state.selectedDefultMiles) {
                formIsValid = false;
                errors["defaultmiles"] = Messages.v_Defaultmiles;
            }
            // else if (this.state.selectedDefultMiles) {
            //     if (!validateFloat(this.state.selectedDefultMiles)) {
            //         formIsValid = false;
            //         errors["defaultmiles"] = Messages.v_DefaultmilesInt;
            //     }
            // }

            if (!this.state.selectedDefultMilesFess) {
                formIsValid = false;
                errors["defaultmilesfees"] = Messages.v_Defaultmilesfees;
            }
            // else if (field["defaultmilesfees"]) {
            //     if (!validateFloat(field["defaultmilesfees"])) {
            //         formIsValid = false;
            //         errors["defaultmilesfees"] = Messages.v_DefaultmilesfeesInt;
            //     }
            // }

            if (!this.state.selectedExtra) {
                formIsValid = false;
                errors["extrapermilefees"] = Messages.v_Extrapermilefees;
            }
            // else if (field["extrapermilefees"]) {
            //     if (!validateFloat(field["extrapermilefees"])) {
            //         formIsValid = false;
            //         errors["extrapermilefees"] = Messages.v_ExtrapermilefeesInt;
            //     }
            // }
        }

        if (!this.state.IsfreeDelivery && this.state.selectedService.DeliveryOptionkey != plazaCartObj.DeliveryOptionkey) {
            let { selectedDoordashBusiness } = this.state

            if (!selectedDoordashBusiness) {
                formIsValid = false;
                errors["selectedDoordashBusiness"] = Messages.v_DoordashBusiness;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAdd(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData["storeid"] = this.state.storeId;
            postData["IsPickupEnable"] = this.state.IsPickupEnable;
            postData["IsDeliveryEnable"] = this.state.IsDeliveryEnable
            postData["DeliveryOption"] = this.state.selectedService.DeliveryOptionkey;
            postData["IsPlazaCartDeliveryEnable"] = this.state.selectedService.DeliveryOptionkey == plazaCartObj.DeliveryOptionkey;

            postData["doordashbussinessexternalid"] = this.state.selectedService.DeliveryOptionkey != plazaCartObj.DeliveryOptionkey ? (this.state.selectedDoordashBusiness ? this.state.selectedDoordashBusiness.external_business_id : "") : ""

            let userFields = this.state.userFields;
            let selectedDefultMiles = this.state.selectedDefultMiles
            let selectedDefultMilesFess = this.state.selectedDefultMilesFess;
            let selectedExtra = this.state.selectedExtra
            postData["deliveryfees"] = {
                "IsfreeDelivery": this.state.IsfreeDelivery,
                "defaultmiles": selectedDefultMiles ? selectedDefultMiles.value : 0,
                "defaultmilesfees": selectedDefultMilesFess ? selectedDefultMilesFess.value : 0,
                "extrapermilefees": selectedExtra ? selectedExtra.value : 0
            }

            console.log("======> post data", postData)

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.postStoreConfig(authToken, postData)
        }
    }

    postStoreConfig(authToken, postData) {
        this.setState({ isActive: true, errors: {} });
        var URL = MY_STORE_API + "/" + DELIVERYOPTIONS_API
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.handleModalHide()
                    this.getDoorDashBusinessList(authToken)
                    //this.getStoreConfig(authToken, postData.storeid)
                }
                else if (responseJson["StatusCode"] === 400 && responseJson["Status"] === "BadRequest") {
                    let errors = {};
                    errors["myModal2ErrorC"] = responseJson["Message"];
                    this.setState({ errors: errors });
                }
                else {
                    let errors = {};
                    errors["myModal2ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["myModal2ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    resetErrVal() {
        let userFields = this.state.userFields;
        // userFields["defaultmiles"] = "0";
        // userFields["defaultmilesfees"] = "0";
        // userFields["extrapermilefees"] = "0";
        this.setState({
            userFields,
            // IsfreeDelivery: false,
            errors: {}
        })
    }

    handleDelete(deliveryTimeObj, flag) {
        if (flag) {
            this.setState({ deliveryTimeId: deliveryTimeObj._id })
        }
        else {
            this.setState({ deliveryTimeId: "" })
        }
    }

    deleteItem() {
        let storeId = this.state.storeId;
        let deliveryTimeId = this.state.deliveryTimeId;
        var plazaId = this.state.plazaId
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.deleteItemAPI(authToken, storeId, deliveryTimeId, plazaId)
    }

    deleteItemAPI(authToken, storeId, deliveryTimeId, plazaId) {
        this.setState({ isActive: true })
        var URL = MY_STORE_API + "/" + storeId + GET_DELIVERY_TIME + "/" + deliveryTimeId + "?" + PLAZAID + plazaId;
        var postData = {}
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ deliveryTimeId: "" })
                    this.getDeliveryTimes(authToken, storeId, plazaId)
                }
                else {
                    this.setState({ isActive: false })
                }
            })
    }

    handleModalsShow() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal2')
        if (backgroung && modalId) {
            backgroung.style.display = "block"
            modalId.style.display = "block"
        }
    }

    handleModalHide() {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal2')
        if (backgroung && modalId) {
            backgroung.style.display = "none"
            modalId.style.display = "none"
        }
        let storeConfigDetail = this.state.storeConfigDetail
        if (storeConfigDetail) {
            // this.setState({ IsDeliveryEnable: storeConfigDetail.IsDeliveryEnable })
        }
    }

    headerChange(flag) {

        let responseData = this.state.masterTimeOption
        console.log(responseData)
        let timeOptions = []
        if (flag == 0) {
            timeOptions = deliveryDataSet()
        }
        else if (flag == 1) {
            timeOptions = pickupDataSet()
        }
        timeOptions = timeOptions.map(e => {
            let index = responseData.findIndex(e1 => e1.day == e.day)
            if (index >= 0) {
                if (flag == 0) {
                    return {
                        ...e,
                        "isdeliveryenable": responseData[index].isdeliveryenable,
                        "deliverytimes": responseData[index].deliverytimes ? responseData[index].deliverytimes : []
                    }
                }
                else if (flag == 1) {
                    return {
                        ...e,
                        "ispickupenable": responseData[index].ispickupenable,
                        "pickuptimes": responseData[index].pickuptimes ? responseData[index].pickuptimes : []
                    }
                }
            }
            return e
        })

        this.setState({
            selectedHeader: flag,
            editDayIndex: -1,
            EditTimeList: [],
            selectedFrom: null,
            timeOptions: timeOptions
        }, () => {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            if (this.state.storeId && this.state.isSubmit) {
                this.getStoreHours(authToken, this.state.storeId)
            }
            this.setState({ isSubmit: false })
        })
    }

    handleTimeOption(timeObj) {
        let timeOptions = this.state.timeOptions;
        if (this.state.selectedHeader == 0) {
            for (var index in timeOptions) {
                if (timeOptions[index].day == timeObj.day) {
                    timeOptions[index].isdeliveryenable = !timeOptions[index].isdeliveryenable
                }
                if (index == this.state.editDayIndex && !timeOptions[index].isdeliveryenable) {
                    this.setState({ editDayIndex: -1 })
                }
            }
        }
        else if (this.state.selectedHeader == 1) {
            for (var index in timeOptions) {
                if (timeOptions[index].day == timeObj.day) {
                    timeOptions[index].ispickupenable = !timeOptions[index].ispickupenable
                }
                if (index == this.state.editDayIndex && !timeOptions[index].ispickupenable) {
                    this.setState({ editDayIndex: -1 })
                }
            }
        }
        this.setState({
            timeOptions: timeOptions,
            isSubmit: true
        });
    }

    handleEditTime(DObj, Ind) {
        const { storeHourData } = this.state
        let clock = []
        let currDayObj = storeHourData.filter(e => e.day == DObj.day)[0]
        if (currDayObj) {
            // let shour = currDayObj.storehours ? currDayObj.storehours : []
            // let tempDataStart = [], tempDataEnd = []
            // for (var t of shour) {
            //     let [sh, eh] = t ? t.split("-") : []
            //     var shtemp = TimeToHours(sh)
            //     var ehtemp = TimeToHours(eh)
            //     tempDataStart.push({ value: sh, label: `${shtemp.hours}:${shtemp.minutes}` })
            //     tempDataEnd.push({ value: eh, label: `${ehtemp.hours}:${ehtemp.minutes}` })
            // }
            // tempDataStart.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
            // tempDataEnd.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
            // if (tempDataStart[0] && tempDataEnd[tempDataEnd.length - 1]) {
            //     clock = getTimeRanges(tempDataStart[0].value, tempDataEnd[tempDataEnd.length - 1].value)
            // }

            let shour = currDayObj.storehours ? currDayObj.storehours : []
            let tempData = []
            for (var t of shour) {
                let [sh, eh] = t ? t.split("-") : []
                tempData.push(...getTimeRanges(sh, eh))
            }
            tempData.sort((a, b) => {
                let [sh, eh] = a.value ? a.value.split("-") : []
                var shtemp = TimeToHours(sh), ehtemp = TimeToHours(eh)

                let [sh1, eh1] = b.value ? b.value.split("-") : []
                var shtemp1 = TimeToHours(sh1), ehtemp1 = TimeToHours(eh1)
                if (`${shtemp.hours}:${shtemp.minutes}` > `${shtemp1.hours}:${shtemp1.minutes}`) {
                    return 1
                }
                else if (`${shtemp1.hours}:${shtemp1.minutes}` > `${shtemp.hours}:${shtemp.minutes}`) {
                    return -1
                }
                else {
                    return 0
                }
            })

            tempData = tempData.reduce((unique, o) => {
                console.log(unique, o)
                if (!unique.some(obj => obj.value === o.value)) {
                    unique.push(o);
                }
                return unique;
            }, [])

            clock = tempData

            console.log("=====", tempData)

        }

        if (this.state.selectedHeader == 0) {
            this.setState({
                editDayIndex: Ind,
                EditTimeList: DObj.deliverytimes ? DObj.deliverytimes : [],
                selectedFrom: null,
                selectedTo: null,
                errors: {},
                clockList: clock
            })
        }
        else if (this.state.selectedHeader == 1) {
            this.setState({
                editDayIndex: Ind,
                EditTimeList: DObj.pickuptimes ? DObj.pickuptimes : [],
                selectedFrom: null,
                selectedTo: null,
                errors: {},
                clockList: clock
            })
        }
    }

    handleFrom = (selectedFrom) => {
        this.setState({ selectedFrom });
    }

    handleTo = (selectedTo) => {
        this.setState({ selectedTo });
    }

    deleteFromArray(TObj, parentIndex) {
        let EditTimeList = this.state.EditTimeList
        EditTimeList = EditTimeList.filter(e => e != TObj)

        let timeOptions = this.state.timeOptions;
        if (this.state.selectedHeader == 0) {
            timeOptions[parentIndex].deliverytimes = EditTimeList
        }
        else if (this.state.selectedHeader == 1) {
            timeOptions[parentIndex].pickuptimes = EditTimeList
        }
        this.setState({
            timeOptions: timeOptions,
            EditTimeList: EditTimeList,
            isSubmit: true
        });
    }

    validateTimeListForm(Obj) {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        const { selectedFrom, selectedTo } = this.state

        // if (this.state.selectedHeader == 0) {
        if ((!this.state.selectedFrom) || (!this.state.selectedTo)) {
            formIsValid = false;
            errors["deliveryTime"] = this.state.selectedHeader == 0 ? Messages.v_deliveryTime : Messages.v_pickupTime;
        }
        if (selectedFrom && selectedTo) {
            let startTObj = TimeToHours(selectedFrom.value), endTObj = TimeToHours(selectedTo.value)
            if (`${startTObj.hours}:${startTObj.minutes}` >= `${endTObj.hours}:${endTObj.minutes}`) {
                formIsValid = false;
                errors["deliveryTime"] = this.state.selectedHeader == 0 ? Messages.v_deliveryTime : Messages.v_pickupTime;
            }
            else {
                const { storeHourData, clockList } = this.state
                let currDayObj = storeHourData.filter(e => e.day == Obj.day)[0]
                if (currDayObj) {
                    let startTObj = TimeToHours(selectedFrom.value), endTObj = TimeToHours(selectedTo.value)
                    let startTHour = `${startTObj.hours}:${startTObj.minutes}`, endTHour = `${endTObj.hours}:${endTObj.minutes}`, flag = false

                    ///// check with store hour //
                    let shour = getTimeRanges(selectedFrom.value, selectedTo.value)

                    for (var tl of shour) {
                        var temphour = clockList.filter(e => e.value === tl.value)[0]
                        if (!temphour) {
                            flag = true;
                            break;
                        }
                    }

                    console.log("=====", clockList, shour)
                    if (flag) {
                        formIsValid = false
                        errors["deliveryTime"] = this.state.selectedHeader == 0 ? Messages.v_StoreHours3 : Messages.v_StoreHours4
                    }
                    //

                    // check with same data
                    let shour1 = []
                    flag = false
                    if (this.state.selectedHeader == 0) {
                        // shour1 = currDayObj.deliverytimes ? currDayObj.deliverytimes : []
                        shour1 = this.state.EditTimeList;
                    }
                    else if (this.state.selectedHeader == 1) {
                        //  shour1 = currDayObj.pickuptimes ? currDayObj.pickuptimes : []
                        shour1 = this.state.EditTimeList;
                    }

                    for (var t of shour1) {
                        let [sh, eh] = t ? t.split("-") : []
                        let shTemp = TimeToHours(sh)
                        let ehTemp = TimeToHours(eh)

                        let vad1 = (`${shTemp.hours}:${shTemp.minutes}` < endTHour && endTHour <= `${ehTemp.hours}:${ehTemp.minutes}`)
                        let vad2 = (`${shTemp.hours}:${shTemp.minutes}` <= startTHour && startTHour < `${ehTemp.hours}:${ehTemp.minutes}`)
                        let vad3 = (`${shTemp.hours}:${shTemp.minutes}` <= startTHour && endTHour <= `${ehTemp.hours}:${ehTemp.minutes}`)
                        let vad4 = (startTHour < `${shTemp.hours}:${shTemp.minutes}` && `${ehTemp.hours}:${ehTemp.minutes}` < endTHour)
                        if (vad1 || vad2 || vad3 || vad4) {
                            flag = true;
                            break
                        }
                    }
                    if (flag) {
                        formIsValid = false
                        errors["deliveryTime"] = Messages.v_StoreHours1
                    }
                }

            }
        }

        // }
        // else if (this.state.selectedHeader == 1) {
        //     if ((!this.state.selectedFrom) || (!this.state.selectedTo)) {
        //         formIsValid = false;
        //         errors["deliveryTime"] = Messages.v_pickupTime;
        //     }
        //     if(selectedFrom && selectedTo){
        //         let startTObj = TimeToHours(selectedFrom.value), endTObj = TimeToHours(selectedTo.value)
        //         if(`${startTObj.hours}:${startTObj.minutes}` >= `${endTObj.hours}:${endTObj.minutes}`){
        //             formIsValid = false;
        //             errors["deliveryTime"] = Messages.v_pickupTime;
        //         }
        //     }
        // }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSaveInArray(obj, index) {
        let EditTimeList = this.state.EditTimeList;
        let selectedFrom = this.state.selectedFrom;
        let selectedTo = this.state.selectedTo
        let timeOptions = this.state.timeOptions;
        if (this.validateTimeListForm(obj)) {
            let stringTime = selectedFrom.value + " - " + selectedTo.value
            let ind = EditTimeList.findIndex(e => e == stringTime);
            if (ind < 0) {
                EditTimeList.push(stringTime)
            }

            if (this.state.selectedHeader == 0) {
                timeOptions[index].deliverytimes = EditTimeList
            }
            else if (this.state.selectedHeader == 1) {
                timeOptions[index].pickuptimes = EditTimeList
            }
            this.setState({
                timeOptions: timeOptions,
                EditTimeList: EditTimeList,
                selectedFrom: null,
                selectedTo: null,
                isSubmit: true
            })
        }

    }

    // handleSaveInMain(obj, ind) {
    //     let timeOptions = this.state.timeOptions;
    //     timeOptions[ind].timeOPtion = this.state.EditTimeList
    //     this.setState({
    //         timeOptions: timeOptions,
    //         editDayIndex: -1,
    //         EditTimeList: [],
    //         selectedFrom: null,
    //         selectedTo: null
    //     })
    // }

    cancelArrayAssign() {
        this.setState({
            editDayIndex: -1,
            EditTimeList: [],
            selectedFrom: null,
            selectedTo: null
        })
    }

    handleSubmit() {
        var postData = {}
        this.setState({ isActive: true })
        postData["storeid"] = this.state.storeId

        console.log("====", postData)

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.cancelArrayAssign()
        let urls = ""
        if (this.state.selectedHeader == 0) {
            urls = SETUPSTOREDELIVERYHOURS_API
            postData["deliveryehours"] = this.state.timeOptions
            this.postStoreHours(authToken, postData, urls)

        }
        else if (this.state.selectedHeader == 1) {
            urls = SETUPSTOREPICKUPHOURS_API
            postData["pickuphours"] = this.state.timeOptions
            this.postStoreHours(authToken, postData, urls)
        }

    }

    postStoreHours(authToken, postData, url) {
        this.setState({ errors: {} });
        var URL = MY_STORE_API + "/" + url;
        console.log("===>", URL)
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getStoreHours(authToken, postData["storeid"])
                    this.setState({ isSubmit: false })
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
        this.setState({ isActive: false });
    }

    render() {
        const { hideEmptyPlazaView, selectedService, selectedHeader } = this.state;
        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed",
                            zIndex: 1055
                        })
                    }}
                    className="lodingspinner">
                    <Header setHeaderTitle={DeliverytimesConst.Deliverytimes} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            {/* <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleDeliveryTimes}
                                    className="btn btn-reg-email">
                                    {DeliverytimesConst.Add_Delivery_Option}
                                </span>
                            </div> */}
                            <div className="reg_form_main">
                                <p><label style={{ color: Color.BLACK }}>{DeliverytimesConst.StoreTitle}</label></p>
                                <div className="row p-0 mb-3 m-0">
                                    <div className="col-md-12 col-sm-12 store-servicetitle">
                                        <input type="checkbox" checked={this.state.IsPickupEnable}
                                            onClick={() => this.handlePickupChbox()} /> &nbsp; {DeliverytimesConst.IsPickupEnable}
                                    </div>
                                    <div className="col-md-12 col-sm-12 store-servicetitle">
                                        <input type="checkbox" onClick={() => this.handleDeliveryChbox()} checked={this.state.IsDeliveryEnable} /> &nbsp; {DeliverytimesConst.IsDeliveryEnable} &nbsp;
                                        <div className="inline-block float-right">
                                            <div className={"dstoreTime-box " + (!this.state.IsDeliveryEnable ? "dstoreTime-box-disable" : "")}>
                                                <div className={"text-center dservice-type get-more-genre " + (this.state.IsfreeDelivery ? "selected-header" : "") + (!this.state.IsDeliveryEnable ? " dservice-typedisable" : "")}
                                                    onClick={() => this.handlePlazaDeliveryChBox()}>Free</div>
                                                <div className={"text-center dservice-type get-more-genre " + (!this.state.IsfreeDelivery ? "selected-header" : "") + (!this.state.IsDeliveryEnable ? " dservice-typedisable" : "")}
                                                    onClick={() => this.handlePlazaDeliveryChBox()}>Paid</div>
                                            </div>
                                            <i class={"fas fa-edit pl-3 get-more-genre " + ((this.state.IsDeliveryEnable && !this.state.IsfreeDelivery) ? "icon-enable" : "icon-disable")} style={{ fontSize: "20px" }} onClick={() => this.handleEditDeliveryConfig()}></i>
                                        </div>
                                    </div>
                                    {/* {this.state.IsDeliveryEnable && <div className="col-md-4 col-sm-8">
                                        <input type="checkbox" onClick={() => this.handlePlazaDeliveryChBox()}
                                            checked={this.state.IsfreeDelivery} /> &nbsp; {DeliverytimesConst.IsFreeDelivery} &nbsp;

                                    </div>} */}
                                </div>
                                {/* <div className="col-md-12 p-0 mb-3">
                                    {this.state.IsDeliveryEnable && <div className="col-md-4 col-sm-6">
                                        <Select menuPortalTarget={document.body}
                                            value={this.state.selectedService}
                                            onChange={this.handleConfig}
                                            options={this.state.merchantConfigs}
                                            styles={colourStyles}
                                            placeholder={Constvariable.D_SelectOption}
                                            className="select-input"
                                            getOptionLabel={(option) => option.DeliveryOption}
                                            getOptionValue={(option) => option.DeliveryOptionkey}
                                        />
                                    </div>}
                                </div> */}
                                <div className="row storeTime-header">
                                    <div className={"text-center col-6 time-Header get-more-genre " + (selectedHeader == 0 ? "selected-header" : "")}
                                        onClick={() => this.headerChange(0)}>Delivery Times</div>
                                    <div className={"text-center col-6 time-Header get-more-genre " + (selectedHeader == 1 ? "selected-header" : "")}
                                        onClick={() => this.headerChange(1)}>Pickup Times</div>
                                </div>
                                {this.state.selectedHeader == 0 ?
                                    (this.state.IsDeliveryEnable ?
                                        (this.state.timeOptions.map((obj, i) => {
                                            return <>
                                                <div className="row storetime-li" key={i}>
                                                    <div className="row col-sm-8 m-0">
                                                        <div className="col-md-2 col-sm-3 col-3">
                                                            <label class="switch m-0">
                                                                <input type="checkbox" onClick={() => this.handleTimeOption(obj)} checked={obj.isdeliveryenable} />
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-2 col-3">
                                                            {obj.isdeliveryenable && <i class="fas fa-edit get-more-genre mt-1" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleEditTime(obj, i)}></i>
                                                            }
                                                        </div>
                                                        <div className="col-sm-6 col-6">
                                                            <label className="pt-1 mb-0">
                                                                {obj.day}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {this.state.editDayIndex == i ?
                                                        <div className="col-sm-4 text-right">
                                                            {this.state.EditTimeList.map((lObj, SubIndex) => {
                                                                return <div className="col-sm-12 col-12 mt-1">
                                                                    {lObj} &nbsp; <i class="fa fa-trash get-more-genre" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.deleteFromArray(lObj, i)}></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                        :
                                                        <div className="col-sm-4 text-right">
                                                            {
                                                                obj.isdeliveryenable ?
                                                                    obj.deliverytimes.map((t, tind) => {
                                                                        return <div className="col-sm-12 mt-1" key={tind}>
                                                                            {t}
                                                                        </div>
                                                                    })
                                                                    :
                                                                    <div className="col-sm-12 mt-1 disable-text">
                                                                        Closed
                                                                    </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                {this.state.editDayIndex == i &&
                                                    <div className="row col-sm-12 m-0 storeAdd-from">
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-10">
                                                            <label className="col-sm-12 label-color p-0">{AddDeliverytimesConstForm.DeliveryTimeFrom}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={AddDeliverytimesForm.DeliveryTimeFrom} /> <PutMandatoryfieldSymbol />
                                                            </label>
                                                            <Select menuPortalTarget={document.body}
                                                                value={this.state.selectedFrom}
                                                                onChange={this.handleFrom}
                                                                options={this.state.clockList}
                                                                styles={colourStyles}
                                                                className="select-input"
                                                            />
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-10">
                                                            <label className="col-sm-12 label-color p-0">{AddDeliverytimesConstForm.DeliveryTimeTo}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={AddDeliverytimesForm.DeliveryTimeTo} /> <PutMandatoryfieldSymbol />
                                                            </label>
                                                            <Select menuPortalTarget={document.body}
                                                                value={this.state.selectedTo}
                                                                onChange={this.handleTo}
                                                                options={this.state.clockList}
                                                                styles={colourStyles}
                                                                className="select-input"
                                                            />
                                                        </div>
                                                        <div className="col-sm-1 col-2 d-flex align-items-center">
                                                            <i class="fa fa-plus get-more-genre" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleSaveInArray(obj, i)}></i>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="errorMsg">
                                                                {this.state.errors.deliveryTime}
                                                            </div>
                                                        </div>
                                                        <div className="row m-0 col-sm-12 p-0">
                                                            {/* <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                                                        <button type="button" class="btn btn-primary w-100 btn-submit" onClick={() => this.handleSaveInMain(obj, i)}>Save</button>
                                                    </div> */}
                                                            <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                                                                <button type="button"
                                                                    class="btn btn-secondary w-100 btn_cancel"
                                                                    onClick={() => this.cancelArrayAssign()}>Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>

                                        })
                                        )
                                        :
                                        <div className="col-sm-12 mt-2 pl-1 pr-1">
                                            <div class="card text-center">
                                                <div class="card-body">
                                                    <p class="card-text">{DeliverytimesConst.DeliveryTimeNoMessage}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (this.state.IsPickupEnable ?
                                        (this.state.timeOptions.map((obj, i) => {
                                            return <>
                                                <div className="row storetime-li" key={i}>
                                                    <div className="row col-sm-8 m-0">
                                                        <div className="col-md-2 col-sm-3 col-3">
                                                            <label class="switch m-0">
                                                                <input type="checkbox" onClick={() => this.handleTimeOption(obj)} checked={obj.ispickupenable} />
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-2 col-3">
                                                            {obj.ispickupenable && <i class="fas fa-edit get-more-genre mt-1" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleEditTime(obj, i)}></i>
                                                            }
                                                        </div>
                                                        <div className="col-sm-6 col-6">
                                                            <label className="pt-1 mb-0">
                                                                {obj.day}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {this.state.editDayIndex == i ?
                                                        <div className="col-sm-4 text-right">
                                                            {this.state.EditTimeList.map((lObj, SubIndex) => {
                                                                return <div className="col-sm-12 col-12 mt-1">
                                                                    {lObj} &nbsp; <i class="fa fa-trash get-more-genre" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.deleteFromArray(lObj, i)}></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                        :
                                                        <div className="col-sm-4 text-right">
                                                            {
                                                                obj.ispickupenable ?
                                                                    obj.pickuptimes.map((t, tind) => {
                                                                        return <div className="col-sm-12 mt-1" key={tind}>
                                                                            {t}
                                                                        </div>
                                                                    })
                                                                    :
                                                                    <div className="col-sm-12 mt-1 disable-text">
                                                                        Closed
                                                                    </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                {this.state.editDayIndex == i &&
                                                    <div className="row col-sm-12 m-0 storeAdd-from">
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-10">
                                                            <label className="col-sm-12 label-color p-0">{AddPickuptimesConstForm.PickupTimeFrom}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={AddPickuptimesForm.PickupTimeFrom} /> <PutMandatoryfieldSymbol />
                                                            </label>
                                                            <Select menuPortalTarget={document.body}
                                                                value={this.state.selectedFrom}
                                                                onChange={this.handleFrom}
                                                                options={this.state.clockList}
                                                                styles={colourStyles}
                                                                className="select-input"
                                                            />
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-10">
                                                            <label className="col-sm-12 label-color p-0">{AddPickuptimesConstForm.PickupTimeTo}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={AddPickuptimesForm.PickupTimeTo} /> <PutMandatoryfieldSymbol />
                                                            </label>
                                                            <Select menuPortalTarget={document.body}
                                                                value={this.state.selectedTo}
                                                                onChange={this.handleTo}
                                                                options={this.state.clockList}
                                                                styles={colourStyles}
                                                                className="select-input"
                                                            />
                                                        </div>
                                                        <div className="col-sm-1 col-2 d-flex align-items-center">
                                                            <i class="fa fa-plus get-more-genre" style={{ color: Color.THEME_COLOR, fontSize: "20px" }} onClick={() => this.handleSaveInArray(obj, i)}></i>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="errorMsg">
                                                                {this.state.errors.deliveryTime}
                                                            </div>
                                                        </div>
                                                        <div className="row m-0 col-sm-12 p-0">
                                                            {/* <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                                                <button type="button" class="btn btn-primary w-100 btn-submit" onClick={() => this.handleSaveInMain(obj, i)}>Save</button>
                                            </div> */}
                                                            <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                                                                <button type="button"
                                                                    class="btn btn-secondary w-100 btn_cancel"
                                                                    onClick={() => this.cancelArrayAssign()}>Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        })
                                        )
                                        :
                                        <div className="col-sm-12 mt-2 pl-1 pr-1">
                                            <div class="card text-center">
                                                <div class="card-body">
                                                    <p class="card-text">{DeliverytimesConst.PickupTimeNoMessage}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="row m-0 mt-5 col-sm-12 p-0 justify-content-around mb-5">
                                    {this.state.isSubmit &&
                                        <div className="col-sm-3">
                                            <button type="button" class="btn btn-primary w-100 btn-submit" onClick={() => this.handleSubmit()}>Submit</button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center>
                                    <div className="errorMsg">
                                        {this.state.errors.ErrorC}
                                    </div>
                                </center>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteDeliveryTimesTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteDeliveryTime}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                        {/*  delivery Modal start  */}
                        <div className="modal-background" id="modalbackgroundId"> </div>
                        <div class="modal" id="myModal2" style={{ verticalAlign: "middle" }}>
                            <div class="modal-dialog setModal-addStore">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <div className="registed_tab_part pb-0 pt-0" style={{ position: "relative" }}>
                                            <div className="container setfontcolor">
                                                <div className="text-right genreSlug">
                                                    <i class="fas fa-times" onClick={() => this.handleModalHide()}></i>
                                                </div>
                                                <p style={{ display: "block" }}>
                                                    <h4 className="setfontcolor">{DeliverytimesConst.DeliveryOptionSetup}</h4>
                                                </p>

                                                <div className="reg_form_main col-sm-12">
                                                    <label className="label-color">
                                                        {DeliverytimesConst.DeliveryOption}
                                                        &nbsp;
                                                        <OnHoverIconInfo
                                                            message={DeliverytimesForm.DeliveryOption} />
                                                    </label>
                                                    <Select menuPortalTarget={document.body}
                                                        value={this.state.selectedService}
                                                        onChange={this.handleConfig}
                                                        options={this.state.merchantConfigs}
                                                        styles={colourStyles}
                                                        placeholder={Constvariable.D_SelectOption}
                                                        className="select-input"
                                                        getOptionLabel={(option) => option.DeliveryOption}
                                                        getOptionValue={(option) => option.DeliveryOptionkey}
                                                    />
                                                    {/* <div className="errorMsg">
                                                        {this.state.errors.developer_id}
                                                    </div> */}
                                                </div>

                                                {/* {selectedService && selectedService.DeliveryOptionkey == plazaCartObj.DeliveryOptionkey && <div className="reg_form_main col-sm-12">
                                                    <input type="checkbox" onClick={() => this.handlePlazaDeliveryChBox()}
                                                        checked={this.state.IsfreeDelivery} /> &nbsp; {DeliverytimesConst.IsFreeDelivery}
                                                </div>} */}

                                                {selectedService && selectedService.DeliveryOptionkey == plazaCartObj.DeliveryOptionkey && !this.state.IsfreeDelivery &&
                                                    <>
                                                        <div className="reg_form_main col-sm-12">
                                                            <label className="label-color">{DeliverytimesConst.DefaultMiles}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={DeliverytimesForm.DefaultMiles} />
                                                            </label>
                                                            <Select menuPortalTarget={document.body}
                                                                value={this.state.selectedDefultMiles}
                                                                onChange={this.handleDefaultMiles}
                                                                options={this.state.defaultMilesOption}
                                                                isClearable
                                                                styles={colourStyles}
                                                                placeholder={Constvariable.DefaultMiles}
                                                                className="select-input"
                                                            />
                                                            {/* <input
                                                                type="text"
                                                                name="defaultmiles"
                                                                className='add-item-box'
                                                                placeholder={Constvariable.DefaultMiles}
                                                                value={this.state.userFields.defaultmiles}
                                                                onChange={this.handleChange}
                                                            /> */}
                                                            <div className="errorMsg">
                                                                {this.state.errors.defaultmiles}
                                                            </div>
                                                        </div>
                                                        <div className="reg_form_main col-sm-12">
                                                            <label className="label-color">{DeliverytimesConst.DefaultMilesFees} {currencySymbol}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={DeliverytimesForm.DefaultMilesFees} />
                                                            </label>
                                                            <Select
                                                                // menuPortalTarget={document.body}
                                                                styles={colourStyles}
                                                                value={this.state.selectedDefultMilesFess}
                                                                onChange={this.handleDefaultMilesFess}
                                                                options={this.state.defaultMilesOptionFess}
                                                                isClearable

                                                                placeholder={Constvariable.DefaultMilesFees}
                                                                className="select-input"
                                                            />
                                                            {/* <input
                                                                type="text"
                                                                name="defaultmilesfees"
                                                                className='add-item-box'
                                                                placeholder={Constvariable.DefaultMilesFees}
                                                                value={this.state.userFields.defaultmilesfees}
                                                                onChange={this.handleChange}
                                                            /> */}
                                                            <div className="errorMsg">
                                                                {this.state.errors.defaultmilesfees}
                                                            </div>
                                                        </div>
                                                        <div className="reg_form_main col-sm-12">
                                                            <label className="label-color">{DeliverytimesConst.ExtraPerMileFees} {currencySymbol}
                                                                &nbsp;
                                                                <OnHoverIconInfo
                                                                    message={DeliverytimesForm.ExtraPerMileFees} />
                                                            </label>
                                                            <Select
                                                                // menuPortalTarget={document.body}
                                                                styles={colourStyles}
                                                                value={this.state.selectedExtra}
                                                                onChange={this.handleExtratMiles}
                                                                options={this.state.extraMilesOption}
                                                                isClearable

                                                                placeholder={Constvariable.ExtraPerMileFees}
                                                                className="select-input"
                                                            />
                                                            {/* <input
                                                                type="text"
                                                                name="extrapermilefees"
                                                                className='add-item-box'
                                                                placeholder={Constvariable.ExtraPerMileFees}
                                                                value={this.state.userFields.extrapermilefees}
                                                                onChange={this.handleChange}
                                                            /> */}
                                                            <div className="errorMsg">
                                                                {this.state.errors.extrapermilefees}
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {selectedService && selectedService.DeliveryOptionkey != plazaCartObj.DeliveryOptionkey && !this.state.IsfreeDelivery &&
                                                    <div className="reg_form_main col-sm-12">
                                                        <label className="label-color">{DeliverytimesConst.DoordashBusiness}
                                                            &nbsp;
                                                            <OnHoverIconInfo
                                                                message={DeliverytimesForm.DoordashBusiness} />
                                                        </label>
                                                        <Select menuPortalTarget={document.body}
                                                            value={this.state.selectedDoordashBusiness}
                                                            onChange={this.handleDoordashBusiness}
                                                            options={this.state.doordashBusinessList}
                                                            isClearable
                                                            styles={colourStyles}
                                                            placeholder={Constvariable.DoordashBusiness}
                                                            className="select-input"
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.external_business_id}
                                                        />
                                                        <div className="errorMsg">
                                                            {this.state.errors.selectedDoordashBusiness}
                                                        </div>
                                                    </div>
                                                }

                                                <div className="col-12">
                                                    <div className="errorMsg">
                                                        {this.state.errors.myModal2ErrorC}
                                                    </div>
                                                </div>

                                                <div className="bottam_sing">
                                                    <label
                                                        style={{ background: Color.THEME_COLOR }}
                                                        for="step5"
                                                        id="continue-step5"
                                                        onClick={this.handleAdd}
                                                        className="btn btn-done"
                                                    > {DeliverytimesConst.btn_Submit}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  end  */}
                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default Deliverytimes;