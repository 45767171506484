import React, { Component } from 'react';
import AGoogleLoadScript from './AGoogleLoadScript';
import AGoogleRemoveScript from './AGoogleRemoveScript';
import jwt_decode from './JwtMainIndex'

class LoginWithGoogle extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleCredentialResponse = (response) => {
        const { onSuccess } = this.props;
        if (typeof onSuccess == "function") {
            onSuccess(jwt_decode(response.credential));
        }
    }

    componentDidMount() {
        AGoogleLoadScript(
            document,
            'script',
            'google_login_aa_jj',
            'https://accounts.google.com/gsi/client',
            () => {
                const { clientId, buttonText, cookiePolicy, theme, mode } = this.props;
                window.google.accounts.id.initialize({
                    client_id: clientId,
                    callback: this.handleCredentialResponse
                });
                const parent = document.getElementById('google_signin_aa_jj_btn_com');
                window.google.accounts.id.renderButton(parent,
                    {
                        theme: "filled_blue",
                        text: mode === 1 ? "signin_with" : "signup_with",
                        shape: "rectangular",
                        size: "large",
                        width: 300
                    });
                window.google.accounts.id.prompt();
            },
            err => {
                console.log(err);
            }
        );
    }

    componentWillUnmount() {
        AGoogleRemoveScript(document, 'google_login_aa_jj');
    }

    render() {
        const { disabled } = this.props;
        return (
            <div id='google_signin_aa_jj_btn_com' disabled={disabled} style={{ display: 'inline-block', pointerEvents: disabled ? 'none' : 'auto' }} />
        );
    }
}

export default LoginWithGoogle;