import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
// import StoreList from "../../StoreList/StoreList";
import Messages from "../Config/Messages";
import Constants from '../Config/Constants'
import { RetriveDataFromStorage, StoreDataInStorage, StoreObjectInStorage, RetriveObjectFromStorage } from "../Config/Utils";
import { GetData } from "../../services/GetData";
import { PostData } from '../../services/PostData';
import { PAYPALPLAN_API, TYPE, SUBSCRIPTION, GETCLIENTID_API, STOREID, PLAZAID, SUBSCRIPTIONREVISE } from '../Config/Config.json'
// import SideMenu from '../SideMenu/SideMenu';
import Header from '../Header/Header.js'
import Constvariable, { PlatFrom } from '../Config/constvariable';
import Color from '../Config/Color';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import RouteMerchant from '../Config/RouteMerchant.js';
import { StoreListConst } from "../Config/ConstForm"
import './Subscription.css'
import MyPayment from './MyPayment'

class SubscriptionUpdateOnly extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderClass: true,
			planList: [],
			isActive: false,
			storeId: "",
			plazaId: "",
			clientId: "",
			paymentObj: null
		}

	}

	componentDidMount() {
		var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
		this.getClientToken(authToken)
		var storeObj = RetriveObjectFromStorage(Constants.TEMPSTORE__OBJ);
		if (storeObj) {
			var storeId = storeObj.ObjectId;
			var plazaId = storeObj.plazaid
			this.setState({ storeId: storeId, plazaId: plazaId })
			this.getCheckPlan(authToken, storeId, plazaId)

		}
		// this.getPlanListAPI(authToken);
		console.log(authToken);
	}

	getCheckPlan(authToken, storeId, plazaId) {
		this.setState({ isActive: true })
		var URL = SUBSCRIPTION + "?" + STOREID + storeId + "&" + PLAZAID + plazaId
		GetData(URL, authToken)
			.then(result => {
				var responseJson = result;
				if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
					var responseData = responseJson["Data"];
					console.log("responseData ", responseData);
					if (responseData) {
						this.setState({ paymentObj: responseData });
					}
					else {
						this.setState({ paymentObj: null })
					}
				}
				else {
					alert(Messages.ERR_SomethingWentWrong);
				}
				this.setState({ isActive: false })
				this.paramRef(authToken)
			});
	}

	paramRef(authToken) {
		var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
		if (storeObj) {
			if (storeObj.isshopifyintegration) {
				var platForm = String(PlatFrom.Shopify).toUpperCase()
				this.getPlanListAPI(authToken, platForm);
			}
			else if (storeObj.iswixintegration) {

			}
			else {
				var platForm = String(PlatFrom.Plazacart).toUpperCase()
				this.getPlanListAPI(authToken, platForm);
			}
		}
	}

	getPlanListAPI(authToken, platForm) {
		this.setState({ isActive: true })
		var URL = PAYPALPLAN_API + "?" + TYPE + platForm
		console.log(URL)
		GetData(URL, authToken)
			.then(result => {
				var responseJson = result;
				if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
					var responseData = responseJson["Data"];
					console.log("responseData ", responseData);
					if (responseData) {
						responseData = responseData.map(e => {
							if (this.state.paymentObj) {
								if (this.state.paymentObj.plan_id == e.id) {
									return { ...e, ispaypalShow: true }
								}
							}
							return { ...e, ispaypalShow: false }
						})
						this.setState({ planList: responseData });
					}
					else {
						alert(Messages.NO_DATA);
					}
				}
				else {
					alert(Messages.ERR_SomethingWentWrong);
				}
				this.setState({ isActive: false })

			});
	}


	getClientToken(authToken) {
		this.setState({ isActive: true })
		var URL = GETCLIENTID_API
		console.log(URL)
		GetData(URL, authToken)
			.then(result => {
				var responseJson = result;
				if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
					var responseData = responseJson["Data"];
					console.log("responseData ", responseData);

					this.setState({ clientId: responseData });

				}
				else {
					alert(Messages.ERR_SomethingWentWrong);
				}
				this.setState({ isActive: false })
			});
	}

	// //////////////////////// detail api ////////////////////////////
	// //////////////////////// detail api ////////////////////////////

	showpaypal(i) {
		var planList = this.state.planList
		planList = planList.map((planObj, index) => {
			if (index == i) {
				return { ...planObj, ispaypalShow: true }
			}
			return { ...planObj, ispaypalShow: false }
		})
		this.setState({ planList: planList })
	}

	handelRevise(subscribeObj) {
		var postData = {}

		postData["planid"] = subscribeObj.id
		postData["storeid"] = this.state.storeId
		postData["plazaid"] = this.state.plazaId
		postData["subscriptionid"] = this.state.paymentObj ? this.state.paymentObj.id : ""

		console.log("== postData ===>", postData, subscribeObj)
		var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

		if (this.state.storeId && this.state.plazaId) {
			this.postRevise(authToken, postData)
		}

	}

	postRevise(authToken, postData) {
		this.setState({ isActive: true })
		var URL = SUBSCRIPTIONREVISE
		PostData(URL, postData, authToken)
			.then(result => {
				var responseJson = result;
				if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
					this.setState({ isActive: false })
					var responseData = responseJson["Data"];
					console.log("response", responseData)
					if (responseData) {
						var a = document.createElement('a')
						a.target = "_blank"
						a.href = responseData
						a.click()
						StoreDataInStorage(Constants.SUBSCRIPTIONID, postData.planid)
					}
				}
				else {
					alert(Messages.ERR_SomethingWentWrong)
				}
			})
		this.setState({ isActive: false })
	}



	render() {


		return (
			<div>
				<LoadingOverlay
					active={this.state.isActive}
					text={Messages.Loading}
					spinner
					styles={{
						overlay: (base) => ({
							...base,
							position: "fixed"
						})
					}}
					className="lodingspinner"
				>
					<Header setHeaderTitle={Constvariable.PlanUpdate} />
					<section className="section" style={{ backgroundColor: Color.WHITE }}>
						<div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
							<div class="row columns" style={{ margin: "0px" }}>
								{this.state.planList.map((e, index) => {
									var priceObj = null
									if (e.billing_cycles) {
										// priceObj = e.billing_cycles[0] ? e.billing_cycles[0] : null
										if (e.billing_cycles[0]) {
											priceObj = e.billing_cycles[0].billing_price_currency_code + " " + e.billing_cycles[0].billing_price + " / " + e.billing_cycles[0].billing_interval_unit
										}
									}

									var coformBtn, ischnage = false
									if (this.state.paymentObj) {
										if (this.state.paymentObj.plan_id === e.id) {
											coformBtn = "Subscribed"
											ischnage = true
										}
										else {
											coformBtn = <label class="button setbtn" onClick={() => this.handelRevise(e)}>Confirm</label>
										}
									}
									else {
										coformBtn = <label class="button setbtn" onClick={() => this.handelRevise(e)}>Confirm</label>
									}

									return <div className="col-md-4 setsubsriptioncard" key={index}>
										<ul class="price" style={{ boxShadow: e.ispaypalShow ? "0 8px 12px 0 rgba(0,0,0,0.2)" : "unset" }}>
											<li class="header">{e.name}</li>
											<li class="grey">{priceObj}</li>
											<li>
												{e.description}
											</li>
											<li class="grey">
												{ischnage ? coformBtn :
													(e.ispaypalShow ? coformBtn : <label class="button setbtn" onClick={() => this.showpaypal(index)}>Update</label>)}

											</li>
										</ul>
									</div>
								})}
								<div className="col-sm-12 setterms">
									<p>
										<a class="" data-toggle="collapse" href="#multiCollapseExample1"
											role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
											<i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }}></i> {Messages.paymentTermTitle}</a>
									</p>
									<div class="row">
										<div class="col">
											<div class="collapse multi-collapse" id="multiCollapseExample1">
												<div class="card card-body">
													{Messages.paymentTermMsg1}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 setterms">
									<p>
										<a class="" data-toggle="collapse" href="#multiCollapseExample2"
											role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
											<i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }}></i> {Messages.cancelSubscriptionTitle}</a>
									</p>
									<div class="row">
										<div class="col">
											<div class="collapse multi-collapse" id="multiCollapseExample2">
												<div class="card card-body">
													{Messages.subscriptionMsg1}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 setterms">
									<p>
										<a class="" data-toggle="collapse" href="#multiCollapseExample3"
											role="button" aria-expanded="false" aria-controls="multiCollapseExample3">
											<i class="fa fa-plus" aria-hidden="false" style={{ color: Color.THEME_COLOR, fontSize: "20px" }}></i> {Messages.havePayPalTitle}</a>
									</p>
									<div class="row">
										<div class="col">
											<div class="collapse multi-collapse" id="multiCollapseExample3">
												<div class="card card-body">
													{Messages.havePayPalMsg1}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</section>
				</LoadingOverlay>
			</div>
		);
	}
}

export default SubscriptionUpdateOnly;