import React, { Component } from 'react';
import StoreList from "../../StoreList/StoreList";
import Messages from "../../Config/Messages";
import Constants,{DEFAULTID} from '../../Config/Constants'
import { RetriveDataFromStorage } from "../../Config/Utils";
import { GetData } from "../../../services/GetData";
import SideMenu from '../../SideMenu/SideMenu';
import Header from '../../Header/Header.js'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Color from '../../Config/Color';
import Constvariable from '../../Config/constvariable';
import { RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import { GET_TOPPING_CATEGORY, DELETE_TOPPING, PLAZAID } from '../../Config/Config.json';
import { PostData } from '../../../services/PostData';
import RouteMerchant from '../../Config/RouteMerchant.js';

class ManageToppings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderClass: true,
            data: [],
            hideEmptyPlazaView: true,
            plazaId: "",
            storeId: '',
            ItemId: '',
            toppingCategoryId: '',
            toppingList: [],
            toppingId: ''

        }
        this.handleAddProducts = this.handleAddProducts.bind(this);
        // this.handleAddItems = this.handleAddItems.bind(this);

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var storeObj = RetriveObjectFromStorage(Constants.STORE_OBJ);
        var storeItemObj = RetriveObjectFromStorage(Constants.STROE_ITEM_OBJ);
        var toppingCategoryObj = RetriveObjectFromStorage(Constants.TOPPING_CATEGORY_OBJ);
        if (storeObj && storeItemObj && toppingCategoryObj) {
            var storeId = storeObj.ObjectId;
            var plazaId = DEFAULTID
            this.setState({ storeId: storeId, plazaId: plazaId })
            var itemId = storeItemObj._id;
            this.setState({ ItemId: itemId });
            var toppingCategoryId = toppingCategoryObj._id;
            this.setState({ toppingCategoryId: toppingCategoryId })

            this.getToppingList(authToken, storeId, itemId, toppingCategoryId, plazaId);
        } else {
            this.props.history.goBack()
        }
        StoreObjectInStorage(Constants.TOPPING_OBJ, '')
    }

    getToppingList(authToken, storeId, itemId, toppingCategoryId, plazaId) {
        this.setState({ isActive: true })
        var URL1 = GET_TOPPING_CATEGORY.replace('#id#', storeId);
        var URL = URL1.replace('#pid#', itemId) + '/' + toppingCategoryId;
        URL = URL + "?" + PLAZAID + plazaId
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ toppingList: responseData });
                    }
                    else {
                        alert(Messages.NO_DATA);
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false })
            });
    }


    // regularRegister(e) {
    //     localStorage.removeItem("FacebookData");
    //     window.location.href = "/Store";
    // }

    handleAddProducts() {
        this.props.history.push({
            pathname: RouteMerchant.AddToppings,
        })

    }

    handleEdittopping(row) {
        StoreObjectInStorage(Constants.TOPPING_OBJ, row)
        this.props.history.push({
            pathname: RouteMerchant.AddToppings,
        })

    }

    handleDelete(toppingObj, flag) {
        if (flag) {
            this.setState({ toppingId: toppingObj._id })
        }
        else {
            this.setState({ toppingId: "" })
        }
    }

    deleteItem() {
        let itemId = this.state.ItemId;
        let storeId = this.state.storeId;
        let toppingcateid = this.state.toppingCategoryId;
        let toppingId = this.state.toppingId;
        var plazaId = this.state.plazaId
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

        var postData = {}
        postData['ItemId'] = itemId;
        postData['ToppingCategoryId'] = toppingcateid;
        postData['ToppingItemId'] = toppingId;

        this.deleteItemAPI(authToken, storeId, postData, plazaId)
    }

    deleteItemAPI(authToken, storeId, postData, plazaId) {
        this.setState({ isActive: true })
        var URL = DELETE_TOPPING.replace('#id#', storeId);
        URL = URL + "?" + PLAZAID + plazaId
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ toppingId: '' })
                    let itemId = this.state.ItemId;
                    let toppingCategoryId = this.state.toppingCategoryId;
                    this.getToppingList(authToken, storeId, itemId, toppingCategoryId, plazaId);
                }
                else {
                    this.setState({ isActive: false })
                    alert(Messages.ERR_SomethingWentWrong)
                }
            })

    }

    render() {
        const { hideEmptyPlazaView } = this.state;

        var currencySymbol = ""
        var countryInfo = RetriveObjectFromStorage(Constants.COUNTRYINFO)
        if (countryInfo) {
            currencySymbol = "(" + countryInfo.Currency + ")" + countryInfo.Symbol
        }

        const columns = [

            {
                name: "Topping",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{row.ToppingItem}</div>
            },
            {
                name: "Topping Price",
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px" }}>{currencySymbol} {Number(row.Price).toFixed(2)}</div>
            },
            {
                maxWidth: "80px",
                cell: row => <i class="fas fa-edit" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleEdittopping(row)}></i>
            },
            {
                maxWidth: "80px",
                cell: row => <a data-toggle="modal" data-target="#exampleModalLong">
                    <i class="fa fa-trash" aria-hidden="false" style={{ color: Color.THEME_COLOR, float: "left", fontSize: "20px" }} onClick={() => this.handleDelete(row, true)}></i>
                </a>
            },

        ];

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Messages.Loading}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="lodingspinner"
                >
                    <Header setHeaderTitle={Constvariable.ManageToppings} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="registered-with" style={{ float: "right", marginRight: "10px" }}>
                                <span
                                    style={{ background: Color.THEME_COLOR }}
                                    onClick={this.handleAddProducts}
                                    className="btn btn-reg-email">
                                    Add Topping
                                </span>
                            </div>
                            <div className="reg_form_main">
                                {!hideEmptyPlazaView
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img></img>
                                            <p className="col-sm-12" for="FirstName">

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <DataTableExtensions
                                        data={this.state.toppingList}
                                        columns={columns}
                                        export={false}
                                        print={false}
                                        filter={true}
                                        filterHidden={false}
                                        filterPlaceholder={Messages.SearchforToppings}
                                        filterStyle={{ width: "100px" }}

                                    >
                                        <DataTable
                                            noHeader={true}
                                        // noTableHead={true}
                                        //pagination
                                        // onRowClicked={this.handleRowClick}

                                        />
                                    </DataTableExtensions>

                                }
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}

                        {/* <!-- delete Modal start--> */}
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">{Messages.DeleteToppingsTitile}</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        {Messages.DeleteToppings}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => this.handleDelete({}, false)}>NO</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" style={{ backgroundColor: Color.THEME_COLOR }} onClick={() => { this.deleteItem() }}>YES</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- delete Modal end--> */}

                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default ManageToppings;